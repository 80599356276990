import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { convertWindSpeedToMyUnit } from "../../helpers/UnitConverter";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

// CONSTANTS
import { filterHighlightColor } from "../../constants/colors";

// ICONS
import AddToTripIcon from "../../assets/svg/itinerary/add_to_trip.svg";
import WindDirectionIcon from "../../assets/wind-direction-light-ic.svg";

// STYLES
import {
	DescriptionContainer,
	DescTxt,
	ImgContainer,
	MainContainer,
	SubTitle,
	Title,
} from "./styled";

export const useStyles = makeStyles((theme) => ({
	progress: {
		color: "#0078ff",
	},
}));

export const Popup = ({
	currentUser = {},
	toAdd = false,
	feature = {},
	notices = {},
	places = {},
	closeSideBars = () => {},
	verifyEditionMode = () => {},
	addTripNodeEditing = () => {},
	dispatch = () => {},
}) => {
	// STYLES
	const classes = useStyles();

	// STATES
	const { id, type, namelatin } = feature.properties;
	const [lon, lat] = feature.geometry.coordinates;
	const [place, setPlace] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [notice, setNotice] = React.useState({ type: "", content: "" });

	// FUNCTIONS
	function handleOnClick() {
		closeSideBars();
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.PLACE_VIEWED
		);
		logEvent_("react_explore_placeprev_thumb_pressed", {
			place_type: place.Type,
		});

		dispatch({
			type: "updateSidebarPlacesState",
			place: {
				id,
				type,
				namelatin,
				lon,
				lat,
			},
		});
	}

	// EFFECTS
	React.useEffect(() => {
		if (!loading) return;
		async function init() {
			try {
				await Promise.all([ApiClient.getPlacePreview(id)]).then((values) => {
					setPlace(values[0]);
				});
			} catch (e) {
			} finally {
				setLoading(false);
			}
		}
		init();
	}, [currentUser?.Settings?.WindSpeedUnits, notices, toAdd]);

	return (
		<MainContainer className="p-0 d-flex flex-row popup">
			{loading ? (
				<div className="d-flex h-100 w-100 justify-content-center align-items-center">
					<CircularProgress size={20} className={classes.progress} />
				</div>
			) : (
				<div className="flex-1 d-flex h-100 w-100">
					<DescriptionContainer
						style={{ padding: 0 }}
						className="d-flex flex-row"
					>
						<ImgContainer
							onClick={handleOnClick}
							style={{
								backgroundImage:
									place?.CoverImage && place?.CoverImage?.length
										? `url(${place?.CoverImage})`
										: `url(https://keeanostage.blob.core.windows.net/0000000000000000000/placeholders/placeholder_${place?.Type}.png)`,
								backgroundPosition: "center",
								backgroundSize: "cover",
							}}
						/>
						<div
							className="p-3 d-flex flex-row justify-content-between"
							style={{ flex: 1 }}
						>
							<div
								className="d-flex flex-column"
								style={{ flex: 1, cursor: "pointer" }}
							>
								<div style={{ flex: 5 }} onClick={handleOnClick}>
									<Title>{place.Name?.Latin || place.Type}</Title>
									<SubTitle className="dromon-l">{places[place.Type]}</SubTitle>
								</div>
								{!!place?.Weather?.Speed && (
									<div
										className="d-flex flex-row"
										onClick={handleOnClick}
										style={{ flex: 5 }}
									>
										<div style={{ flex: 1 }}>
											<DescTxt
												style={{ backgroundColor: "#f6f6f6", borderRadius: 5 }}
												className="p-2 dromon-l"
											>
												<img
													src={WindDirectionIcon}
													style={{
														width: 16,
														marginRight: 4,
														transform: `rotate(${
															135 + place.Weather?.Angle
														}deg)`,
													}}
												/>
												{currentUser?.Settings?.WindSpeedUnits
													? Math.round(
															convertWindSpeedToMyUnit(
																place?.Weather?.Speed,
																currentUser?.Settings?.WindSpeedUnits
															)
													  )
													: place.Weather?.Speed}{" "}
												{currentUser?.Settings?.WindSpeedUnits
													? currentUser?.Settings?.WindSpeedUnits
													: "kts"}
											</DescTxt>
										</div>
									</div>
								)}
							</div>

							<div className="d-flex flex-column justify-content-between align-items-center">
								<IconButton
									onClick={() => {
										logEvent_("react_explore_placeprev_addbtn_pressed");
										verifyEditionMode(() => addTripNodeEditing(place, false));
									}}
									style={{
										width: 32,
										height: 32,
									}}
								>
									<img
										src={AddToTripIcon}
										alt={"Add_Place"}
										title="add a place"
										style={{
											width: 18,
											height: 18,
											objectFit: "contain",
										}}
									/>
								</IconButton>
							</div>
						</div>
					</DescriptionContainer>
				</div>
			)}

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", top: -50, right: 0 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</MainContainer>
	);
};

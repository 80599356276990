import React from "react";
import { connect } from "react-redux";
import { Button, Snackbar } from "@material-ui/core";
import branch from "branch-sdk";
import dateFormat from "date-fns/format";
import { Alert, AlertTitle } from "@material-ui/lab";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { exportToXLSX } from "../../helpers/functions";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import {
	userData,
	sidebarPartnerProvision,
} from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import { showUserProfile } from "../../store/features/user/actions";

// CONSTANTS
import {
	colorInactiveItem,
	filterHighlightColor,
	whiteColor,
} from "../../constants/colors";

// COMPONENTS
import SideBar from "../utils/SideBar";
import ProductItem from "./components/ProductItem";
import AddProvisionControls from "./components/AddProvisionControls";
import { isEmpty } from "@firebase/util";

const ProvisionList = ({
	visible,
	userData = {},
	currentLang = "EN",
	showUserProfile = () => {},
}) => {
	// DATA
	const initialFormValues = {
		Name: "",
		ProductID: "",
		Category: 0,
		Packaging: "",
		Favorite: false,
	};

	const initialNoticeValues = {
		type: "",
		title: "",
		content: "",
		open: false,
		duration: 5000,
	};

	// STATES
	const [loading, setLoading] = React.useState(false);
	const [filter, setFilter] = React.useState(initialFormValues);
	const [notice, setNotice] = React.useState(initialNoticeValues);
	const [productList, setProductList] = React.useState([initialFormValues]);
	const [editableProductList, setEditableProductList] = React.useState([
		initialFormValues,
	]);
	const [inEdition, setInEdition] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [isCompleted, setIsCompleted] = React.useState(false);
	const [categories, setCategories] = React.useState([]);
	const [items, setItems] = React.useState({});
	const [allCategories, setAllCategories] = React.useState({});

	// REFS
	const sidebarContentRef = React.useRef(null);

	// FUNCTIONS
	const onCancel = () => {
		setInEdition(false);
		setEditableProductList(productList);
	};

	const onSave = async () => {
		setInEdition(false);
	};

	const onLoadData = () => {
		setLoading(true);
		getCategories();
		getMine();
	};

	const getCategories = () => {
		ApiClient.getPartnerProvisionCategories()
			.then((res) => {
				setAllCategories(res);
				const data = [];
				for (const key in res) {
					if (Object.hasOwnProperty.call(res, key)) {
						const category = res[key];
						data.push(category);
					}
				}
				setCategories(data);
				setInEdition(false);
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
			});
	};

	const getMine = () => {
		ApiClient.getMyProvisionList()
			.then((res) => {
				let data = [];
				for (const category in res) {
					if (Object.hasOwnProperty.call(res, category)) {
						const item = res[category];
						if (item?.length)
							item.map((product) =>
								data.push({ ...initialFormValues, ...product })
							);
					}
				}
				setItems(res);
				setProductList(data);
				setEditableProductList(data);
				setInEdition(false);
				setLoading(false);
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
				setLoading(false);
			});
	};

	const onAddItem = (item) => {
		var idIndex = null;
		for (const key in allCategories) {
			if (Object.hasOwnProperty.call(allCategories, key)) {
				const category = allCategories[key];
				if (category === item.Category) {
					idIndex = key;
				}
			}
		}
		if (idIndex) {
			setLoading(true);
			ApiClient.addUpdatePartnerProvisionItem(
				null,
				parseInt(idIndex),
				item.Name,
				item.Packaging,
				false
			)
				.then((res) => {
					onLoadData();
				})
				.catch((error) => {
					//console.log("ERROR ==>", error);
					setLoading(false);
				});
		}
	};

	const onDeleteItem = (id) => {
		setLoading(true);
		ApiClient.deletePartnerProvisionItem(id)
			.then((res) => {
				onLoadData();
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
				setLoading(false);
			});
	};

	const onUpdateItem = (item) => {
		var idIndex = null;
		for (const key in allCategories) {
			if (Object.hasOwnProperty.call(allCategories, key)) {
				const category = allCategories[key];
				if (category === item.Category) {
					idIndex = key;
				}
			}
		}
		if (idIndex) {
			ApiClient.addUpdatePartnerProvisionItem(
				item.Id,
				parseInt(idIndex),
				item.Name,
				item.Packaging,
				item.StarterPack
			)
				.then((res) => {
					onLoadData();
				})
				.catch((error) => {
					//console.log("ERROR ==>", error);
					setLoading(false);
				});
		}
	};

	const handleCloseNotice = (event, reason) => {
		if (reason === "clickaway") return;

		setNotice(initialNoticeValues);
	};

	React.useEffect(() => {
		setLoading(true);
		if (visible) onLoadData();
	}, [visible]);

	return (
		<>
			<SideBar
				visible={visible}
				useBackBtn={true}
				back_title={userData?.FirstName + " " + userData?.LastName}
				back_subTitle={"Partner provision list"}
				isLoading={loading}
				sidebarProps={{
					ref: sidebarContentRef,
				}}
				autoCloseSidebars={false}
				fixedFooterButton={true}
				footerButtons={
					[
						/*
					{
						label: languages[currentLang]["common_cancel"],
						props: {
							style: { color: colorInactiveItem, opacity: inEdition ? 1 : 0.4 },
							disabled: !inEdition,
							onClick: onCancel,
						},
					},
					{
						label: (
							<>
								<i className="fa fa-fw fa-save mr-2" /> Save
							</>
						),
						props: {
							style: { opacity: inEdition ? 1 : 0.4 },
							disabled: !inEdition,
							onClick: onSave,
						},
					},*/
					]
				}
				onClickBack={() => {
					if (inEdition)
						return setNotice({
							...notice,
							type: "warning",
							title: "Data not saved",
							content: "Your data aren't saved",
							open: true,
						});
					showUserProfile();
				}}
			>
				<div className="px-3">
					<AddProvisionControls
						state={filter}
						selectItems={categories}
						onAdd={(data) => {
							onAddItem(data);
						}}
						onFilter={setFilter}
					/>

					<div className="d-flex flex-column w-100 h-100" style={{ flex: 1 }}>
						{editableProductList.map((item, id) => {
							const regName = new RegExp(filter.Name, "ig");
							const regPackaging = new RegExp(filter.Packaging, "ig");

							return (
								(item.Category === filter.Category ||
									isEmpty(filter.Category)) &&
								(regName.test(item.Name) || isEmpty(filter.Name)) &&
								(regName.test(item.Packaging) || isEmpty(filter.Packaging)) && (
									<ProductItem
										key={id}
										item={item}
										setState={setEditableProductList}
										categoryItems={categories}
										onEdit={() => setInEdition(true)}
										onDelete={(id) => onDeleteItem(id)}
										onUpdate={(item) => onUpdateItem(item)}
									/>
								)
							);
						})}
					</div>
				</div>
			</SideBar>

			<Snackbar
				open={notice.open}
				autoHideDuration={notice.duration}
				onClose={handleCloseNotice}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Alert onClose={handleCloseNotice} severity={notice.type}>
					<AlertTitle>{notice.title}</AlertTitle>
					{notice.content}
				</Alert>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		visible: sidebarPartnerProvision(state),
		userData: userData(state),
		currentLang: currentLang(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showUserProfile: () => dispatch(showUserProfile()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvisionList);

import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";
import fetch from "node-fetch";
import { Button } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";
import Alert from "@material-ui/lab/Alert";

// HELPERS
import { ApiClient } from "../../ApiClient";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";
import { getBookmarks } from "../map/helpers/bookMarksHelper";

// CONSTANTS
import { Keys } from "../../appConfig";
import DEFAULT_TOAST_PROPS from "../../constants/tostifyDefaultProps";

// STORE
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages/";
import {
	closeUserSidebars,
	setUserData,
} from "../../store/features/user/actions";

// ICONS
import checkIcon from "../../assets/Check.svg";
import logo from "../../assets/keeano-icon.svg";

// STYLES
import "./style.css";
import { AuthService } from "../../AuthService";
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../constants/colors";
import { Container, Form, FormContent } from "./styled";
import { useStyles } from "../map/Popup";

const Auth = ({
	closeSidebar,
	setUpdate,
	setUserData,
	closeUserSidebars,
	showSignupScreen = false,
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [login, setLogin] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [checked, setChecked] = useState(false);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [isForgot, setIsForgot] = useState(false);
	const [isSignup, setIsSignup] = useState(false);
	const [fName, setFName] = useState("");
	const [lName, setLName] = useState("");
	const [password, setPassword] = useState("");

	// DATA
	const facebookId = Keys.facebookId;
	const googleClientId = Keys.googleClientId;
	const appleID = Keys.appleID;
	const redirectURI = "https://" + window.location.host + "/explore";

	const handleClose = () => {
		setLoading(false);
		setUpdate();
	};

	const handleSuccess = () => {
		toast.success("Connected successfully.", DEFAULT_TOAST_PROPS);

		setLogin(true);
		ApiClient.getUser()
			.then((userData) => {
				setUserData(userData);
				// EVENT
				logEvent_("react_auth_userlogin_success");
				window.localStorage.setItem(
					"currentUserId",
					JSON.stringify({
						userId: userData.ID,
					})
				);

				setTimeout(() => {
					window.location.reload(false);
				}, 1000);
			})
			.catch(() => {
				toast.error(
					languages[currentLanguage]["common_error_something_went_wrong"],
					DEFAULT_TOAST_PROPS
				);
				setLoading(false);
			});
	};

	const responseFacebook = async (response) => {
		// ADD EVENT ON FACEBOOK CLICKED
		logEvent_("react_auth_facebook_button_pressed");

		setLoading(true);
		if (response.status === "unknown") {
			// EVENT
			logEvent_("react_auth_userlogin_failure");

			toast.error(
				languages[currentLanguage]["common_error_auth_error_unavailable"],
				DEFAULT_TOAST_PROPS
			);
			setLoading(false);
		} else {
			let token = {
				access_token: response.accessToken,
				expires_in: response.data_access_expiration_time,
				token_type: "facebook",
			};
			if (token.access_token === null) {
				logEvent_("react_auth_userlogin_failure");

				toast.error(
					languages[currentLanguage]["common_error_something_went_wrong"],
					DEFAULT_TOAST_PROPS
				);
				setLoading(false);
				return;
			}
			const LOGIN_SUCCESS = await AuthService.logIn("facebook", token, null);

			if (!LOGIN_SUCCESS.success) {
				logEvent_("react_auth_userlogin_failure");

				toast.error(
					languages[currentLanguage]["common_error_something_went_wrong"],
					DEFAULT_TOAST_PROPS
				);

				setLoading(false);
			} else {
				gtag_report_conversion(
					"https://" + window.location.host + "/explore/",
					types.LOGGED_ON
				);

				handleSuccess();
			}
		}
	};

	const callbackApple = async (response) => {
		logEvent_("react_auth_apple_button_pressed");
		setLoading(true);

		if (response.error) {
			toast.error(
				languages[currentLanguage]["common_error_something_went_wrong"],
				DEFAULT_TOAST_PROPS
			);

			setLoading(false);
		} else {
			let token = {
				access_token: response.authorization.id_token,
				expires_in: new Date(
					new Date().setDate(new Date().getDate() + 30)
				).getTime(),
				token_type: "apple",
			};

			if (token.access_token === null || response.authorization.code === null) {
				logEvent_("react_auth_userlogin_failure");

				toast.error(
					languages[currentLanguage]["common_error_something_went_wrong"],
					DEFAULT_TOAST_PROPS
				);

				return setLoading(false);
			}

			const LOGIN_SUCCESS = await AuthService.logIn(
				"apple",
				token,
				response.authorization.code
			);
			if (!LOGIN_SUCCESS.success) {
				logEvent_("react_auth_userlogin_failure");

				toast.error(
					languages[currentLanguage]["common_error_auth_error_unavailable"],
					DEFAULT_TOAST_PROPS
				);

				setNotice({
					type: "ERROR",
					content:
						languages[currentLanguage]["common_error_auth_error_unavailable"],
				});

				setLoading(false);
			} else {
				gtag_report_conversion(
					"https://" + window.location.host + "/explore/",
					types.LOGGED_ON
				);

				handleSuccess();
			}
		}
	};

	const handleKeeanoRefreshToken = (refresh_token) => {
		setLoading(true);
		fetch("/api/refresh/" + refresh_token, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-type": "application/json",
			},
			mode: "cors",
		})
			.then((res) => res.json())
			.then((resJson) => {
				if (resJson.success) {
					let token = {
						access_token: resJson.data.access_token,
						expires_in: resJson.data.expires_in,
						token_type: resJson.data.token_type,
					};
					AuthService.logIn("keeano", token, null)
						.then((success) => {
							if (!success) {
								if (password.trim().length !== 0)
									setNotice({
										type: "ERROR",
										content:
											languages[currentLanguage][
												"common_error_auth_error_unavailable"
											],
									});
								setLoading(false);
							} else {
								setNotice({
									type: "SUCCESS",
									content: "Connected successfully.",
								});
								setLoading(false);
								setUpdate();
								setTimeout(() => {
									setNotice({
										type: "",
										content: "",
									});
									closeSidebar();
								}, 1000);
								setLogin(true);
								ApiClient.getUser().then((userData) => setUserData(userData));
								closeUserSidebars();
							}
						})
						.catch((error) => {
							setNotice({
								type: "ERROR",
								content:
									languages[currentLanguage][
										"common_error_auth_error_unavailable"
									],
							});
							setLoading(false);
						});
				} else {
					setNotice({
						type: "ERROR",
						content:
							languages[currentLanguage]["common_error_auth_error_unavailable"],
					});
					setLoading(false);
				}
			})
			.catch((error) => {
				setNotice({
					type: "ERROR",
					content:
						languages[currentLanguage]["common_error_auth_error_unavailable"],
				});
				setLoading(false);
			});
	};

	const handleKeeanoLogin = () => {
		setLoading(true);
		fetch(
			"/api/details/" +
				Buffer.from(email, "binary").toString("base64") +
				"/" +
				Buffer.from(password, "binary").toString("base64"),
			{
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-type": "application/json",
				},
				mode: "cors",
			}
		)
			.then((res) => res.json())
			.then((resJson) => {
				if (resJson.success) {
					let token = {
						access_token: resJson.data.access_token,
						expires_in: resJson.data.expires_in,
						token_type: resJson.data.token_type,
					};
					AuthService.logIn("keeano", token, null)
						.then((success) => {
							if (!success.success) {
								setNotice({
									type: "ERROR",
									content: success.data.error_description,
								});
								setLoading(false);
							} else {
								gtag_report_conversion(
									"https://" + window.location.host + "/explore/",
									types.LOGGED_ON
								);
								handleSuccess();
							}
						})
						.catch((error) => {
							// EVENT
							logEvent_("react_auth_userlogin_failure");

							setNotice({
								type: "ERROR",
								content:
									languages[currentLanguage][
										"common_error_something_went_wrong"
									],
							});
							setLoading(false);
						});
				} else {
					setNotice({
						type: "ERROR",
						content: resJson.data.error_description,
					});
					setLoading(false);
				}
			})
			.catch((error) => {
				// EVENT
				logEvent_("react_auth_userlogin_failure");
				setNotice({
					type: "ERROR",
					content:
						languages[currentLanguage]["common_error_something_went_wrong"],
				});
				setLoading(false);
			});
	};

	const onGoogleSucced = async (response) => {
		logEvent_("react_auth_google_button_pressed");

		setLoading(true);
		if (response.error) {
			setNotice({
				type: "ERROR",
				content:
					languages[currentLanguage]["common_error_something_went_wrong"],
			});
			setLoading(false);
			return;
		}
		let token = {
			access_token: response.tokenObj.id_token,
			expires_in: response.tokenObj.expiration_in,
			token_type: "google",
		};
		if (token.access_token === null) {
			// EVENT
			logEvent_("react_auth_userlogin_failure");
			setNotice({
				type: "ERROR",
				content:
					languages[currentLanguage]["common_error_auth_error_unavailable"],
			});
			setLoading(false);
			return;
		}
		const LOGIN_SUCCESS = await AuthService.logIn("google", token, null);
		if (!LOGIN_SUCCESS.success) {
			// EVENT
			logEvent_("react_auth_userlogin_failure");

			setNotice({
				type: "ERROR",
				content:
					languages[currentLanguage]["common_error_auth_error_unavailable"],
			});
			setLoading(false);
		} else {
			// EVENT
			logEvent_("react_auth_userlogin_success");

			gtag_report_conversion(
				"https://" + window.location.host + "/explore/",
				types.LOGGED_ON
			);
			handleSuccess();
		}
	};

	const onGoogleFail = (response) => {
		setLoading(true);
		setNotice({
			type: "ERROR",
			content:
				languages[currentLanguage]["common_error_auth_error_unavailable"],
		});
		setLoading(false);
	};

	const handleForget = async () => {
		setLoading(true);
		const response = await ApiClient.resetPassword(email);
		if (!response) {
			setNotice({
				type: "SUCCESS",
				content: "Reset password email sent",
			});
			if (isSignup) {
				logEvent_("react_screen_signup_view_left");
			}
			setIsSignup(false);
			setLoading(false);
		} else if (response.error || response.Error) {
			setNotice({
				type: "ERROR",
				content: response.error_description
					? languages[currentLanguage][response.error_description]
					: languages[currentLanguage]["common_error_something_went_wrong"],
			});
			setLoading(false);
		} else {
			if (response.Email) {
				setNotice({
					type: "ERROR",
					content: languages[currentLanguage][response.Email[0]]
						? languages[currentLanguage][response.Email[0]]
						: response.Email[0],
				});
				setLoading(false);
				return;
			}
			// No error
			setNotice({
				type: "SUCCESS",
				content: "Reset password email sent",
			});
			if (isSignup) {
				logEvent_("react_screen_signup_view_left");
			}
			setIsSignup(false);
			setLoading(false);
		}
	};

	const handleKeeanoSignup = async () => {
		// check password
		logEvent_("react_auth_signupkeeano_button_pressed");

		if (!checked) {
			setNotice({
				type: "ERROR",
				content: languages[currentLanguage]["auth_acceptlegal_check_error_msg"],
			});
			return;
		}
		setLoading(true);
		if (password.length > 5) {
			setLoading(true);
			const response = await ApiClient.signup(fName, lName, password, email);
			if (!response) {
				// LOG EVENTS
				logEvent_("react_auth_userregister_failure");

				setNotice({
					type: "ERROR",
					content:
						languages[currentLanguage]["common_error_something_went_wrong"],
				});
				setLoading(false);
			} else if (response.error) {
				// LOG EVENTS
				logEvent_("react_auth_userregister_failure");

				setNotice({
					type: "ERROR",
					content: languages[currentLanguage][response.error_description]
						? languages[currentLanguage][response.error_description]
						: languages[currentLanguage][response.error],
				});
				setLoading(false);
			} else {
				// No error
				// EVENTS
				logEvent_("react_auth_userregister_success");

				gtag_report_conversion(
					"https://" + window.location.host + "/explore/",
					types.ACCOUNT_CREATED
				);
				setNotice({
					type: "SUCCESS",
					content: "Account created successfully",
				});
				if (isSignup) {
					logEvent_("react_screen_signup_view_left");
				}
				setIsSignup(false);
				setLoading(false);
			}
		} else {
			setNotice({
				type: "ERROR",
				content:
					"Your password is not strong enough, it should have at least 6 characters",
			});
			setLoading(false);
		}
	};

	useEffect(() => {
		if (showSignupScreen) {
			setIsSignup(true);
			setNotice({
				type: "WARNING",
				content: languages[currentLanguage]["trip_invitation_crew_notauth_msg"],
			});
			setTimeout(() => {
				setNotice({
					content: "",
					type: "",
				});
			}, 5000);
		}
		if (AuthService.isUserLogedIn()) {
			setLogin(true);
			ApiClient.getUser().then((userData) => {
				window.localStorage.setItem(
					"currentUserId",
					JSON.stringify({
						userId: userData.ID,
					})
				);
				setUserData(userData);
			});
			closeUserSidebars();
		} else {
			// refresh the token
			if (localStorage.getItem("session")) {
				switch (JSON.parse(localStorage.getItem("session")).provider) {
					case "google":
						//
						break;
					case "keeano":
						handleKeeanoRefreshToken();
						break;
					default:
						setLogin(false);
				}
			}
			setLogin(false);
		}
	}, [showSignupScreen]);
	return (
		<Container
			className="w-100 auth-container d-flex justify-content-center align-items-center"
			style={{ height: "100vh" }}
		>
			<IconButton
				onClick={() => {
					setNotice({
						content: "",
						type: "",
					});
					closeSidebar();
				}}
				component="span"
				className="d-flex justify-content-center align-items-center"
				style={{
					backgroundColor: "rgba(0,0,0,0.4)",
					position: "absolute",
					zIndex: 2,
					top: 20,
					right: 20,
					height: 30,
					width: 30,
					padding: 0,
					fontSize: 12,
					color: "#fff",
				}}
			>
				<i className="fas fa-times fa-fw" />
			</IconButton>

			<Form className="form">
				<div style={{ backgroundColor: "#003e93" }}>
					<FormContent className="w-100 " style={{ padding: 42 }}>
						<div className="d-flex flex-column justify-content-center text-center">
							<img
								alt=""
								src={logo}
								style={{ height: 80, maxWidth: 120, marginBottom: 50 }}
								className="mx-auto mb-3"
							/>
							<p style={{ fontSize: 30, color: formMainTxt }} className="mb-0">
								{isSignup
									? languages[currentLanguage]["auth_signup"]
									: isForgot
									? languages[currentLanguage]["auth_forgot_pass"]
									: languages[currentLanguage]["auth_login"]}
							</p>
							<p
								style={{ color: secondaryTxt, fontSize: 18 }}
								className="dromon-l"
							>
								{isForgot
									? languages[currentLanguage]["auth_forgot_pass_subheader"]
									: languages[currentLanguage]["auth_welcome"]}
							</p>
						</div>
						<div className="mt-4">
							{isSignup && !isForgot && (
								<>
									<p className="my-1" style={{ color: formMainTxt }}>
										{languages[currentLanguage]["auth_signup_fname"]}
									</p>
									<input
										type="text"
										required="required"
										className="p-2 w-100 dromon-l"
										placeholder={
											languages[currentLanguage][
												"auth_signup_fname_placeholder"
											]
										}
										value={fName}
										onChange={(e) => setFName(e.target.value)}
									/>
									<p className="my-1" style={{ color: formMainTxt }}>
										{languages[currentLanguage]["auth_signup_lname"]}
									</p>
									<input
										type="text"
										required="required"
										className="p-2 w-100 dromon-l"
										placeholder={
											languages[currentLanguage][
												"auth_signup_lname_placeholder"
											]
										}
										value={lName}
										onChange={(e) => setLName(e.target.value)}
									/>
								</>
							)}
							<p className="my-1" style={{ color: formMainTxt }}>
								{languages[currentLanguage]["auth_common_email"]}
							</p>

							<input
								type="email"
								required="required"
								className="p-2 w-100 dromon-l"
								placeholder={
									languages[currentLanguage]["auth_common_email_placeholder"]
								}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>

							{!isForgot && (
								<>
									<p className="my-1" style={{ color: formMainTxt }}>
										{languages[currentLanguage]["auth_common_password"]}
									</p>
									<input
										className="p-2 w-100 dromon-l"
										value={password}
										placeholder={
											languages[currentLanguage][
												"auth_common_password_placeholder"
											]
										}
										type="password"
										required="required"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</>
							)}
						</div>

						<div className="mt-3 d-flex flex-row justify-content-between">
							{!isForgot && (
								<>
									<div className="d-flex align-items-center">
										<div
											onClick={() => setChecked(!checked)}
											className="d-flex justify-content-center align-items-center p-1"
											style={{
												height: 25,
												width: 25,
												borderRadius: 4,
												backgroundColor: checked ? "transparent" : "#dbdee6",
												border: "1px solid #dbdee6",
												cursor: "pointer",
											}}
										>
											{checked && <img src={checkIcon} alt="Accept" />}
										</div>
										<span
											style={{ color: secondaryTxt, fontSize: 12 }}
											className="mx-2 dromon-l"
										>
											{!isSignup ? (
												<span
													style={{ cursor: "pointer" }}
													onClick={() => setChecked(!checked)}
												>
													{
														languages[currentLanguage][
															"auth_login_remember_checkbox"
														]
													}{" "}
												</span>
											) : (
												<>
													<span
														style={{
															textDecoration: "underline",
															cursor: "pointer",
														}}
														className="dromn"
													>
														{
															languages[currentLanguage][
																"auth_signup_acceptlegal_checkbox"
															]
														}{" "}
													</span>
												</>
											)}
										</span>
									</div>
								</>
							)}

							{!isSignup && !isForgot && (
								<>
									<p className="mb-0">
										<span
											onClick={() => setIsForgot(true)}
											style={{
												color: filterHighlightColor,
												textDecoration: "none",
												fontSize: 12,
												cursor: "pointer",
											}}
										>
											{
												languages[currentLanguage][
													"auth_login_forgot_pass_checkbox"
												]
											}
										</span>
									</p>
								</>
							)}
						</div>

						<Button
							className="col-12 my-3"
							onClick={
								!isSignup && !isForgot
									? handleKeeanoLogin
									: isSignup && !isForgot
									? handleKeeanoSignup
									: handleForget
							}
							style={{
								color: whiteColor,
								backgroundColor: filterHighlightColor,
								border: "none",
								height: 50,
								fontSize: 21,
								borderRadius: 10,
							}}
						>
							{isSignup && !loading ? (
								"Sign up"
							) : isForgot && !loading ? (
								languages[currentLanguage]["auth_login_forgot_pass_checkbox"]
							) : !loading ? (
								languages[currentLanguage]["auth_login"]
							) : (
								<i className="fa fa-circle-notch fa-spin mr-2" />
							)}
						</Button>
						{!isForgot && (
							<>
								<div className="mt-3 d-flex flex-row justify-content-between align-items-center">
									<div
										style={{
											height: 1,
											width: 70,
											opacity: 0.5,
											backgroundColor: secondaryTxt,
										}}
									></div>
									<span
										className="dromon-l"
										style={{ color: secondaryTxt, fontSize: 18 }}
									>
										{languages[currentLanguage]["auth_view_join_us"]}
									</span>
									<div
										style={{
											height: 1,
											width: 70,
											opacity: 0.5,
											backgroundColor: secondaryTxt,
										}}
									></div>
								</div>
								<div className="d-flex flex-row justify-content-center mt-4">
									<FacebookLogin
										appId={facebookId}
										// autoLoad={checkToken}
										size="small"
										fields="name,email,picture"
										scope="public_profile,user_friends"
										callback={responseFacebook}
										render={(renderProps) => (
											<button
												className="mx-3"
												onClick={renderProps.onClick}
												style={{
													border: "none",
													backgroundColor: "transparent",
												}}
											>
												<svg
													width="42px"
													height="42px"
													viewBox="0 0 42 42"
													version="1.1"
													xmlns="http://www.w3.org/2000/svg"
												>
													<g
														id="Web-App"
														stroke="none"
														strokeWidth="1"
														fill="none"
														fillRule="evenodd"
													>
														<g
															id="Singup"
															transform="translate(-588.000000, -746.000000)"
															fillRule="nonzero"
														>
															<g
																id="Group"
																transform="translate(481.000000, -39.000000)"
															>
																<g
																	id="social"
																	transform="translate(52.000000, 743.000000)"
																>
																	<g
																		id="facebook_round_ic"
																		transform="translate(55.000000, 42.000000)"
																	>
																		<circle
																			id="Oval"
																			fill="#3B5998"
																			cx="21"
																			cy="21"
																			r="21"
																		></circle>
																		<path
																			d="M17.6765217,34.5678261 L22.9721739,34.5678261 L22.9721739,21.3286957 L26.6243478,21.3286957 L27.0078261,16.9095652 L22.9721739,16.9095652 L22.9721739,14.3713043 C22.9721739,13.3304348 23.1730435,12.9286957 24.1956522,12.9286957 L27.0443478,12.9286957 L27.0443478,8.32695652 L23.3921739,8.32695652 C19.4478261,8.32695652 17.6765217,10.1530435 17.6765217,13.3669565 L17.6765217,16.9095652 L14.9373913,16.9095652 L14.9373913,21.3834783 L17.6765217,21.3834783 L17.6765217,34.5678261 Z"
																			id="Path"
																			fill="#FFFFFF"
																		></path>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</svg>
											</button>
										)}
									/>
									<GoogleLogin
										clientId={googleClientId}
										onSuccess={onGoogleSucced}
										onFailure={onGoogleFail}
										cookiePolicy={"single_host_origin"}
										isSignedIn={false}
										render={(renderProps) => (
											<button
												className="mx-3"
												onClick={renderProps.onClick}
												style={{
													backgroundColor: "transparent",
													border: "none",
												}}
												disabled={renderProps.disabled}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="43"
													height="42"
													viewBox="0 0 43 42"
												>
													<g fill="none" fillRule="evenodd">
														<g fillRule="nonzero">
															<g>
																<g>
																	<g>
																		<g>
																			<path
																				fill="#4285F4"
																				d="M42.007 20.96c.006 5.992-2.555 11.7-7.036 15.68l-6.452-4.953c2.332-1.639 4.064-3.994 4.935-6.707h-12.41v-8.06h20.561c.27 1.33.405 2.683.402 4.04z"
																				transform="translate(-675.000000, -746.000000) translate(481.000000, -39.000000) translate(52.000000, 743.000000) translate(55.000000, 42.000000) translate(87.000000, 0.000000)"
																			/>
																			<path
																				fill="#34A853"
																				d="M34.97 36.695c-4.839 4.302-11.393 6.131-17.761 4.956C10.84 40.476 5.37 36.43 2.385 30.682l6.36-5.282c1.384 3.822 4.465 6.783 8.339 8.015 3.873 1.231 8.099.593 11.435-1.728l6.452 5.008z"
																				transform="translate(-675.000000, -746.000000) translate(481.000000, -39.000000) translate(52.000000, 743.000000) translate(55.000000, 42.000000) translate(87.000000, 0.000000)"
																			/>
																			<path
																				fill="#E94235"
																				d="M34.916 5.26l-5.684 5.611c-3.228-2.62-7.511-3.548-11.534-2.498-4.024 1.05-7.307 3.952-8.843 7.816l-6.36-5.154C5.543 5.394 10.999 1.451 17.312.326c6.313-1.124 12.795.692 17.604 4.934z"
																				transform="translate(-675.000000, -746.000000) translate(481.000000, -39.000000) translate(52.000000, 743.000000) translate(55.000000, 42.000000) translate(87.000000, 0.000000)"
																			/>
																			<path
																				fill="#FABB05"
																				d="M7.868 20.96c0 1.495.26 2.979.767 4.386l-6.25 5.336c-3.218-6.163-3.177-13.52.11-19.647l6.36 5.154c-.63 1.513-.965 3.132-.987 4.77z"
																				transform="translate(-675.000000, -746.000000) translate(481.000000, -39.000000) translate(52.000000, 743.000000) translate(55.000000, 42.000000) translate(87.000000, 0.000000)"
																			/>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</svg>
											</button>
										)}
									/>

									<AppleLogin
										clientId={appleID}
										callback={callbackApple}
										redirectURI={redirectURI}
										responseType="code id_token"
										responseMode="fragment"
										usePopup={true}
										scope="name email"
										render={(renderProps) => (
											<button
												className="mx-3"
												onClick={renderProps.onClick}
												style={{
													backgroundColor: "transparent",
													border: "none",
												}}
												disabled={renderProps.disabled}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="42"
													height="42"
													viewBox="0 0 42 42"
												>
													<g fill="none" fillRule="evenodd">
														<g>
															<g>
																<g>
																	<g>
																		<g transform="translate(-761.000000, -746.000000) translate(481.000000, -39.000000) translate(52.000000, 743.000000) translate(55.000000, 42.000000) translate(173.000000, 0.000000)">
																			<circle
																				cx="21"
																				cy="21"
																				r="21"
																				fill="#000"
																				fillRule="nonzero"
																			/>
																			<path
																				fill="#FFF"
																				d="M33.472 28.067c-1.114 3.013-4.291 8.071-7.432 7.487-2.173-.402-3.123-1.534-5.478-1.077-1.699.328-2.283.967-3.652 1.113-2.032-.066-3.872-1.216-4.821-3.013-.852-1.205-1.574-2.496-2.155-3.853-1.386-3.066-1.763-6.493-1.077-9.787.612-2.58 2.52-4.654 5.04-5.479 2.264-.63 4.684-.361 6.756.749 1.114.329 1.826-.146 3.305-.657 3.188-1.193 6.78-.232 8.948 2.392-4.583 3.287-4.419 8.911.804 11.65-.073.146-.11.128-.238.475z"
																			/>
																			<path
																				fill="#FFF"
																				d="M26.862 6.391c.025.402.025.804 0 1.206-.234 3.197-2.807 5.723-6.008 5.898-.027-.34-.027-.683 0-1.023.324-2.978 2.541-5.402 5.478-5.99.174-.041.351-.072.53-.09z"
																			/>
																		</g>
																	</g>
																</g>
															</g>
														</g>
													</g>
												</svg>
											</button>
										)}
									/>
								</div>
							</>
						)}
					</FormContent>
				</div>
				<div
					className="p-4 text-light text-center auth-texts"
					style={{ backgroundColor: "#003e93", borderRadius: "0 0 20px 20px" }}
				>
					{!isForgot ? (
						<>
							<p className="mb-1" style={{ fontWeight: "lighter" }}>
								{!isSignup
									? languages[currentLanguage]["auth_login_signup_instead"]
									: languages[currentLanguage]["auth_signup_login_instead"]}
							</p>
							<p className="mb-0" style={{ color: "#7cb9ff" }}>
								<span
									onClick={() => {
										if (!isSignup) {
											gtag_report_conversion(
												"https://" + window.location.host + "/explore/",
												types.ACCOUNT_CREATION_INITIATED
											);
											// FIREBASE LOG EVENT
											logEvent_("react_screen_signup_view");
										} else {
											logEvent_("react_screen_signup_view_left");
										}
										setIsSignup(!isSignup);
									}}
									style={{ textDecoration: "none", cursor: "pointer" }}
								>
									{!isSignup
										? languages[currentLanguage][
												"auth_login_signup_instead_button"
										  ]
										: languages[currentLanguage][
												"auth_signup_login_instead_button"
										  ]}
								</span>
							</p>
						</>
					) : (
						<>
							<div className="d-flex flex-row justify-content-between align-items-center">
								<p
									className="mb-0 text-center w-100"
									style={{ color: "#7cb9ff", cursor: "pointer" }}
									onClick={() => {
										setIsForgot(false);
										setIsSignup(true);
										logEvent_("react_screen_signup_view");
									}}
								>
									{
										languages[currentLanguage][
											"auth_login_signup_instead_button"
										]
									}
								</p>
								<div
									style={{
										backgroundColor: secondaryTxt,
										height: 40,
										width: 1,
									}}
								></div>
								<p
									className="mb-0 text-center w-100"
									style={{ color: "#7cb9ff", cursor: "pointer" }}
									onClick={() => {
										setIsForgot(false);
										setIsSignup(false);
										logEvent_("react_screen_signup_view_left");
									}}
								>
									{
										languages[currentLanguage][
											"auth_signup_login_instead_button"
										]
									}
								</p>
							</div>
						</>
					)}
				</div>
			</Form>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 20, left: 20 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserData: (payload) => dispatch(setUserData(payload)),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);

import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// COMPONENTS
import Header from "./utils/Header";
import Footer from "./utils/Footer";

// STORE
import { currentLang } from "../store/features/translation/selectors";
import { languages } from "../store/features/translation/Languages/";
import { showNotification } from "../store/features/notification/actions";

// ICONS
import bgHome from "../assets/bgf.jpg";

// STYLES
import { HomeTitle } from "./styled";
import { whiteColor } from "../constants/colors";
import { gtag_report_conversion, types } from "../helpers/googleTags";

const Home = (props) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	return (
		<div id="home-container" className="scrollable-container container-fluid">
			<div className="row p-0 m-0">
				<div className="col-12 p-0 d-flex flex-column justify-content-center align-items-center">
					<section className="home-header">
						<Header
							dispatch={props.dispatch}
							notifications={props.bookmarks}
							hadleDispatch={() => {}}
							page="home"
							showAuth={() => {}}
						/>
					</section>
					{/* /.home-header */}

					<section className="home-body">
						<div className="row mb-md-3 px-md-0 px-2">
							<div
								data-aos="fade-right"
								data-aos-duration="400"
								className="col-12 col-md-8 pl-0 pr-md-3 pr-0 mb-md-0 mb-3"
							>
								<div
									className="card-landing d-flex flex-column justify-content-between"
									style={{ backgroundColor: "#003e95" }}
								>
									<div style={{ flex: 1 }}>
										<HomeTitle className="text-light">
											{languages[currentLanguage]["home_livemap_tile_header"]}
										</HomeTitle>
										<p
											className="text-light dromon-l"
											style={{ fontSize: 14, fontWeight: 300 }}
										>
											{languages[currentLanguage]["home_livemap_tile_content"]}
										</p>
									</div>
									<div>
										<Link
											onClick={() =>
												gtag_report_conversion(
													"https://" + window.location.host + "/explore/",
													types.GO_TO_MAP
												)
											}
											to="/explore"
											style={{
												color: "#003e95",
												backgroundColor: whiteColor,
												padding: "12px 36px",
												borderRadius: 20,
												fontSize: 12,
												minWidth: 235,
												textDecoration: "none",
											}}
										>
											{languages[currentLanguage]["home_livemap_tile_button"]}
										</Link>
									</div>
								</div>
								{/* /.card-landing */}
							</div>
							{/* /.col */}

							<div
								data-aos="fade-left"
								data-aos-delay="400"
								data-aos-duration="400"
								className="col pl-md-1 pl-0 pr-0 mb-md-0 mb-3"
							>
								<div
									className="card-landing d-flex flex-column justify-content-between"
									style={{ backgroundColor: "#8a807a" }}
								>
									<div style={{ flex: 1 }}>
										<HomeTitle className="text-light">
											{languages[currentLanguage]["home_download_tile_header"]}
										</HomeTitle>
										<p
											className="text-light dromon-l"
											style={{ fontSize: 14, fontWeight: 300 }}
										>
											{languages[currentLanguage]["home_download_tile_content"]}
										</p>
									</div>
									<div>
										<a
											href="https://apps.apple.com/app/keeano/id1169524277"
											target="_blank" rel="noreferrer"
										>
											<img
												src={require("../assets/svg/download-appstore-ic.svg")}
												style={{ marginRight: 17.7 }}
											/>
										</a>
										<a
											href="https://play.google.com/store/apps/details?id=com.keeano.app"
											target="_blank" rel="noreferrer"
										>
											<img
												src={require("../assets/svg/download-googleplay-ic.svg")}
											/>
										</a>
									</div>
								</div>
								{/* /.card-landing */}
							</div>
							{/* /.col */}
						</div>

						<div
							{...(window.innerWidth > 770
								? {
										"data-aos-delay": "800",
										"data-aos-duration": "400",
										"data-aos": "fade-up",
								  }
								: {})}
							className="row mb-md-3 mb-0"
						>
							<div className="col px-0 mb-0">
								<div
									className="card-landing d-flex flex-column justify-content-end align-items-start"
									style={{
										backgroundImage: `url(${bgHome})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
									}}
								>
									<div>
										<Link
											to="/explore/coastview/2984/1078902208113673333/Tavolara-Gallura-Sardegna"
											style={{
												color: "#003e95",
												backgroundColor: whiteColor,
												padding: "12px 36px",
												borderRadius: 20,
												fontSize: 12,
												minWidth: 235,
												textDecoration: "none",
											}}
										>
											{languages[currentLanguage]["home_coastview_tile_button"]}
										</Link>
										{/* <button
                      style={{
                        color: "#003e95",
                        backgroundColor: whiteColor,
                        padding: "12px 36px",
                        borderRadius: 20,
                        fontSize: 12,
                        minWidth: 235,
                        textDecoration: "none",
                        marginLeft: 10,
                        border: "none",
                      }}
                      onClick={() =>
                        props.showNotification({
                          message:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                        })
                      }
                    >
                      Launch alert
                    </button> */}
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* /.home-body */}

					<Footer />
					{/* /.home-footer-wrapper */}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showNotification: (payload) => dispatch(showNotification(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import { Button, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { Sidebar, SidebarContent } from "./styled";
import { connect, useSelector } from "react-redux";
import backIcon from "../../assets/back_ic.svg";
// STORE
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages/";
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../constants/colors";
import ReviewItem from "./Components/ReviewItem";
import ReviewCard from "./Components/ReviewCard";
import { ApiClient } from "../../ApiClient";
import { useStyles } from "../map/Popup";
import { getAvg, getNumberOfDays } from "./helpers/functions";
import Alert from "@material-ui/lab/Alert";

const Reviews = (props) => {
	const currentLanguage = useSelector(currentLang);
	const classes = useStyles();
	const { show, handleBack, place_, handleNewReview } = props;
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(false);
	const [averageRate, setAverageRate] = useState(0);
	const [notice, setNotice] = React.useState({ type: "", content: "" });

	useEffect(() => {
		setLoading(true);
		if (place_?.ID) {
			ApiClient.getPlaceReviews(place_?.ID)
				.then((r) => {
					setReviews(r);
					setAverageRate(getAvg(r));
					setLoading(false);
					// //console.log("REVIEWS ==>", r);
				})
				.catch((error) => {
					setNotice({
						type: "ERROR",
						content: error.ErrorCode,
					});
					// //console.log("ERRORRR ==>", error);
					setLoading(false);
				});
		}
	}, [place_, averageRate]);
	return (
		<>
			<Sidebar show={show}>
				<SidebarContent id="sideBarContentReview">
					<Button
						onClick={handleBack}
						className="justify-content-start pt-3 w-100"
						style={{
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
						}}
					>
						<img
							alt="icon"
							src={backIcon}
							style={{ height: 16, width: 8, marginRight: 17 }}
						/>
						<div className="d-flex flex-column align-items-start">
							<h2
								className="m-0 p-0"
								style={{ fontSize: 21, color: formMainTxt }}
							>
								{languages[currentLanguage]["place_reviews_list_header"]}
							</h2>
						</div>
					</Button>
					{loading ? (
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<>
							<div className="container">
								<ReviewItem
									rate={averageRate}
									reviewers={reviews.length}
									showNext={false}
									handleClick={() => {}}
								/>
							</div>
							{reviews.map((review, idx) => (
								<ReviewCard
									key={idx}
									rate={review.Action.Rating ? review.Action.Rating : 0}
									name={`${
										review.User.FirstName ? review.User.FirstName : ""
									} ${review.User.LastName ? review.User.LastName : ""}`}
									days={getNumberOfDays(review.Action.Timestamp)}
									coverImage={review.Action.Url ? review.Action.Url : null}
									testimony={review.Action.Text}
									cover={review.User.Photo ? review.User.Photo : null}
								/>
							))}
						</>
					)}
					<div className="mb-4 container">
						<div className="row px-3">
							<Button
								title={
									languages[currentLanguage][
										"place_reviews_list_report_hover_button"
									]
								}
								onClick={handleNewReview}
								className="w-100"
								style={{
									backgroundColor: filterHighlightColor,
									color: whiteColor,
									fontFamily: "inherit",
									fontSize: 16,
									textTransform: "initial",
								}}
							>
								Write Review
							</Button>
						</div>
					</div>
				</SidebarContent>
			</Sidebar>
			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

Reviews.propTypes = {
	show: PropTypes.bool.isRequired,
	handleBack: PropTypes.func,
	place_: PropTypes.objectOf(PropTypes.any),
	handleNewReview: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);

const TileHelper = function () {
	const EarthRadius = 6378137;
	const MinLatitude = -85.05112878;
	const MaxLatitude = 85.05112878;
	const MinLongitude = -180;
	const MaxLongitude = 180;

	const mapSize = function (levelOfDetail) {
		return 256 << levelOfDetail;
	}

	const clip = function (n, minValue, maxValue) {
		return Math.min(Math.max(n, minValue), maxValue);
	}

	return {
		latlongToPixelXY: function (latitude, longitude, levelOfDetail) {
			latitude = clip(latitude, MinLatitude, MaxLatitude);
			longitude = clip(longitude, MinLongitude, MaxLongitude);

			const x = (longitude + 180) / 360;
			const sinLatitude = Math.sin(latitude * Math.PI / 180);
			const y = 0.5 - Math.log((1 + sinLatitude) / (1 - sinLatitude)) / (4 * Math.PI);

			const ms = mapSize(levelOfDetail);
			const pixelX = clip(x * ms + 0.5, 0, ms - 1);
			const pixelY = clip(y * ms + 0.5, 0, ms - 1);

			return {
				x: Math.floor(pixelX),
				y: Math.floor(pixelY)
			};
		},

		pixelXYToLatLong: function (pixelX, pixelY, levelOfDetail) {
			const ms = mapSize(levelOfDetail);
			const x = (clip(pixelX, 0, ms - 1) / ms) - 0.5;
			const y = 0.5 - (clip(pixelY, 0, ms - 1) / ms);

			const latitude = 90 - 360 * Math.atan(Math.exp(-y * 2 * Math.PI)) / Math.PI;
			const longitude = 360 * x;

			return {
				long: longitude,
				lat: latitude
			};
		},

        transform: function (x, y, z) {    
			const tranformedQueryz = 8;         
            return {
				x: TileHelper.parentTile(x, z, tranformedQueryz),
				y: TileHelper.parentTile(y, z, tranformedQueryz),
				z: tranformedQueryz
			};
		},

		parentTile: function (x, currentZoom, parentZoom) {
            var parentTile = ((x - x % Math.pow(2, (currentZoom - parentZoom))) / Math.pow(2, (currentZoom - parentZoom)));
            return parentTile;
        },

		getBoundingBox: function(x, y, z) {
			const XY = TileHelper.TileXYToPixelXY(x, y);
			var pixelX = XY.pixelX;
			var pixelY = XY.pixelY;
			const LatLong = TileHelper.pixelXYToLatLong(pixelX, pixelY, z);
			var latitudeN = LatLong.lat;
			var longitudeW = LatLong.long;
			const PixelXY = TileHelper.TileXYToPixelXY(x + 1, y + 1);
			pixelX = PixelXY.pixelX;
			pixelY = PixelXY.pixelY;
			const toLatLong = TileHelper.pixelXYToLatLong(pixelX, pixelY, z);
			var latitudeS = toLatLong.lat;
			var longitudeE = toLatLong.long;
			return {
				latitudeN: latitudeN,
				longitudeW: longitudeW,
				latitudeS: latitudeS,
				longitudeE: longitudeE
			};
		},

		TileXYToPixelXY: function (tileX, tileY) {
			return {
				pixelX: tileX * 256,
				pixelY: tileY * 256
			};
		},

		PixelXYToTileXY: function (pixelX, pixelY) {
			return {
				x: Math.floor(pixelX / 256),
				y: Math.floor(pixelY / 256)
			}
		}
	}
}();


export { TileHelper }

import React, { useEffect, useReducer, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { useSelector, connect } from "react-redux";
import branch from "branch-sdk";
import { Steps } from "intro.js-react";
import Alert from "@material-ui/lab/Alert";
import { ToastContainer, toast } from "react-toastify";

// CONFIGS
import { Keys } from "../../appConfig";
import { ShareLinkType } from "../../shareConfig";

// API
import { ApiClient } from "../../ApiClient";

// REDUCERS
import MapReducer from "./MapReducer";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages/";
import {
	userData,
	authView,
	sidebarProfile,
	sidebarEdit,
	sidebarSettings,
	sidebarVessels,
	userProfileId,
} from "../../store/features/user/selectors";
import {
	showAddReviews,
	showReviews,
	placeToReview,
} from "../../store/features/review/selectors";
import {
	currentTripItinerary,
	filtersSelected,
	globalWeatherBrandVisibility as globalWeatherBrandVisibility_,
	tripItineraryVisibility,
} from "../../store/features/map/selectors";

// ACTIONS
import {
	showUserAuthView,
	showUserProfile,
	showUserEdit,
	closeUserSidebars,
	setUserProfileId,
} from "../../store/features/user/actions";
import {
	setMapFiltersSelected,
	setTripItineraryVisibility,
	addTripNodeEditing,
	setTripItinerary,
	updateIndexTripDay,
} from "../../store/features/map/actions";
import {
	openAddReviewPage,
	openReviewPage,
	closeReviewPage,
} from "../../store/features/review/actions";

// HELPERS
import {
	deleteBoomark,
	REGISTRED,
	UNREGISTRED,
} from "./helpers/bookMarksHelper";
import { isEmpty, isInDev, mobileCheck } from "../../helpers/functions";
import { AuthService } from "../../AuthService";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

// CONSTANTS
import DEFAULT_TOAST_PROPS from "../../constants/tostifyDefaultProps";
import TRIP_USER_ROLES from "../../constants/tripUserRoles";

// COMPONENTS
import Map from "./Map";
import BookMarkList from "../BookmarkList";
import ContributionPage from "../ContrinutionPage";
import Auth from "../Auth";
import Header from "../utils/Header";
import Profile from "../Profile";
import PartnerProvisionList from "../Profile/ProvisionList";
import EditProfile from "../Profile/updateUser";
import UserVessels from "../UserVessels";
import UserSettings from "../UserSettings";
import ClosestCoastView from "../ClosestCoastView";
import FilterModal from "../FIlterModal";
import KeeanoSearch from "../keeanoSearch/";
import CoastView from "../CoastView";
import PlacesDetailsSideBar from "../PlacesDetailsSidebar";
import GWBottomBrand from "../GWBottomBrand";
import { handleFilters } from "./helpers/applyFilter";
import AddReview from "../PlacesDetailsSidebar/AddReview";
import Reviews from "../PlacesDetailsSidebar/Reviews";
import TripWizard from "../TripWizard";
import TripWizardBusiness from "../TripWizardBusiness";
import TripList from "../TripList";
import TripOverview from "../TripOverview";
import Crew from "../TripOverview/Crew.js";
import CrewList from "../TripOverview/CrewList";
import TransferList from "../TripOverview/TransferList";
import Itinerary from "../Itinerary";
import DayDraftNotes from "../Itinerary/DayDraftNotes";
import Chat from "../Chat";
import ProvisionList from "../TripOverview/ProvisionList";
import ProductsList from "../TripOverview/ProductsList";
import SetNightShelterModal from "../Itinerary/components/SetNightShelterModal";
import CustomDialog from "../utils/CustomDialog";

const Explore = ({
	setMapFiltersSelected,
	showUserAuthView,
	showUserProfile,
	openAddReviewPage,
	setUserProfileId,
	closeUserSidebars,
	closeReviewPage,
	setTripItineraryVisibility,
	setTripItinerary,
	addTripNodeEditing,
	updateIndexTripDay,
}) => {
	// INITS
	branch.init(Keys.branchKey);

	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	const myFilters = useSelector((state) => state.map.filters),
		tripItineraryVisibility_ = useSelector(tripItineraryVisibility),
		myFilterSelected = useSelector((state) => state.map.filtersSelected),
		filtersSelected_ = useSelector(filtersSelected),
		selectedUserProfileId = useSelector(userProfileId),
		globalWeatherBrandVisibility = useSelector(globalWeatherBrandVisibility_),
		currentUserData = useSelector(userData),
		placeToReview_ = useSelector(placeToReview),
		authViewVisibility = useSelector(authView),
		sidebarProfileVisibility = useSelector(sidebarProfile),
		showReviewsVisibility = useSelector(showReviews),
		showAddReviewsVisibility = useSelector(showAddReviews),
		sidebarEditVisibility = useSelector(sidebarEdit),
		sidebarSettingsVisibility = useSelector(sidebarSettings),
		sidebarVesselsVisibility = useSelector(sidebarVessels);
	const TRIP_ITINERARY = useSelector(currentTripItinerary);

	// DATA
	const steps = [
		{
			element: ".search-input",
			intro: languages[currentLanguage]["journey_onboarding_a"],
			position: "top-middle-aligned",
		},
		{
			element: ".filter-btn-b",
			intro: languages[currentLanguage]["journey_onboarding_b"],
			position: "top-middle-aligned",
		},
		{
			element: ".coastview-wrapper-c",
			intro: languages[currentLanguage]["journey_onboarding_c"],
			position: "top-middle-aligned",
		},
		{
			element: ".custom-location-btn",
			intro: languages[currentLanguage]["journey_onboarding_d"],
			position: "top-middle-aligned",
		},
		{
			element: ".custom-weather-toggle-btn",
			intro: languages[currentLanguage]["journey_onboarding_e"],
			position: "top",
		},
		{
			element: ".triplist-container-f",
			intro: languages[currentLanguage]["journey_onboarding_f"],
			position: "top-middle-aligned",
		},
		{
			element: ".triplist-container-g",
			intro: languages[currentLanguage]["journey_onboarding_g"],
			position: "top-middle-aligned",
		},
		{
			element: ".select-lang",
			intro: languages[currentLanguage]["journey_onboarding_h"],
			position: "top-middle-aligned",
		},
		{
			element: ".profile-btn-i",
			intro: languages[currentLanguage]["journey_onboarding_i"],
			position: "top-middle-aligned",
		},
	];
	const emptyState = {
		mapState: {
			moveTo: null,
			filters: null,
			layers: null,
			bookmarksBounds: null,
		},
		showFilters: false,
		showLayers: false,
		sidebarState: {
			center: {
				lng: null,
				lat: null,
				zoom: null,
			},
			selectedId: null,
		},
		sliderState: {
			id: null,
			lineid: null,
		},
		showSlider: false,
		placeDetailsState: {
			id: null,
		},
		showPlaceDetails: false,
		closestCVState: {
			id: null,
		},
		showClosestCV: false,
	};

	// REDUCERS
	const [state, dispatch] = useReducer(MapReducer, emptyState);

	// STATES
	const [showModal, setShowModal] = useState(false),
		[zoom, setZoom] = useState(null),
		[mode, setMode] = useState(
			AuthService.isUserLogedIn() ? REGISTRED : UNREGISTRED
		),
		[enableOnboard, setEnableOnboard] = useState(false),
		[showSignupScreen, setShowSignupScreen] = useState(false),
		[showChat, setShowChat] = useState(false),
		[placeAdded, setPlaceAdded] = React.useState(false),
		[initial, setInitial] = useState({}),
		[dataTrip, setDataTrip] = useState(null),
		[bookmarks] = useState([]),
		[dataTripToFilter, setDataTripToFilter] = useState(null),
		[trips, setTrips] = useState([]),
		[, setLoading] = useState(false),
		[page, setPage] = useState("explore"),
		[showBookmarks, setShowBookmarks] = useState(false),
		[showTripList, setShowTripList] = useState(false),
		[showWizard, setShowWizard] = useState(false),
		[showWizardBusiness, setShowWizardBusiness] = useState(false),
		[typeWizardBusiness] = useState("template"),
		[currentTripId, setCurrentTripId] = useState(0),
		[currentTripGUID, setCurrentTripGUID] = useState(null),
		[currentTripType, setCurrentTripType] = useState(null),
		[showTripOverView, setShowTripOverView] = useState(false),
		[showCrew, setShowCrew] = useState(false),
		[showCrewList, setShowCrewList] = useState(false),
		[showTransferList, setShowTransferList] = useState(false),
		[showProvisionList, setShowProvisionList] = useState(false),
		[showProductsList, setShowProductsList] = useState(false),
		[currentTrip, setCurrentTrip] = useState({}),
		[notice, setNotice] = React.useState({ type: "", content: "" }),
		[showContrib, setShowContrib] = useState(false),
		[tmpProducts, setTmpProducts] = useState([]),
		[location, setLocation] = useState([]),
		[onSetNightShelter, setOnSetNightShelter] = useState(null);

	const [productListItems, setProductListItems] = useState({});

	const [crewDeadlineReached, setCrewDeadlineReached] = useState(false);
	const [provisionDeadlineReached, setProvisionDeadlineReached] =
		useState(false);
	const [transferDeadlineReached, setTransferDeadlineReached] = useState(false);
	const [customDialogProps, setCustomDialogProps] = useState({
		visible: false,
	});

	// FUNCTIONS
	const hideModal = () => {
		setShowModal(false);
		setInitial({});
	};
	const closeAddPlace = () => {
		setShowContrib(false);
	};

	const handleEdit = (data) => {
		setDataTrip(data);
		setShowTripOverView(false);
		setCurrentTripId(0);
		if (data.SubType) setShowWizardBusiness(true);
		else setShowWizard(true);
		logEvent_("react_screen_create_trip_view");
	};

	const cloneToBusiness = (data) => {
		data.isClone = true;
		setDataTrip(data);
		setShowTripOverView(false);
		setCurrentTripId(0);
		if (AuthService.isUserPartner()) setShowWizardBusiness(true);
	};

	const openChat = () => {
		if (AuthService.isUserLogedIn()) {
			ApiClient.getCometChatAuthToken().then((res) => {
				if (res && !res?.ErrorCode && !res.ErrorMessage) {
					CometChat.login(res.data[0].authToken).then(
						(user) => {
							if (isInDev()) console.log("Login Successful:", { user });

							setShowChat(true);
						},
						(error) => {
							if (isInDev())
								console.log("Login failed with exception:", { error });
						}
					);
				} else {
					if (isInDev()) console.log("ERROR ==>", res);
				}
			});
		}
	};

	const handleCoastView = (lineid, id) => {
		setPage("coastView");
		dispatch({
			type: "updateslider",
			photo: {
				id: id,
				lineid: lineid,
			},
		});
	};

	const handleUser = async (username, urlParts) => {
		const _REG = new RegExp(/-/, "ig");
		let formattedUsername =
			typeof username === "string"
				? username.replace(_REG, " ").toLowerCase()
				: "";
		let _currentUserID = currentUserData?.ID;

		if (formattedUsername !== currentUserData?.UserName?.toLowerCase()) {
			const OTHER_USER = await ApiClient.getOtherUser(formattedUsername);

			if (!OTHER_USER.ErrorCode) {
				_currentUserID = OTHER_USER?.ID;
				setUserProfileId(OTHER_USER.ID);
				if (urlParts[4] && urlParts[4] === "trips") {
					const OTHER_USER_TRIPS = await ApiClient.visitOtherUserTrips(
						formattedUsername,
						OTHER_USER?.Type === "partner"
					);
					setTrips(OTHER_USER_TRIPS);
				}
			}
		}

		setUserProfileId(_currentUserID);
		if (urlParts.length > 5) {
			setIsFromShare(true);

			switch (urlParts[4]) {
				case "trips":
					if ((urlParts[5] === "b" || urlParts[5] === "c") && urlParts[7]) {
						let guid = urlParts[7];

						const onLoadTrip = (_data) => {
							if (_data && _data.Id && !_data?.ErrorCode) {
								setTimeout(() => {
									const TRIP_USER =
										_data?.Members?.filter(
											(u) => u.UserId === currentUserData?.ID
										)[0] ?? TRIP_USER_ROLES.NO_MEMBER;

									setCurrentTripId(_data.Id);
									if (urlParts[8] === "itinerary") {
										setTripItinerary({
											...TRIP_ITINERARY,
											data: _data,
											visibility: true,
											canEdit:
												TRIP_USER?.Role === TRIP_USER_ROLES.CREATOR ||
												TRIP_USER?.Role === TRIP_USER_ROLES.SKIPPER ||
												TRIP_USER?.Role === TRIP_USER_ROLES.COCREATOR ||
												TRIP_USER?.Role === TRIP_USER_ROLES.SUPERPASSENGER ||
												TRIP_USER?.Role === TRIP_USER_ROLES.PASSENGERSKIPPER,
										});

										if (urlParts[9] && typeof urlParts[9] === "string") {
											const DAY_PARTS = urlParts[9].split("-");
											setTimeout(() => {
												updateIndexTripDay(parseInt(DAY_PARTS[1]) - 1 ?? 0);
											}, 2000);
										}

										return;
									}

									openTripId(_data.Id);
								}, 1000);
							}
							setLoading(false);
						};

						const _TRIP_DATA = await ApiClient.getTripFromGUID(
							guid,
							urlParts[5] === "b"
						);

						if (_TRIP_DATA && _TRIP_DATA.Id && !_TRIP_DATA?.ErrorCode) {
							onLoadTrip(_TRIP_DATA);
							setLoading(false);
							return;
						}
					}
					break;

				default:
					handleCasePendingOpenTripGUID();
					return;
			}
		}

		if (urlParts[4] && urlParts[4] === "trips") {
			closeUserSidebars();
			setShowTripList(true);
			return;
		}

		showUserProfile();
		setShowModal(false);
		setTripItineraryVisibility(false);
	};

	const handleShowBookmarks = (id, isBusiness = false) => {
		if (isInDev())
			console.log("handleShowBookmarks isBusiness ===>", isBusiness);
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.TRIP_LIST_VIEWED
		);
		if (id === currentUserData.ID) {
			// Show my bookmarks
			reloadTripList();
			setShowTripList(true);
			closeUserSidebars();
		} else {
			// SHOW OTHER USER TRIPLIST
			ApiClient.visitOtherUserTripsFromId(id, isBusiness).then((tr) => {
				setTrips(tr);
				setShowTripList(true);
				closeUserSidebars();
			});
		}
	};

	const displayOverview = (id) => {
		setCurrentTripId(id);
		setShowTripOverView(true);
		setCurrentTripGUID(null);
		setCurrentTripType(null);
	};

	const deleteTrip = async (trip) => {
		setTrips(null);
		setTripItinerary({
			visibility: false,
			canEdit: false,
			data: null,
			selectedDay: {
				index: null,
				startTime: "08:30",
				inEditing: false,
				launchPoint: null,
				tripNodes: [],
				shelterPoint: null,
				selectedNode: {
					visibility: false,
					index: null,
					note: null,
				},
				forceLastNode: false,
			},
			daysList: [],
		});

		if (
			trip.State === "onboarding" ||
			trip.State === "live" ||
			trip.State === "completed" ||
			trip.State === "canceled" ||
			trip.State === "cancelled"
		) {
			//const isDelete = trip.State === "onboarding";
			await ApiClient.deleteBusinessTrip(trip.Id).then((res) => {
				if (res?.ErrorCode && res?.ErrorMessage) {
					setNotice({
						content: res.ErrorMessage,
						type: "ERROR",
					});
					return;
				}
				toast.success("Trip deleted!", DEFAULT_TOAST_PROPS);
				reloadTrips();
			});
			return;
		}
		if (trip.SubType === "template") {
			await ApiClient.deleteTripTemplate(trip.Id).then((res) => {
				toast.success("Template trip deleted!", DEFAULT_TOAST_PROPS);
				reloadTrips();
			});
			return;
		}
		await ApiClient.deleteTrip(trip.Id).then((res) => {
			toast.success("Trip deleted!", DEFAULT_TOAST_PROPS);
			reloadTrips();
		});
	};

	const reloadTrips = async () => {
		if (AuthService.isUserPartner()) {
			setTrips(null);
			setCurrentTrip(null);
			return;
		}

		const getTrips = async () => {
			const trips = await ApiClient.getMyCreatedTrips();
			if (!trips || trips.ErrorCode || trips.ErrorMessage) return [];
			return trips;
		};

		getTrips().then((tr) => {
			setTrips(tr);
			setCurrentTrip(null);
		});
	};

	const handleUpdate = () => {
		setLoading(true);
		setMode(AuthService.isUserLogedIn() ? REGISTRED : UNREGISTRED);
		let mode_ = AuthService.isUserLogedIn() ? REGISTRED : UNREGISTRED;
		if (mode_ === REGISTRED) {
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const handleOnClick = (placeId, name, type) => {
		dispatch({
			type: "updateSidebarPlacesState",
			place: {
				id: placeId,
				namelatin: name,
			},
		});
	};

	const handleBack = () => {
		dispatch({
			type: "updateSidebarPlacesState",
			place: {
				id: 0,
				namelatin: "",
			},
		});
		dispatch({
			type: "updateSidebarPlacesState",
			place: {
				id: placeToReview_.ID,
				namelatin: placeToReview_.Name.Latin
					? placeToReview_.Name.Latin
					: placeToReview_.Type,
			},
		});
		closeReviewPage();
	};

	const handleDelete = (placeId) => {
		setLoading(true);
		if (mode === REGISTRED) {
			ApiClient.deleteBookMark(placeId)
				.then((res) => {
					setNotice({
						type: "SUCCESS",
						content:
							languages[currentLanguage]["nexttrip_place_removed_message"],
					});
					handleUpdate();
					setLoading(false);
					setTimeout(() => {
						setNotice({ type: "", content: "" });
					}, 1500);
				})
				.catch((err) => {
					setNotice({
						type: "ERROR",
						content:
							languages[currentLanguage]["common_error_something_went_wrong"],
					});
					setLoading(false);
					setTimeout(() => {
						setNotice({ type: "", content: "" });
					}, 1500);
				});
		} else {
			deleteBoomark(placeId, mode);
			handleUpdate();
			setNotice({
				type: "SUCCESS",
				content: languages[currentLanguage]["nexttrip_place_removed_message"],
			});
			handleUpdate();
			setLoading(false);
			setTimeout(() => {
				setNotice({ type: "", content: "" });
			}, 1500);
		}
	};

	const onShowAuth = (currentUserID = null) => {
		if (AuthService.isUserLogedIn()) {
			dispatch({
				type: "closeSidebarPlaces",
			});
			setUserProfileId(currentUserID);
			showUserProfile();
			setShowModal(false);
			setShowTripOverView(false);
			setShowTripList(false);
			setTripItineraryVisibility(false);
		} else {
			setIsAuth(true);
			showUserAuthView();
		}
	};

	const handleOpenAddPlace = (loc) => {
		if (AuthService.isUserLogedIn()) {
			closeUserSidebars();
			closeReviewPage();
			setShowBookmarks(false);
			setLocation(loc);
			setShowContrib(true);
		} else {
			onShowAuth();
		}
	};

	const openTrip = (guid, type = null) => {
		setCurrentTripGUID(guid);
		setCurrentTripType(type);
		setShowTripOverView(true);
	};

	const openTripId = (id) => {
		setCurrentTripId(id);
		const type = id >= 1000000000 ? "business" : "community";
		setCurrentTripType(type);
		setShowTripOverView(true);
	};

	const reloadTripList = () => {
		const getTrips = async () => {
			const trips = await ApiClient.getMyCreatedTrips();
			if (!trips || trips.ErrorCode || trips.ErrorMessage) return [];
			return trips;
		};

		getTrips().then((tr) => {
			setTrips(tr);
			setShowWizardBusiness(false);
			setShowWizard(false);
			setCurrentTrip(null);
		});
	};

	const openTrips = (data) => {
		setTrips(null);
		setShowTripList(false);
		if (data?.length === 1) {
			// OPEN THE TRIP OVERVIEW
			setCurrentTripId(data[0].Id);
			setShowTripOverView(true);
			setCurrentTripGUID(null);
		} else if (data.length > 1) {
			// OPEN TRIP LIST
			setDataTripToFilter(data);
			setShowTripList(true);
		}
	};

	const onOpenTripOverview = (trip) => {
		if (typeof trip === "string") setCurrentTripId(JSON.parse(trip).Id);
		else setCurrentTrip(trip);
		setShowTripOverView(true);
		setCurrentTripGUID(null);
	};

	const onCloseTripOverview = (isBusiness = false) => {
		if (isInDev()) console.log("isBusiness ====> ", isBusiness);
		var new_url = "https://" + window.location.host + "/explore/";
		window.history.pushState(null, null, new_url);

		const getTrips = async () => {
			const trips = await ApiClient.visitOtherUserTripsFromId(
				selectedUserProfileId,
				isBusiness
			);
			if (!trips || trips.ErrorCode || trips.ErrorMessage) return [];
			return trips;
		};
		getTrips().then((tr) => {
			setTrips(tr);
			setCurrentTrip(null);
		});
		setCurrentTripId(0);
		setCurrentTripGUID(0);
		setShowTripOverView(false);
	};

	const handleTripOverviewSidebars = (
		props = {
			bookmarks: false,
			chat: false,
			contrib: false,
			crew: false,
			crewList: false,
			itinerary: false,
			productsList: false,
			provisionList: false,
			transferList: false,
			tripOverview: false,
			tripList: false,
			tripWizard: false,
			tripWizardBusiness: false,
			authView: false,
			sidebarProfile: false,
			sidebarEdit: false,
			sidebarSettings: false,
			sidebarVessels: false,
			sidebarPartnerProvision: false,
		}
	) => {
		setShowTripOverView(props?.tripOverview || false);
		if (!props?.tripOverview) setCurrentTripId(0);
		setShowCrew(props?.crew || false);
		setShowCrewList(props?.crewList || false);
		setShowProvisionList(props?.provisionList || false);
		setShowTransferList(props?.transferList || false);
		setShowProductsList(props?.productsList || false);
		setTripItinerary(props?.itinerary || false);
		setShowTripList(props?.tripList || false);
		setShowChat(props?.chat || false);
		setShowBookmarks(props?.bookmarks || false);
		setShowWizard(props?.tripWizard || false);
		setShowWizardBusiness(props?.tripWizardBusiness || false);
		setShowContrib(props?.contrib || false);
		closeUserSidebars();
		dispatch({ type: "closeSidebarPlaces" });
	};

	const onShowTripList = () => {
		if (!AuthService.isUserLogedIn()) return onShowAuth();
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.TRIP_LIST_VIEWED
		);
		handleTripOverviewSidebars();
		dispatch({ type: "closeSidebarPlaces" });
		setShowModal(false);
		closeUserSidebars();
		setShowTripOverView(false);
		setTripItineraryVisibility(false);
		if (!showTripList) reloadTripList();
		setShowTripList(!showTripList);
		setUserProfileId(currentUserData.ID);
	};

	const onCloseTripOverviewSidebars = (props = { openTripOverview: true }) => {
		handleTripOverviewSidebars();
		setShowTripOverView(props?.openTripOverview || true);
		setEnableOnboard(false);
		setCurrentTripId(currentTrip?.Id);
	};

	const handleCasePendingInvite = () => {
		// CHECK IF THERE IS ANY PREVIOUS INVITE GUID
		if (
			window.localStorage.getItem("currentInviteGuid") &&
			AuthService.isUserLogedIn()
		) {
			const itemSaved = JSON.parse(
				window.localStorage.getItem("currentInviteGuid")
			);
			const type =
				parseInt(itemSaved.id) >= 1000000000 ? "business" : "community";
			ApiClient.acceptTripInviteFromGUID(itemSaved.guid, type === "business")
				.then((_data) => {
					if (_data !== null) {
						setTimeout(() => {
							setCurrentTripId(_data.id);
							const type = _data.id >= 1000000000 ? "business" : "community";
							if (_data?.IsPublic) {
								openTrip(_data?.ShareGUID, type);
							} else {
								openTripId(_data.Id);
							}
						}, 2500);
					}
					setLoading(false);
					// REMOVE THE INVIT GUID
					window.localStorage.removeItem("currentInviteGuid");
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};

	const handleCasePendingOpenTripGUID = () => {
		// CHECK IF THERE IS ANY PREVIOUS INVITE GUID
		if (
			window.localStorage.getItem("tripGUID") &&
			AuthService.isUserLogedIn()
		) {
			const itemSaved = JSON.parse(window.localStorage.getItem("tripGUID"));
			if (itemSaved.tripGUID === null) {
				return;
			}
			ApiClient.getTripFromGUID(itemSaved.tripGUID)
				.then((_data) => {
					if (_data !== null && _data.Id !== null) {
						setTimeout(() => {
							setCurrentTripId(_data.Id);
							openTripId(_data.Id);
						}, 2500);
					}
					setLoading(false);
					// REMOVE THE INVIT GUID
					window.localStorage.removeItem("tripGUID");
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};

	const setIsFromShare = (value) => {
		window.localStorage.setItem("isSharing", value);
	};

	const setIsAuth = (value) => {
		window.localStorage.setItem("isAuth", value);
	};

	// EFFECTS
	useEffect(() => {
		if (!window.localStorage.getItem("isVisited")) {
			setTimeout(() => {
				var isSharing = window.localStorage.getItem("isSharing");
				var isAuth = window.localStorage.getItem("isAuth");
				var components = window.location.pathname.split("/");

				if (components.length > 3) {
					if (
						components[2] === "coastview" ||
						components[2] === "places" ||
						components[2] === "user" ||
						components[2] === "filter" ||
						currentTripId !== 0 ||
						currentTripGUID ||
						(isSharing && isSharing === "true")
					) {
						setIsFromShare(false);
						return;
					}
				}

				if (isAuth !== null && isAuth === "true") {
					setIsAuth(false);
					return;
				}

				if (!mobileCheck() && AuthService.isUserLogedIn()) {
					setEnableOnboard(true);

					window.localStorage.setItem(
						"isVisited",
						JSON.stringify({ isVisited: true })
					);
				}
			}, 4000);
		}
	}, [enableOnboard]);

	useEffect(() => {
		setLoading(true);
		var components = window.location.pathname.split("/");
		if (components.length > 2) {
			setIsFromShare(true);
			if (isInDev()) console.log("handleCoastView ===>", components);

			switch (components[2]) {
				case "places":
					let guid = components[4];

					dispatch({
						type: "updateSidebarPlacesState",
						place: {
							id: guid,
						},
					});
					break;
				case "filter":
					let vessel = components[3];
					let need = components[4];
					let placeTypes = [];
					let tags = [];
					if (isEmpty(need) || isEmpty(vessel)) {
						return;
					}

					if (components[5] && components[5] !== "") {
						const SPLITTED_TYPES = components[5].split("-");
						if (SPLITTED_TYPES.length > 0 && !SPLITTED_TYPES[0].includes("@")) {
							placeTypes = SPLITTED_TYPES;
						}
					}

					if (components[6] && components[6] !== "") {
						const SPLITED_TAGS = components[6].split("-").map((tag) => {
							return tag
								.replaceAll("place_", "uri:pl:tag:")
								.replaceAll("trip_", "uri:trip:tag:");
						});
						if (SPLITED_TAGS.length > 0 && !SPLITED_TAGS[0]?.includes("@")) {
							tags = SPLITED_TAGS;
						}
					}

					const correctCategoryTag = myFilters[vessel][need].categoryTag;
					setInitial({ vessel, need, placeTypes, tags });
					setMapFiltersSelected(
						handleFilters(
							{
								...myFilterSelected,
								needs: need ? need : "",
								places: placeTypes,
								vesselType: vessel ? vessel : "",
								needCategoryTag: correctCategoryTag,
								tags: tags,
							},
							myFilters
						)
					);
					break;
				case "coastview":
					let lineid = parseInt(components[3]);
					let id = components[4];
					if (lineid && id) {
						if (mobileCheck()) {
							setTimeout(() => {
								handleCoastView(lineid, id);
							}, 2500);
						} else {
							handleCoastView(lineid, id);
						}
					}
					break;
				case "user":
					handleUser(components[3], components);
					break;
				case "trip_share":
					setTimeout(() => {
						let shareGuid = components[3];
						let id = components[4];
						const type = id >= 1000000000 ? "business" : "community";
						setCurrentTripId(id);
						openTrip(shareGuid, type);
					}, 2000);
					break;
				case "trip_invite":
					setTimeout(() => {
						if (AuthService.isUserLogedIn()) {
							let inviteGuid = components[3];
							let id = parseInt(components[4]);
							const type = id >= 1000000000 ? "business" : "community";
							ApiClient.acceptTripInviteFromGUID(
								inviteGuid,
								type === "business"
							)
								.then((_data) => {
									if (_data !== null) {
										setTimeout(() => {
											setCurrentTripId(id);
											if (_data?.IsPublic) {
												openTrip(_data?.ShareGUID, type);
											} else {
												openTripId(_data.Id);
											}
										}, 1000);
									}
									setLoading(false);
								})
								.catch((err) => {
									setLoading(false);
								});
						} else {
							setIsAuth(true);
							// SAVE THE INVITE GUID IN THE LOCAL STORAGE
							window.localStorage.setItem(
								"currentInviteGuid",
								JSON.stringify({ guid: components[3], id: components[4] })
							);
							// Display login alert
							setShowSignupScreen(true);
							onShowAuth();
							setTimeout(() => {
								setNotice({ type: "", content: "" });
							}, 2000);
						}
					}, 2000);
					break;
				default:
					handleCasePendingInvite();
					break;
			}
			if (
				/^@-?\d{1,}\.?\d{1,},-?\d{1,}\.?\d{1,},\d{1,}z$/.test(
					components[components.length - 1]
				)
			) {
				const infos = components[components.length - 1].split(",");
				const long = parseFloat(infos[0].slice(1));
				const lat = parseFloat(infos[1]);
				const zoom_ = parseInt(infos[2]);
				setZoom({
					long,
					lat,
					zoom: zoom_,
				});
			}
		}

		// TRIPS

		// BRANCH
		branch.data(function (err, data) {
			if (
				data !== null &&
				data.data_parsed !== null &&
				data.data_parsed.$canonical_identifier !== null
			) {
				if (data.data_parsed.platform !== null) {
					if (data.data_parsed.platform === "web") {
						logEvent_("share_link_from_web_to_web");
					} else if (
						data.data_parsed.platform === "mobile_ios" ||
						data.data_parsed.platform === "mobile_android"
					) {
						logEvent_("share_link_from_mobile_to_web");
					}
				}

				let identifier = data.data_parsed.$canonical_identifier;
				let compose = identifier.split("/");
				if (compose.length > 0) {
					let shareType = compose[0];
					setIsFromShare(true);
					switch (shareType) {
						case "places":
							logEvent_("react_app_deeplink_place_opened");
							let guid = compose[1];
							dispatch({
								type: "updateSidebarPlacesState",
								place: {
									id: guid,
								},
							});
							break;
						case "coast_view_photos":
							logEvent_("react_app_deeplink_coastviewphoto_opened");

							if (mobileCheck()) {
								let lineid = parseInt(compose[1]);
								let id = compose[2];
								if (lineid && id) {
									setTimeout(() => {
										handleCoastView(lineid, id);
									}, 3500);
								}
							} else if (window.localStorage.getItem("isVisited")) {
								let lineid = parseInt(compose[1]);
								let id = compose[2];
								if (lineid && id) {
									handleCoastView(lineid, id);
								}
							}
							break;
						case "trip_share":
							if (data.data_parsed && data.data_parsed.id) {
								logEvent_("react_app_deeplink_trip_share_opened", {
									tripId: data.data_parsed.id,
								});
								setTimeout(() => {
									setCurrentTripId(data.data_parsed.id);
									const type =
										data.data_parsed.id >= 1000000000
											? "business"
											: "community";
									let shareGuid = compose[1];
									openTrip(shareGuid, type);
								}, 2500);
							}
							break;
						case "trip_invite":
							if (AuthService.isUserLogedIn()) {
								logEvent_("react_app_deeplink_trip_invite_opened", {
									tripId: data.data_parsed.id,
								});
								let inviteGuid = compose[1];
								const type =
									data.data_parsed.id >= 1000000000 ? "business" : "community";
								ApiClient.acceptTripInviteFromGUID(
									inviteGuid,
									type === "business"
								)
									.then((_data) => {
										if (_data !== null && !_data.ErrorCode) {
											setTimeout(() => {
												setCurrentTripId(data.data_parsed.id);
												if (_data?.IsPublic) {
													openTrip(_data?.ShareGUID, type);
												} else {
													openTripId(_data.Id);
												}
											}, 2500);
										} else {
										}
										setLoading(false);
									})
									.catch((err) => {
										setLoading(false);
									});
							} else {
								setIsAuth(true);
								// Display login alert
								// SAVE THE INVITE GUID IN THE LOCAL STORAGE
								window.localStorage.setItem(
									"currentInviteGuid",
									JSON.stringify({ guid: compose[1], id: data.data_parsed.id })
								);
								onShowAuth();
								setShowSignupScreen(true);
								setTimeout(() => {
									setNotice({ type: "", content: "" });
								}, 2000);
							}
							break;
						case ShareLinkType.user + "s":
							if (compose[1]) {
								handleTripOverviewSidebars();
								setUserProfileId(parseInt(compose[1]));
								showUserProfile();
							}
							break;
						default:
							break;
					}
				}
			}
		});

		if (mode === REGISTRED) {
			setLoading(false);
		}

		// GET TRIP LISTS
		const getTrips = async () => {
			const trips = await ApiClient.getMyCreatedTrips();
			if (!trips || trips.ErrorCode || trips.ErrorMessage) return [];
			return trips;
		};

		getTrips().then((tr) => {
			setTrips(tr);
			setCurrentTrip(null);
		});
	}, []);

	return (
		<div
			style={{
				height: "100vh",
				width: "100vw",
				overflow: "hidden",
				position: "relative",
			}}
		>
			<Map
				enableOnboard={enableOnboard}
				openAddPlace={handleOpenAddPlace}
				closeSideBars={() => closeReviewPage()}
				zoom={zoom}
				placeAdded={placeAdded}
				state={state.mapState}
				dispatch={dispatch}
				showAuth={onShowAuth}
				bookmarks={bookmarks?.length || 0}
				setUpdate={handleUpdate}
				bookmarksListState={showBookmarks}
				showBookmarks={() => {
					setShowBookmarks(!showBookmarks);
				}}
				tripOverviewVisibility={showTripOverView}
				openTrips={(data) => openTrips(data)}
				openOverv={(data) => onOpenTripOverview(data)}
				openWizard={() => {
					if (AuthService.isUserPartner()) setShowWizardBusiness(true);
					else setShowWizard(true);
					logEvent_("react_screen_create_trip_view");
				}}
			/>

			<TripWizard
				dataTrip={dataTrip}
				visible={showWizard}
				displayOverview={(_id) => {
					handleTripOverviewSidebars();
					displayOverview(_id);
				}}
				onCancel={() => reloadTripList()}
				onCloseTripList={() => {
					setTrips(null);
					setShowTripList(false);
				}}
				onClose={() => {
					setShowWizard(false);
				}}
			/>

			<TripWizardBusiness
				typeWizardBusiness={typeWizardBusiness}
				dataTrip={dataTrip}
				visible={showWizardBusiness}
				displayOverview={(_id) => {
					handleTripOverviewSidebars();
					displayOverview(_id);
				}}
				onCancel={() => reloadTripList()}
				onCloseTripList={() => {
					setTrips(null);
					setShowTripList(false);
				}}
				onClose={() => {
					setShowWizardBusiness(false);
				}}
			/>

			<Header
				dispatch={dispatch}
				hadleDispatch={() => {}}
				page="explore"
				showTripList={onShowTripList}
				enableOnbroading={() => {
					// if (!enableOnboard) {
					// CLOSE ALL THE SIDE BARS
					setShowModal(false);
					closeUserSidebars();
					setShowTripOverView(false);
					setTripItineraryVisibility(false);
					setShowTripList(false);
					setShowModal(false);
					dispatch({ type: "closeSidebarPlaces" });
					// }
					setTimeout(() => {
						setUserProfileId(null);
						setEnableOnboard(!enableOnboard);
						setShowModal(false);
						closeUserSidebars();
						setShowTripOverView(false);
						setTripItineraryVisibility(false);
						setShowTripList(false);
						setShowModal(false);
						dispatch({ type: "closeSidebarPlaces" });
					}, 1000);
				}}
				showTripItinerary={() => {
					if (!AuthService.isUserLogedIn()) return onShowAuth();
					gtag_report_conversion(
						"https://" + window.location.host + "/explore/",
						types.TRIP_LIST_VIEWED
					);
					dispatch({ type: "closeSidebarPlaces" });
					setShowModal(false);
					closeUserSidebars();
					setShowTripOverView(false);
					setShowTripList(false);
					setTripItineraryVisibility(!tripItineraryVisibility_);
				}}
				showAuth={onShowAuth}
			/>

			<div
				className={
					authViewVisibility && !AuthService.isUserLogedIn()
						? "d-block auth"
						: "d-none"
				}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: "transparent",
					zIndex: "999",
				}}
			>
				<Auth
					showSignupScreen={showSignupScreen}
					closeSidebar={closeUserSidebars}
					setUpdate={handleUpdate}
					// showNoticeAuth={showNoticeAuth}
				/>
			</div>

			<div id="map-searchbox">
				<KeeanoSearch
					dispatch={dispatch}
					bgActive={filtersSelected_.needs}
					selectedTags={
						filtersSelected_.places.length + filtersSelected_.tags.length
					}
					showModal={() => {
						setShowModal(true);
					}}
					openWizard={() => {
						if (AuthService.isUserPartner()) setShowWizardBusiness(true);
						else setShowWizard(true);
						logEvent_("react_screen_create_trip_view");
					}}
					addTripNodeEditing={(place = {}, bool = false, fun = () => {}) => {
						if (!AuthService.isUserLogedIn()) return onShowAuth();
						addTripNodeEditing(place, bool, fun);
					}}
				/>
			</div>

			<div style={{ display: state.showSlider ? "block" : "none", zIndex: 10 }}>
				<CoastView
					state={state.sliderState}
					dispatch={dispatch}
					bookmarks={bookmarks ? bookmarks : []}
					showAuth={onShowAuth}
					setUpdate={handleUpdate}
					showBookmarks={() => {
						gtag_report_conversion(
							"https://" + window.location.host + "/explore/",
							types.TRIP_LIST_VIEWED
						);
						closeUserSidebars();
					}}
				/>
			</div>

			{/* BOOKMARKS */}
			<BookMarkList
				dispatch={dispatch}
				visible={showBookmarks}
				handleAuth={onShowAuth}
				handleClick={handleOnClick}
				bookmarks={bookmarks ? bookmarks : []}
				handleDelete={handleDelete}
				closeSidebar={() => setShowBookmarks(false)}
			/>

			{/** ITINERARY */}
			<Itinerary
				showFilter={(data) => {
					setTripItineraryVisibility(false);
					if (data.vessel && data.need) {
						const correctCategoryTag =
							myFilters[data.vessel][data.need].categoryTag;
						setInitial({
							vessel: data.vessel,
							need: data.need,
							needCategoryTag: correctCategoryTag,
						});
					}
					setShowModal(true);
				}}
				closeAllSideViews
				dispatch={dispatch}
				displayOverview={displayOverview}
				openWizard={() => {
					if (AuthService.isUserPartner()) setShowWizardBusiness(true);
					else setShowWizard(true);
					logEvent_("react_screen_create_trip_view");
				}}
				setOnSetNightShelter={(fun) => {
					setOnSetNightShelter(() => fun);
				}}
			/>

			{/** NOTES */}
			<DayDraftNotes />

			<TripList
				visible={showTripList}
				dispatch={dispatch}
				handleAuth={onShowAuth}
				handleClick={handleOnClick}
				bookmarks={trips ? trips : []}
				handleDelete={handleDelete}
				closeSidebar={() => {
					setTrips(null);
					setTrips([]);
					setDataTripToFilter([]);
					setShowTripList(false);
				}}
				createTrip={() => {
					setDataTrip(null);

					if (AuthService.isUserPartner()) setShowWizardBusiness(true);
					else setShowWizard(true);

					logEvent_("react_screen_create_trip_view");
				}}
				displayOverview={displayOverview}
				deleteTrip={deleteTrip}
				dataTripToFilter={dataTripToFilter}
			/>

			{/* TRIP OVERVIEW */}
			<TripOverview
				show={showTripOverView}
				id={currentTripId}
				openChat={openChat}
				guid={currentTripGUID}
				type={currentTripType}
				showAuth={onShowAuth}
				dispatch={dispatch}
				setUpdate={handleUpdate}
				handleEdit={handleEdit}
				handleCrew={() => handleTripOverviewSidebars({ crew: true })}
				handleCrewList={(a, e) => {
					setCrewDeadlineReached(e);
					handleTripOverviewSidebars({ crewList: true });
				}}
				handleProvisionList={(a, e) => {
					setProvisionDeadlineReached(e);
					handleTripOverviewSidebars({ provisionList: true });
				}}
				handleTransferList={(e) => {
					setTransferDeadlineReached(e);
					handleTripOverviewSidebars({ transferList: true });
				}}
				onOpen={onOpenTripOverview}
				onClose={onCloseTripOverview}
				cloneToBusiness={cloneToBusiness}
				handleTripOverviewSidebars={handleTripOverviewSidebars}
				setCustomDialogProps={setCustomDialogProps}
			/>

			<Crew
				visible={showCrew}
				closeSidebar={onCloseTripOverviewSidebars}
				tripId={currentTrip?.Id}
				handleTripOverviewSidebars={handleTripOverviewSidebars}
			/>

			<CrewList
				visible={showCrewList}
				closeSidebar={onCloseTripOverviewSidebars}
				trip={currentTrip}
				deadlineReached={crewDeadlineReached}
			/>

			<TransferList
				visible={showTransferList}
				closeSidebar={onCloseTripOverviewSidebars}
				trip={currentTrip}
				deadlineReached={transferDeadlineReached}
			/>

			<ProvisionList
				visible={showProvisionList}
				trip={currentTrip}
				deadlineReached={provisionDeadlineReached}
				tmpProducts={tmpProducts}
				handleProductsList={(res) => {
					setProductListItems(res);
					handleTripOverviewSidebars({ productsList: true });
				}}
				onOpen={() => {
					setTmpProducts([]);
				}}
				onClose={() => {
					onCloseTripOverviewSidebars();
					setTmpProducts([]);
				}}
			/>

			<ProductsList
				visible={showProductsList}
				trip={currentTrip}
				onAdd={(data) => {
					setTmpProducts(data);
				}}
				onClose={() => {
					onCloseTripOverviewSidebars({ openTripOverview: false });
					handleTripOverviewSidebars({ provisionList: true });
				}}
				res={productListItems}
			/>

			{/* CONTRIBUTION PAGE */}
			<ContributionPage
				added={() => setPlaceAdded(!placeAdded)}
				dispatch={dispatch}
				onClose={closeAddPlace}
				visible={showContrib}
				place={{ Location: location }}
			/>

			{/* PLACE SIDEBAR */}
			<PlacesDetailsSideBar
				dispatch={dispatch}
				state={state.placeDetailsState}
				show={state.showPlaceDetails}
				setUpdate={handleUpdate}
				addTripNodeEditing={addTripNodeEditing}
				closeSideBars={() => {
					setShowModal(false);
					setShowTripList(false);
					setShowTripOverView(false);
					closeUserSidebars();
				}}
				openWizard={() => {
					if (AuthService.isUserPartner()) setShowWizardBusiness(true);
					else setShowWizard(true);
					logEvent_("react_screen_create_trip_view");
				}}
			/>

			{/* CLOSEST COAST VIEW */}
			<ClosestCoastView
				show={true}
				id={state.closestCVState?.id}
				requestData={state.closestCVState}
				dispatch={dispatch}
				openCoastView={(lineid, id) => {
					if (!enableOnboard) {
						dispatch({
							type: "updateslider",
							photo: {
								lineid,
								id,
							},
						});
					}
				}}
			/>

			{/* FILTER */}
			<FilterModal visible={showModal} onClose={hideModal} initials={initial} />

			{/* PROFILE */}
			<Profile
				visible={sidebarProfileVisibility}
				handleClick={handleOnClick}
				handleDelete={handleDelete}
				closeSidebar={closeUserSidebars}
				showBookmarks={handleShowBookmarks}
			/>

			{/* EDIT PROFILE */}
			<EditProfile
				visible={sidebarEditVisibility}
				handleClick={handleOnClick}
				bookmarks={bookmarks ? bookmarks : []}
				handleDelete={handleDelete}
				closeSidebar={() => {
					closeUserSidebars();
					showUserProfile();
					setShowModal(false);
				}}
			/>

			{/* USER SETTINGS SIDEBAR */}
			<UserSettings show={sidebarSettingsVisibility} setUpdate={handleUpdate} />

			{/* USER VESSELS SIDEBAR */}
			<UserVessels show={sidebarVesselsVisibility} />

			<PartnerProvisionList />

			{/* PLACE REVIEW */}
			<AddReview
				place_={placeToReview_}
				show={showAddReviewsVisibility}
				handleBack={handleBack}
			/>

			<Chat
				visible={showChat}
				tripId={currentTripId?.toString()}
				handleBack={() => setShowChat(false)}
			/>

			<Reviews
				handleNewReview={() => {
					AuthService.isUserLogedIn()
						? openAddReviewPage(placeToReview_)
						: showUserAuthView();
				}}
				place_={placeToReview_}
				show={showReviewsVisibility}
				handleBack={handleBack}
			/>

			{/* GLOBAL WEATHER  BRAND */}
			<GWBottomBrand visible={globalWeatherBrandVisibility} />

			<SetNightShelterModal
				onSetNightShelter={onSetNightShelter}
				handleClose={() => setOnSetNightShelter(null)}
			/>

			<CustomDialog {...customDialogProps} />

			<ToastContainer />

			<Steps
				enabled={enableOnboard}
				steps={steps}
				initialStep={0}
				onExit={() => setEnableOnboard(false)}
				css={{ height: "100vh", width: "100vh", overflow: "hidden" }}
				options={{
					doneLabel: "Done",
					scrollPadding: 0,
					exitOnOverlayClick: false,
					exitOnEsc: false,
				}}
			/>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeReviewPage: () => dispatch(closeReviewPage()),
		openReviewPage: (place) => dispatch(openReviewPage(place)),
		openAddReviewPage: (place) => dispatch(openAddReviewPage(place)),
		showUserAuthView: () => dispatch(showUserAuthView()),
		setUserProfileId: (userId) => dispatch(setUserProfileId(userId)),
		setMapFiltersSelected: (filtersSelected) =>
			dispatch(setMapFiltersSelected(filtersSelected)),
		showUserProfile: () => dispatch(showUserProfile()),
		showUserEdit: () => dispatch(showUserEdit()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
		setTripItineraryVisibility: (bool = false) =>
			dispatch(setTripItineraryVisibility(bool)),
		setTripItinerary: (newTrip) => {
			dispatch(setTripItinerary(newTrip));
		},
		addTripNodeEditing: (
			placeData = {},
			forceLastNode = false,
			callback = () => {}
		) => dispatch(addTripNodeEditing(placeData, forceLastNode, callback)),
		updateIndexTripDay: (index = Number) => dispatch(updateIndexTripDay(index)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);

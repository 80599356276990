/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
	IconButton,
	CircularProgress,
	TextField,
	InputAdornment,
	Button,
} from "@material-ui/core";
import PropTypes from "prop-types";

// STORE
import { languages } from "../../store/features/translation/Languages";
import { currentLang } from "../../store/features/translation/selectors";
import { currentTripItinerary as currentTripItinerary_ } from "../../store/features/map/selectors";

// HELPERS
import { sidebarWidth } from "../utils/SideBar/styled";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

// CONSTANTS
import { filterHighlightColor, secondaryTxt } from "../../constants/colors";

// API
import { ApiClient } from "../../ApiClient";

// COMPONENTS
import { colorDictionary } from "../FIlterModal";

// STYLES
import "intro.js/introjs.css";
import { Sidebar, SidebarContent } from "./styled";
import { useStyles } from "../map/Popup";

// ICON
import ClearIcon from "../../assets/clear_search_ic.svg";
import SearchIcon from "../../assets/search_ic.svg";
import EmptyIcon from "../../assets/empty.svg";
import AddToTripIcon from "../../assets/svg/itinerary/add_to_trip.svg";

// LOCAL COMPONENTS
const SearchBox = ({
	result = {},
	searchFocused = false,
	OnHaveSearchTerm = () => {},
	onFocus = () => {},
	onClearText = () => {},
	onBlur = () => {},
	isFocused = false,
}) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [term, setTerm] = useState("");
	const [searchBarWrapper, setSearchBarWrapper] = useState({});
	const [searchBarInp, setSearchBarInp] = useState({});

	// FUNCTIONS
	const handleOnChange = (event) => {
		setTerm(event.target.value);
		logEvent_("react_explore_search_submitted");
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.SEARCH_PERFORMED
		);
		OnHaveSearchTerm(event.target.value);
	};

	//EFFECTS
	useEffect(() => {
		setTerm(result.substring(0, result.indexOf(",")));
	}, [result]);

	useEffect(() => {
		const searchBarWrapper_ = window.document.querySelector(
			"#map-searchbox .MuiTextField-root>.MuiOutlinedInput-root"
		);
		const searchBarInp_ = window.document.querySelector(
			"#map-searchbox .MuiInputBase-input.MuiOutlinedInput-input"
		);

		setSearchBarWrapper(searchBarWrapper_);
		setSearchBarInp(searchBarInp_);

		searchBarInp_.addEventListener("focus", () => {
			searchBarWrapper_.classList.add("focused");
			onFocus();
		});

		searchBarInp_.addEventListener("blur", () => {
			searchBarWrapper_.classList.add("focused");
			onFocus();
		});
	}, []);

	useEffect(() => {
		if (!searchFocused && Object.keys(searchBarWrapper)?.length) {
			searchBarWrapper.classList.remove("focused");
			onBlur();
		}
	}, [searchFocused]);

	return (
		<>
			<TextField
				id="inputMapSearch"
				type="text"
				className="input search-input"
				onBlur={() => logEvent_("react_explore_search_pressed")}
				value={term}
				placeholder={languages[currentLanguage]["search_placeholder"]}
				variant="outlined"
				onChange={handleOnChange}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<img
								alt="search"
								src={SearchIcon}
								style={{ height: 18, width: 18 }}
							/>
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							{searchFocused && (
								<IconButton
									aria-label="Close search"
									style={{ color: "#8f96a7" }}
									onClick={() => {
										searchBarWrapper.classList.remove("focused");
										onBlur();
										setTerm("");
										onClearText();
									}}
								>
									<img
										src={ClearIcon}
										alt="ClearIcon"
										style={{ height: 18, width: 18 }}
									/>
								</IconButton>
							)}
						</InputAdornment>
					),
				}}
			/>
		</>
	);
};

const SearchItem = ({
	searchResult = {},
	onSelected = () => {},
	onAddToTrip = () => {},
}) => {
	const handleClick = () => {
		logEvent_("react_explore_search_queryresult_pressed");
		onSelected(searchResult);
	};

	return (
		<div className="position-relative w-100">
			<Button
				className="d-flex align-items-center w-100 py-3 mx-0 px-0 text-left"
				style={{
					borderBottom: "solid 1px rgba(232, 232, 232, 0.5)",
					cursor: "pointer",
					textTransform: "initial",
				}}
				onClick={handleClick}
			>
				<div
					style={{ width: 55 }}
					className="d-flex justify-content-center align-items-center"
				>
					<img
						src={require(`../../assets/img/searchIcons/ic_searchtype_${searchResult.Type}.svg`)}
						alt="searchResult"
						style={{
							height: 22,
							width: 22,
						}}
					/>
				</div>

				<div style={{ flex: 1 }} title={searchResult.Name}>
					<h6
						className="pb-0 mb-0"
						style={{
							fontSize: 14,
							lineHeight: 1.29,
							color: "#222c4b",
							marginRight:
								searchResult.Type === "beach" ||
								searchResult.Type === "marina" ||
								searchResult.Type === "port" ||
								searchResult.Type === "mooring"
									? 32
									: 0,
						}}
					>
						{searchResult.Name.substring(
							0,
							searchResult.Type === "beach" ||
								searchResult.Type === "marina" ||
								searchResult.Type === "port" ||
								searchResult.Type === "mooring"
								? 25
								: 30
						)}
						{((searchResult.Type === "beach" ||
							searchResult.Type === "marina" ||
							searchResult.Type === "port" ||
							searchResult.Type === "mooring") &&
							searchResult.Name?.length > 25) ||
						searchResult.Name?.length > 30
							? "..."
							: ""}
					</h6>
					<span
						className="dromon-l"
						style={{
							fontSize: 12,
							fontWeight: "300",
							color: "#8f96a7",
						}}
					>
						{searchResult.Type}
					</span>
				</div>
			</Button>
			{(searchResult.Type === "beach" ||
				searchResult.Type === "marina" ||
				searchResult.Type === "port" ||
				searchResult.Type === "mooring") && (
				<div
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						width: 32,
					}}
					className="d-flex justify-content-center align-items-center h-100 p-0"
				>
					<IconButton
						title="add to trip"
						className="my-1"
						style={{ height: 30, width: 30 }}
						onClick={() => onAddToTrip(searchResult)}
					>
						<img
							src={AddToTripIcon}
							alt="AddToTripIcon"
							style={{
								height: 18,
								width: 18,
							}}
						/>
					</IconButton>
				</div>
			)}
		</div>
	);
};

const FilterBtn = ({ bgActive = "", showModal = () => {} }) => {
	return (
		<>
			<IconButton
				className="filter-btn-b"
				title={"Looking for " + bgActive}
				onClick={() => {
					gtag_report_conversion(
						"https://" + window.location.host + "/explore/",
						types.FILTER_INITIATE
					);
					showModal();
					logEvent_("react_explore_filters_button_pressed");
				}}
				style={{
					backgroundColor: "white",
					boxShadow: "0 0 6px 0 rgba(34, 44, 75, 0.2)",
					marginLeft: 10,
					textAlign: "center",
					borderRadius: 24,
					height: 48,
					width: 48,
					padding: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<span
					style={{
						position: "absolute",
						height: 12,
						width: 12,
						backgroundColor: colorDictionary[bgActive],
						borderRadius: 6,
						top: 10,
						right: 10,
						border: "1px solid white",
					}}
				/>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="19"
					height="20"
					viewBox="0 0 19 20"
				>
					<g
						fill="none"
						fillRule="evenodd"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<g stroke="#222C4B" strokeWidth="2">
							<g>
								<g>
									<path
										d="M5.846 18L5.846 7.181 0 0 16.321 0 10.608 7.181 10.608 14.969z"
										transform="translate(-351.000000, -108.000000) translate(36.500000, 93.000000) translate(316.000000, 16.000000)"
									/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</IconButton>
		</>
	);
};

const KeeanoSearch = ({
	bgActive = "",
	selectedTags = null,
	dispatch = () => {},
	showModal = () => {},
	openWizard = () => {},
	addTripNodeEditing = () => {},
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	const currentTripItinerary = useSelector(currentTripItinerary_);

	// STATES
	const [enteredText, setEnteredText] = useState(null),
		[results, setResults] = useState([]),
		[recentSearches, setRecentSearches] = useState([]),
		[selected, setSelected] = useState(null),
		[searchFocused, setSearchFocused] = useState(false),
		[loading, setLoading] = useState(false);

	// DATA
	let timer = null;
	let lastTerm = null;

	// FUNCTIONS
	async function handleHaveSearchTerm(term) {
		lastTerm = term.slice();
		timer && clearTimeout(timer);

		if (term?.length < 3) {
			setResults([]);
			setEnteredText(null);
		}

		if (term?.length >= 3) {
			setLoading(true);
			setEnteredText(term);

			timer = setTimeout(async () => {
				try {
					const data = await ApiClient.getSearchResults(term);
					if (term === lastTerm) {
						setResults(data.value);
					}
				} catch (e) {
					setResults([]);
				} finally {
					setLoading(false);
				}
			}, 1000);
		}
	}

	async function handleSelected(searchResult = {}) {
		const prevRecentSearches_ = localStorage.getItem("recentSearches")
			? JSON.parse(localStorage.getItem("recentSearches"))
			: [];
		let newRecentSearch_ = [];

		let findIfExist = prevRecentSearches_.find((item) =>
			item ? item.Id === searchResult.Id : false
		);
		if (!findIfExist) prevRecentSearches_.unshift(searchResult);

		newRecentSearch_ = newRecentSearch_.concat(prevRecentSearches_.slice(0, 9));

		setSelected(searchResult);
		setResults([]);
		setSearchFocused(false);

		//props.OnHaveSelected(rs);
		localStorage.setItem("recentSearches", JSON.stringify(newRecentSearch_));
		dispatch({ type: "updatesearch", result: searchResult });
	}

	const onAddToTrip = (place) => {
		addTripNodeEditing(
			{
				...place,
				ID: place?.EntityId,
				Name: place?.Name ? place?.Name.split(",")[1] : "",
				Location: place?.Point?.coordinates,
			},
			false,
			() => {
				setSearchFocused(false);
				setResults([]);
				openWizard();
			}
		);
	};

	// EFFECTS
	useEffect(() => {
		const prevRecentSearches_ = localStorage.getItem("recentSearches")
			? JSON.parse(localStorage.getItem("recentSearches"))
			: [];
		setRecentSearches(prevRecentSearches_);
	}, [searchFocused]);

	return (
		<div
			style={{
				width: sidebarWidth,
				paddingTop: 87,
				marginLeft:
					currentTripItinerary?.visibility &&
					currentTripItinerary?.selectedDay?.inEditing
						? sidebarWidth
						: 0,
			}}
		>
			<div className="d-flex justify-content-center align-items-center">
				<SearchBox
					OnHaveSearchTerm={handleHaveSearchTerm}
					result={!selected ? "" : selected.Name}
					searchFocused={searchFocused}
					onFocus={() => setSearchFocused(true)}
					onBlur={() => {
						setSearchFocused(false);
						setResults([]);
					}}
					onClearText={() => setEnteredText(null)}
				/>

				{!searchFocused && (
					<FilterBtn
						{...{
							bgActive,
							selectedTags,
							showModal,
						}}
					/>
				)}
			</div>

			<Sidebar
				style={{
					top: 55,
					marginLeft:
						currentTripItinerary?.visibility &&
						currentTripItinerary?.selectedDay?.inEditing
							? sidebarWidth
							: 0,
				}}
				show={searchFocused}
			>
				{loading ? (
					<div className="w-100 h-100 d-flex justify-content-center align-items-center">
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<SidebarContent>
						{!!results?.length && (
							<ul>
								<div
									className="text-right mb-1"
									style={{
										fontSize: 14,
										color: "#8f96a7",
									}}
								>
									<span style={{ color: filterHighlightColor }}>
										{results?.length}
									</span>{" "}
									{languages[currentLanguage]["search_results"]}
								</div>
								{results.map((r) => {
									return (
										<SearchItem
											key={r.Id}
											onSelected={handleSelected}
											searchResult={r}
											onAddToTrip={onAddToTrip}
										/>
									);
								})}
							</ul>
						)}
						{!!recentSearches?.length && !enteredText && (
							<ul>
								<div
									className="text-right"
									style={{
										fontSize: 14,
										color: "#8f96a7",
									}}
								>
									<span style={{ color: filterHighlightColor }}>
										{recentSearches?.length}
									</span>{" "}
									{languages[currentLanguage]["search_recent_searches"]}
								</div>
								{recentSearches.map(
									(r) =>
										r && (
											<SearchItem
												key={r.Id}
												onSelected={handleSelected}
												searchResult={r}
												onAddToTrip={onAddToTrip}
											/>
										)
								)}
							</ul>
						)}

						{!results?.length && !!enteredText && (
							<div
								style={{ height: "80%" }}
								className="w-100 p-2 d-flex flex-column align-items-center justify-content-center"
							>
								<img style={{ height: "20%" }} src={EmptyIcon} />
								<p className="text-center mb-1 mt-5" style={{ fontSize: 21 }}>
									{
										languages[currentLanguage][
											"search_noresults_emptystate_header"
										]
									}
								</p>
								<p
									className="dromon-l text-center mb-0"
									style={{ color: secondaryTxt, fontSize: 14 }}
								>
									{
										languages[currentLanguage][
											"search_noresults_emptystate_content"
										]
									}
								</p>
							</div>
						)}
					</SidebarContent>
				)}
			</Sidebar>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KeeanoSearch);

KeeanoSearch.propTypes = {
	bgActive: PropTypes.string,
	selectedTags: PropTypes.number,
	showModal: PropTypes.func.isRequired,
};

import { Button } from "@material-ui/core";
import React, { useState, useRef } from "react";
import { Sidebar, SidebarContent } from "./styled";
import backIcon from "../../assets/back_ic.svg";
import addIcon from "../../assets/review/add_ic.svg";
import PropTypes from "prop-types";
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { connect, useSelector } from "react-redux";
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../constants/colors";
import startUnselectedIcon from "../../assets/review/star_review_active_ic.svg";
import startSelectedIcon from "../../assets/review/star_review_selected_ic.svg";
import { ApiClient } from "../../ApiClient";
import Alert from "@material-ui/lab/Alert";
import { logEvent_ } from "../../helpers/analytics";

const AddReview = (props) => {
	const { show, handleBack, place_ } = props;
	const currentLanguage = useSelector(currentLang);
	const fileInputRef = useRef();
	const [myRate, setMyRate] = useState(0);
	const [reviewComment, setReviewComment] = useState("");
	const [image, setimage] = useState({ uri: null, file: null });
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [notice, setNotice] = React.useState({ type: "", content: "" });
	const [loading, setLoading] = useState(false);
	const loadImage = (e) => {
		const URI = URL.createObjectURL(e.target.files[0]);
		setimage({ uri: URI, file: e.target.files[0] });
	};
	const handleSubmitReview = async () => {
		// EVENT
		logEvent_("react_placedetails_submitrevbtn_pressed");
		//
		let photo = null;
		let uploadPhotoResponse;

		if (image.uri && image.file) {
			setLoadingSubmit(true);

			await ApiClient.getPhotoServiceSignature().then((res) => (photo = res));
			await ApiClient.uploadUserPhoto(photo.UploadUrl, image.file).then(
				(res) => (uploadPhotoResponse = res)
			);
		}

		setLoadingSubmit(true);
		ApiClient.addReview(place_.ID, myRate, reviewComment, photo?.Url)
			.then((res) => {
				setLoadingSubmit(false);
				if (!res.ErrorCode && !res.ErrorMessage) {
					setNotice({
						type: "SUCCESS",
						content: languages[currentLanguage]["place_review_success_msg"],
					});
					setTimeout(() => {
						handleBack();
					}, 1000);
				} else {
					if (res.ErrorCode === "uri:KeeanoWS:OneReviewPerPlace") {
						setNotice({
							type: "ERROR",
							content:
								languages[currentLanguage][
									"place_reviews_list_onereview_validation"
								],
						});
					} else {
						setNotice({
							type: "ERROR",
							content: res.ErrorMessage,
						});
					}
				}
				logEvent_("react_placedetails_submitreview_success");
				setMyRate(0);
				setReviewComment("");
				setimage({ uri: null, file: null });
			})
			.catch((error) => {
				logEvent_("react_placedetails_submitreview_failure");
				if (error.ErrorCode === "uri:KeeanoWS:OneReviewPerPlace") {
					setNotice({
						type: "ERROR",
						content:
							languages[currentLanguage][
								"place_reviews_list_onereview_validation"
							],
					});
				} else {
					setNotice({
						type: "ERROR",
						content: error.ErrorMessage,
					});
				}
				setLoadingSubmit(false);
				setMyRate(0);
				setReviewComment("");
				setimage({ uri: null, file: null });
			});
	};
	return (
		<>
			<Sidebar show={show}>
				<SidebarContent id="sideBarContentReview">
					<Button
						onClick={() => {
							setimage({ uri: null, file: null });
							setMyRate(0);
							setReviewComment("");
							handleBack();
						}}
						className="justify-content-start py-3 mb-4 w-100"
						style={{
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
						}}
					>
						<img
							alt="icon"
							src={backIcon}
							style={{ height: 16, width: 8, marginRight: 17 }}
						/>
						<div className="d-flex flex-column align-items-start">
							<h2
								className="m-0 p-0"
								style={{ fontSize: 21, color: formMainTxt }}
							>
								{languages[currentLanguage]["place_review_header"]}
							</h2>
							{
								<div
									style={{
										color: secondaryTxt,
										fontSize: 14,
									}}
								>
									Write your feedback
								</div>
							}
						</div>
					</Button>
					<div className="mb-4 container">
						<div className="row px-3">
							<h3
								className="m-0 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["place_review_rating_header"]}
							</h3>
							<h4
								className="p-0 col-12 dromon-l"
								style={{
									color: secondaryTxt,
									fontSize: 14,
									marginTop: 14,
									marginBottom: 16,
								}}
							>
								{languages[currentLanguage]["place_review_rating_subheader"]}
							</h4>
							<div id="start-btn">
								{[1, 2, 3, 4, 5].map((start, idx) => (
									<button
										key={idx}
										className="px-0"
										style={{
											height: 30,
											width: 31,
											marginRight: 10,
											backgroundColor: "transparent",
											border: "none",
										}}
										onClick={() => setMyRate(start)}
									>
										<img
											src={
												myRate >= start
													? startSelectedIcon
													: startUnselectedIcon
											}
											style={{ height: 30, width: 31 }}
										/>
									</button>
								))}
							</div>
						</div>
					</div>
					<div className="mb-4 container">
						<div className="row px-3">
							<h3
								className="m-0 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["place_review_comment_header"]}
							</h3>
							<div className="">
								<textarea
									type="text"
									style={{ marginTop: 14, height: 107, width: 380 }}
									value={reviewComment}
									onChange={(e) => setReviewComment(e.target.value)}
									placeholder={
										languages[currentLanguage][
											"place_review_comment_placeholder"
										]
									}
								/>
							</div>
						</div>
					</div>
					<div className="mb-4 container">
						<div className="row px-3">
							<h3
								className="m-0 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["place_details_photos_button"]}
							</h3>
							<div className="row px-3 mb-4" style={{}}>
								<button
									style={{
										marginTop: 14,
										height: 137,
										backgroundImage: image?.uri ? `url(${image?.uri})` : "",
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
										width: 380,
										borderRadius: 5,
										backgroundColor: !image?.uri ? whiteColor : "transparent",
										border: "1px dashed " + secondaryTxt,
									}}
									onClick={() => fileInputRef.current.click()}
								>
									<img src={addIcon} style={{ height: 14, width: 14 }} />
								</button>
								<input
									onChange={loadImage}
									multiple={false}
									ref={fileInputRef}
									type="file"
									hidden
								/>
							</div>
							<Button
								onClick={handleSubmitReview}
								className="w-100"
								style={{
									backgroundColor: filterHighlightColor,
									color: whiteColor,
									fontFamily: "inherit",
									fontSize: 16,
									textTransform: "initial",
								}}
							>
								{loadingSubmit ? (
									<i className="fa fa-circle-notch fa-spin mr-2" />
								) : (
									""
								)}{" "}
								{languages[currentLanguage]["place_review_button"]}
							</Button>
						</div>
					</div>
				</SidebarContent>
			</Sidebar>
			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

AddReview.propTypes = {
	show: PropTypes.bool.isRequired,
	handleBack: PropTypes.func,
	place_: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReview);

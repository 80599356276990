import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Button,
	CircularProgress,
	Collapse,
	IconButton,
	Snackbar,
	ListItem,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// API
import { ApiClient } from "../../ApiClient";

// CONSTANTS
import { filterHighlightColor, secondaryTxt } from "../../constants/colors";

// SELECTORS
import {
	userData as currentUserData_,
	userProfileId,
	sidebarVessels,
} from "../../store/features/user/selectors";

// ACTIONS
import {
	setUserData as setStoredUserData,
	showUserProfile,
	closeUserSidebars,
} from "../../store/features/user/actions";

import {
	convertFuelToMyUnit,
	convertDepthToMyUnit,
	convertDepthToServerUnit,
	convertFuelToServerUnit,
	convertSpeedToMyUnit,
	convertSpeedToServerUnit,
} from "../../helpers/UnitConverter";
import { userSettings } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { currentLang } from "../../store/features/translation/selectors";

// HELPERS
import { isEmpty } from "../../helpers/functions";
import { logEvent_ } from "../../helpers/analytics";

// STYLES
import { Sidebar, SidebarContent } from "./styled";
import { useStyles } from "../map/Popup";

// ICONS
import backIcon from "../../assets/back_ic.svg";

const UserVessels = ({
	setStoredUserData,
	show = false,
	showUserProfile,
	closeUserSidebars,
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentUserData = useSelector(currentUserData_);
	const selectedUserProfileId = useSelector(userProfileId);
	const sidebarVisibility = useSelector(sidebarVessels);
	const USER_SETTINGS = useSelector(userSettings);
	const currentUserSettings = !isEmpty(USER_SETTINGS)
		? USER_SETTINGS
		: {
				DepthUnits: "m",
				DistanceUnits: "nm",
				FuelUnit: "lt",
				ProfilePublic: true,
				SpeedUnit: "kts",
				TempUnits: "c",
				VesselsPublic: false,
				WindSpeedUnits: "bf",
		  };
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [inAdding, setInAdding] = React.useState(false);
	const [inEditing, setInEditing] = React.useState(null);
	const [isOwner, setIsOwner] = React.useState(false);
	const [userVessels, setUserVessels] = React.useState([]);
	const [snackbarNotice, setSnackbarNotice] = React.useState({
		type: "",
		msg: "",
	});
	const [loading, setLoading] = React.useState(false);
	const [loadingAdd, setLoadingAdd] = React.useState(false);
	const [loadingDelete, setLoadingDelete] = React.useState(null);
	const [loadingEdit, setLoadingEdit] = React.useState(null);
	// Add Vessel states
	const [newVName, setNewVName] = React.useState(null);
	const [newVFlag, setNewVFlag] = React.useState(null);
	const [newVVesselType, setNewVVesselType] = React.useState(null);
	const [newVMMSI, setNewVMMSI] = React.useState(null);
	const [newVLength, setNewVLength] = React.useState(0);
	const [newVHeight, setNewVHeight] = React.useState(0);
	const [newVSpeed, setNewVSpeed] = React.useState(0);
	const [newVDraft, setNewVDraft] = React.useState(0);
	const [newVFuel, setNewVFuel] = React.useState(0);
	// Edit vessel states
	const [editVName, setEditVName] = React.useState(null);
	const [editVFlag, setEditVFlag] = React.useState(null);
	const [editVVesselType, setEditVVesselType] = React.useState(null);
	const [editVMMSI, setEditVMMSI] = React.useState(null);
	const [editVLength, setEditVLength] = React.useState(0);
	const [editVHeight, setEditVHeight] = React.useState(0);
	const [editVSpeed, setEditVSpeed] = React.useState(0);
	const [editVDraft, setEditVDraft] = React.useState(0);
	const [editVFuel, setEditVFuel] = React.useState(0);
	const [editVSelected, setEditVSelected] = React.useState(false);

	// DATA
	const iso3311a2 = require("iso-3166-1-alpha-2");
	const regexMMSI = new RegExp("^[0-9]{9,9}$");

	// FUNCTIONS
	const onAddVessel = () => {
		if (newVMMSI !== null) {
			if (!regexMMSI.test(newVMMSI)) {
				setSnackbarNotice({
					type: "error",
					msg: languages[currentLanguage]["user_vessel_invalid_mmsi"],
				});
				return;
			}
		}

		if (currentUserData.Type === "partner") {
			if (newVLength === null || newVLength === 0.0 || newVLength === 0) {
				setSnackbarNotice({
					type: "error",
					msg: "Boat length mandatory",
				});
				return;
			}

			if (newVSpeed === null || newVSpeed === 0.0 || newVSpeed === 0) {
				setSnackbarNotice({
					type: "error",
					msg: "Boat speed mandatory",
				});
				return;
			}
		}
		setLoadingAdd(true);
		ApiClient.createMyVessel(
			newVMMSI,
			"",
			newVFlag,
			convertDepthToServerUnit(newVHeight, currentUserSettings.DepthUnits),
			convertDepthToServerUnit(newVDraft, currentUserSettings.DepthUnits),
			convertDepthToServerUnit(newVLength, currentUserSettings.DepthUnits),
			0,
			convertFuelToServerUnit(newVFuel, currentUserSettings.FuelUnit),
			convertSpeedToServerUnit(newVSpeed, currentUserSettings.SpeedUnit),
			newVVesselType,
			false,
			newVName
		).then((res) => {
			if (res.ErrorCode) {
				setLoadingAdd(false);
				setSnackbarNotice({
					type: "error",
					msg: `${res.ErrorCode}: ${res.ErrorMessage}`,
				});
			} else {
				resetForm();
				logEvent_("react_user_vesseladd_success");
				reloadVessels();
				ApiClient.getUser().then((userData) => {
					setLoadingAdd(false);
					setInAdding(false);
					setStoredUserData(userData);
					setSnackbarNotice({
						type: "success",
						msg: languages[currentLanguage]["profile_message_vessel_added"],
					});
				});
			}
		});
	};

	const onEditingVessel = (Vessel) => {
		setEditVMMSI(JSON.parse(JSON.stringify(Vessel.MMSI ? Vessel.MMSI : null)));
		setEditVFlag(JSON.parse(JSON.stringify(Vessel.Flag ? Vessel.Flag : null)));
		setEditVHeight(
			convertDepthToMyUnit(
				JSON.parse(JSON.stringify(Vessel.BeamMeters ? Vessel.BeamMeters : 0)),
				currentUserSettings.DepthUnits
			)
		);
		setEditVSpeed(
			convertSpeedToMyUnit(
				JSON.parse(JSON.stringify(Vessel.SpeedKnots ? Vessel.SpeedKnots : 0)),
				currentUserSettings.SpeedUnit
			)
		);
		setEditVDraft(
			convertDepthToMyUnit(
				JSON.parse(JSON.stringify(Vessel.DraftMeters ? Vessel.DraftMeters : 0)),
				currentUserSettings.DepthUnits
			)
		);
		setEditVLength(
			convertDepthToMyUnit(
				JSON.parse(
					JSON.stringify(Vessel.LengthMeters ? Vessel.LengthMeters : 0)
				),
				currentUserSettings.DepthUnits
			)
		);
		setEditVFuel(
			convertFuelToMyUnit(
				JSON.parse(
					JSON.stringify(
						Vessel.FuelConsumptionLiters ? Vessel.FuelConsumptionLiters : 0
					)
				),
				currentUserSettings.FuelUnit
			)
		);
		setEditVVesselType(
			JSON.parse(JSON.stringify(Vessel.Type ? Vessel.Type : null))
		);
		setEditVName(JSON.parse(JSON.stringify(Vessel.Name ? Vessel.Name : null)));
		setEditVSelected(
			JSON.parse(JSON.stringify(Vessel.Selected ? Vessel.Selected : false))
		);

		setInEditing(Vessel.ID);
	};

	const onDeleteVessel = (vesselID) => {
		setLoadingDelete(vesselID);
		ApiClient.deleteMyVessel(vesselID)
			.then(() => {
				reloadVessels();
				ApiClient.getUser()
					.then((userData) => setStoredUserData(userData))
					.then(() =>
						setSnackbarNotice({
							type: "success",
							msg: languages[currentLanguage]["profile_message_vessel_updated"],
						})
					);
			})
			.catch(() => {})
			.finally(() => {
				setLoadingDelete(null);
			});
	};

	const onEditVessel = (vessel) => {
		if (editVMMSI !== null) {
			if (!regexMMSI.test(editVMMSI)) {
				setSnackbarNotice({
					type: "error",
					msg: languages[currentLanguage]["user_vessel_invalid_mmsi"],
				});
				return;
			}
		}
		if (currentUserData.Type === "partner") {
			if (editVLength === null || editVLength === 0.0 || editVLength === 0) {
				setSnackbarNotice({
					type: "error",
					msg: "Boat length mandatory",
				});
				return;
			}
			if (editVSpeed === null || editVSpeed === 0.0 || editVSpeed === 0) {
				setSnackbarNotice({
					type: "error",
					msg: "Boat speed mandatory",
				});
				return;
			}
		}
		setLoadingEdit(vessel.ID);
		ApiClient.updateMyVessel(
			vessel.ID,
			editVMMSI,
			"",
			editVFlag,
			convertDepthToServerUnit(editVHeight, currentUserSettings.DepthUnits),
			convertDepthToServerUnit(editVDraft, currentUserSettings.DepthUnits),
			convertDepthToServerUnit(editVLength, currentUserSettings.DepthUnits),
			0,
			convertFuelToServerUnit(editVFuel, currentUserSettings.FuelUnit),
			convertSpeedToServerUnit(editVSpeed, currentUserSettings.SpeedUnit),
			editVVesselType,
			editVSelected,
			editVName
		)
			.then((res) => {
				if (res.ErrorCode) {
					setSnackbarNotice({
						type: "error",
						msg: `${res.ErrorCode}: ${res.ErrorMessage}`,
					});
				} else {
					setInEditing(null);
					setEditVName(null);
					setEditVFlag(null);
					setEditVVesselType(null);
					setEditVMMSI(null);
					setEditVLength(0);
					setEditVHeight(0);
					setEditVDraft(0);
					setEditVFuel(0);
					reloadVessels();

					ApiClient.getUser()
						.then((userData) => setStoredUserData(userData))
						.then(() =>
							setSnackbarNotice({
								type: "success",
								msg: languages[currentLanguage][
									"profile_message_vessel_updated"
								],
							})
						)
						.finally(() => {
							setInEditing(null);
						});
				}
			})
			.catch(() => {})
			.finally(() => {
				setLoadingEdit(null);
			});
	};

	const vesselDictionary = {
		motorboat: "user_vessels_type_motorboat",
		kayak: "user_vessels_type_kayak",
		sailboat: "user_vessels_type_sailing",
	};

	const resetForm = () => {
		setNewVName(null);
		setNewVFlag(null);
		setNewVVesselType(null);
		setNewVMMSI(null);
		setNewVLength(0);
		setNewVHeight(0);
		setNewVSpeed(0);
		setNewVDraft(0);
		setNewVFuel(0);
	};

	const reloadVessels = () => {
		setLoading(true);
		ApiClient.getMyVessels()
			.then((vessels) => {
				const customizedUserVessels = [];
				vessels.forEach((vessel) => {
					vessel.Type = vessel.Type.replace("community", "").replace(
						"business",
						""
					);
					customizedUserVessels.unshift({
						...vessel,
						collapse: true,
					});
				});
				setUserVessels(customizedUserVessels);
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	//EFFECTS
	React.useEffect(() => {
		if (!isEmpty(selectedUserProfileId) && sidebarVisibility) {
			setInAdding(false);
			setInEditing(null);
			setIsOwner(false);
			setUserVessels([]);

			if (selectedUserProfileId === currentUserData.ID) {
				setIsOwner(true);
				reloadVessels();
			} else {
				setLoading(true);
				setIsOwner(false);
				ApiClient.getOtherUserById(selectedUserProfileId).then((_userData) => {
					ApiClient.getOtherUserVessels(
						selectedUserProfileId,
						_userData?.Type === "partner"
					)
						.then((Vessels) => {
							const customizedUserVessels = [];
							Vessels.forEach((vessel) => {
								vessel.Type = vessel.Type.replace("community", "").replace(
									"business",
									""
								);
								customizedUserVessels.unshift({
									...vessel,
									collapse: true,
								});
							});
							setUserVessels(customizedUserVessels);
						})
						.catch(() => {})
						.finally(() => {
							setLoading(false);
						});
				});
			}
		}
	}, [sidebarVisibility, currentUserData.Vessels]);

	React.useEffect(() => {
		return () => {};
	}, [newVLength]);

	return (
		<>
			<Sidebar show={show}>
				<SidebarContent id="sideBarContentVessels">
					<Button
						onClick={() => {
							closeUserSidebars();
							showUserProfile();
						}}
						className="justify-content-start py-3 mb-2 w-100"
						style={{
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
						}}
					>
						<img
							alt="icon"
							src={backIcon}
							style={{ height: 16, width: 8, marginRight: 17 }}
						/>
						<div className="d-flex flex-column align-items-start">
							<h2 className="m-0 p-0" style={{ fontSize: 21 }}>
								{languages[currentLanguage]["user_vessels_header"]}
							</h2>
							{
								<div
									style={{
										color: secondaryTxt,
										fontSize: 14,
									}}
								>
									{/*userData.FirstName} {userData.LastName*/}
								</div>
							}
						</div>
					</Button>
					<div
						className="d-flex flex-column"
						style={{
							minHeight: "calc(100% - 90px)",
							width: "100%",
						}}
					>
						{loading ? (
							<div
								style={{ flex: 1 }}
								className="d-flex w-100 justify-content-center align-items-center"
							>
								<CircularProgress className={classes.progress} />
							</div>
						) : (
							<div
								style={{ flex: 1 }}
								className="d-flex flex-column w-100 justify-content-center align-items-center px-2 pb-4"
							>
								{!userVessels?.length && !inAdding && (
									<div
										className="d-flex flex-column justify-content-center align-items-center"
										style={{ flex: 1 }}
									>
										<img
											src={require("../../assets/svg/empty-state-list-vector.svg")}
											style={{
												width: 121.2,
												height: 102.3,
												objectFit: "contain",
												marginBottom: 55.7,
											}}
										/>
										<span
											style={{
												fontSize: 21,
												color: "#222c4b",
											}}
										>
											{
												languages[currentLanguage][
													"user_vessels_emptystate_header"
												]
											}
										</span>
										{isOwner && (
											<span
												className="dromon-l"
												style={{
													fontSize: 14,
													color: secondaryTxt,
													marginTop: 11,
												}}
											>
												{
													languages[currentLanguage][
														"user_vessels_emptystate_content"
													]
												}
											</span>
										)}
									</div>
								)}

								{(inAdding || !!userVessels?.length + 1) && (
									<div
										className="d-flex w-100 flex-column justify-content-start align-items-center mb-4"
										style={{ flex: 1 }}
									>
										{userVessels?.map((item, _index) => (
											<div
												key={_index}
												className="w-100"
												style={{ fontSize: 12 }}
											>
												<ListItem
													button
													className="py-2"
													style={{
														color: "#222c4b",
														height: 85,
														fontFamily: "inherit",
														borderTop: "solid 1px #edeef2",
													}}
													onClick={() =>
														setUserVessels((state) => {
															return state.map((vi) =>
																vi.ID === item.ID && inEditing !== item.ID
																	? { ...vi, collapse: !item.collapse }
																	: vi
															);
														})
													}
												>
													{inEditing === item.ID ? (
														<>
															<div className="d-flex justify-content-center align-items-center mr-4">
																{!editVVesselType ||
																editVVesselType === "vessel" ? (
																	<i
																		className=""
																		style={{
																			fontSize: 30,
																			width: 30,
																		}}
																	/>
																) : (
																	<img
																		src={require(`../../assets/svg/vesselIcons/${editVVesselType}.svg`)}
																		style={{ width: 30 }}
																	/>
																)}
															</div>

															<div
																className="d-flex flex-column"
																style={{ flex: 1 }}
															>
																<div className="w-100 row">
																	<div className="col-6">
																		<input
																			style={{ fontSize: 12, color: "#222c4b" }}
																			value={editVName}
																			className="form-control mb-1"
																			placeholder={
																				languages[currentLanguage][
																					"user_vessels_name_placeholder"
																				]
																			}
																			onChange={(e) =>
																				setEditVName(e.target.value)
																			}
																		/>
																	</div>
																	<div className="col-5">
																		<select
																			onChange={(event) =>
																				setEditVFlag(event.target.value)
																			}
																			disabled={editVVesselType === "kayak"}
																			value={editVFlag}
																			style={{ fontSize: 12, color: "#222c4b" }}
																			className="form-control mb-1"
																		>
																			<option
																				value={null}
																				style={{ color: "#edeef2" }}
																			>
																				{
																					languages[currentLanguage][
																						"user_vessels_flag_placeholder"
																					]
																				}
																			</option>
																			{iso3311a2.getCodes().map((flg, _idx) => (
																				<option key={_idx} value={flg}>
																					{flg}
																				</option>
																			))}
																		</select>
																	</div>
																</div>

																<div className="w-100 row">
																	<div className="col-6">
																		<select
																			disabled={true}
																			value={editVVesselType}
																			onChange={(event) =>
																				setEditVVesselType(event.target.value)
																			}
																			style={{ fontSize: 12, color: "#222c4b" }}
																			className="form-control mb-1"
																		>
																			<option value="kayak">
																				{
																					languages[currentLanguage][
																						"uri_user_tag_interests_kayak"
																					]
																				}
																			</option>
																			<option value="sailboat">
																				{
																					languages[currentLanguage][
																						"filter_vesseltype_sailing"
																					]
																				}
																			</option>
																			<option value="motorboat">
																				{
																					languages[currentLanguage][
																						"filter_vesseltype_motorboat"
																					]
																				}
																			</option>
																		</select>
																	</div>
																	<div className="col-5">
																		<input
																			value={editVMMSI}
																			style={{ fontSize: 12, color: "#222c4b" }}
																			className="form-control mb-1"
																			placeholder={
																				languages[currentLanguage][
																					"user_vessels_mmsi_placeholder"
																				]
																			}
																			disabled={editVVesselType === "kayak"}
																			onChange={(event) =>
																				setEditVMMSI(event.target.value)
																			}
																		/>
																	</div>
																</div>
															</div>
														</>
													) : (
														<>
															{!item.Type ? (
																<i
																	className=""
																	style={{ fontSize: 30, width: 30 }}
																/>
															) : (
																<img
																	src={require(`../../assets/svg/vesselIcons/${item.Type}.svg`)}
																	style={{ width: 30 }}
																/>
															)}
															<div style={{ flex: 1 }} className="px-4 mx-0">
																<h3 style={{ fontSize: 14, color: "#222c4b" }}>
																	{item.Name}{" "}
																	{item.Flag ? "(" + item.Flag + ")" : ""}
																</h3>
																<span
																	style={{ fontSize: 12, color: secondaryTxt }}
																>
																	<span>
																		{
																			languages[currentLanguage][
																				vesselDictionary[item.Type]
																			]
																		}
																	</span>{" "}
																	<span>{item.MMSI}</span>
																</span>
															</div>
														</>
													)}

													<span
														style={{
															color: "#9096a6",
															opacity: 0.5,
															fontSize: 16,
														}}
													>
														{item.collapse ? (
															<i className="fas fa-fw fa-chevron-down" />
														) : (
															<i className="fas fa-fw fa-chevron-up" />
														)}
													</span>
												</ListItem>
												<Collapse
													in={!item.collapse}
													timeout="auto"
													unmountOnExit
													className="p-2"
													style={{
														borderBottom: "solid 1px #edeef2",
														color: secondaryTxt,
													}}
												>
													{isOwner && inEditing === item.ID ? (
														<div className="d-flex">
															<div className="mr-2" style={{ flex: 1 }}>
																<div className="d-flex justify-content-between">
																	<label>
																		{
																			languages[currentLanguage][
																				"user_vessel_length"
																			]
																		}{" "}
																		({currentUserSettings.DepthUnits})
																	</label>
																	<input
																		type="number"
																		min="0"
																		style={{
																			fontSize: 12,
																			color: "#222c4b",
																			width: 80,
																		}}
																		value={editVLength}
																		onChange={(e) =>
																			setEditVLength(e.target.value)
																		}
																		className="form-control mb-1"
																	/>
																</div>

																<div className="d-flex justify-content-between">
																	<label>
																		{
																			languages[currentLanguage][
																				"user_vessel_height"
																			]
																		}{" "}
																		({currentUserSettings.DepthUnits})
																	</label>
																	<input
																		type="number"
																		min="0"
																		style={{
																			fontSize: 12,
																			color: "#222c4b",
																			width: 80,
																		}}
																		disabled={editVVesselType === "kayak"}
																		value={editVHeight}
																		onChange={(e) =>
																			setEditVHeight(e.target.value)
																		}
																		className="form-control mb-1"
																	/>
																</div>
																<div className="d-flex justify-content-between">
																	<label>
																		{
																			languages[currentLanguage][
																				"profile_settings_units_speed"
																			]
																		}{" "}
																		({currentUserSettings.SpeedUnit})
																	</label>
																	<input
																		type="number"
																		min="0"
																		style={{
																			fontSize: 12,
																			color: "#222c4b",
																			width: 80,
																		}}
																		// disabled={editVVesselType === "kayak"}
																		value={editVSpeed}
																		onChange={(e) =>
																			setEditVSpeed(e.target.value)
																		}
																		className="form-control mb-1"
																	/>
																</div>
															</div>

															<div className="" style={{ flex: 1 }}>
																<div className="d-flex justify-content-between">
																	<label>
																		{
																			languages[currentLanguage][
																				"user_vessel_draft"
																			]
																		}{" "}
																		({currentUserSettings.DepthUnits})
																	</label>
																	<input
																		type="number"
																		min="0"
																		style={{
																			fontSize: 12,
																			color: "#222c4b",
																			width: 80,
																		}}
																		value={editVDraft}
																		onChange={(e) =>
																			setEditVDraft(e.target.value)
																		}
																		className="form-control mb-1"
																	/>
																</div>

																<div className="d-flex justify-content-between">
																	<label>
																		{
																			languages[currentLanguage][
																				"user_vessel_fuel"
																			]
																		}{" "}
																		({currentUserSettings.FuelUnit}/hr)
																	</label>
																	<input
																		type="number"
																		min="0"
																		style={{
																			fontSize: 12,
																			color: "#222c4b",
																			width: 80,
																		}}
																		disabled={editVVesselType === "kayak"}
																		value={editVFuel}
																		onChange={(e) =>
																			setEditVFuel(e.target.value)
																		}
																		className="form-control mb-1"
																	/>
																</div>
															</div>
														</div>
													) : (
														<div
															className="d-flex mb-2"
															style={{
																fontSize: 12,
																color: "#222c4b",
															}}
														>
															<div className="mr-2" style={{ flex: 1 }}>
																<div className="d-flex justify-content-between">
																	<label style={{ color: secondaryTxt }}>
																		{
																			languages[currentLanguage][
																				"user_vessel_length"
																			]
																		}
																	</label>
																	{item.LengthMeters
																		? Number.parseFloat(
																				convertDepthToMyUnit(
																					item.LengthMeters,
																					currentUserSettings.DepthUnits
																				)
																		  ).toFixed(2)
																		: "N/A"}
																	{item.LengthMeters && item.LengthMeters > 0
																		? currentUserSettings.DepthUnits
																		: ""}
																</div>

																<div className="d-flex justify-content-between">
																	<label style={{ color: secondaryTxt }}>
																		{
																			languages[currentLanguage][
																				"user_vessel_height"
																			]
																		}
																	</label>
																	{item.Type === "kayak"
																		? "N/A"
																		: item.BeamMeters
																		? Number.parseFloat(
																				convertDepthToMyUnit(
																					item.BeamMeters,
																					currentUserSettings.DepthUnits
																				)
																		  ).toFixed(2)
																		: "N/A"}
																	{item.Type !== "kayak"
																		? item.BeamMeters && item.BeamMeters > 0
																			? currentUserSettings.DepthUnits
																			: ""
																		: ""}
																</div>
																<div className="d-flex justify-content-between">
																	<label style={{ color: secondaryTxt }}>
																		{
																			languages[currentLanguage][
																				"profile_settings_units_speed"
																			]
																		}
																	</label>
																	{item.SpeedKnots && item.SpeedKnots > 0
																		? Number.parseFloat(
																				convertSpeedToMyUnit(
																					item.SpeedKnots,
																					currentUserSettings.SpeedUnit
																				)
																		  ).toFixed(2)
																		: "N/A"}
																	{true
																		? item.SpeedKnots && item.SpeedKnots > 0
																			? currentUserSettings.SpeedUnit
																			: ""
																		: ""}
																</div>
															</div>

															<div className="" style={{ flex: 1 }}>
																<div className="d-flex justify-content-between">
																	<label style={{ color: secondaryTxt }}>
																		{
																			languages[currentLanguage][
																				"user_vessel_draft"
																			]
																		}
																	</label>
																	{item.DraftMeters
																		? Number.parseFloat(
																				convertDepthToMyUnit(
																					item.DraftMeters,
																					currentUserSettings.DepthUnits
																				)
																		  ).toFixed(2)
																		: "N/A"}
																	{item.DraftMeters && item.DraftMeters > 0
																		? currentUserSettings.DepthUnits
																		: ""}
																</div>

																<div className="d-flex justify-content-between">
																	<label style={{ color: secondaryTxt }}>
																		{
																			languages[currentLanguage][
																				"user_vessel_fuel"
																			]
																		}
																	</label>
																	{item.Type === "kayak"
																		? "N/A"
																		: item.FuelConsumptionLiters
																		? convertFuelToMyUnit(
																				item.FuelConsumptionLiters,
																				currentUserSettings.FuelUnit
																		  )
																		: "N/A"}{" "}
																	{item.Type === "kayak"
																		? ""
																		: item.FuelConsumptionLiters &&
																		  item.FuelConsumptionLiters > 0
																		? currentUserSettings.FuelUnit
																		: ""}
																	{item.Type === "kayak"
																		? ""
																		: item.FuelConsumptionLiters &&
																		  item.FuelConsumptionLiters > 0
																		? "/hr"
																		: ""}
																</div>
															</div>
														</div>
													)}
													{isOwner && (
														<>
															{inEditing !== item.ID && (
																<div className="d-flex justify-content-between pt-2">
																	<div></div>
																	<Button
																		onClick={() => onEditingVessel(item)}
																		style={{
																			fontFamily: "inherit",
																			textTransform: "initial",
																			fontSize: 16,
																			color: filterHighlightColor,
																		}}
																	>
																		{
																			languages[currentLanguage][
																				"profile_vessel_edit"
																			]
																		}
																	</Button>
																</div>
															)}

															{inEditing === item.ID && (
																<div className="d-flex justify-content-between pt-2">
																	<div className="d-flex align-items-center">
																		<IconButton
																			onClick={() => onDeleteVessel(item.ID)}
																			disabled={loadingDelete === item.ID}
																			style={{ color: "#ff0000" }}
																			className="mr-2"
																		>
																			{loadingDelete !== item.ID ? (
																				<img
																					alt="delete-icon"
																					src={require("../../assets/svg/delete-ic.svg")}
																					style={{ width: 22.1, height: 22 }}
																				/>
																			) : (
																				<i className="fas fa-spin fa-circle-notch mr-2" />
																			)}
																		</IconButton>

																		{currentUserData.Type !== "partner" && (
																			<IconButton
																				style={{
																					color: editVSelected
																						? "#FFF"
																						: filterHighlightColor,
																					fontSize: 22,
																				}}
																				onClick={() =>
																					setEditVSelected(!editVSelected)
																				}
																			>
																				<img
																					alt="set-default-icon"
																					src={
																						editVSelected
																							? require("../../assets/svg/vesselIcons/star_filled.svg")
																							: require("../../assets/svg/vesselIcons/start.svg")
																					}
																					style={{ width: 22.1, height: 22 }}
																				/>
																			</IconButton>
																		)}
																	</div>
																	<Button
																		disabled={loadingEdit}
																		onClick={() => onEditVessel(item)}
																		style={{
																			fontFamily: "inherit",
																			textTransform: "initial",
																			fontSize: 16,
																			color: filterHighlightColor,
																		}}
																	>
																		{loadingEdit && (
																			<i className="fas fa-spin fa-circle-notch mr-2" />
																		)}{" "}
																		{languages[currentLanguage]["common_save"]}
																	</Button>
																</div>
															)}
														</>
													)}
												</Collapse>
											</div>
										))}

										{inAdding && (
											<div
												className="w-100 p-2"
												style={{
													color: secondaryTxt,
													fontSize: 12,
													borderTop: "solid 1px #edeef2",
													borderBottom: "solid 1px #edeef2",
												}}
											>
												<div className="d-flex mb-2">
													<div className="d-flex justify-content-center align-items-center mr-4">
														{!newVVesselType || newVVesselType === "vessel" ? (
															<i
																className=""
																style={{
																	fontSize: 30,
																	width: 30,
																}}
															/>
														) : (
															<img
																src={require(`../../assets/svg/vesselIcons/${newVVesselType}.svg`)}
																style={{ width: 30 }}
															/>
														)}
													</div>

													<div
														className="d-flex flex-column"
														style={{ flex: 1 }}
													>
														<div className="w-100 row">
															<div className="col-6">
																<input
																	style={{ fontSize: 12, color: "#222c4b" }}
																	className="form-control mb-1"
																	placeholder="Name"
																	onChange={(e) => setNewVName(e.target.value)}
																/>
															</div>
															<div className="col-5">
																<select
																	onChange={(event) =>
																		setNewVFlag(event.target.value)
																	}
																	style={{ fontSize: 12, color: "#222c4b" }}
																	className="form-control mb-1"
																	disabled={newVVesselType === "kayak"}
																>
																	<option
																		value={null}
																		style={{ color: "#edeef2" }}
																	>
																		Flags
																	</option>
																	{iso3311a2.getCodes().map((flg, _idx) => (
																		<option key={_idx} value={flg}>
																			{flg}
																		</option>
																	))}
																</select>
															</div>
														</div>

														<div className="w-100 row">
															<div className="col-6">
																<select
																	onChange={(event) =>
																		setNewVVesselType(event.target.value)
																	}
																	style={{ fontSize: 12, color: "#222c4b" }}
																	className="form-control mb-1"
																>
																	<option value="kayak">
																		{
																			languages[currentLanguage][
																				"uri_user_tag_interests_kayak"
																			]
																		}
																	</option>
																	<option value="sailboat">
																		{
																			languages[currentLanguage][
																				"filter_vesseltype_sailing"
																			]
																		}
																	</option>
																	<option value="motorboat">
																		{
																			languages[currentLanguage][
																				"filter_vesseltype_motorboat"
																			]
																		}
																	</option>
																</select>
															</div>
															<div className="col-5">
																<input
																	style={{ fontSize: 12, color: "#222c4b" }}
																	className="form-control mb-1"
																	placeholder="MMSI"
																	disabled={newVVesselType === "kayak"}
																	onChange={(event) =>
																		setNewVMMSI(event.target.value)
																	}
																/>
															</div>
														</div>
													</div>

													<div></div>
												</div>

												<div className="d-flex mb-2">
													<div className="mr-2" style={{ flex: 1 }}>
														<div className="d-flex justify-content-between">
															<label>
																{
																	languages[currentLanguage][
																		"user_vessel_length"
																	]
																}{" "}
																({currentUserSettings.DepthUnits})
															</label>
															<input
																type="number"
																min="0"
																style={{
																	fontSize: 12,
																	color: "#222c4b",
																	width: 80,
																}}
																onChange={(e) => setNewVLength(e.target.value)}
																className="form-control mb-1"
															/>
														</div>

														<div className="d-flex justify-content-between">
															<label>
																{
																	languages[currentLanguage][
																		"user_vessel_height"
																	]
																}{" "}
																({currentUserSettings.DepthUnits})
															</label>
															<input
																type="number"
																min="0"
																style={{
																	fontSize: 12,
																	color: "#222c4b",
																	width: 80,
																}}
																onChange={(e) => setNewVHeight(e.target.value)}
																className="form-control mb-1"
																disabled={newVVesselType === "kayak"}
															/>
														</div>
														<div className="d-flex justify-content-between">
															<label>
																{
																	languages[currentLanguage][
																		"profile_settings_units_speed"
																	]
																}{" "}
																({currentUserSettings.SpeedUnit})
															</label>
															<input
																type="number"
																min="0"
																style={{
																	fontSize: 12,
																	color: "#222c4b",
																	width: 80,
																}}
																onChange={(e) => setNewVSpeed(e.target.value)}
																className="form-control mb-1"
																// disabled={newVVesselType === "kayak"}
															/>
														</div>
													</div>

													<div className="" style={{ flex: 1 }}>
														<div className="d-flex justify-content-between">
															<label>
																{
																	languages[currentLanguage][
																		"user_vessel_draft"
																	]
																}{" "}
																({currentUserSettings.DepthUnits})
															</label>
															<input
																type="number"
																min="0"
																style={{
																	fontSize: 12,
																	color: "#222c4b",
																	width: 80,
																}}
																onChange={(e) => setNewVDraft(e.target.value)}
																className="form-control mb-1"
															/>
														</div>

														<div className="d-flex justify-content-between">
															<label>
																{languages[currentLanguage]["user_vessel_fuel"]}{" "}
																({currentUserSettings.FuelUnit}/hr)
															</label>
															<input
																type="number"
																min="0"
																style={{
																	fontSize: 12,
																	color: "#222c4b",
																	width: 80,
																}}
																onChange={(e) => setNewVFuel(e.target.value)}
																className="form-control mb-1"
																disabled={newVVesselType === "kayak"}
															/>
														</div>
													</div>
												</div>

												<div className="d-flex justify-content-between align-items-center">
													<IconButton
														onClick={() => {
															resetForm();
															setInAdding(false);
														}}
													>
														<img
															alt="delete-icon"
															src={require("../../assets/svg/delete-ic.svg")}
															style={{ width: 22.1, height: 22 }}
														/>
													</IconButton>

													<Button
														onClick={onAddVessel}
														disabled={loadingAdd}
														style={{
															fontFamily: "inherit",
															textTransform: "initial",
															fontSize: 16,
															color: filterHighlightColor,
														}}
													>
														{loadingAdd && (
															<i className="fas fa-spin fa-circle-notch mr-2" />
														)}{" "}
														{languages[currentLanguage]["common_save"]}
														{/* Save */}
													</Button>
												</div>
											</div>
										)}
									</div>
								)}

								{isOwner && (
									<Button
										onClick={() => {
											const sidebar = window.document.getElementById(
												"sideBarContentVessels"
											);

											setTimeout(() => {
												sidebar.scrollTo(0, sidebar.scrollHeight);
											}, 100);
											setNewVVesselType("kayak");
											setInAdding(true);
										}}
										className="w-100"
										disabled={inAdding}
										style={{
											backgroundColor: inAdding
												? secondaryTxt
												: filterHighlightColor,
											color: "#FFF",
											fontFamily: "inherit",
											fontSize: 16,
											textTransform: "initial",
										}}
									>
										{
											languages[currentLanguage][
												"user_vessels_add_vessel_button"
											]
										}
									</Button>
								)}
							</div>
						)}
					</div>
				</SidebarContent>
			</Sidebar>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={!!(snackbarNotice.type.length && snackbarNotice.msg.length)}
				autoHideDuration={4000}
				onClose={() => setSnackbarNotice({ type: "", msg: "" })}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity={snackbarNotice.type}
					onClose={() => setSnackbarNotice({ type: "", msg: "" })}
				>
					{snackbarNotice.msg}
				</MuiAlert>
			</Snackbar>
		</>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setStoredUserData: (data) => dispatch(setStoredUserData(data)),
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserVessels);

import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress, IconButton } from "@material-ui/core";

// HELPERS
import { logEvent_ } from "../../helpers/analytics";

// API
import { ApiClient } from "../../ApiClient";

// SELECTORS
import { setGlobalWeatherDate } from "../../store/features/map/actions";

// SELECTORS
// import { currentTripItinerary } from "../../store/features/map/selectors";
// import { languages } from "../../store/features/translation/Languages/";
// import { currentLang } from "../../store/features/translation/selectors";

// ICONS
// import nok_ico from "../../assets/img/legendIcons/marker_nok_ic.svg";
// import warn_ico from "../../assets/img/legendIcons/marker_warn_ic.svg";
// import ok_ico from "../../assets/img/legendIcons/marker_ok_ic.svg";

// STYLES
import { Wrapper } from "./styled";
import { useStyles } from "../map/Popup";
import { sidebarWidth } from "../utils/SideBar/styled";
import "./style.css";

function GWBottomBrand({ setGlobalWeatherDate }) {
	// SELECTORS
	// const currentTripItinerary_ = useSelector(currentTripItinerary);
	// const currentLanguage = useSelector(currentLang);

	// STATES
	const [weatherData, setWeatherData] = React.useState({});
	const [, setCurrentWeatherDateKey] = React.useState(null);
	const [currentWeatherDate, setCurrentWeatherDate] = React.useState({});
	const [loading, setLoading] = React.useState(true);

	// STYLES
	const classes = useStyles();

	// DATA
	// const weatherDegree_ = [
	// 	languages[currentLanguage]["place_details_weather_unsuitable"],
	// 	languages[currentLanguage]["place_details_weather_warning"],
	// 	languages[currentLanguage]["place_details_weather_suitable"],
	// ];

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		async function init() {
			if (!!!true) return;

			try {
				await Promise.all([ApiClient.getGlobalWeatherPrediction()]).then(
					(res) => {
						const weatherData_ = res[0];
						const currentDate = new Date(Date.now());

						setWeatherData(weatherData_);

						Object.keys(weatherData_).forEach((dateKey) => {
							const retrievedDate = new Date(dateKey);

							const strRetrievedDate = retrievedDate.toString().slice(0, 16);

							const strCurrentDate = currentDate.toString().slice(0, 16);

							if (strRetrievedDate === strCurrentDate) {
								const needleHour = currentDate.getHours();
								const closestTimestamp = weatherData_[dateKey].reduce(
									(a, b) => {
										return Math.abs(new Date(b).getHours() - needleHour) <
											Math.abs(new Date(a).getHours() - needleHour)
											? b
											: a;
									}
								);

								setCurrentWeatherDateKey(dateKey);
								setCurrentWeatherDate({
									Timestamp: closestTimestamp,
									weatherDateKey: dateKey,
								});
								setGlobalWeatherDate(closestTimestamp);
							}
						});
					}
				);
			} catch (e) {
			} finally {
				setLoading(false);
			}
		}
		init();
	}, []);

	return (
		<Wrapper
			visible={true}
			className="d-flex flex-row align-align-items-sm-stretch p-2"
		>
			<div
				className="d-flex flex-row align-items-center justify-content-center"
				style={{ width: sidebarWidth }}
			>
				{/* <span className="mr-4 desc-column dromon-l">
					{languages[currentLanguage]["global_weather_info"]}
				</span>

				<div className="d-flex flex-column justify-content-between legends-column dromon-l">
					{weatherDegree_.map((item, index) => (
						<div key={index}>
							<img
								src={[nok_ico, warn_ico, ok_ico][index]}
								style={{
									width: 17,
									height: 14,
									display: "inline-block",
								}}
							/>{" "}
							{item}
						</div>
					))}
				</div> */}
			</div>

			<div className="d-flex align-items-center justify-content-center">
				<IconButton
					className="shadow-sm bg-white"
					style={{
						height: 30,
						width: 30,
						padding: 0,
						fontSize: 12,
					}}
					onClick={() => {
						const GWHW = window.document.getElementById("GWHW");
						GWHW.scrollBy({
							left: -100,
							behavior: "smooth",
						});
					}}
				>
					<i className="fa fa-fw fa-chevron-left" />
				</IconButton>
			</div>
			<div id="GWHW" className="weather-hours-wrapper">
				{loading ? (
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ flex: 1 }}
					>
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<>
						{!!Object.keys(weatherData).length &&
							Object.keys(weatherData).map((dateKey, index) => (
								<div
									key={index}
									style={{
										color: "#8f96a7",
										fontSize: 12,
									}}
								>
									<h2
										className="mb-1"
										style={{
											fontSize: 12,
											color:
												currentWeatherDate.weatherDateKey === dateKey
													? "#0078ff"
													: "#8f96a7",
										}}
									>
										{new Date(dateKey).toDateString()}
									</h2>
									<div className="d-flex align-items-center">
										{weatherData[dateKey].map((weatherDate, index) => (
											<Button
												key={index}
												className="text-center"
												style={{
													flex: 1,
													height: 35,
													width: 35,
													minWidth: "initial",
													marginRight: 4,
													borderRadius: 0,
													fontSize: 12,
													color: "#222c4b",
													...(currentWeatherDate.Timestamp === weatherDate &&
													currentWeatherDate.weatherDateKey === dateKey
														? {
																color: "#0078ff",
																backgroundColor: "#e5f1ff",
																borderRadius: 6,
														  }
														: {}),
												}}
												onClick={() => {
													// EVENT
													logEvent_("react_explore_weather_datetime_selected");
													//

													setGlobalWeatherDate(weatherDate);
													setCurrentWeatherDate({
														Timestamp: weatherDate,
														weatherDateKey: dateKey,
													});
												}}
											>
												{new Date(weatherDate).getHours()}
											</Button>
										))}
									</div>
								</div>
							))}
					</>
				)}
			</div>
			<div className="d-flex align-items-center justify-content-center">
				<IconButton
					className="shadow-sm bg-white"
					style={{
						height: 30,
						width: 30,
						padding: 0,
						fontSize: 12,
					}}
					onClick={() => {
						const GWHW = window.document.getElementById("GWHW");
						GWHW.scrollBy({
							left: 100,
							behavior: "smooth",
						});
					}}
				>
					<i className="fa fa-fw fa-chevron-right" />
				</IconButton>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setGlobalWeatherDate: (newDate) => dispatch(setGlobalWeatherDate(newDate)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GWBottomBrand);

import React from "react";
import { connect } from "react-redux";
import CountryTelData from "country-telephone-data";

// HELPERS
import { isEmpty } from "../../../../helpers/functions";

// COMPONENTS
import CollapseCard from "../../../utils/CollapseCard";
import CollapseCardBody from "./CollapseCardBody";
import CollapseCardHeader from "./CollapseCardHeader";

function TransferListCollapseCard({
	member = {},
	inEditing = null,
	inAdding = false,
	isLoadingSave = false,
	role = "no-member",
	canEdit = false,
    canComplete = false,
	onEdit = () => {},
    onComplete = () => {},
	onCancel = () => {},
	onSave = () => {},
	onDelete = () => {},
	onCollapse = () => {},
	collapseCardProps = {},
}) {
	// STATES
	const [form, setForm] = React.useState({ CodeNum: 0, ...member });

	// EFFECTS
	React.useEffect(() => {
		if (inEditing && form.CodeNum === 0 && !isEmpty(form.Contact)) {
			const countries = CountryTelData.allCountries;
			let loop = true;
			let i = 0;

			while (loop && i < countries.length) {
				const code = `\\+${countries[i].dialCode}`;

				if (form?.Contact?.match(code)) {
					const formattedCode = code.replace("\\", "");

					setForm({
						...form,
						CodeNum: formattedCode,
						Contact: form.Contact.replace(formattedCode, ""),
					});
					loop = false;
				}
				i++;
			}
		}
	}, [inEditing]);

	return (
		<CollapseCard
			{...{ ...collapseCardProps }}
			key={member.UserId}
			isCollapsed={member.Collapse}
			collapsable={!(inEditing || inAdding)}
			headerContent={
				<CollapseCardHeader
					{...{
						state: form,
						setState: setForm,
						member,
						inAdding,
						inEditing,
						role: role,
					}}
				/>
			}
			BodyContent={
				<CollapseCardBody
					{...{
						member,
						inEditing,
						inAdding,
						state: form,
						setState: setForm,
						isLoadingSave,
						onEdit,
                        onComplete,
						onCancel,
						onDelete,
						canEdit: canEdit,
                        canComplete: canComplete,
						onSave: () =>
							onSave({ ...form, Contact: form.CodeNum + form.Contact }, member),
					}}
				/>
			}
			onCollapse={onCollapse}
		/>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TransferListCollapseCard);

import { Slide } from "react-toastify";

export default {
	position: "bottom-left",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: false,
	transition: Slide,
};

export const getNumberOfDays = (date, isShort = false) => {
	let sentAt = new Date(date).getTime();
	let now = new Date().getTime();
	let days = 0,
		months = 0,
		minutes = 0,
		hours = 0,
		years = 0;
	minutes = Math.floor((now - sentAt) / (1000 * 60));
	hours = Math.floor(minutes / 60);
	days = Math.floor(hours / 24);
	months = Math.floor(days / 30);
	years = Math.floor(months / 12);
	return minutes <= 60
		? {
				number: minutes,
				key: isShort ? "m" : minutes <= 1 ? "minute" : "minutes",
		  }
		: hours <= 24
		? {
				number: hours,
				key: isShort ? "h" : hours <= 1 ? "hour" : "hours",
		  }
		: days <= 30
		? {
				number: days,
				key: isShort ? "d" : days <= 1 ? "day" : "days",
		  }
		: months <= 12 && months > 1
		? {
				number: months,
				key: isShort ? "M" : months <= 1 ? "month" : "months",
		  }
		: {
				number: years,
				key: isShort ? "Y" : years <= 1 ? "year" : "years",
		  };
};

export const getDuration_ = (date) => {
	let days = 0,
		months = 0,
		minutes = 0,
		hours = 0,
		years = 0;
	minutes = Math.floor(date / (1000 * 60));
	hours = Math.floor(minutes / 60);
	days = Math.floor(hours / 24);
	months = Math.floor(days / 30);
	years = Math.floor(months / 12);
	if (minutes < 60) return minutes + "m";
	let rest = minutes % 60;
	return hours + "h" + rest + "m";
};

export const getDuration = (date, isShort = false) => {
	let days = 0,
		months = 0,
		minutes = 0,
		hours = 0,
		years = 0;
	minutes = Math.floor(date / (1000 * 60));
	hours = Math.floor(minutes / 60);
	days = Math.floor(hours / 24);
	months = Math.floor(days / 30);
	years = Math.floor(months / 12);
	return minutes <= 60
		? {
				number: minutes,
				key: isShort ? "m" : minutes <= 1 ? "minute" : "minutes",
		  }
		: hours <= 24
		? {
				number: hours,
				key: isShort ? "h" : hours <= 1 ? "hour" : "hours",
		  }
		: days <= 30
		? {
				number: days,
				key: isShort ? "d" : days <= 1 ? "day" : "days",
		  }
		: months <= 12 && months > 1
		? {
				number: months,
				key: isShort ? "M" : months <= 1 ? "month" : "months",
		  }
		: {
				number: years,
				key: isShort ? "Y" : years <= 1 ? "year" : "years",
		  };
};

export const getAvg = (reviews) => {
	let c = 0,
		nonRated = 0,
		s = 0;
	reviews.forEach((r) => {
		if (!r.Action.Rating) nonRated++;
		c = r.Action.Rating ? r.Action.Rating : 0;
		s += c;
	});
	return reviews.length - nonRated > 0
		? Math.round((s / (reviews.length - nonRated)) * 10) / 10
		: 0;
};

import React from "react";
import Ratings from "./Ratings";
import { formMainTxt, secondaryTxt } from "../../../constants/colors";
import reviewIcon from "../../../assets/review/noun.svg";
import PropTypes from "prop-types";

const ReviewsHeader = (props) => {
	const { name, numberOfStars, uncheckedStars, days, cover } = props;
	return (
		<div className="container">
			<div className="row align-items-center px-3" style={{ marginBottom: 20 }}>
				<div className="col p-0">
					<img
						src={cover}
						style={{ height: 50, width: 50, borderRadius: 5 }}
						alt=""
					/>
				</div>
				<div className="col-9 ml-0 px-0">
					<span
						className="col pl-0 mt-1"
						style={{ color: formMainTxt, fontSize: 18 }}
					>
						{name}
					</span>
					<div className="d-flex flex-row" style={{ marginTop: 11 }}>
						<Ratings
							numberOfStars={numberOfStars}
							uncheckedStars={uncheckedStars}
						/>
						<span
							className="ml-2"
							style={{ color: secondaryTxt, fontSize: 14 }}
						>
							{days.number} {days.key} ago
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

ReviewsHeader.propTypes = {
	numberOfStars: PropTypes.arrayOf(PropTypes.any),
	uncheckedStars: PropTypes.arrayOf(PropTypes.any),
	name: PropTypes.string,
	days: PropTypes.number,
};

export default ReviewsHeader;

import React, { useState, useEffect } from "react";
import { filterHighlightColor, formMainTxt } from "../../../constants/colors";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Button from "../Components/Button";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { connect, useSelector } from "react-redux";
import checkIcon from "../../../assets/Check.svg";
import { logEvent_ } from "../../../helpers/analytics";

export const getNumberOfDays = (start, end) => {
	const date1 = new Date(start);
	const date2 = new Date(end);
	const oneDay = 1000 * 60 * 60 * 24;
	const diffInTime = date2.getTime() - date1.getTime();
	const diffInDays = Math.round(diffInTime / oneDay);

	return diffInDays;
};
const Second = (props) => {
	const currentLanguage = useSelector(currentLang);
	const [checked, setChecked] = useState(false);
	const [data, setData] = useState({
		numberOfDays: props.state?.DurationDays ? props.state?.DurationDays : 0,
	});
	const [state, setState] = useState([
		{
			startDate: props.state?.StartDate
				? new Date(props.state?.StartDate)
				: new Date(),
			endDate: props.state?.DurationDays
				? new Date(
						new Date(props.state?.StartDate).getTime() +
							(props.state?.DurationDays - 1) * 24 * 3600 * 1000
				  )
				: new Date(),
			key: "selection",
		},
	]);

	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};
	const handleBack = () => {
		props.handleBack({
			step: 2,
		});
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_stepdays_nextbtn_pressed");

		const nbDays = getNumberOfDays(state[0].startDate, state[0].endDate) + 1;

		props.handleNext({
			step: 2,
			startDate: !checked ? state[0].startDate : null,
			numberOfDays: nbDays,
		});
	};

	useEffect(() => {
		setState([
			{
				startDate: props.state?.StartDate
					? new Date(props.state?.StartDate)
					: new Date(),
				endDate:
					props.state?.DurationDays && props.state?.StartDate
						? new Date(
								new Date(props.state?.StartDate).getTime() +
									(props.state?.DurationDays - 1) * 24 * 3600 * 1000
						  )
						: props.state?.DurationDays
						? new Date(
								new Date().getTime() +
									(props.state?.DurationDays - 1) * 24 * 3600 * 1000
						  )
						: new Date(),
				key: "selection",
			},
		]);
		setChecked(props.state?.StartDate === null ? true : false);
	}, [props.state, props.finished]);

	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<h3
						className="my-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						Creating a New Trip
					</h3>
					<h2 className="mb-4" style={{ fontSize: 22, color: "#222c4b" }}>
						{languages[currentLanguage]["trip_date_header"]}
					</h2>
					<DateRangePicker
						onChange={(item) => {
							setState([item.selection]);
						}}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						// months={2}
						ranges={state}
						direction="horizontal"
						minDate={new Date()}
					/>
					<h3
						className="mt-3 pr-4 text-right"
						style={{ fontSize: 16, color: "#222c4b" }}
					>
						{languages[currentLanguage]["trip_date_total_days"]}{" "}
						<span style={{ color: filterHighlightColor }}>
							{getNumberOfDays(state[0].startDate, state[0].endDate) + 1}
						</span>
					</h3>
					<div className="d-flex align-items-center">
						<div
							onClick={() =>
								getNumberOfDays(state[0].startDate, state[0].endDate) !== 0
									? setChecked(!checked)
									: {}
							}
							className="d-flex justify-content-center align-items-center p-1 mr-2"
							style={{
								height: 25,
								width: 25,
								borderRadius: 4,
								backgroundColor: checked ? "transparent" : "#dbdee6",
								border: "1px solid #dbdee6",
								cursor: "pointer",
							}}
						>
							{checked && <img src={checkIcon} alt="Accept" />}
						</div>
						<span
							className="dromon-l"
							style={{ fontSize: 12, color: formMainTxt }}
						>
							{languages[currentLanguage]["trip_date_exclude_checkbox"]}
						</span>
					</div>
				</div>
				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Second);

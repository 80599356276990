import React from "react";
import { useDispatch } from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

// STORE
import { toggleShelterNode } from "../../../store/features/map/actions";

// CONSTANTS
import {
	experience,
	filterHighlightColor,
	gray,
} from "../../../constants/colors";

function SetNightShelterModal({
	onSetNightShelter = null,
	handleClose = () => {},
}) {
	// ACTIONS
	const dispatch = useDispatch();

	// LOADING
	const [loading, setLoading] = React.useState(false);

	return (
		<Dialog
			open={!!onSetNightShelter}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			BackdropProps={{ style: { background: experience + "CC" } }}
		>
			<DialogContent className="d-flex flex-column justify-content-center align-items-center text-center">
				<DialogTitle id="alert-dialog-title">
					<h3>{"You need to set an overnight stay"}</h3>
				</DialogTitle>

				<DialogContentText
					id="alert-dialog-description"
					className="mb-5 mx-md-5"
					style={{ color: gray }}
				>
					Please, set your last stop as an overnight stay or choose another
					location as an overnight stay to proceed
				</DialogContentText>

				<DialogActions className="d-flex flex-column">
					<Button
						disabled={loading}
						className="mb-2"
						onClick={() => {
							dispatch(toggleShelterNode());
							setLoading(true);

							setTimeout(() => {
								handleClose();
								setLoading(false);
								// onSetNightShelter(CURRENT_TRIP_ITINERARY);
							}, 500);
						}}
						color="primary"
						variant="contained"
						style={{ backgroundColor: filterHighlightColor, color: "#FFF" }}
						autoFocus
					>
						{loading ? <i className="fa fa-spin fa-circle-notch mr-2" /> : ""}
						Set the last stop
					</Button>
					<Button
						disabled={loading}
						onClick={handleClose}
						style={{ color: filterHighlightColor }}
					>
						Cancel
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}

export default SetNightShelterModal;

import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Button,
	CircularProgress,
	IconButton,
	Snackbar,
} from "@material-ui/core";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { isEmpty } from "../../helpers/functions";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import {
	userData as currentUserData_,
	userProfileId,
} from "../../store/features/user/selectors";

// ACTIONS
import {
	closeUserSidebars,
	showUserProfile,
} from "../../store/features/user/actions";

// ICONS
import bkmIcon from "../../assets/remove-btn.svg";

// STYLES
import {
	DescriptionContainer,
	ImgContainer,
	SubTitle,
	Title,
} from "../map/styled";
import { Sidebar } from "./styled";
import { AuthService } from "../../AuthService";
import { SidebarContent } from "../PlacesDetailsSidebar/styled";
import { languages } from "../../store/features/translation/Languages";
import {
	filterHighlightColor,
	warningBorderColor,
	warningColor,
	whiteColor,
} from "../../constants/colors";
import { useStyles } from "../map/Popup";

const BookMarkList = ({
	dispatch,
	visible,
	bookmarks = [],
	handleClick,
	handleAuth,
	handleDelete,
	showUserProfile,
	closeUserSidebars,
	closeSidebar,
}) => {
	const placeTypeDictionary = {
		beach: "place_type_beach",
		anchorage: "place_type_anchorage",
		cave: "place_type_cave",
		wreck: "place_type_wreck",
		marina: "place_type_marina",
		port: "place_type_port",
		shipyard: "place_type_shipyard",
		boatyard: "place_type_boatyard",
		mooring: "place_type_mooring",
		boatramp: "place_type_boatramp",
	};
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentUserData = useSelector(currentUserData_),
		selectedUserProfileId = useSelector(userProfileId),
		currentLanguage = useSelector(currentLang);

	// DATA
	const [userData, setUserData] = React.useState([]),
		[displayedBookmarks, setDisplayedBookmarks] = React.useState([]),
		[isOwner, setIsOwner] = React.useState(false),
		[loading, setLoading] = React.useState(false);

	// EFFECTS
	React.useEffect(() => {
		if (visible) {
			setIsOwner(false);
			setDisplayedBookmarks(bookmarks ? bookmarks : []);
			if (!isEmpty(selectedUserProfileId)) {
				if (selectedUserProfileId === currentUserData.ID) {
					var new_url =
						"https://" +
						window.location.host +
						"/explore/user/" +
						currentUserData.UserName +
						"/trips";
					window.history.pushState(null, null, new_url);
					setIsOwner(true);
					setUserData(currentUserData);
					createBoundingBoxFromBookmarks();
				} else {
					setLoading(true);
					setIsOwner(false);
					ApiClient.getOtherUserById(selectedUserProfileId).then(
						(userData_) => {
							var new_url =
								"https://" +
								window.location.host +
								"/explore/user/" +
								userData_.UserName +
								"/trips";
							window.history.pushState(null, null, new_url);
							setUserData(userData_);
							ApiClient.getBookMarksOtherUser(selectedUserProfileId)
								.then((reqBM) => {
									setDisplayedBookmarks(reqBM ? reqBM : []);
									createBoundingBoxFromBookmarks();
								})
								.catch(() => {})
								.finally(() => {
									setLoading(false);
								});
						}
					);
				}
			} else {
				createBoundingBoxFromBookmarks();
			}
		} else {
		}
	}, [visible, bookmarks, selectedUserProfileId]);

	function createBoundingBoxFromBookmarks() {
		var isMe = false;
		if (
			(selectedUserProfileId === null && currentUserData === null) ||
			selectedUserProfileId === currentUserData?.ID ||
			(currentUserData !== null && selectedUserProfileId === null)
		) {
			isMe = true;
		}
		if (!isMe) {
			return;
		}
		const toUse = isMe ? bookmarks : displayedBookmarks;
		const allLongs = toUse.map((e) => {
			return e.Place.Location.Longitude;
		});
		const allLats = toUse.map((e) => {
			return e.Place.Location.Latitude;
		});
		if (allLongs.length === 0 || allLats.length === 0) {
			return;
		}
		const minLong = Math.min.apply(null, allLongs); // west
		const maxLong = Math.max.apply(null, allLongs); // east
		const minLat = Math.min.apply(null, allLats); // south
		const maxLat = Math.max.apply(null, allLats); // north

		dispatch({
			type: "bookmarksBounding",
			bookmarks,
			bounds: [
				[minLong, minLat], // southwestern corner of the bounds
				[maxLong, maxLat], // northeastern corner of the bounds
			],
		});
	}

	return (
		<Sidebar show={visible}>
			<SidebarContent>
				{selectedUserProfileId ? (
					<Button
						onClick={() => {
							closeSidebar();
							closeUserSidebars();
							showUserProfile();
						}}
						className="justify-content-start py-3 mb-2 w-100"
						style={{
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
						}}
					>
						<i
							className="fas fa-fw fa-chevron-left mr-3"
							style={{ fontSize: 20 }}
						/>
						<div className="d-flex flex-column align-items-start">
							<h2 className="m-0 p-0" style={{ fontSize: 21 }}>
								{languages[currentLanguage]["nexttrip_header"]}
							</h2>
							<div
								className="dromon-l"
								style={{
									color: "#8f96a7",
									fontWeight: "300",
									fontSize: 12,
								}}
							>
								{isOwner ? (
									<>
										{languages[currentLanguage]["nexttrip_count_part_1"] +
											" " +
											bookmarks.length +
											" " +
											languages[currentLanguage]["nexttrip_count_part_2"]}
									</>
								) : (
									<>
										{userData.FirstName} {userData.LastName}
									</>
								)}
							</div>
						</div>
					</Button>
				) : (
					<div
						className="w-100 pb-3 px-4 mb-2 w-100"
						style={{
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
						}}
					>
						<div className="d-flex" style={{ paddingTop: 24 }}>
							<div style={{ flex: 1 }}>
								<h2
									className="p-0"
									style={{ fontSize: 21, marginBottom: 11, color: "#222c4b" }}
								>
									{languages[currentLanguage]["nexttrip_header"]}
								</h2>
								<div
									style={{
										color: "#8f96a7",
										fontWeight: "300",
										fontSize: 12,
									}}
								>
									{languages[currentLanguage]["nexttrip_count_part_1"]}{" "}
									{displayedBookmarks.length}{" "}
									{languages[currentLanguage]["nexttrip_count_part_2"]}
								</div>
							</div>
							<IconButton
								onClick={closeSidebar}
								component="span"
								style={{
									height: 45,
									width: 45,
								}}
							>
								<img
									src={require("../../assets/close.svg")}
									style={{ height: 15, width: 15 }}
								/>
							</IconButton>
						</div>
					</div>
				)}
				<div
					className="d-flex flex-column"
					style={{
						marginTop: 0,
						minHeight: "calc(100% - 110px)",
						width: "100%",
					}}
				>
					{loading ? (
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<div
							style={{ flex: 1 }}
							className="d-flex flex-column h-100 w-100 justify-content-center align-items-center px-4 pb-4"
						>
							{!displayedBookmarks?.length && (
								<div
									className="d-flex flex-column justify-content-center align-items-center"
									style={{ flex: 1 }}
								>
									<img
										src={require("../../assets/svg/empty-trip-list-vector.svg")}
										style={{
											height: "20%",
											objectFit: "contain",
											marginBottom: 40.7,
										}}
									/>
									<span
										align="center"
										style={{
											fontSize: 21,
											color: "#222c4b",
										}}
									>
										{languages[currentLanguage]["nexttrip_emptystate_header"]}
									</span>
									<span
										align="center"
										style={{
											fontSize: 14,
											color: "#8f96a7",
											marginTop: 11,
										}}
									>
										{languages[currentLanguage]["nexttrip_emptystate_content"]}
									</span>
								</div>
							)}

							<div
								className="w-100 flex-column justify-content-start align-items-center mb-4"
								style={{
									flex: 1,
									display: displayedBookmarks.length ? "flex" : "none",
								}}
							>
								{!!displayedBookmarks.length &&
									displayedBookmarks?.map((bookmark, _index) => (
										<div
											key={_index}
											style={{ border: "none", backgroundColor: whiteColor }}
											className="flex-1 d-flex w-100 my-2 px-0"
										>
											<div
												style={{
													backgroundPosition: "center",
													backgroundSize: "cover",
													backgroundImage: `url(${
														bookmark.Place.CoverImage?.length
															? bookmark.Place.CoverImage
															: `https://keeanostage.blob.core.windows.net/0000000000000000000/placeholders/placeholder_${bookmark.Place.Type}.png`
													})`,
													height: 115,
													width: 115,
													borderRadius: 5,
													cursor: "pointer",
												}}
												className="d-flex justify-content-center align-items-center"
												onClick={() => {
													handleClick(
														bookmark.Place.ID,
														bookmark.Place.Name.Latin,
														bookmark.Place.Type
													);
													closeSidebar();
												}}
											>
												{!bookmark.Place.CoverImage?.includes("placeholder") &&
													bookmark.Place.CoverImage?.length && (
														<img
															src={require(`../../assets/svg/placeWhiteIcons/${bookmark.Place.Type}.svg`)}
															style={{ width: 45, height: 40.2 }}
														/>
													)}
											</div>
											<DescriptionContainer
												style={{ borderRadius: 5 }}
												className="flex-auto d-flex flex-row"
											>
												<div
													className="d-flex flex-column pt-3"
													style={{ flex: 1, cursor: "pointer" }}
													onClick={() =>
														handleClick(
															bookmark.Place.ID,
															bookmark.Place.Name.Latin,
															bookmark.Place.Type
														)
													}
												>
													<div style={{ flex: 1 }}>
														<Title>{bookmark.Place.Name.Latin}</Title>
														<SubTitle>
															{
																languages[currentLanguage][
																	placeTypeDictionary[bookmark.Place.Type]
																]
															}
														</SubTitle>
													</div>
												</div>

												{(isOwner || !selectedUserProfileId) && (
													<div className="d-flex flex-column justify-content-center align-items-center">
														<div>
															<IconButton
																onClick={() => handleDelete(bookmark.Place.ID)}
																aria-label={
																	languages[currentLanguage][
																		"profile_edit_upload_photo_hover_button"
																	]
																}
																component="span"
															>
																<img src={bkmIcon} alt="" />
															</IconButton>
														</div>
													</div>
												)}
											</DescriptionContainer>
										</div>
									))}
							</div>

							{bookmarks.length >= 5 && !AuthService.isUserLogedIn() && (
								<div className="px-3">
									<div
										className="p-3 d-flex flex-row align-items-center"
										style={{
											backgroundColor: warningColor,
											borderRadius: 5,
											borderStyle: "solid",
											borderColor: warningBorderColor,
											borderWidth: 1,
										}}
									>
										<p
											className="mb-0 dromon-l"
											style={{ color: warningBorderColor }}
										>
											{languages[currentLanguage]["nexttrip_max_error_msg"]}
										</p>
										<span style={{ color: "white", fontSize: 15 }}>
											<i className="fas fa-times"></i>
										</span>
									</div>
									<Button
										className="p-2 col-12 mt-4"
										onClick={() => handleAuth()}
										style={{
											color: whiteColor,
											backgroundColor: filterHighlightColor,
											border: "none",
											fontSize: 14,
											borderRadius: 5,
										}}
									>
										{languages[currentLanguage]["nexttrip_addmore_button"]}
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			</SidebarContent>
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookMarkList);

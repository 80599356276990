export const filters = ({ map }) => map.filters;
export const filtersSelected = ({ map }) => map.filtersSelected;
export const weather = ({ map }) => map.weather;
export const globalWeatherDate = ({ map }) => map.globalWeatherDate;
export const globalWeatherBrandVisibility = ({ map }) =>
	map.globalWeatherBrandVisibility;
export const placeAttributes = ({ map }) => map.placeAttributes;

// TRIP NAMESPACE
export const tripAttributes = ({ map }) => map.tripAttributes;
export const currentTripItinerary = ({ map }) => map?.currentTripItinerary;
export const selectedItineraryDay = ({ map }) =>
	map?.currentTripItinerary?.selectedDay;
export const selectedItineraryDayNode = ({ map }) =>
	map?.currentTripItinerary?.selectedDay?.selectedNode;
export const tripItineraryVisibility = ({ map }) =>
	map?.currentTripItinerary?.visibility;
export const getTripItineraryDaysList = ({ map }) =>
	map?.currentTripItinerary?.daysList;
export const getTripItineraryDayTotalHours = ({ map }) => {
	let totalHours = 0;

	map.currentTripItinerary.selectedDay.tripNodes.map((item) => {
		totalHours = totalHours + item.Duration;
		return item;
	});

	return totalHours;
};

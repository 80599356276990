import React from "react";
import { Button } from "@material-ui/core";
import { filterHighlightColor } from "../../../constants/colors";

const UnfiledBtn = (props) => {
	const { onClick, text, image, title } = props;
	return (
		<Button
			title={title}
			className="mr-3 text-capitalize"
			variant="outlined"
			component="span"
			style={{
				color: filterHighlightColor,
				fontSize: 14,
				fontWeight: "normal",
				borderRadius: 20,
				backdropFilter: " blur(4px)",
				border: "solid 1px " + filterHighlightColor,
			}}
			onClick={onClick}
		>
			<img src={image} style={{ width: 12, marginRight: 10 }} />
			<span className="deci" style={{ fontSize: 12, marginTop: 2 }}>
				{" "}
				{text}
			</span>
		</Button>
	);
};

export default UnfiledBtn;

import React, { useState, useEffect } from "react";
import MultiselectCheck from "../Components/MultiselectCheck";
import Button from "../Components/Button";
import { connect, useSelector } from "react-redux";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { logEvent_ } from "../../../helpers/analytics";
import { makeStyles } from "@material-ui/core/styles";
import { formMainTxt } from "../../../constants/colors";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		fontFamily: "dromon-l",
		color: formMainTxt,
		borderBottom: "none",
	},
}));

const Sixth = (props) => {
	const classes = useStyles();
	const currentLanguage = useSelector(currentLang);
	const [options, setOptions] = useState([]);
	const tripAttributes = useSelector((state) => state.map.tripAttributes);
	const [options2, setOptions2] = useState([]);
	const [state, setState] = useState(options[0]);
	const [state2, setState2] = useState(options2[0]);
	const [preference, setPreference] = useState(
		props.state?.Preferences ? props.state?.Preferences : ""
	);
	const [arrivalTime, setArrivalTime] = useState(
		props.state?.ArrivalTime ? props.state?.ArrivalTime : "08:30"
	);
	const handleSelect = (option) => {
		setState(option);
	};
	const handleSelect2 = (option) => {
		setState2(option);
	};
	const handleBack = () => {
		props.handleBack({
			step: 6,
		});
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_stepstyle_nextbtn_pressed");

		props.handleNext({
			step: 6,
			tags: [state, state2],
			preference: preference,
			arrivalTime: arrivalTime,
		});
	};
	useEffect(() => {
		let categories = Object.keys(tripAttributes);
		let versions = tripAttributes[categories[2]].Values;
		let versions2 = tripAttributes[categories[3]].Values;
		setOptions(versions);
		setOptions2(versions2);
		setState(
			props.state?.Attributes ? props.state?.Attributes[0] : versions[0]
		);
		setState2(
			props.state?.Attributes ? props.state?.Attributes[1] : versions2[1]
		);
		const attributeSelected = props.state?.Attributes.find((e) =>
			e.includes("style")
		);
		if (attributeSelected) {
			handleSelect2(attributeSelected);
		}

		if (props.state?.ArrivalTime) {
			var val = props.state?.ArrivalTime.substring(2);
			if (val.length === 6) {
				val = val.substring(0, val.length - 1);
				val = val.replaceAll("H", ":");
			} else {
				val = val.replaceAll("H", ":").replaceAll("M", "");
				if (val.length === 4) {
					val = "0" + val;
				}
			}
			setArrivalTime(val);
		}

		setPreference(props.state?.Preferences ? props.state?.Preferences : "");
	}, [tripAttributes, props.state, props.finished]);
	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
						marginBottom: 40,
					}}
				>
					<div>
						<h3
							className="my-2 dromon-l"
							style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
						>
							{props.state?.Type === "business" &&
							props.state?.State !== "template"
								? languages[currentLanguage]["trip_create_new_business_trip"]
								: languages[currentLanguage]["trip_create_new_template_trip"]}
						</h3>
						<h2 className="my-4" style={{ fontSize: 22, color: "#222c4b" }}>
							{languages[currentLanguage]["trip_attr_style"]}
						</h2>
						<div>
							{options2.map((option) => (
								<MultiselectCheck
									handleSelect={() => handleSelect2(option)}
									text={
										languages[currentLanguage][option.replaceAll(":", "_")]
											? languages[currentLanguage][option.replaceAll(":", "_")]
											: option
									}
									key={option}
									selected={state2 === option}
								/>
							))}
						</div>
					</div>

					{props.state?.Type === "business" &&
						props.state?.State !== "template" && (
							<div className="row" style={{ paddingLeft: 20 }}>
								<h3
									className="mt-3 p-0 col-12"
									style={{ color: formMainTxt, fontSize: 17 }}
								>
									{languages[currentLanguage]["trip_wizard_extra_preferences"]}
								</h3>
								<div className="">
									<textarea
										type="text"
										style={{ marginTop: 14, height: 107, width: 380 }}
										value={preference}
										onChange={(e) => setPreference(e.target.value)}
										placeholder={
											languages[currentLanguage][
												"trip_attr_extrapref_placeholder"
											]
										}
									/>
								</div>

								<h3
									className="mt-3 p-0 col-12"
									style={{ color: formMainTxt, fontSize: 17 }}
								>
									Estimated Arrival Time
								</h3>

								<TextField
									id="time"
									label=""
									type="time"
									value={arrivalTime}
									onChange={(e) => setArrivalTime(e.target.value)}
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300,
									}}
									size="small"
								/>
							</div>
						)}
				</div>

				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (_state) => {
	return {};
};

const mapDispatchToProps = (_dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sixth);

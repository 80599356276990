import React from "react";
import { connect, useSelector } from "react-redux";
import { IconButton, Button, Snackbar } from "@material-ui/core";
import dateFormat from "date-fns/format";
import Alert from "@material-ui/lab/Alert";
import { confirmAlert } from "react-confirm-alert";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { exportToXLSX } from "../../helpers/functions";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { userData, userProfileId } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import { closeUserSidebars } from "../../store/features/user/actions";

// CONSTANTS
import { filterHighlightColor, whiteColor } from "../../constants/colors";

// COMPONENTS
import SideBar from "../utils/SideBar";
import TransferListCollapseCard from "./components/transferList/TransferListCollapseCard.jsx";

// SVG ICONS
import FullExportListIcon from "../../assets/svg/crewList/full_export_list_ic.svg";

// STYLES
import "react-confirm-alert/src/react-confirm-alert.css";

const TransferList = ({
	visible,
	trip = {},
	deadlineReached = false,
	closeSidebar,
}) => {
	// SELECTORS
	const currentUserData = useSelector(userData),
		selectedUserProfileId = useSelector(userProfileId),
		currentLanguage = useSelector(currentLang);

	// DATA
	const [initialFormValues, setInitialFormValues] = React.useState({
		PickUpDate: null,
		PickUpPlace: "",
		DestinationPlace: "",
		NumberOfAdults: 1,
		Contact: "",
		Name: "",
		IsArrival: true,
		BaseStationName: "",
	});

	// STATES
	const [loading, setLoading] = React.useState(false),
		[snackbarClipboard, setSnackbarClipboard] = React.useState(false),
		[transferList, setTransferList] = React.useState([]),
		[canEdit, setCanEdit] = React.useState(false),
		[role, setRole] = React.useState("");

	const [inAdding, setInAdding] = React.useState(false),
		[inEditing, setInEditing] = React.useState(null),
		[isLoadingSave, setIsLoadingSave] = React.useState(false);

	const [notice, setNotice] = React.useState({ type: "", content: "" });

	// REFS
	const sidebarContentRef = React.useRef(null);

	// FUNCTIONS
	const onSaveCrewData = async (formData = {}, member = {}) => {
		onAddCrew(formData, null);
	};

	const onAddCrew = async (formData = {}, member = null) => {
		if (formData.CodeNum === 0 || formData.ContactNumber?.length < 7) {
			setNotice({
				content: "Invalid phone number",
				type: "ERROR",
			});
			return;
		}
		if (
			formData.PickUpDateTime === null ||
			formData.PickUpPlace === "" ||
			formData.DestinationPlace === "" ||
			formData.Contact === "" ||
			formData.Name === "" ||
			formData.Contact?.length < 8
		) {
			return;
		}
		setIsLoadingSave(true);
		const pickUpDate = dateFormat(
			new Date(formData.PickUpDateTime),
			"yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
		);

		ApiClient.createTransfer(
			formData.Id,
			trip?.Id,
			pickUpDate,
			formData.PickUpPlace,
			formData.DestinationPlace,
			parseInt(formData.NumberOfAdults),
			formData.Contact,
			formData.Name
		)
			.then((res) => {
				if (res?.ErrorCode && res?.ErrorMessage) {
					setNotice({
						content: res.ErrorMessage,
						type: "ERROR",
					});
				}
				setIsLoadingSave(false);
				setInAdding(false);
				loadData();
			})
			.catch((error) => {
				setIsLoadingSave(false);
				setInAdding(false);
			});
	};

	const loadData = () => {
		ApiClient.getTransfers(trip?.Id)
			.then((res) => {
				const user = trip.Members.filter(
					(u) => u.UserId === currentUserData?.ID
				)[0];
				const data = (res ?? []).reverse();

				const minCalendarDate = new Date(trip.StartDate);
				const tripDuration = trip.DurationDays;

				const milliseconds = 86400000;

				const maxCalendarDate = new Date(
					minCalendarDate.getTime() + tripDuration * milliseconds
				);

				const dayAfterMaxCalendarDate = new Date(
					minCalendarDate.getTime() + (tripDuration + 1) * milliseconds
				);

				const mapped = data.map((transfer) => {
					let myRole = user?.Role;
					var thisDate = new Date(transfer.Data.PickUpDateTime);
					thisDate = new Date(
						thisDate.getFullYear(),
						thisDate.getMonth(),
						thisDate.getDate()
					);

					var MIN = new Date(minCalendarDate);
					MIN = new Date(MIN.getFullYear(), MIN.getMonth(), MIN.getDate());

					const isGreaterThanMin = thisDate.getTime() > MIN.getTime();
					const isArrival = !isGreaterThanMin;

					var canComplete = false;

					if (myRole === "superpassenger") {
						if (transfer.IsCompleted || deadlineReached) {
							canComplete = false;
						} else {
							canComplete = true;
						}
					} else if (myRole === "creator" || myRole === "cocreator") {
						canComplete = true;
					}

					if (myRole === "cocreator" && deadlineReached) {
						canComplete = false;
					}

					var canUserEdit =
						myRole === "creator" ||
						myRole === "cocreator" ||
						myRole === "superpassenger";

					canUserEdit =
						trip?.State === "onboarding" &&
						canUserEdit &&
						!transfer.IsCompleted;

					var allowFreeText = true;
					const tripAttr = trip.Attributes ?? [];
					for (const e in tripAttr) {
						const val = tripAttr[e];
						if (val === "uri:trip:attr:direction:circular") {
							allowFreeText = false;
						}
					}

					return {
						Id: transfer.Data.Id,
						Contact: transfer.Data.Contact,
						Name: transfer.Data.Name,
						DestinationPlace: transfer.Data.DestinationPlace,
						NumberOfAdults: transfer.Data.NumberOfAdults,
						PickUpDateTime: transfer.Data.PickUpDateTime,
						PickUpPlace: transfer.Data.PickUpPlace,
						TripId: transfer.Data.TripId,
						IsCompleted: transfer.IsCompleted,
						CalendarMin: minCalendarDate,
						CalendarMax: maxCalendarDate,
						CalendarDayAfterMax: dayAfterMaxCalendarDate,
						IsArrival: isArrival,
						BaseStationName: trip.Place.Name ?? trip.Place.Type,
						CanComplete: canComplete,
						CanEdit: canUserEdit,
						AllowFreeText: allowFreeText,
					};
				});
				setTransferList(mapped);

				let role = user ? user?.Role : "no-member";
				setRole(role);

				let canUserEdit =
					role === "creator" ||
					role === "cocreator" ||
					role === "superpassenger";
				setCanEdit(trip?.State === "onboarding" && canUserEdit);

				setInAdding(false);
				setInEditing(null);

				setLoading(false);

				if (deadlineReached && role === "cocreator") {
					setCanEdit(false);
				}

				if (deadlineReached && role === "superpassenger") {
					setCanEdit(false);
					setNotice({
						content:
							"You are past the deadline for completing the list. You can no longer make changes.",
						type: "WARNING",
					});
				}
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
				setLoading(false);
			});
	};

	const exportData = () => {
		const dataToSend = transferList.map((item) => {
			//const pickUpDate = dateFormat(new Date(item.PickUpDateTime), "yyyy-MM-dd HH:mm");
			var date = new Date(item.PickUpDateTime).toUTCString();
			return {
				Contact: item.Contact,
				PickUpDate: date.replaceAll("GMT", ""),
				PickUpPlace: item.PickUpPlace,
				DestinationPlace: item.DestinationPlace,
				NumberOfPassengerss: item.NumberOfAdults,
			};
		});
		exportToXLSX(dataToSend, "Transfer_list");
	};

	const deleteTransfer = (transferId) => {
		ApiClient.deleteTransfer(transferId, trip?.Id).then((resp) => {
			if (resp?.ErrorCode && resp?.ErrorMessage) {
				setNotice({
					content: resp.ErrorMessage,
					type: "ERROR",
				});
				return;
			}
			loadData();
		});
	};

	const markAsCompleted = async (id, completed) => {
		if (role === "superpassenger") {
			confirmAlert({
				title: "Confirmation",
				message: languages[currentLanguage]["trip_confirmation_action"],
				buttons: [
					{
						label: "Yes",
						onClick: () => updateStateTransferItem(id, completed),
					},
					{
						label: "No",
					},
				],
			});
			return;
		}
		updateStateTransferItem(id, completed);
	};

	const updateStateTransferItem = async (id, completed) => {
		setLoading(true);
		ApiClient.completeTransfer(id, trip?.Id, !completed)
			.then((res) => {
				loadData();
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		if (visible) {
			const minCalendarDate = new Date(trip.StartDate);
			const tripDuration = trip.DurationDays;

			const milliseconds = 86400000;

			const maxCalendarDate = new Date(
				minCalendarDate.getTime() + tripDuration * milliseconds
			);

			const dayAfterMaxCalendarDate = new Date(
				minCalendarDate.getTime() + (tripDuration + 1) * milliseconds
			);

			const baseStationName = trip.Place.Name ?? trip.Place.Type;
			var allowFreeText = true;
			const tripAttr = trip.Attributes ?? [];
			for (const e in tripAttr) {
				const val = tripAttr[e];
				if (val === "uri:trip:attr:direction:circular") {
					allowFreeText = false;
				}
			}
			setInitialFormValues({
				PickUpDateTime: minCalendarDate,
				PickUpPlace: "",
				DestinationPlace: baseStationName,
				NumberOfAdults: 1,
				Contact: "",
				Name: "",
				IsArrival: true,
				BaseStationName: baseStationName,
				CalendarMin: minCalendarDate,
				CalendarMax: maxCalendarDate,
				CalendarDayAfterMax: dayAfterMaxCalendarDate,
				AllowFreeText: allowFreeText,
			});
			loadData();
		}
	}, [visible, selectedUserProfileId]);

	return (
		<>
			<SideBar
				visible={visible}
				useBackBtn={true}
				back_title={trip?.Name}
				back_subTitle={
					languages[currentLanguage]["trip_transfer_manage_header"]
				}
				onClickBack={closeSidebar}
				isLoading={loading}
				sidebarProps={{
					ref: sidebarContentRef,
				}}
			>
				{transferList !== null &&
					transferList?.length > 0 &&
					(role === "basemanager" || role === "creator") && (
						<IconButton
							style={{
								position: "absolute",
								zIndex: 11,
								top: 29,
								right: 15,
							}}
							onClick={() => exportData()}
						>
							<img src={FullExportListIcon} style={{ height: 28, width: 28 }} />
						</IconButton>
					)}

				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={snackbarClipboard}
					autoHideDuration={2000}
					onClose={() => setSnackbarClipboard(false)}
					message="Copied to clipboard"
				/>

				<div className="d-flex flex-column w-100 h-100" style={{ flex: 1 }}>
					<div className="py-3" style={{ flex: 1 }}>
						{transferList?.map((transfer) => (
							<TransferListCollapseCard
								{...{
									key: transfer.Id,
									member: transfer,
									inEditing: inEditing === transfer.Id,
									inAdding: false,
									isLoadingSave: isLoadingSave && inEditing === transfer.Id,
									role: role,
									onEdit: () => {
										if (!isLoadingSave) {
											setInEditing(transfer.Id);
											setInAdding(false);
										}
									},
									onComplete: () => {
										if (!isLoadingSave) {
											markAsCompleted(transfer.Id, transfer.IsCompleted);
											setInAdding(false);
										}
									},
									canEdit: transfer.CanEdit,
									canComplete: transfer.CanComplete,
									onDelete: () => {
										if (!isLoadingSave) {
											deleteTransfer(transfer.Id);
											setInAdding(false);
										}
									},
									onCancel: () => !isLoadingSave && setInEditing(null),
									onSave: onSaveCrewData,
									onCollapse: () =>
										!(inEditing === transfer.Id && isLoadingSave) &&
										setTransferList(
											transferList.map((item) => {
												return {
													...item,
													Collapse:
														item.Id === transfer.Id
															? !item.Collapse
															: item.Collapse,
												};
											})
										),
								}}
							/>
						))}

						{inAdding && (
							<TransferListCollapseCard
								{...{
									member: initialFormValues,
									inEditing: false,
									inAdding: inAdding,
									isLoadingSave: isLoadingSave && inAdding,
									onCancel: () => !isLoadingSave && setInAdding(false),
									onSave: onAddCrew,
									role: role,
								}}
							/>
						)}
					</div>

					{canEdit && (
						<div className="container">
							<Button
								disabled={inAdding}
								className="p-2 col-12 text-capitalize"
								style={{
									color: whiteColor,
									backgroundColor: filterHighlightColor,
									border: "none",
									fontSize: 14,
									borderRadius: 5,
									margin: "16px 0",
									boxShadow: "-5px 0 7px 0 rgba(160, 160, 169, 0.15)",
								}}
								onClick={() => {
									if (!isLoadingSave) {
										const sidebar = sidebarContentRef.current.firstElementChild;

										setInAdding(true);
										setInEditing(null);
										setTimeout(() => {
											sidebar.scrollTo(0, sidebar.scrollHeight);
										}, 100);
									}
								}}
							>
								<span className="deci text-capitalize">
									{languages[currentLanguage]["trip_add_new_transfer"]}
								</span>
							</Button>
						</div>
					)}
				</div>
			</SideBar>
			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferList);

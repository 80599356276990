import React, { useRef, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

// HELPERS
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

// API
import { AuthService } from "../../AuthService";

// CONFIGS
import { Keys } from "../../appConfig";

// HELPERS
import { TileHelper } from "../../TileHelper";
import { onResetMapData, onSetMapData } from "./helpers/onLoadMap";
import { onMoveEnds, onMove } from "./helpers/mapEventsHandler";

// SELECTORS
import {
	filtersSelected as filtersSelected_,
	globalWeatherBrandVisibility as globalWeatherBrandVisibility_,
	globalWeatherDate as globalWeatherDate_,
	tripItineraryVisibility as tripItineraryVisibility_,
	currentTripItinerary,
	selectedItineraryDay,
} from "../../store/features/map/selectors";
import { currentLang } from "../../store/features/translation/selectors";
import { userData } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages/";

// ACTIONS
import {
	setGlobalWeatherBrandVisibility,
	addTripNodeEditing,
	setTripItineraryVisibility,
} from "../../store/features/map/actions";

// COMPONENTS
import { Popup } from "./Popup";
import VesselPopup from "../VesselPopup";
import PopupAdd from "../VesselPopup/PopupAdd";

// STYLES
import "../../assets/css/marker-popup.css";
import "../../assets/css/map-controls.css";

// CONFIGS
mapboxgl.accessToken = Keys.mapBoxKey;

let isLoadingData = false;
let iBoolInEditing = 0;
let firstZoom = true;

export const closeAddPlacePopup = () => {
	const popup = document.querySelectorAll(".add-popup");
	if (popup.length) {
		for (let i = 0; i < popup.length; i++) {
			popup[i].remove();
		}
	}
};

const Map = (props) => {
	// STATES
	const { bookmarksBounds, moveTo, layers, coastViewMoveTo } = props.state;
	const [showDialog, setShowDialog] = React.useState(false);
	const [filterWeatherMark, setFilterWeatherMark] = React.useState(null);
	const [nbrMoveEnd, setNbrMoveEnd] = React.useState(0);
	const [, setOnGlobalWeatherclose] = useState(() => {});
	const [, setOnGlobalClick] = useState(() => {});
	const [map, setMap] = useState(null);
	const [lng, setLng] = useState(20.917282565575874);
	const [lat, setLat] = useState(38.61324230564156);
	const [zoom, setZoom] = useState(11);
	const [mapLoaded, setMapLoaded] = useState(false);

	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	const currentUser = useSelector(userData);
	const filtersSelected = useSelector((state) => filtersSelected_(state));
	const globalWeatherBrandVisibility = useSelector((state) =>
		globalWeatherBrandVisibility_(state)
	);
	const tripItineraryVisibility = useSelector((state) =>
		tripItineraryVisibility_(state)
	);
	const globalWeatherDateSelector = useSelector((state) =>
		globalWeatherDate_(state)
	);
	const currentTripItinerary_ = useSelector(currentTripItinerary);
	const SELECTED_ITINERARY_DAY = useSelector(selectedItineraryDay);

	// DATA
	const NOTICES_MSG = {
		mytrip_place_added_msg:
			languages[currentLanguage]["nexttrip_place_added_msg"],
		mytrip_max_error_msg: languages[currentLanguage]["nexttrip_max_error_msg"],
		place_list_remove:
			languages[currentLanguage]["nexttrip_place_removed_message"],
		mytrip_max_error_msg: languages[currentLanguage]["nexttrip_max_error_msg"],
		common_error_something_went_wrong:
			languages[currentLanguage]["common_error_something_went_wrong"],
	};
	const PLACE_TRANSLATED = {
		beach: languages[currentLanguage]["place_type_beach"],
		anchorage: languages[currentLanguage]["place_type_anchorage"],
		cave: languages[currentLanguage]["place_type_cave"],
		wreck: languages[currentLanguage]["place_type_wreck"],
		marina: languages[currentLanguage]["place_type_marina"],
		port: languages[currentLanguage]["place_type_port"],
		shipyard: languages[currentLanguage]["place_type_shipyard"],
		boatyard: languages[currentLanguage]["place_type_boatyard"],
		mooring: languages[currentLanguage]["place_type_mooring"],
		boatramp: languages[currentLanguage]["place_type_boatramp"],
	};

	// REFS
	const mapContainerRef = useRef(null);
	const ShipRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const placesRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const anchoragesRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const tripsRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const tripsExpRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const bookmarkPlacesRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const itineraryPlacesRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const itineraryRoutesRef = useRef({
		type: "FeatureCollection",
		features: [],
	});
	const vesselPopup = useRef(
		new mapboxgl.Popup({
			offset: 15,
			className: "vessel-popup",
			closeButton: false,
		})
	);
	const placePopup = useRef(
		new mapboxgl.Popup({
			offset: 15,
			className: "place-popup",
			closeButton: false,
		})
	);
	const addPlacePopup = useRef(
		new mapboxgl.Popup({
			offset: 15,
			className: "add-popup",
			closeButton: false,
		})
	);

	// FUNCTIONS
	const handleUpdate = () => {
		props.setUpdate();
	};
	const verifyEditionMode = (successCallback = () => {}) => {
		if (iBoolInEditing) {
			successCallback();
		} else {
			setShowDialog(true);
		}
	};
	const handleAdd = () => {
		if (!AuthService.isUserLogedIn()) {
			props.showAuth();
		} else {
			props.openWizard();
		}
	};
	const reloadItineraryDataSource = (
		inEditing = false,
		_tripItinerary = currentTripItinerary_,
		_itineraryDay = SELECTED_ITINERARY_DAY,
		_fitBounds = true
	) => {
		if (map && map.getSource) {
			let allItinerary = _tripItinerary?.daysList;

			const index = _tripItinerary?.selectedDay.index;

			if (
				!props.tripOverviewVisibility &&
				_tripItinerary.visibility &&
				allItinerary?.length > 0 &&
				index !== null &&
				index < allItinerary?.length &&
				map.getSource("itinerary_places_source") &&
				map.getSource("itinerary_routes_source")
			) {
				const selectedDay = allItinerary[index];
				var itineraryPlaceSource = {
					type: "FeatureCollection",
					features: [],
				};
				var allSail = [];
				var allLongs = [];
				var allLats = [];
				var count = 1;
				const EDITABLE_NODES = [
					...(_itineraryDay?.launchPoint ? [_itineraryDay?.launchPoint] : []),
					..._itineraryDay.tripNodes,
					...(_itineraryDay?.shelterPoint ? [_itineraryDay?.shelterPoint] : []),
				];

				const NODES = inEditing
					? EDITABLE_NODES.map((_node) => ({
							..._node,
							Place: {
								Id: _node?.PlaceId,
								Type: _node?.Type,
							},
							Type: "stop",
					  }))
					: selectedDay.Nodes;

				NODES.forEach((node) => {
					if (node.Type === "stop" && node?.Place) {
						const place = node?.Place;
						const type = place?.Type;
						const need = node.Need;
						const location = node.Point;

						allLongs.push(node.Point[0]);
						allLats.push(node.Point[1]);
						itineraryPlaceSource.features.push({
							id: place?.Id,
							type: "Feature",
							properties: {
								id: place?.Id,
								icon: type,
								need: inEditing ? "itinerary" : need,
								index: count,
							},
							geometry: {
								type: "Point",
								coordinates: location,
							},
						});
						count += 1;
					} else if (node.Type === "sail") {
						node.Line.forEach((line) => {
							allSail.push([line[0], line[1]]);
						});
					}
				});

				var itineraryRouteSource = {
					id: "s",
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: allSail,
					},
				};

				map.getSource("itinerary_places_source").setData(itineraryPlaceSource);
				map.getSource("itinerary_routes_source").setData(itineraryRouteSource);

				if (inEditing && map.getSource("places_source")) {
					const _EDITABLE_NODES = [
						...(_itineraryDay?.launchPoint ? [_itineraryDay?.launchPoint] : []),
						...(_itineraryDay?.tripNodes?.length
							? _itineraryDay?.tripNodes
							: []),
						...(_itineraryDay?.shelterPoint
							? [_itineraryDay?.shelterPoint]
							: []),
					];

					const _PLACE_SOURCE = map.getSource("places_source")._data;
					const _FORMATTED_EDITABLE_NODES = _EDITABLE_NODES
						.map((_node) => {
							return {
								id: _node.Place?.Id,
								type: "Feature",
								properties: {
									id: _node.PlaceId,
									icon: _node.Type,
									tags: [
										"uri:pl:tag:experience",
										"uri:pl:tag:shelter",
										"uri:pl:tag:lanchpoint",
										"uri:pl:tag:kayaklanchpoint",
										"uri:pl:tag:smallboatlanchpoint",
										"uri:pl:tag:sailmotorshelter",
										"uri:pl:tag:smallboatshelter",
										"uri:pl:tag:kayakshelter",
										"uri:pl:tag:eatdrink",
										"uri:pl:tag:shopsservices",
										"uri:pl:tag:sailmotorlanchpoint",
									],
									isFromItinerary: true,
								},
								geometry: {
									type: "Point",
									coordinates: _node?.Point,
								},
							};
						})
						.concat(_PLACE_SOURCE.features);

					_PLACE_SOURCE.features = _FORMATTED_EDITABLE_NODES;
					map.getSource("places_source").setData(_PLACE_SOURCE);
				} else if (map.getSource("places_source")) {
					const _PLACE_SOURCE = map.getSource("places_source")._data;

					_PLACE_SOURCE.features = _PLACE_SOURCE.features.filter(
						(_place) => !_place?.properties?.isFromItinerary
					);
					map.getSource("places_source").setData(_PLACE_SOURCE);
				}

				if (
					allLongs.length === 0 ||
					allLats.length === 0 ||
					!tripItineraryVisibility
				) {
					return;
				}

				if (_fitBounds) {
					const minLong = Math.min.apply(null, allLongs); // west
					const maxLong = Math.max.apply(null, allLongs); // east
					const minLat = Math.min.apply(null, allLats); // south
					const maxLat = Math.max.apply(null, allLats); // north
					const bounds = [
						[minLong, minLat],
						[maxLong, maxLat],
					];
					map.fitBounds(bounds, { maxZoom: 11, padding: 150 });
				}
			}
			return () => {};
		}
	};

	// EFFECTS
	useEffect(() => {
		// INIT MAP
		const myLatest = window.localStorage.getItem("latestPosition")
			? JSON.parse(window.localStorage.getItem("latestPosition"))
			: [];
		let myCenter = [props.zoom?.lat || lng, props.zoom?.long || lat];
		let myZoom = props.zoom?.zoom || zoom;

		if (myLatest && myLatest?.length > 2) {
			myCenter = [myLatest[1], myLatest[0]];
			myZoom = myLatest[2];
		}
		const _MAP = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/ibogordos/ckqr3n7i6396j18pd2pngznuy",
			center: myCenter,
			zoom: myZoom,
			attributionControl: false,
		});

		_MAP.addControl(
			new mapboxgl.GeolocateControl({
				positionOptions: {
					enableHighAccuracy: true,
				},
				trackUserLocation: true,
			})
		);
		_MAP.addControl(new mapboxgl.NavigationControl());

		_MAP.once("load", () => {
			setMap(_MAP);
			onMove({ map: _MAP, setLng, setLat, setZoom });
			onMoveEnds({
				map: _MAP,
				ref: ShipRef.current,
				placesRef: placesRef.current,
				anchoragesRef: anchoragesRef.current,
				tripsRef: tripsRef.current,
				tripsExpRef: tripsExpRef.current,
				dispatch: props.dispatch,
				onIncrementMove: (nbr = nbrMoveEnd + 1) => setNbrMoveEnd(nbr),
			});

			if (firstZoom) {
				firstZoom = false;
				const currentZoom = _MAP.getZoom();
				setTimeout(() => {
					_MAP.flyTo({ zoom: currentZoom });
				}, 500);
			}

			setMapLoaded(true);

			setTimeout(() => {
				if (
					_MAP.isSourceLoaded("places_source") &&
					_MAP.getSource("places_source") &&
					!globalWeatherBrandVisibility
				) {
					props.setGWBrandVisibility(true);
				}
			}, 3000);
		});

		// Clean up on unmount
		return () => {
			if (map && map.remove) {
				map.remove();
			}
			setMapLoaded(false);
		};
	}, []);

	useEffect(() => {
		if (map && map.loaded && map.setLayoutProperty && mapLoaded) {
			const isSmallBoat = () => {
				if (filtersSelected.vesselType === "kayak") return true;
				if (
					filtersSelected.vesselType === "sailboat" ||
					filtersSelected.vesselType === "motorboat"
				) {
					if (parseInt(filtersSelected.vesselLength) > 10) return false;
					return true;
				}
				return false;
			};

			const filterWeatherMark_ = (timestamp) => {
				if (map.getSource && globalWeatherBrandVisibility) {
					if (map.getSource("places_source")) {
						var currentNeed = filtersSelected.needs;
						const source = map.getSource("places_source")._data;
						source.features.forEach((place) => {
							if (
								!place.properties.suitability ||
								!place.properties.suitability.length
							) {
								var icon = place.properties["icon"];
								place.properties["weather-icon"] = `${icon}_${currentNeed}`;
							} else {
								const allSuitability = place.properties.suitability;
								let suitability = allSuitability.find(
									(e) => e[0] === timestamp
								);
								let info = isSmallBoat() ? suitability[1] : suitability[2];
								let icon = place.properties["icon"];
								place.properties["info"] = info;
								place.properties[
									"weather-icon"
								] = `${icon}_${currentNeed}_${info}`;
							}
						});
						map.getSource("places_source").setData(source);
						map.setLayoutProperty("places", "icon-image", [
							"get",
							"weather-icon",
						]);
					}
				}
			};

			const onGlobalClick_ = () => {
				if (
					!isLoadingData &&
					map &&
					map.getSource("places_source") &&
					map.getSource("ships_source") &&
					map.getSource("anchorages_source")
				) {
					props.setGWBrandVisibility(!globalWeatherBrandVisibility);
				}
			};

			const onGlobalWeatherclose_ = () => {
				var currentNeed = filtersSelected.needs;
				if (!map || typeof map === "undefined") {
					return;
				}

				map.setLayoutProperty("places", "icon-image", [
					"concat",
					["get", "icon"],
					`_${currentNeed}`,
				]);
			};

			if (
				globalWeatherDateSelector &&
				globalWeatherBrandVisibility &&
				typeof filterWeatherMark === "function"
			) {
				filterWeatherMark(globalWeatherDateSelector);
			}

			onSetMapData(
				map,
				ShipRef.current,
				placesRef.current,
				bookmarkPlacesRef.current,
				itineraryPlacesRef.current,
				itineraryRoutesRef.current,
				anchoragesRef.current,
				tripsRef.current,
				tripsExpRef.current,
				filtersSelected
			);

			// PLACES EVENT
			map.on("click", "places", (e) => {
				// EVENT
				if (e.features.length) {
					const feature = e.features[0];
					logEvent_("react_explore_placemarker_selected", {
						place_type: feature.properties.icon,
					});
					// create popup node
					const popupNode = document.createElement("div");
					ReactDOM.render(
						<Popup
							closeSideBars={props.closeSideBars}
							addTripNodeEditing={props.addTripNodeEditing}
							verifyEditionMode={verifyEditionMode}
							currentUser={currentUser ? currentUser : null}
							feature={feature}
							dispatch={(data) => {
								props.dispatch(data);
							}}
							setUpdate={handleUpdate}
							notices={NOTICES_MSG}
							places={PLACE_TRANSLATED}
						/>,
						popupNode
					);
					// set popup on map
					addPlacePopup.current.remove();
					new mapboxgl.Popup({
						offset: 15,
						className: "place-popup",
						closeButton: false,
					})
						.setLngLat(feature.geometry.coordinates)
						.setDOMContent(popupNode)
						.addTo(map)
						.setMaxWidth("330px");

					if (props.dispatch)
						props.dispatch({
							type: "updateselectedId",
							selectedId: feature.properties.id,
						});

					if (globalWeatherBrandVisibility && feature.properties?.info) {
						const info = feature.properties?.info;
						if (info === 1) {
							logEvent_("react_explore_redmarker_selected");
						} else if (info === 2) {
							logEvent_("react_explore_yellowmarker_selected");
						} else if (info === 3) {
							logEvent_("react_explore_greenmarker_selected");
						}
					}
				}
				map.setLayoutProperty("places", "icon-image", [
					"match",
					["id"],
					e.features[0].id,
					["concat", ["get", "icon"], "_selected"],
					globalWeatherBrandVisibility
						? ["get", "weather-icon"]
						: ["concat", ["get", "icon"], `_${filtersSelected.needs}`],
				]);
			});

			// SHIPS EVENT
			map.on("click", "ships", (e) => {
				// EVENT
				logEvent_("react_explore_vesselmarker_selected");

				if (e?.features?.length) {
					const feature = e?.features[0];

					const popupNode = document.createElement("div");

					ReactDOM.render(<VesselPopup feature={feature} />, popupNode);

					new mapboxgl.Popup({
						offset: 15,
						className: "vessel-popup",
						closeButton: false,
					})
						.setLngLat(feature.geometry.coordinates)
						.setDOMContent(popupNode)
						.addTo(map)
						.setMaxWidth("200px");
				}
			});

			// GLOBAL MAP EVENT
			map.on("contextmenu", (e) => {
				if (!vesselPopup.current.isOpen() && !placePopup.current.isOpen()) {
					const popupNode = document.createElement("div");
					let location = [e.lngLat.lng, e.lngLat.lat];
					// if (!e.features[0]) {
					ReactDOM.render(
						<PopupAdd
							title={languages[currentLanguage]["place_contribution_header"]}
							btn={languages[currentLanguage]["trip_itinerary_add_place"]}
							Location={location}
							handleClick={() => {
								logEvent_("react_explore_addplace_button_pressed");
								props.openAddPlace(location);
							}}
							handleClose={() => addPlacePopup.current.remove()}
						/>,
						popupNode
					);

					addPlacePopup.current
						.setLngLat(location)
						.setDOMContent(popupNode)
						.addTo(map)
						.setMaxWidth("300px");
					// }
				}
			});

			map.on("click", "bookmark_places", (e) => {
				if (e.features.length) {
					const feature = e.features[0];
					// create popup node
					const popupNode = document.createElement("div");

					ReactDOM.render(
						<Popup
							currentUser={currentUser ? currentUser : null}
							feature={feature}
							dispatch={props.dispatch}
							setUpdate={handleUpdate}
						/>,
						popupNode
					);

					// set popup on map
					new mapboxgl.Popup({
						offset: 15,
						className: "place-popup",
						closeButton: false,
					})
						.setLngLat(feature.geometry.coordinates)
						.setDOMContent(popupNode)
						.addTo(map)
						.setMaxWidth("330px");

					if (props.dispatch)
						props.dispatch({
							type: "updateselectedId",
							selectedId: feature.properties.id,
						});
				}
			});

			map.on("click", "coastview", (e) => {
				if (e.features.length) {
					// EVENT
					logEvent_("react_explore_coastviewmarker_selected");

					gtag_report_conversion(
						"https://" + window.location.host + "/explore/",
						types.COAST_VIEW_IMAGE_SHOWN
					);
					const feature = e.features[0];
					if (props.dispatch)
						props.dispatch({
							type: "updateslider",
							photo: feature.properties,
						});
					setTimeout(() => {
						addPlacePopup.current.remove();
					}, 1000);
				}
			});

			map.on("click", "clusters", (e) => {
				addPlacePopup.current.remove();
				const features = map.queryRenderedFeatures(e.point, {
					layers: ["clusters"],
				});
				if (features.length > 0) {
					if (features[0]?.properties?.data) {
						// Signle -> Open Trip overview
						// TODO -> Opens trip list avec allTrips
						props.openOverv(features[0]?.properties?.data);
					} else {
						// Multiple -> Open trip lists
						var clusterId = features[0].properties.cluster_id;
						var point_count = features[0].properties.point_count;
						var clusterSource = map.getSource("tile_trips_source");
						if (clusterSource) {
							clusterSource.getClusterLeaves(
								clusterId,
								point_count,
								0,
								function (err, aFeatures) {
									const selectedFeatures = aFeatures;
									if (selectedFeatures && selectedFeatures.length > 0) {
										const allTrips = selectedFeatures.map(
											(e) => e?.properties?.data
										);
										// TODO -> Opens trip list avec allTrips
										props.openTrips(allTrips);
									}
								}
							);
						}
					}
				}
			});

			map.on("movestart", () => {
				isLoadingData = true;
			});

			map.on("data", function () {
				if (!map.getLayer("anchorages_layer") || !filtersSelected || !map) {
					return;
				}
				var visibility = "visible";
				if (
					filtersSelected.places.length > 0 &&
					!filtersSelected.places.includes("anchorage")
				) {
					visibility = "none";
				}
				map.setLayoutProperty("anchorages_layer", "visibility", visibility);
			});

			handleUpdate();

			map.setLayoutProperty("places", "visibility", "visible");
			map.setLayoutProperty("anchorages_layer", "visibility", "visible");
			map.setLayoutProperty("coastview", "visibility", "visible");
			map.setLayoutProperty("ships", "visibility", "visible");
			map.setLayoutProperty("bookmark_places", "visibility", "none");
			map.setLayoutProperty("clusters", "visibility", "none");
			map.setLayoutProperty("clusters-count", "visibility", "none");
			if (tripItineraryVisibility) {
				if (!currentTripItinerary_?.selectedDay?.inEditing) {
					map.setLayoutProperty("places", "visibility", "none");
				} else {
					map.setLayoutProperty("itinerary_layer_routes", "visibility", "none");
				}
				map.setLayoutProperty("coastview", "visibility", "none");
				map.setLayoutProperty("ships", "visibility", "none");
			}

			if (filtersSelected.needs === "trips") {
				map.setLayoutProperty("clusters", "visibility", "visible");
				map.setLayoutProperty("clusters-count", "visibility", "visible");
				map.setLayoutProperty("places", "visibility", "none");
				map.setLayoutProperty("anchorages_layer", "visibility", "none");
			}

			// SET
			setFilterWeatherMark(() => filterWeatherMark_);
			setOnGlobalClick(() => onGlobalClick_);
			setOnGlobalWeatherclose(() => onGlobalWeatherclose_);
		}

		return () => {
			if (map) {
				onResetMapData(map);
				map.off("click", "places");
				map.off("click", "ships");
				map.off("click");
				map.off("click", "bookmark_places");
				map.off("click", "coastview");
				map.off("click", "clusters");
				map.off("movestart");
				map.off("data");
			}
		};
	}, [filtersSelected, globalWeatherBrandVisibility, props.zoom, mapLoaded]);

	useEffect(() => {
		if (moveTo && moveTo.bounds) {
			map.fitBounds(moveTo.bounds);
			return;
		}

		if (moveTo && moveTo.center && map) {
			const toUse = {
				center: moveTo.center,
				zoom: map.getZoom(),
			};
			map.flyTo(toUse);
			return;
		}
	}, [moveTo]);

	useEffect(() => {
		if (map) {
			const bounds = map.getBounds();
			const nw = bounds.getNorthWest();
			const se = bounds.getSouthEast();
			const zoom = Math.floor(map.getZoom());
			const pixelNW = TileHelper.latlongToPixelXY(nw.lat, nw.lng, zoom);
			const tileNW = TileHelper.PixelXYToTileXY(pixelNW.x, pixelNW.y);
			const pixelSE = TileHelper.latlongToPixelXY(se.lat, se.lng, zoom);
			const tileSE = TileHelper.PixelXYToTileXY(pixelSE.x, pixelSE.y);
			var addedPlaceXYs = [];
			for (let y = tileNW.y; y <= tileSE.y; y++) {
				for (let x = tileNW.x; x <= tileSE.x; x++) {
					if (x !== null && y !== null && zoom !== null) {
						addedPlaceXYs.push([x, y, zoom]);
					}
				}
			}
			let items = JSON.parse(window.localStorage.getItem("placesToSave"));
			if (items && items?.length > 0) {
				items = items.concat(addedPlaceXYs);
			} else {
				items = addedPlaceXYs;
			}
			window.localStorage.setItem("placesToSave", JSON.stringify(items));
		}
	}, [props.placeAdded]);

	useEffect(() => {
		addPlacePopup.current.remove();
		if (coastViewMoveTo?.id) {
			map.setPaintProperty("coastview", "circle-color", [
				"match",
				["get", "id"],
				coastViewMoveTo?.id,
				"#0078FF",
				"#569DD1",
			]);
		}
		if (coastViewMoveTo && coastViewMoveTo.bounds) {
			map.fitBounds(coastViewMoveTo.bounds);
			return;
		}

		if (coastViewMoveTo && coastViewMoveTo.center) {
			const toUse = {
				center: coastViewMoveTo.center,
				zoom: 13,
			};
			map.flyTo(toUse);
			return;
		}
	}, [coastViewMoveTo]);

	useEffect(() => {
		if (
			bookmarksBounds &&
			bookmarksBounds?.bounds &&
			bookmarksBounds?.bookmarks
		) {
			map.fitBounds(bookmarksBounds.bounds);
			var bookmarkSource = {
				type: "FeatureCollection",
				features: [],
			};
			bookmarksBounds.bookmarks.forEach((p) => {
				const place = p.Place;
				bookmarkSource.features.push({
					id: place.ID,
					type: "Feature",
					properties: { id: place.ID, icon: place.Type },
					geometry: {
						type: "Point",
						coordinates: [place.Location.Longitude, place.Location.Latitude],
					},
				});
			});
			if (map.getSource("bookmark_places_source")) {
				map.getSource("bookmark_places_source").setData(bookmarkSource);
				map.setLayoutProperty("places", "visibility", "none");
				map.setLayoutProperty("anchorages_layer", "visibility", "none");
				map.setLayoutProperty("coastview", "visibility", "none");
				map.setLayoutProperty("bookmark_places", "visibility", "visible");
			}
		}
	}, [bookmarksBounds]);

	useEffect(() => {
		if (!props.bookmarksListState && map) {
			map.setLayoutProperty("places", "visibility", "visible");
			map.setLayoutProperty("anchorages_layer", "visibility", "visible");
			map.setLayoutProperty("coastview", "visibility", "visible");
			map.setLayoutProperty("bookmark_places", "visibility", "none");
		}
	}, [props.bookmarksListState]);

	useEffect(() => {
		if (!layers || !map || !map.MyCustomLoaded) return;

		Object.keys(layers).forEach((key) => {
			var currentVisibility = layers[key] ? "visible" : "none";
			var prevVisibility = map.getLayoutProperty(key, "visibility");

			if (prevVisibility !== currentVisibility) {
				map.setLayoutProperty(key, "visibility", currentVisibility);
			}
		});
	}, [layers]);

	useEffect(() => {
		isLoadingData = false;
		if (filterWeatherMark && globalWeatherDateSelector) {
			filterWeatherMark(globalWeatherDateSelector);
		}
		return () => {};
	}, [globalWeatherDateSelector, filterWeatherMark, nbrMoveEnd]);

	useEffect(() => {
		iBoolInEditing = currentTripItinerary_?.selectedDay?.inEditing;
		if (map && props.tripOverviewVisibility) {
			const allItinerary = currentTripItinerary_?.daysList;
			if (allItinerary?.length > 0) {
				var itineraryPlaceSource = {
					type: "FeatureCollection",
					features: [],
				};
				var allSail = [];
				var allLongs = [];
				var allLats = [];
				var count = 1;
				allItinerary.forEach((day) => {
					day.Nodes.forEach((node) => {
						if (node.Type === "stop" && node?.Place) {
							const place = node?.Place;
							const type = place.Type;
							const need = node.Need;
							const location = node.Point;
							allLongs.push(node.Point[0]);
							allLats.push(node.Point[1]);
							itineraryPlaceSource.features.push({
								id: place?.Id,
								type: "Feature",
								properties: {
									id: place?.Id,
									icon: place?.Type,
									need: need,
									index: count,
								},
								geometry: {
									type: "Point",
									coordinates: location,
								},
							});
							count += 1;
						} else if (node.Type === "sail") {
							node.Line.forEach((line) => {
								allSail.push([line[0], line[1]]);
							});
						}
					});
				});
				var itineraryRouteSource = {
					id: "s",
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: allSail,
					},
				};

				if (map.getSource) {
					map
						.getSource("itinerary_places_source")
						.setData(itineraryPlaceSource);
					map
						.getSource("itinerary_routes_source")
						.setData(itineraryRouteSource);
				}
				if (
					allLongs.length === 0 ||
					allLats.length === 0 ||
					!props.tripOverviewVisibility
				) {
					return;
				}
				const minLong = Math.min.apply(null, allLongs); // west
				const maxLong = Math.max.apply(null, allLongs); // east
				const minLat = Math.min.apply(null, allLats); // south
				const maxLat = Math.max.apply(null, allLats); // north
				const bounds = [
					[minLong, minLat],
					[maxLong, maxLat],
				];
				map.fitBounds(bounds, { maxZoom: 11, padding: 150 });
			} else {
				var emptyPlaces = { type: "FeatureCollection", features: [] };
				var emptyRoute = {
					id: "s",
					type: "Feature",
					properties: {},
					geometry: {
						type: "LineString",
						coordinates: [],
					},
				};
				if (
					map.getSource("itinerary_places_source") &&
					map.getSource("itinerary_routes_source")
				) {
					map.getSource("itinerary_places_source").setData(emptyPlaces);
					map.getSource("itinerary_routes_source").setData(emptyRoute);
				}
			}
			return () => {};
		}
		return () => {};
	}, [currentTripItinerary_, props.tripOverviewVisibility]);

	useEffect(() => {
		if (map) {
			if (
				(tripItineraryVisibility, props.tripOverviewVisibility) ||
				(currentTripItinerary_.visibility &&
					currentTripItinerary_.selectedDay.index !== null)
			) {
				map.setLayoutProperty("places", "visibility", "none");
				map.setLayoutProperty("anchorages_layer", "visibility", "none");
				map.setLayoutProperty("coastview", "visibility", "none");
				map.setLayoutProperty("clusters", "visibility", "none");
				map.setLayoutProperty("clusters-count", "visibility", "none");
				map.setLayoutProperty("ships", "visibility", "none");
				map.setLayoutProperty(
					"itinerary_layer_places",
					"visibility",
					"visible"
				);
				map.setLayoutProperty(
					"itinerary_layer_routes",
					"visibility",
					"visible"
				);
				map.setLayoutProperty("itinerary-clusters", "visibility", "visible");
				map.setLayoutProperty(
					"itinerary-clusters-count",
					"visibility",
					"visible"
				);
			} else {
				if (map?.isStyleLoaded()) {
					map.setLayoutProperty("clusters", "visibility", "visible");
					map.setLayoutProperty("clusters-count", "visibility", "visible");
					if (filtersSelected.needs === "trips") {
						map.setLayoutProperty("clusters", "visibility", "visible");
						map.setLayoutProperty("clusters-count", "visibility", "visible");
					}
					map.setLayoutProperty("places", "visibility", "visible");
					map.setLayoutProperty("anchorages_layer", "visibility", "visible");
					map.setLayoutProperty("coastview", "visibility", "visible");
					map.setLayoutProperty("ships", "visibility", "visible");
					map.setLayoutProperty("itinerary_layer_places", "visibility", "none");
					map.setLayoutProperty("itinerary_layer_routes", "visibility", "none");
					map.setLayoutProperty("itinerary-clusters", "visibility", "none");
					map.setLayoutProperty(
						"itinerary-clusters-count",
						"visibility",
						"none"
					);
				}
			}
		}
		return () => {};
	}, [
		tripItineraryVisibility,
		props.tripOverviewVisibility,
		props.enableOnboard,
	]);

	useEffect(() => {
		if (
			tripItineraryVisibility &&
			currentTripItinerary_?.selectedDay?.inEditing
		) {
			// setTimeout(() => {
			// 	props.setGWBrandVisibility(true);
			// }, 500);
		} else if (
			tripItineraryVisibility &&
			!currentTripItinerary_?.selectedDay?.inEditing
		) {
			setTimeout(() => {
				if (map && map.setLayoutProperty && map.getSource) {
					map.setLayoutProperty("places", "visibility", "none");
					map.setLayoutProperty("anchorages_layer", "visibility", "none");
					map.setLayoutProperty("coastview", "visibility", "none");
					map.setLayoutProperty("clusters", "visibility", "none");
					map.setLayoutProperty("clusters-count", "visibility", "none");
					map.setLayoutProperty("ships", "visibility", "none");

					map.setLayoutProperty(
						"itinerary_layer_places",
						"visibility",
						"visible"
					);
					map.setLayoutProperty(
						"itinerary_layer_routes",
						"visibility",
						"visible"
					);
				}
			}, 100);
		}

		if (!currentTripItinerary_?.selectedDay?.inEditing) {
			// props.setGWBrandVisibility(false);
		}

		setTimeout(() => {
			reloadItineraryDataSource(
				currentTripItinerary_?.selectedDay?.inEditing,
				currentTripItinerary_,
				SELECTED_ITINERARY_DAY
			);
		}, 1000);

		return () => {};
	}, [
		filtersSelected,
		currentTripItinerary_?.selectedDay?.inEditing,
		currentTripItinerary_.selectedDay.index,
		tripItineraryVisibility,
	]);

	useEffect(() => {
		if (SELECTED_ITINERARY_DAY?.inEditing) {
			if (currentTripItinerary_?.visibility && map && map.getLayer("places")) {
				map.setLayoutProperty("places", "visibility", "visible");
			}
			reloadItineraryDataSource(
				true,
				currentTripItinerary_,
				SELECTED_ITINERARY_DAY,
				false
			);
		}

		return () => {};
	}, [currentTripItinerary_, SELECTED_ITINERARY_DAY]);

	return (
		<>
			<Dialog open={showDialog} onClose={() => setShowDialog(false)}>
				<DialogTitle>
					{/* {languages[currentLanguage]["place_details_share_button"]} */}
					Message
				</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{languages[currentLanguage]["notrip_initiate_trip_wizard_msg"]}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => setShowDialog(false)}>Cancel</Button>
					<Button
						onClick={() => {
							setShowDialog(false);
							handleAdd();
						}}
						// autoFocus
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>

			<div className="map-container" ref={mapContainerRef} />

			{/* GLOBAL WHEATHER BTN */}
			{/* <IconButton
				disabled={tripItineraryVisibility}
				title={
					languages[currentLanguage]["explore_weather_hover_unselected_button"]
				}
				onClick={() => {
					// EVENT
					logEvent_("react_explore_weatherbtn_pressed");

					if (globalWeatherBrandVisibility) onGlobalWeatherclose();
					if (!props.enableOnboard) onGlobalClick();
				}}
				className="custom-weather-toggle-btn"
				style={{
					zIndex: 1,
					position: "absolute",
					backgroundColor: "#0078ff",
					boxShadow: "0 0 6px gray",
					opacity: tripItineraryVisibility ? 0.5 : 1,
				}}
			>
				<img
					src={require(`../../assets/weather${
						globalWeatherBrandVisibility
							? "_suitability_selected"
							: "_recommendation"
					}_ic.svg`)}
					style={{
						width: 18,
						height: 18,
					}}
				/>
			</IconButton> */}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setGWBrandVisibility: (bool = false) =>
			dispatch(setGlobalWeatherBrandVisibility(bool)),
		addTripNodeEditing: (
			placeData = {},
			forceLastNode = false,
			callback = () => {}
		) => dispatch(addTripNodeEditing(placeData, forceLastNode, callback)),
		setTripItineraryVisibility: (bool = false) =>
			dispatch(setTripItineraryVisibility(bool)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

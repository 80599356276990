import { SWITCH_LANG } from "./types";

export const initialState = {
  language: window.localStorage.getItem("language")
    ? JSON.parse(window.localStorage.getItem("language")).language
    : "EN",
};

// Reducer
export default function langReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case SWITCH_LANG:
      window.localStorage.setItem(
        "language",
        JSON.stringify({ language: action.payload })
      );
      newState = {
        language: action.payload,
      };
      break;
    default:
      break;
  }

  return newState;
}

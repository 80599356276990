import React from "react";
import { filterHighlightColor, whiteColor } from "../../../constants/colors";

const Button = ({
	next = false,
	text = "",
	handleNext = () => {},
	handleBack = () => {},
}) => {
	return (
		<>
			<div
				className="text-center d-flex align-items-center justify-content-center"
				onClick={next ? handleNext : handleBack}
				style={{
					color: !next ? filterHighlightColor : whiteColor,
					backgroundColor: next ? filterHighlightColor : whiteColor,
					border: "1px solid" + filterHighlightColor,
					borderRadius: 5,
					cursor: "pointer",
					width: 185,
					height: 35,
				}}
			>
				{text}
			</div>
		</>
	);
};

export default Button;

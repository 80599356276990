import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

export const FIREBASE_CONFIG = {
	apiKey: "AIzaSyAKaEtJmMFhTStNKxvXoVyNZuWasRtCbhQ",
	authDomain: "keeano-156616.firebaseapp.com",
	databaseURL: "https://keeano-156616.firebaseio.com",
	projectId: "keeano-156616",
	storageBucket: "keeano-156616.appspot.com",
	messagingSenderId: "243002230193",
	appId: "1:243002230193:web:028fe6746fe398dbdfa990",
	measurementId: "G-S0017CKT5J",
};

const firebaseMessagingServices = ({ serverFcmTokenRegister = () => {} }) => {
	const app = initializeApp(FIREBASE_CONFIG);
	const analytics = getAnalytics(app);
	const messaging = getMessaging(app);
	const perf = getPerformance(app);

	getToken(messaging, {
		vapidKey:
			"BFMgub3s6YAFhUpweHoCHlp9PJa_UFJFV41foieT-Sl8XXHeCa-ORhOxTX6iVjmTuJmpu0vonnus3iVrF8tz0aE",
	})
		.then((currentToken) => {
			if (currentToken) {
				serverFcmTokenRegister(currentToken);
			} else {
				// Show permission request UI
				//console.log("No registration token available. Request permission to generate one.");
			}
		})
		.catch((err) => {
			//console.log("An error occurred while retrieving token. ", err);
		});

	onMessage(messaging, (payload) => {
		//console.log("Message received. ", payload);
		// ...
	});
};

export default firebaseMessagingServices;

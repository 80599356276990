import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// CONSTANTS
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../../constants/colors";

// STORE
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import {
	selectedItineraryDay,
	currentTripItinerary,
} from "../../../store/features/map/selectors";

// COMPONENTS
import { iconDictionary } from "../../TripWizard/Steps/First";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";

// ICONS
import deleteIcon from "../../../assets/svg/delete-ic-n.svg";
import cover_ from "../../../assets/svg/hero-ve-bg.svg";
import editIcon from "../../../assets/svg/itinerary/edit-active-ic.svg";
import minus from "../../../assets/svg/itinerary/minus-icon.svg";
import plus from "../../../assets/svg/itinerary/plus-icon.svg";

// STYLE
const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		fontFamily: "dromon-l",
		color: formMainTxt,
		borderBottom: "none",
	},
}));

export const CardPlace = ({
	place = null,
	isCircular = false,
	edited = false,
	canEdit = false,
	end = false,
	noEditMode = false,
	showOvernight = false,
	onClickDelete = () => {},
	onClickNote = () => {},
	openPlace = () => {},
}) => {
	const currentLanguage = useSelector(currentLang);

	return (
		<div
			style={{
				border: "solid 1px #e3e5e9",
				borderRadius: 5,
			}}
		>
			<div className="d-flex flex-row">
				<div
					onClick={() => openPlace()}
					className="d-flex justify-content-center align-items-center mr-2"
					style={{
						height: 93,
						width: 93,
						borderRadius: 5,
						backgroundImage:
							place?.CoverImage && !edited
								? `url(${place?.CoverImage})`
								: edited && place?.Cover
								? `url(${place?.Cover})`
								: `url(${cover_})`,
						backgroundPosition: "center",
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						cursor: "pointer",
						position: "relative",
					}}
				>
					<div
						style={{
							position: "absolute",
							backgroundColor: "rgba(0, 0, 0, 0.21)",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							borderRadius: 5,
						}}
					/>

					<img
						src={iconDictionary[`${place?.Type}_`]}
						style={{ height: 30, zIndex: 1 }}
						alt=""
					/>
				</div>

				<div
					style={{ position: "relative", flex: 1 }}
					className="d-flex flex-column justify-content-between pt-3 pb-2"
				>
					<h2
						style={{
							color: formMainTxt,
							fontSize: 14,
						}}
						className="mb-0"
					>
						{place?.Place?.Name
							? place?.Place?.Name
							: place?.Name?.Latin
							? place?.Name?.Latin
							: typeof place?.Name === "string"
							? place?.Name
							: place?.Type}
					</h2>

					<div>
						<Button
							onClick={onClickNote}
							style={{ textTransform: "initial" }}
							className=""
						>
							<h3
								style={{ color: filterHighlightColor, fontSize: 12 }}
								className="mb-0"
							>
								{languages[currentLanguage]["trip_itinerary_notes_button"]}
							</h3>
						</Button>
					</div>
				</div>

				<div className="d-flex justify-content-center align-items-center mx-2">
					{((!isCircular && end && canEdit) ||
						(canEdit && !end) ||
						showOvernight) && (
						<>
							{!noEditMode && (
								<IconButton
									onClick={onClickDelete}
									style={{
										height: 20,
										width: 20,
										padding: 0,
									}}
								>
									<img
										alt=""
										src={deleteIcon}
										style={{
											height: 20,
											width: 20,
										}}
									/>
								</IconButton>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const FiledStart = ({
	place,
	openPlace,
	indexDay,
	startTime,
	canEdit = false,
	removeTripNode = () => {},
	handleChangeStartTime = () => {},
	onOpenNoteEdit = () => {},
}) => {
	const classes = useStyles();
	const [toEdit, setToEdit] = useState(false);
	const currentLanguage = useSelector(currentLang);

	return (
		<>
			<div className="d-flex flex-row mb-1 align-items-center" style={{}}>
				<img
					onClick={() => setToEdit(!toEdit)}
					src={editIcon}
					style={{ height: 15, width: 14, cursor: "pointer" }}
					alt=""
					className="mr-3"
				/>
				{!toEdit ? (
					<span
						style={{ color: filterHighlightColor, fontSize: 16 }}
						className="mr-3"
					>
						{startTime}
					</span>
				) : (
					<TextField
						id="time"
						label=""
						type="time"
						value={startTime}
						onChange={(e) => handleChangeStartTime(e.target.value)}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300,
						}}
						size="small"
					/>
				)}
				<span style={{ color: formMainTxt, fontSize: 14 }} className="mr-3">
					{languages[currentLanguage]["trip_itinerary_start"]} :
				</span>
			</div>
			<div
				className="ml-1 pb-3 pt-0"
				style={{ borderLeft: "1px dashed " + secondaryTxt, paddingLeft: 64 }}
			>
				<CardPlace
					canEdit={canEdit}
					place={place}
					placeType={place?.Need}
					onClickDelete={removeTripNode}
					onClickNote={onOpenNoteEdit}
					noEditMode={indexDay !== 0}
					openPlace={openPlace}
				/>
			</div>
		</>
	);
};

export const FiledOvernight = ({
	place,
	end = false,
	isCircular = false,
	canEdit = false,
	removeTripNode = () => {},
	onOpenNoteEdit = () => {},
	openPlace = () => {},
}) => {
	// SELECTORS
	const CURRENT_LANGUAGE = useSelector(currentLang);

	return (
		<>
			<div>
				<div className="pl-0 py-0 my-0 d-flex flex-row align-items-center">
					<span
						className="mb-0"
						style={{
							border: "solid 1px #8f96a7",
							height: 10,
							width: 10,
							borderRadius: 5,
						}}
					></span>
					<span
						className="dromon-l mb-0 ml-3"
						style={{ color: secondaryTxt, fontSize: 12 }}
					>
						{languages[CURRENT_LANGUAGE]["trip_itinerary_route_tip"]}
					</span>
				</div>
				<div className="pb-4">
					<div
						className="ml-1 pb-4 pt-3"
						style={{
							borderLeft: "1px dashed " + secondaryTxt,
							paddingLeft: 64,
						}}
					>
						<CardPlace
							end={end}
							isCircular={isCircular}
							place={place}
							canEdit={canEdit}
							placeType={place?.Need}
							onClickDelete={removeTripNode}
							onClickNote={onOpenNoteEdit}
							showOvernight={false}
							openPlace={openPlace}
						/>
					</div>
					<div className="pl-0 pt-0 pb-1 my-0 d-flex flex-row justify-content-between align-items-center">
						<span
							className="mb-0"
							style={{
								height: 10,
								backgroundColor: secondaryTxt,
								width: 10,
								borderRadius: 5,
							}}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export const FiledMiddle = ({
	place = {},
	end = false,
	isCircular = false,
	idxNode = 0,
	canEdit = false,
	increaseDuration = () => {},
	decreaseDuration = () => {},
	removeTripNode = () => {},
	onOpenNoteEdit = () => {},
	replaceShelterNode = () => {},
	openPlace = () => {},
}) => {
	// SELECTORS
	const tripNodesStored = useSelector(selectedItineraryDay).tripNodes;
	const currentLanguage = useSelector(currentLang);
	const currentTripId = useSelector(currentTripItinerary)?.data?.Id;

	return (
		<div>
			<div className="pl-0 py-0 my-0 d-flex flex-row align-items-center">
				<span
					className="mb-0"
					style={{
						border: "solid 1px #8f96a7",
						height: 10,
						width: 10,
						borderRadius: 5,
					}}
				></span>
				<span
					className="dromon-l mb-0 ml-3"
					style={{ color: secondaryTxt, fontSize: 12 }}
				>
					{languages[currentLanguage]["trip_itinerary_route_tip"]}
				</span>
			</div>
			<div
				className="ml-1 pb-3 pt-3"
				style={{
					borderLeft: "1px dashed " + secondaryTxt,
					paddingLeft: 64,
					position: "relative",
				}}
			>
				<div
					style={{
						position: "absolute",
						left: -12.5,
						top: 25,
						backgroundColor: whiteColor,
					}}
				>
					<div className="d-flex flex-column">
						<div
							onClick={() => {
								logEvent_("react_trip_itiner_durationbutton_pressed", {
									tripId: currentTripId,
								});
								increaseDuration();
							}}
							className="d-flex justify-content-center align-items-center"
							style={{
								height: 25,
								width: 25,
								borderRadius: "50%",
								border: "2px solid #dbdee6",
								cursor: "pointer",
							}}
						>
							<img src={plus} alt="" style={{ height: 10, width: 10 }} />
						</div>
						<span style={{ color: formMainTxt, fontSize: 16 }}>
							{tripNodesStored[idxNode]?.Duration} hr
						</span>
						<div
							onClick={() => {
								logEvent_("react_trip_itiner_durationbutton_pressed", {
									tripId: currentTripId,
								});
								decreaseDuration();
							}}
							className="d-flex justify-content-center align-items-center"
							style={{
								height: 25,
								width: 25,
								borderRadius: "50%",
								border: "2px solid #dbdee6",
								cursor: "pointer",
							}}
						>
							<img src={minus} alt="" style={{ height: 2, width: 20 }} />
						</div>
					</div>
				</div>
				<CardPlace
					place={place}
					canEdit={canEdit}
					placeType={place?.Need || "launching"}
					onClickDelete={removeTripNode}
					onClickNote={onOpenNoteEdit}
					replaceShelterNode={replaceShelterNode}
					showOvernight
					isCircular={isCircular}
					end={end}
					openPlace={openPlace}
				/>
			</div>
		</div>
	);
};

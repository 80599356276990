import React, { useState, useEffect } from "react";
import MultiselectCheck from "../Components/MultiselectCheck";
import Button from "../Components/Button";
import { connect, useSelector } from "react-redux";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { logEvent_ } from "../../../helpers/analytics";

const Third = (props) => {
	const currentLanguage = useSelector(currentLang);
	const [options, setOptions] = useState([]);
	const tripAttributes = useSelector((state) => state.map.tripAttributes);
	const [isSingle, setIsSingle] = useState(false);
	const [state, setState] = useState([]);

	const handleBack = () => {
		props.handleBack({
			step: 3,
		});
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_steppeople_nextbtn_pressed");

		props.handleNext({
			step: 3,
			tags: state,
		});
	};

	const handleSelect = (option) => {
		if (isSingle) {
			setState([option]);
		} else {
			let actualState = state;
			actualState = actualState.push(options);
			setState(actualState);
		}
	};
	useEffect(() => {
		// //console.log("ATTRIBUTES ===>", tripAttributes);
		let categories = Object.keys(tripAttributes);
		let versions = tripAttributes[categories[0]].Values;
		setIsSingle(tripAttributes[categories[0]].IsSingle);
		setOptions(versions);
		setState(
			props.state?.Attributes ? props.state?.Attributes[3] : [versions[0]]
		);
	}, [tripAttributes, props.state, props.finished]);
	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<div>
						<h3
							className="my-2 dromon-l"
							style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
						>
							Creating a New Trip
						</h3>
						<h2 style={{ fontSize: 22, color: "#222c4b" }}>
							{languages[currentLanguage]["trip_attr_crew_header"]}
						</h2>
						<div>
							{options.map((option) => (
								<MultiselectCheck
									handleSelect={() => handleSelect(option)}
									text={
										languages[currentLanguage][option.replaceAll(":", "_")]
											? languages[currentLanguage][option.replaceAll(":", "_")]
											: option
									}
									key={option}
									selected={state.includes(option)}
								/>
							))}
						</div>
					</div>
				</div>
				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						next
						text={languages[currentLanguage]["common_next"]}
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Third);

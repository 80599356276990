import { CLOSE_REVIEW_PAGE, REVIEW_PLACE, OPEN_REVIEW, OPEN_REVIEW_LIST } from "./types";

export const initialState = {
  placeToReview: null,
  showReviews: false,
  showAddReviews: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 */
export default function reviewReducer(state = initialState, action) {
  let newState = state;
  switch (action.type) {
    case OPEN_REVIEW:
      newState = {
        ...state,
        placeToReview: action.payload,
        showAddReviews: true,
        showReviews: false,
      };
      break;
    case OPEN_REVIEW_LIST:
      newState = {
        ...state,
        placeToReview: action.payload,
        showAddReviews: false,
        showReviews: true,
      }
      break;
    case CLOSE_REVIEW_PAGE:
      newState = {
        ...state,
        showAddReviews: false,
        showReviews: false,
      };
      break;
    case REVIEW_PLACE:
      break;
    default:
      break;
  }

  return newState;
}

import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItem,
	List,
	Collapse,
	InputLabel,
	IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dateFormat from "date-fns/format";
import SelectSearch from "react-select-search";
import { toast } from "react-toastify";

// API
import { ApiClient } from "../../ApiClient";

// CONSTANTS
import { formMainTxt, whiteColor } from "../../constants/colors";
import DEFAULT_TOAST_PROPS from "../../constants/tostifyDefaultProps";

// HELPERS
import { isEmpty } from "../../helpers/functions";
import { AuthService } from "../../AuthService";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import {
	userData as currentUserData_,
	userProfileId,
} from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import {
	closeUserSidebars,
	showUserProfile,
} from "../../store/features/user/actions";

// COMPONENTS
import SideBar from "../utils/SideBar";
import TripCard from "./components/TripCard";

// ICONS
import tripAddIcon from "../../assets/trip_list_add_button.svg";
import { formatDate_ } from "../TripWizard";

const TripList = ({
	visible,
	bookmarks,
	showUserProfile,
	closeUserSidebars,
	closeSidebar,
	createTrip,
	deleteTrip,
	displayOverview,
	dataTripToFilter,
}) => {
	// SELECTORS
	const currentUserData = useSelector(currentUserData_);
	const selectedUserProfileId = useSelector(userProfileId);
	const currentLanguage = useSelector(currentLang);

	// DATA
	const [, setUserData] = React.useState([]);
	const [displayedTrips, setDisplayedTrips] = React.useState(null);
	const [isOwner, setIsOwner] = React.useState(false);
	const [showDialog, setShowDialog] = React.useState(false);
	const [, setCurrentTripId] = React.useState(null);
	const [currentTrip, setCurrentTrip] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [collapseFilter, setCollapseFilter] = React.useState(false);
	const [tripFilter, setTripFilter] = React.useState([]);
	const [currentTripFilter, setCurrentTripFilter] = React.useState(0);
	const [VesselId, setVesselId] = React.useState(null);
	const [vessels, setVessels] = React.useState([]);
	const [places, setPlaces] = React.useState([]);
	const [placeId, setPlaceId] = React.useState(null);
	const [stateId, setStateId] = React.useState(null);
	const [charterId, setCharterId] = React.useState(null);
	const [fromDate, setFromDate] = React.useState(null);
	const [toDate, setToDate] = React.useState(null);
	const [otherFilterDisable, setOtherFilterDisable] = React.useState(false);
	const [charterIdDisable, setCharterIdDisable] = React.useState(true);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [, setPageResultsCount] = React.useState(0);
	const [totalResultsCount, setTotalResultsCount] = React.useState(0);
	const [currentVisibility, setCurrentVisibility] = React.useState(false);
	const [isDateRangeDisabled, setIsDateRangeDisabled] = React.useState(true);
	const [canGoNext, setCanGoNext] = React.useState(false);
	const [canGoBack, setCanGoBack] = React.useState(false);
	const [filtersCount, setFiltersCount] = React.useState(1);
	const [partnerAllowPagination, setPartnerAllowPagination] =
		React.useState(true);

	const loadView = async () => {
		setLoading(true);
		setIsOwner(false);
		const dataToDisplay =
			bookmarks !== null && bookmarks?.length > 0
				? bookmarks
				: dataTripToFilter !== null
				? dataTripToFilter
				: [];

		if (dataToDisplay.length > 0) {
			setPartnerAllowPagination(false);
		} else {
			setPartnerAllowPagination(true);
		}

		if (
			AuthService.isUserPartner() &&
			dataToDisplay.length === 0 &&
			selectedUserProfileId === currentUserData.ID
		) {
			await getVessels();
			await getBaseStations();
			await getFilterStates();
			getTripsPerPage(currentPage);
		} else {
			if (dataToDisplay?.length) {
				const parsed = parseTrips(dataToDisplay);
				setDisplayedTrips(parsed);
			} else {
				setDisplayedTrips([]);
			}
		}

		if (!isEmpty(selectedUserProfileId)) {
			if (selectedUserProfileId === currentUserData.ID) {
				var new_url =
					"https://" +
					window.location.host +
					"/explore/user/" +
					(typeof currentUserData.UserName === "string"
						? currentUserData.UserName.replace(
								new RegExp(/[ ]/, "g"),
								"-"
						  ).toLowerCase()
						: "") +
					"/trips";
				window.history.pushState(null, null, new_url);
				setIsOwner(true);
				setUserData(currentUserData);
				setLoading(false);
			} else {
				ApiClient.getOtherUserById(selectedUserProfileId)
					.then((u) => {
						var new_url =
							"https://" +
							window.location.host +
							"/explore/user/" +
							(typeof u.UserName === "string"
								? u.UserName.replace(new RegExp(/[ ]/, "g"), "-").toLowerCase()
								: "") +
							"/trips";
						window.history.pushState(null, null, new_url);
						setUserData(u);
					})
					.finally(() => setLoading(false));
			}
		} else {
			setLoading(false);
		}
	};

	const parseTrips = (data) => {
		data.forEach((v) => {
			let now = Date.now();
			let date = new Date(v.StartDate);
			let start = date.getTime();
			let duration = v.DurationDays * 24 * 3600 * 1000;
			v.canDelete = false;
			if (v.Creator) {
				if (currentUserData.ID === v.Creator.UserId && !v.State) {
					v.canDelete = true;
				}
				if (
					currentUserData.ID === v.Creator.UserId &&
					(v.State === "onboarding" ||
						v.State === "template" ||
						v.State === "canceled")
				) {
					v.canDelete = true;
				}
			}
			if (v.Vessel) {
				var vesselType = v.Vessel.Type.replace("community", "").replace(
					"business",
					""
				);
				v.vesselType = vesselType;
			}
			if (v.Type === "business") {
				if (v.StartDate) {
					v.FormattedDeparture = formatDate_(v.StartDate);
					v.FormattedArrivalTime = "";
				}
				if (v.ArrivalTime) {
					var val = v.ArrivalTime.substring(2);
					if (val.length === 6) {
						val = val.substring(0, val.length - 1);
					}
					v.FormattedArrivalTime = val;
				}
			}
			if (v.State) {
				var backgroundColor = whiteColor;
				var titleColor = formMainTxt;
				if (v.State === "live") {
					backgroundColor = "#75fb4c";
					titleColor = formMainTxt;
				} else if (v.State === "onboarding" || v.State === "completed") {
					backgroundColor = "#0078ff";
					titleColor = whiteColor;
				} else if (v.State === "cancelled" || v.State === "canceled") {
					backgroundColor = "#ff0000";
					titleColor = whiteColor;
				}
				v.status = v.State;
				v.color = titleColor;
				v.backgroundColor = backgroundColor;
			} else {
				// COMMUNITY TRIPS
				if (now < start) {
					v.status = "upcoming";
					v.color = formMainTxt;
					v.backgroundColor = whiteColor;
				} else if (now > start + duration) {
					v.status = "completed";
					v.color = whiteColor;
					v.backgroundColor = formMainTxt;
				} else if (now >= start && now < start + duration) {
					v.status = "live";
					v.color = formMainTxt;
					v.backgroundColor = "#75fb4c";
				}
			}
		});
		if (AuthService.isUserPartner()) {
			data.sort(function (a, b) {
				if (a.StartDate !== null && b.StartDate !== null) {
					return new Date(b.StartDate) - new Date(a.StartDate);
				}
				return 0;
			});
		}
		return data;
	};

	const getVessels = async () => {
		ApiClient.getMyVessels()
			.then((vessels) => {
				const customizedUserVessels = [];
				vessels.forEach((vessel) => {
					vessel.Type = vessel.Type.replace("community", "").replace(
						"business",
						""
					);
					const name =
						(!isEmpty(vessel.Name) ? vessel.Name : vessel.Type) +
						(!isEmpty(vessel.Flag) ? ` (${vessel.Flag})` : "");
					customizedUserVessels.unshift({
						...vessel,
						name: name,
						value: vessel.ID,
					});
				});
				customizedUserVessels.unshift({
					Name: "",
					name: "",
					Type: "",
					value: 0,
					Flag: "",
				});
				if (customizedUserVessels.length > 0 && VesselId === null) {
					//setVesselId(customizedUserVessels[0].value);
				}
				setVessels(customizedUserVessels);
			})
			.catch(() => {})
			.finally(() => {});
	};

	const getBaseStations = async () => {
		ApiClient.getMyBaseStations()
			.then((places) => {
				const customized = [];
				places.forEach((place) => {
					customized.unshift({
						...place,
						name:
							(!isEmpty(place.Name) ? place.Name : place.Type) +
							(!isEmpty(place.Type) ? `(${place.Type})` : ""),
						value: place.ID,
					});
				});
				customized.unshift({ Name: "", name: "", Type: "", value: 0 });
				setPlaces(customized);
			})
			.catch(() => {})
			.finally(() => {});
	};

	const getFilterStates = async () => {
		ApiClient.getTripsFilters().then((_res) => {
			// setCurrentTripFilter(0);
			// setFromDate(null);
			// setToDate(null);
			setTripFilter(_res);
		});
	};

	const handleSwitch = (vesselId) => {
		setVesselId(vesselId);
	};

	const onCharterIdFieldFocused = () => {
		// Gray out others
		setOtherFilterDisable(true);
		// Highlight charter field
		setCharterIdDisable(false);
	};

	const onOtherFieldsFocused = () => {
		setCharterId("");
		// Gray out charter id
		setCharterIdDisable(true);
		// Highlight others field
		setOtherFilterDisable(false);
	};

	const diffInMonths = (end, start) => {
		var timeDiff = Math.abs(end.getTime() - start.getTime());
		return Math.round(timeDiff / (2e3 * 3600 * 365.25));
	};

	const onApply = () => {
		if (charterId !== null && charterId !== "") {
			getTripsPerPage(1);
			setFiltersCount(1);
			return;
		}
		if (
			stateId !== "live" &&
			tripFilter[currentTripFilter]?.SubType !== "template" &&
			(fromDate === null || toDate === null)
		) {
			showWarningMessage("Date range missing");
			return;
		}
		const monthDifference = diffInMonths(new Date(toDate), new Date(fromDate));
		if (monthDifference > 2) {
			showWarningMessage("Maximum date range interval 2 months");
			return;
		}
		setCurrentPage(1);
		getTripsPerPage(1);
		var count = 1;
		if (fromDate !== null && toDate !== null) {
			count += 1;
		}
		if (placeId > 0) {
			count += 1;
		}
		if (VesselId > 0) {
			count += 1;
		}
		setFiltersCount(count);
	};

	const getTripsPerPage = (page) => {
		setCollapseFilter(false);
		setLoading(true);
		ApiClient.getFilteredTrips(
			tripFilter[currentTripFilter]?.SubType === "template" ||
				!tripFilter[currentTripFilter]?.SubType
				? null
				: stateId ?? tripFilter[currentTripFilter]?.State[0],
			fromDate,
			toDate,
			placeId === 0 ? null : placeId,
			VesselId === 0 ? null : VesselId,
			charterId,
			tripFilter[currentTripFilter]?.SubType ?? "template",
			page ?? 1
		)
			.then((response) => {
				if (response.ErrorMessage) {
					showWarningMessage(response.ErrorMessage);
				}
				if (response.Data?.length > 0) {
					const parsed = parseTrips(response.Data);
					setCurrentPage(response.Page);
					setPageResultsCount(response.Count);
					setTotalResultsCount(response.TotalCount);
					setCanGoNext(response.TotalCount > 5 * response.Page);
					setCanGoBack(response.Page > 1);
					setDisplayedTrips(parsed);
				} else {
					setCanGoNext(false);
					setCanGoBack(false);
					setDisplayedTrips([]);
				}
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const showWarningMessage = (message) => {
		toast.warning(message, DEFAULT_TOAST_PROPS);
	};

	const nextPage = () => {
		const nextPage = currentPage + 1;
		setCurrentPage(nextPage);
		getTripsPerPage(nextPage);
	};

	const previousPage = () => {
		if (currentPage === 1) {
			return;
		}
		const previousPage = currentPage - 1 < 0 ? 0 : currentPage - 1;
		setCurrentPage(previousPage);
		getTripsPerPage(previousPage);
	};

	// EFFECTS
	React.useEffect(() => {
		setCurrentVisibility(visible);
		if (visible) {
			loadView();
		}
	}, [visible, bookmarks, selectedUserProfileId, dataTripToFilter]);

	return (
		<SideBar
			visible={visible}
			useBackBtn={true}
			back_title={languages[currentLanguage]["user_trips_header"]}
			isLoading={loading || !bookmarks || displayedTrips === null}
			onClickBack={() => {
				closeSidebar();
				closeUserSidebars();
				showUserProfile();
			}}
			fixedFooterButton={true}
			customFooter={
				AuthService.isUserPartner() &&
				partnerAllowPagination &&
				displayedTrips !== null &&
				displayedTrips?.length > 0 && (
					<div style={{ flex: 1 }} className="row p-2 w-100 h-100">
						<div
							className="col d-flex justify-content-center"
							style={{ marginLeft: -30, fontFamily: "Decimamono" }}
						>
							Results
						</div>

						<div
							className="col d-flex text-bold justify-content-center align-items-center"
							style={{ fontFamily: "Decimamono" }}
						>
							{currentPage > 1 ? 5 * (currentPage - 1) + 1 : 1} -{" "}
							{5 * currentPage} of {totalResultsCount}
						</div>

						<div className="col d-flex justify-content-end align-items-center">
							<IconButton
								className="mr-2"
								style={{ width: 15, height: 15, fontSize: 15 }}
								onClick={() => previousPage()}
								disabled={!canGoBack}
							>
								<i className="fa fa-fw fa-chevron-left" />
							</IconButton>

							<IconButton
								style={{ width: 15, height: 15, fontSize: 15 }}
								onClick={() => nextPage()}
								disabled={!canGoNext}
							>
								<i className="fa fa-fw fa-chevron-right" />
							</IconButton>
						</div>
					</div>
				)
			}
		>
			<Dialog open={showDialog} onClose={() => setShowDialog(false)}>
				<DialogTitle>Confirmation</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{languages[currentLanguage]["trip_delete_msg"]}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => setShowDialog(false)}>Cancel</Button>
					<Button
						onClick={async () => {
							setShowDialog(false);

							await deleteTrip(currentTrip);
							if (AuthService.isUserPartner()) {
								loadView();
							}

							setCurrentTripId(null);
							setCurrentTrip(null);
						}}
						autoFocus
						style={{ color: "#eb5732" }}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			{AuthService.isUserLogedIn() && isOwner && (
				<IconButton
					onClick={() => createTrip()}
					title={
						AuthService.isUserPartner()
							? languages[currentLanguage]["trip_create_template_trip"]
							: languages[currentLanguage]["trip_list_createtrip_button"]
					}
					style={{
						position: "absolute",
						zIndex: 11,
						top: 29,
						right: 15,
						padding: 0,
					}}
				>
					<img src={tripAddIcon} style={{ height: 28, width: 28 }} />
				</IconButton>
			)}

			<div>
				{/* Filters */}
				{AuthService.isUserPartner() && partnerAllowPagination && (
					<>
						<ListItem
							button
							style={{
								backgroundColor: "white",
								color: "black",
								fontSize: 16,
								fontFamily: "Dromon-light",
								borderBottom: "solid 1px rgba(255, 255, 255, 1)",
							}}
							onClick={() => setCollapseFilter(!collapseFilter)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="15"
								height="16"
								viewBox="0 0 19 20"
							>
								<g
									fill="none"
									fillRule="evenodd"
									strokeLinecap="round"
									strokeLinejoin="round"
								>
									<g stroke="#222C4B" strokeWidth="2">
										<g>
											<g>
												<path
													d="M5.846 18L5.846 7.181 0 0 16.321 0 10.608 7.181 10.608 14.969z"
													transform="translate(-351.000000, -108.000000) translate(36.500000, 93.000000) translate(316.000000, 16.000000)"
												/>
											</g>
										</g>
									</g>
								</g>
							</svg>
							<span
								style={{
									marginLeft: 15,
									flex: 1,
									fontFamily: "Dromon-light",
									textColor: "black",
								}}
								className="px-2 mx-0"
							>
								Filters ( {filtersCount} )
							</span>
							{collapseFilter ? (
								<span style={{ color: "#0078ff" }}>
									<i className="fas fa-fw fa-chevron-up" />
								</span>
							) : (
								<span style={{ color: "#0078ff" }}>
									<i className="fas fa-fw fa-chevron-down" />
								</span>
							)}
						</ListItem>
						<Collapse in={collapseFilter} timeout="auto" unmountOnExit>
							<List component="form" className="px-4 pt-3">
								<div className="row">
									{/* /.col-6 */}
									<div
										className="col-6 mb-2"
										style={{ opacity: otherFilterDisable ? "0.3" : "1" }}
									>
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 5,
												marginBottom: 5,
											}}
										>
											Trip Type
										</InputLabel>
										<SelectSearch
											className="select-search"
											value={currentTripFilter}
											options={tripFilter?.map((item, index) => ({
												name: item?.SubType,
												value: index,
											}))}
											name="TripType"
											onChange={(value) => {
												setCurrentTripFilter(value);
												const disableDateRange =
													tripFilter[value]?.SubType === "template";
												setIsDateRangeDisabled(disableDateRange);
												if (disableDateRange) {
													setFromDate(null);
													setToDate(null);
												}
											}}
											onFocus={onOtherFieldsFocused}
											renderOption={(item, options, snpOption, className) => {
												onOtherFieldsFocused();
												return (
													<button
														{...item}
														key={item.tabIndex}
														className={"d-flex align-items-center " + className}
													>
														<span>{options.name}</span>
													</button>
												);
											}}
										/>
									</div>

									{/* /.col-6 */}
									<div
										className="col-6 mb-2"
										style={{
											opacity: !tripFilter[currentTripFilter]?.State?.length
												? "0.5"
												: "1",
										}}
									>
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 5,
												marginBottom: 5,
											}}
										>
											State
										</InputLabel>
										<SelectSearch
											disabled={!tripFilter[currentTripFilter]?.State?.length}
											className="select-search"
											options={
												tripFilter[currentTripFilter]?.State?.map(
													(item, index) => ({
														name: item,
														value: item,
													})
												) ?? []
											}
											value={stateId}
											name="State"
											onChange={(value) => {
												setStateId(value);
												const disableDateRange = value === "live";
												setIsDateRangeDisabled(disableDateRange);
												if (disableDateRange) {
													setFromDate(null);
													setToDate(null);
												}
											}}
											onFocus={onOtherFieldsFocused}
											renderOption={(item, options, snpOption, className) => {
												onOtherFieldsFocused();
												return (
													<button
														{...item}
														key={item.tabIndex}
														className={"d-flex align-items-center " + className}
													>
														<span>{options.name}</span>
													</button>
												);
											}}
										/>
									</div>
								</div>

								<div className="row">
									{/* /.col-6 */}
									<div
										className="col-6 mb-2"
										style={{ opacity: otherFilterDisable ? "0.3" : "1" }}
									>
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 5,
												marginBottom: 5,
											}}
										>
											Vessel
										</InputLabel>
										<SelectSearch
											className="select-search"
											options={vessels}
											value={VesselId}
											name="Vessel"
											onChange={(value) => handleSwitch(value)}
											emptyMessage="No data"
											search
											renderOption={(item, options, snpOption, className) => {
												onOtherFieldsFocused();
												return (
													<button
														{...item}
														key={item.tabIndex}
														className={"d-flex align-items-center " + className}
													>
														<span>{options.name}</span>
													</button>
												);
											}}
											onFocus={onOtherFieldsFocused}
										/>
									</div>
								</div>

								<div className="row">
									{/* /.col-6 */}
									<div className="col-6 mb-2">
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 5,
												marginBottom: 5,
												opacity: otherFilterDisable ? "0.3" : "1",
											}}
										>
											From
										</InputLabel>
										<KeyboardDatePicker
											clearable="true"
											disabled={isDateRangeDisabled}
											value={fromDate}
											onChange={(selectedDate) => {
												try {
													const date = dateFormat(
														new Date(selectedDate),
														"yyyy-MM-dd"
													);
													if (date > toDate) {
														setFromDate(null);
														showWarningMessage(
															"The start date cannot be after the end date"
														);
													} else {
														setFromDate(date);
													}
												} catch (e) {
													setFromDate(null);
												}
											}}
											format="dd-MM-yyyy"
											className="my-0"
											autoOk={true}
											InputProps={{
												style: {
													fontFamily: "Dromon-light",
													fontSize: 14,
													height: 44,
													opacity: otherFilterDisable ? "0.3" : "1",
												},
											}}
											onFocus={onOtherFieldsFocused}
											variant="inline"
										/>
									</div>

									{/* /.col-6 */}
									<div className="col-6 mb-2">
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 5,
												marginBottom: 5,
												opacity: otherFilterDisable ? "0.3" : "1",
											}}
										>
											To
										</InputLabel>
										<KeyboardDatePicker
											clearable="true"
											disabled={isDateRangeDisabled}
											value={toDate}
											onChange={(selectedDate) => {
												try {
													const date = dateFormat(
														new Date(selectedDate),
														"yyyy-MM-dd"
													);
													if (date < fromDate) {
														setToDate(null);
														showWarningMessage(
															"The end date cannot be before the start date"
														);
													} else {
														setToDate(date);
													}
												} catch (e) {
													setToDate(null);
												}
											}}
											format="dd-MM-yyyy"
											className="my-0"
											autoOk={true}
											InputProps={{
												style: {
													fontFamily: "Dromon-light",
													fontSize: 14,
													height: 44,
													opacity: otherFilterDisable ? "0.3" : "1",
												},
											}}
											onFocus={onOtherFieldsFocused}
											variant="inline"
										/>
									</div>
								</div>

								<div className="row mb-2">
									<div
										className="col-12 "
										style={{ opacity: otherFilterDisable ? "0.3" : "1" }}
									>
										<InputLabel
											className="mb-0"
											style={{
												fontSize: 12,
												fontFamily: "Dromon-light",
												marginTop: 10,
												marginBottom: 5,
												opacity: otherFilterDisable ? "0.3" : "1",
											}}
										>
											Base station
										</InputLabel>
										<SelectSearch
											className="select-search"
											options={places}
											value={placeId}
											name="Base station"
											onChange={(value) => setPlaceId(value)}
											search
											onFocus={onOtherFieldsFocused}
											renderOption={(item, options, _snpOption, className) => {
												onOtherFieldsFocused();
												return (
													<button
														{...item}
														key={item.tabIndex}
														className={"d-flex align-items-center " + className}
													>
														<span>{options.name}</span>
													</button>
												);
											}}
										/>
									</div>
								</div>

								<div className="row" style={{ paddingTop: 15 }}>
									<div className="container">
										<input
											className="col-12 inp-focus-BP"
											value={charterId}
											style={{
												height: 40,
												fontFamily: "Dromon-light",
												opacity: charterIdDisable ? "0.3" : "1",
											}}
											placeholder="Search by Charter ID"
											onChange={(e) => setCharterId(e.target.value)}
											onFocus={onCharterIdFieldFocused}
										/>
									</div>
								</div>

								<div className="row" style={{ paddingTop: 10 }}>
									<div style={{ width: "100%", justifyContent: "flex-end" }}>
										<Button
											className="p-2 col-12 text-capitalize apply-filter-f"
											onClick={onApply}
											style={{
												width: 135,
												color: "#0078ff",
												backgroundColor: "transparent",
												border: "none",
												fontSize: 14,
												float: "right",
											}}
										>
											<span
												style={{ fontFamily: "Dromon-light", float: "right" }}
											>
												<strong>Show Results</strong>
											</span>
										</Button>
									</div>
									<div
										className="container"
										style={{ height: 1, backgroundColor: "lightGray" }}
									></div>
								</div>
							</List>
						</Collapse>
					</>
				)}
			</div>

			<div className="d-flex flex-column w-100" style={{ flex: 1 }}>
				<div className="d-flex flex-column" style={{ flex: 1 }}>
					{!displayedTrips?.length && (
						<div
							className="d-flex flex-column justify-content-center align-items-center px-3"
							style={{ flex: 1 }}
						>
							{/** EMPTY STATE */}
							<img
								src={require("../../assets/svg/empty-trip-list-vector.svg")}
								style={{
									height: "20%",
									objectFit: "contain",
									marginBottom: 40.7,
								}}
							/>
							<span
								align="center"
								style={{
									fontSize: 21,
									color: "#222c4b",
								}}
							>
								{languages[currentLanguage]["nexttrip_emptystate_header"]}
							</span>
							<span
								align="center"
								style={{
									fontSize: 14,
									color: "#8f96a7",
									marginTop: 11,
								}}
							>
								{languages[currentLanguage]["nexttrip_emptystate_content"]}
							</span>
						</div>
					)}

					<div style={{ flex: 1 }}>
						{!!displayedTrips?.length &&
							JSON.parse(JSON.stringify(displayedTrips))
								?.reverse()
								?.map((trip, index) => (
									<div key={index} className="px-3 mt-2 w-100">
										<TripCard
											statusTitle={trip.status}
											statusColor={trip.color}
											statusBgColor={trip.backgroundColor}
											State={trip.State}
											isOwner={isOwner}
											StartDate={trip.StartDate}
											durationDays={trip.DurationDays}
											vesselType={trip.vesselType}
											cover={trip.Cover}
											id={trip.Id}
											deleteTrip={() => {
												setCurrentTripId(trip.Id);
												setCurrentTrip(trip);
												setShowDialog(true);
											}}
											displayOverview={() => {
												closeSidebar();
												displayOverview(trip.Id);
											}}
											tags={trip.Attributes}
											owner={trip.Members}
											tripName={trip.Name}
											creator={trip?.Creator}
											state={trip.State}
											trip={trip}
										/>
									</div>
								))}
					</div>
				</div>
			</div>
		</SideBar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripList);

import React from "react";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

// STYLES
import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  progress: {
    color: "#0078ff",
  },
}));

const VesselPopup = ({ feature = {} }) => {
  // STYLES
  const classes = useStyles();

  // STATES
  const { id, angle, name, icon, Speed } = feature.properties;
  const [lon, lat] = feature.geometry.coordinates;
  const [loading, setLoading] = React.useState(false);

  // FUNCTIONS

  // EFFECTS
  React.useEffect(() => {}, []);

  return (
    <div className="p-0 popup">
      {loading ? (
        <div
          className="d-flex h-100 justify-content-center align-items-center"
          style={{ flex: 1 }}
        >
          <CircularProgress size={20} className={classes.progress} />
        </div>
      ) : (
        <div
          className="text-center d-flex flex-column align-items-center justify-content-center h-100 p-4 dromon-l"
          style={{ flex: 1 }}
        >
          <div className="d-flex justify-content-center align-items-center mb-3">
            <img
              src={require(`../../assets/svg/map_vessel/${icon}.svg`)}
              style={{ transform: `rotate(${angle}deg)`, width: 15 }}
            />
            <h3
              className="ml-2 mb-0"
              style={{
                fontSize: 15,
                color: "#222c4b",
                lineHeight: 1,
              }}
            >
              {name}
            </h3>
          </div>
          <div style={{ fontSize: 12, color: "#8f96a7" }}>
            <i className="fas fa-fw fa-tachometer-alt" /> {Speed} kts
          </div>
        </div>
      )}
    </div>
  );
};

export default VesselPopup;

import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Button,
	CircularProgress,
	Snackbar,
	ListItem,
	List,
	Collapse,
	TextField,
	Select,
	MenuItem,
	FormControl,
	Dialog,
	DialogTitle,
	DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import tabIcon from "../../assets/tab.svg";
import backIcon from "../../assets/back_ic.svg";
import dateFormat from "date-fns/format";
import { KeyboardDatePicker } from "@material-ui/pickers";

// API
import { ApiClient } from "../../ApiClient";
import { AuthService } from "../../AuthService";

// SELECTORS
import { userData, userSettings } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { currentLang } from "../../store/features/translation/selectors";
import { version } from "../../../package.json";

// ACTIONS
import {
	showUserProfile,
	closeUserSidebars,
	resetUser,
	setUserData,
} from "../../store/features/user/actions";

// CONSTANTS
import {
	DepthUnits,
	DistanceUnits,
	FuelUnit,
	SpeedUnit,
	TempUnits,
	WindSpeedUnits,
} from "../../helpers/UnitConverter";

// STYLED
import { Sidebar, SidebarContent } from "./styled";
import { useStyles } from "../map/Popup";
import { Check } from "../FIlterModal";
import { Link } from "react-router-dom";
import { logEvent_ } from "../../helpers/analytics";

// LOCAL COMPONENTS
const BtnSelect = ({ active = false, label = null, onClick = () => {} }) => {
	return (
		<Button
			className="text-center"
			style={{
				flex: 1,
				minWidth: "initial",
				marginRight: 6,
				borderRadius: 5,
				fontSize: 12,
				fontFamily: "Dromon-light",
				height: 40,
				textTransform: "initial",
				...(active
					? {
							backgroundColor: "#e5f1ff",
							color: "#0078ff",
					  }
					: {
							backgroundColor: "#f6f6f6",
							color: "#222c4b",
					  }),
			}}
			onClick={onClick}
		>
			{label}
		</Button>
	);
};

const SwitchItem = ({
	title = null,
	description = null,
	checked = false,
	onChange = () => {},
}) => {
	return (
		<div className="d-flex align-items-center mb-3">
			<div className="" style={{ flex: 1 }}>
				<h4 className="m-0 p-0" style={{ fontSize: 14, color: "#222c4b" }}>
					{title}
				</h4>
				<span
					style={{ fontFamily: "Dromon-light", fontSize: 12, color: "#8f96a7" }}
				>
					{description}
				</span>
			</div>
			<Check
				checked={checked}
				onChange={onChange}
				name="check"
				inputProps={{ "aria-label": "primary checkbox" }}
			/>
		</div>
	);
};

const UserSettings = ({
	setUserData,
	show = false,
	setUpdate = () => {},
	showUserProfile,
	closeUserSidebars,
	resetUser,
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentUserData = useSelector(userData);
	const currentUserSettings = useSelector(userSettings);
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [collapseUnits, setCollapseUnits] = React.useState(true),
		[collapsePrivacy, setCollapsePrivacy] = React.useState(true),
		[collapseAccount, setCollapseAccount] = React.useState(true),
		[currentDistanceUnit, setCurrentDistanceUnit] = React.useState(
			currentUserSettings.DistanceUnits
		),
		[currentFuelUnit, setCurrentFuelUnit] = React.useState(
			currentUserSettings.FuelUnit
		),
		[currentSpeedUnit, setCurrentSpeedUnit] = React.useState(
			currentUserSettings.SpeedUnit
		),
		[currentTempUnit, setCurrentTempUnit] = React.useState(
			currentUserSettings.TempUnits
		),
		[currentWindSpeedUnit, setCurrentWindSpeedUnit] = React.useState(
			currentUserSettings.WindSpeedUnits
		),
		[currentDepthUnit, setCurrentDepthUnit] = React.useState(
			currentUserSettings.DepthUnits
		),
		[switchPolicyPrivacy, setSwitchPolicyPrivacy] = React.useState(
			currentUserSettings.VesselsPublic
		),
		[switchPublicVisibility, setSwitchPublicVisibility] = React.useState(
			currentUserSettings.ProfilePublic
		),
		[emailInp, setEmailInp] = React.useState(currentUserData.Email),
		[dateOfBirthInp, setDateOfBirthInp] = React.useState(null),
		[countryInp, setCountryInp] = React.useState(null),
		[countryInpText, setCountryInpText] = React.useState(""),
		[notice, setNotice] = React.useState({ type: "", content: "" }),
		[logoutDialog, setLogoutDialog] = React.useState(false),
		[loading, setLoading] = React.useState(false);

	// DATA
	const iso3311a2 = require("iso-3166-1-alpha-2");

	// FUNCTIONS
	const logout = () => {
		var new_url = "https://" + window.location.host + "/explore";
		window.history.pushState(null, null, new_url);
		localStorage.removeItem("session");
		localStorage.removeItem("myBookmarks");
		setUpdate();
		resetUser();
		setLogoutDialog(false);
	};

	const onUpdatedSettings = async () => {
		const promises = [];

		setLoading(true);
		promises.push(
			ApiClient.updateSettings(
				switchPublicVisibility,
				switchPolicyPrivacy,
				currentDistanceUnit,
				currentDepthUnit,
				currentFuelUnit,
				currentSpeedUnit,
				currentTempUnit,
				currentWindSpeedUnit
			)
		);
		promises.push(
			ApiClient.updateBasicUserProfile(
				currentUserData.FirstName,
				currentUserData.LastName,
				currentUserData.UserName,
				dateOfBirthInp,
				countryInp,
				currentUserData.Url,
				currentUserData.Description
			)
		);

		Promise.all(promises)
			.then((res) => {
				setNotice({
					type: "success",
					content: languages[currentLanguage]["profile_message_settings_saved"],
				});
				ApiClient.getUser().then((userData) => setUserData(userData));
			})
			.catch((err) => {
				setNotice({
					type: "error",
					content:
						languages[currentLanguage]["common_error_something_went_wrong"],
				});
			})
			.finally(() => {
				setLoading(false);
				setUpdate();
				closeUserSidebars();
				showUserProfile();
			});
	};

	// EFFECTS
	React.useEffect(() => {
		setCurrentDistanceUnit(currentUserSettings.DistanceUnits);
		setCurrentFuelUnit(currentUserSettings.FuelUnit);
		setCurrentSpeedUnit(currentUserSettings.SpeedUnit);
		setCurrentTempUnit(currentUserSettings.TempUnits);
		setCurrentWindSpeedUnit(currentUserSettings.WindSpeedUnits);
		setCurrentDepthUnit(currentUserSettings.DepthUnits);
		setSwitchPublicVisibility(currentUserSettings.ProfilePublic);
		setEmailInp(currentUserData.Email);
		setDateOfBirthInp(currentUserData.DateOfBirth);
		setCountryInp(currentUserData.Nationality);
	}, [currentUserSettings]);

	return (
		<>
			<Sidebar show={show} id="settings-box">
				<SidebarContent className="pb-5">
					<Button
						onClick={onUpdatedSettings}
						className="justify-content-start py-3 mb-2 w-100"
						style={{ textTransform: "initial", fontFamily: "inherit" }}
					>
						<img
							alt="icon"
							src={backIcon}
							style={{ height: 16, width: 8, marginRight: 17 }}
						/>
						<h2 className="m-0 p-0" style={{ fontSize: 21, color: "#222c4b" }}>
							{languages[currentLanguage]["user_settings"]}
						</h2>
					</Button>
					{loading ? (
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<List component="nav" aria-labelledby="nested-list" className="">
							{/* UNITS */}
							<ListItem
								button
								style={{
									backgroundColor: "#fbfbfb",
									color: "#9096a6",
									fontSize: 16,
									fontFamily: "inherit",
									borderBottom: "solid 1px rgba(232, 232, 232, 0.5)",
								}}
								onClick={() => setCollapseUnits(!collapseUnits)}
							>
								<span
									primary="Units"
									style={{ fontSize: 16, flex: 1 }}
									className="px-2 mx-0"
								>
									{" "}
									{
										languages[currentLanguage]["profile_settings_units_title"]
									}{" "}
								</span>
								{collapseUnits ? (
									<i className="fas fa-fw fa-chevron-up" style={{}} />
								) : (
									<i className="fas fa-fw fa-chevron-down" style={{}} />
								)}
							</ListItem>

							<Collapse in={collapseUnits} timeout="auto" unmountOnExit>
								<List component="div" className="px-4 pt-3">
									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_distance"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(DistanceUnits).map((item, index) => (
												<BtnSelect
													key={index.toString()}
													active={currentDistanceUnit === item[1]}
													label={item[0]}
													onClick={() => setCurrentDistanceUnit(item[1])}
												/>
											))}
										</div>
									</div>

									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_depth"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(DepthUnits).map((item, index) => (
												<BtnSelect
													key={index.toString()}
													active={currentDepthUnit === item[1]}
													label={item[0]}
													onClick={() => setCurrentDepthUnit(item[1])}
												/>
											))}
										</div>
									</div>

									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_fuel"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(FuelUnit).map((item, index) => {
												return (
													<BtnSelect
														key={index.toString()}
														active={currentFuelUnit === item[1]}
														label={item[0]}
														onClick={() => setCurrentFuelUnit(item[1])}
													/>
												);
											})}
										</div>
									</div>

									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_speed"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(SpeedUnit).map((item, index) => (
												<BtnSelect
													key={index.toString()}
													active={currentSpeedUnit === item[1]}
													label={item[0]}
													onClick={() => setCurrentSpeedUnit(item[1])}
												/>
											))}
										</div>
									</div>

									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_temp"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(TempUnits).map((item, index) => (
												<BtnSelect
													key={index.toString()}
													active={currentTempUnit === item[1]}
													label={item[0]}
													onClick={() => setCurrentTempUnit(item[1])}
												/>
											))}
										</div>
									</div>

									<div className="mb-3">
										<h3 style={{ fontSize: 14, color: "#222c4b" }}>
											{
												languages[currentLanguage][
													"profile_settings_units_wind"
												]
											}
										</h3>
										<div className="d-flex align-items-center">
											{Object.entries(WindSpeedUnits).map((item, index) => (
												<BtnSelect
													key={index.toString()}
													active={currentWindSpeedUnit === item[1]}
													label={item[0]}
													onClick={() => setCurrentWindSpeedUnit(item[1])}
												/>
											))}
										</div>
									</div>
								</List>
							</Collapse>

							{/* PRIVACY */}
							<ListItem
								button
								style={{
									backgroundColor: "#fbfbfb",
									color: "#9096a6",
									fontSize: 16,
									fontFamily: "inherit",
									borderBottom: "solid 1px rgba(232, 232, 232, 0.5)",
								}}
								onClick={() => setCollapsePrivacy(!collapsePrivacy)}
							>
								<span style={{ flex: 1 }} className="px-2 mx-0">
									{" "}
									{languages[currentLanguage]["profile_settings_privacy"]}{" "}
								</span>
								{collapsePrivacy ? (
									<i className="fas fa-fw fa-chevron-up" style={{}} />
								) : (
									<i className="fas fa-fw fa-chevron-down" style={{}} />
								)}
							</ListItem>

							<Collapse in={collapsePrivacy} timeout="auto" unmountOnExit>
								<List component="div" className="px-4 pt-3">
									<SwitchItem
										title={
											languages[currentLanguage][
												"profile_settings_privacy_public_vessels"
											]
										}
										description={
											languages[currentLanguage][
												"profile_settings_privacy_public_profile_subheader"
											]
										}
										checked={switchPolicyPrivacy}
										onChange={(e) => setSwitchPolicyPrivacy(e.target.checked)}
									/>
									{/*<SwitchItem title="Public visibility of Interests" description="Show needs and interests in your public profile?" checked={false} />*/}
									{!AuthService.isUserPartner() && (
										<SwitchItem
											title={
												languages[currentLanguage][
													"profile_settings_privacy_public_profile"
												]
											}
											description={
												languages[currentLanguage][
													"profile_settings_privacy_public_transportation_subheader"
												]
											}
											checked={switchPublicVisibility}
											onChange={(e) =>
												setSwitchPublicVisibility(e.target.checked)
											}
										/>
									)}
								</List>
							</Collapse>

							{/* Account */}
							<ListItem
								button
								style={{
									backgroundColor: "#fbfbfb",
									color: "#9096a6",
									fontSize: 16,
									fontFamily: "inherit",
									borderBottom: "solid 1px rgba(232, 232, 232, 0.5)",
								}}
								onClick={() => setCollapseAccount(!collapseAccount)}
							>
								<span style={{ flex: 1 }} className="px-2 mx-0">
									{" "}
									{languages[currentLanguage]["profile_settings_account"]}{" "}
								</span>
								{collapseAccount ? (
									<i className="fas fa-fw fa-chevron-up" style={{}} />
								) : (
									<i className="fas fa-fw fa-chevron-down" style={{}} />
								)}
							</ListItem>

							<Collapse in={collapseAccount} timeout="auto" unmountOnExit>
								<List component="form" className="px-4 pt-3">
									<div className="mb-2">
										<span style={{ color: "#222c4b", fontSize: 14 }}>
											{languages[currentLanguage]["auth_common_email"]}
										</span>
										<TextField
											value={emailInp}
											className="mb-4"
											style={{
												fontFamily: "Dromon-light !important",
												width: "100%",
												color: "#8f96a7",
											}}
											variant="outlined"
										/>

										<span style={{ color: "#222c4b", fontSize: 14 }}>
											{languages[currentLanguage]["profile_settings_birth"]}
										</span>
										<KeyboardDatePicker
											clearable="true"
											value={dateOfBirthInp}
											placeholder="10/10/2018"
											onChange={(selectedDate) => {
												try {
													setDateOfBirthInp(
														dateFormat(new Date(selectedDate), "yyyy-MM-dd")
													);
												} catch (e) {
													setDateOfBirthInp(null);
												}
											}}
											// minDate={new Date()}
											format="MM/dd/yyyy"
											className="mt-0 mb-4"
											style={{
												fontFamily: "Dromon-light",
												width: "100%",
											}}
											inputVariant="outlined"
											variant="inline"
										/>
										{/* <DatePicker
											disableToolbar
											variant="inline"
											inputVariant="outlined"
											format="MM/dd/yyyy"
											margin="normal"
											value={dateOfBirthInp}
											onChange={(selectedDate) =>
												setDateOfBirthInp(
													dateFormat(selectedDate, "yyyy-MM-dd")
												)
											}
											className="mt-0 mb-4"
											style={{
												fontFamily: "Dromon-light",
												width: "100%",
											}}
										/> */}

										<span style={{ color: "#222c4b", fontSize: 14 }}>
											{languages[currentLanguage]["profile_settings_country"]}
										</span>
										<FormControl
											style={{ fontFamily: "Dromon-light", width: "100%" }}
											variant="outlined"
											className="mb-4"
											id="country-selector"
										>
											<Select
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												value={countryInpText}
												onChange={(event) => {
													setCountryInpText(
														iso3311a2.countries[event.target.value]
													);
													setCountryInp(event.target.value);
												}}
											>
												<MenuItem
													value={
														currentUserData.Nationality ? countryInp : null
													}
												>
													{" "}
													<em>
														{currentUserData.Nationality
															? currentUserData.Nationality
															: ""}
													</em>{" "}
												</MenuItem>
												{iso3311a2.getCodes().map((item, index) => (
													<MenuItem key={index} value={item}>
														{item}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>

									<ListItem
										button
										onClick={() =>
											window.open(
												"https://keeano.com/legal/terms-conditions.html",
												"_blank"
											)
										}
										className="px-0"
										style={{
											color: "#222c4b",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<span style={{ flex: 1 }}>
											{" "}
											{
												languages[currentLanguage][
													"profile_settings_account_terms"
												]
											}{" "}
										</span>
										<img
											src={tabIcon}
											alt="link"
											style={{ height: 14, width: 14 }}
										/>
									</ListItem>

									<ListItem
										button
										onClick={() =>
											window.open(
												"https://keeano.com/legal/privacy-policy.html",
												"_blank"
											)
										}
										className="px-0"
										style={{
											color: "#222c4b",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<span style={{ flex: 1 }}>
											{" "}
											{
												languages[currentLanguage][
													"profile_settings_account_privacy"
												]
											}
										</span>
										<img
											src={tabIcon}
											alt="link"
											style={{ height: 14, width: 14 }}
										/>
									</ListItem>

									<ListItem
										button
										className="px-0"
										style={{
											color: "#222c4b",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<Link
											style={{
												flex: 1,
												color: "#222c4b",
												textDecoration: "none",
											}}
											to="/legal/cookies-policy"
										>
											{
												languages[currentLanguage][
													"home_footer_quicklinks_legal_cookies"
												]
											}
										</Link>
										<img
											src={tabIcon}
											alt="link"
											style={{ height: 14, width: 14 }}
										/>
									</ListItem>

									<ListItem
										button
										onClick={() => {
											// EVENT
											logEvent_("react_user_set_exportdata_btn_pressed");
											window.open("http://eepurl.com/dvLEeD", "_blank");
										}}
										className="px-0"
										style={{
											color: "#222c4b",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<span style={{ flex: 1 }}>
											{" "}
											{
												languages[currentLanguage][
													"profile_settings_export_data"
												]
											}{" "}
										</span>
									</ListItem>

									<ListItem
										button
										onClick={() => {
											// EVENT
											logEvent_("react_user_set_deleteaccount_btn_pressed");
											window.open("http://eepurl.com/dvLTID", "_blank");
										}}
										className="px-0"
										style={{
											color: "#222c4b",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<span style={{ flex: 1 }}>
											{" "}
											{
												languages[currentLanguage][
													"profile_settings_account_delete_account"
												]
											}{" "}
										</span>
									</ListItem>

									<ListItem
										button
										onClick={() => setLogoutDialog(true)}
										className="px-0"
										style={{
											color: "#eb5732",
											fontSize: 14,
											fontFamily: "inherit",
										}}
									>
										<span style={{ flex: 1 }}>
											{
												languages[currentLanguage][
													"profile_settings_account_logout"
												]
											}
										</span>
									</ListItem>
								</List>
							</Collapse>

							<div
								className="mt-4 px-4"
								style={{
									fontWeight: "300",
									fontSize: 14,
									opacity: "0.5",
									color: "#222c4b",
								}}
							>
								<p className="m-0 p-0">Version</p>
								<span style={{ fontSize: 12 }}>{version}</span>
							</div>

							<ListItem
								className="mt-4 px-4"
								style={{
									fontWeight: "300",
									color: "#222c4b",
									fontSize: 14,
									fontFamily: "inherit",
								}}
							>
								<span style={{ flex: 1 }}>
									@ Keeano 2021, All right reserved
								</span>
							</ListItem>
						</List>
					)}
				</SidebarContent>
			</Sidebar>

			<Dialog open={logoutDialog} onClose={() => setLogoutDialog(false)}>
				<DialogTitle style={{ minWidth: 285 }}>
					{languages[currentLanguage]["profile_settings_account_logout"]}?
				</DialogTitle>

				<DialogActions>
					<Button onClick={() => setLogoutDialog(false)}>
						{languages[currentLanguage]["common_cancel"]}
					</Button>

					<Button
						onClick={logout}
						style={{ color: "#eb5732" }}
						startIcon={() => <i className="fas fa-sign-out fa-fw" />}
					>
						{languages[currentLanguage]["profile_settings_account_logout"]}
					</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={!!(notice.type.length && notice.content.length)}
				autoHideDuration={4000}
				onClose={() => setNotice({ type: "", content: "" })}
			>
				<Alert
					elevation={6}
					variant="filled"
					severity={notice.type.toLowerCase()}
					onClose={() => setNotice({ type: "", content: "" })}
				>
					{notice.content}
				</Alert>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserData: (data) => dispatch(setUserData(data)),
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
		resetUser: () => dispatch(resetUser()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);

import { AuthService } from "./AuthService";

// DATA
export const ShareLinkType = {
	place: "place",
	coast_view_photo: "coast_view_photo",
	trip_share: "trip_share",
	trip_invite: "trip_invite",
	user: "user",
};

// FUNCTIONS
const generateTripGUIDLink = (
	type,
	id,
	guid,
	image,
	tripName,
	tripDescription,
	role,
	webOnly = true
) => {
	if (guid === null) {
		return null;
	}
	const shareType = type;
	const canonicalIdentifier = shareType + "/" + guid;
	const productName = tripName;
	const coverImage = image;
	const desktopUrl = "https://" + window.location.host + "/explore";
	var linkData = {
		campaign: shareType,
		channel: "web",
		stage: AuthService.isUserLogedIn()
			? "registered_user"
			: "unregistered_user",
		tags: [],
		data: {
			id: id,
			$og_title: productName,
			$og_description: tripDescription,
			$og_image_url: coverImage,
			$canonical_identifier: canonicalIdentifier,
			$web_only: webOnly,
			$desktop_url: desktopUrl,
			$ios_url: desktopUrl,
			$android_url: desktopUrl,
			$product_name: productName,
			$product_variant: shareType,
			$publicly_indexable: 0,
			$locally_indexable: 0,
			platform: "web",
			role: role,
		},
	};
	if (!webOnly) {
		delete linkData.data.$ios_url;
		delete linkData.data.$android_url;
	}
	return linkData;
};

export const TripCommunityMemberType = {
	creator: "creator",
	skipper: "skipper",
	passenger: "passenger",
};

export const generatePlaceShareLink = (place) => {
	if (place === null) {
		return null;
	}
	const shareType = ShareLinkType.place;
	const productName =
		place.Name?.Latin === null ? place.Type : place.Name?.Latin;
	const coverImage = place.CoverImage?.length
		? place.CoverImage
		: `https://keeanostage.blob.core.windows.net/0000000000000000000/placeholders/placeholder_${place.Type}.png)`;
	const description =
		place.BreadCrumb?.Latin === null ? "" : place.BreadCrumb?.Latin.join(", ");
	const desktopUrl = "https://" + window.location.host + "/explore";
	const tags = [shareType + "." + place.Type, shareType + "." + place.ID];
	const canonicalIdentifier = shareType + "s/" + place.GUID;

	const linkData = {
		campaign: shareType,
		channel: "web",
		stage: AuthService.isUserLogedIn()
			? "registered_user"
			: "unregistered_user",
		tags: tags,
		data: {
			$og_title: productName,
			$og_description: description,
			$og_image_url: coverImage,
			$canonical_identifier: canonicalIdentifier,
			$desktop_url: desktopUrl,
			$fallback_url: desktopUrl,
			$product_name: productName,
			$product_variant: shareType,
			$publicly_indexable: 0,
			$locally_indexable: 0,
			platform: "web",
		},
	};
	return linkData;
};

export const generateCoastViewShareLink = (
	pointId,
	lineId,
	breadCrumb,
	imageUrl
) => {
	if (pointId === null || lineId === null || breadCrumb === null) {
		return null;
	}
	const shareType = ShareLinkType.coast_view_photo;
	const canonicalIdentifier = shareType + "s/" + lineId + "/" + pointId;
	const productName = "Coast View";
	const coverImage = imageUrl;
	const description = breadCrumb;
	const desktopUrl = "https://" + window.location.host + "/explore";
	const tags = ["VEpID." + pointId, "VElID." + lineId];
	const linkData = {
		campaign: shareType,
		channel: "web",
		stage: AuthService.isUserLogedIn()
			? "registered_user"
			: "unregistered_user",
		tags: tags,
		data: {
			$og_title: productName,
			$og_description: description,
			$og_image_url: coverImage,
			$canonical_identifier: canonicalIdentifier,
			$fallback_url: desktopUrl,
			$desktop_url: desktopUrl,
			$product_name: productName,
			$product_variant: shareType,
			$publicly_indexable: 0,
			$locally_indexable: 0,
			platform: "web",
		},
	};
	return linkData;
};

export const generateTripShareLink = (
	id,
	guid,
	image,
	tripName,
	tripDescription
) => {
	return generateTripGUIDLink(
		ShareLinkType.trip_share,
		id,
		guid,
		image,
		tripName,
		tripDescription
	);
};

export const generateTripInviteLink = (
	id,
	guid,
	image,
	tripName,
	tripDescription,
	role = "skipper",
	webOnly = true
) => {
	return generateTripGUIDLink(
		ShareLinkType.trip_invite,
		id,
		guid,
		image,
		tripName,
		tripDescription,
		role,
		webOnly
	);
};

export const generateUserProfileShareLink = (userData) => {
	if (!userData) {
		return null;
	}

	const SHARE_TYPE = ShareLinkType.user;
	const PRODUCT_NAME = userData?.FirstName + " " + userData?.LastName;
	const COVER_IMAGE = userData.Photo?.length
		? userData.Photo
		: `https://keeanostage.blob.core.windows.net/0000000000000000000/placeholders/placeholder_beach.png)`;
	const DESCRIPTION = userData.Email;
	const DESKTOP_URL = "https://" + window.location.host + "/explore";
	const TAGS = [
		SHARE_TYPE + "." + userData.Type,
		SHARE_TYPE + "." + userData.ID,
	];
	const CANONICAL_IDENTIFIER = SHARE_TYPE + "s/" + userData.ID;

	return {
		campaign: SHARE_TYPE,
		channel: "web",
		stage: AuthService.isUserLogedIn()
			? "registered_user"
			: "unregistered_user",
		tags: TAGS,
		data: {
			$og_title: PRODUCT_NAME,
			$og_description: DESCRIPTION,
			$og_image_url: COVER_IMAGE,
			$canonical_identifier: CANONICAL_IDENTIFIER,
			$desktop_url: DESKTOP_URL,
			$fallback_url: DESKTOP_URL,
			$product_name: PRODUCT_NAME,
			$product_variant: SHARE_TYPE,
			$publicly_indexable: 0,
			$locally_indexable: 0,
			platform: "web",
		},
	};
};

import React, { useEffect, useState } from "react";
import ReviewsHeader from "./ReviewsHeader";
import PropTypes from "prop-types";
import { secondaryTxt } from "../../../constants/colors";

const ReviewCard = (props) => {
	const { testimony, name, rate, days, coverImage, cover } = props;
	const [numberOfStars, setNumberOfStars] = useState([]);
	const [uncheckedStars, setUncheckedStars] = useState(new Array(5));
	useEffect(() => {
		setNumberOfStars([...Array(Math.floor(rate)).keys()]);
		setUncheckedStars([...Array(5 - Math.floor(rate)).keys()]);
	}, []);
	return (
		<div style={{ marginBottom: 35 }}>
			<ReviewsHeader
				uncheckedStars={uncheckedStars}
				numberOfStars={numberOfStars}
				name={name}
				days={days}
				cover={cover}
			/>
			<h3
				className="dromon-l px-3"
				style={{ fontSize: 14, color: secondaryTxt }}
			>
				{testimony}
			</h3>
			<div className="px-3 mt-4">
				{coverImage && (
					<img src={coverImage} style={{ height: 190, width: 380 }} alt="" />
				)}
			</div>
		</div>
	);
};

ReviewCard.propTypes = {
	testimony: PropTypes.string,
	rate: PropTypes.number,
	name: PropTypes.string,
	days: PropTypes.number,
	coverImage: PropTypes.any,
};

export default ReviewCard;

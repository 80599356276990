import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	position: absolute;
	z-index: 0;
	width: 100vw;
	height: 65px;
	bottom: -65px;
	margin: 0;
	padding: 0;
	background-color: #ffffff;
	opacity: 0.5;
	border-radius: 16px;
	transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.visible &&
		css`
			bottom: 0;
			box-shadow: 0 -6px 18px 6px rgba(207, 207, 207, 0.165177);
			opacity: 1;
		`}
`;

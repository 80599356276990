/**
 * App trip user role constant.
 *
 * Don't forget to update if needed
 */
export default {
	CREATOR: "creator",
	SKIPPER: "skipper",
	COCREATOR: "cocreator",
	PASSENGER: "passenger",
	SUPERPASSENGER: "superpassenger",
	PASSENGERSKIPPER: "passengerskipper",
	BASEMANAGER: "basemanager",
	HOSTESS: "hostess",
	CHEF: "chef",
	PASSENGER_SKIPPER: "passengerSkipper",
	SKIPPER: "skipper",
	DECKMAN: "deckman",
	HOSTESS: "hostess",
	NO_MEMBER: "no-member",
	VIRTUAL_GUIDE: "virtualguide",
};

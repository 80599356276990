import React, { Component } from "react";
import { Container } from "reactstrap";

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <Container fluid>
          <div>{this.props.children}</div>
        </Container>
      </div>
    );
  }
}

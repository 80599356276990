import React from "react";
import Radio from "@material-ui/core/Radio";
import { Input, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// CONSTANTS
import {
	filterHighlightColor,
	borderColor,
	colorInactiveItem,
} from "../../../constants/colors";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// COMPONENTS
import IncreaseDecrease from "../../utils/IncreaseDecrease";

// SVG ICONS
import deleteIcon from "../../../assets/svg/delete-ic-n.svg";

export default function CustomIncreaseDecrease({
	item = {},
	props,
	increaseDecreaseProps = {},
	displayRadio = false,
	radioProps = {},
	currentCategory,
	setState = () => {},
	onEdit = () => {},
	isCompleted = false,
}) {
	// STYLES
	const KeeanoRadio = withStyles({
		root: {
			color: filterHighlightColor + "aa",
			"&$checked": {
				color: filterHighlightColor,
			},
		},
		checked: {},
	})((props) => <Radio color="default" {...props} />);

	// REFS
	const noteRef = React.useRef(null);

	return (
		<div
			{...{
				...props,
				className: `d-flex align-items-stretch mb-4 ${
					props?.className ? props.className : ""
				}`,
			}}
		>
			<IncreaseDecrease
				increaseProps={{ disabled: !item.Selected }}
				decreaseProps={{ disabled: !item.Selected }}
				props={{
					...increaseDecreaseProps,
					style: {
						width: 85,
						height: 55,
						opacity: item.Selected ? 1 : 0.4,
						...(increaseDecreaseProps?.style
							? increaseDecreaseProps.style
							: ""),
					},
					className: `mr-2  ${
						increaseDecreaseProps?.className
							? increaseDecreaseProps.className
							: ""
					}`,
				}}
				textInputProps={{
					disabled: !item.Selected,
					value: item.Quantity,
					className: "text-center",
					style: { height: "100%" },
					onChange: (e) => {
						onEdit();
						setState((state) => {
							if (currentCategory && state[currentCategory].length)
								return {
									...state,
									[currentCategory]: state[currentCategory]?.map((item_) => {
										return item_.Id === item.Id &&
											item_.Quantity > 0 &&
											item_.Quantity < 100
											? { ...item_, Quantity: Number(e.target.value) }
											: item_;
									}),
								};
							else if (state.length)
								return [
									...state?.map((item_) => {
										return item_.ProductID === item.ProductID &&
											item_.Quantity > 0 &&
											item_.Quantity < 100
											? { ...item_, Quantity: Number(e.target.value) }
											: item_;
									}),
								];
						});
					},
					inputProps: {
						min: 0,
						max: 100,
						style: { height: "100%" },
						onKeyDown: (event) => event.preventDefault(),
					},
				}}
				onIncrease={() => {
					onEdit();
					setState((state) => {
						if (currentCategory && state[currentCategory].length)
							return {
								...state,
								[currentCategory]: state[currentCategory]?.map((item_) => {
									return item_.Id === item.Id && item_.Quantity < 100
										? { ...item_, Quantity: item_.Quantity + 1 }
										: item_;
								}),
							};
						else if (state.length)
							return [
								...state?.map((item_) => {
									return item_.ProductID === item.ProductID &&
										item_.Quantity < 100
										? { ...item_, Quantity: item_.Quantity + 1 }
										: item_;
								}),
							];
					});
				}}
				onDecrease={() => {
					onEdit();
					setState((state) => {
						if (currentCategory && state[currentCategory].length)
							return {
								...state,
								[currentCategory]: state[currentCategory]?.map((item_) => {
									return item_.Id === item.Id && item_.Quantity > 1
										? { ...item_, Quantity: item_.Quantity - 1 }
										: item_;
								}),
							};
						else if (state.length)
							return [
								...state?.map((item_) => {
									return item_.ProductID === item.ProductID &&
										item_.Quantity > 1
										? { ...item_, Quantity: item_.Quantity - 1 }
										: item_;
								}),
							];
					});
				}}
				isCompleted={isCompleted}
			/>
			<div
				className="rounded p-2 d-flex align-items-stretch"
				style={{
					flex: 1,
					border: `1px solid ${borderColor}`,
				}}
			>
				<div style={{ flex: 1 }}>
					<h6 className="mb-0" style={{ fontSize: 16 }}>
						{item?.Name}
					</h6>

					<div
						className="d-flex justify-content-between"
						style={{ fontSize: 14, color: colorInactiveItem }}
					>
						{!isEmpty(item?.Category) && (
							<span className="text-capitalize">{item.Category}</span>
						)}
						{!isEmpty(item?.Packaging) && (
							<span className="text-capitalize">{item.Packaging}</span>
						)}
					</div>

					{!currentCategory && (
						<Input
							placeholder="Note"
							inputProps={{
								"aria-label": "Preference Note",
								ref: noteRef,
							}}
							onChange={(e) => {
								onEdit();
								return setState((state) => {
									return state?.map((item_) => {
										return item_.ProductID === item.ProductID
											? {
													...item_,
													PreferenceNote: noteRef.current.value,
											  }
											: item_;
									});
								});
							}}
							multiline={true}
							rows={2}
							value={item.PreferenceNote}
							style={{ borderColor: filterHighlightColor }}
							className="w-100"
							disabled={isCompleted}
						/>
					)}
				</div>

				{!currentCategory && !isCompleted && (
					<div className="d-flex align-items-end ml-2">
						<IconButton
							onClick={(e) => {
								onEdit();
								setState((state) => {
									return state?.filter(
										(item_) => item_.ProductID !== item.ProductID
									);
								});
							}}
							style={{ height: 30, width: 30 }}
						>
							<img
								alt="delete icon"
								src={deleteIcon}
								style={{
									height: 18,
									width: 18,
									marginLeft: 1,
									userSelect: "none",
									pointerEvents: "none",
								}}
							/>
						</IconButton>
					</div>
				)}
			</div>
			{displayRadio && (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: 57 }}
				>
					<KeeanoRadio
						checked={item.Selected}
						onClick={() => {
							onEdit();
							setState((state) => {
								if (currentCategory && state[currentCategory].length)
									return {
										...state,
										[currentCategory]: state[currentCategory]?.map((item_) => {
											return item_.Id === item.Id
												? {
														...item_,
														Quantity: !item_.Selected
															? item_.Quantity <= 0
																? 1
																: item_.Quantity
															: 0,
														Selected: !item_.Selected,
												  }
												: item_;
										}),
									};
								else if (state.length)
									return [
										...state?.map((item_) => {
											return item_.ProductID === item.ProductID
												? {
														...item_,
														Quantity: !item_.Selected
															? item_.Quantity <= 0
																? 1
																: item_.Quantity
															: 0,
														Selected: !item_.Selected,
												  }
												: item_;
										}),
									];
							});
						}}
						{...{ ...radioProps }}
					/>
				</div>
			)}
		</div>
	);
}

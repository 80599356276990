import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { IconButton, ButtonBase } from "@material-ui/core";

// CONSTANTS
import {
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../../constants/colors";

// COMPONENTS
import { iconDictionary } from "../../TripWizard/Steps/First";
import { AuthService } from "../../../AuthService";
// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { userData } from "../../../store/features/user/selectors";

// SVG ICONS
import deleteIcon from "../../../assets/svg/delete-ic-n.svg";
import cover_ from "../../../assets/svg/hero-ve-bg.svg";

const TripCard = (props) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang),
		currentUserData = useSelector(userData);

	// STATES
	const [status, setStatus] = useState({
			status: "",
			color: "",
			backgroundColor: "",
		}),
		[owner, setOwner] = useState(null);

	// EFFECTS
	useEffect(() => {
		setOwner(props.creator);
	}, []);

	return (
		<div className="position-relative">
			<ButtonBase
				onClick={() => props.displayOverview()}
				className="d-flex justify-content-start w-100"
				style={{
					overflow: "hidden",
					minHeight: 115,
					border: "solid 1px #e3e5e9",
					borderRadius: 5,
					textAlign: "initial",
					alignItems: "initial",
				}}
			>
				<div
					className="position-relative d-flex justify-content-center align-items-center mx-0 my-0 p-0"
					style={{
						width: 115,
						borderRadius: 5,
						backgroundColor: "rgba(0, 0, 0, 0.21)",
						backgroundImage: props.cover
							? `url(${props.cover})`
							: `url(${cover_})`,
						backgroundPosition: "center",
						backgroundSize: props.cover ? "cover" : "140% auto",
						backgroundRepeat: "no-repeat",
						textTransform: "initial",
					}}
				>
					<img
						src={iconDictionary[`${props.vesselType}_`]}
						style={{ height: 30, zIndex: 1 }}
						alt=""
					/>

					<span
						className="p-1 dromon-l text-center"
						style={{
							backgroundColor: props.statusBgColor,
							borderRadius: "0 5px 0 5px",
							position: "absolute",
							bottom: 0,
							left: 0,
							color: props.statusColor,
							minWidth: 57,
							fontSize: 12,
						}}
					>
						{props.statusTitle}
					</span>
				</div>

				<div style={{ flex: 1, position: "relative" }} className="p-3">
					<h2
						style={{
							color: formMainTxt,
							fontSize: 14,
						}}
					>
						{props.tripName}
					</h2>

					{props.trip?.Type !== "business" && (
						<h3
							className="dromon-l"
							style={{
								color: secondaryTxt,
								fontSize: 12,
							}}
						>
							{(props.creator?.FirstName || owner?.FirstName) && (
								<>
									by{" "}
									{props.creator
										? `${
												props.creator?.FirstName ? props.creator?.FirstName : ""
										  } ${
												props.creator?.LastName ? props.creator?.LastName : ""
										  }`
										: `${owner?.FirstName ? owner?.FirstName : ""} ${
												owner?.LastName ? owner?.LastName : ""
										  }`}
								</>
							)}
						</h3>
					)}

					<div className="">
						{props.tags?.slice(0, 2).map((tag) => (
							<span
								style={{
									backgroundColor: "#f6f6f6",
									fontSize: 12,
									color: formMainTxt,
									borderRadius: 5,
								}}
								className="dromon-l mr-2 p-1"
								key={tag}
							>
								{languages[currentLanguage][tag.replaceAll(":", "_")]
									? languages[currentLanguage][tag.replaceAll(":", "_")]
									: tag}
							</span>
						))}
					</div>

					{props.trip?.Type === "business" && (
						<div className="">
							{props.trip?.Place?.Name !== null && (
								<span
									style={{
										backgroundColor: "#f6f6f6",
										fontSize: 12,
										color: formMainTxt,
										borderRadius: 5,
									}}
									className="dromon-l mr-2 p-1"
									key="basestation"
								>
									{props.trip?.Place?.Name}
								</span>
							)}

							{props.trip?.CharterId !== null && AuthService.isUserPartner() && (
								<span
									style={{
										backgroundColor: "#f6f6f6",
										fontSize: 12,
										color: formMainTxt,
										borderRadius: 5,
									}}
									className="dromon-l mr-2 p-1"
									key="charterid"
								>
									{props.trip?.CharterId}
								</span>
							)}

							{props?.trip?.FormattedDeparture && (
								<span
									style={{
										backgroundColor: "#f6f6f6",
										fontSize: 12,
										color: formMainTxt,
										borderRadius: 5,
									}}
									className="dromon-l mr-2 p-1"
									key="departure"
								>
									{props.trip?.FormattedDeparture +
										" " +
										props.trip?.FormattedArrivalTime}
								</span>
							)}
						</div>
					)}
				</div>

				<div className="px-1" style={{ minWidth: 40 }} />
			</ButtonBase>

			{props.trip.canDelete && (
				<IconButton
					onClick={(e) => {
						e.stopPropagation();
						props.deleteTrip();
					}}
					className="mr-1 position-absolute"
					style={{ bottom: 16, right: 0, height: 40, width: 40 }}
				>
					<img
						alt="delete icon"
						src={deleteIcon}
						style={{
							height: 20,
							width: 20,
							marginLeft: 1,
							userSelect: "none",
							pointerEvents: "none",
						}}
					/>
				</IconButton>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripCard);

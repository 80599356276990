import React from "react";
import { CircularProgress } from "@material-ui/core";
import { currentLang } from "../../store/features/translation/selectors";
import { connect, useSelector } from "react-redux";
import { languages } from "../../store/features/translation/Languages/";

// STYLED
import { ClosestCoastViewStyled, PhotoItem } from "./styled";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import defaultImg from "../../assets/img/default_coastview.jpeg";

function ClosestCoastView({
	show = false,
	requestData = {},
	id = null,
	dispatch = () => {},
	openCoastView = () => {},
}) {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	// STATES
	const [state, setState] = React.useState({
			breadCrumb: "",
			photo: null,
			disablePrev: false,
			disableNext: false,
		}),
		[loading, setLoading] = React.useState(false);

	// REFS
	const localState = React.useRef({ line: null, index: null });

	// FUNCTIONS
	const onclick = () => {
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.COAST_VIEW_IMAGE_SHOWN
		);
		openCoastView(
			requestData?.Id ? requestData?.Id : 4993,
			requestData?.id ? requestData?.id : "1078971332374300333"
		);
	};

	// EFFECTS
	React.useEffect(() => {
		if (!requestData.Id) {
			setState((state) => {
				return {
					...state,
					breadCrumb: 4993,
					photo: defaultImg,
				};
			});
		} else {
			async function init() {
				setLoading(true);
				try {
					localState.current.line = requestData;
					localState.current.index = requestData.PhotoPoints.findIndex(
						(photo) => photo.id === requestData.id
					);
					setState((state) => {
						return {
							...state,
							breadCrumb: localState.current.line.BreadCrumb,
							photo:
								localState.current.line.PhotoPoints[localState.current.index],
						};
					});
				} catch (e) {
				} finally {
					setLoading(false);
				}
			}
			init();
		}
	}, [show, id]);

	return (
		<ClosestCoastViewStyled
			className="coastview-wrapper-c"
			show={show}
			onClick={onclick}
		>
			{loading || !requestData.Id ? (
				<PhotoItem
					title={languages[currentLanguage]["explore_coastview_hover_button"]}
				>
					<div
						style={{
							height: 72,
							borderRadius: "0 0 10px 10px",
							backgroundImage: `url(${defaultImg})`,
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundColor: "#fff",
						}}
						className="d-flex justify-content-center align-items-center"
					>
						<svg
							width="24px"
							height="17px"
							viewBox="0 0 24 17"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>
								{languages[currentLanguage]["explore_coastview_hover_button"]}
							</title>
							<g
								id="Web-App"
								stroke="none"
								strokeWidth="1"
								fill="none"
								fillRule="evenodd"
							>
								<g
									id="Explore-View-/-Place-Details-/-Marina"
									transform="translate(-1295.000000, -121.000000)"
									fill="#FFFFFF"
									fillRule="nonzero"
								>
									<g
										id="coast-view-btn"
										transform="translate(1248.000000, 93.000000)"
									>
										<g
											id="coast-view-icon"
											transform="translate(48.000000, 28.000000)"
										>
											<path
												d="M20.3962821,8.5 C20.3697767,5.56515574 21.0062621,2.71592617 22,1.77635684e-15 C15.4452759,3.27629862 8.55330629,3.27561564 2,1.77635684e-15 C4.1376472,5.69335681 4.13705644,11.3078886 2,17 C6.14879288,14.9634873 10.4061675,14.0983812 14.9656177,14.7501938 C16.1189004,14.9142719 17.2634398,15.1759772 18.3798196,15.5299664 C19.5679965,15.8901829 20.856327,16.478799 22,17 C21.0061439,14.2837926 20.3698555,11.4354469 20.3962821,8.5 Z M12.3879327,13.3395803 C10.695719,13.3085244 9.00066953,13.4732051 7.34677642,13.8285201 L10.3643811,9.56907879 C11.0359182,10.5174292 12.3944311,12.4338162 13.0503722,13.36007 C12.8298216,13.3496242 12.609271,13.3427943 12.3879327,13.3395803 L12.3879327,13.3395803 Z M14.5985585,13.4874273 L13.5123469,11.9547195 L14.3106613,10.971617 L16.6256548,13.8224937 C15.9549447,13.679066 15.2791146,13.5673772 14.5985585,13.4874273 Z M20.0670316,14.8493879 C19.5723682,14.658151 19.0737663,14.4845914 18.571226,14.3295127 L14.3106613,9.08174599 L12.7975267,10.9455027 L10.3643811,7.51127286 L5.56228585,14.2889351 C5.01484778,14.4540578 4.47174196,14.6412771 3.93296837,14.8493879 C5.0683313,10.7562745 5.0683313,6.24372548 3.93296837,2.15061209 C9.10621874,4.16727797 14.8937813,4.16727797 20.0670316,2.15061209 C18.9316687,6.24372548 18.9316687,10.7562745 20.0670316,14.8493879 L20.0670316,14.8493879 Z"
												id="Shape"
											></path>
											<path
												d="M15.6923077,5 C14.589641,5 13.6923077,5.89966496 13.6923077,7.0051968 C13.8023521,9.66533543 17.5826632,9.66453335 17.6923077,7.0051968 C17.6923077,5.89966496 16.7949744,5 15.6923077,5 Z M15.6923077,7.67359573 C15.3247521,7.67359573 15.025641,7.37370741 15.025641,7.0051968 C15.0623077,6.11849877 16.322441,6.11876613 16.3589744,7.0051968 C16.3589744,7.37370741 16.0598632,7.67359573 15.6923077,7.67359573 Z"
												id="Shape"
											></path>
											<path
												d="M-5.07692308,8.07494401 L-4.59241502,9 C-1.0837995,7.68153482 2.92995335,7.68153482 6.43856887,9 L6.92307692,8.07494401 C3.1096599,6.64166848 -1.26346607,6.64170218 -5.07692308,8.07494401 Z"
												id="Path"
												transform="translate(0.923077, 8.000000) rotate(90.000000) translate(-0.923077, -8.000000) "
											></path>
											<path
												d="M29.0769231,8.9250819 L28.592415,8 C25.0837995,9.31845407 21.0700467,9.31845407 17.5614311,8 L17.0769231,8.92504819 C20.8903401,10.3583117 25.2634661,10.3583117 29.0769231,8.9250819 L29.0769231,8.9250819 Z"
												id="Path"
												transform="translate(23.076923, 9.000000) rotate(90.000000) translate(-23.076923, -9.000000) "
											></path>
										</g>
									</g>
								</g>
							</g>
						</svg>
					</div>
					<div
						style={{ flex: 1, backgroundColor: "#fff" }}
						className="d-flex justify-content-center align-items-center"
					>
						<h2
							className="m-0 p-0"
							style={{
								fontSize: 14,
								color: "#222c4b",
							}}
						>
							{languages[currentLanguage]["common_coast_view"]}
						</h2>
					</div>
				</PhotoItem>
			) : (
				<PhotoItem
					title={languages[currentLanguage]["explore_coastview_hover_button"]}
				>
					<div
						style={{
							height: 72,
							borderRadius: "0 0 10px 10px",
							backgroundImage: `url(${state.photo?.ul})`,
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundColor: "#fff",
						}}
						className="d-flex justify-content-center align-items-center"
					>
						<svg
							width="24px"
							height="17px"
							viewBox="0 0 24 17"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>
								{languages[currentLanguage]["explore_coastview_hover_button"]}
							</title>
							<g
								id="Web-App"
								stroke="none"
								strokeWidth="1"
								fill="none"
								fillRule="evenodd"
							>
								<g
									id="Explore-View-/-Place-Details-/-Marina"
									transform="translate(-1295.000000, -121.000000)"
									fill="#FFFFFF"
									fillRule="nonzero"
								>
									<g
										id="coast-view-btn"
										transform="translate(1248.000000, 93.000000)"
									>
										<g
											id="coast-view-icon"
											transform="translate(48.000000, 28.000000)"
										>
											<path
												d="M20.3962821,8.5 C20.3697767,5.56515574 21.0062621,2.71592617 22,1.77635684e-15 C15.4452759,3.27629862 8.55330629,3.27561564 2,1.77635684e-15 C4.1376472,5.69335681 4.13705644,11.3078886 2,17 C6.14879288,14.9634873 10.4061675,14.0983812 14.9656177,14.7501938 C16.1189004,14.9142719 17.2634398,15.1759772 18.3798196,15.5299664 C19.5679965,15.8901829 20.856327,16.478799 22,17 C21.0061439,14.2837926 20.3698555,11.4354469 20.3962821,8.5 Z M12.3879327,13.3395803 C10.695719,13.3085244 9.00066953,13.4732051 7.34677642,13.8285201 L10.3643811,9.56907879 C11.0359182,10.5174292 12.3944311,12.4338162 13.0503722,13.36007 C12.8298216,13.3496242 12.609271,13.3427943 12.3879327,13.3395803 L12.3879327,13.3395803 Z M14.5985585,13.4874273 L13.5123469,11.9547195 L14.3106613,10.971617 L16.6256548,13.8224937 C15.9549447,13.679066 15.2791146,13.5673772 14.5985585,13.4874273 Z M20.0670316,14.8493879 C19.5723682,14.658151 19.0737663,14.4845914 18.571226,14.3295127 L14.3106613,9.08174599 L12.7975267,10.9455027 L10.3643811,7.51127286 L5.56228585,14.2889351 C5.01484778,14.4540578 4.47174196,14.6412771 3.93296837,14.8493879 C5.0683313,10.7562745 5.0683313,6.24372548 3.93296837,2.15061209 C9.10621874,4.16727797 14.8937813,4.16727797 20.0670316,2.15061209 C18.9316687,6.24372548 18.9316687,10.7562745 20.0670316,14.8493879 L20.0670316,14.8493879 Z"
												id="Shape"
											></path>
											<path
												d="M15.6923077,5 C14.589641,5 13.6923077,5.89966496 13.6923077,7.0051968 C13.8023521,9.66533543 17.5826632,9.66453335 17.6923077,7.0051968 C17.6923077,5.89966496 16.7949744,5 15.6923077,5 Z M15.6923077,7.67359573 C15.3247521,7.67359573 15.025641,7.37370741 15.025641,7.0051968 C15.0623077,6.11849877 16.322441,6.11876613 16.3589744,7.0051968 C16.3589744,7.37370741 16.0598632,7.67359573 15.6923077,7.67359573 Z"
												id="Shape"
											></path>
											<path
												d="M-5.07692308,8.07494401 L-4.59241502,9 C-1.0837995,7.68153482 2.92995335,7.68153482 6.43856887,9 L6.92307692,8.07494401 C3.1096599,6.64166848 -1.26346607,6.64170218 -5.07692308,8.07494401 Z"
												id="Path"
												transform="translate(0.923077, 8.000000) rotate(90.000000) translate(-0.923077, -8.000000) "
											></path>
											<path
												d="M29.0769231,8.9250819 L28.592415,8 C25.0837995,9.31845407 21.0700467,9.31845407 17.5614311,8 L17.0769231,8.92504819 C20.8903401,10.3583117 25.2634661,10.3583117 29.0769231,8.9250819 L29.0769231,8.9250819 Z"
												id="Path"
												transform="translate(23.076923, 9.000000) rotate(90.000000) translate(-23.076923, -9.000000) "
											></path>
										</g>
									</g>
								</g>
							</g>
						</svg>
					</div>
					<div
						style={{ flex: 1, backgroundColor: "#fff" }}
						className="d-flex justify-content-center align-items-center"
					>
						<h2
							className="m-0 p-0"
							style={{
								fontSize: 14,
								color: "#222c4b",
							}}
						>
							{languages[currentLanguage]["common_coast_view"]}
						</h2>
					</div>
				</PhotoItem>
			)}
		</ClosestCoastViewStyled>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosestCoastView);

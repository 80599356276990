export const USER_SET = "USER/SET";
export const USER_SET_PROFILE_ID = "USER/SET_PROFILE_ID";
export const USER_SET_DATA = "USER/SET_DATA";
export const USER_SET_SETTINGS = "USER/SET_SETTINGS";
export const USER_SET_VESSELS = "USER/SET_VESSELS";
export const USER_AUTH_VIEW = "USER/AUTH_VIEW";
export const USER_SIDEBAR_PROFILE = "USER/SIDEBAR_PROFILE";
export const USER_SIDEBAR_EDIT = "USER/SIDEBAR_EDIT";
export const USER_SIDEBAR_SETTINGS = "USER/SIDEBAR_SETTINGS";
export const USER_SIDEBAR_VESSELS = "USER/SIDEBAR_VESSELS";
export const USER_SIDEBAR_PARTNER_PROVISION = "USER/SIDEBAR_PARTNER_PROVISION";
export const USER_CLOSE_SIDEBARS = "USER/CLOSE_SIDEBAR";
export const USER_CLEAN = "USER/CLEAN";

import React from "react";
import { useDispatch } from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from "@material-ui/core";

// CONSTANTS
import { experience, shelter } from "../../constants/colors";

// ICONS
import CloseIcon from "../../assets/close-btn.svg";

function CustomDialog({
	visible,
	title,
	description,
	topIcon,
	actionContainerProps = {},
	actions = [],
	showCloseBtn = false,
	isLoading = false,
	onClose = () => {},
}) {
	// DATA
	const STYLES = {
		dialogBackDrop: { background: experience + "CC" },
		closeIcon: {
			top: -50,
			right: -50,
		},
		topIcon: {
			height: 85,
			width: 85,
		},
		headerTitle: {
			fontSize: 28,
			color: shelter,
		},
	};

	return (
		<Dialog
			id="CustomDialog"
			open={!!visible}
			aria-labelledby="custom-dialog-title"
			aria-describedby="custom-dialog-description"
			BackdropProps={{ style: STYLES.dialogBackDrop }}
			maxWidth="md"
			onClose={onClose}
		>
			{!!showCloseBtn && (
				<IconButton
					className="position-absolute"
					style={STYLES.closeIcon}
					onClick={onClose}
				>
					<img src={CloseIcon} alt="Close Icon" />
				</IconButton>
			)}
			<DialogContent className="d-flex flex-column justify-content-center align-items-center text-center">
				{isLoading ? (
					<div className="h-100 w-100 d-flex flex-row align-items-center justify-content-center">
						<i className="fa fa-fw fa-spin fa-circle-notch" />
					</div>
				) : (
					<>
						{topIcon && (
							<div className="d-flex align-items-center justify-content-center mt-5 mb-3">
								<img src={topIcon} style={STYLES.topIcon} />
							</div>
						)}

						<DialogTitle id="custom-dialog-title" className="mb-4">
							{title && <h3 style={STYLES.headerTitle}>{title}</h3>}
						</DialogTitle>

						{description && (
							<DialogContentText
								id="custom-dialog-description"
								className="mb-5 pb-3 mx-md-5"
								style={{ color: shelter, fontSize: 23, fontWeight: "100" }}
							>
								{description}
							</DialogContentText>
						)}

						{actionContainerProps && !!actions && !!actions.length && (
							<DialogActions
								{...{
									className: "d-flex flex-column",
									...actionContainerProps,
								}}
							>
								{actions?.map((item, index) => (
									<Button key={index} {...item} />
								))}
							</DialogActions>
						)}
					</>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default CustomDialog;

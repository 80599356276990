import React from 'react';
import { IconButton, CircularProgress, Avatar } from '@material-ui/core';
import { currentLang } from "../../store/features/translation/selectors";
import { connect, useSelector } from "react-redux";
import { languages } from "../../store/features/translation/Languages/";

// API
import { ApiClient } from "../../ApiClient";

// STYLED
import { PlacePhotos as Wrapper } from "./styled";
import { useStyles } from '../map/Popup';
import { whiteColor } from '../../constants/colors';
import { gtag_report_conversion, types } from '../../helpers/googleTags';

function CoastViewPhotos({
	placeId = null,
	onClose = () => { }
}) {
	// STYLES 
	const classes = useStyles();

	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	// STATES
	const
		[photosList, setPhotosList] = React.useState([]),
		[currentObject, setCurrentObject] = React.useState({}),
		[loading, setLoading] = React.useState(true);

	// FUNCTIONS
	const canDisplay = () => placeId;

	const prev = () => {
		
		const indexLength = photosList.length - 1;
		const prevIndex = currentObject.index - 1;
		if (prevIndex >= 0) {
			setCurrentObject({
				index: prevIndex,
				...photosList[prevIndex]
			})
		} else {
			setCurrentObject({
				index: indexLength,
				...photosList[indexLength]
			})
		}
	}

	const next = () => {
		
		const indexLength = photosList.length - 1;
		const nextIndex = currentObject.index + 1;

		if (nextIndex <= indexLength) {
			setCurrentObject({
				index: nextIndex,
				...photosList[nextIndex]
			})
		} else {
			setCurrentObject({
				index: 0,
				...photosList[0]
			})
		}
	}

	// EFFECTS
	React.useEffect(() => {
		(async () => {
			if (placeId) {
				setLoading(true);
				ApiClient.getPlaceCoastViewPhotos(placeId).then((photosList_) => {
					setPhotosList(photosList_);
					setCurrentObject({
						index: 0,
						...photosList_[0]
					})
				}).catch(() => {

				}).finally(() => {
					setLoading(false);
				})
			}
		})();
		return () => setPhotosList([]);
	}, [placeId]);

	return <Wrapper
		className="d-flex flex-column"
		show={canDisplay()}
	>
		<div
			className="position-relative flex w-100"
			style={{ flex: 1 }}
		>
			{loading
				? <div
					className="d-flex h-100 w-100 justify-content-center align-items-center"
					style={{}}
				>
					<CircularProgress className={classes.progress} />
				</div>
				: <>
					{Object.keys(currentObject).length && <div className="h-100 w-100 d-flex flex-column">
						<div
							className="d-flex align-items-center justify-content-between"
							style={{
								backgroundColor: "#000",
								color: "#FFF",
								height: 85,
								width: "100%",
								padding: "0 20px",
							}}>

							<div>

								<h1 style={{ fontSize: 21, color: whiteColor }}>{languages[currentLanguage]["common_coast_view"]}</h1>
							</div>

							<IconButton
								style={{
									height: 30,
									width: 30,
									padding: 0,
									fontSize: 14,
									color: "#fff",
								}}
								onClick={onClose}
							>
								<i className="fas fa-fw fa-times" />
							</IconButton>

						</div>

						<div
							className="position-relative overflow-hidden w-100"
							style={{
								flex: 1,
								backgroundImage: `url(${currentObject?.ul})`,
								backgroundPosition: "center",
								backgroundSize: "cover",
							}}
						/>

						<div
							className="d-flex align-items-center justify-content-end"
							style={{
								backgroundColor: "#000",
								color: "#FFF",
								height: 85,
								width: "100%",
								padding: "0 20px",
							}}
						>
							<h2 className="p-0 m-0" style={{ fontSize: 21, color: whiteColor }}>
								<i className="far fa-image" /> {currentObject.index + 1}/{photosList.length}
							</h2>
						</div>

						<IconButton
							onClick={prev}
							style={{
								...styles.floatIconBtn,
								left: 20,
								borderRadius: "25px !important"
							}}
						>
							<i className="fas fa-chevron-left fa-fw" />
						</IconButton>
						<IconButton
							onClick={next}
							style={{
								...styles.floatIconBtn,
								right: 20,
								borderRadius: "25px !important"
							}}
						>
							<i className="fas fa-chevron-right fa-fw" />
						</IconButton>
					</div>}
				</>
			}
		</div>
	</Wrapper>
}

const mapStateToProps = (state) => {
	return {};
};
  
const mapDispatchToProps = (dispatch) => {
	return {};
};
  
export default connect(mapStateToProps, mapDispatchToProps)(CoastViewPhotos);

const styles = {
	floatIconBtn: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		width: 50,
		height: 50,
		opacity: 0.9,
		fontSize: 16,
		margin: 0,
		padding: 0,
		borderRadius: 100,
		"-webkit-backdrop-filter": "blur(5px)",
		backdropFilter: "blur(5px)",
		overflow: "hidden",
		color: "#FFF",
		fontSize: 16,
		backgroundColor: "rgba(17, 17, 17, 0.5)"
	}
}

import React from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, Collapse, List, Avatar } from "@material-ui/core";
import { differenceInSeconds, differenceInDays } from "date-fns";
import { toast } from "react-toastify";

// CONSTANTS
import {
	filterHighlightColor,
	shelter,
	deleteColor,
	shopsservices,
} from "../../../constants/colors";
import TRIP_USER_ROLES from "../../../constants/tripUserRoles";
import TOSTIFY_DEFAULT_PROPS from "../../../constants/tostifyDefaultProps";
import TRIP_VIRTAUL_GUIDE_STATES from "../../../constants/tripVirtaulGuideStates";
import TRIP_STATES from "../../../constants/tripStates";

// STORE
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// HELPERS
import { ApiClient } from "../../../ApiClient";
import { isErrorResponse } from "../../../helpers/functions";

// ICONS
import PriceIcon from "../../../assets/svg/tripOverview/price.svg";
import BoxChatIcon from "../../../assets/svg/tripOverview/boxChat.svg";
import BoxChatInactiveIcon from "../../../assets/svg/tripOverview/boxChatInactive.svg";
import chatBubbleIcon from "../../../assets/svg/tripOverview/chatBubble.svg";
import CloseLightIcon from "../../../assets/svg/tripOverview/closeLight.svg";
import VG_activatedIcon from "../../../assets/svg/tripOverview/vg_activated.svg";
import VG_unactivatedIcon from "../../../assets/svg/tripOverview/vg_unactivated.svg";

const NotificationPopup = ({ visible = false, onClose = () => {} }) => {
	// SELECTORS
	const CURRENT_LANGUAGE = useSelector(currentLang);

	// DATA
	const STYLES = {
		arrowLeft: {
			top: "50%",
			left: "-10px",
			transform: " translateY(-50%)",
			borderTop: "10px solid transparent",
			borderBottom: "10px solid transparent",
			borderRight: "10px solid " + filterHighlightColor,
		},
		closeBtn: {
			top: 10,
			right: 10,
		},
		body: {
			width: 400,
			padding: 35,
			background: filterHighlightColor,
			color: "#FFF",
		},
		title: {
			fontWeight: "800",
			fontSize: 17,
			color: "#FFF",
		},
		bodyContent: {
			fontWeight: "400",
			fontSize: 14,
		},
	};

	return (
		<div className="position-relative ml-4">
			<div className="position-absolute w-0 h-0" style={STYLES.arrowLeft} />
			<IconButton
				className="position-absolute"
				style={STYLES.closeBtn}
				onClick={onClose}
			>
				<img src={CloseLightIcon} />
			</IconButton>
			<div style={STYLES.body}>
				<h3 className="deci m-0 mt-2 p-0 pb-3" style={STYLES.title}>
					{languages[CURRENT_LANGUAGE]["trip_overview_vg_popup_header"]}
				</h3>
				<p class="dromon-l m-0 p-0 text-justify" style={STYLES.bodyContent}>
					{languages[CURRENT_LANGUAGE]["trip_overview_vg_popup_msg"]}
				</p>
			</div>
		</div>
	);
};

function VirtualGuide({
	tripData = {},
	data = {},
	currentUserRole = TRIP_USER_ROLES.NO_MEMBER,
	currentVirtualGuide = null,
	showNotification = false,
	onClickChat = () => {},
	onChangeDialogState = () => {},
	onChangeVirtualGuidState = (newVirtualGuideData = {}) => {},
	onClosesNotification = () => {},
}) {
	// SELECTORS
	const CURRENT_LANGUAGE = useSelector(currentLang);

	// STATES
	const [seeMoreDetails, setSeeMoreDetails] = React.useState(false);
	const [stateLoading, setStateLoading] = React.useState(false);

	// DATA
	const ENABLE_CASE_1 = currentUserRole === TRIP_USER_ROLES.SUPERPASSENGER;
	// const ENABLE_CASE_2 =
	// 	currentUserRole === TRIP_USER_ROLES.CREATOR ||
	// 	currentUserRole === TRIP_USER_ROLES.COCREATOR ||
	// 	currentUserRole === TRIP_USER_ROLES.PASSENGER ||
	// 	currentUserRole === TRIP_USER_ROLES.PASSENGERSKIPPER ||
	// 	currentUserRole === TRIP_USER_ROLES.PASSENGER_SKIPPER ||
	// 	currentUserRole === TRIP_USER_ROLES.VIRTUAL_GUIDE;
	const ENABLE_CASE_3 =
		currentUserRole === TRIP_USER_ROLES.CREATOR ||
		currentUserRole === TRIP_USER_ROLES.SUPERPASSENGER ||
		currentUserRole === TRIP_USER_ROLES.PASSENGERSKIPPER ||
		currentUserRole === TRIP_USER_ROLES.PASSENGER_SKIPPER ||
		currentUserRole === TRIP_USER_ROLES.VIRTUAL_GUIDE;
	// const ENABLE_CASE_4 = ENABLE_CASE_2 || ENABLE_CASE_1;
	const IS_COMPLETED_OR_CANCELED =
		tripData?.State === TRIP_STATES.COMPLETED ||
		tripData?.State === TRIP_STATES.CANCELLED ||
		tripData?.State === TRIP_STATES.CANCELED;

	// FUNCTIONS
	const onEnableVirtualGuide = async () => {
		if (!tripData || !tripData?.Id) {
			return;
		}

		setStateLoading(true);

		ApiClient.setVirtualGuideServiceState(
			tripData?.Id,
			TRIP_VIRTAUL_GUIDE_STATES.ENABLED
		)
			.then((res) => {
				if (!res || isErrorResponse(res)) {
					return toast.error(
						res.ErrorMessage ?? "Something went wrong",
						TOSTIFY_DEFAULT_PROPS
					);
				}

				onChangeVirtualGuidState(res);
				onChangeDialogState({
					visible: true,
					title: (
						<span className="mb-4">
							{languages[CURRENT_LANGUAGE]["trip_vg_modal_active_header"]}
						</span>
					),
					description: (
						<p>
							{languages[CURRENT_LANGUAGE]["trip_vg_modal_active_txt"].replace(
								"%trip_name%",
								""
							)}
							<strong>{tripData?.Name}</strong>
						</p>
					),
					topIcon: VG_activatedIcon,
					showCloseBtn: true,
					onClose: () => onChangeDialogState({ visible: false }),
				});
			})
			.finally(() => {
				setStateLoading(false);
			});
	};

	const onDisableVirtualGuide = async () => {
		if (!tripData || !tripData?.Id) {
			return;
		}

		const onDisableVirtualGuide_ = () => {
			setStateLoading(true);

			ApiClient.setVirtualGuideServiceState(
				tripData?.Id,
				TRIP_VIRTAUL_GUIDE_STATES.DISABLED
			)
				.then((res) => {
					if (!res || isErrorResponse(res)) {
						return toast.error(
							res.ErrorMessage ?? "Something went wrong",
							TOSTIFY_DEFAULT_PROPS
						);
					}

					onChangeVirtualGuidState(res);
					onChangeDialogState({
						visible: true,
						title: (
							<span className="mb-4">
								{languages[CURRENT_LANGUAGE]["trip_vg_reactivate_modal_header"]}
							</span>
						),
						description:
							languages[CURRENT_LANGUAGE]["trip_vg_reactivate_modal_txt"],
						topIcon: VG_unactivatedIcon,
						actionContainerProps: {
							className: "d-flex flex-column mb-5",
						},
						actions: [
							{
								children:
									languages[CURRENT_LANGUAGE]["trip_vg_reactivate_modal_btn"],
								variant: "text",
								className: "px-4",
								style: { background: filterHighlightColor, color: "#FFF" },
								onClick: () => onEnableVirtualGuide(),
							},
						],
						showCloseBtn: true,
						onClose: () => onChangeDialogState({ visible: false }),
					});
				})
				.finally(() => {
					setStateLoading(false);
				});
		};

		onChangeDialogState({
			visible: true,
			description: languages[CURRENT_LANGUAGE]["trip_vg_cancel_modal"],
			showCloseBtn: true,
			actionContainerProps: {
				className: "d-flex flex-row mb-5",
			},
			actions: [
				{
					children: (
						<span>
							{stateLoading && (
								<i className="fa fa-fw fa-spin fa-circle-notch" />
							)}{" "}
							{languages[CURRENT_LANGUAGE]["common_ok"]}
						</span>
					),
					variant: "text",
					className: "px-4",
					style: { color: filterHighlightColor },
					onClick: onDisableVirtualGuide_,
				},
				{
					children: languages[CURRENT_LANGUAGE]["common_cancel"],
					variant: "text",
					className: "px-4",
					style: { background: filterHighlightColor, color: "#FFF" },
					onClick: () => onChangeDialogState({ visible: false }),
				},
			],
			onClose: () => onChangeDialogState({ visible: false }),
		});
	};

	// STYLES
	const STYLES = {
		main: {
			background: "rgba(237, 241, 247, 0.65)",
			borderRadius: 3,
		},
		headerTitle: {
			fontSize: 20,
			color: "#222C4B",
		},
		headerStateAction: {
			background: filterHighlightColor,
			color: "#FFF",
			borderRadius: 20,
			padding: "3px 20px",
		},
		headerChatAction: {
			background: filterHighlightColor,
			color: "#FFF",
			borderRadius: 20,
		},
		contentTitle: {
			fontSize: 16,
			color: shelter,
			wordSpacing: -3,
			fontWeight: "900",
		},
		priceIcon: {
			height: 16,
		},
		priceSubTitle: {
			fontSize: 14,
		},
		priceDesc: {
			fontSize: 12,
			color: "#6C7CA3",
		},
		availableDate: {
			color: deleteColor,
			fontSize: 10,
		},
		liveSupport: {
			background: !IS_COMPLETED_OR_CANCELED
				? "rgba(142, 174, 156, 0.4)"
				: "rgba(192, 199, 216, 0.4)",
			fontSize: 12,
			color: !IS_COMPLETED_OR_CANCELED
				? shopsservices
				: "rgba(192, 199, 216, 1)",
		},
		collapseAction: {
			color: filterHighlightColor,
			fontSize: 12,
			textTransform: "initial",
		},
		collapseTitle: {
			fontSize: 16,
			color: shelter,
			wordSpacing: -3,
		},
		detailsList: {
			fontSize: 14,
			color: shelter,
		},
		detailsListItemIcon: {
			fontSize: 8,
		},
		collapseDesc: {
			color: "#6C7CA3",
			fontSize: 11,
		},
		notificationPopup: {
			top: "50%",
			right: 0,
			transform: "translateY(-50%) translateX(100%)",
		},
	};

	return (
		<div className="py-3 px-2 my-4 position-relative" style={STYLES.main}>
			<div className="d-flex flex-row align-items-center justify-content-between mb-3">
				<h3 className="my-0 p-0" style={STYLES.headerTitle}>
					{languages[CURRENT_LANGUAGE]["trip_overview_vg_header"]}
				</h3>

				{/* HEADER ACTIONS */}
				<div>
					{ENABLE_CASE_3 && data?.State === TRIP_VIRTAUL_GUIDE_STATES.ENABLED && (
						<IconButton
							disabled={
								IS_COMPLETED_OR_CANCELED ||
								!(
									tripData?.State === TRIP_STATES.LIVE ||
									(data?.StartDateTimeUtc &&
										differenceInDays(
											new Date(data?.StartDateTimeUtc),
											new Date()
										) <= 0)
								)
							}
							onClick={onClickChat}
							style={{ color: filterHighlightColor }}
						>
							<img
								src={
									!(
										IS_COMPLETED_OR_CANCELED ||
										!(
											tripData?.State === TRIP_STATES.LIVE ||
											(data?.StartDateTimeUtc &&
												differenceInDays(
													new Date(data?.StartDateTimeUtc),
													new Date()
												) <= 0)
										)
									)
										? BoxChatIcon
										: BoxChatInactiveIcon
								}
							/>
						</IconButton>
					)}

					{ENABLE_CASE_1 &&
						data?.State === TRIP_VIRTAUL_GUIDE_STATES.DISABLED &&
						!IS_COMPLETED_OR_CANCELED && (
							<Button
								disabled={stateLoading}
								className="text-capitalize"
								style={STYLES.headerStateAction}
								onClick={() => onEnableVirtualGuide()}
							>
								{stateLoading && (
									<i className="fa fa-circle-notch fa-spin mr-1" />
								)}
								{languages[CURRENT_LANGUAGE]["trip_overview_vg_cta"]}
							</Button>
						)}
				</div>
			</div>

			{/* VIRTUAL GUIDE PROFILE */}
			{currentVirtualGuide && data.State === TRIP_VIRTAUL_GUIDE_STATES.ENABLED && (
				<div className="d-flex flex-row align-items-center mb-4">
					<Avatar
						src={
							currentVirtualGuide.Photo ? currentVirtualGuide.Photo : undefined
						}
						style={{
							height: 40,
							width: 40,
							background: filterHighlightColor,
						}}
						alt={"Virtual Guide Profile"}
						className="mr-2"
					>
						{currentVirtualGuide.FirstName[0]}
					</Avatar>

					<div>
						<h4 style={{ ...STYLES.collapseTitle, fontSize: 14 }}>
							{currentVirtualGuide.FirstName} {currentVirtualGuide.LastName}
						</h4>
						<h5 className="mb-0" style={{ ...STYLES.priceDesc }}>
							Travel guide
						</h5>
					</div>
				</div>
			)}

			{IS_COMPLETED_OR_CANCELED && (
				<p style={{ color: "#6C7CA3", fontSize: 12 }}>
					{languages[CURRENT_LANGUAGE]["trip_vg_unavailable_disclaimer"]}
				</p>
			)}

			{/* PRICING */}
			{!IS_COMPLETED_OR_CANCELED && (
				<div className="d-flex flex-row mb-3 align-items-start">
					<img src={PriceIcon} className="mr-2" style={STYLES.priceIcon} />

					<div style={{ flex: 1 }}>
						<div className="d-flex flex-row align-items-center justify-content-between">
							{!!data?.PricePerday && (
								<h4 className="mb-0" style={STYLES.contentTitle}>
									{data.PricePerday + "€ "}
									<span
										className="font-weight-light"
										style={STYLES.priceSubTitle}
									>
										{languages[CURRENT_LANGUAGE]["trip_overview_vg_priceper"]}
									</span>
								</h4>
							)}

							{false && (
								<small style={STYLES.availableDate}>
									*Active from 24th August, 2022
								</small>
							)}
						</div>
						<p style={STYLES.priceDesc}>
							{languages[CURRENT_LANGUAGE]["trip_overview_vg_nocard"]}
						</p>
					</div>
				</div>
			)}

			{/* DETAILS */}
			<div style={{ opacity: !IS_COMPLETED_OR_CANCELED ? 1 : 0.5 }}>
				<div className="d-flex flex-row align-items-center justify-content-between">
					<div
						className="d-flex flex-row align-items-center py-1 px-2 rounded-sm"
						style={STYLES.liveSupport}
					>
						<img src={chatBubbleIcon} className="mr-2" />
						{languages[CURRENT_LANGUAGE]["trip_overview_vg_tagsupport"]}
					</div>

					<Button
						disabled={showNotification}
						style={STYLES.collapseAction}
						onClick={() => setSeeMoreDetails(!seeMoreDetails)}
					>
						{seeMoreDetails ? (
							<>
								{languages[CURRENT_LANGUAGE]["trip_overview_vg_expanded"]}
								<i className="fa fa-fw fa-chevron-down ml-1" />
							</>
						) : (
							<>
								{languages[CURRENT_LANGUAGE]["trip_overview_vg_collapsed"]}
								<i className="fa fa-fw fa-chevron-up ml-1" />
							</>
						)}
					</Button>
				</div>

				<Collapse in={seeMoreDetails} timeout="auto" unmountOnExit>
					<List className="pt-4 pl-3">
						<h5 className="mb-3" style={STYLES.contentTitle}>
							{languages[CURRENT_LANGUAGE]["trip_overview_vg_included"]}
						</h5>

						<ul className="mb-4 ml-0 pl-0" style={STYLES.detailsList}>
							{!!data?.Included &&
								data?.Included?.length &&
								data?.Included.map((item, index) => (
									<li
										key={index}
										className="d-flex flex-row align-items-center mb-1"
									>
										<i
											className="fa fa-fw fa-check mr-1"
											style={STYLES.detailsListItemIcon}
										/>
										{languages[CURRENT_LANGUAGE][item] ?? item ?? ""}
									</li>
								))}
						</ul>

						{data?.State === TRIP_VIRTAUL_GUIDE_STATES.ENABLED &&
							tripData?.State === "onboarding" && (
								<p className="mb-3" style={STYLES.collapseDesc}>
									*The payment starts on the first day of your trip. Read more
									at{" "}
									<a
										className=""
										href={"/virtual-guide/FAQ"}
										target="_blank"
										style={{ color: filterHighlightColor }}
									>
										{languages[CURRENT_LANGUAGE]["trip_overview_vg_faq_cta"]}
									</a>
								</p>
							)}

						<div>
							<small>
								{ENABLE_CASE_1 &&
									data?.State == TRIP_VIRTAUL_GUIDE_STATES.ENABLED &&
									!IS_COMPLETED_OR_CANCELED && (
										<a
											href="#"
											style={{ color: filterHighlightColor }}
											onClick={(e) => {
												e.preventDefault();
												onDisableVirtualGuide();
											}}
										>
											{stateLoading && (
												<i className="fa fa-circle-notch fa-spin mr-1" />
											)}
											{
												languages[CURRENT_LANGUAGE][
													"trip_overview_vg_cancel_btn"
												]
											}
										</a>
									)}
							</small>
						</div>
					</List>
				</Collapse>
			</div>

			{showNotification && (
				<div
					className="position-absolute pl-3"
					style={STYLES.notificationPopup}
				>
					<NotificationPopup
						visible={showNotification}
						onClose={onClosesNotification}
					/>
				</div>
			)}
		</div>
	);
}

export default VirtualGuide;

import React from "react";
import { connect, useSelector } from "react-redux";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// API
import { ApiClient } from "../../../ApiClient";

// SELECTORS
import {
	currentTripItinerary,
	selectedItineraryDay,
	selectedItineraryDayNode,
} from "../../../store/features/map/selectors";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// ACTIONS
import { setTripItinerary } from "../../../store/features/map/actions";

// SVG
import backIcon from "../../../assets/back_ic.svg";

// STYLED
import { Sidebar, SidebarContent } from "./styled";
import { useStyles } from "../../map/Popup";
import { logEvent_ } from "../../../helpers/analytics";

const DayDraftNotes = ({ setTripItinerary = () => {} }) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentTripItinerary_ = useSelector(currentTripItinerary),
		selectedItineraryDay_ = useSelector(selectedItineraryDay),
		selectedItineraryDayNode_ = useSelector(selectedItineraryDayNode),
		currentLanguage = useSelector(currentLang);

	// STATES
	const [notice, setNotice] = React.useState({ type: "", content: "" }),
		[noteInp, setNoteInp] = React.useState(null),
		[orderInp, setOrderInp] = React.useState(null),
		[visible, setVisible] = React.useState(false),
		[loading, setLoading] = React.useState(false);

	// FUNCTION
	const onClose = () =>
		setTripItinerary(
			{
				visibility: true,
				selectedDay: {
					...selectedItineraryDay_,
					selectedNode: {
						...selectedItineraryDayNode_,
						visibility: false,
					},
				},
			},
			true
		);

	const onCloseSave = () => {
		if (currentTripItinerary_.canEdit) {
			if (selectedItineraryDay_.inEditing) {
				setTripItinerary(
					{
						visibility: true,
						selectedDay: {
							...selectedItineraryDay_,
							selectedNode: {
								...selectedItineraryDayNode_,
								visibility: false,
							},
							launchPoint:
								selectedItineraryDay_?.launchPoint &&
								selectedItineraryDay_?.launchPoint?.Order === orderInp
									? {
											...selectedItineraryDay_?.launchPoint,
											Note: noteInp,
									  }
									: selectedItineraryDay_?.launchPoint,
							tripNodes: selectedItineraryDay_?.tripNodes?.map((item) => {
								if (item.Order === selectedItineraryDayNode_.order) {
									return {
										...item,
										Note: noteInp,
									};
								}
								return item;
							}),
							shelterPoint:
								selectedItineraryDay_?.shelterPoint &&
								selectedItineraryDay_?.shelterPoint?.Order === orderInp
									? {
											...selectedItineraryDay_?.shelterPoint,
											Note: noteInp,
									  }
									: selectedItineraryDay_?.shelterPoint,
						},
						daysList: currentTripItinerary_?.daysList.map((item, idx) => {
							if (idx === selectedItineraryDay_.index) {
								return {
									...item,
									EditableNodes: item.EditableNodes.map((subItem) => {
										if (subItem.Order === orderInp) {
											return {
												...subItem,
												Note: noteInp,
											};
										}
										return subItem;
									}),
								};
							}
							return item;
						}),
					},
					true
				);
				setVisible(false);
			} else {
				setLoading(true);
				ApiClient.setNodeNoteTripItineraryDay({
					tripId: currentTripItinerary_?.data?.Id,
					Day: currentTripItinerary_?.daysList[selectedItineraryDay_?.index]
						?.Day,
					Order: orderInp,
					Note: noteInp,
				})
					.then((res) => {
						if (res.ErrorCode || res.ErrorMessage) {
							//alert("Data not saved");
							setTripItinerary(
								{
									visibility: true,
									selectedDay: {
										...selectedItineraryDay_,
										selectedNode: {
											...selectedItineraryDayNode_,
											visibility: false,
										},
									},
								},
								true
							);
							setVisible(false);
						} else {
							//alert("Data saved");
							setTripItinerary(
								{
									visibility: true,
									selectedDay: {
										...selectedItineraryDay_,
										selectedNode: {
											...selectedItineraryDayNode_,
											visibility: false,
											note: noteInp,
										},
									},
									daysList: currentTripItinerary_?.daysList.map((item, idx) => {
										if (idx === selectedItineraryDay_.index) {
											return {
												...item,
												Nodes: item.Nodes.map((subItem) => {
													if (
														subItem.Order === selectedItineraryDayNode_.order
													) {
														return {
															...subItem,
															Note: noteInp,
														};
													}
													return subItem;
												}),
												EditableNodes: item.EditableNodes.map((subItem) => {
													if (
														subItem.Order === selectedItineraryDayNode_.order
													) {
														return {
															...subItem,
															Note: noteInp,
														};
													}
													return subItem;
												}),
											};
										}
										return item;
									}),
								},
								true
							);
							setVisible(false);
						}
					})
					.catch((err) => {
						//console.log(err);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		} else {
			setTripItinerary(
				{
					visibility: true,
					selectedDay: {
						...selectedItineraryDay_,
						selectedNode: {
							...selectedItineraryDayNode_,
							visibility: false,
						},
					},
				},
				true
			);
			setVisible(false);
		}
	};

	// EFFECTS
	React.useEffect(() => {
		if (
			currentTripItinerary_?.daysList[selectedItineraryDay_?.index] &&
			currentTripItinerary_?.daysList[selectedItineraryDay_?.index][
				selectedItineraryDay_.inEditing ? "EditableNodes" : "Nodes"
			] &&
			(!!currentTripItinerary_?.daysList[selectedItineraryDay_?.index][
				selectedItineraryDay_.inEditing ? "EditableNodes" : "Nodes"
			][selectedItineraryDayNode_.index]?.Order ||
				currentTripItinerary_?.daysList[selectedItineraryDay_?.index][
					selectedItineraryDay_.inEditing ? "EditableNodes" : "Nodes"
				][selectedItineraryDayNode_.index]?.Order === 0)
		)
			setOrderInp(
				currentTripItinerary_?.daysList[selectedItineraryDay_?.index][
					selectedItineraryDay_.inEditing ? "EditableNodes" : "Nodes"
				][selectedItineraryDayNode_.index]?.Order
			);

		if (selectedItineraryDayNode_.visibility) {
			setVisible(true);
			logEvent_("react_trip_itiner_notesbutton_pressed", {
				tripId: currentTripItinerary_?.data?.Id,
			});
			setNoteInp(selectedItineraryDayNode_.note);
		}

		return () => {
			if (selectedItineraryDayNode_?.visibility) setVisible(false);
		};
	}, [selectedItineraryDayNode_?.visibility, visible]);

	return (
		<>
			<Sidebar show={visible} id="settings-box">
				<SidebarContent className="pb-5">
					<Button
						onClick={onClose}
						className="justify-content-start py-3 mb-2 w-100"
						style={{ textTransform: "initial", fontFamily: "inherit" }}
					>
						<img
							alt="icon"
							src={backIcon}
							style={{ height: 16, width: 8, marginRight: 17 }}
						/>
						<h2 className="m-0 p-0" style={{ fontSize: 21, color: "#222c4b" }}>
							{/* Notes */}
							{languages[currentLanguage]["trip_itinerary_notes"]}
						</h2>
					</Button>
					{loading ? (
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<>
							<div
								component="nav"
								aria-labelledby="nested-list"
								className="d-flex w-100 flex-column px-3"
								style={{ height: "85%" }}
							>
								<div className="d-flex w-100 flex-column" style={{ flex: 1 }}>
									<label>
										{languages[currentLanguage]["trip_itinerary_notes"]}
									</label>
									<textarea
										disabled={!currentTripItinerary_.canEdit}
										value={noteInp ?? ""}
										onChange={(e) => setNoteInp(e.target.value)}
										className="p-3"
										style={{ minHeight: 107, borderRadius: 5 }}
										placeholder={
											currentTripItinerary_.canEdit
												? languages[currentLanguage][
														"trip_itinerary_place_notes_placeholder"
												  ]
												: ""
										}
									></textarea>
								</div>

								{currentTripItinerary_.canEdit && (
									<Button
										onClick={onCloseSave}
										type="filled"
										className="w-100 my-2"
										style={{
											textTransform: "initial",
											fontFamily: "inherit",
											backgroundColor: "#0078FF",
											color: "#FFF",
										}}
									>
										{/* Save */}
										{languages[currentLanguage]["common_save"]}
									</Button>
								)}
							</div>
						</>
					)}
				</SidebarContent>
			</Sidebar>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={!!notice.type.length && !!notice.content.length}
				autoHideDuration={4000}
				onClose={() => setNotice({ type: "", content: "" })}
			>
				<Alert
					elevation={6}
					variant="filled"
					severity={notice.type.toLowerCase()}
					onClose={() => setNotice({ type: "", content: "" })}
				>
					{notice.content}
				</Alert>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (_state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTripItinerary: (itineraryObject = {}, combine = true) =>
			dispatch(setTripItinerary(itineraryObject, combine)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DayDraftNotes);

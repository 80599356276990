import React, { useState, useEffect } from "react";
import mapLayer from "../../../assets/svg/tripWizard/map_layer.png";
import circular from "../../../assets/svg/tripWizard/circular_trip_ic.png";
import oneDir from "../../../assets/svg/tripWizard/direct_trip_ic.png";
import { filterHighlightColor, whiteColor } from "../../../constants/colors";
import Button from "../Components/Button";
import { connect, useSelector } from "react-redux";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { logEvent_ } from "../../../helpers/analytics";

const Fourth = (props) => {
	const currentLanguage = useSelector(currentLang);
	const [state, setstate] = useState({
		selected: "circular",
		alltypes: [],
	});
	const tripAttributes = useSelector((state) => state.map.tripAttributes);
	const handleBack = () => {
		props.handleBack({
			step: 4,
		});
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_stepdirect_nextbtn_pressed");

		props.handleNext({
			step: 4,
			tags: [state.selected],
		});
	};
	useEffect(() => {
		let categories = Object.keys(tripAttributes);
		let versions = tripAttributes[categories[1]].Values;
		setstate({
			selected: props.state?.Attributes
				? props.state?.Attributes[2]
				: versions[0],
			alltypes: versions,
		});
	}, [tripAttributes, props.state, props.finished]);
	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
				>
					<h3
						className="my-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						Creating a New Trip
					</h3>
					<h2 style={{ fontSize: 22, color: "#222c4b" }}>
						{languages[currentLanguage]["trip_attr_direction_header"]}
					</h2>
					<div
						style={{ width: 384 }}
						className="d-flex flex-row justify-content-between px-0"
					>
						<div>
							<div
								onClick={() =>
									setstate({ ...state, selected: state.alltypes[0] })
								}
								className="d-flex flex-column pt-4 align-items-center"
								style={{
									height: 250,
									width: 180,
									borderRadius: 10,
									border:
										state.selected === state.alltypes[0]
											? "solid 2px #0078ff"
											: "solid 2px #dbdee6",
									backgroundImage: `url(${mapLayer})`,
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									backgroundSize: "cover",
									cursor: "pointer",
								}}
							>
								<img
									src={circular}
									alt=""
									style={{
										height: 150,
										width: 150,
									}}
								/>
								{state.selected === state.alltypes[0] ? (
									<img
										src={require("../../../assets/svg/filters_icons/tick_ic.svg")}
										style={{ marginTop: 25, width: 26, height: 26 }}
									/>
								) : (
									<div
										style={{
											height: 26,
											marginTop: 25,
											width: 26,
											borderRadius: 14,
											border: "solid 2px #dbdee6",
										}}
									></div>
								)}
							</div>
							<h2
								className="dromon-l text-center mt-2"
								style={{
									fontSize: 16,
									fontWeight: 300,
									color:
										state.selected === state.alltypes[0]
											? "#0078ff"
											: "#222c4b",
								}}
							>
								{languages[currentLanguage][
									state.alltypes[0]?.replaceAll(":", "_")
								]
									? languages[currentLanguage][
											state.alltypes[0]?.replaceAll(":", "_")
									  ]
									: state.alltypes[0]}
							</h2>
						</div>
						<div>
							<div
								onClick={() =>
									setstate({ ...state, selected: state.alltypes[1] })
								}
								className="d-flex flex-column pt-4 align-items-center"
								style={{
									height: 250,
									width: 180,
									borderRadius: 10,
									border:
										state.selected === state.alltypes[1]
											? "solid 2px #0078ff"
											: "solid 2px #dbdee6",
									backgroundImage: `url(${mapLayer})`,
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									backgroundSize: "cover",
									cursor: "pointer",
								}}
							>
								<img
									src={oneDir}
									alt=""
									style={{
										height: 150,
										// width: 150,
									}}
								/>
								{state.selected === state.alltypes[1] ? (
									<img
										src={require("../../../assets/svg/filters_icons/tick_ic.svg")}
										style={{ marginTop: 25, width: 26, height: 26 }}
									/>
								) : (
									<div
										style={{
											height: 26,
											marginTop: 25,
											width: 26,
											borderRadius: 14,
											border: "solid 2px #dbdee6",
										}}
									></div>
								)}
							</div>
							<h2
								className="dromon-l text-center mt-2"
								style={{
									fontSize: 16,
									fontWeight: 300,
									color:
										state.selected === state.alltypes[1]
											? "#0078ff"
											: "#222c4b",
								}}
							>
								{languages[currentLanguage][
									state.alltypes[1]?.replaceAll(":", "_")
								]
									? languages[currentLanguage][
											state.alltypes[1]?.replaceAll(":", "_")
									  ]
									: state.alltypes[1]}
							</h2>
						</div>
					</div>
				</div>
				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Fourth);

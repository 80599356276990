import React from "react";
import copy from "../../assets/svg/addPlace/copy-ic.svg";
import close from "../../assets/close.svg";
import compass from "../../assets/svg/addPlace/directions-ic.svg";

// STYLES
import { makeStyles } from "@material-ui/core";
import { filterHighlightColor } from "../../constants/colors";
export const useStyles = makeStyles((theme) => ({
	progress: {
		color: "#0078ff",
	},
}));

const PopupAdd = (props) => {
	// STYLES
	const classes = useStyles();

	// STATES
	const { handleClick } = props;
	const [lon, lat] = props.Location;

	const coordinateToDMS = (latitude = Number, longitude = Number) => {
		let latDegrees = parseInt(latitude);
		let longDegrees = parseInt(longitude);
		// LATITUDE
		let latMinutesDouble = (latitude - parseFloat(latDegrees)) * 60;
		let latMinutes = parseInt(latMinutesDouble);
		let latSeconds = parseInt((latMinutesDouble - parseFloat(latMinutes)) * 60);
		// LONGITUDE
		let longMinutesDouble = (longitude - parseFloat(longDegrees)) * 60;
		let longMinutes = parseInt(longMinutesDouble);
		let longSeconds = parseInt(
			(longMinutesDouble - parseFloat(longMinutes)) * 60
		);
		let ns = latDegrees >= 0 ? "N" : "S";
		let ew = longDegrees >= 0 ? "E" : "W";
		let latValue =
			parseInt(latDegrees) + "°" + latMinutes + "'" + latSeconds + '"' + ns;
		let longValue =
			parseInt(longDegrees) + "°" + longMinutes + "'" + longSeconds + '"' + ew;
		return (latValue + "  " + longValue).trim();
	};

	// EFFECTS
	React.useEffect(() => {}, []);

	return (
		<div className="p-0 popup">
			<>
				<div
					className="text-center d-flex flex-column align-items-center justify-content-center h-100 px-4 dromon-l"
					style={{ flex: 1 }}
				>
					<img
						src={close}
						onClick={props.handleClose}
						style={{
							cursor: "pointer",
							position: "absolute",
							top: 20,
							right: 20,
							height: 14,
							width: 14,
						}}
					/>
					<div className="d-flex justify-content-center align-items-center mb-3">
						<h3
							className="mb-0"
							style={{
								fontSize: 18,
								color: "#222c4b",
								lineHeight: 1,
							}}
						>
							{props.title}
						</h3>
					</div>
					<div
						className="d-flex w-100 flex-row justify-content-between align-items-center py-2"
						style={{ fontSize: 12, color: "#8f96a7" }}
					>
						<img src={compass} style={{ height: 14, width: 14 }} />
						<h4
							className="mb-0"
							style={{
								fontSize: 12,
								color: "#9fa5b4",
								lineHeight: 1,
							}}
						>
							{coordinateToDMS(lat, lon)}
						</h4>
						<img src={copy} style={{ height: 14, width: 14 }} alt="" />
					</div>
					<div
						onClick={handleClick}
						className="d-flex mt-3 justify-content-center align-items-center"
						style={{
							backgroundColor: filterHighlightColor,
							width: 100,
							height: 35,
							borderRadius: 4,
							color: "white",
							cursor: "pointer",
						}}
					>
						{props.btn}
					</div>
				</div>
			</>
		</div>
	);
};

export default PopupAdd;

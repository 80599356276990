import React from "react";
import { connect, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { IconButton } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { exportToXLSX } from "../../helpers/functions";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { userData, userProfileId } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import { closeUserSidebars } from "../../store/features/user/actions";

// CONSTANTS
import {
	colorInactiveItem,
	filterHighlightColor,
} from "../../constants/colors";

// COMPONENTS
import CustomIncreaseDecrease from "./components/CustomIncreaseDecrease";
import SideBar from "../utils/SideBar";

// SVG ICONS
import FullExportListIcon from "../../assets/svg/crewList/full_export_list_ic.svg";

// STYLES
import "react-confirm-alert/src/react-confirm-alert.css";

const ProvisionList = ({
	visible = false,
	trip = {},
	deadlineReached = false,
	tmpProducts = [],
	handleProductsList = () => {},
	onOpen = () => {},
	onClose = () => {},
}) => {
	// SELECTORS
	const currentUserData = useSelector(userData),
		selectedUserProfileId = useSelector(userProfileId),
		currentLanguage = useSelector(currentLang);

	// STATES
	const [notice, setNotice] = React.useState({ type: "", content: "" });
	const [productList, setProductList] = React.useState([]);
	const [editableProductList, setEditableProductList] = React.useState([]);
	const [inEdition, setInEdition] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [isCompleted, setIsCompleted] = React.useState(false);
	const [role, setRole] = React.useState("");
	const [canEdit, setCanEdit] = React.useState(false);
	const [canFinalize, setCanFinalize] = React.useState(false);
	const [res, setRes] = React.useState({});
	const [tempPreSelected, setTempPreSelected] = React.useState([]);

	// REFS
	const sidebarContentRef = React.useRef(null);

	// FUNCTIONS
	const onCancel = () => {
		setInEdition(false);
		setEditableProductList(productList);
	};

	const onSave = async (data = null) => {
		setInEdition(false);
		setLoading(true);
		const isA = Array.isArray(data);
		const dataToSend = isA
			? data
			: editableProductList.map((item) => {
					return {
						ProductId: item.ProductID,
						Quantity: item.Quantity,
						PreferenceNote: item.PreferenceNote,
					};
			  });
		ApiClient.addUpdateProvisionList(trip.Id, dataToSend)
			.then((res) => {
				loadData();
				setLoading(false);
				if (isA) {
					setNotice({
						type: "WARNING",
						content: languages[currentLanguage]["trip_suggested_products"],
					});
					setInEdition(true);
					setTimeout(() => {
						setNotice({ type: "", content: "" });
					}, 6000);
				}
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
				setLoading(false);
			});
	};

	const loadData = async () => {
		setLoading(true);
		const creator = trip.Members.filter((u) => u.Role === "creator")[0];
		var tmpArrPreselected = [];
		if (tempPreSelected.length > 0) {
			tmpArrPreselected = tempPreSelected;
		} else {
			await ApiClient.getPartnerProvisionList(creator?.UserId).then((resp) => {
				setRes(resp);
				var arr = [];
				for (let i in resp) {
					for (let j in resp[i]) {
						const item = resp[i][j];
						if (item.StarterPack) {
							let newItem = {
								Quantity: 1,
								...item,
							};
							tmpArrPreselected.push(newItem);
						}
					}
				}
			});
		}

		if (tmpArrPreselected.length > 0) {
			setTempPreSelected(tmpArrPreselected);
		}

		ApiClient.getTripProvisionList(trip.Id)
			.then((res) => {
				if (res.ErrorCode && res.ErrorMessage) {
					setNotice({
						type: "ERROR",
						content: res.ErrorMessage,
					});
					return;
				}
				let data = res.Data;
				if (data.length === 0 && tmpArrPreselected.length > 0) {
					const dataToSend = tmpArrPreselected.map((item) => {
						return {
							ProductId: item.Id,
							Quantity: item.Quantity,
							PreferenceNote: "",
						};
					});
					onSave(dataToSend);
					return;
				}

				let editableData = editableProductList.length
					? editableProductList
					: data;
				var tmpData = tmpProducts;

				setProductList(data);
				setIsCompleted(res.IsCompleted);

				if (tmpData && tmpData.length) {
					for (let i in editableData) {
						tmpData = tmpData.filter((item, j) => {
							let bool = item.Id === editableData[i].ProductID;
							if (bool) editableData[i].Quantity += item.Quantity;
							return !bool;
						});
					}
				}

				setEditableProductList(
					editableData.concat(
						tmpData.map((item) => {
							return {
								ProductID: item.Id,
								...item,
							};
						})
					)
				);

				const user = trip.Members.filter(
					(u) => u.UserId === currentUserData?.ID
				)[0];
				let role = user ? user?.Role : "no-member";
				setRole(role);
				let canUserEdit =
					role === "creator" ||
					role === "hostess" ||
					role === "chef" ||
					role === "superpassenger";

				let conditionForEdition = trip?.State === "onboarding" && canUserEdit;
				setCanEdit(conditionForEdition);
				setCanFinalize(role === "creator" || role === "superpassenger");
				if (!conditionForEdition) {
					setIsCompleted(true);
				}
				if (deadlineReached && role === "superpassenger") {
					setCanFinalize(false);
					setCanEdit(false);
					setIsCompleted(true);
					setNotice({
						content:
							"You are past the deadline for completing the list. You can no longer make changes.",
						type: "WARNING",
					});
				}
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
			})
			.finally(() => {
				onOpen();
				setLoading(false);
			});
	};

	const exportData = () => {
		const dataToSend = editableProductList.map((item) => {
			return {
				Quantity: item.Quantity,
				Name: item.Name,
				Category: item.Category,
				Packaging: item.Packaging,
				PreferenceNote: item.PreferenceNote,
			};
		});
		exportToXLSX(dataToSend, "Provision_list");
	};

	const orderNow = async () => {
		if (role === "superpassenger") {
			confirmAlert({
				title: "Confirmation",
				message: languages[currentLanguage]["trip_confirmation_action"],
				buttons: [
					{
						label: "Yes",
						onClick: () => sendOrder(),
					},
					{
						label: "No",
					},
				],
			});
			return;
		}
		sendOrder();
	};

	const sendOrder = async () => {
		setLoading(true);
		ApiClient.updateProvisionListFlag(trip.Id, !isCompleted)
			.then((res) => {
				loadData();
			})
			.catch((error) => {
				//console.log("ERROR ==>", error);
			})
			.finally(() => {
				onOpen();
				setLoading(false);
			});
	};

	React.useEffect(() => {
		setLoading(true);
		if (visible) loadData();
		if (!visible) {
			setProductList([]);
			setEditableProductList([]);
		}
	}, [visible, selectedUserProfileId]);

	return (
		<>
			<SideBar
				visible={visible}
				useBackBtn={true}
				back_title={trip?.Name}
				back_subTitle={languages[currentLanguage]["trip_provision_list"]}
				footerButtons={
					canEdit &&
					(inEdition
						? [
								{
									label: languages[currentLanguage]["common_cancel"],
									props: {
										onClick: onCancel,
										style: { color: colorInactiveItem },
									},
								},
								{
									label: (
										<>
											<i className="fa fa-fw fa-save mr-2" />{" "}
											{languages[currentLanguage]["common_save"]}
										</>
									),
									props: { onClick: onSave },
								},
						  ]
						: (!isCompleted && canFinalize) ||
						  (isCompleted && role === "creator")
						? [
								{
									label: !isCompleted
										? languages[currentLanguage][
												"trip_provision_list_order_now"
										  ]
										: languages[currentLanguage]["trip_provision_list_cancel"],
									props: {
										onClick: orderNow,
										style: {
											color: !isCompleted ? filterHighlightColor : "white",
											backgroundColor: !isCompleted ? "white" : "red",
										},
									},
								},
						  ]
						: []
					).concat(
						!isCompleted
							? [
									{
										label:
											languages[currentLanguage][
												"trip_provision_list_add_more"
											],
										props: {
											onClick: () => {
												setInEdition(true);
												handleProductsList(res);
											},
										},
									},
							  ]
							: []
					)
				}
				fixedFooterButton={true}
				onClickBack={onClose}
				isLoading={loading}
				sidebarProps={{
					ref: sidebarContentRef,
				}}
			>
				{isCompleted &&
					(role === "creator" ||
						role === "hostess" ||
						role === "chef" ||
						role === "superpassenger" ||
						role === "basemanager") && (
						<div>
							<IconButton
								disabled={inEdition}
								style={{
									position: "absolute",
									zIndex: 11,
									top: 29,
									right: 15,
									opacity: inEdition ? 0.4 : 1,
								}}
								onClick={() => exportData()}
							>
								<img
									src={FullExportListIcon}
									style={{ height: 28, width: 28 }}
								/>
							</IconButton>
						</div>
					)}

				<div className="pt-3 px-3">
					<div>
						{(editableProductList ? editableProductList : []).map(
							(item, id) => (
								<CustomIncreaseDecrease
									key={id}
									item={{ ...item, Selected: !saveLoading }}
									displayRadio={false}
									setState={setEditableProductList}
									increaseDecreaseProps={{ Text }}
									onEdit={() => setInEdition(true)}
									isCompleted={isCompleted}
								/>
							)
						)}
					</div>
				</div>
			</SideBar>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvisionList);

import React from "react";
import { connect } from "react-redux";
import CountryTelData from "country-telephone-data";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// COMPONENTS
import CollapseCard from "../../utils/CollapseCard";
import CollapseCardHeader from "./CollapseCardHeader";
import CollapseCardBody from "./CollapseCardBody";

function CrewListCollapseCard({
	member = {},
	inEditing = null,
	inAdding = false,
	isLoadingSave = false,
	role = "no-member",
	canEdit = false,
	onEdit = () => {},
	onCancel = () => {},
	onSave = () => {},
	onDelete = () => {},
	onCollapse = () => {},
	collapseCardProps = {},
}) {
	// STATES
	const [form, setForm] = React.useState({ CodeNum: 0, ...member });

	// EFFECTS
	React.useEffect(() => {
		if (inEditing && form.CodeNum === 0 && !isEmpty(form.ContactNumber)) {
			const countries = CountryTelData.allCountries;
			let loop = true;
			let i = 0;

			while (loop && i < countries.length) {
				const code = `\\+${countries[i].dialCode}`;

				if (form?.ContactNumber?.match(code)) {
					const formattedCode = code.replace("\\", "");

					setForm({
						...form,
						CodeNum: formattedCode,
						ContactNumber: form.ContactNumber.replace(formattedCode, ""),
					});
					loop = false;
				}
				i++;
			}
		}
	}, [inEditing]);

	return (
		<CollapseCard
			{...{ ...collapseCardProps }}
			key={member.UserId}
			isCollapsed={member.Collapse}
			collapsable={!(inEditing || inAdding)}
			headerContent={
				<CollapseCardHeader
					{...{
						state: form,
						setState: setForm,
						member,
						inAdding,
						inEditing,
						role: role,
					}}
				/>
			}
			BodyContent={
				<CollapseCardBody
					{...{
						member,
						inEditing,
						inAdding,
						state: form,
						setState: setForm,
						isLoadingSave,
						onEdit,
						onCancel,
						onDelete,
						canEdit: canEdit,
						onSave: () =>
							onSave(
								{ ...form, ContactNumber: form.CodeNum + form.ContactNumber },
								member
							),
					}}
				/>
			}
			onCollapse={onCollapse}
		/>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CrewListCollapseCard);

export const filterHighlightColor = " #0078ff";
export const filterDisabledItemColor = "#e3e3e3";
export const bgActiveItem = "#c2ecff";
export const bgInactiveItem = "#f5f0f0";
export const borderColor = "#dbdee6";
export const colorInactiveItem = "#9e9898";
export const gray = "#9096a6";

export const whiteColor = "#fff";

export const experience = "#003E93";
export const shelter = "#222C4B";
export const fooddrink = "#CF8A35";
export const shopsservices = "#265E36";
export const lanchpoint = "#8A807A";
export const inspired = "#CD6E9E";
export const trips = "#CD6E9E";

export const notification = "#d80374";
export const headerTextColor = "#0a2463";
export const warningBorderColor = "#cd6e9e";
export const warningColor = "rgba(205, 110, 158, 0.2)";

export const secondaryTxt = "#8f96a7";
export const formMainTxt = "#222c4b";

export const deleteColor = "#eb5732";

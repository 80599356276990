import React from "react";
import { connect, useSelector } from "react-redux";
import { Button, FormControl, MenuItem, Select } from "@material-ui/core";
import dateFormat from "date-fns/format";
import Alert from "@material-ui/lab/Alert";

// API
import { ApiClient } from "../../ApiClient";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { userData } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import { closeUserSidebars } from "../../store/features/user/actions";

// HELPERS
import { isEmpty } from "../../helpers/functions";

// COMPONENTS
import SideBar from "../utils/SideBar";
import CustomIncreaseDecrease from "./components/CustomIncreaseDecrease";

const ProductsList = ({
	visible = false,
	trip = {},
	onAdd = () => {},
	onClose = () => {},
	res = {},
}) => {
	// SELECTORS
	const currentUserData = useSelector(userData),
		currentLanguage = useSelector(currentLang);

	// STATES
	const [loading, setLoading] = React.useState(false);
	const [notice, setNotice] = React.useState({ type: "", content: "" });
	const [allData, setAllData] = React.useState({});
	const [categoryList, setCategoryList] = React.useState([]);
	const [productList, setProductList] = React.useState({});
	const [currentCategory, setCurrentCategory] = React.useState("");

	// REFS
	const sidebarContentRef = React.useRef(null);

	// FUNCTIONS
	const loadData = () => {
		setAllData(res);
		var keys = [];
		for (var index in res) {
			keys.push(index);
			productList[index] = res[index].map((item) => {
				return {
					Quantity: 0,
					Selected: res?.StarterPack ? res?.StarterPack : false,
					...item,
				};
			});
		}
		setCategoryList(keys);
		if (keys.length > 0) {
			onSelectCategory(keys[0], res);
		}
		setLoading(false);
	};

	const nbrProd = () => {
		let counter = 0;
		for (let index in productList) {
			if (productList[index] && productList[index].length)
				productList[index].map((item) => {
					if (item.Selected) counter += item.Quantity;
				});
		}

		return counter;
	};

	const nbrProdPerCategory = (category = "") => {
		let counter = 0;

		if (productList[category] && productList[category].length)
			productList[category].map((item) => {
				if (item.Selected) counter += item.Quantity;
			});

		return counter;
	};

	const onAddAndCLose = async () => {
		const arr = [];

		for (let i in productList) {
			for (let j in productList[i]) {
				const item = productList[i][j];

				if (item.Selected) arr.push(item);
			}
		}

		onAdd(arr);
		onClose();
	};

	const onSelectCategory = (category = "", data = allData) => {
		if (isEmpty(category) || typeof category !== "string" || !data[category])
			return;

		setCurrentCategory(category);
	};

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		if (visible) loadData();
	}, [visible]);

	return (
		<>
			<SideBar
				visible={visible}
				isLoading={loading}
				sidebarProps={{
					ref: sidebarContentRef,
				}}
				useBackBtn={true}
				back_title={
					languages[currentLanguage]["trip_provision_list_add_products"]
				}
				footerButtons={[
					{
						label: `(${nbrProd()}) ${
							languages[currentLanguage]["trip_provision_add_to_list"]
						}`,
						props: { onClick: onAddAndCLose },
					},
				]}
				fixedFooterButton={true}
				onClickBack={onClose}
			>
				<div className="px-3">
					<div className="mt-2 mb-4">
						<div
							className="d-flex justify-content-between align-items-center deci"
							style={{ fontSize: 16 }}
						>
							<label>{languages[currentLanguage]["trip_category"]}:</label>

							<FormControl style={{ minWidth: 120 }} className="">
								<Select
									value={currentCategory}
									onChange={(item) => {
										//console.log(item.target.value, allData[item.target.value]);
										onSelectCategory(item.target.value, allData);
									}}
									displayEmpty
									inputProps={{ "aria-label": "Without label" }}
								>
									<MenuItem value="" disabled>
										Select a category
									</MenuItem>
									{categoryList?.map((item, id) => (
										<MenuItem key={id} value={item}>
											{nbrProdPerCategory(item)
												? `(${nbrProdPerCategory(item)}) `
												: ""}
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>

					<div>
						{(productList[currentCategory]
							? productList[currentCategory]
							: []
						).map((item, id) => (
							<CustomIncreaseDecrease
								key={id}
								item={item}
								displayRadio={true}
								currentCategory={currentCategory}
								setState={setProductList}
							/>
						))}
					</div>
				</div>
			</SideBar>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);

import { formatISODuration } from "date-fns";
import { toast } from "react-toastify";

// HELPERS
import { ApiClient } from "../../../ApiClient";
import { isEmpty, isFloat } from "../../../helpers/functions";

// COMPONENTS
import { startAt } from "../../../components/Itinerary/components/NoEditDayState";

// STORE
import { languages } from "../translation/Languages/";
import {
	SET_MAP_FILTERS,
	SET_MAP_FILTERS_SELECTED,
	SET_PLACE_ATTRIBUTES,
	SET_GLOBAL_WEATHER_BRAND_VISIBILITY,
	SET_GLOBAL_WEATHER_DATE,
	SET_WEATHER,
	// TRIP NAMESPACE
	SET_TRIP_ATTRIBUTES,
	SET_CURRENT_TRIP_ITINERARY,
	SET_TRIP_ITINERARY_VISIBILITY,
	SET_TRIP_ITINERARY_DAYS_LIST,
	ADD_NODE_TO_TRIP_ITINERARY,
	REPLACE_SHELTER_NODE,
	TOGGLE_SHELTER_NODE,
	REMOVE_NODE_TO_TRIP_ITINERARY,
	UPDATE_TRIP_ITINERARY_NODE_DURATION,
	UPDATE_INDEX_TRIP_DAY,
	UPDATE_MIDDLE_NODES,
} from "./types";

const currentLanguage = window.localStorage.getItem("language")
	? JSON.parse(window.localStorage.getItem("language")).language
	: "EN";

const formatDate = (time) => {
	const [hours, minutes] = time.split(":");
	return `PT${hours}H${minutes}M`;
};

export async function getMapFilters() {
	return ApiClient.getFilters()
		.then((filters) => {
			if (filters)
				return {
					type: SET_MAP_FILTERS,
					payload: filters,
				};
			return {
				type: null,
			};
		})
		.catch((err) => {
			return {
				type: null,
			};
		});
}

export async function getTripAttributes() {
	return ApiClient.getTripAttributes()
		.then((attributes) => {
			if (attributes)
				return {
					type: SET_TRIP_ATTRIBUTES,
					payload: attributes,
				};
			return {
				type: null,
			};
		})
		.catch((err) => {
			return {
				type: null,
			};
		});
}

export async function retrieveTripDaysList(tripId) {
	try {
		return ApiClient.getTripDaysList(tripId)
			.then((daysList) => {
				if (!daysList.ErrorCode && !daysList.ErrorMessage)
					return {
						type: SET_TRIP_ITINERARY_DAYS_LIST,
						payload: daysList,
					};
				return { type: null, daysList };
			})
			.catch((err) => {
				return {
					type: null,
					error: err,
				};
			});
	} catch (error) {
		//console.log(error);
		return {
			type: null,
		};
	}
}

export async function setTripItinerary(tripData = {}, combine = false) {
	try {
		if (!isEmpty(tripData))
			return {
				type: SET_CURRENT_TRIP_ITINERARY,
				payload: tripData,
				combine: !!combine,
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function addTripNodeEditing(
	pointData = {},
	forceLastNode = false,
	callbackIfNoTrips = () => {}
) {
	try {
		if (!isEmpty(pointData))
			return {
				type: ADD_NODE_TO_TRIP_ITINERARY,
				payload: pointData,
				forceLastNode,
				callbackIfNoTrips,
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function replaceShelterNode(pointData = {}) {
	try {
		if (!isEmpty(pointData))
			return {
				type: REPLACE_SHELTER_NODE,
				payload: {
					...pointData,
				},
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function toggleShelterNode() {
	try {
		return {
			type: TOGGLE_SHELTER_NODE,
		};
	} catch (error) {
		return { type: null };
	}
}

export async function updateMiddleNodes(middleNodes = []) {
	try {
		if (!isEmpty(middleNodes))
			return {
				type: UPDATE_MIDDLE_NODES,
				payload: middleNodes,
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function removeTripNodeEditing(nodeData = {}, idxNode) {
	try {
		if (!isEmpty(nodeData))
			return {
				type: REMOVE_NODE_TO_TRIP_ITINERARY,
				payload: {
					...nodeData,
					idxNode,
				},
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function updateNodeTripDuration(
	nodeIndex = Number,
	numberToUpdate = Number
) {
	try {
		if (typeof nodeIndex === "number" && typeof numberToUpdate === "number")
			return {
				type: UPDATE_TRIP_ITINERARY_NODE_DURATION,
				nodeIndex,
				numberToUpdate,
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function updateIndexTripDay(newIndexDay = Number) {
	try {
		if (typeof newIndexDay === "number")
			return {
				type: UPDATE_INDEX_TRIP_DAY,
				payload: newIndexDay,
			};
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function calculateDayTrip(tripItinerarySelected = Object) {
	try {
		if (
			typeof tripItinerarySelected === "object" &&
			!isEmpty(tripItinerarySelected)
		) {
			let currentTripItinerary = JSON.parse(
				JSON.stringify(tripItinerarySelected)
			);
			let tripItineraryDaySelected = currentTripItinerary.selectedDay;
			let isCircularTrip;
			let isOneWayTrip = false;

			const isOneDayTrip = currentTripItinerary?.data?.DurationDays === 1;
			const isLastDayTrip =
				currentTripItinerary?.daysList?.length ===
				tripItineraryDaySelected.index + 1;
			const launchPointFirstDay = currentTripItinerary?.daysList[0]?.Nodes[0];

			(currentTripItinerary?.data?.Attributes || []).map((item) => {
				const regCircular = new RegExp(/circular/, "ig");
				const regOneWay = new RegExp(/direction:direct/, "ig");
				if (regCircular.test(item)) isCircularTrip = true;
				if (regOneWay.test(item)) isOneWayTrip = true;

				return item;
			});

			let initialStateDay = {
				visibility: false,
				data: null,
				selectedDay: {
					index: null,
					startTime: "08:30",
					inEditing: true,
					launchPoint: null,
					tripNodes: [],
					shelterPoint: null,
					selectedNode: {
						visibility: false,
						index: null,
						note: null,
					},
				},
				daysList: [],
			};

			let dayToCalculate = {
				Day: tripItineraryDaySelected.index + 1,
				StartTime: formatDate(tripItineraryDaySelected.startTime),
				Nodes: [],
			};

			tripItineraryDaySelected = {
				...tripItineraryDaySelected,
				tripNodes: tripItineraryDaySelected.tripNodes.map((item) => {
					return {
						...item,
						Duration: formatISODuration({
							hours: Math.floor(item.Duration),
							minutes: isFloat(item.Duration)
								? (60 * parseInt((item.Duration % 1).toFixed(2).substring(2))) /
								  100
								: 0,
						}),
					};
				}),
			};

			const newMiddleNodes = [];
			tripItineraryDaySelected.tripNodes.map(
				(node, idxNode) =>
					!(
						tripItineraryDaySelected?.tripNodes?.length - 1 === idxNode &&
						node.Need === "lanchpoint"
					) &&
					newMiddleNodes.push({
						Point: node.Point,
						Need: node.Need,
						Note: node.Note,
						PlaceId: node.PlaceId,
						Duration: node.Duration,
					})
			);

			dayToCalculate = {
				...dayToCalculate,
				Nodes: dayToCalculate.Nodes.concat([
					{
						Point: tripItineraryDaySelected?.launchPoint?.Point,
						Need: tripItineraryDaySelected?.launchPoint?.Need,
						Note: tripItineraryDaySelected?.launchPoint?.Note,
						PlaceId: tripItineraryDaySelected?.launchPoint?.PlaceId,
					},
				])
					.concat(newMiddleNodes)
					.concat(
						isCircularTrip && isOneDayTrip
							? [
									{
										Point: tripItineraryDaySelected?.launchPoint?.Point,
										Need: tripItineraryDaySelected?.launchPoint?.Need,
										Note: tripItineraryDaySelected?.launchPoint?.Note,
										PlaceId: tripItineraryDaySelected?.launchPoint?.PlaceId,
									},
							  ]
							: isCircularTrip && isLastDayTrip && launchPointFirstDay
							? [
									{
										Point: launchPointFirstDay?.Point,
										Need: launchPointFirstDay?.Need,
										Note: launchPointFirstDay?.Note,
										PlaceId: launchPointFirstDay?.PlaceId,
									},
							  ]
							: [
									{
										Point: tripItineraryDaySelected?.shelterPoint?.Point,
										Need:
											isOneWayTrip && isLastDayTrip
												? "lanchpoint"
												: tripItineraryDaySelected?.shelterPoint?.Need,
										Note: tripItineraryDaySelected?.shelterPoint?.Note,
										PlaceId: tripItineraryDaySelected?.shelterPoint?.PlaceId,
									},
							  ]
					),
			};
			let newDaysList = null;
			let haveError = false;
			var errorMessage = "";

			await ApiClient.setTripItineraryDay(
				currentTripItinerary.data.Id,
				dayToCalculate
			)
				.then((res) => {
					if (res.ErrorCode || res.ErrorMessage) {
						errorMessage = languages[currentLanguage][res.ErrorCode]
							? languages[currentLanguage][res.ErrorCode]
							: languages[currentLanguage][res.ErrorMessage]
							? languages[currentLanguage][res.ErrorMessage]
							: res.ErrorMessage
							? res.ErrorMessage
							: "Can't save day";

						if (res.ErrorCode === "uri:KeeanoWS:TripMaxDayDurationExceeded") {
							errorMessage =
								languages[currentLanguage][
									"trip_itinerary_tripmaxdayduration_error_msg"
								];
						}
						return (haveError = true);
					}
					newDaysList = JSON.parse(
						JSON.stringify(
							(currentTripItinerary.daysList[tripItineraryDaySelected.index] =
								res)
						)
					);
				})
				.catch((err) => {
					//console.log(err);
					haveError = true;
				});

			if (!haveError) {
				await ApiClient.getTripDaysList(currentTripItinerary.data.Id)
					.then((daysList) => {
						if (daysList)
							newDaysList = daysList.map((dayObject, idxDay) => {
								return {
									...dayObject,
									Nodes:
										dayObject?.Nodes?.map((nodeDay) => {
											return {
												...nodeDay,
												Name: { Latin: nodeDay.Place?.Name },
												CoverImage: nodeDay.Place?.Cover,

												// REQUIRED
												PlaceId: nodeDay.Place?.Id,
											};
										}) || dayObject.Nodes,
									EditableNodes:
										dayObject?.Nodes?.filter(
											(item, idx) => item.Type !== "sail"
										)?.map((nodeDay) => {
											return {
												...nodeDay,
												//Name: { Latin: nodeDay.Place?.Name },
												Type: nodeDay.Place?.Type,
												CoverImage: nodeDay.Place?.Cover,
												//Order: nodeDay.Order,

												// REQUIRED
												Point: nodeDay.Point || nodeDay.Line,
												Duration: nodeDay.Duration
													? startAt(nodeDay.Duration).getHours() +
													  Math.round(
															(startAt(nodeDay.Duration).getMinutes() / 60) *
																100
													  ) /
															100
													: 2,
												Note: nodeDay.Note,
												//Need: nodeDay.Need,
												PlaceId: nodeDay.Place?.Id,
											};
										}) || dayObject.Nodes,
								};
							});
					})
					.catch((err) => {});

				if (newDaysList) {
					const indexDay = tripItineraryDaySelected.index;

					if (typeof indexDay === "number") {
						let tripDay = newDaysList[indexDay];

						if (!isEmpty(tripDay)) {
							currentTripItinerary = {
								...currentTripItinerary,
								selectedDay: {
									index: indexDay,
									inEditing: true,
									launchPoint: null,
									tripNodes: [],
									shelterPoint: null,
									startTime: `${
										startAt(newDaysList[0]?.StartTime).getHours() >= 10
											? startAt(newDaysList[0]?.StartTime).getHours()
											: `0${startAt(newDaysList[0]?.StartTime).getHours()}`
									}:${
										startAt(newDaysList[0]?.StartTime).getMinutes() >= 10
											? startAt(newDaysList[0]?.StartTime).getMinutes()
											: `0${startAt(newDaysList[0]?.StartTime).getMinutes()}`
									}`,
								},
								daysList: newDaysList,
							};

							if (!isEmpty(tripDay?.EditableNodes)) {
								if (
									tripDay?.EditableNodes[0]?.Need === "lanchpoint" ||
									tripDay?.EditableNodes[0]?.Need === "launchpoint" ||
									tripDay?.EditableNodes[0]?.Need === "shelter"
								)
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...currentTripItinerary.selectedDay,
											launchPoint: tripDay?.EditableNodes[0],
										},
									};
								if (tripDay?.EditableNodes?.length > 1) {
									const tripNodes = tripDay?.EditableNodes.slice(
										1,
										tripDay.EditableNodes.length
									);
									if (
										tripNodes[tripNodes.length - 1]?.Need === "shelter" ||
										tripNodes[tripNodes.length - 1]?.Need === "lanchpoint" ||
										tripNodes[tripNodes.length - 1]?.Need === "launchpoint"
									) {
										currentTripItinerary = {
											...currentTripItinerary,
											selectedDay: {
												...currentTripItinerary.selectedDay,
												shelterPoint: tripNodes[tripNodes.length - 1],
												tripNodes: tripNodes.filter(
													(item, idx) => idx !== tripNodes.length - 1
												),
												inEditing: false,
											},
										};
									} else
										currentTripItinerary = {
											...currentTripItinerary,
											selectedDay: {
												...currentTripItinerary.selectedDay,
												tripNodes,
												inEditing:
													tripNodes?.length > 1 && isCircularTrip
														? false
														: true,
											},
										};
								}
							}
							toast.success("Successfully updated trip!", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
							return await setTripItinerary(
								{
									...initialStateDay,
									...currentTripItinerary,
									selectedDay: {
										...currentTripItinerary.selectedDay,
										selectedNode: {
											index: null,
											visible: null,
											note: null,
										},
									},
								},
								false
							);
						} else {
							toast.error("Day not found", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
						}
					}
				}
				toast.warn("Can't set days list", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else {
				toast.error(
					errorMessage !== "" ? errorMessage : "Can't save new node",
					{
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}
		}
		return { type: null };
	} catch (error) {
		//console.log(error);
		return { type: null };
	}
}

export async function setTripItineraryVisibility(payload) {
	return {
		type: SET_TRIP_ITINERARY_VISIBILITY,
		payload,
	};
}

export async function getPlaceAttributes() {
	return ApiClient.getPlaceAttributes()
		.then((attributes) => {
			if (attributes)
				return {
					type: SET_PLACE_ATTRIBUTES,
					payload: attributes,
				};
			return {
				type: null,
			};
		})
		.catch((err) => {
			return {
				type: null,
			};
		});
}

export async function setMapFiltersSelected(filtersData) {
	return {
		type: SET_MAP_FILTERS_SELECTED,
		payload: filtersData,
	};
}

export async function setWeather(weatherData) {
	return {
		type: SET_WEATHER,
		payload: weatherData,
	};
}

export async function setGlobalWeatherDate(payload = String) {
	return {
		type: SET_GLOBAL_WEATHER_DATE,
		payload,
	};
}

export async function setGlobalWeatherBrandVisibility(payload) {
	return {
		type: SET_GLOBAL_WEATHER_BRAND_VISIBILITY,
		payload,
	};
}

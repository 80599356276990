import styled from "styled-components";
import { filterHighlightColor, whiteColor } from "../../constants/colors";

export const Container = styled.div`
  z-index: 20;
  padding: 50px 0;
	overflow-y: scroll;
	-ms-overflow-style: scrollbar;
	scrollbar-color:  #222c4b #FFF;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8f96a7;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #222c4b;
  }
`;

export const Form = styled.div`
  background-color: transparent;
  padding-top: 50px;
  width: 440px;
  border-radius: 0 0 20px 20px;
`;

export const FormContent = styled.div`
  background-color: ${whiteColor};
  border-radius: 0 0 20px 20px;
`;

import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import {
	Checkbox,
	FormControlLabel,
	TextField,
	withStyles,
	MenuItem,
} from "@material-ui/core";
// API
import { ApiClient } from "../../../ApiClient";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// CONSTANTS
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../../constants/colors";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";

// COMPONENTS
import MultiselectCheck from "../Components/MultiselectCheck";

// SVGs
import SvgHeader from "../../../assets/svg/tripWizard/kudos_ic.svg";
import SvgAddIcon from "../../../assets/review/add_ic.svg";

const Seventh = (props) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [state, setState] = useState({
		tripName: props.state?.Name ? props.state?.Name : "",
		description: props.state?.Description ? props.state?.Description : "",
		CharterId: props.state?.CharterId ? props.state?.CharterId : "",
		HasCharterListsEnabled: props.state?.HasCharterListsEnabled
			? props.state?.HasCharterListsEnabled
			: true,
	});
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [image, setimage] = useState({
		uri: props.state?.Cover ? props.state?.Cover : null,
		file: null,
	});
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [options] = useState([
		"trip_review_privacy_public",
		"trip_review_privacy_private",
	]);
	const [option, setOption] = useState("trip_review_privacy_private");
	const [needTransfer, setNeedTransfer] = useState(false);
	const [whenDate, setWhenDate] = useState(null);
	const [whereField, setWhereField] = useState(null);
	const [toField, setToField] = useState(null);
	const [numberField, setNumberField] = useState(0);
	const [phoneNumber, setPhoneNumber] = useState(null);

	// DATA
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};

	// REFS
	const fileInputRef = useRef();

	// FUNCTIONS
	const handleSelect = (op) => {
		setOption(op);
	};

	const loadImage = (e) => {
		const URI = URL.createObjectURL(e.target.files[0]);
		setimage({ uri: URI, file: e.target.files[0] });
	};

	const handleSubmitTrip = async () => {
		logEvent_("react_tripwiz_stepinfo_savebtn_pressed");
		if (state.CharterId?.length > 30) {
			setNotice({
				type: "WARNING",
				content: "Invalid Charter Id",
			});
			return;
		}

		if (state.tripName.trim().length >= 2) {
			let photo = null;

			if (image.uri && image.file) {
				setLoadingSubmit(true);

				await ApiClient.getPhotoServiceSignature().then((res) => (photo = res));
				await ApiClient.uploadUserPhoto(photo.UploadUrl, image.file);
			}

			setLoadingSubmit(true);
			props.handleSubmit({
				url: photo ? photo?.Url : image.uri ? image.uri : null,
				tripName: state.tripName,
				CharterId: state.CharterId?.length ? state.CharterId : null,
				description: state.description,
				public: option === options[0],
				needTransfer: needTransfer,
				transferDate: whenDate,
				transferWhere: whereField,
				transferTo: toField,
				transferPassengers: numberField,
				transferPhone: phoneNumber,
				EmailInvtations: state.EmailInvtations,
				IsCrewed: state.IsCrewed,
				HasCharterListsEnabled: state.HasCharterListsEnabled,
			});
			setTimeout(() => {
				setLoadingSubmit(false);
			}, 1000);
		} else {
			setNotice({
				type: "WARNING",
				content: "Enter the name of the trip",
			});
		}
	};

	// EFFECTS
	useEffect(() => {
		setState({
			tripName: props.state?.Name ? props.state?.Name : "",
			description: props.state?.Description ? props.state?.Description : "",
			CharterId: props.state?.CharterId ? props.state?.CharterId : "",
			IsCrewed: props.state?.IsCrewed ?? false,
			HasCharterListsEnabled: props.state?.HasCharterListsEnabled ?? true,
		});
		setimage({
			uri: props.state?.Cover ? props.state?.Cover : null,
			file: null,
		});
		setNeedTransfer(false);
		setWhenDate(null);
		setWhereField(null);
		setToField(null);
		setNumberField(0);
		setPhoneNumber(null);
	}, [props.state, props.finished]);

	// LOCAL COMPONENTS
	const CustomCheckbox = withStyles({
		root: {
			color: "#0078ff",
			"&$checked": {
				color: "#0078ff",
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	return (
		<>
			<div
				className={
					props.visible
						? "px-0 d-flex flex-column justify-content-space-between step-body"
						: "d-none"
				}
				style={{
					width: 384,
					marginBottom: 300,
				}}
			>
				<div className="d-flex justify-content-center align-items-center mb-3">
					<img
						src={SvgHeader}
						style={{ height: 80, width: 80, marginTop: -15, marginBottom: 50 }}
						alt=""
					/>
				</div>

				<div className="mb-3">
					<h3
						className="mb-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						{props.state?.Type === "business" &&
						props.state?.State !== "template"
							? languages[currentLanguage]["trip_create_new_business_trip"]
							: languages[currentLanguage]["trip_create_new_template_trip"]}
					</h3>

					<h2
						className="text-center"
						style={{ fontSize: 22, color: "#222c4b" }}
					>
						{languages[currentLanguage]["trip_description_header"]}
					</h2>
				</div>

				<div style={{ width: 384 }}>
					<h3 className="mb-3" style={titleStyle}>
						{languages[currentLanguage]["trip_description_tripname"]}
					</h3>
					<input
						className="col-12 inp-focus-BP"
						style={{ height: 50 }}
						value={state.tripName}
						placeholder="Name your trip in something memorable"
						onChange={(e) => setState({ ...state, tripName: e.target.value })}
					/>
					{props.state?.Type === "business" &&
						props.state?.State !== "template" && (
							<div style={{ marginTop: 15 }}>
								<h3 className="mb-3" style={titleStyle}>
									Charter ID
								</h3>
								<input
									className="col-12 inp-focus-BP"
									style={{ height: 50 }}
									value={state.CharterId}
									placeholder=""
									onChange={(e) =>
										setState({ ...state, CharterId: e.target.value })
									}
								/>
							</div>
						)}
					<h3
						className="mt-4 p-0 col-12"
						style={{ color: formMainTxt, fontSize: 14 }}
					>
						{languages[currentLanguage]["trip_description_coverphoto"]}
					</h3>
					<div className="row px-3 mb-4" style={{}}>
						<button
							style={{
								marginTop: 14,
								height: 137,
								backgroundImage: image?.uri ? `url(${image?.uri})` : "",
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								width: 380,
								borderRadius: 5,
								backgroundColor: !image?.uri ? whiteColor : "transparent",
								border: "1px dashed " + secondaryTxt,
							}}
							onClick={() => fileInputRef.current.click()}
						>
							{!image.uri && (
								<>
									<img src={SvgAddIcon} style={{ height: 14, width: 14 }} />
									<h3
										className="m-0 dromon-l"
										style={{
											color: formMainTxt,
											fontSize: 14,
											color: "#8f96a7",
										}}
									>
										{
											languages[currentLanguage][
												"trip_description_coverphoto_placeholder"
											]
										}
									</h3>
								</>
							)}
						</button>
						<input
							onChange={loadImage}
							multiple={false}
							ref={fileInputRef}
							type="file"
							hidden
						/>
					</div>

					<div className="mb-4 container">
						<div className="row">
							<h3
								className="mt-3 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["trip_description_description"]}
							</h3>
							<div className="">
								<textarea
									className="p-2"
									style={{ marginTop: 14, height: 107, width: 380 }}
									value={state.description}
									onChange={(e) =>
										setState({
											...state,
											description: e.target.value,
										})
									}
									placeholder={
										languages[currentLanguage][
											"trip_description_description_placeholder"
										]
									}
								/>
							</div>
						</div>
					</div>
					{(props.state?.State === "template" ||
						props.state?.State === null) && (
						<div className="mb-3">
							<h3
								className="mt-3 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["trip_review_privacy_header"]}
							</h3>
							<div>
								{options.map((op) => (
									<MultiselectCheck
										handleSelect={() => handleSelect(op)}
										text={
											languages[currentLanguage][op.replaceAll(":", "_")]
												? languages[currentLanguage][op.replaceAll(":", "_")]
												: op
										}
										key={op}
										selected={option === op}
									/>
								))}
							</div>
						</div>
					)}

					{props.state?.Type === "business" &&
						props.state?.State !== "template" && (
							<div style={{ marginTop: 15, marginBottom: 25 }}>
								{props.state?.State === "clone" && (
									<>
										<h3 className="mb-3" style={titleStyle}>
											Email Notification:
										</h3>
										<div className="d-flex flex-row justify-content-between px-0 mb-3">
											<div style={{ width: 150 }}>
												<TextField
													select
													label="Role"
													value={
														state.EmailInvtations
															? state.EmailInvtations[0]?.Role
															: ""
													}
													className="w-100"
													onChange={(event) => {
														var EmailInvtations = state.EmailInvtations ?? [];
														if (
															EmailInvtations !== null &&
															EmailInvtations.length > 0
														) {
															EmailInvtations[0].Role = event.target.value;
														} else {
															EmailInvtations.push({
																Role: event.target.value,
															});
														}
														setState({
															...state,
															EmailInvtations: EmailInvtations,
														});
													}}
													variant="outlined"
													required={true}
													style={{ width: 80 }}
												>
													<MenuItem value="superpassenger">
														Superpassenger
													</MenuItem>
													<MenuItem value="cocreator">Cocreator</MenuItem>
												</TextField>
											</div>

											<TextField
												label="Email"
												type="email"
												variant="outlined"
												value={
													state.EmailInvtations
														? state.EmailInvtations[0]?.Email
														: ""
												}
												onChange={(event) => {
													var EmailInvtations = state.EmailInvtations ?? [];
													if (EmailInvtations && EmailInvtations.length > 0) {
														EmailInvtations[0].Email = event.target.value;
													} else {
														EmailInvtations.push({ Email: event.target.value });
													}
													setState({
														...state,
														EmailInvtations: EmailInvtations,
													});
												}}
											/>
										</div>

										{!!state.EmailInvtations?.length &&
											state.EmailInvtations[0]?.Role === "cocreator" && (
												<textarea
													className="w-100 p-2"
													style={{ height: 107 }}
													value={
														state.EmailInvtations !== null
															? state.EmailInvtations[0]?.BookingContact
															: ""
													}
													maxLength="100"
													onChange={(event) => {
														var EmailInvtations = state.EmailInvtations ?? [];
														if (
															EmailInvtations !== null &&
															EmailInvtations.length > 0
														) {
															EmailInvtations[0].BookingContact =
																event.target.value;
														} else {
															EmailInvtations.push({
																BookingContact: event.target.value,
															});
														}
														setState({
															...state,
															EmailInvtations: EmailInvtations,
														});
													}}
													placeholder="Booking Contact"
												/>
											)}

										<br></br>
									</>
								)}

								<div className="mb-2">
									<FormControlLabel
										control={
											<CustomCheckbox
												checked={state.HasCharterListsEnabled}
												onChange={(event) =>
													setState({
														...state,
														HasCharterListsEnabled: event.target.checked,
													})
												}
											/>
										}
										label="Charter lists"
										style={{ fontFamily: "Dromon-light" }}
										labelstyle={{
											fontFamily: "Dromon-light",
										}}
									/>
								</div>

								<div>
									<FormControlLabel
										control={
											<CustomCheckbox
												checked={state.IsCrewed}
												onChange={(event) =>
													setState({
														...state,
														IsCrewed: event.target.checked,
													})
												}
											/>
										}
										label="Is Crewed"
										style={{ fontFamily: "Dromon-light" }}
										labelstyle={{
											fontFamily: "Dromon-light",
										}}
									/>
								</div>
							</div>
						)}

					<div
						onClick={handleSubmitTrip}
						className="d-flex mb-4 justify-content-center align-items-center text-center"
						style={{
							position: "relative",
							height: 35,
							borderRadius: 5,
							cursor: "pointer",
							backgroundColor: filterHighlightColor,
						}}
					>
						<p
							className="mb-0 text-center"
							style={{ color: whiteColor, fontSize: 16 }}
						>
							{loadingSubmit ? (
								<i className="fa fa-circle-notch fa-spin mr-2" />
							) : (
								""
							)}{" "}
							{languages[currentLanguage]["common_saveclose"]}
						</p>
					</div>
				</div>
			</div>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Seventh);

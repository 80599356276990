import {
	DEFAULT_NEED,
	DEFAULT_PLACE_TYPE_TAG,
} from "../../../store/features/map/types";

export const handleFilters = (filtersSelected, myFilters) => {
	if (!filtersSelected.needs) {
		return;
	}
	var FILTERS = [];
	if (filtersSelected.places.length > 0) {
		if (
			filtersSelected.places.includes("business") ||
			filtersSelected.places.includes("community")
		) {
			window.localStorage.setItem(
				"tripMapType",
				JSON.stringify(filtersSelected.places[0])
			);
		} else {
			filtersSelected.places.forEach(function (eachSelectedPlace) {
				var tagsToApply = [];
				var allAppliedTags =
					myFilters[filtersSelected.vesselType][filtersSelected.needs]
						.appliedTags;
				if (allAppliedTags[eachSelectedPlace] !== null) {
					allAppliedTags[eachSelectedPlace].forEach(function (eachAppliedTag) {
						if (filtersSelected.tags.includes(eachAppliedTag)) {
							tagsToApply.push(eachAppliedTag);
						}
					});
					FILTERS.push({ key: eachSelectedPlace, value: tagsToApply });
				}
			});
		}
	}
	var val = [];
	val.push(["==", ["get", "isFromItinerary"], true]);

	if (FILTERS.length > 0) {
		FILTERS.forEach(function (dict) {
			let key = dict.key;
			var thisPlaceFilter = [];
			dict.value.forEach(function (eachValue) {
				thisPlaceFilter.push(["in", eachValue, ["get", "tags"]]);
			});
			if (thisPlaceFilter.length > 0) {
				val.push([
					"all",
					["match", ["get", "icon"], key, true, false],
					[
						"in",
						filtersSelected.needCategoryTag.length > 0
							? filtersSelected.needCategoryTag
							: DEFAULT_NEED,
						["get", "tags"],
					],
					...thisPlaceFilter,
				]);
			} else {
				val.push([
					"all",
					["match", ["get", "icon"], key, true, false],
					[
						"in",
						filtersSelected.needCategoryTag.length > 0
							? filtersSelected.needCategoryTag
							: DEFAULT_NEED,
						["get", "tags"],
					],
				]);
			}
		});
	} else if (filtersSelected.needs === "trips") {
		val = [];
		if (
			filtersSelected.vesselType === "motorboat" ||
			filtersSelected.vesselType === "smallboat"
		) {
			//val.push(["in", "uri:trip:tag:motorboattrip", ["get", "tags"]]);
		} else if (filtersSelected.vesselType === "kayak") {
			//val.push(["in", "uri:trip:tag:kayaktrip", ["get", "tags"]]);
		} else if (filtersSelected.vesselType === "sailboat") {
			//val.push(["in", "uri:trip:tag:sailboattrip", ["get", "tags"]]);
		}
		if (filtersSelected.tags.length > 0) {
			filtersSelected.tags.forEach(function (eachValue) {
				if (eachValue !== DEFAULT_PLACE_TYPE_TAG) {
					val.push(["in", eachValue, ["get", "tags"]]);
				}
			});
		}
	} else {
		if (
			filtersSelected.places.length > 0 &&
			filtersSelected.needs !== "trips"
		) {
			val.push([
				"all",
				["match", ["get", "icon"], filtersSelected.places, true, false],
				[
					"in",
					filtersSelected.needCategoryTag.length > 0
						? filtersSelected.needCategoryTag
						: DEFAULT_NEED,
					["get", "tags"],
				],
			]);
		} else if (filtersSelected.needs !== "trips") {
			val.push([
				"all",
				[
					"in",
					filtersSelected.needCategoryTag.length > 0
						? filtersSelected.needCategoryTag
						: DEFAULT_NEED,
					["get", "tags"],
				],
			]);
		}
	}
	let all = ["any", ...val];
	if (filtersSelected.needs === "trips") {
		if (val.length > 0) {
			filtersSelected.tripsPredicates = all;
		} else {
			filtersSelected.tripsPredicates = ["!=", ["get", "id"], 0];
		}
	} else {
		filtersSelected.allPredicates = all;
	}

	return filtersSelected;
};

export const onWeahterGlobalOpen = (filtersSelected) => {
	filtersSelected.allPredicates = [
		"match",
		["get", "icon"],
		["beach", "anchorages"],
		true,
		false,
	];
	return filtersSelected;
};

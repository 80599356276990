import React from "react";
import Radio from "@material-ui/core/Radio";
import {
	Input,
	IconButton,
	Select,
	FormControl,
	MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// CONSTANTS
import {
	filterHighlightColor,
	borderColor,
	colorInactiveItem,
} from "../../../constants/colors";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// COMPONENTS

// SVG ICONS
import starIcon from "../../../assets/svg/vesselIcons/start.svg";
import starFilledIcon from "../../../assets/svg/vesselIcons/star_filled.svg";
import deleteIcon from "../../../assets/svg/delete-ic-n.svg";
import xIcon from "../../../assets/close.svg";

export default function CustomIncreaseDecrease({
	item = {},
	props,
	categoryItems = [],
	setState = () => {},
	onEdit = () => {},
	onDelete = () => {},
	onUpdate = () => {},
}) {
	// DATA
	const initialFormValues = {
		Name: "",
		Id: "",
		Category: "",
		Packaging: "",
		StarterPack: false,
	};

	// STATES
	const [form, setForm] = React.useState(initialFormValues);
	const [inEdition, setInEdition] = React.useState(false);

	// REFS
	const nameRef = React.useRef(null);
	const packagingRef = React.useRef(null);
	const categoryRef = React.useRef(null);

	// EFFECTS
	React.useEffect(() => {
		setForm(item);
		return () => {};
	}, []);

	return (
		<div
			{...{
				...props,
				className: `d-flex align-items-stretch mb-4 ${
					props?.className ? props.className : ""
				}`,
			}}
		>
			<div className="d-flex justify-content-center align-items-center mr-2">
				<IconButton
					onClick={() => {
						setState((state) => {
							return state?.map((item_) => {
								return item_.Id === item.Id
									? {
											...item_,
											StarterPack: !item_.StarterPack,
									  }
									: item_;
							});
						});
						setForm((state) => {
							return {
								...state,
								StarterPack: !(item?.StarterPack ?? false),
							};
						});
						setInEdition(true);
					}}
					style={{
						width: 30,
						height: 30,
					}}
				>
					<img
						alt="delete icon"
						src={item?.StarterPack ? starFilledIcon : starIcon}
						style={{
							height: 18,
							width: 18,
							marginLeft: 1,
							userSelect: "none",
							pointerEvents: "none",
						}}
					/>
				</IconButton>
			</div>

			<div
				className="rounded p-2 mr-2"
				style={{
					flex: 1,
					border: `1px solid ${borderColor}`,
				}}
			>
				<div className="d-flex align-items-center mb-2">
					<FormControl className="">
						<Input
							placeholder="Name"
							inputProps={{
								"aria-label": "Name",
								ref: nameRef,
							}}
							onChange={(e) => {
								setForm((state) => {
									return {
										...state,
										Name: nameRef.current.value,
									};
								});
								setInEdition(true);
							}}
							value={form.Name}
							style={{ borderColor: filterHighlightColor }}
						/>
					</FormControl>
					<span className="mx-2">-</span>
					<FormControl className="">
						<Input
							placeholder="Packaging"
							inputProps={{
								"aria-label": "Packaging",
								ref: packagingRef,
							}}
							onChange={(e) => {
								setForm((state) => {
									return {
										...state,
										Packaging: packagingRef.current.value,
									};
								});
								setInEdition(true);
							}}
							value={form.Packaging}
							style={{ borderColor: filterHighlightColor }}
						/>
					</FormControl>
				</div>

				<div className="d-flex align-items-center">
					<FormControl className="mr-2">
						<Select
							value={form.Category}
							inputProps={{ itemRef: categoryRef }}
							style={{ minWidth: 85 }}
							onChange={(e) => {
								setForm((state) => {
									return {
										...state,
										Category: e.target.value || nameRef.current.value,
									};
								});
								setInEdition(true);
							}}
						>
							<MenuItem disabled>Category</MenuItem>
							{categoryItems?.map((category, id) => (
								<MenuItem key={id} value={category}>
									{category}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</div>

			<div
				className={`d-flex flex-column ${
					inEdition ? "justify-content-around" : "justify-content-center"
				}`}
			>
				{!inEdition ? (
					<IconButton
						onClick={() => {
							onDelete(item.Id);
						}}
						style={{
							width: 30,
							height: 30,
						}}
					>
						<img
							alt="delete icon"
							src={deleteIcon}
							style={{
								height: 18,
								width: 18,
								marginLeft: 1,
								userSelect: "none",
								pointerEvents: "none",
							}}
						/>
					</IconButton>
				) : (
					<>
						<IconButton
							onClick={() => {
								setForm(item);
								setInEdition(false);
							}}
							style={{
								width: 30,
								height: 30,
								color: filterHighlightColor,
							}}
						>
							<img
								alt="delete icon"
								src={xIcon}
								style={{
									height: 13,
									width: 13,
									marginLeft: 1,
									userSelect: "none",
									pointerEvents: "none",
								}}
							/>
						</IconButton>

						<IconButton
							onClick={() => {
								onUpdate(form);
								setInEdition(false);
							}}
							style={{
								width: 30,
								height: 30,
								color: filterHighlightColor,
							}}
						>
							<i className="fa fa-fw fa-save" style={{ fontSize: 18 }} />
						</IconButton>
					</>
				)}
			</div>
		</div>
	);
}

import React, { useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
	Button,
	IconButton,
	Tooltip,
	CircularProgress,
	Snackbar,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import { generatePlaceShareLink } from "../../shareConfig";
import branch from "branch-sdk";
import Alert from "@material-ui/lab/Alert";

// HELPERS
import { getAvg } from "./helpers/functions";
import { logEvent_ } from "../../helpers/analytics";
import {
	isBookmarked,
	REGISTRED,
	UNREGISTRED,
} from "../map/helpers/bookMarksHelper";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { convertWindSpeedToMyUnit } from "../../helpers/UnitConverter";

// API
import { AuthService } from "../../AuthService";
import { ApiClient } from "../../ApiClient";

// ACTIONS
import {
	showUserProfile,
	setUserProfileId,
	closeUserSidebars,
	showUserAuthView,
} from "../../store/features/user/actions";
import { setWeather } from "../../store/features/map/actions";
import {
	openReviewPage,
	openAddReviewPage,
} from "../../store/features/review/actions";

// SELECTORS
import { languages } from "../../store/features/translation/Languages/";
import { currentLang } from "../../store/features/translation/selectors";
import {
	filtersSelected,
	weather as getStoredWeather,
} from "../../store/features/map/selectors";
import { userSettings } from "../../store/features/user/selectors";

// COMPONENTS
import GalleryPhotos from "./GalleryPhotos";
import CoastViewPhotos from "./CoastViewPhotos";
import ReviewItem from "./Components/ReviewItem";

// STYLED
import { CoverImage, PhotoItem, Sidebar, SidebarContent } from "./styled";
import { filterHighlightColor, formMainTxt } from "../../constants/colors";
import { useStyles } from "../map/Popup";

// ICONS
import ContributorIcon from "../../assets/contributor-ic.svg";
import PhoneIc from "../../assets/phone-dark-ic.svg";
import WebLightIc from "../../assets/svg/web-light-ic.svg";
import RemoveTripIc from "../../assets/remove_fromtrip_ic.svg";
import Close from "../../assets/close-btn.svg";
import AddIcon from "../../assets/review/plus-icon.svg";
import AddToTripIcon from "../../assets/svg/itinerary/addtotrip_ic.svg";

const PlacesDetailsSideBar = ({
	state = {},
	show = false,
	openReviewPage,
	openAddReviewPage,
	setUserProfileId,
	showUserProfile,
	setStoreWeather = () => {},
	showUserAuthView = () => {},
	closeUserSidebars = () => {},
	addTripNodeEditing = () => {},
	openWizard = () => {},
	dispatch = () => {},
	closeSideBars = () => {},
}) => {
	// REFS
	const fileInputRef = useRef();

	// STYLES
	const classes = useStyles();

	// SELECTORS
	const weatherStored = useSelector(getStoredWeather);
	const filtersSelected_ = useSelector(filtersSelected);
	const currentLanguage = useSelector(currentLang);
	const currentUserSettings = useSelector(userSettings);

	// STATES
	const { id } = state;
	const [loadingAdd, setLoadingAdd] = useState(false);
	const [place, setPlace] = React.useState({});
	const [weatherPlace, setWeatherPlace] = React.useState([]);
	const [marinaTags, setMarinaTags] = React.useState([]);
	const [currentWeatherDateKey, setCurrentWeatherDateKey] =
		React.useState(null);
	const [currentWeatherDate, setCurrentWeatherDate] = React.useState({});
	const [galleryPhotos, setGalleryPhotos] = React.useState(null);
	const [coastViewPhotos, setCoastViewPhotos] = React.useState(null);
	const [generatedLink, setGeneratedLink] = React.useState(null);
	const [mode] = React.useState(
		AuthService.isUserLogedIn() ? REGISTRED : UNREGISTRED
	);
	const [snackbarClipboard, setSnackbarClipboard] = React.useState(false);
	const [shareDialog, setShareDialog] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [notice, setNotice] = React.useState({ type: "", content: "" });
	const [averageRating, setAverageRating] = React.useState(0);
	const [reviewers, setReviewers] = React.useState(0);

	// DATA
	const weatherDegree = [
		languages[currentLanguage]["place_details_weather_unsuitable"],
		languages[currentLanguage]["place_details_weather_warning"],
		languages[currentLanguage]["place_details_weather_suitable"],
	];

	const weatherColorDegree = ["#e75e32", "#f1df8d", "#3eff03"];

	// FUNCTIONS
	const onOpenReviewPage = (place) => {
		closeSidebar();
		openReviewPage(place);
	};

	const onOpenAddReview = (place) => {
		closeSidebar();
		openAddReviewPage(place);
	};

	const onShowUserProfile = (userId = null) => {
		closeSidebar();
		setUserProfileId(userId);
		showUserProfile();
	};

	const sharePlace = () => {
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.SHARE_INITIATED
		);
		logEvent_("react_placedetails_sharebutton_pressed");
		const linkData = generatePlaceShareLink(place);
		branch.link(linkData, function (err, link) {
			setGeneratedLink(link);
			setShareDialog(true);
		});
	};

	const copyToClipboard = (str = "") => {
		const el = window.document.createElement("textarea");
		el.value = str;
		window.document.body.appendChild(el);
		el.select();
		window.document.execCommand("copy");
		window.document.body.removeChild(el);
		navigator.clipboard.writeText(str);
		setSnackbarClipboard(true);
	};

	const handleDismiss = () => {
		var new_url = "https://" + window.location.host + "/explore/";
		window.history.pushState(null, null, new_url);
	};

	const coordinateToDMS = (latitude = Number, longitude = Number) => {
		let latDegrees = parseInt(latitude);
		let longDegrees = parseInt(longitude);
		// LATITUDE
		let latMinutesDouble = (latitude - parseFloat(latDegrees)) * 60;
		let latMinutes = parseInt(latMinutesDouble);
		let latSeconds = parseInt((latMinutesDouble - parseFloat(latMinutes)) * 60);
		// LONGITUDE
		let longMinutesDouble = (longitude - parseFloat(longDegrees)) * 60;
		let longMinutes = parseInt(longMinutesDouble);
		let longSeconds = parseInt(
			(longMinutesDouble - parseFloat(longMinutes)) * 60
		);
		let ns = latDegrees >= 0 ? "N" : "S";
		let ew = longDegrees >= 0 ? "E" : "W";
		let latValue =
			parseInt(latDegrees) + "°" + latMinutes + "'" + latSeconds + '"' + ns;
		let longValue =
			parseInt(longDegrees) + "°" + longMinutes + "'" + longSeconds + '"' + ew;
		return (latValue + "  " + longValue).trim();
	};

	const loadImage = (e) => {
		let photo = null;
		let file = e.target?.files[0];
		ApiClient.getPhotoServiceSignature().then((res) => {
			setLoadingAdd(true);
			photo = res; // url
			ApiClient.uploadUserPhoto(photo.UploadUrl, file).then((res) => {
				ApiClient.uploadPhotoPlace(place.ID, photo.Url)
					.then((res) => {
						if (!res?.ErrorCode && !res?.ErrorMessage) {
							setNotice({
								content:
									languages[currentLanguage][
										"place_details_photos_success_msg"
									],
								type: "SUCCESS",
							});
							setLoadingAdd(false);
							setLoading(true);
							init();
						} else {
							setNotice({
								content: res.ErrorMessage,
								type: "ERROR",
							});
							setLoadingAdd(false);
						}
					})
					.catch((error) => {
						setNotice({
							content: "Something went wrong, try again later",
							type: "ERROR",
						});
						setLoadingAdd(false);
					});
			});
		});
	};

	const closeSidebar = () => {
		setGalleryPhotos(null);
		setCoastViewPhotos(null);
		handleDismiss();
		dispatch({
			type: "closeSidebarPlaces",
		});
	};

	const moveToPlace = (coordinates = place.Location) => {
		dispatch({
			type: "moveMapTo",
			coordinates: coordinates,
		});
	};

	const isSmallBoat = () => {
		if (filtersSelected_.vesselType === "kayak") return true;
		if (
			filtersSelected_.vesselType === "sailboat" ||
			filtersSelected_.vesselType === "motorboat"
		) {
			if (parseInt(filtersSelected_.vesselLength) > 10) return false;
			return true;
		}
		return false;
	};

	const init = async () => {
		if (!!!id) return;
		closeUserSidebars();
		logEvent_("react_app_place_details_screen");
		try {
			await Promise.all([
				ApiClient.getPlaceDetails(id),
				ApiClient.getWeatherPlace(id),
				ApiClient.getPlaceReviews(id),
			]).then((data) => {
				const weatherPlace_ = data[1];
				const currentDate = new Date(Date.now());

				Object.keys(weatherPlace_).forEach((dateKey) => {
					const retrievedDate = new Date(dateKey);

					const strRetrievedDate = retrievedDate.toString().slice(0, 16);

					const strCurrentDate = currentDate.toString().slice(0, 16);

					if (strRetrievedDate === strCurrentDate) {
						const needleHour = currentDate.getHours();
						const closestTimestamp = weatherPlace_[dateKey].reduce((a, b) => {
							return Math.abs(new Date(b.Timestamp).getHours() - needleHour) <
								Math.abs(new Date(a.Timestamp).getHours() - needleHour)
								? b
								: a;
						});

						setCurrentWeatherDateKey(dateKey);
						setCurrentWeatherDate({
							...closestTimestamp,
							weatherDateKey: dateKey,
						});
						setStoreWeather({
							...closestTimestamp,
							weatherDateKey: dateKey,
							placeId: id,
						});
					}
				});

				setPlace(data[0]);
				setAverageRating(data[2].length ? getAvg(data[2]) : 0);
				setReviewers(data[2].length ? data[2].length : 0);
				setWeatherPlace(weatherPlace_);
				moveToPlace(data[0].Location);

				if (data[0].Type === "marina")
					setMarinaTags([
						{
							label: languages[currentLanguage]["place_details_tag_berths"],
							value: data[0]?.Berths !== null ? data[0].Berths : "N/A",
						},
						{
							label: languages[currentLanguage]["place_details_tag_maxdraft"],
							value:
								data[0]?.MaxDraft !== null ? data[0].MaxDraft + "m" : "N/A",
						},
						{
							label: languages[currentLanguage]["place_details_tag_vhf"],
							value: data[0]?.VHF !== null ? data[0].VHF : "N/A",
						},
					]);
				else setMarinaTags([]);

				var place = data[0];
				var placeType = place.Type;
				var guid = place.GUID;
				var breadCrumb = place.BreadCrumb?.Latin?.join("-");
				breadCrumb = breadCrumb.replaceAll(" ", "");
				var new_url =
					"https://" +
					window.location.host +
					"/explore/places/" +
					placeType +
					"/" +
					guid +
					"/" +
					breadCrumb;
				window.history.pushState(null, null, new_url);
			});
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		closeSideBars();
		setGalleryPhotos(null);
		setCoastViewPhotos(null);

		init();
	}, [id]);

	React.useEffect(() => {
		return () => {};
	}, [weatherStored]);

	return (
		<>
			<Sidebar show={show}>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={snackbarClipboard}
					autoHideDuration={2000}
					onClose={() => setSnackbarClipboard(false)}
					message="Copied to clipboard"
				/>

				<Dialog open={shareDialog} onClose={() => setShareDialog(false)}>
					<DialogTitle>
						{languages[currentLanguage]["place_details_share_button"]}
					</DialogTitle>

					<DialogContent>
						<DialogContentText>
							{languages[currentLanguage]["place_details_share_button"]}
						</DialogContentText>

						<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
							<a href={generatedLink} target="_blank" className="mr-2">
								<h3
									className="m-0 p-0"
									style={{
										color: { formMainTxt },
										fontSize: 14,
									}}
								>
									<i className="fas fa-fw fa-link mr-2" />
									{generatedLink}
								</h3>
							</a>

							<Tooltip title="Copy to clipboard" placement="top">
								<IconButton
									onClick={() => {
										// EVENT
										logEvent_("react_placedetails_copy_xy_btn_pressed");

										copyToClipboard(generatedLink);
									}}
								>
									<img
										src={require(`../../assets/copy-ic.svg`)}
										style={{ width: 14 }}
									/>
								</IconButton>
							</Tooltip>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShareDialog(false)}>Dismiss</Button>
						<Button
							onClick={() => {
								setShareDialog(false);
								copyToClipboard(generatedLink);
							}}
							autoFocus
						>
							Copy and dismiss
						</Button>
					</DialogActions>
				</Dialog>

				<div className="w-100 position-relative">
					<IconButton
						onClick={closeSidebar}
						component="span"
						className="d-flex justify-content-center align-items-center"
						style={{
							position: "absolute",
							zIndex: 2,
							top: 20,
							right: 20,
							height: 30,
							width: 30,
							padding: 0,
						}}
					>
						<img alt="close" src={Close} style={{ height: 30, width: 30 }} />
					</IconButton>
				</div>

				<SidebarContent className="pb-5">
					{loading ? (
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<div className="h-100 w-100">
							<CoverImage
								onClick={() => {
									if (!!place.CoastViewGallery?.Count) {
										logEvent_("react_placedetails_coastviewgal_pressed");
										setGalleryPhotos(null);
										setCoastViewPhotos(place.ID);
									} else if (!!place.UserGallery?.Count) {
										logEvent_("react_placedetails_usergallery_pressed");
										setCoastViewPhotos(null);
										setGalleryPhotos(place.ID);
									}
								}}
								style={{
									backgroundImage:
										place?.CoverImage && place?.CoverImage?.length
											? `url(${place?.CoverImage})`
											: `url(https://keeanostage.blob.core.windows.net/0000000000000000000/placeholders/placeholder_${place.Type}.png)`,
									backgroundPosition: "center",
									backgroundSize: "cover",
								}}
							/>
							{/* CONTENT */}
							<div className="px-4 pt-2">
								<div className="mb-1">
									{/* TITLES */}
									<h1
										className="mb-2"
										style={{
											fontSize: 21,
											fontWeight: "normal",
											color: { formMainTxt },
										}}
									>
										{place.Name?.Latin}
									</h1>

									<h3
										className="dromon-l"
										style={{
											fontSize: 12,
											fontWeight: "300",
											color: "#8f96a7",
										}}
									>
										{languages[currentLanguage][`place_type_${place.Type}`] +
											", " +
											place.BreadCrumb?.Latin?.join(", ")}
									</h3>
								</div>

								<div className="mb-4">
									{place.Location && (
										<div className="d-flex flex-row justify-content-between align-items-center">
											<h3
												className="m-0 p-0"
												style={{
													color: { formMainTxt },
													fontSize: 14,
												}}
											>
												<i
													className="far fa-fw fa-compass"
													style={{ marginRight: 16 }}
												/>
												{coordinateToDMS(place.Location[1], place.Location[0])}
											</h3>

											<div className="d-flex flex-row align-items-center">
												<Tooltip title="Move to location" placement="top">
													<IconButton
														className="mr-2"
														onClick={() => moveToPlace(place.Location)}
													></IconButton>
												</Tooltip>

												<Tooltip title="Copy to clipboard" placement="top">
													<IconButton
														onClick={() =>
															copyToClipboard(
																coordinateToDMS(
																	place.Location[1],
																	place.Location[0]
																)
															)
														}
													>
														<img
															src={require(`../../assets/copy-ic.svg`)}
															style={{ width: 14 }}
														/>
													</IconButton>
												</Tooltip>
											</div>
										</div>
									)}
									<a
										title="Contributor"
										className="d-flex flex-row justify-content-between align-items-center"
										style={{ cursor: "pointer" }}
										onClick={(e) => {
											e.preventDefault();

											logEvent_("react_placedetails_contributor_pressed");

											onShowUserProfile(place.Contributor?.Id);
										}}
									>
										<h3
											className="m-0 p-0"
											style={{
												color: { formMainTxt },
												fontSize: 14,
											}}
										>
											<img
												alt="contributor"
												src={ContributorIcon}
												style={{
													height: 14,
													width: 14,
													marginRight: 16,
													marginLeft: 2,
												}}
											/>
											{place.Contributor?.FirstName +
												" " +
												place.Contributor?.LastName}
										</h3>
									</a>
								</div>

								{/* BTN ACTIONS */}
								<div className="mb-3">
									<Button
										onClick={() => {
											// EVENT
											logEvent_("react_placedetails_addtripbtn_pressed");
											if (AuthService.isUserLogedIn()) {
												addTripNodeEditing(place, false, openWizard);
											} else {
												showUserAuthView();
												// FIREBASE EVENT
												logEvent_("react_addtotrip_unregister_click");
											}
										}}
										className="mr-3 mb-1 text-capitalize"
										variant="contained"
										component="span"
										style={{
											backgroundColor: filterHighlightColor,
											color: "#fff",

											fontWeight: "normal",
											borderRadius: 20,
										}}
									>
										{isBookmarked(place.ID, mode) ? (
											<>
												<img
													alt=""
													src={RemoveTripIc}
													style={{
														width: 12,
														height: 14,
														marginRight: 10,
														marginBottom: 2,
													}}
												/>
												<span className="deci" style={{ fontSize: 12 }}>
													{
														languages[currentLanguage][
															"place_details_removefromtrip_button"
														]
													}
												</span>
											</>
										) : (
											<>
												<img
													alt=""
													src={AddToTripIcon}
													style={{
														width: 12,
														height: 14,
														marginRight: 10,
														marginBottom: 2,
													}}
												/>{" "}
												<span
													className="deci"
													style={{ fontSize: 12, "padding-top": 2 }}
												>
													{
														languages[currentLanguage][
															"place_details_addtrip_button"
														]
													}
												</span>
											</>
										)}
									</Button>

									<Button
										className="mr-3 text-capitalize"
										variant="outlined"
										component="span"
										style={{
											color: filterHighlightColor,
											fontSize: 14,
											fontWeight: "normal",
											borderRadius: 20,
											backdropFilter: " blur(4px)",
											border: "solid 1px " + filterHighlightColor,
										}}
										onClick={sharePlace}
									>
										<img
											src={require(`../../assets/share-ic-blue.svg`)}
											style={{ width: 12, marginRight: 10 }}
										/>
										<span
											className="deci"
											style={{ fontSize: 12, "margin-top": 2 }}
										>
											{" "}
											{languages[currentLanguage]["place_details_share_button"]}
										</span>
									</Button>
								</div>

								{/* MARINA CONTACT */}
								{place.Type === "marina" && (
									<div style={{ marginBottom: 36 }}>
										<h2
											style={{
												fontSize: 14,
												color: { formMainTxt },
												marginBottom: 0,
											}}
										>
											{languages[currentLanguage]["place_details_contact"]}
										</h2>
										<div className="mb-4">
											{place.Location && (
												<div className="d-flex flex-row justify-content-between align-items-center">
													<a
														target="_blank"
														href={place.Url === null ? "N/A" : place.Url}
														title="Last contributor"
														className="d-flex flex-row justify-content-between align-items-center"
														style={{ cursor: "pointer" }}
														onClick={() =>
															onShowUserProfile(place.Contributor?.id)
														}
													>
														<h3
															className="m-0 p-0"
															style={{
																color: { formMainTxt },
																fontSize: 14,
															}}
														>
															<img
																src={WebLightIc}
																style={{ width: 14, width: 14, marginLeft: 3 }}
																className="mr-3"
															/>
															{place.Url === null ? "N/A" : place.Url}
														</h3>
													</a>

													<div className="d-flex flex-row align-items-center">
														<Tooltip title="Copy to clipboard" placement="top">
															<IconButton
																onClick={() => copyToClipboard(place.Url)}
															>
																<img
																	src={require(`../../assets/copy-ic.svg`)}
																	style={{ width: 14 }}
																/>
															</IconButton>
														</Tooltip>
													</div>
												</div>
											)}
											<div className="d-flex flex-row justify-content-between align-items-center">
												<h3
													className="m-0 p-0"
													style={{
														color: { formMainTxt },
														fontSize: 14,
													}}
												>
													<img
														src={PhoneIc}
														style={{
															width: 14,
															height: 14,
															marginRight: 16,
															marginLeft: 2,
														}}
														alt=""
													/>
													{place.Telephone === null ? "N/A" : place.Telephone}
												</h3>
											</div>
										</div>
									</div>
								)}

								{/* TAGS */}
								<h2
									style={{
										fontSize: 14,
										color: { formMainTxt },
										marginBottom: 16,
										marginTop: 25,
									}}
								>
									{languages[currentLanguage]["place_details_tags"]}
								</h2>
								<div
									className="mb-3 d-flex flex-wrap"
									style={{ marginBottom: 36 }}
								>
									<div className="w-100 d-flex flex-wrap">
										{marinaTags &&
											marinaTags?.map((tag, idx) => (
												<div
													key={idx}
													className="py-1 px-2 mr-2 mb-2"
													style={{
														borderRadius: 5,
														backgroundColor: { formMainTxt },
														color: "#ffffff",
														fontSize: 12,
													}}
												>
													{languages[currentLanguage][
														`${tag.label} : ${tag.value}`.replaceAll(":", "_")
													]
														? languages[currentLanguage][
																`${tag.label} : ${tag.value}`.replaceAll(
																	":",
																	"_"
																)
														  ]
														: `${tag.label} : ${tag.value}`}
												</div>
											))}
									</div>
									{(place.Attributes?.length === 0 ? [] : place.Attributes) &&
										(place.Attributes?.length === 0
											? []
											: place.Attributes
										)?.map((tag) => (
											<div
												key={tag}
												className="mb-2 dromon-l"
												style={{
													borderRadius: 5,
													backgroundColor: "#f6f6f6",
													color: { formMainTxt },
													fontSize: 12,
													padding: 5,
													marginRight: 16,
												}}
											>
												{languages[currentLanguage][tag.replaceAll(":", "_")]
													? languages[currentLanguage][tag.replaceAll(":", "_")]
													: tag}
											</div>
										))}
								</div>

								{/* WEATHER */}
								{!!weatherPlace &&
									!!weatherPlace[currentWeatherDate?.weatherDateKey] && (
										<div>
											<h2
												style={{
													fontSize: 14,
													color: { formMainTxt },
													marginBottom: 16,
												}}
											>
												{languages[currentLanguage]["place_details_weather"]}
											</h2>
											<div className="d-flex" style={{ marginBottom: 20 }}>
												<div
													className="py-1 px-2 mr-2 dromon-l"
													style={{
														borderRadius: 5,
														backgroundColor: "#f6f6f6",
														color: { formMainTxt },
														fontSize: 12,
													}}
												>
													<i
														style={{
															color:
																weatherColorDegree[
																	isSmallBoat()
																		? currentWeatherDate.SmallBoatScore - 1
																		: currentWeatherDate.BigBoatScore - 1
																],
														}}
														className="fas fa-circle mr-2"
													/>
													{
														weatherDegree[
															isSmallBoat()
																? currentWeatherDate.SmallBoatScore - 1
																: currentWeatherDate.BigBoatScore - 1
														]
													}
												</div>

												<div
													className="py-1 px-2 mr-2 dromon-l"
													style={{
														borderRadius: 5,
														backgroundColor: "#f6f6f6",
														color: { formMainTxt },
														fontSize: 12,
													}}
												>
													<img
														src={require(`../../assets/wind-direction-light-ic.svg`)}
														style={{
															width: 16,
															marginRight: 4,
															"margin-bottom": 4,
															transform: `rotate(${
																135 + currentWeatherDate.Angle
															}deg)`,
														}}
													/>
													{AuthService.isUserLogedIn()
														? Math.round(
																convertWindSpeedToMyUnit(
																	currentWeatherDate.Speed,
																	currentUserSettings.WindSpeedUnits
																)
														  )
														: currentWeatherDate.Speed}{" "}
													{AuthService.isUserLogedIn()
														? currentUserSettings.WindSpeedUnits
														: "kts"}
												</div>
											</div>

											{currentWeatherDate.weatherDateKey && (
												<span
													style={{
														color: "#8f96a7",
														fontSize: 12,
													}}
												>
													{new Date(currentWeatherDate.weatherDateKey)
														.toString()
														.slice(0, 16)}
												</span>
											)}

											<div
												className="d-flex align-items-center"
												style={{ marginBottom: 36 }}
											>
												<IconButton
													className="shadow-sm bg-white"
													style={{
														fontSize: 12,
														marginLeft: -17,
														marginRight: 8,
													}}
													disabled={
														Object.keys(weatherPlace).findIndex(
															(item) =>
																item === currentWeatherDate.weatherDateKey
														) === 0
													}
													onClick={() => {
														const keys_ = Object.keys(weatherPlace);
														const currentDateIndex_ = keys_.findIndex(
															(item) =>
																item === currentWeatherDate.weatherDateKey
														);
														const currentDateItemSelectedIndex_ = weatherPlace[
															currentWeatherDate.weatherDateKey
														].findIndex(
															(item) =>
																item.Timestamp === currentWeatherDate.Timestamp
														);
														if (currentDateIndex_ !== -1) {
															if (
																currentDateIndex_ + keys_?.length - 1 <
																keys_?.length
															) {
															} else {
																// EVENT TO SWIP
																logEvent_(
																	"react_placedetails_weather_date_swipe"
																);
																setCurrentWeatherDateKey(
																	keys_[currentDateIndex_ - 1]
																);
																setCurrentWeatherDate({
																	...weatherPlace[keys_[currentDateIndex_ - 1]][
																		currentDateItemSelectedIndex_
																	],
																	weatherDateKey: keys_[currentDateIndex_ - 1],
																});
																setStoreWeather({
																	...weatherPlace[keys_[currentDateIndex_ - 1]][
																		currentDateItemSelectedIndex_
																	],
																	weatherDateKey: keys_[currentDateIndex_ - 1],
																	placeId: id,
																});
															}
														}
													}}
												>
													<i className="fa fa-fw fa-chevron-left" />
												</IconButton>

												{weatherPlace[currentWeatherDate.weatherDateKey]?.map(
													(item, idx) => (
														<Button
															key={idx}
															className="text-center hour-tag"
															style={{
																flex: 1,
																minWidth: "initial",
																marginRight: 4,
																borderRadius: 0,
																fontSize: 12,
																...(currentWeatherDate.Timestamp ===
																item.Timestamp
																	? {
																			backgroundColor: "#e5f1ff",
																			borderBottom: "3px solid #e5f1ff",
																			borderRadius: 6,
																			color: filterHighlightColor,
																	  }
																	: {
																			color: { formMainTxt },
																			borderBottom:
																				"3px solid " +
																				weatherColorDegree[
																					isSmallBoat()
																						? item.SmallBoatScore - 1
																						: item.BigBoatScore - 1
																				],
																	  }),
															}}
															onClick={() => {
																logEvent_(
																	"react_placedetails_weather_date_selected"
																);
																setCurrentWeatherDate({
																	...item,
																	weatherDateKey: currentWeatherDateKey,
																});
																setStoreWeather({
																	...item,
																	weatherDateKey: currentWeatherDateKey,
																	placeId: id,
																});
															}}
														>
															{new Date(item.Timestamp).getHours()}
														</Button>
													)
												)}

												<IconButton
													className="shadow-sm bg-white"
													disabled={
														Object.keys(weatherPlace).findIndex(
															(item) =>
																item === currentWeatherDate.weatherDateKey
														) ==
														Object.keys(weatherPlace)?.length - 1
													}
													style={{
														fontSize: 12,
														marginLeft: 8,
														marginRight: -18,
													}}
													onClick={() => {
														const keys_ = Object.keys(weatherPlace);
														const currentDateIndex_ = keys_.findIndex(
															(item) =>
																item === currentWeatherDate.weatherDateKey
														);
														const currentDateItemSelectedIndex_ = weatherPlace[
															currentWeatherDate.weatherDateKey
														].findIndex(
															(item) =>
																item.Timestamp === currentWeatherDate.Timestamp
														);
														if (currentDateIndex_ !== -1) {
															if (currentDateIndex_ + 1 > keys_?.length) {
															} else {
																// EVENT TO SWIP
																logEvent_(
																	"react_placedetails_weather_date_swipe"
																);
																setCurrentWeatherDateKey(
																	keys_[currentDateIndex_ + 1]
																);
																setCurrentWeatherDate({
																	...weatherPlace[keys_[currentDateIndex_ + 1]][
																		currentDateItemSelectedIndex_
																	],
																	weatherDateKey: keys_[currentDateIndex_ + 1],
																});
																setStoreWeather({
																	...weatherPlace[keys_[currentDateIndex_ + 1]][
																		currentDateItemSelectedIndex_
																	],
																	weatherDateKey: keys_[currentDateIndex_ + 1],
																	placeId: id,
																});
															}
														}
													}}
												>
													<i className="fa fa-fw fa-chevron-right" />
												</IconButton>
											</div>
										</div>
									)}

								{/* PHOTOS */}
								<div className="d-flex flex-row justify-content-between align-items-center w-100">
									<h2
										style={{
											fontSize: 14,
											color: formMainTxt,
											marginBottom: 16,
										}}
									>
										{languages[currentLanguage]["place_details_photos"]}
									</h2>
									<Button
										onClick={() => {
											logEvent_("react_placedetails_addphotobtn_pressed");
											AuthService.isUserLogedIn()
												? fileInputRef.current.click()
												: showUserAuthView();
										}}
										className="d-flex flex-row align-items-center text-capitalize"
										style={{
											color: filterHighlightColor,
											cursor: "pointer",
											marginBottom: 16,
										}}
									>
										{loadingAdd ? (
											<i
												style={{ color: filterHighlightColor }}
												className="fa fa-circle-notch fa-spin mr-2"
											/>
										) : (
											<img
												src={AddIcon}
												style={{ height: 14, width: 14 }}
												alt=""
											/>
										)}{" "}
										<span
											className="ml-2"
											style={{
												fontSize: 14,
												color: filterHighlightColor,
											}}
										>
											{
												languages[currentLanguage][
													"place_details_photos_button"
												]
											}
										</span>
										<input
											onChange={loadImage}
											multiple={false}
											ref={fileInputRef}
											type="file"
											hidden
										/>
									</Button>
								</div>

								<div className="mb-5 d-flex">
									{!!place.CoastViewGallery?.Count && (
										<PhotoItem
											onClick={() => {
												logEvent_("react_placedetails_coastviewgal_pressed");
												setGalleryPhotos(null);
												setCoastViewPhotos(place.ID);
											}}
										>
											<div
												style={{
													height: 72,
													backgroundImage: `url(${place?.CoastViewGallery.Sample})`,
													backgroundPosition: "center",
													backgroundSize: "cover",
												}}
												className="d-flex justify-content-center align-items-center"
											>
												<h2
													className="m-0 p-0"
													style={{
														color: "#ffffff",
														fontSize: 21,
														textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
													}}
												>
													{place.CoastViewGallery.Count}
												</h2>
											</div>
											<div
												style={{ flex: 1 }}
												className="d-flex justify-content-center align-items-center"
											>
												<h2
													className="m-0 p-0"
													style={{
														fontSize: 14,
														color: { formMainTxt },
													}}
												>
													{languages[currentLanguage]["common_coast_view"]}
												</h2>
											</div>
										</PhotoItem>
									)}

									{!!place.CoastViewGallery?.Count &&
										!!place.UserGallery?.Count && <div className="mr-3" />}

									{!!place.UserGallery?.Count && (
										<PhotoItem
											onClick={() => {
												logEvent_("react_placedetails_usergallery_pressed");
												setCoastViewPhotos(null);
												setGalleryPhotos(place.ID);
											}}
										>
											<div
												style={{
													height: 72,
													backgroundImage: `url(${place?.UserGallery.Sample})`,
													backgroundPosition: "center",
													backgroundSize: "cover",
												}}
												className="d-flex justify-content-center align-items-center"
											>
												<h2
													className="m-0 p-0"
													style={{
														color: "#ffffff",
														fontSize: 21,
														textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
													}}
												>
													{place.UserGallery.Count}+
												</h2>
											</div>
											<div
												style={{ flex: 1 }}
												className="d-flex justify-content-center align-items-center"
											>
												<h2
													className="m-0 p-0"
													style={{
														fontSize: 14,
														color: { formMainTxt },
													}}
												>
													{languages[currentLanguage]["place_details_gallery"]}
												</h2>
											</div>
										</PhotoItem>
									)}
								</div>

								{/* PLACE REVIEW */}
								<>
									<h2
										style={{
											fontSize: 14,
											color: { formMainTxt },
											marginBottom: 16,
										}}
									>
										{languages[currentLanguage]["place_details_reviews_header"]}
										{/* Reviews */}
									</h2>
									<ReviewItem
										rate={averageRating}
										reviewers={reviewers}
										showNext={true}
										handleClick={() => {
											logEvent_("react_placedetails_reviewlist_pressed");
											onOpenReviewPage(place);
										}}
										handleAddReview={() => {
											logEvent_("react_placedetails_reviewbutton_pressed");
											AuthService.isUserLogedIn()
												? onOpenAddReview(place)
												: showUserAuthView();
										}}
									/>
								</>
							</div>
						</div>
					)}
				</SidebarContent>
			</Sidebar>

			{show && (
				<>
					<GalleryPhotos
						placeId={galleryPhotos}
						onClose={() => setGalleryPhotos(null)}
						onShowUserProfile={onShowUserProfile}
					/>

					<CoastViewPhotos
						placeId={coastViewPhotos}
						onClose={() => setCoastViewPhotos(null)}
					/>
				</>
			)}

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		openReviewPage: (place) => dispatch(openReviewPage(place)),
		openAddReviewPage: (place) => dispatch(openAddReviewPage(place)),
		showUserAuthView: () => dispatch(showUserAuthView()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
		setStoreWeather: (weatherData) => dispatch(setWeather(weatherData)),
		showUserProfile: () => dispatch(showUserProfile()),
		setUserProfileId: (userId) => dispatch(setUserProfileId(userId)),
		setStoreWeather: (weatherData) => dispatch(setWeather(weatherData)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlacesDetailsSideBar);

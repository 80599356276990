import React from "react";
import MuiBtn from "@material-ui/core/Button";
import { filterHighlightColor, whiteColor } from "../../../constants/colors";

const Button = ({
	text = "",
	next = true,
	disabled = false,
	handleNext = () => {},
	handleBack = () => {},
	buttonProps = {},
}) => {
	return (
		<>
			<MuiBtn
				{...{
					className:
						"text-center d-flex align-items-center justify-content-center",
					onClick: next ? handleNext : handleBack,
					style: {
						color: !next ? filterHighlightColor : whiteColor,
						backgroundColor: next ? filterHighlightColor : whiteColor,
						border: "1px solid" + filterHighlightColor,
						borderRadius: 5,
						cursor: "pointer",
						width: 185,
						height: 35,
						opacity: disabled ? 0.5 : 1,
						textTransform: "none",
					},
					...buttonProps,
				}}
			>
				{text}
			</MuiBtn>
		</>
	);
};

export default Button;

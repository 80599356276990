import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import logger from "redux-logger";

// REDUCERS
import userReducer from "./features/user/reducer";
import langReducer from "./features/translation/reducer";
import mapReducer from "./features/map/reducer";
import reviewReducer from "./features/review/reducer";
import notificationReducer from "./features/notification/reducer";

// HELPERS
import { isInDev } from "../helpers/functions";

// MIDDLEWARE=
const middleware = isInDev()
	? applyMiddleware(thunk, promise, logger)
	: applyMiddleware(thunk, promise);

const store = createStore(
	combineReducers({
		map: mapReducer,
		user: userReducer,
		language: langReducer,
		review: reviewReducer,
		notification: notificationReducer,
	}),
	middleware
);

export default store;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

// ICONS
import kayakIcon from "../../assets/svg/filters_icons/kayak_active_ic.svg";
import kayakIconDisabled from "../../assets/svg/filters_icons/kayak_disabled_ic.svg";
import kayakIconSelected from "../../assets/svg/filters_icons/kayak_selected_ic.svg";
import motorboatIcon from "../../assets/svg/filters_icons/motorboat_active_ic.svg";
import motorboatIconDisabled from "../../assets/svg/filters_icons/motorboat_disabled_ic.svg";
import motorboatIconSelected from "../../assets/svg/filters_icons/motorboat_selected_ic.svg";
import sailingIcon from "../../assets/svg/filters_icons/sailboat_active_ic.svg";
import sailingIconDisabled from "../../assets/svg/filters_icons/sailboat_disabled_ic.svg";
import sailingIconSelected from "../../assets/svg/filters_icons/sailboat_selected_ic.svg";

import {
  colorInactiveItem,
  bgInactiveItem,
  filterHighlightColor,
  bgActiveItem,
  whiteColor,
} from "../../constants/colors";

const CardVessels = (props) => {
  return (
    <div
      className="col px-2"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Button
        disabled={props.disabled}
        onClick={() => props.onClick()}
        className="d-flex flex-column justify-content-center align-items-center h-100 w-100"
        style={{
          backgroundColor:
            props.trip && props.active
              ? filterHighlightColor
              : props.trip
              ? whiteColor
              : props.place && props.active
              ? "#e5f1ff"
              : props.place
              ? "transparent"
              : props.active
              ? "#e5f1ff"
              : props.disabled
              ? "transparent"
              : "#f6f6f6",
          borderRadius: 10,
          paddingBottom: 0,
          padding: "10px 0 5px 0",
          height: "72px !important",
          width: "100%",
          border:
            props.trip && !props.active
              ? "solid 2px #dbdee6"
              : props.disabled || props.active || props.place
              ? "1px solid #f6f6f6"
              : "1px solid transparent",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
          <img
            style={{
              height: props.place || props.trip ? 20 : 30,
              adding: 0,
              margin: props.place || props.trip ? "8px 0" : 0,
              objectFit: "contain",
            }}
            alt=""
            src={(() => {
              if ((props.place || props.trip) && props.active)
                return props.activeIcon;
              if (props.place || props.trip) return props.icon;
              if (props.type === "kayak")
                return props.active
                  ? kayakIconSelected
                  : props.disabled
                  ? kayakIconDisabled
                  : kayakIcon;

              if (props.type === "sailboat")
                return props.active
                  ? sailingIconSelected
                  : props.disabled
                  ? sailingIconDisabled
                  : sailingIcon;

              return props.active
                ? motorboatIconSelected
                : props.disabled
                ? motorboatIconDisabled
                : motorboatIcon;
            })()}
          />

          <div
            style={{
              color:
                props.trip && props.active
                  ? whiteColor
                  : props.place && props.active
                  ? "#0078ff"
                  : props.place
                  ? "#8f96a7"
                  : props.active
                  ? "#0078ff"
                  : props.disabled
                  ? "#e6e6e6"
                  : "#222c4b",
              fontWeight: "300",
              textTransform: "lowercase",
              fontSize: 12,
              margin: 0,
              width: "100%",
              fontFamily: "Dromon-light",
            }}
          >
            {props.label}
          </div>
        </div>
      </Button>
    </div>
  );
};

CardVessels.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

export default CardVessels;

import React from "react";
import { connect } from "react-redux";
import { Button, IconButton, CircularProgress } from "@material-ui/core";

// CONSTANTS
import { filterHighlightColor, whiteColor } from "../../../constants/colors";

// SELECTORS
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages";

// ACTIONS
import { closeUserSidebars } from "../../../store/features/user/actions";

// COMPONENTS
import { Sidebar, SidebarContent, sidebarWidth } from "./styled";

// SVG ICONS
import Close_svg from "../../../assets/close.svg";
import ChevronLeftIcon from "../../../assets/back_ic.svg";

const SideBar = ({
	currentLang = "EN",
	visible = false,
	useBackBtn = false,
	back_sticky = false,
	back_title = null,
	back_subTitle = null,
	backTitleStyle = {},
	backSubTitleStyle = {},
	children = React.Component,
	customCloseBtn,
	customFooter,
	footerButtons = [],
	fixedFooterButton = false,
	footerProps = {},
	isLoading = false,
	sidebarProps = {},
	sidebarContentProps = {},
	autoCloseSidebars = true,
	closeUserSidebars = () => {},
	onClickBack = () => {},
	onClickClose = () => {},
}) => {
	// EFFECTS
	React.useEffect(() => {
		if (visible && autoCloseSidebars) closeUserSidebars();
		return () => {};
	}, [visible]);

	return (
		<Sidebar {...{ ...sidebarProps }} show={visible}>
			<SidebarContent {...{ ...sidebarContentProps }}>
				{useBackBtn ? (
					<Button
						onClick={onClickBack}
						className={
							"justify-content-start py-3 " + (back_sticky ? "" : "w-100")
						}
						style={{
							position: back_sticky ? "fixed" : "relative",
							textTransform: "initial",
							fontFamily: "inherit",
							height: 80,
							width: sidebarWidth - 8,
							backgroundColor: "#FFF",
							zIndex: 10,
						}}
					>
						<img
							src={ChevronLeftIcon}
							className="ml-2 mr-3"
							style={{ height: 17 }}
						/>

						<div className="d-flex flex-column align-items-start">
							<h2
								className="m-0 p-0"
								style={{
									fontSize: 21,
									color: "black",
									...(backTitleStyle ? backTitleStyle : {}),
								}}
							>
								{back_title}
							</h2>
							<div
								className="dromon-l"
								style={{
									color: "#8f96a7",
									fontWeight: "300",
									fontSize: 12,
									...(backSubTitleStyle ? backSubTitleStyle : {}),
								}}
							>
								{back_subTitle}
							</div>
						</div>
					</Button>
				) : (
					<div
						className="position-absolute"
						style={{
							backgroundColor: "transparent",
							zIndex: 2,
							top: 20,
							right: 20,
						}}
					>
						{customCloseBtn ? (
							customCloseBtn
						) : (
							<IconButton
								onClick={onClickClose}
								className=""
								title={languages[currentLang]["common_close"]}
							>
								<img
									src={Close_svg}
									alt="Close icon"
									style={{
										height: 15,
										width: 15,
										userSelect: "none",
										pointerEvents: "none",
									}}
								/>
							</IconButton>
						)}
					</div>
				)}

				<div
					className="w-100 d-flex flex-column"
					style={{
						minHeight: "calc(100% - 110px)",
						paddingTop: back_sticky ? 80 : 0,
					}}
				>
					{isLoading ? (
						<div
							className="d-flex w-100 justify-content-center align-items-center"
							style={{ flex: 1 }}
						>
							<CircularProgress style={{ color: filterHighlightColor }} />
						</div>
					) : (
						<div className="d-flex flex-column" style={{ flex: 1 }}>
							<div
								className="d-flex flex-column"
								style={{
									flex: 1,
									...(fixedFooterButton ? { paddingBottom: 80 } : {}),
								}}
							>
								{children}
							</div>

							{(!!footerButtons.length || customFooter) && (
								<div
									className={`d-flex justify-content-between px-2 ${
										fixedFooterButton ? "pt-2 pb-4" : ""
									} ${footerProps.className ? footerProps.className : ""}`}
									style={{
										...(fixedFooterButton
											? {
													width: sidebarWidth,
													position: "fixed",
													bottom: 0,
													left: 0,
													zIndex: 2,
													backgroundColor: whiteColor,
											  }
											: {}),
										...(footerProps?.style ? footerProps.style : {}),
									}}
								>
									{!customFooter ? (
										<>
											{footerButtons
												.filter((btn, id) => {
													return btn.label !== null;
												})
												.map((btn, id) => (
													<Button
														key={id}
														{...{
															...btn.props,
															className:
																"mx-1 " +
																(btn.props?.className
																	? btn.props?.className
																	: ""),
															style: {
																flex: 1,
																height: 36,
																borderRadius: 5,
																border: "solid 1px",
																color: filterHighlightColor,
																marginBottom: 5,
																textTransform: "initial",
																...(btn.props?.style ? btn.props?.style : {}),
															},
														}}
													>
														{btn.label}
													</Button>
												))}
										</>
									) : (
										customFooter
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</SidebarContent>
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return { currentLang: currentLang(state) };
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

import React, { useEffect, useState } from "react";
import { BoxFilter, CloseModalBtn } from "../FIlterModal/styled";
import closeIcon from "../../assets/close.svg";
import { connect, useSelector } from "react-redux";
import { Overlay } from "./styled";

// ICONS
import anchorage from "../../assets/svg/placeBlackIcons/anchorage.svg";
import beach from "../../assets/svg/placeBlackIcons/beach.svg";
import boatramp from "../../assets/svg/placeBlackIcons/boatramp.svg";
import boatyard from "../../assets/svg/placeBlackIcons/boatyard.svg";
import cave from "../../assets/svg/placeBlackIcons/cave.svg";
// import fishingarea from "../../assets/svg/placeBlackIcons/fishingarea.svg";
import marina from "../../assets/svg/placeBlackIcons/marina.svg";
import mooring from "../../assets/svg/placeBlackIcons/mooring.svg";
import port from "../../assets/svg/placeBlackIcons/port.svg";
import seaplaneport from "../../assets/svg/placeBlackIcons/seaplaneport.svg";
import shipyard from "../../assets/svg/placeBlackIcons/shipyard.svg";
// import transitional_anchorage from "../../assets/svg/placeBlackIcons/transitional_anchorage.svg";
import wreck from "../../assets/svg/placeBlackIcons/wreck.svg";

// SELECTED ICONS
import anchorage_ from "../../assets/svg/placeBlueIcons/anchorage.svg";
import beach_ from "../../assets/svg/placeBlueIcons/beach.svg";
import boatramp_ from "../../assets/svg/placeBlueIcons/boatramp.svg";
// import boatyard_ from "../../assets/svg/placeBlueIcons/boatyard.svg";
import cave_ from "../../assets/svg/placeBlueIcons/cave.svg";
import mooring_ from "../../assets/svg/placeBlueIcons/mooring.svg";
import wreck_ from "../../assets/svg/placeBlueIcons/wreck.svg";

// SELECTOR
import { languages } from "../../store/features/translation/Languages";
import { currentLang } from "../../store/features/translation/selectors";
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../constants/colors";
import { Button, Snackbar } from "@material-ui/core";
import CardVessels from "../FIlterModal/CardVessels";
import CardMultiSelect from "../FIlterModal/CardMultiSelect";
import { ApiClient } from "../../ApiClient";
import Alert from "@material-ui/lab/Alert";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

const ContributionPage = ({
	added = () => {},
	dispatch = () => {},
	visible,
	onClose = () => {},
	place,
}) => {
	const iconDictionary = {
		anchorage,
		beach,
		boatramp,
		boatyard,
		cave,
		// fishingarea,
		marina,
		mooring,
		port,
		seaplaneport,
		shipyard,
		wreck,
		anchorage_,
		beach_,
		boatramp_,
		cave_,
		mooring_,
		wreck_,
	};
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [placeName, setPlaceName] = useState("");
	const [state, setState] = useState({
		selectedTags: [],
		allVessels: [],
		selectedVessel: "",
		allTags: [],
	});
	const [loading, setLoading] = useState(false);
	const currentLanguage = useSelector(currentLang);
	const placeAttributes = useSelector((state) => state.map.placeAttributes);
	const [placeAttributes_, setPlaceAttributes_] = useState({});
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};

	const resetState = () => {
		let vessels = Object.keys(placeAttributes);
		let tags = [];
		for (let type in placeAttributes[vessels[0]]) {
			tags = tags.concat(placeAttributes[vessels[0]][type].Values);
		}
		setState({
			...state,
			selectedTags: [],
			selectedVessel: "",
			allTags: tags,
		});
		setPlaceName("");
	};

	const onSelectVessel = (vessel) => {
		// UPDATE THE STATE
		let tags = [];
		for (let type in placeAttributes[vessel]) {
			tags = tags.concat(placeAttributes[vessel][type].Values);
		}
		setState({
			...state,
			selectedTags: [],
			selectedVessel: vessel,
			allTags: tags,
		});
	};

	const onSelectTag = (tag) => {
		var allTagsToUse = state.selectedTags;
		for (let t in placeAttributes[state.selectedVessel]) {
			if (placeAttributes[state.selectedVessel][t].Values.includes(tag)) {
				if (placeAttributes[state.selectedVessel][t].IsSingle) {
					const found = allTagsToUse.find((e) => e === tag);
					placeAttributes[state.selectedVessel][t].Values.forEach((e) => {
						allTagsToUse = allTagsToUse.filter((item) => item !== e);
					});
					if (!found) {
						allTagsToUse.push(tag);
					}
				} else {
					const found = allTagsToUse.find((e) => e === tag);
					if (found) {
						allTagsToUse = allTagsToUse.filter((item) => item !== found);
					} else {
						allTagsToUse.push(tag);
					}
				}
			}
		}
		setState({
			...state,
			selectedTags: allTagsToUse,
		});
	};

	const addPlace = () => {
		// EVENT
		logEvent_("react_explore_createplace_button_pressed");

		if (placeName.trim() !== "") {
			setLoading(true);
			ApiClient.addPlace(
				placeName,
				place.Location,
				state.selectedVessel,
				state.selectedTags
			)
				.then((res) => {
					if (res && !res?.ErrorCode && !res?.ErrorMessage) {
						setNotice({
							type: "SUCCESS",
							content:
								languages[currentLanguage]["place_contribution_success_msg"],
						});
						setTimeout(() => {
							setLoading(false);
							onClose();
							gtag_report_conversion(
								"https://" + window.location.host + "/explore/",
								types.PLACE_VIEWED
							);
							logEvent_("react_place_contribution_success");
							added();
							dispatch({
								type: "updateSidebarPlacesState",
								place: {
									id: res.ID,
									type: res.Type,
									namelatin: res.Name.Latin,
								},
							});
						}, 1500);
					} else {
						setLoading(false);
						setNotice({
							type: "ERROR",
							content: res.ErrorMessage || res.ErrorCode,
						});
					}
					resetState();
				})
				.catch((error) => {
					setNotice({
						type: "ERROR",
						content:
							languages[currentLanguage]["common_error_something_went_wrong"],
					});
					setLoading(false);
				});
		} else {
			setNotice({
				type: "WARNING",
				content: "Name and at least one tag are required",
			});
		}
	};

	useEffect(() => {
		let vessels = Object.keys(placeAttributes);
		let tags = [];
		for (let type in placeAttributes[vessels[0]]) {
			tags = tags.concat(placeAttributes[vessels[0]][type].Values);
		}
		setPlaceAttributes_(placeAttributes);
		setState({
			...state,
			allTags: tags,
			allVessels: vessels,
			selectedVessel: vessels[0],
		});
	}, [placeAttributes]);

	return (
		<>
			<BoxFilter
				className="container"
				show={visible}
				style={{
					padding: "24px 16px 86px 16px",
				}}
			>
				<div>
					<h2 style={{ fontSize: 21, color: formMainTxt }}>
						{languages[currentLanguage]["place_contribution_header"]}
					</h2>
					{/* <h3 style={{ fontSize: 14, color: secondaryTxt }}>
						Give your contribution
					</h3> */}
				</div>
				<CloseModalBtn
					onClick={() => {
						resetState();
						onClose();
					}}
				>
					<img src={closeIcon} alt="Close" style={{ height: 15, width: 15 }} />
				</CloseModalBtn>
				<div style={{ marginBottom: 16 }}>
					<div className="container-fluid mt-1"></div>
				</div>
				<div style={{}} className="container">
					<div className="row" id="input-add">
						<h3 className="mb-3 mt-3" style={titleStyle}>
							{languages[currentLanguage]["place_contribution_name"]}
						</h3>
						<input
							className="col-12 inp-focus-BP"
							value={placeName}
							placeholder={
								languages[currentLanguage][
									"place_contribution_name_placeholder"
								]
							}
							onChange={(e) => setPlaceName(e.target.value)}
						/>
					</div>
					<div className="row px-0">
						<h3 className="col-12 mb-3 mt-3 px-0" style={titleStyle}>
							{languages[currentLanguage]["place_contribution_type"]}
						</h3>
						<div className="col-12 px-0">
							<div className="row justify-content-between">
								{state.allVessels.slice(0, 3).map((place_) => (
									<CardVessels
										key={place_}
										icon={iconDictionary[place_]}
										active={state.selectedVessel === place_}
										type={place_}
										disabled={false}
										place
										activeIcon={
											iconDictionary[`${place_}_`]
												? iconDictionary[`${place_}_`]
												: iconDictionary[place_]
										}
										label={languages[currentLanguage][`place_type_${place_}`]}
										onClick={() => onSelectVessel(place_)}
									/>
								))}
							</div>
							<div className="row justify-content-between mt-3">
								{state.allVessels.slice(3, 6).map((place_) => (
									<CardVessels
										key={place_}
										icon={iconDictionary[place_]}
										active={state.selectedVessel === place_}
										type={place_}
										disabled={false}
										place
										label={languages[currentLanguage][`place_type_${place_}`]}
										activeIcon={
											iconDictionary[`${place_}_`]
												? iconDictionary[`${place_}_`]
												: iconDictionary[place_]
										}
										onClick={() => onSelectVessel(place_)}
									/>
								))}
							</div>
							<div className="row justify-content-between mt-3">
								{state.allVessels.slice(6).map((place_) => (
									<CardVessels
										key={place_}
										icon={iconDictionary[place_]}
										active={state.selectedVessel === place_}
										type={place_}
										disabled={false}
										place
										label={languages[currentLanguage][`place_type_${place_}`]}
										activeIcon={
											iconDictionary[`${place_}_`]
												? iconDictionary[`${place_}_`]
												: iconDictionary[place_]
										}
										onClick={() => onSelectVessel(place_)}
									/>
								))}
							</div>
						</div>
					</div>
					<div className="row">
						<h3 className="col-12 mb-3 mt-3 px-0" style={titleStyle}>
							{languages[currentLanguage]["place_details_tags"]}
						</h3>
						<div className="col-12">
							<div className="row">
								{state.allTags.map((tag) => (
									<CardMultiSelect
										key={tag}
										onClick={() => onSelectTag(tag)}
										active={state.selectedTags.includes(tag)}
										label={
											languages[currentLanguage][tag.replaceAll(":", "_")]
												? languages[currentLanguage][tag.replaceAll(":", "_")]
												: tag
										}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={notice.type !== ""}
					autoHideDuration={2000}
					onClose={() => setNotice({ type: "", content: "" })}
					message={notice.content}
				/>
			</BoxFilter>

			<BoxFilter
				className="container d-flex align-items-center"
				show={visible}
				style={{
					top: "calc(100% - 70px)",
					padding: "0 12px",
					width: 407,
					left: visible ? 0 : -407,
					height: 70,
					overflow: "hidden",
				}}
			>
				<Button
					className="p-2 col-12 text-capitalize"
					onClick={() => addPlace("place")}
					style={{
						color: whiteColor,
						backgroundColor: filterHighlightColor,
						border: "none",
						fontSize: 14,
						borderRadius: 5,
						margin: "16px 0",
						boxShadow: "-5px 0 7px 0 rgba(160, 160, 169, 0.15)",
					}}
				>
					{loading ? (
						<i className="fa fa-circle-notch fa-spin mr-2" />
					) : (
						<span className="deci text-capitalize">
							{languages[currentLanguage]["place_contribution_create_button"]}
						</span>
					)}
				</Button>
				{notice.type !== "" && (
					<>
						<Alert
							onClose={() => {
								setNotice({ type: "", content: "" });
							}}
							variant="filled"
							severity={notice.type.toLowerCase()}
							style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
						>
							{notice.content}
						</Alert>
					</>
				)}
			</BoxFilter>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContributionPage);

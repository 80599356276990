export default function MapReducer(state, action) {
	switch (action.type) {
		case "updatesearch": {
			const bbox = JSON.parse(action.result.Bbox);
			let newMoveTo;

			if (
				[
					"bay",
					"country",
					"destination",
					"islet",
					"lagoon",
					"peninsula",
				].includes(action.result.Type)
			) {
				newMoveTo = {
					bounds: [
						[bbox[0], bbox[1]],
						[bbox[2], bbox[3]],
					],
					center: action.result.Point.coordinates,
					zoom: 10,
				};
			} else {
				newMoveTo = {
					bounds: [
						[bbox[0], bbox[1]],
						[bbox[2], bbox[3]],
					],
					center: action.result.Point.coordinates,
					zoom: 13,
				};
			}

			return {
				...state,
				mapState: { ...state.mapState, moveTo: newMoveTo },
			};
		}
		case "updatefilters": {
			return {
				...state,
				mapState: { ...state.mapState, filters: action.filters },
			};
		}
		case "updatelayers": {
			return {
				...state,
				mapState: { ...state.mapState, layers: action.layers },
			};
		}
		case "bookmarksBounding": {
			return {
				...state,
				mapState: {
					...state.mapState,
					bookmarksBounds: {
						bookmarks: action.bookmarks,
						bounds: action.bounds,
					}
				},
			};
		}
		//buttons
		case "selectfilters": {
			return {
				...state,
				showLayers: false,
				showFilters: !state.showFilters,
			};
		}
		case "selectlayers": {
			return {
				...state,
				showFilters: false,
				showLayers: !state.showLayers,
			};
		}
		//chart
		case "updatemapcenter": {
			return {
				...state,
				sidebarState: { ...state.sidebarState, center: action.center },
			};
		}
		case "updateselectedId": {
			return {
				...state,
				sidebarState: { ...state.sidebarState, selectedId: action.selectedId },
			};
		}
		//slider
		case "updateslider": {
			return {
				...state,
				sliderState: action.photo,
				showSlider: true,
			};
		}
		case "closeslider": {
			const newMoveTo = {
				center: action.coordinates,
				zoom: 13,
			};
			return {
				...state,
				showSlider: false,
				mapState: { ...state.mapState, moveTo: newMoveTo },
			};
		}
            
        case "coastViewcloseslider": {
			const coastViewMoveTo = {
				center: action.coordinates,
				id: action.id,
			};
			return {
				...state,
				showSlider: false,
				mapState: { ...state.mapState, coastViewMoveTo: coastViewMoveTo },
			};
		}
		// Places details
		case "updateSidebarPlacesState": {
			return {
				...state,
				placeDetailsState: action.place,
				showPlaceDetails: true,
			};
		}
		case "closeSidebarPlaces": {
			return {
				...state,
				showPlaceDetails: false
			};
		}
		// Closest CoastView
		case "showClosestCVState": {
			return {
				...state,
				closestCVState: action.closestCV,
				showClosestCV: true
			};
		}
		case "hideClosestCV": {
			return {
				...state,
				showClosestCV: false
			};
		}
		case "moveMapTo": {
			const newMoveTo = {
				center: action.coordinates,
				zoom: 10,
			};
			return {
				...state,
				mapState: { ...state.mapState, moveTo: newMoveTo }
			};
		}
		default:
			throw new Error("Unhandle action " + action.type);
	}
}

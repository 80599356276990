/*global FB*/
const FacebookClient =  function () {

    // (function init(){
    //    return new Promise((resolve, reject) => {
    //        if (typeof FB !== 'undefined') {
    //            resolve();
    //        } else {
    //            window.fbAsyncInit = () => {
    //                FB.init({
    //                    appId: '140270766478904',
    //                    cookie: true,
    //                    xfbml: true,
    //                    version: 'v3.2'
    //                });
    //                resolve();
    //            };
    //            (function (d, s, id) {
    //                var js, fjs = d.getElementsByTagName(s)[0];
    //                if (d.getElementById(id)) return;
    //                js = d.createElement(s); js.id = id;
    //                js.src = "//connect.facebook.net/en_US/sdk.js";
    //                fjs.parentNode.insertBefore(js, fjs);
    //            }(document, 'script', 'facebook-jssdk'));
    //        }
    //    });
    //})()


    return {
        init: () => {
            return new Promise((resolve, reject) => {
                if (typeof FB !== 'undefined') {
                    resolve();
                } else {
                    window.fbAsyncInit = () => {
                        FB.init({
                            appId: '140270766478904',
                            cookie: true,
                            xfbml: true,
                            version: 'v3.2'
                        });
                        resolve();
                    };
                    (function (d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s); js.id = id;
                        js.src = "//connect.facebook.net/en_US/sdk.js";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));
                }
            });
        },
        checkLoginState: function () {
            return new Promise((resolve, reject) => {
                FB.getLoginStatus((response) => {

                    if (response && response.status)
                        resolve(response)
                    else
                        reject(response)

                    //response.status !== 'connected' ? resolve(response) : reject(response);
                    //response.status === 'connected' ? resolve(response) : reject(response);
                });
            });
        },
        login: function () {
            return new Promise((resolve, reject) => {
                FB.login((response) => {
                    FB.getLoginStatus((response) => {

                        if (response && response.status)
                            resolve(response)
                        else
                            reject(response)

                    });
                });
            });
        },
        logout: function () {
            return new Promise((resolve, reject) => {
                FB.logout((response) => {
                    //response.authResponse ? resolve(response) : reject(response);
                    response ? resolve(response) : reject(response);
                });
            });
        },
        fetch: function () {
            return new Promise((resolve, reject) => {
                FB.api(
                    '/me',
                    { fields: 'first_name, last_name, gender' },
                    response => response.error ? reject(response) : resolve(response)
                );
            });
        }
    }
}();


export { FacebookClient}
import React from "react";
import { connect, useSelector } from "react-redux";
import StarRatings from "react-star-ratings";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

// CONSTANTS
import { formMainTxt, secondaryTxt } from "../../../constants/colors";

// STORE
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages/";

// COMPONENTS
import UnfilledBtn from "./UnfilledBtn";

// SVG ICONS
import reviewBtn from "../../../assets/review/review_ic.svg";

const ReviewItem = (props) => {
	const { handleClick, reviewers, rate, showNext, handleAddReview } = props;
	const currentLanguage = useSelector(currentLang);

	return (
		<>
			<Button
				className="container mb-3 px-0 text-left"
				style={{ textTransform: "initial" }}
				onClick={handleClick}
			>
				<div style={{ flex: 1 }}>
					<div className="row align-items-center">
						<div className="col-2 pr-0" style={{ fontSize: 50 }}>
							{rate.toFixed(1)}
						</div>
						<div className="col-8 ml-4">
							<StarRatings
								rating={rate}
								starRatedColor="#f5db7b"
								starEmptyColor="#f6f6f6"
								numberOfStars={5}
								name="rating"
								starDimension="25"
							/>
							<br />

							<span
								className="col pl-0 mt-1"
								style={{ color: secondaryTxt, fontSize: 14 }}
							>
								{reviewers}{" "}
								{languages[currentLanguage]["place_details_reviews_count"]}
							</span>
						</div>
						<div className={showNext ? "d-block" : "d-none"}>
							<i
								className="fas fa-chevron-right"
								style={{ fontSize: 16, color: formMainTxt }}
							></i>
						</div>
					</div>
				</div>
			</Button>

			<div className={showNext ? "row px-3 pb-4" : "d-none"}>
				<UnfilledBtn
					title={
						languages[currentLanguage]["place_reviews_list_report_hover_button"]
					}
					image={reviewBtn}
					text={languages[currentLanguage]["place_details_reviews_button"]}
					onClick={handleAddReview}
				/>
			</div>
		</>
	);
};

ReviewItem.propTypes = {
	rate: PropTypes.number,
	reviewers: PropTypes.number,
	handleClick: PropTypes.func.isRequired,
	showNext: PropTypes.bool,
	handleAddReview: PropTypes.func,
};

const mapStateToProps = (_state) => {
	return {};
};

const mapDispatchToProps = (_dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewItem);

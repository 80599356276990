import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const CardCategory = (props) => {
	return (
		<div className="col-4 my-1 px-2">
			<Button
				className="py-2 px-1"
				onClick={() => props.onClick()}
				disabled={props?.disabled}
				style={{
					backgroundColor: props.active ? props.bgColor : "#f6f6f6",
					borderRadius: 5,
					fontSize: 12,
					height: 32,
					lineHeight: 1,
					width: "100%",
					border: "none",
					textAlign: "center",
					fontFamily: "Dromon-light",
					fontWeight: "300",
					textTransform: "capitalize",
					color: props.active ? "#ffffff" : "#222c4b",
					opacity: props?.disabled ? 0.6 : 1,
				}}
			>
				{props.label}
			</Button>
		</div>
	);
};

CardCategory.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func,
	label: PropTypes.string.isRequired,
	bgColor: PropTypes.string,
};

export default CardCategory;

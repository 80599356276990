/**
 * App trip states.
 *
 * Don't forget to update if needed
 */
export default {
	COMPLETED: "completed",
	CANCELLED: "cancelled",
	CANCELED: "canceled",
	LIVE: "live",
	ONBOARDING: "onboarding",
	UPCOMING: "upcoming",
};

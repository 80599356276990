export const SET_MAP = "MAP/SET";
export const SET_MAP_FILTERS = "MAP/SET_FILTERS";
export const SET_PLACE_ATTRIBUTES = "MAP/PLACE/ATTRIBUTES";
export const SET_TRIP_ATTRIBUTES = "MAP/TRIP/ATTRIBUTES";
export const SET_CURRENT_TRIP_ITINERARY = "MAP/TRIP/CURRENT_TRIP_ITINERARY";
export const SET_TRIP_ITINERARY_DAYS_LIST = "MAP/TRIP/ITINERARY_DAYS_LIST";
export const ADD_NODE_TO_TRIP_ITINERARY = "MAP/TRIP/ADD_NODE_TO_ITINERARY";
export const REPLACE_SHELTER_NODE = "MAP/TRIP/REPLACE_SHELTER_NODE";
export const TOGGLE_SHELTER_NODE = "MAP/TRIP/TOGGLE_SHELTER_NODE";
export const UPDATE_MIDDLE_NODES = "MAP/UPDATE_MIDDLE_NODES";
export const REMOVE_NODE_TO_TRIP_ITINERARY =
	"MAP/TRIP/REMOVE_NODE_TO_ITINERARY";
export const UPDATE_TRIP_ITINERARY_NODE_DURATION =
	"MAP/TRIP/UPDATE_NODE_DURATION";
export const UPDATE_INDEX_TRIP_DAY = "MAP/TRIP/UPDATE_INDEX_DAY";
export const SET_TRIP_ITINERARY_VISIBILITY =
	"MAP/TRIP/SET_ITINERARY_VISIBILITY";
export const SET_MAP_FILTERS_SELECTED = "MAP/SET_FILTERS_SELECTED";
export const SET_WEATHER = "MAP/SET_WEATHER";
export const SET_GLOBAL_WEATHER_DATE = "MAP/SET_GLOBAL_WEATHER_DATE";
export const SET_GLOBAL_WEATHER_BRAND_VISIBILITY =
	"MAP/SET_GLOBAL_WEATHER_BRAND_VISIBILITY";
export const CLEAN_MAP = "MAP/CLEAN_MAP";
export const DEFAULT_NEED = "uri:pl:tag:experience";
export const DEFAULT_NEED_KEY = "experience";
export const DEFAULT_PLACE_TYPES = ["beach"];
export const DEFAULT_PLACE_TYPE_TAG = "uri:pl:tag:noamenities";
export const DEFAULT_PLACES_PREDICATE = [
	"all",
	["in", DEFAULT_NEED, ["get", "tags"]],
];

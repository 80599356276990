import React from "react";
import { IconButton, CircularProgress, Avatar } from "@material-ui/core";
import { connect, useSelector } from "react-redux";

// API
import { ApiClient } from "../../ApiClient";

// STYLED
import { PlacePhotos as Wrapper } from "./styled";
import { useStyles } from "../map/Popup";

// STORE
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { whiteColor } from "../../constants/colors";

function GalleryPhotos({
	placeId = null,
	onClose = () => {},
	onShowUserProfile = () => {},
}) {
	// Selectors
	const currentLanguage = useSelector(currentLang);

	// STYLES
	const classes = useStyles();

	// STATES
	const [photosList, setPhotosList] = React.useState([]),
		[currentObject, setCurrentObject] = React.useState({}),
		[loading, setLoading] = React.useState(true);

	// FUNCTIONS
	const canDisplay = () => placeId;

	const prev = () => {
		const indexLength = photosList.length - 1;
		const prevIndex = currentObject.index - 1;
		if (prevIndex >= 0) {
			setCurrentObject({
				index: prevIndex,
				...photosList[prevIndex],
			});
		} else {
			setCurrentObject({
				index: indexLength,
				...photosList[indexLength],
			});
		}
	};

	const next = () => {
		const indexLength = photosList.length - 1;
		const nextIndex = currentObject.index + 1;

		if (nextIndex <= indexLength) {
			setCurrentObject({
				index: nextIndex,
				...photosList[nextIndex],
			});
		} else {
			setCurrentObject({
				index: 0,
				...photosList[0],
			});
		}
	};

	// EFFECTS
	React.useEffect(() => {
		(async () => {
			if (placeId) {
				setLoading(true);
				ApiClient.getPlaceGalleryPhotos(placeId)
					.then((photosList_) => {
						setPhotosList(photosList_);
						setCurrentObject({
							index: 0,
							...photosList_[0],
						});
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			}
		})();
		return () => setPhotosList([]);
	}, [placeId]);

	return (
		<Wrapper className="d-flex flex-column" show={canDisplay()}>
			<div className="position-relative flex w-100" style={{ flex: 1 }}>
				{loading ? (
					<div
						className="d-flex h-100 w-100 justify-content-center align-items-center"
						style={{}}
					>
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<>
						{Object.keys(currentObject).length && (
							<div className="h-100 w-100 d-flex flex-column">
								<div
									className="d-flex align-items-center justify-content-between"
									style={{
										backgroundColor: "#000000",
										color: "#FFF",
										height: 85,
										width: "100%",
										padding: "5px 20px",
									}}
								>
									<div>
										<h1 style={{ fontSize: 21, color: whiteColor }}>
											{languages[currentLanguage]["place_details_gallery"]}
										</h1>
										{currentObject?.User && (
											<h2 style={{ fontSize: 14 }}>
												<a
													title="Last contributor"
													onClick={() =>
														onShowUserProfile(currentObject.User.ID)
													}
													className="d-flex align-items-center"
													style={{ color: "#FFF", cursor: "pointer" }}
												>
													<Avatar
														alt="Travis Howard"
														src={currentObject.User.Photo}
														className="mr-2"
														style={{
															height: 27,
															width: 27,
														}}
													/>
													<span>
														{currentObject.User.LastName}{" "}
														{currentObject.User.FirstName}
													</span>
												</a>
											</h2>
										)}
									</div>

									<IconButton
										style={{
											height: 30,
											width: 30,
											padding: 0,
											fontSize: 14,
											color: "#fff",
										}}
										onClick={onClose}
									>
										<i className="fas fa-fw fa-times" />
									</IconButton>
								</div>
								{currentObject?.Action && (
									<>
										{" "}
										<div
											className="position-relative overflow-hidden w-100"
											style={{
												flex: 1,
												backgroundImage: `url(${currentObject?.Action.Url})`,
												backgroundPosition: "center",
												backgroundSize: "cover",
											}}
										/>
										<div
											className="d-flex align-items-center justify-content-end"
											style={{
												backgroundColor: "#000000",
												color: "#FFF",
												height: 85,
												width: "100%",
												padding: "0 20px",
											}}
										>
											<h2
												className="p-0 m-0"
												style={{ fontSize: 21, color: whiteColor }}
											>
												<i className="far fa-image" /> {currentObject.index + 1}
												/{photosList.length}
											</h2>
										</div>
									</>
								)}

								<IconButton
									onClick={prev}
									style={{
										...styles.floatIconBtn,
										left: 20,
										borderRadius: 25,
									}}
								>
									<i className="fas fa-chevron-left fa-fw" />
								</IconButton>
								<IconButton
									onClick={next}
									style={{
										...styles.floatIconBtn,
										right: 20,
										borderRadius: 25,
									}}
								>
									<i className="fas fa-chevron-right fa-fw" />
								</IconButton>
							</div>
						)}
					</>
				)}
			</div>
		</Wrapper>
	);
}
export default GalleryPhotos;

const styles = {
	floatIconBtn: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		width: 50,
		height: 50,
		opacity: 0.9,
		fontSize: 16,
		margin: 0,
		padding: 0,
		borderRadius: 100,
		"-webkit-backdrop-filter": "blur(5px)",
		backdropFilter: "blur(5px)",
		overflow: "hidden",
		color: "#FFF",
		fontSize: 16,
		backgroundColor: "rgba(17, 17, 17, 0.5)",
	},
};

import React, { useState, useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// API
import { ApiClient } from "../../../ApiClient";

// CONSTANTS
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../../constants/colors";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";

// COMPONENTS
import MultiselectCheck from "../Components/MultiselectCheck";

// SVGs ICONS
import imgHeader from "../../../assets/svg/tripWizard/kudos_ic.svg";
import addIcon from "../../../assets/review/add_ic.svg";

const Sixth = (props) => {
	const currentLanguage = useSelector(currentLang);
	const fileInputRef = useRef();
	const [state, setstate] = useState({
		selected: "circular",
		tripName: props.state?.Name ? props.state?.Name : "",
		description: props.state?.Description ? props.state?.Description : "",
	});
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [image, setimage] = useState({
		uri: props.state?.Cover ? props.state?.Cover : null,
		file: null,
	});
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [options, setOptions] = useState([
		"trip_review_privacy_public",
		"trip_review_privacy_private",
	]);
	const [option, setOption] = useState("trip_review_privacy_public");
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};
	const handleSelect = (op) => {
		setOption(op);
	};
	const loadImage = (e) => {
		const URI = URL.createObjectURL(e.target.files[0]);
		setimage({ uri: URI, file: e.target.files[0] });
		// //console.log("RESP 1 ==>", e.target?.files[0]);
	};
	const handleSubmitTrip = async () => {
		logEvent_("react_tripwiz_stepinfo_savebtn_pressed");

		if (state.tripName.trim().length >= 2) {
			let photo = null;
			let uploadPhotoResponse;

			if (image.uri && image.file) {
				setLoadingSubmit(true);

				await ApiClient.getPhotoServiceSignature().then((res) => (photo = res));
				await ApiClient.uploadUserPhoto(photo.UploadUrl, image.file).then(
					(res) => (uploadPhotoResponse = res)
				);
			}

			setLoadingSubmit(true);
			props.handleSubmit({
				url: photo ? photo?.Url : image.uri ? image.uri : null,
				tripName: state.tripName,
				description: state.description,
				public: option === options[0],
			});
			setTimeout(() => {
				setLoadingSubmit(false);
			}, 1000);
		} else {
			setNotice({
				type: "WARNING",
				content: "Enter the name of the trip",
			});
		}
	};

	useEffect(() => {
		setstate({
			selected: "circular",
			tripName: props.state?.Name ? props.state?.Name : "",
			description: props.state?.Description ? props.state?.Description : "",
		});
		setimage({
			uri: props.state?.Cover ? props.state?.Cover : null,
			file: null,
		});
	}, [props.state, props.finished]);

	return (
		<>
			<div
				className={
					props.visible
						? "px-0 d-flex flex-column justify-content-space-between step-body"
						: "d-none"
				}
				style={{
					width: 384,
					marginBottom: 300,
				}}
			>
				<div className="d-flex justify-content-center align-items-center">
					<img
						src={imgHeader}
						style={{ height: 80, width: 80, marginTop: -15, marginBottom: 50 }}
						alt=""
					/>
				</div>
				<h3
					className="my-2 dromon-l"
					style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
				>
					Creating a New Trip
				</h3>
				<h2 className="text-center" style={{ fontSize: 22, color: "#222c4b" }}>
					{languages[currentLanguage]["trip_description_header"]}
				</h2>
				<div
					style={{ width: 384 }}
					// className="d-flex flex-column justify-content-between px-0"
				>
					<h3 className="mb-3 mt-3" style={titleStyle}>
						{languages[currentLanguage]["trip_description_tripname"]}
					</h3>
					<input
						className="col-12 inp-focus-BP"
						style={{ height: 50 }}
						value={state.tripName}
						placeholder="Name your trip in something memorable"
						onChange={(e) => setstate({ ...state, tripName: e.target.value })}
					/>
					<h3
						className="mt-4 p-0 col-12"
						style={{ color: formMainTxt, fontSize: 14 }}
					>
						{languages[currentLanguage]["trip_description_coverphoto"]}{" "}
					</h3>
					<div className="row px-3 mb-4" style={{}}>
						<button
							style={{
								marginTop: 14,
								height: 137,
								backgroundImage: image?.uri ? `url(${image?.uri})` : "",
								backgroundPosition: "center",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								width: 380,
								borderRadius: 5,
								backgroundColor: !image?.uri ? whiteColor : "transparent",
								border: "1px dashed " + secondaryTxt,
							}}
							onClick={() => fileInputRef.current.click()}
						>
							{!image.uri && (
								<>
									<img src={addIcon} style={{ height: 14, width: 14 }} />
									<h3
										className="m-0 dromon-l"
										style={{
											color: formMainTxt,
											fontSize: 14,
											color: "#8f96a7",
										}}
									>
										{
											languages[currentLanguage][
												"trip_description_coverphoto_placeholder"
											]
										}
									</h3>
								</>
							)}
						</button>
						<input
							onChange={loadImage}
							multiple={false}
							ref={fileInputRef}
							type="file"
							hidden
						/>
					</div>
					<div className="mb-4 container">
						<div className="row">
							<h3
								className="mt-3 p-0 col-12"
								style={{ color: formMainTxt, fontSize: 14 }}
							>
								{languages[currentLanguage]["trip_description_description"]}
							</h3>
							<div className="">
								<textarea
									type="text"
									style={{ marginTop: 14, height: 107, width: 380 }}
									value={state.description}
									onChange={(e) =>
										setstate({
											...state,
											description: e.target.value,
										})
									}
									placeholder={
										languages[currentLanguage][
											"trip_description_description_placeholder"
										]
									}
								/>
							</div>
						</div>
					</div>

					<div className="pb-3">
						<h3
							className="mt-3 p-0 col-12"
							style={{ color: formMainTxt, fontSize: 14 }}
						>
							{languages[currentLanguage]["trip_review_privacy_header"]}
						</h3>
						<div>
							{options.map((op) => (
								<MultiselectCheck
									handleSelect={() => handleSelect(op)}
									text={
										languages[currentLanguage][op.replaceAll(":", "_")]
											? languages[currentLanguage][op.replaceAll(":", "_")]
											: op
									}
									key={op}
									selected={option === op}
								/>
							))}
						</div>
					</div>
					<div
						onClick={handleSubmitTrip}
						className="d-flex mb-4 justify-content-center align-items-center text-center"
						style={{
							position: "relative",
							height: 35,
							borderRadius: 5,
							cursor: "pointer",
							backgroundColor: filterHighlightColor,
						}}
					>
						<p
							className="mb-0 text-center"
							style={{ color: whiteColor, fontSize: 16 }}
						>
							{loadingSubmit ? (
								<i className="fa fa-circle-notch fa-spin mr-2" />
							) : (
								""
							)}{" "}
							{languages[currentLanguage]["common_saveclose"]}
						</p>
					</div>
				</div>
			</div>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={notice.type !== ""}
				autoHideDuration={4000}
				onClose={() => setNotice({ type: "", content: "" })}
			>
				<Alert
					onClose={() => setNotice({ type: "", content: "" })}
					variant="filled"
					severity={notice.type.toLowerCase()}
				>
					{notice.content}
				</Alert>
			</Snackbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sixth);

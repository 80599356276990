import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// CONSTANTS
import TRIP_USER_ROLES from "../constants/tripUserRoles";

/**
 * Methode to test if the param passed is empty or not
 *
 * @param {any} data Any type of value
 *
 * @returns {boolean} `boolean`
 */
export function isEmpty(data) {
	switch (typeof data) {
		case "object":
			for (var prop in data) {
				if (data.hasOwnProperty(prop)) {
					return false;
				}
			}
			return JSON.stringify(data) === JSON.stringify({}) || data === null;

		case "string":
			return !data && !data.trim().length && data !== null;

		case "number":
			return !data && !(data !== NaN);

		case "boolean":
			return !data;

		default:
			return true;
	}
}

/**
 * Method that copy passed string value to clipboard
 *
 * @param {string} stringValue
 * @param {() => any} onCopied
 *
 * @returns {void} `void`
 */
export const copyToClipboard = (stringValue, onCopied = () => {}) => {
	const el = window.document.createElement("textarea");
	el.value = stringValue;
	window.document.body.appendChild(el);
	el.select();
	window.document.execCommand("copy");
	window.document.body.removeChild(el);
	navigator.clipboard.writeText(stringValue).then(() => onCopied());
};

/**
 * Methode that return a boolean to check relative to app environment
 *
 *
 * @return {boolean} `Boolean`
 */
export function isInDev() {
	return (
		process.env.REACT_APP_ENVIRONMENT === "development" ||
		!process.env.REACT_APP_ENVIRONMENT
	);
}

/**
 * Check if response don't return error
 *
 * @param {object} _responseData data from api response
 *
 * @returns {boolean} ``boolean``
 */
export function isErrorResponse(_responseData) {
	if (
		isEmpty(_responseData) ||
		_responseData.ErrorCode ||
		_responseData.ErrorMessage ||
		_responseData.Error
	)
		return true;

	return false;
}

/**
 * Helper that return a boolean if user have or not role passed to params
 *
 * @param {string} userRole Current user role
 * @param {string[]} excludes Exluded roles
 * @param {string[]} only Only roles to test
 *
 * @returns {boolean} ``boolean``
 */
export function userHaveRole(userRole, excludes = [], only = []) {
	const MUST_BE = [];
	const ROLES = Object.keys(TRIP_USER_ROLES).map(
		(_role) => TRIP_USER_ROLES[_role]
	);

	if (userRole && typeof excludes === "object" && typeof only === "object") {
		if (only.length) {
			ROLES.map((_role) => {
				if (only.includes(_role)) MUST_BE.push(_role);
			});
		} else {
			ROLES.map((_role) => {
				if (!excludes.includes(_role)) MUST_BE.push(_role);
			});
		}

		return MUST_BE.includes(userRole);
	}

	return false;
}

/**
 *
 * @param {Object} object Object items that will be tested
 * @param {Array} except Field that will be excepted
 * @description Function that will test Object items one by one & return an object of empty fields
 *
 * @returns {string[]} ``string[]``
 */
export function testObjectItem(object = {}, except = []) {
	if (typeof object !== "object")
		return console.warn("This function require a object");

	let arrayKey = [];

	for (const key in object) {
		if (Object.hasOwnProperty.call(object, key)) {
			if (isEmpty(object[key])) arrayKey.push(key);
		}
	}
	return arrayKey;
}

/**
 * @constructor {Any} initialState reference;
 * @description That function create a new reference of à variable;
 */
export class createReference {
	constructor(initialState) {
		this.state = initialState;
	}
	get _() {
		return this.state;
	}
	setState(newState) {
		this.state = newState;
	}
}

export const isInt = (n) => {
	return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n) => {
	return Number(n) === n && n % 1 !== 0;
};

export const mobileCheck = () => {
	let check = false;
	(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true;
	})(navigator.userAgent || navigator.vendor || window.opera);
	return check;
};

// Validate.js
/**
 * @param {Object} object Fields object that will stringified
 * @param {Boolean} showNumber Optional param that allow to display number of errors (e.g: 1. <<variableName>> is empty)
 * @returns String
 */
export function stringifyErrors(object, showNumber = true) {
	let combinedError = "",
		i = 0;
	for (const key in object) {
		if (Object.hasOwnProperty.call(object, key)) {
			const array = object[key];
			array.forEach((error) => {
				combinedError +=
					(combinedError.length ? "\n" : "") +
					(showNumber ? `${++i}. ` : "") +
					error +
					" !";
			});
		}
	}
	return combinedError;
}

export function debounce(func, wait, immediate = false) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export function formatNativeDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

export const exportToXLSX = (csvData, fileName) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const ws = XLSX.utils.json_to_sheet(csvData);
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
};

export function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
	//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
	var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

	var CSV = "sep=," + "\r\n\n";
	//Set Report title in first row or line

	CSV += ReportTitle + "\r\n\n";

	//This condition will generate the Label/Header
	if (ShowLabel) {
		var row = "";

		//This loop will extract the label from 1st index of on array
		for (var index in arrData[0]) {
			//Now convert each value to string and comma-seprated
			row += index + ",";
		}

		row = row.slice(0, -1);

		//append Label row with line break
		CSV += row + "\r\n";
	}

	//1st loop is to extract each row
	for (var i = 0; i < arrData.length; i++) {
		var row = "";

		//2nd loop will extract each column and convert it in string comma-seprated
		for (var index in arrData[i]) {
			row += '"' + arrData[i][index] + '",';
		}

		row.slice(0, row.length - 1);

		//add a line break after each row
		CSV += row + "\r\n";
	}

	if (CSV === "") {
		alert("Invalid data");
		return;
	}

	//Generate a file name
	var fileName = "MyReport_";
	//this will remove the blank-spaces from the title and replace it with an underscore
	fileName += ReportTitle.replace(/ /g, "_");

	//Initialize file format you want csv or xls
	var uri = "data:text/csv;charset=utf-8," + escape(CSV);

	// Now the little tricky part.
	// you can use either>> window.open(uri);
	// but this will not work in some browsers
	// or you will not get the correct file extension

	//this trick will generate a temp <a /> tag
	var link = document.createElement("a");
	link.href = uri;

	//set the visibility hidden so it will not effect on your web-layout
	link.style = "visibility:hidden";
	link.download = fileName + ".csv";

	//this part will append the anchor tag and remove it after automatic click
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

// Moment.js
/**
 * @param {Date} dt needed date
 * @param {String} pattern of date format
 */
export function momentFormat(dt = new Date(), pattern = "DD/MM/YYYY HH:mm") {
	return moment(dt).format(pattern);
}

/**
 * @param {Date} dt needed date
 * @param {Boolean} bool for prefixing
 */
export function fromNow(dt = new Date(), bool = true) {
	return moment(dt).fromNow(bool);
}

/**
 * @param {Date} dt needed date
 * @param {Boolean} bool for prefixing
 */
export const getBuildDate = (epoch) => {
	const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
	return buildDate;
};

// End Moment.js

import styled from "styled-components";

export const TripBox = styled.div`
  z-index: 25;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 90px;
  overflow-y: scroll;
  right: 0;
`;

export const API_CONFIG = {
	apiUrl: "https://apidev.keeano.com",
	apiProdUrl: "https://webapi.keeano.com",
	authDevUrl: "https://authdev.keeano.com",
	authProdUrl: "https://auth.keeano.com",
	tileDevUrl:
		"https://tile-server.azurewebsites.net/api/tiles/keeano.cvphotosdev/{z}/{x}/{y}.mvt",
	tileProdUrl:
		"https://tileserver.keeano.com/api/tiles/keeano.cvphotos/{z}/{x}/{y}.mvt",
};

export const IDENTITY_CONFIG = {
	authority: "https://authdev.keeano.com",
	client_id: "js",
	redirect_uri: "https://localhost:44370/callback",
	response_type: "code",
	scope: "openid profile",
	post_logout_redirect_uri: "https://localhost:44370",
};

export const Keys = {
	facebookId: "140270766478904",
	googleClientId:
		"243002230193-d2mqpp05et5020uhp2vcuirdvd4g6qie.apps.googleusercontent.com",
	appleID: "com.keeano.applesignin",
	mapBoxKey:
		process.env.REACT_APP_ENVIRONMENT === "production"
			? "pk.eyJ1IjoiaWJvZ29yZG9zIiwiYSI6ImNrcWh3OXZzazAxa3oydnFzdTFibmM2cXkifQ.pPwhrXUdMHo5lf5uCdUO3A"
			: "pk.eyJ1IjoiaWJvZ29yZG9zIiwiYSI6ImNrcWMybG5mcTBjbmUydnA3dGpsZHNqY2QifQ.1-kIeuGf4gkFb4cEq0MvQg",
	branchKey:
		process.env.REACT_APP_ENVIRONMENT === "production"
			? "key_live_poHAFYVHhDXsQG1V12k6xpbmuBdcDuMT"
			: "key_test_bbRAE7POpE5wHG0PY7h8yhcbrBpdywKu",
};

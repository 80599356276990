import { CometChat } from "@cometchat-pro/chat";

// API
import { ApiClient } from "../ApiClient";

// HELPERS
import { AuthService } from "../AuthService";
import firebaseServices from "./firebaseServices";

const initFirebaseServices = async () => {
	// Check if user is connected & login to CometChat
	if (AuthService.isUserLogedIn()) {
		// Retrieve CometChat auth token
		const authToken = await ApiClient.getCometChatAuthToken();

		if (authToken && !authToken?.ErrorCode && !authToken.ErrorMessage) {
			CometChat.login(authToken.data[0].authToken).then(
				async (user) => {
					if (!!user && typeof user === "object")
						await firebaseServices({
							serverFcmTokenRegister:
								CometChat.registerTokenForPushNotification,
						});
				},
				(error) => {
					//console.log("Login failed with exception:", { error });
				}
			);
		} else {
			//console.log("ERROR MESSAGING ==>", authToken);
		}
	}
};

export default initFirebaseServices;

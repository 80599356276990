import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

// API
import { ApiClient } from "../../ApiClient";

// ACTIONS
import {
	closeUserSidebars,
	setUserData,
	showUserProfile,
} from "../../store/features/user/actions";

// SELECTORS
import {
	userData,
	sidebarEdit as sidebarEdit_,
} from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { currentLang } from "../../store/features/translation/selectors";

// COMPONENTS
import CardMultiSelect from "../FIlterModal/CardMultiSelect";

// STYLES
import { filterHighlightColor, whiteColor } from "../../constants/colors";
import { Sidebar } from "../BookmarkList/styled";
import { SidebarContent } from "../PlacesDetailsSidebar/styled";
import { DetailsContainer, Input, Tag } from "./styled";
import { useStyles } from "../map/Popup";
import { logEvent_ } from "../../helpers/analytics";

const EditProfile = ({
	visible,
	bookmarks,
	handleClick,
	handleDelete,
	setUserData,
	closeSidebar,
	closeUserSidebars,
	showUserProfile,
}) => {
	// STYLES
	const classes = useStyles();
	// SELECTORS
	const currentUserData = useSelector(userData),
		sidebarEdit = useSelector(sidebarEdit_),
		currentLanguage = useSelector(currentLang);

	// STATE
	const [user, setUser] = useState(JSON.parse(JSON.stringify(currentUserData)));
	const [userImage, setUserImage] = useState({ uri: null, file: null });
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [tags_, setTags] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	// FUNCTIONS
	const handleSelectTag = (tagName) => {
		switch (tagName) {
			case "uri:user:tag:interests:cultural":
				// EVENT
				logEvent_("react_user_tags_culture_selected");
				break;
			case "uri:user:tag:interests:fishing":
				// EVENT
				logEvent_("react_user_tags_fishing_selected");
				break;
			case "uri:user:tag:interests:hiking":
				// EVENT
				logEvent_("react_user_tags_hiking_selected");
				break;
			case "uri:user:tag:interests:kayak":
				// EVENT
				logEvent_("react_user_tags_kayak_selected");
				break;
			case "uri:user:tag:interests:sailing":
				// EVENT
				logEvent_("react_user_tags_sailing_selected");
				break;
			case "uri:user:tag:interests:scubadiving":
				// EVENT
				logEvent_("react_user_tags_scubadiving_selected");
				break;
			case "uri:user:tag:interests:snorkeling":
				// EVENT
				logEvent_("react_user_tags_snorkeling_selected");
				break;
			case "uri:user:tag:interests:spearfishing":
				// EVENT
				logEvent_("react_user_tags_spearfishing_selected");
				break;
			case "uri:user:tag:interests:sup":
				// EVENT
				logEvent_("react_user_tags_sup_selected");
				break;
			case "uri:user:tag:interests:watersports":
				// EVENT
				logEvent_("react_user_tags_watersports_selected");
				break;

			default:
				break;
		}
		let actualTags = user.Tags;

		if (user?.Tags?.includes(tagName))
			actualTags = actualTags.filter((item) => item !== tagName);
		else actualTags.push(tagName);

		setUser({
			...user,
			Tags: actualTags,
		});
	};

	function loadUserImage(e) {
		const URI = URL.createObjectURL(e.target.files[0]);

		setUserImage({ uri: URI, file: e.target.files[0] });
	}

	const onSubmit = async () => {
		const promises = [];

		if (userImage.uri && userImage.file) {
			setLoadingSubmit(true);
			let photoServices;
			let uploadUserPhotoResponse;

			await ApiClient.getPhotoServiceSignature().then(
				(res) => (photoServices = res)
			);
			await ApiClient.uploadUserPhoto(
				photoServices.UploadUrl,
				userImage.file
			).then((res) => (uploadUserPhotoResponse = res));
			if (uploadUserPhotoResponse === null)
				await ApiClient.putUserPhoto(photoServices.Url).then((res) => {});
		}

		setLoadingSubmit(true);
		promises.push(
			ApiClient.updateBasicUserProfile(
				user.FirstName,
				user.LastName,
				user.UserName,
				user.DateOfBirth,
				user.Nationality,
				user.Url,
				user.Description
			)
		);
		promises.push(ApiClient.updateTags(user.Tags));

		Promise.all(promises)
			.then((res) => {
				setNotice({
					type: "success",
					content: languages[currentLanguage]["profile_message_settings_saved"],
				});
				ApiClient.getUser().then((userData) => setUserData(userData));
			})
			.catch((err) => {
				setNotice({
					type: "error",
					content:
						languages[currentLanguage]["common_error_something_went_wrong"],
				});
			})
			.finally(() => {
				setLoadingSubmit(false);
			});
	};

	// EFFECTS
	useEffect(() => {
		setUser(JSON.parse(JSON.stringify(currentUserData)));
	}, [currentUserData, sidebarEdit]);

	useEffect(() => {
		setLoading(true);
		Promise.all([ApiClient.getUserTags()])
			.then((res) => {
				if (!res[0].ErrorCode && !res[0].Error) setTags(res[0]);
				else
					setNotice({
						type: "ERROR",
						content: languages[currentLanguage][res[0].ErrorMessage]
							? languages[currentLanguage][res[0].ErrorMessage]
							: res[0].ErrorMessage,
					});
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<Sidebar show={visible} className="pb-4">
			<SidebarContent>
				{loading ? (
					<div className="d-flex h-100 w-100 justify-content-center align-items-center">
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<>
						<div
							style={{
								height: 170,
								backgroundColor: "#f6f6f6",
								paddingTop: 70,
								backgroundImage: `url(${require("../../assets/svg/hero-ve-bg.svg")})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						>
							<div
								style={{ paddingLeft: 22 }}
								className="d-flex flex-row justify-content-start"
							>
								<div
									className="d-flex align-items-center justify-content-center"
									style={{
										height: 150,
										width: 150,
										borderRadius: 15,
										overflow: "hidden",
										position: "relative",
										backgroundImage: `url(${
											userImage.uri ? userImage.uri : user?.Photo
										})`,
										backgroundColor: "#ddd",
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
								>
									<IconButton
										onClick={() => {
											const pickerInp =
												window.document.getElementById("pickerImage");
											pickerInp.click();
										}}
										style={{
											backgroundColor: "rgba(0,0,0,0.5)",
											color: "#FFF",
											position: "absolute",
											top: "50%",
											transform: "translateY(-50%)",
											fontSize: 14,
										}}
									>
										<img
											src={require("../../assets/edit-white-ic.svg")}
											style={{ width: 14, height: 14 }}
										/>
									</IconButton>
									<input
										type="file"
										id="pickerImage"
										className="d-none"
										onChange={loadUserImage}
										accept="image/gif, image/jpeg, image/png"
									/>
								</div>
							</div>
						</div>
						<DetailsContainer className="px-0">
							<div
								className="container-fluid px-4 py-4"
								style={{
									borderTop: "solid 1px rgba(232, 232, 232, 0.5)",
									borderBottom: "solid 1px rgba(232, 232, 232, 0.5)",
								}}
							>
								<div
									className="row align-items-center"
									style={{ color: "#9096a6", fontSize: 14 }}
								>
									<p className="mb-0 col-5 text-left">
										{languages[currentLanguage]["profile_edit_fname"]}
									</p>
									<Input
										style={{
											color: "#3d4661",
											fontFamily: "Dromon-light",
										}}
										className="col p-2"
										type="text"
										placeholder="Your first name"
										value={user?.FirstName}
										onChange={(e) =>
											setUser({ ...user, FirstName: e.target.value })
										}
									/>
								</div>
								<div
									className="row align-items-center"
									style={{ color: "#9096a6", fontSize: 14 }}
								>
									<p className="mb-0 col-5 text-left">
										{languages[currentLanguage]["auth_signup_lname"]}
									</p>
									<Input
										style={{
											color: "#3d4661",
											fontFamily: "Dromon-light",
										}}
										className="col p-2"
										type="text"
										placeholder="Your last name"
										value={user?.LastName}
										onChange={(e) =>
											setUser({ ...user, LastName: e.target.value })
										}
									/>
								</div>
								<div
									className="row align-items-center"
									style={{ color: "#9096a6", fontSize: 14 }}
								>
									<p className="mb-0 col-5 text-left">
										{languages[currentLanguage]["profile_edit_username"]}
									</p>
									<Input
										style={{
											color: "#3d4661",
											fontFamily: "Dromon-light",
										}}
										className="col p-2"
										type="text"
										placeholder="Username"
										value={user?.UserName}
										onChange={(e) =>
											setUser({ ...user, UserName: e.target.value })
										}
									/>
								</div>
								<div
									className="row align-items-center"
									style={{ color: "#9096a6", fontSize: 14 }}
								>
									<p className="mb-0 col-5 text-left">
										{languages[currentLanguage]["profile_edit_link"]}
									</p>
									<Input
										style={{
											color: "#3d4661",
											fontFamily: "Dromon-light",
										}}
										className="col p-2"
										type="text"
										placeholder="Your network link"
										value={user?.Url}
										onChange={(e) => setUser({ ...user, Url: e.target.value })}
									/>
								</div>
								<div
									className="row align-items-center"
									style={{ color: "#9096a6", fontSize: 14 }}
								>
									<p className="mb-0 col-5 text-left">
										{languages[currentLanguage]["profile_edit_bio"]}
									</p>
									<Input
										style={{
											color: "#3d4661",
											fontFamily: "Dromon-light",
											borderBottom: "none",
										}}
										className="col p-2"
										type="text"
										placeholder="Your Bio"
										multiline
										aria-rowspan={4}
										aria-multiline="true"
										value={user?.Description}
										onChange={(e) =>
											setUser({ ...user, Description: e.target.value })
										}
									/>
								</div>
							</div>
							<div className="px-4 mt-4">
								<p
									className="mb-1"
									style={{
										fontSize: 14,
										fontWeight: "normal",
										color: "#222c4b",
									}}
								>
									{languages[currentLanguage]["profile_edit_about"]}
								</p>
								<div className="container-fluid">
									<div className="w-100 d-flex flex-wrap">
										{tags_?.map((tag, index) => (
											<CardMultiSelect
												key={index}
												onClick={() => handleSelectTag(tag)}
												disable={false}
												active={user?.Tags?.includes(tag)}
												label={
													languages[currentLanguage][tag.replaceAll(":", "_")]
														? languages[currentLanguage][
																tag.replaceAll(":", "_")
														  ]
														: tag
												}
											/>
										))}
									</div>
								</div>
							</div>
							<div className="px-4">
								<Button
									className="p-1 col-12 mt-2"
									onClick={onSubmit}
									disabled={loadingSubmit}
									style={{
										fontFamily: "inherit",
										color: whiteColor,
										backgroundColor: filterHighlightColor,
										border: "none",
										fontSize: 14,
										textTransform: "capitalize",
										borderRadius: 5,
									}}
								>
									{loadingSubmit ? (
										<i className="fa fa-circle-notch fa-spin mr-2" />
									) : (
										""
									)}{" "}
									{languages[currentLanguage]["user_edit_save_profile_button"]}
								</Button>
								<Button
									className="p-1 col-12 mt-2"
									onClick={() => {
										closeSidebar();
										setUserImage({ uri: null, file: null });
									}}
									style={{
										fontFamily: "inherit",
										color: filterHighlightColor,
										backgroundColor: whiteColor,
										border: `1px solid ${filterHighlightColor}`,
										fontSize: 14,
										textTransform: "capitalize",
										borderRadius: 5,
									}}
								>
									{languages[currentLanguage]["common_cancel"]}
								</Button>
							</div>
						</DetailsContainer>
					</>
				)}
			</SidebarContent>
			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 20, left: 20 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
			{/*<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={notice.type !== ""}
				autoHideDuration={2000}
				onClose={() => setNotice({ type: "", content: "" })}
				message={notice.content}
			/>*/}
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserData: (payload) => dispatch(setUserData(payload)),
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

import React, { useState } from "react";
import { AuthService } from "./AuthService";

export const UserContext = React.createContext(null);

export function UserProvider(props) {
	//state
	const [isAuthenticated, setIsAuthenticated] = useState(() => {
		return AuthService.isUserLogedIn();
	});
	const [userState, setUserState] = useState({
		email: "keeano",
		isAuthenticated: false,
	});

	//Derived state

	//callbacks
	function HandleOnHaveSession(session) {
		setIsAuthenticated(session.status);
	}

	//Registration
	AuthService.setHaveSession(HandleOnHaveSession);

	const contextValue = { isAuthenticated, userState, setUserState };
	return (
		<UserContext.Provider value={contextValue}>
			{props.children}
		</UserContext.Provider>
	);
}

export const UserConsumer = UserContext.Consumer;

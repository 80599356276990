import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import Aos from "aos";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CometChat } from "@cometchat-pro/chat";

// CONSTANTS
import {
	APP_ID as COMET_CHAT_APP_ID,
	REGION as COMET_CHAT_REGION,
} from "../constants/cometchat";

// API
import { ApiClient } from "../ApiClient";

// ACTIONS
import {
	getMapFilters,
	getPlaceAttributes,
	getTripAttributes,
} from "../store/features/map/actions";
import { setUserData } from "../store/features/user/actions";
import { switchLang } from "../store/features/translation/actions";
import {
	showNotification,
	hideNotification,
} from "../store/features/notification/actions";

// HELPERS
import initFirebaseServices from "../helpers/initFirebaseServices";

// SELECTORS
import { filtersSelected } from "../store/features/map/selectors";
import { getNotification } from "../store/features/notification/selectors";

// VIEWS
import Home from "../components/Home";
import Explore from "../components/map/Explore";
import TermsConditionsView from "../Views/TermsConditions.view";
import PrivacyPolicyView from "../Views/PrivacyPolicy.view";
import CookiesPolicyView from "../Views/CookiesPolicy.view";
import FAQ from "../Views/FAQ";

// COMPONENTS
import { Layout } from "../components/Layout";

// STYLE
import "../assets/css/router.css";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "../components/map/Popup";

const MainRoute = ({
	getMapFilters,
	setUserData,
	switchLang,
	getPlaceAttributes,
	getTripAttributes,
	notification,
	showNotification,
	hideNotification,
}) => {
	// styles
	const classes = useStyles();
	const [loadingData, setLoadingData] = React.useState(true),
		[hasError, setHasError] = React.useState(false);
	const cometChatAppSetting = new CometChat.AppSettingsBuilder()
		.subscribePresenceForAllUsers()
		.setRegion(COMET_CHAT_REGION)
		.build();

	// EFFECTS
	React.useEffect(() => {
		Promise.all([
			getMapFilters(),
			ApiClient.getUser(),
			getPlaceAttributes(),
			getTripAttributes(),
			CometChat.init(COMET_CHAT_APP_ID, cometChatAppSetting),
		])
			.then((data) => {
				const defaultLanguage = window.localStorage.getItem("language")
					? JSON.parse(window.localStorage.getItem("language")).language
					: "EN";
				setHasError(!data[0].type);
				if (data[1]) {
					setUserData(data[1]);
					initFirebaseServices();
				}
				switchLang(defaultLanguage);
				if (!data[0].type && data[0].type === null) {
					if (process.env.REACT_APP_ENVIRONMENT === "production") {
						window.location.replace("https://keeano.com");
					}
				}
			})
			.catch((err) => {
				if (
					process.env.REACT_APP_ENVIRONMENT === "development" ||
					!process.env.REACT_APP_ENVIRONMENT
				)
					console.log(err);
			})
			.then(() => {
				Aos.init();
				window.showNotification = (data = {}) => showNotification(data);
				setLoadingData(false);
			});
		return () => {};
	}, []);

	return (
		<>
			{!loadingData ? (
				<>
					{!hasError && (
						<Layout>
							<Router>
								<Route
									render={({ location }) => (
										<Switch location={location}>
											<Route exact path="/" component={Home} />
											<Route path="/explore" component={Explore} />
											<Route
												exact
												path="/legal/terms-conditions"
												component={TermsConditionsView}
											/>
											<Route
												exact
												path="/legal/privacy-policy"
												component={PrivacyPolicyView}
											/>
											<Route
												exact
												path="/legal/cookies-policy"
												component={CookiesPolicyView}
											/>
											<Route exact path="/virtual-guide/FAQ" component={FAQ} />
											<Route exact path="*">
												<Redirect to="/" />
											</Route>
										</Switch>
									)}
								/>
							</Router>

							{/* Snackbar */}
							<Snackbar
								open={notification?.visible}
								anchorOrigin={{
									vertical: notification?.positionY,
									horizontal: notification?.positionX,
								}}
								autoHideDuration={notification?.duration}
								onClose={hideNotification}
							>
								<Alert
									onClose={hideNotification}
									severity={notification?.severity}
								>
									{notification?.message}
								</Alert>
							</Snackbar>
						</Layout>
					)}
				</>
			) : (
				<div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center loading-view">
					<div>
						<img src={require("../assets/svg/keeano-icon.svg")} />
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	notification: getNotification(state),
});

const mapDispatchToProps = (dispatch) => ({
	getMapFilters: () => dispatch(getMapFilters()),
	getTripAttributes: () => dispatch(getTripAttributes()),
	getPlaceAttributes: () => dispatch(getPlaceAttributes()),
	setUserData: (payload) => dispatch(setUserData(payload)),
	switchLang: (payload) => dispatch(switchLang(payload)),
	showNotification: (payload) => dispatch(showNotification(payload)),
	hideNotification: () => dispatch(hideNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute);

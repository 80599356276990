import styled, { css } from "styled-components";
import IBtn from "@material-ui/core/IconButton";

export const BoxFilter = styled.div`
  background-color: white;
  box-shadow: 7px 0 7px 0 rgba(0, 0, 0, 0.03);
  position: absolute;
  z-index: 19;
  top: 55px;
  bottom: 0;
  height: calc(100vh-70px);
  width: 415px;
  left: -415px;
  overflow: hidden auto;
	visibility: hidden;
  -ms-overflow-style: scrollbar;
	scrollbar-color:  #222c4b #FFF;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8f96a7;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #222c4b;
  }
  opacity: 0.5;
  transition: all 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${(props) =>
		props.show &&
		css`
      left: 0;
      opacity: 1;
			visibility: visible;
    `}
`;

export const Overlay = styled.div`
	position: absolute;
	top: 55px;
	left: 0;
	width: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, .4);
	opacity: 0.5;
  transition: all 0s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	z-index: 4;
	 ${(props) =>
		props.show &&
		css`
      width: 100%;
      opacity: 1;
    `}
`;

export const CloseModalBtn = styled(IBtn)`
  border: none;
  background-color: white;
  position: absolute;
  right: 16px;
  top: 24px;
`;

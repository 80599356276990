// TYPES
import {
	USER_SET,
	USER_SET_PROFILE_ID,
	USER_SET_DATA,
	USER_SET_SETTINGS,
	USER_SET_VESSELS,
	USER_AUTH_VIEW,
	USER_SIDEBAR_PROFILE,
	USER_SIDEBAR_SETTINGS,
	USER_SIDEBAR_VESSELS,
	USER_SIDEBAR_PARTNER_PROVISION,
	USER_CLOSE_SIDEBARS,
	USER_CLEAN,
	USER_SIDEBAR_EDIT,
} from "./types";

export function setUser(payload) {
	return {
		type: USER_SET,
		payload,
	};
}

export function setUserProfileId(payload) {
	return {
		type: USER_SET_PROFILE_ID,
		payload,
	};
}

export function setUserData(payload) {
	return {
		type: USER_SET_DATA,
		payload,
	};
}

export function setUserSettings(payload) {
	return {
		type: USER_SET_SETTINGS,
		payload,
	};
}

export function setUserVessels(payload) {
	return {
		type: USER_SET_VESSELS,
		payload,
	};
}

export function showUserAuthView() {
	return {
		type: USER_AUTH_VIEW,
	};
}

export function showUserProfile() {
	return {
		type: USER_SIDEBAR_PROFILE,
	};
}

export function showUserEdit() {
	return {
		type: USER_SIDEBAR_EDIT,
	};
}

export function showUserSettings() {
	return {
		type: USER_SIDEBAR_SETTINGS,
	};
}

export function showPartnerProvision() {
	return {
		type: USER_SIDEBAR_PARTNER_PROVISION,
	};
}

export function showUserVessels() {
	return {
		type: USER_SIDEBAR_VESSELS,
	};
}

export function closeUserSidebars() {
	return {
		type: USER_CLOSE_SIDEBARS,
	};
}

export function resetUser() {
	return {
		type: USER_CLEAN,
	};
}

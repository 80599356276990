import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Slider, Snackbar, CircularProgress } from "@material-ui/core";
import SelectSearch from "react-select-search";

// API
import { ApiClient } from "../../../ApiClient";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { userData } from "../../../store/features/user/selectors";

// HELPERS
import { isEmpty } from "../../../helpers/functions";
import { convertSpeedToMyUnit } from "../../../helpers/UnitConverter";
import { logEvent_ } from "../../../helpers/analytics";

// CONSTANTS
import { filterHighlightColor, formMainTxt } from "../../../constants/colors";

// COMPONENTS
import Button from "../Components/Button";
import CardVessels from "../../FIlterModal/CardVessels";

// ADDITIONAL ICONS
import kayak from "../../../assets/svg/tripWizard/wiz_kayak_active_ic.svg";
import motorboat from "../../../assets/svg/tripWizard/wiz_motorboat_active_ic.svg";
import sailboat from "../../../assets/svg/tripWizard/wiz_sailboat_active_ic.svg";
import kayak_ from "../../../assets/svg/tripWizard/wiz_kayak_selected_ic.svg";
import motorboat_ from "../../../assets/svg/tripWizard/wiz_motorboat_selected_ic.svg";
import sailboat_ from "../../../assets/svg/tripWizard/wiz_sailboat_selected_ic.svg";
import beach_ from "../../../assets/svg/placeWhiteIcons/beach.svg";
import anchorage_ from "../../../assets/svg/placeWhiteIcons/anchorage.svg";
import cave_ from "../../../assets/svg/placeWhiteIcons/cave.svg";
import mooring_ from "../../../assets/svg/placeWhiteIcons/mooring.svg";
import marina_ from "../../../assets/svg/placeWhiteIcons/marina.svg";
import port_ from "../../../assets/svg/placeWhiteIcons/port.svg";
import wreck_ from "../../../assets/svg/placeWhiteIcons/wreck.svg";
import fishingarea_ from "../../../assets/svg/placeWhiteIcons/fishingarea.svg";
import shipyard_ from "../../../assets/svg/placeWhiteIcons/shipyard.svg";
import transitional_anchorage_ from "../../../assets/svg/placeWhiteIcons/transitional_anchorage.svg";
import seaplaneport_ from "../../../assets/svg/placeWhiteIcons/seaplaneport.svg";
import boatramp_ from "../../../assets/svg/placeWhiteIcons/boatramp.svg";
import boatyard_ from "../../../assets/svg/placeWhiteIcons/boatyard.svg";

// INTERNAL CONSTANTS
export const iconDictionary = {
	kayak,
	kayak_,
	motorboat,
	motorboat_,
	sailboat,
	sailboat_,
	beach_,
	anchorage_,
	cave_,
	mooring_,
	marina_,
	port_,
	wreck_,
	fishingarea_,
	shipyard_,
	transitional_anchorage_,
	seaplaneport_,
	boatramp_,
	boatyard_,
};

const First = (props) => {
	// STATES
	const currentLanguage = useSelector(currentLang);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [, setChecked] = useState(false);
	const [value, setValue] = useState(10);
	const [valueSpeed, setValueSpeed] = useState(10);
	const [state, setState] = useState({
		allVessels: [
			{ key: "kayak", text: "sea kayak" },
			{ key: "motorboat", text: "motorboat" },
			{ key: "sailboat", text: "sailing boat" },
		],
		selectedVessel:
			props.state?.VesselType !== "" && props.state?.VesselType
				? languages["EN"][props.state?.VesselType]
				: "kayak",
		speedValue: props.state?.VesselLengthMeters ? props.state?.VesseyType : 10,
		lengthValue: props.state?.VesselSpeedKnots
			? props.state?.VesselSpeedKnots
			: 10,
	});
	const [loading, setLoading] = useState(true);
	const [VesselId, setVesselId] = useState(null);
	const [vessels, setVessels] = useState([]);

	// DATA
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};

	// FUNCTIONS
	const onSliderSpeedValueChange = (e, newValue) => {
		setValueSpeed(newValue);
		setChecked(false);
		setState({
			...state,
			speedValue: newValue,
		});
	};

	const onSliderValueChange = (e, newValue) => {
		setValue(newValue);
		setChecked(false);
		setState({
			...state,
			lengthValue: newValue,
		});
	};

	const handleSwitch = (vesselId) => {
		let selectedVess = vessels.filter((item) => item.ID === vesselId)[0];

		if (selectedVess) {
			var length = selectedVess?.LengthMeters
				? selectedVess?.LengthMeters?.toFixed(0)
				: 10;
			var speed = selectedVess?.SpeedKnots
				? Number.parseFloat(
						convertSpeedToMyUnit(selectedVess?.SpeedKnots, "kts")
				  ).toFixed(0)
				: 10;

			setState({
				...state,
				selectedVessel: selectedVess?.Type,
				lengthValue: length,
				speedValue: speed,
			});
			setValue(length);
			setValueSpeed(speed);
			setChecked(true);
			setVesselId(selectedVess.ID);
		} else {
			setNotice({
				type: "WARNING",
				content: languages[currentLanguage]["user_vessels_emptystate_header"],
			});
		}
	};

	const onSelectVesselType = (vessel) => {
		// UPDATE THE STATE
		setState({
			...state,
			selectedVessel: vessel,
		});
		setChecked(false);
	};

	const handleBack = () => {
		props.handleBack({
			step: 1,
		});
		// props.onCancel()
	};

	const handleNext = () => {
		logEvent_("react_tripwiz_stepvessel_nextbtn_pressed");

		props.handleNext({
			step: 1,
			VesselId,
			type: state.selectedVessel,
			speed: state.speedValue,
			length: state.lengthValue,
		});
	};

	// EFFECTS
	useEffect(() => {
		if (props.visible && props.wizardV) {
			setState({
				allVessels: [
					{ key: "kayak", text: "sea kayak" },
					{ key: "motorboat", text: "motorboat" },
					{ key: "sailboat", text: "sailing boat" },
				],
				selectedVessel:
					props.state?.VesselType !== "" && props.state?.VesselType
						? props.state?.VesselType
						: "kayak",
				speedValue: props.state?.VesselSpeedKnots
					? props.state?.VesselSpeedKnots
					: 10,
				lengthValue: props.state?.VesselLengthMeters
					? props.state?.VesselLengthMeters
					: 10,
			});
			setVesselId(props.state?.VesselId);
			setValueSpeed(
				props.state?.VesselSpeedKnots ? props.state?.VesselSpeedKnots : 10
			);
			setValue(
				props.state?.VesselLengthMeters ? props.state?.VesselLengthMeters : 10
			);
			setChecked(false);
			getVessels();
		}
	}, [props.state, props.finished, props.wizardV, props.visible]);

	// useEffect(() => {
	// 	if (props.visible) {
	// 		getVessels();
	// 	}
	// 	return () => {};
	// }, []);

	// useEffect(() => {
	// 	if (props.wizardV) {
	// 		getVessels();
	// 	}
	// }, [props.wizardV]);

	const getVessels = () => {
		setLoading(true);
		const currentUserRole = props.state?.currentUserRole ?? "n/a";
		if (currentUserRole === "creator" || currentUserRole === "n/a") {
			ApiClient.getMyVessels()
				.then((vessels) => {
					const customizedUserVessels = [];
					vessels.forEach((vessel) => {
						vessel.Type = vessel.Type.replace("community", "").replace(
							"business",
							""
						);
						customizedUserVessels.unshift({
							...vessel,
							name:
								(!isEmpty(vessel.Name) ? vessel.Name : vessel.Type) +
								(!isEmpty(vessel.Flag) ? ` (${vessel.Flag})` : ""),
							value: vessel.ID,
						});
					});
					setVessels(customizedUserVessels);
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		} else {
			if (props.state?.creatorUserId) {
				ApiClient.getOtherUserVessels(props.state?.creatorUserId, true)
					.then((vessels) => {
						const customizedUserVessels = [];
						vessels.forEach((vessel) => {
							vessel.Type = vessel.Type.replace("community", "").replace(
								"business",
								""
							);
							customizedUserVessels.unshift({
								...vessel,
								name:
									(!isEmpty(vessel.Name) ? vessel.Name : vessel.Type) +
									(!isEmpty(vessel.Flag) ? ` (${vessel.Flag})` : ""),
								value: vessel.ID,
							});
						});
						setVessels(customizedUserVessels);
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	return (
		<div className={props.visible ? "pb-4" : "d-none"}>
			<div
				className={
					props.visible
						? "px-0 d-flex flex-column justify-content-space-between step-body"
						: "d-none"
				}
				style={{ width: 384 }}
			>
				<div style={{ marginBottom: 42 }}>
					<h3
						className="my-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						{props.state?.Type === "business" &&
						props.state?.State !== "template"
							? languages[currentLanguage]["trip_create_new_business_trip"]
							: languages[currentLanguage]["trip_create_new_template_trip"]}
					</h3>
					<h2 style={{ fontSize: 22, color: "#222c4b" }}>
						{languages[currentLanguage]["trip_attr_vesseltype_header"]}
					</h2>
				</div>

				<div className="mb-4">
					{!loading ? (
						<SelectSearch
							className="select-search"
							options={vessels}
							value={VesselId}
							name="Vessel Picker"
							autoComplete="off"
							placeholder={languages[currentLanguage]["trip_choose_vessel"]}
							onChange={(value) => handleSwitch(value)}
							emptyMessage="No data"
							search
							renderOption={(item, options, snpOption, className) => {
								const vessel = vessels.filter(
									(item_) => item_.ID === item.value
								)[0];

								return (
									<button
										{...item}
										key={item.tabIndex}
										className={"d-flex align-items-center " + className}
									>
										<div
											className="rounded-circle overflow-hidden shadow-sm d-flex justify-content-center align-items-center mr-2"
											style={{ height: 25, width: 25 }}
										>
											{vessel?.Type && (
												<img
													src={
														iconDictionary[
															vessel.Type + (snpOption.selected ? "_" : "")
														]
													}
													style={{ height: 20, width: 20 }}
												/>
											)}
										</div>

										<span>
											{(!isEmpty(vessel.Name) ? vessel.Name : vessel.Type) +
												(!isEmpty(vessel.Flag) ? ` (${vessel.Flag})` : "")}
										</span>
									</button>
								);
							}}
						/>
					) : (
						<div className="d-flex justify-content-center align-items-center">
							<CircularProgress size={30} color="primary" />
						</div>
					)}
				</div>

				<div className="d-flex justify-content-between align-items-center">
					<h3 className="mb-0" style={titleStyle}>
						{languages[currentLanguage]["trip_attr_vesseltype_subheader"]}
					</h3>
				</div>

				<div style={{ marginTop: 15 }}>
					<div className="col-12 px-0">
						<div className="row justify-content-between">
							{state.allVessels.map((place_) => (
								<CardVessels
									key={place_.key}
									icon={iconDictionary[place_.key]}
									active={state.selectedVessel === place_.key}
									type={place_.key}
									activeIcon={
										iconDictionary[`${place_.key}_`]
											? iconDictionary[`${place_.key}_`]
											: iconDictionary[place_.key]
									}
									disabled={true}
									trip
									label={place_.text}
									onClick={() => onSelectVesselType(place_.key)}
								/>
							))}
						</div>
					</div>
				</div>

				<div
					className="d-flex flex-row justify-content-between"
					style={{ alignItems: "center", width: "100%", marginTop: 36 }}
				>
					<h3
						style={{
							fontWeight: "500",
							fontSize: 12,
							color: "#222c4b",
							marginBottom: 0,
						}}
					>
						{languages[currentLanguage]["trip_attr_vesseltype_length_label"]}
					</h3>
					<span
						className="d-flex justify-content-center align-items-center px-2"
						style={{
							color: filterHighlightColor,
							fontSize: 12,
							padding: "3px 7px",
							height: 24,
							borderRadius: 5,
						}}
					>
						{value}m
					</span>
					<Slider
						min={2}
						max={50}
						value={value}
						style={{ width: 218, color: filterHighlightColor }}
						onChange={onSliderValueChange}
						disabled
					/>
				</div>

				<div
					className="d-flex flex-row justify-content-between"
					style={{
						alignItems: "center",
						width: "100%",
						marginTop: 36,
					}}
				>
					<h3
						style={{
							fontWeight: "500",
							fontSize: 12,
							color: "#222c4b",
							marginBottom: 0,
						}}
					>
						{languages[currentLanguage]["trip_attr_vesseltype_speed_label"]}
					</h3>
					<span
						className="d-flex justify-content-center align-items-center px-2"
						style={{
							color: filterHighlightColor,
							fontSize: 12,
							padding: "3px 7px",
							height: 24,
							borderRadius: 5,
						}}
					>
						{valueSpeed}kts
					</span>
					<Slider
						min={2}
						max={50}
						value={valueSpeed}
						style={{ width: 218, color: filterHighlightColor }}
						onChange={onSliderSpeedValueChange}
						disabled
					/>
				</div>
			</div>

			<div
				style={{ width: 384 }}
				className="d-flex flex-row justify-content-between px-0"
			>
				<Button text="Back" handleBack={handleBack} disabled />
				<Button
					text={languages[currentLanguage]["common_next"]}
					next
					handleNext={handleNext}
				/>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={notice.type !== ""}
					autoHideDuration={2000}
					onClose={() => setNotice({ type: "", content: "" })}
					message={notice.content}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(First);

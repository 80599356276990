import React, { useEffect, useState } from "react";
import { useSelector, connect } from "react-redux";
import {
	IconButton,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogContentText,
	Snackbar,
	Tooltip,
	DialogActions,
} from "@material-ui/core";
import branch from "branch-sdk";

// API
import { ApiClient } from "../../ApiClient";
import { AuthService } from "../../AuthService.js";

// SELECTORS
import {
	userData,
	userProfileId,
	sidebarProfile,
} from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages/";
import { currentLang } from "../../store/features/translation/selectors";

// ACTIONS
import {
	showUserEdit,
	showUserSettings,
	showUserVessels,
	showPartnerProvision,
	closeUserSidebars,
} from "../../store/features/user/actions";

// HELPERS
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { copyToClipboard, isEmpty } from "../../helpers/functions";
import settingIcon from "../../assets/settings_ic.svg";

// STYLES
import { filterHighlightColor, formMainTxt } from "../../constants/colors";
import { SidebarContent } from "../PlacesDetailsSidebar/styled";
import { DetailsContainer, Tag } from "./styled";
import { useStyles } from "../map/Popup";
import { Sidebar } from "../BookmarkList/styled";
import { generateUserProfileShareLink } from "../../shareConfig";

// ICONS
const ArrowIcon = require("../../assets/back_ic.svg");
const CopyIcon = require(`../../assets/copy-ic.svg`);
const CloseIcon = require("../../assets/close.svg");
const ShareIcon = require("../../assets/share-ic-blue.svg");

const Profile = ({
	visible,
	showUserSettings,
	showUserVessels,
	showUserEdit,
	showBookmarks,
	showPartnerProvision,
	closeUserSidebars,
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentUserData = useSelector(userData),
		selectedUserProfileId = useSelector(userProfileId),
		sidebarVisibility = useSelector(sidebarProfile),
		currentLanguage = useSelector(currentLang);

	// STATE
	const [user, setUser] = useState(null);
	const [isOwner, setIsOwner] = useState(false);
	const [loading, setLoading] = useState(false);
	const [generatedUserLink, setGeneratedUserLink] = React.useState(null);
	const [snackbarClipboard, setSnackbarClipboard] = React.useState(false);

	// FUNCTIONS
	const shareUserProfile = () => {
		const linkData = generateUserProfileShareLink(user);
		branch.link(linkData, function (err, link) {
			setGeneratedUserLink(link);
		});
	};

	// EFFECTS
	useEffect(() => {
		if (sidebarVisibility) {
			if (!selectedUserProfileId || isEmpty(selectedUserProfileId)) {
				closeUserSidebars();
				return () => {};
			}

			if (selectedUserProfileId === currentUserData.ID) {
				setUser(JSON.parse(JSON.stringify(currentUserData)));
				var new_url =
					"https://" +
					window.location.host +
					"/explore/user/" +
					(typeof currentUserData.UserName === "string"
						? currentUserData.UserName.replace(
								new RegExp(/[ ]/, "g"),
								"-"
						  ).toLowerCase()
						: "");
				window.history.pushState(null, null, new_url);
				setIsOwner(true);
			} else {
				setLoading(true);
				setIsOwner(false);
				ApiClient.getOtherUserById(selectedUserProfileId)
					.then((userData) => {
						setUser(userData);
						var new_url =
							"https://" +
							window.location.host +
							"/explore/user/" +
							(typeof userData.UserName === "string"
								? userData.UserName.replace(
										new RegExp(/[ ]/, "g"),
										"-"
								  ).toLowerCase()
								: "");
						window.history.pushState(null, null, new_url);
					})
					.catch((err) => {})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	}, [sidebarVisibility, selectedUserProfileId]);

	return (
		<Sidebar show={visible} className="pb-4">
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackbarClipboard}
				autoHideDuration={2000}
				onClose={() => setSnackbarClipboard(false)}
				message="Copied to clipboard"
			/>

			<div className="w-100 position-relative">
				<IconButton
					onClick={() => {
						let new_url = "https://" + window.location.host + "/explore";
						window.history.pushState(null, null, new_url);
						closeUserSidebars();
					}}
					component="span"
					className="d-flex justify-content-center align-items-center"
					style={{
						backgroundColor: "transparent",
						position: "absolute",
						zIndex: 2,
						top: 20,
						right: 20,
						color: "#222c4b",
					}}
				>
					<img src={CloseIcon} style={{ height: 15, width: 15 }} />
				</IconButton>
			</div>

			<SidebarContent>
				{loading ? (
					<div className="d-flex h-100 w-100 justify-content-center align-items-center">
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<>
						<div
							className="px-4"
							style={{
								height: 170,
								backgroundColor: "#f6f6f6",
								paddingTop: 70,
								backgroundImage: `url(${require("../../assets/svg/hero-ve-bg.svg")})`,
								backgroundSize: "cover",
								backgroundPosition: "center",
							}}
						>
							<div className="d-flex align-items-end">
								<div>
									<div
										className=""
										style={{
											height: 150,
											width: 150,
											borderRadius: 30,
											backgroundImage: `url(${
												user?.Photo
													? user.Photo
													: "https://thumbs.dreamstime.com/b/user-icon-trendy-flat-style-isolated-grey-background-user-symbol-user-icon-trendy-flat-style-isolated-grey-background-123663211.jpg"
											})`,
											backgroundColor: "#ddd",
											backgroundSize: "cover",
											backgroundPosition: "center",
										}}
									/>
								</div>
								<div
									className="d-flex w-100 ml-2 flex-row justify-content-between align-items-center"
									style={{ color: filterHighlightColor }}
								>
									{isOwner ? (
										<Button
											onClick={() => {
												showUserEdit();
											}}
											style={{
												fontFamily: "inherit",
												fontSize: 14,
												textTransform: "initial",
												color: filterHighlightColor,
												lineHeight: 1.1,
												maxWidth: 150,
											}}
											className="mb-0 py-2 justify-content-center text-center"
										>
											<img
												src={require("../../assets/edit-active-ic.svg")}
												style={{ height: 14, width: 14, border: "none" }}
											/>
											<span className="ml-2">
												{
													languages[currentLanguage][
														"profile_edit_profile_button"
													]
												}{" "}
											</span>
										</Button>
									) : (
										<div />
									)}

									{isOwner && (
										<div className="d-flex flex-row">
											<IconButton
												className="mb-0 mr-1"
												style={{ color: filterHighlightColor, fontSize: 14 }}
												onClick={() => {
													showUserSettings();
												}}
											>
												<img
													alt="settings"
													src={settingIcon}
													style={{ height: 14, width: 14 }}
												/>
											</IconButton>

											<IconButton
												className="mb-0"
												style={{ color: filterHighlightColor, fontSize: 14 }}
												onClick={() => {
													shareUserProfile();
												}}
											>
												<img
													alt="settings"
													src={ShareIcon}
													style={{ height: 14, width: 14 }}
												/>
											</IconButton>
										</div>
									)}
								</div>
							</div>
						</div>
						<DetailsContainer className="px-4">
							<div className="d-flex flex-column align-items-stretch">
								{user?.LastName && (
									<p
										className="mb-1"
										style={{ fontSize: 21, color: "#222c4b" }}
									>{`${user?.FirstName} ${user?.LastName}`}</p>
								)}
								{user?.UserName && (
									<p
										className="mb-1"
										style={{ fontSize: 14, color: "#222c4b" }}
									>{`@${user?.UserName}`}</p>
								)}

								{user?.Url && (
									<Button
										href={user.Url}
										target="_blank"
										className="d-flex align-items-center justify-content-between my-3"
										style={{
											textTransform: "lowercase",
											fontFamily: "Dromon-light",
											fontSize: 14,
											color: "#8f96a7",
										}}
									>
										{`${user.Url}`} <i className="fa fa-external-link" />
									</Button>
								)}

								{user?.Description && (
									<p
										className="my-3"
										style={{
											fontFamily: "Dromon-light",
											fontSize: 14,
											fontWeight: 300,
											color: "#8f96a7",
											lineHeight: "24px",
										}}
									>
										{user?.Description}
									</p>
								)}
							</div>
							<div>
								<p
									className="mb-1"
									style={{
										fontSize: 14,
										fontWeight: "normal",
										color: "#222c4b",
									}}
								>
									{languages[currentLanguage]["profile_edit_about"]}
								</p>
								<div className="container-fluid mb-3">
									<div className="w-100 row">
										{user?.Tags?.map((tag) => (
											<Tag className="p-2 m-2 dromon-l" key={tag}>
												{languages[currentLanguage][tag.replaceAll(":", "_")]
													? languages[currentLanguage][tag.replaceAll(":", "_")]
													: tag}
											</Tag>
										))}

										{!user?.Tags?.length && (
											<Tag className="p-2 m-2">No tags</Tag>
										)}
									</div>
								</div>
							</div>

							<Button
								className="container-fluid p-0 mb-3"
								style={{ textTransform: "capitalize" }}
								onClick={showUserVessels}
							>
								<div
									className="w-100 d-flex justify-content-between align-items-center mr-0 px-3"
									style={{ height: 40 }}
								>
									<p
										className="m-0 p-0"
										style={{
											fontSize: 14,
											fontWeight: "normal",
											color: "#222c4b",
										}}
									>
										{languages[currentLanguage]["user_vessels_header"]}
									</p>

									<img
										src={ArrowIcon}
										alt="link"
										style={{
											height: 14,
											width: 14,
											transform: "rotate(180deg)",
										}}
									/>
								</div>
							</Button>

							<Button
								className="container-fluid p-0 mb-3"
								style={{ textTransform: "capitalize" }}
								onClick={() => {
									gtag_report_conversion(
										"https://" + window.location.host + "/explore/",
										types.TRIP_LIST_VIEWED
									);
									showBookmarks(
										selectedUserProfileId,
										user?.Type === "partner"
									);
								}}
							>
								<div
									className="w-100 d-flex justify-content-between align-items-center mr-0 px-3"
									style={{ height: 40 }}
								>
									<p
										className="m-0 p-0"
										style={{
											fontSize: 14,
											fontWeight: "normal",
											color: "#222c4b",
										}}
									>
										{languages[currentLanguage]["user_trips_header"]}
									</p>

									<img
										src={ArrowIcon}
										alt="link"
										style={{
											height: 14,
											width: 14,
											transform: "rotate(180deg)",
										}}
									/>
								</div>
							</Button>

							{AuthService.isUserPartner() && isOwner && (
								<Button
									className="container-fluid p-0 mb-3"
									style={{ textTransform: "capitalize" }}
									onClick={showPartnerProvision}
								>
									<div
										className="w-100 d-flex justify-content-between align-items-center mr-0 px-3"
										style={{ height: 40 }}
									>
										<p
											className="m-0 p-0"
											style={{
												fontSize: 14,
												fontWeight: "normal",
												color: "#222c4b",
											}}
										>
											{languages[currentLanguage]["trip_provision_list"]}
										</p>

										<img
											src={ArrowIcon}
											alt="link"
											style={{
												height: 14,
												width: 14,
												transform: "rotate(180deg)",
											}}
										/>
									</div>
								</Button>
							)}
						</DetailsContainer>
					</>
				)}
			</SidebarContent>

			<Dialog
				open={!!generatedUserLink}
				onClose={() => setGeneratedUserLink(null)}
			>
				<DialogContent>
					<DialogContentText>
						{languages[currentLanguage]["place_details_share_button"]}
					</DialogContentText>

					<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
						<a href={generatedUserLink} target="_blank" className="mr-2">
							<span
								className="m-0 p-0"
								style={{
									color: { formMainTxt },
									fontSize: 14,
								}}
							>
								<i className="fas fa-fw fa-link mr-2" />
								{generatedUserLink}
							</span>
						</a>

						<Tooltip title="Copy to clipboard" placement="top">
							<IconButton
								onClick={() => {
									// logEvent_("");

									copyToClipboard(generatedUserLink, () => {
										setSnackbarClipboard(true);
									});
								}}
							>
								<img src={CopyIcon} style={{ width: 14 }} />
							</IconButton>
						</Tooltip>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setGeneratedUserLink(null)}>Dismiss</Button>
					<Button
						onClick={() => {
							setGeneratedUserLink(null);
							copyToClipboard(generatedUserLink, () => {
								setSnackbarClipboard(true);
							});
						}}
						autoFocus
					>
						Copy and dismiss
					</Button>
				</DialogActions>
			</Dialog>
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showUserEdit: () => dispatch(showUserEdit()),
		showUserSettings: () => dispatch(showUserSettings()),
		showUserVessels: () => dispatch(showUserVessels()),
		showPartnerProvision: () => dispatch(showPartnerProvision()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React from "react";
import Radio from "@material-ui/core/Radio";
import {
	Input,
	IconButton,
	Select,
	FormControl,
	MenuItem,
	Snackbar,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

// CONSTANTS
import { filterHighlightColor } from "../../../constants/colors";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// COMPONENTS

export default function AddProvisionControls({
	props,
	selectItems = [],
	onFilter = () => {},
	onAdd = () => {},
}) {
	// DATA
	const initialFormValues = {
		Name: "",
		Id: "",
		Category: 0,
		Packaging: "",
		Favorite: false,
	};

	// STATES
	const [form, setForm] = React.useState(initialFormValues);
	const [canAdd, setCanAdd] = React.useState(false);
	const [showWarning, setShowWarning] = React.useState(false);

	// REFS
	const nameRef = React.useRef(null);
	const packagingRef = React.useRef(null);
	const categoryRef = React.useRef(null);

	// FUNCTIONS
	const handleCloseWarning = (event, reason) => {
		if (reason === "clickaway") return;

		setShowWarning(false);
	};

	return (
		<>
			<div
				{...{
					...props,
					className: `d-flex align-items-center mb-4 ${
						props?.className ? props.className : ""
					}`,
				}}
			>
				<IconButton
					title="Filter"
					className="mr-1"
					style={{
						color: filterHighlightColor,
						fontSize: 14,
						width: 30,
						height: 30,
					}}
					onClick={() => onFilter(form)}
				>
					<i className="fa-fw fa fa-filter" />
				</IconButton>

				<FormControl className="mr-2" style={{ flex: 1 }}>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={form.Category}
						inputProps={{ itemRef: categoryRef }}
						onChange={(e) => {
							setForm((prevState) => {
								return {
									...prevState,
									Category: e.target.value || nameRef.current.value,
								};
							});
						}}
					>
						{selectItems.map((selectItem, id) => (
							<MenuItem value={selectItem} key={id}>
								{selectItem}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className="mr-2" style={{ flex: 1 }}>
					<Input
						placeholder="Name"
						inputProps={{
							"aria-label": "Product name",
							ref: nameRef,
						}}
						onChange={(e) => {
							setForm((prevState) => {
								return {
									...prevState,
									Name: nameRef.current.value,
								};
							});
						}}
						value={form.Name}
						style={{ borderColor: filterHighlightColor }}
					/>
				</FormControl>

				<FormControl className="mr-2" style={{ flex: 1 }}>
					<Input
						placeholder="Packaging"
						inputProps={{
							"aria-label": "Packaging",
							ref: packagingRef,
						}}
						onChange={(e) => {
							setForm((prevState) => {
								return {
									...prevState,
									Packaging: packagingRef.current.value,
								};
							});
						}}
						value={form.Packaging}
						style={{ borderColor: filterHighlightColor }}
					/>
				</FormControl>

				<IconButton
					title="Add to list"
					className=""
					style={{
						color: filterHighlightColor,
						fontSize: 14,
						width: 30,
						height: 30,
					}}
					onClick={() => {
						if (isEmpty(!!form.Category) || isEmpty(form.Name))
							return setShowWarning(true);

						onAdd({ ...form, Id: new Date().getTime() });
						setForm(initialFormValues);
						setCanAdd(false);
					}}
				>
					<i className="fa-fw fa fa-plus" />
				</IconButton>
			</div>

			<Snackbar
				open={showWarning}
				autoHideDuration={5000}
				onClose={handleCloseWarning}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Alert onClose={handleCloseWarning} severity="warning">
					<AlertTitle>Warning</AlertTitle>
					Make sure to fill — <strong>name & category </strong> fields
				</Alert>
			</Snackbar>
		</>
	);
}

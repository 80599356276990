import styled, { css } from "styled-components";
import { TextField } from "@material-ui/core";

export const Sidebar = styled.div`
	position: absolute;
	z-index: 0;
	top: 55px;
	left: -415px;
	width: 415px;
	height: calc(100vh - 55px);
	margin: 0;
	padding: 110px 0 0 0;
	background-color: #ffffff;
	opacity: 0.5;
	transition: all 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.show &&
		css`
			left: 0;
			opacity: 1;
			box-shadow: 7px 0 7px 0 rgba(160, 160, 169, 0.18);
		`}
`;

export const SidebarContent = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	padding: 5px 37px;
	overflow: hidden auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #fff;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background: #8f96a7;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #222c4b;
	}
`;

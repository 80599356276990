import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import SelectSearch from "react-select-search";
import mapboxgl from "mapbox-gl";

// CONFIGS
import { Keys } from "../../../appConfig";

// API
import { ApiClient } from "../../../ApiClient";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";
import { isEmpty } from "../../../helpers/functions";

// COMPONENTS
import Button from "../Components/Button";

mapboxgl.accessToken = Keys.mapBoxKey;

const BaseMap = ({ center }) => {
	const [map, setMap] = React.useState(null);
	const markerRef = React.useRef(null);

	useEffect(() => {
		let map = new mapboxgl.Map({
			container: "startingMap",
			style: "mapbox://styles/mapbox/streets-v11",
			center: [0, 0],
			zoom: 12,
			interactive: false,
		});
		setMap(map);

		map.on("load", () => {
			map.MyCustomLoaded = true;
		});

		// Clean up on unmount
		return () => map.remove();
	}, []);

	useEffect(() => {
		if (!map) return;
		if (!map.MyCustomLoaded) return;

		map.flyTo({ center: center, zoom: 10, curve: 0.5, duration: 0.1 });

		if (markerRef.current) {
			markerRef.current.remove();
		}
		const marker = new mapboxgl.Marker().setLngLat(center).addTo(map);
		markerRef.current = marker;
	}, [center]);

	return (
		<div id="startingMap" className="map w-100" style={{ height: 250 }}></div>
	);
};

const Fith = (props) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [loading, setLoading] = useState(false);
	const [places, setPlaces] = useState([]);
	const [placeId, setPlaceId] = useState(null);
	const [location, setLocation] = useState(null);
	const [placeholder, setPlaceholder] = useState("Choose your base station");
	const [isDisabled, setIsDisabled] = useState(false);

	// FUNCTIONS
	const handleBack = () => {
		props.handleBack({
			step: 5,
		});
	};

	const handleNext = () => {
		logEvent_("react_tripwiz_stepstyle_nextbtn_pressed");
		props.handleNext({
			step: 5,
			placeId: placeId,
		});
	};

	const handleSelect = (placeId) => {
		setPlaceId(placeId);
		const place = places.filter((item) => item.ID === placeId)[0];
		if (place) {
			const location = place.location;
			setPlaceholder(place.name);
			setLocation(location);
		}
	};

	const autoInit = (selectedPlaceId, places) => {
		if (selectedPlaceId && places) {
			const place = places.filter((item) => item.ID === selectedPlaceId)[0];
			if (place) {
				setPlaceId(place.ID);
				const location = place.location;
				setPlaceholder(place.name);
				setLocation(location);
			}
		}
	};

	const getBaseStations = () => {
		const currentUserRole = props.state?.currentUserRole ?? "n/a";
		if (currentUserRole === "creator" || currentUserRole === "n/a") {
			ApiClient.getMyBaseStations()
				.then((places) => {
					const customized = [];
					places.forEach((place) => {
						customized.unshift({
							...place,
							name:
								(!isEmpty(place.Name) ? place.Name : place.Type) +
								(!isEmpty(place.Type) ? `(${place.Type})` : ""),
							value: place.ID,
							location: place.Location,
						});
					});
					setPlaces(customized);
					autoInit(props.state?.PlaceId, customized);
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		} else {
			if (props.state?.creatorUserId) {
				ApiClient.getOtherBaseStations(props.state?.creatorUserId)
					.then((places) => {
						const customized = [];
						places.forEach((place) => {
							customized.unshift({
								...place,
								name:
									(!isEmpty(place.Name) ? place.Name : place.Type) +
									(!isEmpty(place.Type) ? `(${place.Type})` : ""),
								value: place.ID,
								location: place.Location,
							});
						});
						setPlaces(customized);
						autoInit(props.state?.PlaceId, customized);
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	// EFFECTS
	useEffect(() => {
		if (props.state?.State) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
		/*
        if (props.state?.State === "clone" || props.state?.State === "template") {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }*/
		if (props.visible) {
			//setLoading(true);
			getBaseStations();
		}
		return () => {};
	}, [props.visible]);

	useEffect(() => {
		if (props.wizardV) {
			getBaseStations();
		}
	}, [props.wizardV]);

	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<h3
						className="my-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						{props.state?.Type === "business" &&
						props.state?.State !== "template"
							? languages[currentLanguage]["trip_create_new_business_trip"]
							: languages[currentLanguage]["trip_create_new_template_trip"]}
					</h3>
					<h2 style={{ fontSize: 22, color: "#222c4b" }}>
						{languages[currentLanguage]["trip_wizard_start_from"]}
					</h2>
					<div className="mb-4">
						<SelectSearch
							disabled={isDisabled}
							isDisabled={isDisabled}
							className="select-search"
							options={places}
							value={placeId}
							name="Base station"
							onChange={(value) => handleSelect(value)}
							emptyMessage="No data"
							placeholder={placeholder}
							search
						/>
					</div>

					<BaseMap center={location} />
				</div>

				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Fith);

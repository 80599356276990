import styled, { css } from "styled-components";
import { IconButton } from "@material-ui/core";
import { filterHighlightColor, whiteColor } from "../../constants/colors";

export const Sidebar = styled.div`
	position: absolute;
	z-index: 19;
	top: 55px;
	left: -415px;
	width: 415px;
	height: calc(100vh - 55px);
	margin: 0;
	padding: 0;
	box-shadow: none;
	visibility: hidden;
	background-color: #ffffff;
	opacity: 0.5;
	transition: all 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.show &&
		css`
			left: 0;
			opacity: 1;
			box-shadow: 7px 0 7px 0 rgba(160, 160, 169, 0.18);
			visibility: visible;
		`}
`;

export const SidebarContent = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #fff;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background: #8f96a7;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #222c4b;
	}
`;

export const CoverImage = styled.div`
	height: 230px;
	width: 100%;
	margin-bottom: 15px;
	overflow: hidden;
	cursor: pointer;
`;

export const PhotoItem = styled.div`
	height: 105px;
	width: 180px;
	overflow: hidden;
	border: solid 2px #e3e5e9;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	transition: all 0.5s;
	&:hover {
		box-shadow: 0 0 3px 0 rgba(160, 160, 169, 0.15);
		cursor: pointer;
	}
`;

export const PlacePhotos = styled.div`
	position: absolute;
	z-index: 21;
	top: 55px;
	right: calc(-100vw + 415px);
	width: calc(100vw - 415px);
	height: calc(100vh - 55px);
	margin: 0;
	padding: 0;
	background-color: ${whiteColor};
	opacity: 0.5;
	transition: all 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.show &&
		css`
			right: 0;
			opacity: 1;
		`}
`;

export const AddReviewBtn = styled.button`
	border: 1px solid ${filterHighlightColor};
	width: 161px;
	height: 36px;
	margin: 6px 210px 0 0;
	padding: 11px 21px 11px 12px;
	border-radius: 20px;
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	background-color: ${whiteColor};
`;

import styled from "styled-components";
import { secondaryTxt } from "../../constants/colors";

export const DetailsContainer = styled.div`
	padding-top: 70px;
`;

export const Tag = styled.span`
	background-color: #f6f6f6;
	font-size: 11px;
	border-radius: 5px;
	color: #222c4b;
`;

export const Input = styled.input`
	outline: none;
	border: none;
	border-bottom: solid 1px rgba(232, 232, 232, 0.5);
	background-color: transparent;
	&:focus {
		background-color: #f6f6f6;
		border-radius: 5px;
	}
	&::placeholder {
		color: #b6b6b6;
	}
`;

import React, { useState, useEffect } from "react";
import MultiselectCheck from "../Components/MultiselectCheck";
import Button from "../Components/Button";
import { connect, useSelector } from "react-redux";
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { logEvent_ } from "../../../helpers/analytics";
import minus from "../../../assets/svg/itinerary/minus-icon.svg";
import plus from "../../../assets/svg/itinerary/plus-icon.svg";

const Third = (props) => {
	const currentLanguage = useSelector(currentLang);
	const [options, setOptions] = useState([]);
	const tripAttributes = useSelector((state) => state.map.tripAttributes);
	const [isSingle, setIsSingle] = useState(false);
	const [state, setState] = useState([]);

	const handleBack = () => {
		props.handleBack({
			step: 3,
		});
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_steppeople_nextbtn_pressed");

		props.handleNext({
			step: 3,
			tags: state,
			nbAdults: adults,
			nbKids: kids,
		});
	};

	const [adults, setAdults] = useState(0);
	const [kids, setKids] = useState(0);

	const handleSelect = (option) => {
		if (isSingle) {
			setState([option]);
		} else {
			let actualState = state;
			actualState = actualState.push(options);
			setState(actualState);
		}
	};

	const increaseAdults = () => {
		const count = adults + 1;
		setAdults(count);
	};

	const decreaseAdults = () => {
		const count = adults - 1;
		setAdults(count > 0 ? count : 0);
	};

	const setCustomAdults = (e) => {
		const val = parseInt(e);
		setAdults(val >= 0 ? val : 0);
	};

	const increaseKids = () => {
		const count = kids + 1;
		setKids(count);
	};

	const decreaseKids = () => {
		const count = kids - 1;
		setKids(count > 0 ? count : 0);
	};

	const setCustomKids = (e) => {
		const val = parseInt(e);
		setKids(val >= 0 ? val : 0);
	};

	useEffect(() => {
		let categories = Object.keys(tripAttributes);
		var versions = tripAttributes[categories[0]].Values;
		setIsSingle(tripAttributes[categories[0]].IsSingle);
		versions = versions.filter((e) => !e.includes("solo"));
		setOptions(versions);
		setState(
			props.state?.Attributes ? props.state?.Attributes[3] : [versions[0]]
		);
		const attributeCrew = props.state?.Attributes.find((e) =>
			e.includes("crew")
		);
		if (attributeCrew) {
			handleSelect(attributeCrew);
		}
		setAdults(props.state?.NumberOfAdults ?? 0);
		setKids(props.state?.NumberOfKids ?? 0);
	}, [tripAttributes, props.state, props.finished]);
	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<div>
						<h3
							className="my-2 dromon-l"
							style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
						>
							{props.state?.Type === "business" &&
							props.state?.State !== "template"
								? languages[currentLanguage]["trip_create_new_business_trip"]
								: languages[currentLanguage]["trip_create_new_template_trip"]}
						</h3>
						<h2 style={{ fontSize: 22, color: "#222c4b" }}>
							{languages[currentLanguage]["trip_attr_crew_header"]}
						</h2>
						<div>
							{options.map((option) => (
								<MultiselectCheck
									handleSelect={() => handleSelect(option)}
									text={
										languages[currentLanguage][option.replaceAll(":", "_")]
											? languages[currentLanguage][option.replaceAll(":", "_")]
											: option
									}
									key={option}
									selected={state.includes(option)}
								/>
							))}
						</div>
					</div>
					{props.state?.Type === "business" &&
						props.state?.State !== "template" && (
							<div>
								<h2 style={{ fontSize: 22, color: "#222c4b" }}>
									{languages[currentLanguage]["trip_wizard_nb_adults"]}
								</h2>
								<div className="d-flex flex-row justify-content-start px-0">
									<div className="d-flex flex-column">
										<div
											onClick={() => {
												increaseAdults();
											}}
											className="d-flex justify-content-center align-items-center"
											style={{
												height: 25,
												width: 25,
												borderRadius: "50%",
												border: "2px solid #dbdee6",
												cursor: "pointer",
											}}
										>
											<img
												src={plus}
												alt=""
												style={{ height: 10, width: 10 }}
											/>
										</div>
										<div
											onClick={() => {
												decreaseAdults();
											}}
											className="d-flex justify-content-center align-items-center"
											style={{
												height: 25,
												width: 25,
												borderRadius: "50%",
												border: "2px solid #dbdee6",
												cursor: "pointer",
											}}
										>
											<img
												src={minus}
												alt=""
												style={{ height: 2, width: 20 }}
											/>
										</div>
									</div>
									<div style={{ width: 25 }}></div>
									<input
										className="form-control mb-1"
										type="number"
										value={adults}
										onChange={(e) => setCustomAdults(e.target.value)}
										style={{
											fontSize: 12,
											color: "#222c4b",
											width: 65,
											marginTop: 8,
										}}
									/>
									<div style={{ width: 25 }}></div>
								</div>

								<h2 style={{ fontSize: 22, color: "#222c4b", paddingTop: 20 }}>
									{languages[currentLanguage]["trip_wizard_nb_kids"]}
								</h2>
								<div className="d-flex flex-row justify-content-start px-0">
									<div className="d-flex flex-column">
										<div
											onClick={() => {
												increaseKids();
											}}
											className="d-flex justify-content-center align-items-center"
											style={{
												height: 25,
												width: 25,
												borderRadius: "50%",
												border: "2px solid #dbdee6",
												cursor: "pointer",
											}}
										>
											<img
												src={plus}
												alt=""
												style={{ height: 10, width: 10 }}
											/>
										</div>
										<div
											onClick={() => {
												decreaseKids();
											}}
											className="d-flex justify-content-center align-items-center"
											style={{
												height: 25,
												width: 25,
												borderRadius: "50%",
												border: "2px solid #dbdee6",
												cursor: "pointer",
											}}
										>
											<img
												src={minus}
												alt=""
												style={{ height: 2, width: 20 }}
											/>
										</div>
									</div>
									<div style={{ width: 25 }}></div>
									<input
										className="form-control mb-1"
										type="number"
										value={kids}
										onChange={(e) => setCustomKids(e.target.value)}
										style={{
											fontSize: 12,
											color: "#222c4b",
											width: 65,
											marginTop: 8,
										}}
									/>
									<div style={{ width: 25 }}></div>
								</div>
							</div>
						)}
				</div>

				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						next
						text={languages[currentLanguage]["common_next"]}
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Third);

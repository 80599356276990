import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import {
	IconButton,
	Button,
	Dialog,
	DialogTitle,
	DialogContentText,
	Tooltip,
	DialogContent,
	DialogActions,
} from "@material-ui/core";

// CONFIGS
import { Keys } from "../../appConfig";
import { generateCoastViewShareLink } from "../../shareConfig";

// API
import branch from "branch-sdk";
import { ApiClient } from "../../ApiClient";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";

// DATA
import { languages } from "../../store/features/translation/Languages/";

// HELPERS
import { REGISTRED, UNREGISTRED } from "../map/helpers/bookMarksHelper";
import { AuthService } from "../../AuthService";

// COMPONENTS
import Header from "../utils/Header";

// STYLES
import "../../assets/css/coast-view.css";
import { useStyles } from "../map/Popup";
import { gtag_report_conversion, types } from "../../helpers/googleTags";
import { logEvent_ } from "../../helpers/analytics";

const BaseMap = (props) => {
	mapboxgl.accessToken = Keys.mapBoxKey;

	// REFS
	const [map, setMap] = useState(null);
	const mapContainerRef = useRef(null);
	const markerRef = useRef(null);

	const customMarker = document.createElement("div");
	customMarker.className = "marker";

	// FUNCTIONS
	const updateMarker = () => {
		map.flyTo({ center: props.center, zoom: 10, curve: 0.5, duration: 0.1 });

		if (markerRef.current) {
			markerRef.current.remove();
		}
		const marker = new mapboxgl.Marker(customMarker)
			.setLngLat(props.center)
			.addTo(map);
		markerRef.current = marker;
	};

	// EFFECTS
	useEffect(() => {
		let map_ = new mapboxgl.Map({
			container: "mapContainer",
			style: "mapbox://styles/ibogordos/ckqr3n7i6396j18pd2pngznuy",
			center: [0, 0],
			attributionControl: false,
			zoom: 10,
			interactive: false,
		});
		setMap(map_);

		map_.on("load", () => {
			map_.MyCustomLoaded = true;
			map_.flyTo({ center: props.center, zoom: 10, curve: 0.5, duration: 0.1 });
			const marker = new mapboxgl.Marker(customMarker)
				.setLngLat(props.center)
				.addTo(map_);
			markerRef.current = marker;
		});

		// Clean up on unmount
		return () => map_.remove();
	}, []);

	useEffect(() => {
		if (!map) return;
		if (!map.MyCustomLoaded) return;
		updateMarker();
	}, [props.center]);

	return (
		<div className="wrapper-map d-flex flex-column">
			<div id="mapContainer" className="map"></div>
			<div
				className="d-flex justify-content-center align-items-center "
				style={{
					flex: 1,
					fontSize: 16,
					color: "#0a2463",
				}}
			>
				Map View
			</div>
		</div>
	);
};

const CoastView = (props) => {
	// STYLES
	const classes = useStyles();
	// INIT
	const { id, lineid } = props.state;
	const dispatch = props.dispatch;
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [state, setState] = useState({
			breadCrumb: "",
			photo: null,
			disablePrev: false,
			disableNext: false,
		}),
		[loadingImg, setLoadingImg] = useState(false),
		[mode] = React.useState(
			AuthService.isUserLogedIn() ? REGISTRED : UNREGISTRED
		),
		[generatedLink, setGeneratedLink] = React.useState(null),
		[shareDialog, setShareDialog] = useState(false);

	// REFS
	const localState = useRef({ line: null, index: null });

	// FUNCTIONS
	function handleBack() {
		var newUrl = "https://" + window.location.host + "/explore/";
		window.history.pushState(null, null, newUrl);
		setState((state) => {
			return { ...state, photo: null, disablePrev: false, disableNext: false };
		});
		if (dispatch)
			dispatch({
				type: "coastViewcloseslider",
				coordinates: state.photo.lc,
				id: state.photo.id,
			});
	}

	const openPlaceDetails = (placeId, name) => {
		logEvent_("react_cv_visibleplaces_listitem_pressed");
		if ((dispatch, placeId, name)) handleBack();
		dispatch({
			type: "updateSidebarPlacesState",
			place: {
				id: placeId,
				namelatin: name,
			},
		});
	};

	function shareCoastView() {
		logEvent_("react_coastview_sharebutton_pressed");
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.SHARE_INITIATED
		);
		const breadcrumb = localState.current.line.BreadCrumb.split(",").join("-");
		const lineId = localState.current.line.Id;
		const pointId =
			localState.current.line.PhotoPoints[localState.current.index].id;
		const imageUrl =
			localState.current.line.PhotoPoints[localState.current.index].ul;
		const linkData = generateCoastViewShareLink(
			pointId,
			lineId,
			breadcrumb,
			imageUrl
		);
		branch.link(linkData, function (err, link) {
			setGeneratedLink(link);
			setShareDialog(true);
		});
	}

	function updateLink() {
		if (
			localState.current?.line === null ||
			localState.current?.index === null ||
			localState.current?.line?.PhotoPoints === null ||
			localState.current?.line?.BreadCrumb === null
		) {
			return;
		}
		var breadCrumb = localState.current.line.BreadCrumb.split(",").join("-");
		var lineID = localState.current.line.Id;
		var currentIndexId =
			localState.current?.line?.PhotoPoints[localState.current.index]?.id;
		if (currentIndexId === null) {
			return;
		}
		breadCrumb = breadCrumb.replaceAll(" ", "");
		var newUrl =
			"https://" +
			window.location.host +
			"/explore/coastview/" +
			lineID +
			"/" +
			currentIndexId +
			"/" +
			breadCrumb;
		window.history.pushState(null, null, newUrl);
	}

	async function handlePrev() {
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.COAST_VIEW_IMAGE_SHOWN
		);
		if (localState.current.index !== null && localState.current.index !== 0) {
			localState.current.index = localState.current.index - 1;
			setState((state) => {
				return {
					...state,
					photo: localState.current.line.PhotoPoints[localState.current.index],
				};
			});
			updateLink();
		}

		if (
			(localState.current.index === null || localState.current.index === 0) &&
			localState.current.line.PreviousId
		) {
			setState((state) => {
				return { ...state, disablePrev: true, disableNext: true };
			});

			setLoadingImg(true);

			const data = await ApiClient.getGTline(
				localState.current.line.PreviousId
			);

			localState.current.line = data;
			localState.current.index =
				data.PhotoPoints.length !== 0 ? data.PhotoPoints.length - 1 : null;
			if (data.PhotoPoints.length)
				setState({
					breadCrumb: localState.current.line.BreadCrumb,
					photo: localState.current.line.PhotoPoints[localState.current.index],
					disablePrev: false,
					disableNext: false,
				});
			else
				setState((state) => {
					return { ...state, disablePrev: false, disableNext: false };
				});

			setLoadingImg(false);
			updateLink();
		}
	}

	async function handleNext() {
		gtag_report_conversion(
			"https://" + window.location.host + "/explore/",
			types.COAST_VIEW_IMAGE_SHOWN
		);
		if (
			localState.current.index !== null &&
			localState.current.index !==
				localState.current.line.PhotoPoints.length - 1
		) {
			localState.current.index = localState.current.index + 1;
			setState((state) => {
				return {
					...state,
					photo: localState.current.line.PhotoPoints[localState.current.index],
				};
			});
			updateLink();
		}

		if (
			(localState.current.index === null ||
				localState.current.index ===
					localState.current.line.PhotoPoints.length - 1) &&
			localState.current.line.NextId
		) {
			setState((state) => {
				return { ...state, disablePrev: true, disableNext: true };
			});

			setLoadingImg(true);

			const data = await ApiClient.getGTline(localState.current.line.NextId);

			localState.current.line = data;

			localState.current.index = data.PhotoPoints.length !== 0 ? 0 : null;
			if (data.PhotoPoints.length)
				setState({
					breadCrumb: localState.current.line.BreadCrumb,
					photo: localState.current.line.PhotoPoints[localState.current.index],
					disablePrev: false,
					disableNext: false,
				});
			else
				setState((state) => {
					return { ...state, disablePrev: false, disableNext: false };
				});

			setLoadingImg(false);
			updateLink();
		}
	}

	const copyToClipboard = (str = "") => {
		const el = window.document.createElement("textarea");
		el.value = str;
		window.document.body.appendChild(el);
		el.select();
		window.document.execCommand("copy");
		window.document.body.removeChild(el);
		navigator.clipboard.writeText(str);
	};

	// EFFECTS
	useEffect(() => {
		async function init() {
			if (!lineid) return;
			logEvent_("react_app_coastview_screen");
			try {
				const data = await ApiClient.getGTline(lineid);
				localState.current.line = data;
				localState.current.index = data.PhotoPoints.findIndex(
					(photo) => photo.id === `${id}`
				);
				setState((state) => {
					return {
						...state,
						breadCrumb: localState.current.line.BreadCrumb,
						photo:
							localState.current.line.PhotoPoints[localState.current.index],
					};
				});
				updateLink();
			} catch (e) {
			} finally {
			}
		}
		init();
	}, [props.state]);

	return (
		<div className="wrapper-coast-view" style={{ backgroundColor: "black" }}>
			<Dialog open={shareDialog} onClose={() => setShareDialog(false)}>
				<DialogTitle>
					{languages[currentLanguage]["place_details_share_button"]}
				</DialogTitle>

				<DialogContent>
					<DialogContentText>
						{languages[currentLanguage]["place_details_share_button"]}
					</DialogContentText>

					<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
						<a href={generatedLink} target="_blank" rel="noreferrer">
							<h3
								className="m-0 p-0"
								style={{
									color: "#222c4b",
									fontSize: 14,
								}}
							>
								<i className="fas fa-fw fa-link mr-3" />
								{generatedLink}
							</h3>
						</a>

						<Tooltip title="Copy to clipboard" placement="top">
							<IconButton onClick={() => copyToClipboard(generatedLink)}>
								<i className="far fa-fw fa-copy" style={{ fontSize: 15 }} />
							</IconButton>
						</Tooltip>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShareDialog(false)}>Dismiss</Button>
					<Button
						onClick={() => {
							setShareDialog(false);
							copyToClipboard(generatedLink);
						}}
						autoFocus
					>
						Copy and dismiss
					</Button>
				</DialogActions>
			</Dialog>

			{!state.photo || loadingImg ? (
				<div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center loading-view">
					<div>
						<img src={require("../../assets/svg/keeano-icon.svg")} />
					</div>
				</div>
			) : (
				<>
					<img
						id="imgCoastView"
						src={state.photo.ul}
						alt=""
						style={{ width: "100%", height: "100%" }}
					/>
					<div
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							height: "100vh",
							width: "100vw",
						}}
					>
						<Header
							mapCoordinates={state.photo.lc}
							dispatch={props.dispatch}
							notifications={props.bookmarks ? props.bookmarks.length : 0}
							page="coastview"
							showBookmarks={() => {
								gtag_report_conversion(
									"https://" + window.location.host + "/explore/",
									types.TRIP_LIST_VIEWED
								);
								props.showBookmarks();
								handleBack();
							}}
							showAuth={() => {
								props.showAuth();
								handleBack();
							}}
						/>
						<h3 className="breadCrumb"> {state.breadCrumb} </h3>
						{/* /.breadCrumb */}

						<div
							onClick={() => {
								handleBack();
								logEvent_("react_explore_locationbutton_pressed");
							}}
						>
							<BaseMap center={state.photo.lc} />
						</div>

						<div
							className="floatIconBtn"
							style={{ left: 20, backgroundColor: "rgba(17, 17, 17, 0.5)" }}
						>
							<IconButton
								disabled={state.disablePrev}
								onClick={handlePrev}
								style={{
									marginLeft: "-3px",
									color: "#FFF",
								}}
							>
								<i className="fas fa-chevron-left fa-fw" />
							</IconButton>
						</div>

						<div className="floatIconBtn" style={{ right: 20 }}>
							<IconButton
								disabled={state.disableNext}
								onClick={handleNext}
								style={{
									color: "#FFF",
									backgroundColor: "rgba(17, 17, 17, 0.5)",
								}}
							>
								<i className="fas fa-chevron-right fa-fw" />
							</IconButton>
						</div>

						<div className="actions-btn">
							<Button
								onClick={shareCoastView}
								variant="contained"
								className="rounded-btn rounded-btn-light"
							>
								<img
									src={require(`../../assets/share-ic.svg`)}
									style={{ width: 11, marginRight: 12 }}
								/>
								<span className="deci" style={{ fontSize: 12 }}>
									{languages[currentLanguage]["place_details_share_button"]}
								</span>
							</Button>
						</div>

						<ul className="actions-places" style={{}}>
							{state.photo.pl.map(({ id, nm, tp }) => (
								<Button
									key={id}
									variant="contained"
									className="rounded-btn rounded-btn-light"
									style={{ width: "100%", justifyContent: "flex-start" }}
									onClick={() => openPlaceDetails(id, nm ?? tp)}
								>
									<div className="text-left" style={{ flex: 1 }}>
										<img
											src={require(`../../assets/svg/placeBlackIcons/${tp}.svg`)}
											style={{ width: 21, marginRight: 9 }}
										/>
										<span className="deci" style={{ fontSize: 14 }}>
											{nm ?? tp}
										</span>
									</div>
									<i
										style={{ color: "#0a2463" }}
										className="fas fa-fw fa-arrow-right"
									/>
								</Button>
							))}

							{state.photo?.pl.length > 0 && (
								<Button
									variant="contained"
									component="li"
									className="rounded-btn rounded-btn-dark mb-1"
									style={{ width: "100%", justifyContent: "flex-start" }}
								>
									<img
										src={require("../../assets/svg/visible_places_ic.svg")}
										style={{ width: 15, marginRight: 10, color: "white" }}
									/>
									<span className="deci" style={{ fontSize: 13 }}>
										{
											languages[currentLanguage][
												"coastview_visibleplaces_button"
											]
										}
									</span>
								</Button>
							)}
						</ul>
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CoastView);

import styled, { css } from "styled-components";

export const Box = styled.div`
  position: absolute;
  top: 75px;
  right: 30px;
  width: 300px;
  background-color: white;
  box-shadow: 0 0 4px whitesmoke;
`;

export const Sidebar = styled.div`
  position: absolute;
  z-index: 19;
  top: 55px;
  left: -415px;
  width: 415px;
  height: calc(100vh - 55px);
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  opacity: 0.5;
  transition: all 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19);

  ${(props) =>
    props.show &&
    css`
      left: 0;
      opacity: 1;
			box-shadow: 7px 0 7px 0 rgba(160, 160, 169, 0.18);
    `}
`;

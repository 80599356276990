import {
  OPEN_REVIEW,
  CLOSE_REVIEW_PAGE,
  REVIEW_PLACE,
  OPEN_REVIEW_LIST,
} from "./types";

export const openReviewPage = (payload) => {
  return {
    type: OPEN_REVIEW_LIST,
    payload,
  };
};

export const openAddReviewPage = (payload) => {
  return {
    type: OPEN_REVIEW,
    payload,
  };
};

export const closeReviewPage = () => {
  return {
    type: CLOSE_REVIEW_PAGE,
  };
};

export const reviewPage = () => {
  return {
    type: REVIEW_PLACE,
  };
};

import React from "react";
import { connect, useSelector } from "react-redux";
import { IconButton, Avatar, TextField, MenuItem } from "@material-ui/core";
import { AuthService } from "../../../../AuthService";

// CONSTANTS
import {
	secondaryTxt,
	formMainTxt,
	filterHighlightColor,
} from "../../../../constants/colors";

// SELECTORS
import { currentLang } from "../../../../store/features/translation/selectors";
import { languages } from "../../../../store/features/translation/Languages";

// HELPERS
import { isEmpty } from "../../../../helpers/functions";
import dateFormat from "date-fns/format";

function CollapseCardHeader({
	currentLang = "EN",
	member = {},
	inAdding = false,
	inEditing = null,
	state = {},
	role = "no-member",
	setState = () => {},
	onClickUserProfile = () => {},
}) {
	// DATA
	let inEdition = inAdding || inEditing;
	let title = `${member.PickUpPlace || ""} -> ${member.DestinationPlace || ""}`;
	let editionTitle = `${state.Name || ""} ${state.Surname || ""}`;
	let formattedTitle = !inEdition ? title : editionTitle;
    let date = (new Date(member.PickUpDateTime)).toUTCString();

	return (
		<>
			<IconButton
				onClick={(e) => {
					e.stopPropagation();
					onClickUserProfile();
				}}
				className="px-0 py-0 mr-3"
				title={title}
			>
			</IconButton>
			<div
				style={{ flex: 1 }}
				className="d-flex flex-column justify-content-center h-100"
			>
				{!inEdition ? (
					<>
						<h2
							className="text-capitalize dromon-l mb-0 pb-0"
							style={{
								color: formMainTxt,
								fontSize: 16,
							}}
						>
							{title}
						</h2>

						<span
							className="text-capitalize dromon-l mb-0 pb-0"
							style={{ fontSize: 14, color: secondaryTxt }}
						>
                            {date.replaceAll("GMT", "")}
						</span>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		currentLang: currentLang(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseCardHeader);

import styled, { css } from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Sidebar = styled.div`
	position: absolute;
	z-index: 19;
	top: 55px;
	left: -415px;
	width: 415px;
  height: calc(100vh - 55px);
  margin: 0;
  padding: 0;
  background-color: #ffffff;
	opacity: 0.5;
	transition: all .35s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${props => props.show && css`
    left: 0;
		opacity: 1;
  	box-shadow: 7px 0 7px 0 rgba(160, 160, 169, 0.18);
  `}
`;

export const SidebarContent = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	overflow:hidden auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #FFF;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background: #8f96a7;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #222c4b;
	}
`;

export const CoverImage = styled.div`
	height: 230px;
	width: 100%;
	margin-bottom: 15px;
	overflow: hidden;
`;

export const PhotoItem = styled.div`
	height: 105px;
	width: 180px;
	margin-right: 20px;
	overflow: hidden;
	border: solid 2px #e3e5e9;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	transition: all .5s;
	&:hover{
		box-shadow: 0 0 3px 0 rgba(160, 160, 169, 0.15);
		cursor: pointer;
	}
`;

export const PlacePhotos = styled.div`
	position: absolute;
	z-index: 21;
	top: 55px;
	right: calc(-100vw + 415px);
	width: calc(100vw - 415px);
  height: calc(100vh - 55px);
  margin: 0;
  padding: 0;
  background-color: #ffffff;
	opacity: 0.5;
	transition: all .35s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${props => props.show && css`
    right: 0;
		opacity: 1;
  `}
`;

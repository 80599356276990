import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";

// CONSTANTS
import { FIREBASE_CONFIG } from "./firebaseServices";
import { AuthService } from "../AuthService";

export const logEvent_ = async (eventName, propertyObject = {}) => {
	const app = initializeApp(FIREBASE_CONFIG);
	const analytics = getAnalytics(app);

	const userIdObject = AuthService.isUserLogedIn()
		? {
				user_id: JSON.parse(window.localStorage.getItem("currentUserId"))
					?.userId,
		  }
		: {};
	//console.log("=== eventName === : ", eventName, userIdObject, propertyObject);
	if (process.env.REACT_APP_ENVIRONMENT === "production") {
		logEvent(analytics, eventName, { ...propertyObject, ...userIdObject });
	}
};

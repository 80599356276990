import styled from "styled-components";

export const HomeTitle = styled.div`
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
	margin-bottom: 18px;
`;

import React, { useEffect, useState } from "react";
import { CometChatMessages } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src";
import { IconButton, CircularProgress } from "@material-ui/core";

// API
import { AuthService } from "../../AuthService";

// ICONS
import BackIcon from "../../assets/back_ic.svg";

// STYLES
import { SidebarContent } from "../PlacesDetailsSidebar/styled";
import { Sidebar } from "../BookmarkList/styled";
import { useStyles } from "../map/Popup";

const Chat = ({ tripId, visible, handleBack }) => {
	const classes = useStyles();
	const [loading] = useState(false);

	return (
		<Sidebar show={visible} className="pb-4">
			<div className="w-100 position-relative">
				<IconButton
					onClick={() => handleBack()}
					component="span"
					className="d-flex justify-content-center align-items-center"
					style={{
						backgroundColor: "transparent",
						position: "absolute",
						zIndex: 2,
						top: 15,
						left: 5,
						color: "#222c4b",
					}}
				>
					<img
						alt="icon"
						src={BackIcon}
						style={{ height: 16, width: 8, marginRight: 17 }}
					/>
				</IconButton>
			</div>
			<SidebarContent>
				{loading ? (
					<div className="d-flex h-100 w-100 justify-content-center align-items-center">
						<CircularProgress className={classes.progress} />
					</div>
				) : (
					<>
						{AuthService.isUserLogedIn() && visible && (
							<div style={{ width: "100%", height: "100%" }}>
								<CometChatMessages chatWithGroup={tripId} />
							</div>
						)}
					</>
				)}
			</SidebarContent>
		</Sidebar>
	);
};

export default Chat;

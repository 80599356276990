import React from "react";
import { connect, useSelector } from "react-redux";
import { Snackbar, IconButton } from "@material-ui/core";
import dateFormat from "date-fns/format";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { exportToXLSX } from "../../helpers/functions";

// CONSTANTS
import DEFAULT_TOAST_PROPS from "../../constants/tostifyDefaultProps";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { userData, userProfileId } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";

// ACTIONS
import { closeUserSidebars } from "../../store/features/user/actions";

// CONSTANTS
import { filterHighlightColor, whiteColor } from "../../constants/colors";

// COMPONENTS
import SideBar from "../utils/SideBar";
import CrewListCollapseCard from "./components/CrewListCollapseCard";

// SVG ICONS
import TripAddIcon from "../../assets/trip_list_add_button.svg";
import CrewListEmptyStateIcon from "../../assets/svg/crewList/crewlist_emptystate.svg";
import EditListIcon from "../../assets/svg/crewList/edit_list_ic.svg";
import ExportListIcon from "../../assets/svg/crewList/export_list.svg";
import FinalizeMarkIcon from "../../assets/svg/crewList/finalize_mark_ic.svg";
import FullExportListIcon from "../../assets/svg/crewList/full_export_list_ic.svg";

// STYLES
import "react-confirm-alert/src/react-confirm-alert.css";

const CrewList = ({
	visible,
	trip = {},
	deadlineReached = false,
	closeSidebar,
}) => {
	// SELECTORS
	const currentUserData = useSelector(userData);
	const selectedUserProfileId = useSelector(userProfileId);
	const currentLanguage = useSelector(currentLang);

	// STATES
	const [loading, setLoading] = React.useState(false);
	const [snackbarClipboard, setSnackbarClipboard] = React.useState(false);
	const [crewList, setCrewList] = React.useState([]);
	const [jsonCrewList, setJsonCrewList] = React.useState([]);
	const [canEdit, setCanEdit] = React.useState(false);
	const [role, setRole] = React.useState("");
	const [inAdding, setInAdding] = React.useState(false);
	const [inEditing, setInEditing] = React.useState(null);
	const [isLoadingSave, setIsLoadingSave] = React.useState(false);
	const [IsCompletedClient, setIsCompletedClient] = React.useState(false);
	const [IsCompletedCharter, setIsCompletedCharter] = React.useState(false);
	const [tripStartDate, setTripStartDate] = React.useState(null);
	const [tripEndDate, setTripEndDate] = React.useState(null);
	const [canFinalizeClient, setCanFinalizeClient] = React.useState(false);
	const [canFinalizeCharter, setCanFinalizeCharter] = React.useState(false);
	const [canEditClientList, setCanEditClientList] = React.useState(false);
	const [canEditCharterList, setCanEditCharterList] = React.useState(false);
	const [IsCrewed, setIsCrewed] = React.useState(false);
	const [canDisplayAddButton, setCanDisplayAddButton] = React.useState(false);

	// DATA
	const [initialFormValues, setInitialFormValues] = React.useState({
		Name: "",
		Surname: "",
		DateOfBirth: null,
		Nationality: "",
		PassportId: "",
		IdentityId: "",
		ContactNumber: "",
		SkipperLicenseId: "",
		SkipperLicenseUrl: null,
		CurrentID: "PassportId",
		Type: "passenger",
		EmbarkDate: tripStartDate,
		DisembarkDate: tripEndDate,
		TripStartDate: tripStartDate,
		TripEndDate: tripEndDate,
		Collapse: true,
	});

	// REFS
	const sidebarContentRef = React.useRef(null);

	// FUNCTIONS
	const onSaveCrewData = async (formData = {}, member = {}) => {
		onAddCrew(formData, null, member.MemberOf);
	};

	const onAddCrew = async (formData = {}, member = null, MemberOf = null) => {
		const IsTripCrewed = trip.IsCrewed ?? false;
		if (
			role === "creator" &&
			(formData.MemberOf === null || formData.MemberOf === "")
		) {
			toast.warning("Type is missing", DEFAULT_TOAST_PROPS);
			return;
		}

		// if (formData.MemberOf === "charter" && !IsTripCrewed) {
		// 	toast.warning(
		// 		"Trip is not crewed, you cannot add a charter member. Please edit the trip",
		// 		DEFAULT_TOAST_PROPS
		// 	);
		// 	return;
		// }

		if (formData.CodeNum === 0 || formData.ContactNumber?.length < 7) {
			toast.warning("Invalid phone number", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.Name === "") {
			toast.warning("The name is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.Surname === "") {
			toast.warning("The surname is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.DateOfBirth === null) {
			toast.warning("The date of birth is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.EmbarkDate === null || formData.DisembarkDate === null) {
			toast.warning("EmbarkDate/DisembarkDate is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.Nationality === null || formData.Nationality === "") {
			toast.warning("The nationality is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.Type === "skipper") {
			if (
				formData.SkipperLicenseId === "" ||
				formData.SkipperLicenseUrl === null
			) {
				return;
			}
		}
		if (formData.PassportId === "" && formData.IdentityId === "") {
			toast.warning("The passport/identity ID is missing", DEFAULT_TOAST_PROPS);
			return;
		}
		if (formData.PassportId !== "") {
			if (formData.PassportId.length < 7 || formData.PassportId.length > 30) {
				toast.error("Passport ID is invalid", DEFAULT_TOAST_PROPS);
				return;
			}
		}
		if (formData.IdentityId !== "") {
			if (formData.IdentityId.length < 4 || formData.IdentityId.length > 30) {
				toast.error("Identity ID is invalid", DEFAULT_TOAST_PROPS);
				return;
			}
		}
		setIsLoadingSave(true);
		let photoServices;
		if (
			formData.SkipperLicenseUrl &&
			formData.SkipperLicenseUrl?.type !== null
		) {
			var type = formData.SkipperLicenseUrl.type.replaceAll("image/", "");
			type = type.replaceAll("application/", "");

			await ApiClient.uploadPhotoService("skipperlicense", type).then(
				(res) => (photoServices = res)
			);

			await ApiClient.uploadUserPhoto(
				photoServices.UploadUrl,
				formData.SkipperLicenseUrl
			);
		}
		const dateBirth = dateFormat(new Date(formData.DateOfBirth), "yyyy-MM-dd");
		const embarkDate = dateFormat(new Date(formData.EmbarkDate), "yyyy-MM-dd");
		const disembarkDate = dateFormat(
			new Date(formData.DisembarkDate),
			"yyyy-MM-dd"
		);
		var isCharter = role === "creator";
		if (role === "creator") {
			isCharter = formData.MemberOf === "charter";
		}
		ApiClient.addUpdateBusinessCrew(
			formData.Id,
			trip?.Id,
			formData.Type,
			formData.Name,
			formData.Surname,
			dateBirth,
			formData.Nationality,
			formData.PassportId,
			formData.IdentityId,
			formData.ContactNumber,
			formData.SkipperLicenseId,
			photoServices?.Url ?? formData.SkipperLicenseUrl?.split("?")[0],
			MemberOf,
			embarkDate,
			disembarkDate,
			isCharter
		)
			.then((res) => {
				if (res?.ErrorCode && res?.ErrorMessage) {
					toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
					return;
				}

				setInAdding(false);
				loadData();
			})
			.catch((error) => {
				setInAdding(false);
			})
			.finally(() => {
				setIsLoadingSave(false);
			});
	};

	const loadData = async () => {
		const user = trip.Members.filter(
			(u) => u.UserId === currentUserData?.ID
		)[0];
		let role = user ? user?.Role : "no-member";
		setRole(role);
		var completedCharter = false;
		var completedClient = false;

		var toParse = [];
		if (
			role === "creator" ||
			role === "cocreator" ||
			role === "superpassenger"
		) {
			await ApiClient.getClientCrewList(trip?.Id).then((res) => {
				setIsCompletedClient(res.IsCompleted ?? false);
				completedClient = res.IsCompleted ?? false;
				(res.Data ?? []).forEach((e) => {
					toParse.push(e);
				});
			});
		}
		if (role === "creator" || role === "basemanager") {
			if (
				role === "basemanager" &&
				trip.ListsStates &&
				trip.ListsStates?.crewclient !== null
			) {
				setIsCompletedClient(trip.ListsStates.crewclient === "completed");
			}
			await ApiClient.getCharterCrewList(trip?.Id)
				.then((res) => {
					setIsCompletedCharter(res.IsCompleted ?? false);
					completedCharter = res.IsCompleted ?? false;
					(res.Data ?? []).forEach((e) => {
						toParse.push(e);
					});
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setLoading(false);
		}

		// HANDLE ADD CREATOR TYPE
		const milliseconds = 86400000;
		const startDate = new Date(trip.StartDate);
		const tripDuration = trip.DurationDays;
		const endDate = new Date(startDate.getTime() + tripDuration * milliseconds);

		setTripStartDate(startDate);
		setTripEndDate(endDate);

		setInitialFormValues({
			Name: "",
			Surname: "",
			DateOfBirth: null,
			Nationality: "",
			PassportId: "",
			IdentityId: "",
			ContactNumber: "",
			SkipperLicenseId: "",
			SkipperLicenseUrl: null,
			CurrentID: "PassportId",
			Type: "passenger",
			EmbarkDate: startDate,
			DisembarkDate: endDate,
			TripStartDate: startDate,
			TripEndDate: endDate,
			Collapse: true,
			CanAddCharter: !completedCharter,
			CanAddClient: !completedClient,
		});

		// VISIBILITY ADD BUTTON
		if (trip.IsCrewed !== null) {
			if (
				(!trip.IsCrewed &&
					completedClient &&
					(role === "creator" || role === "basemanager")) ||
				(trip.IsCrewed &&
					completedClient &&
					completedCharter &&
					(role === "creator" || role === "basemanager"))
			) {
				setCanDisplayAddButton(false);
			} else {
				setCanDisplayAddButton(true);
			}
		}

		// PARSE DATA
		parseData(toParse);
	};

	const parseData = (res) => {
		var data = (res ?? []).reverse();
		const toCSV = [];
		const user = trip.Members.filter(
			(u) => u.UserId === currentUserData?.ID
		)[0];
		let role = user ? user?.Role : "no-member";
		setRole(role);

		data.forEach((e) => {
			const dateBirth = dateFormat(new Date(e.DateOfBirth), "yyyy-MM-dd");
			let newJ = {
				Type: e.Type,
				MemberOf: e.MemberOf,
				Name: e.Name,
				Surname: e.Surname,
				DateOfBirth: dateBirth,
				Nationality: e.Nationality,
				PassportId: e.PassportId ?? "-",
				IdentityId: e.IdentityId ?? "-",
				ContactNumber: e.ContactNumber,
				EmbarkDate: e.EmbarkDate ?? "-",
				DisembarkDate: e.DisembarkDate ?? "-",
				SkipperLicenseId: e.SkipperLicenseId ?? "-",
				SkipperLicenseURL: e.SkipperLicenseUrl ?? "-",
			};
			toCSV.push(newJ);
		});

		data = data.sort((a, b) => (a.MemberOf > b.MemberOf ? 1 : -1));

		let canUserEdit =
			role === "creator" || role === "cocreator" || role === "superpassenger";

		let canEditClientList =
			role === "creator" || role === "cocreator" || role === "superpassenger";

		let canEditCharterList = role === "creator";

		if (trip.IsCrewed !== null) {
			setIsCrewed(trip.IsCrewed);
		}

		setCanEdit(trip?.State === "onboarding" && canUserEdit);

		setCanEditClientList(trip?.State === "onboarding" && canEditClientList);

		setCanEditCharterList(trip?.State === "onboarding" && canEditCharterList);

		setCanFinalizeClient(
			(role === "creator" ||
				role === "cocreator" ||
				role === "superpassenger") &&
				trip?.State === "onboarding"
		);

		setCanFinalizeCharter(role === "creator" && trip?.State === "onboarding");

		if (deadlineReached && role === "superpassenger") {
			setCanFinalizeClient(false);
			setCanEdit(false);
			setCanEditClientList(false);
			toast.warning(
				"You are past the deadline for completing the list. You can no longer make changes.",
				DEFAULT_TOAST_PROPS
			);
		}
		const startDate = new Date(trip.StartDate);
		const tripDuration = trip.DurationDays;

		const milliseconds = 86400000;
		const endDate = new Date(startDate.getTime() + tripDuration * milliseconds);

		setCrewList(
			data.map((item) => {
				var newItem = item;
				newItem.CurrentID = item.PassportId ? "PassportId" : "IdentityId";
				newItem.TripStartDate = startDate;
				newItem.TripEndDate = endDate;
				return { ...initialFormValues, ...newItem };
			})
		);

		setJsonCrewList(toCSV);
		setInAdding(false);
		setInEditing(null);
		setLoading(false);
	};

	const deleteMember = (member) => {
		var isCharter = role === "creator";
		if (role === "creator") {
			isCharter = member.MemberOf === "charter";
		}
		ApiClient.deleteCrewMember(
			trip?.Id,
			member.Id,
			member.MemberOf,
			isCharter
		).then((resp) => {
			if (resp?.ErrorCode && resp?.ErrorMessage) {
				toast.error(resp.ErrorMessage, DEFAULT_TOAST_PROPS);
			}
			loadData();
		});
	};

	const markAsCompletedClient = async () => {
		if (role === "superpassenger" || role === "cocreator") {
			confirmAlert({
				title: "Confirmation",
				message: languages[currentLanguage]["trip_confirmation_action"],
				buttons: [
					{
						label: "Yes",
						onClick: () => updateStateClientCrewList(),
					},
					{
						label: "No",
					},
				],
			});
			return;
		}
		updateStateClientCrewList();
	};

	const updateStateClientCrewList = async () => {
		setLoading(true);
		ApiClient.updateClientCrewListFlag(trip?.Id, !IsCompletedClient)
			.then((res) => {
				loadData();
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const markAsCompletedCharter = async () => {
		setLoading(true);
		ApiClient.updateCharterCrewListFlag(trip?.Id, !IsCompletedCharter)
			.then((res) => {
				loadData();
			})
			.catch((error) => {
				setLoading(false);
				//console.log("ERROR ==>", error);
			});
	};

	const canEditFunction = (member) => {
		if (role === "creator" || role === "cocreator") {
			if (member.MemberOf === "client" && IsCompletedClient) {
				return false;
			}
			if (member.MemberOf === "charter" && IsCompletedCharter) {
				return false;
			}
			return true;
		} else {
			return canEdit && !IsCompletedClient;
		}
	};

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		if (visible) {
			loadData();
		}
	}, [visible, selectedUserProfileId]);

	return (
		<>
			<SideBar
				visible={visible}
				useBackBtn={true}
				back_title={languages[currentLanguage]["trip_crew_list"]}
				back_subTitle={languages[currentLanguage]["trip_crew_manage_header"]}
				onClickBack={closeSidebar}
				isLoading={loading}
				sidebarProps={{
					ref: sidebarContentRef,
				}}
				fixedFooterButton={true}
				footerButtons={[
					...(((!IsCompletedClient && canFinalizeClient) ||
						(IsCompletedClient &&
							(role === "creator" || role === "cocreator"))) &&
					!!crewList?.length
						? [
								{
									label: !IsCompletedClient
										? languages[currentLanguage][
												"trip_crew_list_final_guest_btn"
										  ]
										: languages[currentLanguage][
												"trip_crew_list_edit_guest_btn"
										  ],
									props: {
										className: "deci",
										style: {
											color: "white",
											backgroundColor: !IsCompletedClient
												? filterHighlightColor
												: "#8F96A7",
											textTransform: "initial",
											border: "none",
											fontSize: 11,
										},
										onClick: () => {
											markAsCompletedClient();
										},
									},
								},
						  ]
						: []),

					...(canFinalizeCharter && IsCrewed && !!crewList?.length
						? [
								{
									label: !IsCompletedCharter
										? languages[currentLanguage][
												"trip_crew_list_final_crew_btn"
										  ]
										: languages[currentLanguage][
												"trip_crew_list_edit_crew_btn"
										  ],
									props: {
										className: "deci",
										style: {
											color: "white",
											backgroundColor: !IsCompletedCharter
												? filterHighlightColor
												: "#8F96A7",
											textTransform: "initial",
											border: "none",
											fontSize: 11,
										},
										onClick: () => {
											markAsCompletedCharter();
										},
									},
								},
						  ]
						: []),
				]}
			>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={snackbarClipboard}
					autoHideDuration={2000}
					onClose={() => setSnackbarClipboard(false)}
					message="Copied to clipboard"
				/>

				{(((role === "creator" || role === "cocreator") &&
					canEditCharterList &&
					!IsCompletedCharter &&
					canDisplayAddButton) ||
					(canEditClientList && !IsCompletedClient && canDisplayAddButton)) && (
					<IconButton
						disabled={inAdding}
						onClick={() => {
							if (!isLoadingSave) {
								const sidebar = sidebarContentRef.current.firstElementChild;

								setInAdding(true);
								setInEditing(null);
								setTimeout(() => {
									sidebar.scrollTo(0, sidebar.scrollHeight);
								}, 100);
							}
						}}
						style={{
							position: "absolute",
							zIndex: 11,
							top: 29,
							right: 15,
							padding: 0,
							opacity: inAdding ? 0.3 : 1,
						}}
					>
						<img src={TripAddIcon} style={{ height: 28, width: 28 }} />
					</IconButton>
				)}

				{((IsCrewed &&
					IsCompletedClient &&
					IsCompletedCharter &&
					(role === "creator" || role === "basemanager")) ||
					(!IsCrewed &&
						IsCompletedClient &&
						(role === "creator" || role === "basemanager"))) && (
					<IconButton
						style={{
							position: "absolute",
							zIndex: 11,
							top: 29,
							right: 15,
							padding: 0,
						}}
						onClick={() => exportToXLSX(jsonCrewList, "Crew_list")}
					>
						<img src={FullExportListIcon} style={{ height: 28, width: 28 }} />
					</IconButton>
				)}

				<div className="d-flex flex-column w-100 h-100" style={{ flex: 1 }}>
					<div className="d-flex flex-column py-3" style={{ flex: 1 }}>
						{!crewList.length && !inAdding && (
							<div
								className="d-flex flex-column justify-content-center align-items-center px-3"
								style={{ flex: 1 }}
							>
								<img
									src={CrewListEmptyStateIcon}
									className="mb-4"
									style={{ width: "50%" }}
								/>

								<span
									className="mb-2"
									align="center"
									style={{
										fontSize: 21,
										color: "#222c4b",
									}}
								>
									{
										languages[currentLanguage][
											"trip_crew_list_emptystate_header"
										]
									}
								</span>

								{((IsCompletedClient &&
									IsCompletedCharter &&
									(role === "creator" || role === "basemanager")) ||
									(canEditClientList &&
										!IsCompletedClient &&
										canDisplayAddButton)) && (
									<span
										align="center"
										style={{
											fontSize: 14,
											color: "#8f96a7",
											marginTop: 11,
										}}
									>
										{
											languages[currentLanguage][
												"trip_crew_list_emptystate_subheader"
											]
										}
									</span>
								)}
							</div>
						)}

						{crewList?.map((member) => (
							<CrewListCollapseCard
								{...{
									key: member.Id,
									member: member,
									inEditing: inEditing === member.Id,
									inAdding: false,
									isLoadingSave: isLoadingSave && inEditing === member.Id,
									role: role,
									onEdit: () => {
										if (!isLoadingSave) {
											setInEditing(member.Id);
											setInAdding(false);
										}
									},
									canEdit: canEditFunction(member),
									onDelete: () => {
										if (!isLoadingSave) {
											deleteMember(member);
											setInAdding(false);
										}
									},
									onCancel: () => !isLoadingSave && setInEditing(null),
									onSave: onSaveCrewData,
									onCollapse: () =>
										!(inEditing === member.Id && isLoadingSave) &&
										setCrewList(
											crewList.map((item) => {
												return {
													...item,
													Collapse:
														item.Id === member.Id
															? !item.Collapse
															: item.Collapse,
												};
											})
										),
								}}
							/>
						))}

						{inAdding && (
							<CrewListCollapseCard
								{...{
									member: { ...initialFormValues, Collapse: false },
									inEditing: false,
									inAdding: inAdding,
									isLoadingSave: isLoadingSave && inAdding,
									onSave: onAddCrew,
									role: role,
									onCancel: () => !isLoadingSave && setInAdding(false),
								}}
							/>
						)}
					</div>
				</div>
			</SideBar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewList);

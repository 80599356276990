import React, { useState, useEffect } from "react";
import { TripBox } from "./styled";
import PropTypes from "prop-types";
import First from "./Steps/First";
import Second from "./Steps/Second";
import Third from "./Steps/Third";
import Fourth from "./Steps/Fourth";
import Fith from "./Steps/Fith";
import Sixth from "./Steps/Sixth";
import { ApiClient } from "../../ApiClient";
import { connect, useSelector } from "react-redux";
import { languages } from "../../store/features/translation/Languages";
import { currentLang } from "../../store/features/translation/selectors";
import Alert from "@material-ui/lab/Alert";
import { IconButton } from "@material-ui/core";
import {
	retrieveTripDaysList,
	setTripItinerary,
} from "../../store/features/map/actions";
import { currentTripItinerary } from "../../store/features/map/selectors";
import { logEvent_ } from "../../helpers/analytics";

export const formatDate = (date, trip) => {
	const months = [
		"january",
		"february",
		"march",
		"april",
		"may",
		"jun",
		"july",
		"august",
		"september",
		"october",
		"november",
		"december",
	];
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + parseInt(d.getDate()),
		year = d.getFullYear();

	if (month.length < 2 && trip !== "trip") month = "0" + month;
	if (day.length < 2 && trip !== "trip") day = "0" + day;
	if (trip === "trip")
		return [
			`${day}${
				day === "1"
					? "st"
					: day === "2"
					? "nd"
					: day === "3" || day === "23"
					? "rd"
					: "th"
			}`,
			months[month - 1],
		].join(" ");
	return [year, month, day].join("-");
};

export const formatDate_ = (date) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	var d = new Date(date),
		month = d.getMonth(),
		day = d.getDay(),
		date = parseInt(d.getDate());

	return (
		[`${days[day]},`, date, months[month]].join(" ") + " " + d.getFullYear()
	);
};
const TripWizard = (props) => {
	const [state, setState] = useState({
		step: 1,
		Type: "community",
		Name: "",
		IsPublic: true,
		Cover: "",
		Description: "",
		StartDate: "",
		DurationDays: 0,
		VesselType: "",
		VesselLengthMeters: 10,
		VesselSpeedKnots: 10,
		Attributes: [],
	});
	const [stateProps, setStateProps] = useState(null);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [finished, setFinished] = useState(false);
	const currentLanguage = useSelector(currentLang);
	const tripItinerary = useSelector(currentTripItinerary);

	const reset = () => {
		setState({
			step: 1,
			Type: "community",
			Name: "",
			IsPublic: true,
			Cover: "",
			Description: "",
			StartDate: "",
			DurationDays: 0,
			VesselType: "",
			VesselLengthMeters: 10,
			VesselSpeedKnots: 10,
			Attributes: [],
		});
		setFinished(true);
		setStateProps(null);
		setNotice({
			type: "",
			content: "",
		});
	};

	const handleSubmitTrip = (data) => {
		let sDate = state.StartDate !== null ? formatDate(state.StartDate) : null;
		if (props.dataTrip !== null) {
			ApiClient.updateTrip(
				props.dataTrip.Id,
				state.VesselType,
				state.VesselLengthMeters,
				state.VesselSpeedKnots,
				sDate,
				state.DurationDays,
				state.Attributes,
				data.tripName,
				data.url ? data.url : null,
				data.description,
				data.public
			)
				.then((res) => {
					// //console.log("RES UPDATE ===>", res);
					if (!res.ErrorCode && !res.ErrorMessage) {
						setNotice({
							type: "SUCCESS",
							content: "Trip updated successfully",
						});
						setFinished(true);
						setStateProps(null);
						setTimeout(() => {
							reset();
							props.onClose();
							props.displayOverview(props.dataTrip?.Id);
						}, 500);
					} else {
						setNotice({
							type: "ERROR",
							content: res.ErrorMessage,
						});
						setFinished(true);
						setStateProps(null);
					}
				})
				.catch((error) => {
					//console.log("ERROR ===>", error);
					setNotice({
						type: "ERROR",
						content: error.ErrorMessage,
					});
					// setFinished(true);
				});
		} else {
			ApiClient.createTrip(
				state.VesselType,
				state.VesselLengthMeters,
				state.VesselSpeedKnots,
				sDate,
				state.DurationDays,
				state.Attributes,
				data.tripName,
				data.url ? data.url : null,
				data.description,
				data.public
			)
				.then((res) => {
					setFinished(false);
					if (!res.ErrorCode && !res.ErrorMessage) {
						(async () => {
							props.setTripItinerary({
								...tripItinerary,
								data: res,
								visibility: true,
								selectedDay: {
									...tripItinerary.selectedDay,
									inEditing: true,
								},
								canEdit: true,
							});
							props.retrieveTripDaysList(res?.Id);
							// FIREBASE EVENT
							logEvent_("react_tripwiz_create_success", { tripId: res?.Id });

							setNotice({
								type: "SUCCESS",
								content: languages[currentLanguage]["trip_description_header"],
							});
							props.onCloseTripList();
							setFinished(true);
							setStateProps(null);
							setTimeout(() => {
								reset();
								props.onClose();
							}, 500);
						})();
					} else {
						setNotice({
							type: "ERROR",
							content: res.ErrorMessage,
						});
						setFinished(true);
						setStateProps(null);
					}
				})
				.catch((error) => {
					//console.log("ERROR ===>", error);
					setNotice({
						type: "ERROR",
						content: error.ErrorMessage,
					});
					// setFinished(true);
				});
		}
	};
	const { visible } = props;
	const handleBack = (data) => {
		// //console.log("RECEIVED ===>", data);
		switch (data.step) {
			case 1:
				setState({
					step: 1,
					Type: "community",
					Name: "",
					IsPublic: true,
					Cover: "",
					Description: "",
					StartDate: "",
					DurationDays: 0,
					VesselType: "",
					VesselLengthMeters: 10,
					VesselSpeedKnots: 10,
					Attributes: [],
				});
				props.onCancel();
				logEvent_("react_tripwiz_stepinfo_cancelbtn_pressed");
				break;
			case 2:
				setState({
					...state,
					step: 1,
				});
				break;
			case 3:
				setState({
					...state,
					step: 2,
				});
				break;
			case 4:
				setState({
					...state,
					step: 3,
				});
				break;
			case 5:
				setState({
					...state,
					step: 4,
				});
				break;
			case 6:
				break;
			default:
				break;
		}
	};
	const handleNext = (data) => {
		// //console.log("RECEIVED ===>", data);
		let newTags = [];
		switch (data.step) {
			case 1:
				setState({
					...state,
					VesselType: data.type,
					VesselSpeedKnots: data.speed,
					VesselLengthMeters: data.length,
					step: 2,
				});
				break;
			case 2:
				setState({
					...state,
					StartDate: data.startDate,
					DurationDays: data.numberOfDays,
					step: 3,
				});
				break;
			case 3:
				newTags = data.tags.concat(state.Attributes);
				// //console.log("TAGS ===>", newTags);
				setState({
					...state,
					Attributes: newTags,
					step: 4,
				});
				break;
			case 4:
				newTags = data.tags.concat(state.Attributes);
				// //console.log("TAGS ===>", newTags);
				setState({
					...state,
					step: 5,
					Attributes: newTags,
				});
				break;
			case 5:
				newTags = data.tags.concat(state.Attributes);
				// //console.log("TAGS ===>", newTags);
				setState({
					...state,
					step: 6,
					Attributes: newTags,
				});
				break;
			case 6:
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (props.dataTrip !== null) {
			if (props.dataTrip.Type !== "community") {
				return;
			}
			let data = props.dataTrip;
			setStateProps({
				step: 1,
				Type: "community",
				Name: data.Name,
				IsPublic: data.IsPublic,
				Cover: data.Cover,
				Description: data.Description,
				StartDate: data.StartDate,
				DurationDays: data.DurationDays,
				VesselType: data.VesselType,
				VesselLengthMeters: data.VesselLengthMeters,
				VesselSpeedKnots: data.VesselSpeedKnots,
				Attributes: data.Attributes,
			});
		} else {
			//logEvent_('react_screen_create_trip_view');
		}
	}, [props.dataTrip]);
	return (
		<>
			<TripBox
				className={
					!visible ? "d-none" : "d-flex flex-column align-items-center"
				}
			>
				<div
					className="position-fixed bg-white"
					style={{
						minHeight: 50,
						top: 0,
						width: "calc(100% - 20px)",
						zIndex: 99,
					}}
				>
					<div className="position-relative w-100">
						<div
							className="position-absolute"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								height: 10,
								width: `${(state.step / 6) * 100}%`,
								transition: "all .3s linear",
								backgroundColor: "#00ff00",
							}}
						></div>

						<IconButton
							onClick={() => {
								logEvent_("react_tripwiz_stepinfo_cancelbtn_pressed");
								reset();
								props.onClose();

								if (props.dataTrip?.Id && props.displayOverview) {
									props.displayOverview(props.dataTrip?.Id);
								}
							}}
							component="span"
							className="position-absolute d-flex justify-content-center align-items-center text-white p-0"
							style={{
								zIndex: 2,
								top: 20,
								right: 5,
								height: 30,
								width: 30,
								fontSize: 12,
								backgroundColor: "rgba(0,0,0,0.4)",
							}}
						>
							<i className="fas fa-times fa-fw" />
						</IconButton>
					</div>
				</div>

				<First
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 1}
					finished={finished}
				/>

				<Second
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 2}
					finished={finished}
				/>
				<Third
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 3}
					finished={finished}
				/>
				<Fourth
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 4}
					finished={finished}
				/>
				<Fith
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 5}
					finished={finished}
				/>
				<Sixth
					state={stateProps}
					handleSubmit={handleSubmitTrip}
					visible={state.step === 6}
					finished={finished}
				/>
				{notice.type !== "" && (
					<>
						<Alert
							onClose={() => {
								setNotice({ type: "", content: "" });
							}}
							variant="filled"
							severity={notice.type.toLowerCase()}
							style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
						>
							{notice.content}
						</Alert>
					</>
				)}
			</TripBox>
		</>
	);
};

TripWizard.propTypes = {
	visible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTripItinerary: (data = {}, combine = true) =>
			dispatch(setTripItinerary(data, combine)),
		retrieveTripDaysList: (tripId) => dispatch(retrieveTripDaysList(tripId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripWizard);

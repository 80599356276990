// CONFIGS
import { API_CONFIG } from "./appConfig";
import { AuthService } from "./AuthService";

// HELPERS
import { formatNativeDate, isEmpty } from "./helpers/functions";
import { version } from "../package.json";

const ApiClient = (function () {
	const apiVersion = "3.2";

	function createAuthServerHeaders(access_token, auth_code) {
		let headers = new Headers();
		headers.append("Authorization", access_token);
		headers.append("api-version", apiVersion);
		headers.append("web-version", version);
		headers.append("Content-Type", "application/json");
		if (auth_code !== null) {
			let redirect = "https://" + window.location.host + "/explore";
			headers.append("Authorization-Code", auth_code);
			headers.append("Apple-Redirect", redirect);
		}
		return headers;
	}

	function createHeaders(forceValues = {}) {
		let headers = new Headers();
		headers.append("api-version", forceValues?.apiVersion ?? apiVersion);
		headers.append("web-version", forceValues?.version ?? version);
		if (AuthService.isUserLogedIn()) {
			headers.append(
				"keeano-token",
				JSON.parse(localStorage.getItem("session")).Token
			);
		}
		return headers;
	}

	const apiUrl =
		process.env.REACT_APP_ENVIRONMENT === "production"
			? API_CONFIG.apiProdUrl
			: API_CONFIG.apiUrl;
	const authUrl =
		process.env.REACT_APP_ENVIRONMENT === "production"
			? API_CONFIG.authProdUrl
			: API_CONFIG.authDevUrl;
	const bookmarks = "bookmarks";
	const cacheExpiration = 3600; // 1 hour
	const cacheExpirationTripsTile = 7200; // 2 hours

	return {
		getBookMarksOtherUser: async function (userId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url =
					apiUrl + `/api/users/other/lists/${bookmarks}/?userId=${userId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getBookMarks: async function () {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/users/my/lists/${bookmarks}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		addBookMark: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/users/my/lists/${bookmarks}/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		addBookmarks: async function (placeIds) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(placeIds),
				};
				const url = apiUrl + `/api/users/my/lists/${bookmarks}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		deleteBookMark: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/users/my/lists/${bookmarks}/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {}
		},

		getSearchResults: async function (term) {
			let headers = createHeaders();
			const myInit = {
				method: "GET",
				headers: headers,
				mode: "cors",
				cache: "default",
			};
			const url = apiUrl + `/api/search?term=${term}`;
			let myRequest = new Request(url);
			const response = await fetch(myRequest, myInit);
			const data = await response.json();
			return data;
		},

		getGTline: async function getGTline(lineId) {
			try {
				let headers = createHeaders();
				headers.append("Cache-Control", `max-age=${cacheExpiration}`);
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/coastview/line?Id=${lineId}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMTVessels: async function (x, y, z) {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/vessels?x=${x}&y=${y}&z=${z}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getFilters: async function () {
			let headers = createHeaders();

			const myInit = {
				method: "GET",
				headers: headers,
				mode: "cors",
				cache: "default",
			};

			// const url = apiUrl + "/api/places/mapfilters"; // 3.3
			const url = apiUrl + "/api/misc/mapfilters";
			let myRequest = new Request(url);

			const response = await fetch(myRequest, myInit);
			var data = await response.json();

			for (var index in data) {
				data[index]["trips"].types = ["community", "business"];
			}
			return data;
		},

		getUserTags: async () => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + "/api/misc/usertags";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		updateBasicUserProfile: async (
			FirstName,
			LastName,
			UserName,
			DateOfBirth,
			Nationality,
			Url,
			Description
		) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						FirstName: FirstName,
						LastName: LastName,
						UserName: UserName,
						DateOfBirth: DateOfBirth,
						Nationality: Nationality,
						Url: Url,
						Description: Description,
					}),
				};
				const url = apiUrl + "/api/users/my/basicprofile";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getPhotoServiceSignature: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/photoservice/signature";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		uploadUserPhoto: async (UploadUrl, Photo) => {
			try {
				let headers = createHeaders();
				headers.append("x-ms-version", "2015-02-21");
				headers.append("x-ms-blob-type", "BlockBlob");
				headers.append("x-ms-date", formatNativeDate(new Date()));
				headers.append("Content-Type", "multipart/form-data");

				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: Photo,
				};
				const url = UploadUrl;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		uploadPhotoPlace: async (placeId, Url) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						PlaceId: placeId,
						Url: Url,
					}),
				};
				const url = apiUrl + "/api/actions/photo";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		putUserPhoto: async (URL) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Photo: URL,
					}),
				};
				const url = apiUrl + "/api/Users/my/profilephoto";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		updateSettings: async (
			ProfilePublic,
			TransportationPublic,
			DistanceUnits,
			DepthUnits,
			FuelUnit,
			SpeedUnit,
			TempUnits,
			WindSpeedUnits
		) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						ProfilePublic: ProfilePublic,
						VesselsPublic: TransportationPublic,
						DistanceUnits: DistanceUnits,
						DepthUnits: DepthUnits,
						FuelUnit: FuelUnit,
						SpeedUnit: SpeedUnit,
						TempUnits: TempUnits,
						WindSpeedUnits: WindSpeedUnits,
					}),
				};

				const url = apiUrl + "/api/users/my/settings";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		updateTags: async (tags) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(tags),
				};
				const url = apiUrl + "/api/users/my/tags";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getTripAttributes: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				// const url = apiUrl + "/api/trips/attributes";
				const url = apiUrl + "/api/misc/tripattributes";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getTripAttributesFilter: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/misc/triptags";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		/**
		 * Get place attributes
		 *
		 * @returns {any[] | null}
		 */
		getPlaceAttributes: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/misc/typeattributes";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		/**
		 * Get place tages
		 *
		 * @returns {any[] | null}
		 */
		getPlaceTags: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/misc/typetags";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		/**
		 * Get place types
		 *
		 * @returns {any[] | null}
		 */
		getPlaceAttributes: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/misc/typesforcreation";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		addPlace: async (Name, Location, Type, Attributes) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Name: Name,
						Location: [Location[0], Location[1]],
						Type: Type,
						Attributes: Attributes,
					}),
				};
				const url = apiUrl + "/api/places";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		selectVessel: async (VESSEL_ID) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						ID: VESSEL_ID,
						Selected: true,
					}),
				};
				const url = apiUrl + "/api/users/my/vessels";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getUser: async () => {
			if (!AuthService.isUserLogedIn()) {
				return;
			}
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/users/me`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				window.localStorage.setItem(
					"currentUserType",
					JSON.stringify({
						type: data.Type,
					})
				);
				return data;
			} catch (ex) {
				return null;
			}
		},

		getOtherUser: async (userName) => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const query = Number.isInteger(userName) ? "id" : "username";
				const url = apiUrl + `/api/users/other?${query}=${userName}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getOtherUserById: async (id) => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/users/other?id=${id}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		localLoginme: async function (provider, access_token, auth_code) {
			let tokenType = "";
			switch (provider) {
				case "facebook":
					tokenType = "FacebookAccessToken";
					break;
				case "google":
					tokenType = "GoogleAccessToken";
					break;
				case "apple":
					tokenType = "AppleAccessToken";
					break;
				default:
					tokenType = "Bearer";
					break;
			}
			const authHeader = tokenType + " " + access_token;
			let headers = createAuthServerHeaders(authHeader, auth_code);
			const myInit = {
				method: "POST",
				headers: headers,
				mode: "cors",
			};

			const url = apiUrl + "/api/login/me";
			let myRequest = new Request(url);

			const response = await fetch(myRequest, myInit);
			const data = await response.json();
			return data;
		},

		// VIRTUAL GUIDE
		/**
		 * Methode to get available virtual guide states
		 *
		 * @returns {Promise<string[]>}
		 */
		getAvailableVirtualGuideStates: async () => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/trips/business/virtualguideservice/states`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return [];
			}
		},

		/**
		 * Methode to update trip virtual guide service state
		 *
		 * @param {string} tripId Trip Id
		 * @param {string} state New virtual guide state
		 * @returns {Promise<null | {
        State: string;
        StartDateTimeUtc: string;
        EndDateTimeUtc: string;
        PricePerDay: number
      }>}
		 */
		setVirtualGuideServiceState: async (tripId, state) => {
			try {
				if (!(tripId && state)) {
					throw "Need valide string values";
				}

				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						State: state,
					}),
				};
				const url =
					apiUrl + `/api/trips/business/${tripId}/booked/virtualguideservice`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		/* REVIEWS */
		getPlaceReviews: async function (placeId) {
			if (placeId === null) {
				return;
			}
			try {
				let headers = createHeaders();
				headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
				headers.append("Pragma", "no-cache");
				headers.append("Expires", 0);
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "no-store",
				};
				const url = apiUrl + `/api/places/reviews/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		addReview: async function (PlaceId, Rating, Text, Url) {
			try {
				var baseBody = {
					PlaceId: PlaceId,
					Rating: Rating,
					Text: Text,
				};
				if (Url) {
					baseBody["Url"] = Url;
				}
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(baseBody),
				};
				const url = apiUrl + "/api/actions/review";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getPlaces: async function (x, y, z, resetCache) {
			try {
				var cache = "default";
				var headers = createHeaders();
				if (resetCache) {
					headers.append(
						"Cache-Control",
						"no-cache, no-store, must-revalidate"
					);
					headers.append("Pragma", "no-cache");
					headers.append("Expires", 0);
					cache = "no-store";
				} else {
					//headers.append("Cache-Control", `max-age=${cacheExpiration}`);
				}
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: cache,
				};
				const url = apiUrl + `/api/places?x=${x}&y=${y}&z=${z}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPlacePreview: async function (placeId) {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/places/preview/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPlaceDetails: async function (placeId) {
			try {
				let headers = createHeaders();
				headers.append("Cache-Control", "no-cache, no-store, must-revalidate");
				headers.append("Pragma", "no-cache");
				headers.append("Expires", 0);
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "no-store",
				};
				const url = apiUrl + `/api/places/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		signup: async function (fName, lName, password, email) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Email: email,
						Password: password,
						FirstName: fName,
						Surname: lName,
					}),
				};
				const url = authUrl + "/api/register";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		resetPassword: async function (email) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Email: email,
					}),
				};
				const url = authUrl + "/api/ForgetPass";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getWeatherPlace: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/places/weather/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPlaceGalleryPhotos: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/places/photos/${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPlaceCoastViewPhotos: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/coastview/gallery?pl=${placeId}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getGlobalWeatherPrediction: async function (placeId) {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/misc/weatherpredictiontimes`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		createTrip: async function (
			vesselType,
			vesselLengthMeters,
			vesselSpeedKnots,
			startDate,
			durationDays,
			attributes,
			name,
			coverUrl,
			description,
			public_
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Type: "community",
						Name: name,
						IsPublic: public_,
						Cover: coverUrl,
						Description: description,
						StartDate: startDate,
						DurationDays: durationDays === 0 ? 1 : durationDays,
						VesselType: vesselType,
						VesselLengthMeters: vesselLengthMeters,
						VesselSpeedKnots: vesselSpeedKnots,
						Attributes: attributes,
					}),
				};
				const url = apiUrl + "/api/trips/community";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMyTrips: async function (isPartner = false) {
			try {
				let headers = createHeaders();
				var isUserLogedIn = await AuthService.isUserLogedIn();
				if (!isUserLogedIn) {
					return null;
				}
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isPartner ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/my`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMyCreatedTrips: async function () {
			try {
				const isPartner = AuthService.isUserPartner();
				if (isPartner) {
					return [];
					/*
					const myBusinessTrips = await ApiClient.getMyTrips(true);
					if (myBusinessTrips !== null) {
						return myBusinessTrips;
					} else {
						return [];
					}*/
				}
				const myBusinessTrips = await ApiClient.getMyTrips(true);
				const myCommunityTrips = await ApiClient.getMyTrips(false);
				if (myBusinessTrips !== null) {
					return myCommunityTrips.concat(myBusinessTrips);
				} else {
					return myCommunityTrips;
				}
			} catch (ex) {
				return [];
			}
		},

		getTrip: async function (id, isBusiness = false) {
			if (id === null) {
				return;
			}
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = id >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getTripFromShare: async function (guid, isBusiness = false) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/fromshare/${guid}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		duplicateTrip: async function (id, isBusiness = false) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = id >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/fromclone/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		acceptTripInviteFromGUID: async function (guid, isBusiness = false) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/frominvite/${guid}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteTrip: async (id, isBusiness = false) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = id >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		deleteTripTemplate: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/trips/business/template/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		updateTrip: async function (
			id,
			vesselType,
			vesselLengthMeters,
			vesselSpeedKnots,
			startDate,
			durationDays,
			attributes,
			name,
			coverUrl,
			description,
			public_
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Id: id,
						Type: "community",
						Name: name,
						IsPublic: public_,
						Cover: coverUrl,
						Description: description,
						StartDate: startDate,
						DurationDays: durationDays,
						VesselType: vesselType,
						VesselLengthMeters: vesselLengthMeters,
						VesselSpeedKnots: vesselSpeedKnots,
						Attributes: attributes,
					}),
				};
				const url = apiUrl + `/api/trips/community`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteMember: async (tripId, userId, role, isBusiness = false) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						TripId: tripId,
						UserId: userId,
						Role: role,
					}),
				};
				const path = tripId >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/membership`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {}
		},

		editMemberRole: async (tripId, userId, role, isBusiness = false) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						TripId: tripId,
						UserId: userId,
						Role: role,
					}),
				};
				const path = tripId >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/membership`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {}
		},

		visitOtherUserTrips: async (username, isBusiness = false) => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/other?username=${username}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		visitOtherUserTripsFromId: async (id, isBusiness = false) => {
			if (id === null) {
				return;
			}
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/other?id=${id}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getTripDaysList: async (id, isBusiness = false) => {
			try {
				let headers = createHeaders();

				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = id >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/${id}/itinerary`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		setTripItineraryDay: async (tripId, dayToCalculate, isBusiness = false) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Day: dayToCalculate.Day,
						StartTime: dayToCalculate.StartTime,
						Nodes: dayToCalculate.Nodes,
					}),
				};
				const path = tripId >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/${tripId}/itinerary/day`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		setNodeNoteTripItineraryDay: async ({
			tripId = null,
			Day = null,
			Order = null,
			Note = null,
			isBusiness = false,
		}) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Day: Day,
						Order: Order,
						Note: Note,
					}),
				};
				const path = tripId >= 1000000000 ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}/${tripId}/itinerary/day/node`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getTripTile: async function (x, y, z, isBusiness = false) {
			try {
				var headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${path}?x=${x}&y=${y}&z=${z}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getCometChatAuthToken: async () => {
			try {
				let headers = createHeaders();
				// headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/comet/auth_tokens`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getOtherUserVessels: async (id, isBusiness = false) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/vessels/${path}/other/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				if (data.ErrorCode) {
					const pathE = !isBusiness ? "business" : "community";
					const urlE = apiUrl + `/api/vessels/${pathE}/other/${id}`;
					let myRequestE = new Request(urlE);
					const resp = await fetch(myRequestE, myInit);
					const newData = await resp.json();
					return newData;
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMyVessels: async () => {
			try {
				const isPartner = AuthService.isUserPartner();
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isPartner ? "business" : "community";
				const url = apiUrl + `/api/vessels/${path}/my`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		createMyVessel: async (
			MMSI,
			CallSign,
			Flag,
			BeamMeters,
			DraftMeters,
			LengthMeters,
			ClearanceMeters,
			FuelConsumptionLiters,
			SpeedKnots,
			Type,
			Selected,
			Name
		) => {
			try {
				const isPartner = AuthService.isUserPartner();
				const toBeamMeters = BeamMeters === 0.0 || 0 ? null : BeamMeters;
				const toDraftMeters = DraftMeters === 0.0 || 0 ? null : DraftMeters;
				const toLengthMeters = LengthMeters === 0.0 || 0 ? null : LengthMeters;
				const toClearanceMeters =
					ClearanceMeters === 0.0 || 0 ? null : ClearanceMeters;
				const toFuelConsumptionLiters =
					FuelConsumptionLiters === 0.0 || 0 ? null : FuelConsumptionLiters;
				const toSpeedKnots = SpeedKnots === 0.0 || 0 ? null : SpeedKnots;
				let headers = createHeaders();
				const path = isPartner ? "business" : "community";
				headers.append("Content-Type", "application/json");
				const jsonBody = {
					MMSI: MMSI,
					CallSign: CallSign,
					Flag: Flag,
					BeamMeters: toBeamMeters,
					DraftMeters: toDraftMeters,
					LengthMeters: toLengthMeters,
					ClearanceMeters: toClearanceMeters,
					FuelConsumptionLiters: toFuelConsumptionLiters,
					SpeedKnots: toSpeedKnots,
					Type: Type + path,
					Selected: Selected,
					Name: Name,
				};
				if (isPartner) {
					delete jsonBody["Selected"];
				}
				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(jsonBody),
				};
				const url = apiUrl + `/api/vessels/${path}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateMyVessel: async (
			ID,
			MMSI,
			CallSign,
			Flag,
			BeamMeters,
			DraftMeters,
			LengthMeters,
			ClearanceMeters,
			FuelConsumptionLiters,
			SpeedKnots,
			Type,
			Selected,
			Name
		) => {
			try {
				const isPartner = AuthService.isUserPartner();
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const path = isPartner ? "business" : "community";
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						ID: ID,
						MMSI: MMSI,
						CallSign: CallSign,
						Flag: Flag,
						BeamMeters: BeamMeters,
						DraftMeters: DraftMeters,
						LengthMeters: LengthMeters,
						ClearanceMeters: ClearanceMeters,
						FuelConsumptionLiters: FuelConsumptionLiters,
						SpeedKnots: SpeedKnots,
						Type: Type + path,
						Selected: Selected,
						Name: Name,
					}),
				};
				const url = apiUrl + `/api/vessels/${path}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteMyVessel: async (id) => {
			try {
				const isPartner = AuthService.isUserPartner();
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const path = isPartner ? "business" : "community";
				const url = apiUrl + `/api/vessels/${path}/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMyBaseStations: async () => {
			try {
				let headers = createHeaders();

				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/basestations/my";
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		createUpdateBusinessTemplate: async function (
			id = null,
			name,
			public_,
			coverUrl,
			description,
			durationDays,
			placeId,
			vesselId,
			attributeDirection,
			attributeCrew,
			attributeStyle
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const json = {
					Id: id,
					Name: name,
					IsPublic: public_,
					Cover: coverUrl,
					Description: description,
					DurationDays: durationDays === 0 ? 1 : durationDays,
					PlaceId: placeId,
					VesselId: vesselId,
					AttributeDirection: attributeDirection,
					AttributeCrew: attributeCrew,
					AttributeStyle: attributeStyle,
				};
				if (id === null) {
					delete json["Id"];
				}
				const myInit = {
					method: id === null ? "POST" : "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};
				const url = apiUrl + "/api/trips/business/template";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		cloneTemplateToBusiness: async function (
			ParentTripId,
			Name,
			CharterId,
			Cover,
			Description,
			StartDate,
			DurationDays,
			VesselId,
			NumberOfAdults,
			NumberOfKids,
			AttributeDirection,
			AttributeCrew,
			AttributeStyle,
			Preferences,
			ArrivalTime,
			IsCrewed = false,
			EmailInvtations = [],
			HasCharterListsEnabled = true
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const json = {
					ParentTripId,
					Name,
					CharterId,
					Cover,
					Description,
					StartDate,
					DurationDays,
					VesselId,
					NumberOfAdults,
					NumberOfKids,
					AttributeDirection,
					AttributeCrew,
					AttributeStyle,
					Preferences,
					ArrivalTime,
					IsCrewed,
					EmailInvtations,
					HasCharterListsEnabled,
				};

				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};
				const url = apiUrl + "/api/trips/business/booked/fromtemplate";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getTransfers: async function (Id) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + "/api/trips/business/booked/" + Id + "/transfers";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				if (data.LastUpdate) {
					window.localStorage.setItem(
						"TransferLastUpdate",
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		createTransfer: async function (
			Id = null,
			TripId,
			PickUpDate,
			PickUpPlace,
			DestinationPlace,
			NumberOfAdults,
			Contact,
			Name
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const dataJson = {
					Id: Id,
					TripId: TripId,
					PickUpDateTime: PickUpDate,
					PickUpPlace: PickUpPlace,
					DestinationPlace: DestinationPlace,
					NumberOfAdults: NumberOfAdults,
					Contact: Contact,
					Name: Name,
				};

				const myInit = {
					method: Id === null ? "POST" : "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(dataJson),
				};
				const url = apiUrl + "/api/trips/business/booked/transfers";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteTransfer: async function (Id, TripId) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url =
					apiUrl + `/api/trips/business/booked/${TripId}/transfers/${Id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		completeTransfer: async function (Id, TripId, completed) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const dataJson = {
					Id: Id,
					TripId: TripId,
					IsCompleted: completed,
				};
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(dataJson),
				};
				const url = apiUrl + `/api/trips/business/booked/transferow`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteBusinessTrip: async function (TripId, isDelete = true) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const action = isDelete ? "delete" : "cancel";
				const url =
					apiUrl + "/api/trips/business/booked/" + TripId + "?action=" + action;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateBusinessTripPartner: async function (
			Id,
			Name,
			CharterId,
			Cover,
			Description,
			StartDate,
			DurationDays,
			VesselId,
			NumberOfAdults,
			NumberOfKids,
			AttributeDirection,
			AttributeCrew,
			AttributeStyle,
			Preferences,
			ArrivalTime,
			IsCrewed = false,
			HasCharterListsEnabled
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const json = {
					Id,
					Name,
					CharterId,
					Cover,
					Description,
					StartDate,
					DurationDays,
					VesselId,
					NumberOfAdults,
					NumberOfKids,
					AttributeDirection,
					AttributeCrew,
					AttributeStyle,
					Preferences,
					ArrivalTime,
					IsCrewed,
					HasCharterListsEnabled,
				};
				const myInit = {
					method: "PUT",
					headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};
				const url = apiUrl + "/api/trips/business/booked/partner";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateBusinessTripPassenger: async function (
			Id,
			NumberOfAdults,
			NumberOfKids,
			AttributeCrew,
			AttributeStyle,
			Preferences,
			ArrivalTime
		) {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const json = {
					Id: Id,
					NumberOfAdults: NumberOfAdults,
					NumberOfKids: NumberOfKids,
					AttributeCrew: AttributeCrew,
					AttributeStyle: AttributeStyle,
					Preferences: Preferences,
					ArrivalTime: ArrivalTime,
				};
				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};
				const url = apiUrl + "/api/trips/business/booked/passenger";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getClientCrewList: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url =
					apiUrl + `/api/trips/business/booked/${id}/crew` + "/client";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();

				if (data.LastUpdate) {
					window.localStorage.setItem(
						"CrewClientLastUpdate",
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		getCharterCrewList: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url =
					apiUrl + `/api/trips/business/booked/${id}/crew` + "/charter";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();

				if (data.LastUpdate) {
					window.localStorage.setItem(
						"CrewCharterLastUpdate",
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		addUpdateBusinessCrew: async (
			Id = null,
			TripId,
			Type,
			Name,
			Surname,
			DateofBirth,
			Nationality,
			PassportId,
			IdentityId,
			ContactNumber,
			SkipperLicenseId,
			SkipperLicenseUrl,
			MemberOf = null,
			EmbarkDate,
			DisembarkDate,
			isPartner = false
		) => {
			try {
				const lastUpdateKey = isPartner
					? "CrewCharterLastUpdate"
					: "CrewClientLastUpdate";
				const lastUpdate = localStorage.getItem(lastUpdateKey)
					? JSON.parse(localStorage.getItem(lastUpdateKey))
					: null;
				var LastUpdate = null;
				if (lastUpdate !== null) {
					LastUpdate = parseInt(lastUpdate);
				}

				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const dataJson = {
					Id: Id,
					TripId: TripId,
					Type: Type,
					Name: Name,
					Surname: Surname,
					DateofBirth: DateofBirth,
					Nationality: Nationality,
					PassportId: PassportId,
					IdentityId: IdentityId,
					ContactNumber: ContactNumber,
					SkipperLicenseId: SkipperLicenseId,
					SkipperLicenseUrl: SkipperLicenseUrl,
					EmbarkDate: EmbarkDate,
					DisembarkDate: DisembarkDate,
				};
				Object.entries(dataJson).forEach(([key, value]) => {
					if (value === null || value === "") {
						if (key !== "OnBoard") {
							delete dataJson[key];
						}
					}
				});

				const json = {
					Data: dataJson,
					LastUpdate: LastUpdate,
				};

				const myInit = {
					method: Id === null ? "POST" : "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				var path = isPartner ? "charter" : "client";
				if (MemberOf) {
					path = MemberOf;
				}
				const url = apiUrl + `/api/trips/business/booked/crew/` + path;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				if (data.LastUpdate) {
					window.localStorage.setItem(
						lastUpdateKey,
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		deleteCrewMember: async function (
			tripId,
			crewId,
			MemberOf = null,
			isPartner
		) {
			try {
				const lastUpdateKey = isPartner
					? "CrewCharterLastUpdate"
					: "CrewClientLastUpdate";
				const lastUpdate = localStorage.getItem(lastUpdateKey)
					? JSON.parse(localStorage.getItem(lastUpdateKey))
					: null;
				let headers = createHeaders();

				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				var path = isPartner ? "charter" : "client";
				if (MemberOf) {
					path = MemberOf;
				}
				const url =
					apiUrl +
					`/api/trips/business/booked/${tripId}/crew/${path}/${crewId}?version=${parseInt(
						lastUpdate
					)}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {}
		},

		uploadPhotoService: async (Category, Filetype) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify({
						Category: Category,
						Filetype: Filetype,
					}),
				};
				const url = apiUrl + "/api/uploadservice/signature";
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getTripProvisionList: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/trips/business/booked/${id}/provisions`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();

				if (data.LastUpdate) {
					window.localStorage.setItem(
						"ProvisionLastUpdate",
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		addUpdateProvisionList: async (id, products) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const lastUpdate = localStorage.getItem("ProvisionLastUpdate")
					? JSON.parse(localStorage.getItem("ProvisionLastUpdate"))
					: null;
				var LastUpdate = null;
				if (lastUpdate !== null) {
					LastUpdate = parseInt(lastUpdate);
				}

				const json = {
					Data: products,
					LastUpdate: LastUpdate,
				};

				const myInit = {
					method: "POST",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				const url = apiUrl + `/api/trips/business/booked/${id}/provisions`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				if (data.LastUpdate) {
					window.localStorage.setItem(
						"ProvisionLastUpdate",
						JSON.stringify(data.LastUpdate)
					);
				}
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPartnerProvisionList: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/partner/provisions/other/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getMyProvisionList: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/partner/provisions/my`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getPartnerProvisionCategories: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/partner/provisions/categories`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		deletePartnerProvisionItem: async (id) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "DELETE",
					headers: headers,
					mode: "cors",
					cache: "default",
				};

				const url = apiUrl + `/api/partner/provisions/${id}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		addUpdatePartnerProvisionItem: async (
			Id = null,
			CategoryId,
			Name,
			Packaging,
			StarterPack
		) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const json = {
					Id: Id,
					CategoryId: CategoryId,
					Name: Name,
					Packaging: Packaging,
					StarterPack: StarterPack,
				};

				Object.entries(json).forEach(([key, value]) => {
					if (value === null) {
						delete json[key];
					}
				});

				const myInit = {
					method: Id === null ? "POST" : "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				const url = apiUrl + `/api/partner/provisions`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateProvisionListFlag: async (id, value) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const lastUpdate = localStorage.getItem("ProvisionLastUpdate")
					? JSON.parse(localStorage.getItem("ProvisionLastUpdate"))
					: null;
				var LastUpdate = null;
				if (lastUpdate !== null) {
					LastUpdate = parseInt(lastUpdate);
				}

				const json = {
					IsCompleted: value,
					LastUpdate: LastUpdate,
				};

				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				const url = apiUrl + `/api/trips/business/booked/${id}/provisionlist`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateClientCrewListFlag: async (id, value) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const lastUpdate = localStorage.getItem("CrewClientLastUpdate")
					? JSON.parse(localStorage.getItem("CrewClientLastUpdate"))
					: null;
				var LastUpdate = null;
				if (lastUpdate !== null) {
					LastUpdate = parseInt(lastUpdate);
				}

				const json = {
					IsCompleted: value,
					LastUpdate: LastUpdate,
				};

				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				const url = apiUrl + `/api/trips/business/booked/${id}/crewlist/client`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		updateCharterCrewListFlag: async (id, value) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");

				const lastUpdate = localStorage.getItem("CrewCharterLastUpdate")
					? JSON.parse(localStorage.getItem("CrewCharterLastUpdate"))
					: null;
				var LastUpdate = null;
				if (lastUpdate !== null) {
					LastUpdate = parseInt(lastUpdate);
				}

				const json = {
					IsCompleted: value,
					LastUpdate: LastUpdate,
				};

				const myInit = {
					method: "PUT",
					headers: headers,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(json),
				};

				const url =
					apiUrl + `/api/trips/business/booked/${id}/crewlist/charter`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		getOtherBaseStations: async (UserId) => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/basestations/other/${UserId}`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getFilterStates: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/misc/tripbusinesstates`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		getTripsFilters: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/trips/business/query/filters`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		/**
		 *
		 * @returns {Promise<string[]>}
		 */
		getTemplateTripFilters: async () => {
			try {
				let headers = createHeaders();
				headers.append("Content-Type", "application/json");
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const url = apiUrl + `/api/trips/business/fromtemplate/types`;
				let myRequest = new Request(url);

				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data?.length ? data : [];
			} catch (error) {
				return [];
			}
		},

		getFilteredTrips: async (
			state,
			startingFrom,
			startingTo,
			baseStationId,
			vesselId,
			charterId,
			subType,
			page,
			countPerPage = 5
		) => {
			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				var url = apiUrl + "/api/trips/business/my/filter";
				if (charterId !== null && charterId !== "") {
					url = url + `?q.query.charterId=${charterId}`;
				} else {
					url += `?f`;

					if (state && !isEmpty(state)) {
						url += `&q.query.state=${state}`;
					}
					if (subType !== null && subType !== "") {
						url = url + `&q.query.subType=${subType}`;
					}
					if (baseStationId !== null) {
						url = url + `&q.query.baseStationId=${baseStationId}`;
					}
					if (vesselId !== null) {
						url = url + `&q.query.vesselId=${vesselId}`;
					}
					if (state !== "live" && state !== "template") {
						if (startingFrom !== null && startingTo !== null) {
							url =
								url +
								`&q.query.startingFrom=${startingFrom}&q.query.startingTo=${startingTo}`;
						}
					}
				}
				url = url + `&q.page=${page}&q.countPerPage=${countPerPage}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (error) {
				return null;
			}
		},

		/**
		 * Get a trip from passed GUID
		 *
		 * @param {string} guid Trip GUID
		 * @param {boolean} isBusiness Trip type, is a business trip or not
		 * @returns
		 */
		getTripFromGUID: async function (guid, isBusiness = true) {
			if (guid === null) {
				return null;
			}

			try {
				let headers = createHeaders();
				const myInit = {
					method: "GET",
					headers: headers,
					mode: "cors",
					cache: "default",
				};
				const TRIP_TYPE = isBusiness ? "business" : "community";
				const url = apiUrl + `/api/trips/${TRIP_TYPE}/${guid}`;
				let myRequest = new Request(url);
				const response = await fetch(myRequest, myInit);
				const data = await response.json();
				return data;
			} catch (ex) {
				return null;
			}
		},

		/**
		 * @param {string} placeId
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * }[] | null>}
		 */
		searchMyLists: async function (placeId) {
			try {
				const HEADERS = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/search?placeId=${placeId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->searchMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * }[] | null>}
		 */
		getMyLists: async function () {
			try {
				const HEADERS = createHeaders();
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
				};
				const URL = apiUrl + `/api/lists/my`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->searchMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {string} Name
		 * @param {boolean} IsPublic
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * } | null>}
		 */
		addToMyList: async function (Name, IsPublic) {
			try {
				if (isEmpty(Name) || isEmpty(IsPublic)) {
					return null;
				}

				const HEADER = createHeaders();
				const BODY = { Name: Name, IsPublic: IsPublic };
				const MY_INIT = {
					method: "POST",
					headers: HEADER,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->addToMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {number} Id
		 * @param {string} Name
		 * @param {boolean} IsPublic
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * } | null>}
		 */
		updateMyList: async function (Id, Name, IsPublic) {
			try {
				if (isEmpty(Name) || isEmpty(IsPublic)) {
					return null;
				}

				const HEADER = createHeaders();
				const BODY = { Id: Id, Name: Name, IsPublic: IsPublic };
				const MY_INIT = {
					method: "PUT",
					headers: HEADER,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->updateMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {number} userId
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * }[] | null>}
		 */
		getOtherLists: async function (userId) {
			try {
				const HEADERS = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/other?userId=${userId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->getOtherList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {string} listguid
		 * @returns {Promise<{
		 *  Id: number,
		 *  UserId: number,
		 *  Name: string,
		 *  ShareGUID: string,
		 *  IsPublic: boolean,
		 *  Count: number,
		 *  IsDefault: boolean
		 * } | null>}
		 */
		addFromOtherList: async function (listguid) {
			try {
				if (isEmpty(listguid)) {
					return null;
				}

				const HEADER = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "POST",
					headers: HEADER,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/${listguid}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->addFromOtherList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {string} listId
		 * @returns {Promise<boolean>}
		 */
		deleteMyList: async function (listId) {
			try {
				if (isEmpty(listId)) {
					return false;
				}

				const HEADER = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "DELETE",
					headers: HEADER,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/${listId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA || true;
			} catch (err) {
				console.warn("🚧 ApiClient->deleteMyList->catch:", err);
				return false;
			}
		},

		/**
		 * @param {number} listId
		 * @returns {Promise<{
		 * Items: [{
		 * 	 Place: {
		 *    ID: number;
		 *    Type: string;
		 *    Name: string;
		 *    CoverImage: string;
		 *    Location: number[];
		 * 	 };
		 * 	 Note: string;
		 *  }];
		 *	Id: number;
		 *	UserId: number;
		 *	Name: string;
		 *	ShareGUID: string;
		 *	IsPublic: boolean;
		 *	Count: number;
		 *	IsDefault: boolean;
		 * } | null>}
		 */
		getMyList: async function (listId) {
			try {
				if (isEmpty(listId)) {
					return null;
				}

				const HEADERS = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/${listId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->getMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {number} listId
		 * @returns {Promise<{
		 * Items: [{
		 * 	 Place: {
		 *    ID: number;
		 *    Type: string;
		 *    Name: string;
		 *    CoverImage: string;
		 *    Location: number[];
		 * 	 };
		 * 	 Note: string;
		 *  }];
		 *	Id: number;
		 *	UserId: number;
		 *	Name: string;
		 *	ShareGUID: string;
		 *	IsPublic: boolean;
		 *	Count: number;
		 *	IsDefault: boolean;
		 * } | null>}
		 */
		getOtherList: async function (listId) {
			try {
				if (isEmpty(listId)) {
					return null;
				}

				const HEADERS = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/other/${listId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->getOtherList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {string} listguid
		 * @returns {Promise<{
		 * Items: [{
		 * 	 Place: {
		 *    ID: number;
		 *    Type: string;
		 *    Name: string;
		 *    CoverImage: string;
		 *    Location: number[];
		 * 	 };
		 * 	 Note: string;
		 *  }];
		 *	Id: number;
		 *	UserId: number;
		 *	Name: string;
		 *	ShareGUID: string;
		 *	IsPublic: boolean;
		 *	Count: number;
		 *	IsDefault: boolean;
		 * } | null>}
		 */
		getOtherListFromGuid: async function (listguid) {
			try {
				if (isEmpty(listguid)) {
					return null;
				}

				const HEADERS = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "GET",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/other/${listguid}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->getOtherListFromGuid->catch:", err);
				return null;
			}
		},

		/**
		 * @param {number} listId
		 * @param {number} PlaceId
		 * @param {string} Note
		 * @returns {Promise<{
		 *	PlaceId: number;
		 *	Note: string;
		 * } | null>}
		 */
		addPlaceToMyList: async function (listId, PlaceId, Note = "") {
			try {
				if (isEmpty(listId) || isEmpty(PlaceId)) {
					return null;
				}

				const HEADERS = createHeaders();
				const BODY = {
					PlaceId: PlaceId,
					Note: Note,
				};
				const MY_INIT = {
					method: "POST",
					headers: HEADERS,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/${listId}/places`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->addPlaceToMyList->catch:", err);
				return null;
			}
		},

		/**
		 * @param {string} listId
		 * @param {string} placeId
		 * @returns {Promise<boolean>}
		 */
		deleteMyListPlace: async function (listId, placeId) {
			try {
				if (isEmpty(listId) || isEmpty(placeId)) {
					return false;
				}

				const HEADER = createHeaders();
				const BODY = {};
				const MY_INIT = {
					method: "DELETE",
					headers: HEADER,
					mode: "cors",
					cache: "default",
					body: JSON.stringify(BODY),
				};
				const URL = apiUrl + `/api/lists/my/${listId}/places/${placeId}`;
				const MY_REQUEST = new Request(URL);
				const RESPONSE = await fetch(MY_REQUEST, MY_INIT);
				const DATA = await RESPONSE.json();

				return DATA;
			} catch (err) {
				console.warn("🚧 ApiClient->deleteMyListPlace->catch:", err);
				return false;
			}
		},
	};
})();

export { ApiClient };

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";

// SELECTORS
import { languages } from "../../store/features/translation/Languages";
import { currentLang } from "../../store/features/translation/selectors";
import { setTripItinerary } from "../../store/features/map/actions";
import { currentTripItinerary } from "../../store/features/map/selectors";
import { userData } from "../../store/features/user/selectors";

// HELPERS
import { ApiClient } from "../../ApiClient";
import { logEvent_ } from "../../helpers/analytics";

// CONSTANTS
import DEFAULT_TOAST_PROPS from "../../constants/tostifyDefaultProps";

// COMPONENTS
import First from "./Steps/First";
import Second from "./Steps/Second";
import Third from "./Steps/Third";
import Fourth from "./Steps/Fourth";
import Fith from "./Steps/Fith";
import Sixth from "./Steps/Sixth";
import Seventh from "./Steps/Seventh";

// STYLES
import { TripBox } from "./styled";

// INTERNAL FUNCTION HELPERS
export const formatDate = (date, trip) => {
	// DATA
	const months = [
		"january",
		"february",
		"march",
		"april",
		"may",
		"jun",
		"july",
		"august",
		"september",
		"october",
		"november",
		"december",
	];
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + parseInt(d.getDate()),
		year = d.getFullYear();

	if (month.length < 2 && trip !== "trip") month = "0" + month;
	if (day.length < 2 && trip !== "trip") day = "0" + day;
	if (trip === "trip")
		return [
			`${day}${
				day === "1"
					? "st"
					: day === "2"
					? "nd"
					: day === "3" || day === "23"
					? "rd"
					: "th"
			}`,
			months[month - 1],
		].join(" ");
	return [year, month, day].join("-");
};

export const formatDate_ = (date) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	var d = new Date(date),
		month = d.getMonth(),
		day = d.getDay(),
		date = parseInt(d.getDate());

	return [`${days[day]},`, date, months[month]].join(" ");
};

const TripWizard = (props) => {
	// DATA
	const initialState = {
		step: 1,
		Type: props.typeWizardBusiness,
		Name: "",
		IsPublic: true,
		Cover: "",
		Description: "",
		StartDate: "",
		DurationDays: 0,
		VesselType: "",
		VesselLengthMeters: 10,
		VesselSpeedKnots: 10,
		Attributes: [],
		VesselId: null,
		nbAdults: null,
		nbKids: null,
	};

	// STATES
	const [state, setState] = useState(initialState);
	const [stateProps, setStateProps] = useState(null);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [finished, setFinished] = useState(false);
	const currentLanguage = useSelector(currentLang);
	const tripItinerary = useSelector(currentTripItinerary);
	const currentUserData = useSelector(userData);

	const { visible } = props;

	// FUNCTIONS
	const reset = () => {
		setState(initialState);
		setFinished(true);
		setStateProps(null);
		setNotice({ type: "", content: "" });
	};

	const formatISO8601Date = (time) => {
		if (time?.split) {
			const [hours, minutes] = time?.split(":");
			return `PT${hours}H${minutes}M`;
		}
		return `PT0H0M`;
	};

	const handleTemplateTrip = (data) => {
		const durationDays = state.DurationDays;

		const attributeDirection = state.Attributes.find((e) =>
			e.includes("direction")
		);
		const attributeCrew = state.Attributes.find((e) => e.includes("crew"));
		const attributeStyle = state.Attributes.find((e) => e.includes("style"));

		ApiClient.createUpdateBusinessTemplate(
			props.dataTrip?.Id,
			data.tripName,
			data.public,
			data.url ? data.url : null,
			data.description,
			durationDays,
			state.PlaceId,
			state.VesselId,
			attributeDirection,
			attributeCrew,
			attributeStyle
		)
			.then((res) => {
				setFinished(false);
				if (!res.ErrorCode && !res.ErrorMessage) {
					(async () => {
						if (props.dataTrip?.Id) {
							// UPDATE
							if (!res.ErrorCode && !res.ErrorMessage) {
								toast.success("Trip updated successfully", DEFAULT_TOAST_PROPS);
								setFinished(true);
								setStateProps(null);
								setTimeout(() => {
									reset();
									props.onClose();
									props.displayOverview(props.dataTrip?.Id);
								}, 500);
							} else {
								toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
							}
							return;
						}

						// FIREBASE EVENT
						logEvent_("react_tripwiz_create_success", {
							tripId: props.dataTrip?.Id,
						});
						toast.success(
							languages[currentLanguage]["trip_description_header"],
							DEFAULT_TOAST_PROPS
						);
						setFinished(true);
						setStateProps(null);
						setTimeout(() => {
							reset();
							if (props.displayOverview) {
								props.onClose();
								props.displayOverview(res.Id);
							}
						}, 500);
					})();
				} else {
					toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
					return;
				}
			})
			.catch((error) => {
				toast.error(error.ErrorMessage, DEFAULT_TOAST_PROPS);
			});
	};

	const handleCloneTemplateTrip = (data) => {
		const attributeDirection = state.Attributes.find((e) =>
			e.includes("direction")
		);
		const attributeCrew = state.Attributes.find((e) => e.includes("crew"));
		const attributeStyle = state.Attributes.find((e) => e.includes("style"));
		let sDate = state.StartDate !== null ? formatDate(state.StartDate) : null;

		ApiClient.cloneTemplateToBusiness(
			props.dataTrip?.Id,
			data.tripName,
			data.CharterId,
			data.url ? data.url : null,
			data.description,
			sDate,
			state.DurationDays,
			state.VesselId,
			state.nbAdults,
			state.nbKids,
			attributeDirection,
			attributeCrew,
			attributeStyle,
			state.Preference,
			formatISO8601Date(state.ArrivalTime),
			data.IsCrewed,
			data.EmailInvtations,
			data.HasCharterListsEnabled
		)
			.then((res) => {
				setFinished(false);
				if (!res.ErrorCode && !res.ErrorMessage) {
					(async () => {
						const TripId = res.Id;

						if (data.needTransfer !== null && data.needTransfer === true) {
							const PickUpDate = data.transferDate;
							const PickUpPlace = data.transferWhere;
							const destinationPlace = data.transferTo;
							const NumberOfAdults = data.transferPassengers;
							const Contact = data.transferPhone;
							await ApiClient.createTransfer(
								TripId,
								PickUpDate,
								PickUpPlace,
								destinationPlace,
								NumberOfAdults,
								Contact
							);
						}

						toast.success(
							"Business Trip successfully created",
							DEFAULT_TOAST_PROPS
						);
						setFinished(true);
						setStateProps(null);
						setTimeout(() => {
							reset();
							if (props.displayOverview) {
								props.onClose();
								props.displayOverview(TripId);
							}
						}, 500);
					})();
				} else {
					toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
					return;
				}
			})
			.catch((error) => {
				toast.error(error.ErrorMessage, DEFAULT_TOAST_PROPS);
			});
	};

	const handleUpdateBusinessTripPartner = (data) => {
		const attributeDirection = state.Attributes.find((e) =>
			e.includes("direction")
		);
		const attributeCrew = state.Attributes.find((e) => e.includes("crew"));
		const attributeStyle = state.Attributes.find((e) => e.includes("style"));
		let sDate = state.StartDate !== null ? formatDate(state.StartDate) : null;

		ApiClient.updateBusinessTripPartner(
			props.dataTrip?.Id,
			data.tripName,
			data.CharterId,
			data.url ? data.url : null,
			data.description,
			sDate,
			state.DurationDays,
			state.VesselId,
			state.nbAdults,
			state.nbKids,
			attributeDirection,
			attributeCrew,
			attributeStyle,
			state.Preference,
			formatISO8601Date(state.ArrivalTime),
			data.IsCrewed,
			data.HasCharterListsEnabled
		)
			.then((res) => {
				setFinished(false);
				if (!res.ErrorCode && !res.ErrorMessage) {
					(async () => {
						toast.success(
							"Business Trip Updated successfully",
							DEFAULT_TOAST_PROPS
						);
						setFinished(true);
						setStateProps(null);
						setTimeout(() => {
							reset();
							props.onClose();
							if (props.dataTrip?.Id && props.displayOverview) {
								props.displayOverview(props.dataTrip?.Id);
							}
						}, 500);
					})();
				} else {
					toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
				}
			})
			.catch((error) => {
				toast.error(error.ErrorMessage, DEFAULT_TOAST_PROPS);
			});
	};

	const handleUpdateBusinessTripPassenger = (data) => {
		const attributes = data.Attributes;
		const preference = state.Preference ?? data.Preference;
		const attributeCrew = attributes.find((e) => e.includes("crew"));
		const attributeStyle = attributes.find((e) => e.includes("style"));

		console.log(!(data.ArrivalTime?.split(":").length >= 2));

		if (
			!data.ArrivalTime ||
			!data.ArrivalTime?.split ||
			!(data.ArrivalTime?.split(":").length >= 2)
		) {
			return toast.warning("Please set the arrival time", DEFAULT_TOAST_PROPS);
		}

		ApiClient.updateBusinessTripPassenger(
			props.dataTrip?.Id,
			state.nbAdults,
			state.nbKids,
			attributeCrew,
			attributeStyle,
			preference,
			formatISO8601Date(data.ArrivalTime)
		)
			.then((res) => {
				setFinished(false);
				if (!res.ErrorCode && !res.ErrorMessage) {
					(async () => {
						toast.success(
							"Business Trip Updated successfully",
							DEFAULT_TOAST_PROPS
						);
						setFinished(true);
						setStateProps(null);
						setTimeout(() => {
							reset();
							props.onClose();
							if (props.dataTrip?.Id && props.displayOverview) {
								props.displayOverview(props.dataTrip?.Id);
							}
						}, 500);
					})();
				} else {
					toast.error(res.ErrorMessage, DEFAULT_TOAST_PROPS);
					setFinished(true);
					setStateProps(null);
					setTimeout(() => {
						reset();
						props.onClose();
						if (props.dataTrip?.Id && props.displayOverview) {
							props.displayOverview(props.dataTrip?.Id);
						}
					}, 500);
				}
			})
			.catch((error) => {
				toast.error(error.ErrorMessage, DEFAULT_TOAST_PROPS);
			});
	};

	const handleSubmitTrip = (data) => {
		if (props.dataTrip) {
			if (props.dataTrip.isClone) {
				// CLONING
				handleCloneTemplateTrip(data);
				return;
			}
			if (props.dataTrip.SubType === "template") {
				handleTemplateTrip(data);
				return;
			}
			// UPDATE BUSINESS TRIP PASSENGER OR PARTNER
			const currentUserRole = stateProps?.currentUserRole;
			if (currentUserRole === "creator" || currentUserRole === "cocreator") {
				handleUpdateBusinessTripPartner(data);
			} else if (currentUserRole === "superpassenger") {
				handleUpdateBusinessTripPassenger(data);
			}
		} else {
			handleTemplateTrip(data);
		}
	};

	const handleBack = (data) => {
		switch (data.step) {
			case 1:
				setState({
					...state,
					step: 0,
				});
				props.onCancel();
				logEvent_("react_tripwiz_stepinfo_cancelbtn_pressed");
				break;
			case 2:
				setState({
					...state,
					step: 1,
				});
				break;
			case 3:
				if (stateProps?.isPassenger) {
					setState({
						...state,
						step: 0,
					});
					props.onCancel();
					logEvent_("react_tripwiz_stepinfo_cancelbtn_pressed");
					return;
				}
				setState({
					...state,
					step: 2,
				});
				break;
			case 4:
				setState({
					...state,
					step: 3,
				});
				break;
			case 5:
				setState({
					...state,
					step: 4,
				});
				break;
			case 6:
				const toStep = stateProps?.isPassenger ? 3 : 5;
				setState({
					...state,
					step: toStep,
				});
				break;
			default:
				break;
		}
	};

	const handleNext = (data) => {
		let newTags = [];
		switch (data.step) {
			case 1:
				if (data.VesselId || data.VesselId === 0)
					setState({
						...state,
						VesselId: data.VesselId,
						VesselType: data.type,
						VesselSpeedKnots: data.speed,
						VesselLengthMeters: data.length,
						step: 2,
					});
				else toast.warning("Please, select a vessel", DEFAULT_TOAST_PROPS);
				break;
			case 2:
				if (data.numberOfDays === null || data.numberOfDays === 0) {
					toast.warning("Please, set a duration", DEFAULT_TOAST_PROPS);
					return;
				}
				setState({
					...state,
					StartDate: data.startDate,
					DurationDays: data.numberOfDays,
					step: 3,
				});
				break;
			case 3:
				newTags = data.tags.concat(state.Attributes);
				const toStep = stateProps?.isPassenger ? 6 : 4;
				setState({
					...state,
					Attributes: newTags,
					step: toStep,
					nbAdults: data.nbAdults,
					nbKids: data.nbKids,
				});
				break;
			case 4:
				newTags = data.tags.concat(state.Attributes);
				setState({
					...state,
					step: 5,
					Attributes: newTags,
				});
				break;
			case 5:
				if (data.placeId === null || data.placeId === 0) {
					toast.warning("Please, select a base station", DEFAULT_TOAST_PROPS);
				} else {
					setState({
						...state,
						step: 6,
						PlaceId: data.placeId,
					});
				}
				break;
			case 6:
				newTags = data.tags.concat(state.Attributes);
				if (stateProps?.isPassenger) {
					setState({
						...state,
						Attributes: newTags,
						Preference: data.preference,
						ArrivalTime: data.arrivalTime,
					});
					handleSubmitTrip({
						Attributes: newTags,
						Preference: data.preference,
						ArrivalTime: data.arrivalTime,
					});
					return;
				}

				setState({
					...state,
					step: 7,
					Attributes: newTags,
					Preference: data.preference,
					ArrivalTime: data.arrivalTime,
				});
				break;
			default:
				break;
		}
	};

	// EFFECTS
	useEffect(() => {
		if (props.dataTrip !== null) {
			if (props.dataTrip.Type === "community") {
				return;
			}
			const user = props.dataTrip?.Members?.find(
				(u) => u.UserId === currentUserData?.ID
			);
			const creatorUserId = props.dataTrip?.Members?.find(
				(u) => u.Role === "creator"
			).UserId;
			let data = props.dataTrip;
			const isPassenger = user?.Role === "superpassenger";

			const Vessel = data.Vessel;
			const vesselType = Vessel.Type.replaceAll("business", "").replaceAll(
				"community",
				""
			);
			const isClone = data.isClone ?? false;
			const Place = data.Place;
			console.log("place ===>", Place);
			setStateProps({
				step: 1,
				IsPublic: data.IsPublic,
				Cover: data.Cover,
				Description: data.Description,
				StartDate: data.StartDate,
				DurationDays: data.DurationDays,
				Name: data.Name,
				CharterId: data.CharterId,
				State: isClone ? "clone" : data.SubType ?? data.State,
				Type: data.Type,
				VesselId: Vessel.Id ?? Vessel.ID,
				VesselType: vesselType,
				VesselSpeedKnots: Vessel.SpeedKnots?.toFixed(0),
				VesselLengthMeters: Vessel.LengthMeters,
				DurationDays: data.DurationDays,
				Attributes: props.dataTrip.Attributes,
				PlaceId: Place.Id ?? Place?.ID,
				isClone: isClone,
				NumberOfAdults: data.NumberOfAdults,
				NumberOfKids: data.NumberOfKids,
				Preferences: data.Preferences,
				currentUserRole: user?.Role,
				isPassenger: isPassenger,
				creatorUserId: creatorUserId,
				ArrivalTime: data.ArrivalTime,
				IsCrewed: data.IsCrewed,
				HasCharterListsEnabled: data.HasCharterListsEnabled ?? true,
			});

			if (isPassenger) {
				setState({
					...state,
					step: 3,
				});
			}
		} else {
		}
	}, [props.dataTrip]);
	return (
		<>
			<TripBox
				className={
					!visible ? "d-none" : "d-flex flex-column align-items-center"
				}
			>
				<div
					className="position-fixed bg-white"
					style={{
						minHeight: 50,
						top: 0,
						width: "calc(100% - 20px)",
						zIndex: 99,
					}}
				>
					<div className="position-relative w-100">
						<div
							className="position-absolute"
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								height: 10,
								width: `${(state.step / 7) * 100}%`,
								transition: "all .3s linear",
								backgroundColor: "#00ff00",
							}}
						></div>

						<IconButton
							onClick={() => {
								logEvent_("react_tripwiz_stepinfo_cancelbtn_pressed");
								reset();
								props.onClose();

								if (props.dataTrip?.Id && props.displayOverview) {
									props.displayOverview(props.dataTrip?.Id);
								}
							}}
							component="span"
							className="position-absolute d-flex justify-content-center align-items-center text-white p-0"
							style={{
								zIndex: 2,
								top: 20,
								right: 5,
								height: 30,
								width: 30,
								fontSize: 12,
								backgroundColor: "rgba(0,0,0,0.4)",
							}}
						>
							<i className="fas fa-times fa-fw" />
						</IconButton>
					</div>
				</div>

				<First
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 1}
					wizardV={visible}
					finished={finished}
				/>
				<Second
					type={state.Type}
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 2}
					finished={finished}
				/>
				<Third
					type={state.Type}
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 3}
					finished={finished}
				/>
				<Fourth
					type={state.Type}
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 4}
					finished={finished}
				/>
				<Fith
					type={state.Type}
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 5}
					finished={finished}
					wizardV={visible}
				/>
				<Sixth
					type={state.Type}
					state={stateProps}
					handleBack={handleBack}
					handleNext={handleNext}
					visible={state.step === 6}
					finished={finished}
				/>
				<Seventh
					type={state.Type}
					state={stateProps}
					handleSubmit={handleSubmitTrip}
					visible={state.step === 7}
					finished={finished}
				/>
				{notice.type !== "" && (
					<>
						<Alert
							onClose={() => {
								setNotice({ type: "", content: "" });
							}}
							variant="filled"
							severity={notice.type.toLowerCase()}
							style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
						>
							{notice.content}
						</Alert>
					</>
				)}
			</TripBox>
		</>
	);
};

TripWizard.propTypes = {
	visible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTripItinerary: (data = {}, combine = true) =>
			dispatch(setTripItinerary(data, combine)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripWizard);

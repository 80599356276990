import React, { Component } from "react";
import { Provider } from "react-redux";
import { useClearCacheCtx } from "react-clear-cache";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MainRoute from "./router/";
import Store from "./store/";
import withClearCache from "./helpers/ClearCache";
import { Helmet } from "react-helmet";

// STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./custom.css";
import "./components/map/Map.css";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	const displayName = App.name;
	const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

	return (
		<Provider store={Store}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>keeano - The Social Coastline Guide</title>
					<meta
						name="description"
						content="Join keeano and change the way you dream, explore and plan your routes around the Mediterranean coast."
					/>
					<link rel="canonical" href="http://keeano.com" />
				</Helmet>
				<MainRoute />
			</MuiPickersUtilsProvider>
		</Provider>
	);
}

export default App;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { UserProvider } from "./userContext";

// COMPONENTS
import App from "./App";

// STYLES
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-toastify/dist/ReactToastify.css";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

if (
	window.location.hostname !== "keeano.com" &&
	process.env.REACT_APP_ENVIRONMENT === "production" &&
	window.location.hostname !== "localhost"
) {
	window.location.replace("https://keeano.com");
} else {
	ReactDOM.render(
		<BrowserRouter basename={baseUrl}>
			<UserProvider>
				<App />
			</UserProvider>
		</BrowserRouter>,
		rootElement
	);

	registerServiceWorker();
}

import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Snackbar,
	Tooltip,
} from "@material-ui/core";
import branch from "branch-sdk";
import { Alert, AvatarGroup } from "@material-ui/lab";

// CONFIGS
import {
	generateTripShareLink,
	generateTripInviteLink,
} from "../../shareConfig";

// CONSTANTS
import TRIP_USER_ROLES from "../../constants/tripUserRoles";
import TRIP_VIRTAUL_GUIDE_STATES from "../../constants/tripVirtaulGuideStates";

// API
import { ApiClient } from "../../ApiClient";
import { AuthService } from "../../AuthService";

// HELPERS
import { logEvent_ } from "../../helpers/analytics";
import { isEmpty, isInDev } from "../../helpers/functions";

// ACTIONS
import {
	setTripItineraryVisibility,
	setTripItinerary,
	retrieveTripDaysList,
} from "../../store/features/map/actions";
import {
	setUserProfileId,
	showUserProfile,
} from "../../store/features/user/actions";

// SELECTORS
import { userData } from "../../store/features/user/selectors";
import { languages } from "../../store/features/translation/Languages";
import { currentLang } from "../../store/features/translation/selectors";
import { currentTripItinerary } from "../../store/features/map/selectors";

// COMPONENTS
import Sidebar from "../utils/SideBar";
import { formatDate_ } from "../TripWizard";
import VirtualGuide from "./components/VirtualGuide";

// SVG ICONS
import CloseIcon from "../../assets/close-btn.svg";
import PlusIcon from "../../assets/svg/plus.svg";
import ArrowIcon from "../../assets/back_ic.svg";
import VesselAvailIcon from "../../assets/available-vessels.png";
import CopyIcon from "../../assets/copy-ic.svg";
import ShareIcon from "../../assets/share-ic-blue.svg";
import EditIcon from "../../assets/edit-active-ic.svg";

// STYLES
import {
	filterHighlightColor,
	formMainTxt,
	whiteColor,
} from "../../constants/colors";
import tripUserRoles from "../../constants/tripUserRoles";

const TripOverview = ({
	show = false,
	id,
	guid,
	type,
	showUserProfile = () => {},
	setTripItinerary = () => {},
	retrieveTripDaysList = () => {},
	showAuth = () => {},
	openChat = () => {},
	setUserProfileId = () => {},
	handleEdit = () => {},
	handleCrew = () => {},
	handleCrewList = () => {},
	handleProvisionList = () => {},
	handleTransferList = () => {},
	onOpen = () => {},
	onClose = () => {},
	cloneToBusiness = () => {},
	handleTripOverviewSidebars = () => {},
	setCustomDialogProps = () => {},
}) => {
	// SELECTORS
	const TRIP_ITINERARY = useSelector(currentTripItinerary);
	const CURRENT_LANGUAGE = useSelector(currentLang);
	const CURRENT_USER_DATA = useSelector(userData);

	// STATES
	const [loading, setLoading] = React.useState(true);
	const [trip, setTrip] = React.useState({});
	const [details, setDetails] = React.useState([]);
	const [businessDetails, setBusinessDetails] = React.useState([]);
	const [shareDialog, setShareDialog] = React.useState(false);
	const [Tags, setTags] = useState([]);
	const [inviteDialog, setInviteDialog] = React.useState(false);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [generatedShareLink, setGeneratedShareLink] = React.useState(null);
	const [generatedInviteLink, setGeneratedInviteLink] = React.useState(null);
	const [snackbarClipboard, setSnackbarClipboard] = React.useState(false);
	const [role, setRole] = React.useState("");
	const [tripCreator, setTripCreator] = React.useState({});
	const [allowedInvites, setAllowedInvites] = React.useState([]);
	const [settingsVirtualGuide, setSettingsVirtualGuide] = useState(null);
	const [isCrewDeadlineReached, setIsCrewDeadlineReached] =
		React.useState(false);
	const [isProvisionDeadlineReached, setIsProvisionDeadlineReached] =
		React.useState(false);
	const [isTransferDeadlineReached, setIsTransferDeadlineReached] =
		React.useState(false);
	const [vesselUrl, setVesselUrl] = React.useState(null);
	const [showVirtualGuideNotification, setShowVirtualGuideNotification] =
		React.useState(true);
	const [currentVirtualGuide, setCurrentVirtualGuide] = React.useState(null);

	// FUNCTION
	const shareTrip = () => {
		logEvent_("react_trip_sharebutton_pressed", { tripId: trip?.Id });

		const linkData = generateTripShareLink(
			trip.Id,
			trip.ShareGUID,
			trip.Cover ? trip.Cover : "",
			trip?.Name,
			trip?.Description
		);
		branch.link(linkData, function (err, link) {
			setGeneratedShareLink(link);
			setShareDialog(true);
		});
	};

	const onShowUserProfile = (userId = null) => {
		handleTripOverviewSidebars();
		setUserProfileId(userId);
		showUserProfile();
	};

	const handleInvite = () => {
		logEvent_("react_trip_addcrewmemberbutton_pressed", { tripId: trip?.Id });

		setInviteDialog(true);
	};

	const onSelectInvitation = (Role, GUID) => {
		if (!GUID) return;
		const linkData = generateTripInviteLink(
			trip.Id,
			GUID,
			trip.Cover ? trip.Cover : "",
			trip?.Name,
			trip?.Description,
			Role,
			!!trip?.HasCharterListsEnabled
		);

		branch.link(linkData, function (err, link) {
			setGeneratedInviteLink(link);
		});
	};

	const copyToClipboard = (str = "") => {
		const el = window.document.createElement("textarea");
		el.value = str;
		window.document.body.appendChild(el);
		el.select();
		window.document.execCommand("copy");
		window.document.body.removeChild(el);
		navigator.clipboard.writeText(str);
		setSnackbarClipboard(true);
	};

	const handleDuplicate = () => {
		if (trip.Type === "business") {
			// for now
			return;
		}
		ApiClient.duplicateTrip(id)
			.then((res) => {
				if (res && !res?.ErrorCode && !res?.ErrorMessage) {
					setNotice({
						type: "SUCCESS",
						content: "Trip duplicated successfully",
					});
					setTimeout(() => {
						onClose(tripCreator.Type === "partner");
					}, 1000);
				} else {
					setNotice({
						type: "ERROR",
						content: res.ErrorMessage,
					});
				}
			})
			.catch((error) => {
				if (isInDev()) console.log("ERROR =>>", error);
			});
	};

	const handleChat = () => {
		logEvent_("react_trip_chatwithcrewbutton_pressed", { tripId: trip?.Id });
		openChat();
	};

	const updateView = async (tr) => {
		const TEMP_TRIP_CREATOR = tr.Members.filter(
			(u) => u.Role === TRIP_USER_ROLES.CREATOR
		)[0];
		const TRIP_CREATOR =
			TEMP_TRIP_CREATOR.UserId === CURRENT_USER_DATA.ID
				? CURRENT_USER_DATA
				: TEMP_TRIP_CREATOR;
		const TRIP_USER = tr.Members.filter(
			(u) => u.UserId === CURRENT_USER_DATA?.ID
		)[0];
		const TRIP_VIRTUAL_GUIDE = tr.Members.filter(
			(u) => u.Role === TRIP_USER_ROLES.VIRTUAL_GUIDE
		)[0];

		if (isInDev()) console.log("TRIP_VIRTUAL_GUIDE ==>", TRIP_VIRTUAL_GUIDE);

		setCurrentVirtualGuide(TRIP_VIRTUAL_GUIDE);
		setTripCreator(TRIP_CREATOR);
		var daysBeforeCompletedCrew = 3;
		var daysBeforeCompletedProvision = 5;
		var daysBeforeCompletedTransfer = 5;
		var vUrl = null;
		let _tripVessel = tr?.Vessel;

		// if (tr?.SubType === "template") {
		// 	const TEMPLATE_TRIP_FILTER = await ApiClient?.getTemplateTripFilters();
		// 	console.log("TEMPLATE_TRIP_FILTER", TEMPLATE_TRIP_FILTER);

		// 	if (TEMPLATE_TRIP_FILTER?.length) {
		// 		setTemplateTypesFilter(TEMPLATE_TRIP_FILTER);
		// 		setCurrentTemplateTypesFilter(TEMPLATE_TRIP_FILTER[0]);
		// 	}
		// }

		// ApiClient.getAvailableVirtualGuideStates().then((res) => {
		// 	console.log("getAvailableVirtualGuideStates ==>", res);
		// });
		// ['enabled', 'disabled']

		if (TRIP_CREATOR.UserId !== TRIP_USER?.UserId) {
			await ApiClient.getOtherUser(TRIP_CREATOR.UserId).then((res) => {
				if (res.SettingsTripBusiness) {
					const settings = res.SettingsTripBusiness;
					daysBeforeCompletedCrew = settings.DaysBeforeCompletedCrew;
					daysBeforeCompletedProvision = settings.DaysBeforeCompletedProvision;
					daysBeforeCompletedTransfer = settings.DaysBeforeCompletedTransfer;
				}
				if (res.SettingsVesselsAvailability?.VesselsUrl) {
					vUrl = res.SettingsVesselsAvailability.VesselsUrl;
					setVesselUrl(res.SettingsVesselsAvailability.VesselsUrl);
				}
				setSettingsVirtualGuide(res?.SettingsVirtualGuide);
			});
		} else if (TRIP_CREATOR.UserId === TRIP_USER?.UserId) {
			if (CURRENT_USER_DATA?.SettingsTripBusiness) {
				const settings = CURRENT_USER_DATA.SettingsTripBusiness;
				daysBeforeCompletedCrew = settings.DaysBeforeCompletedCrew;
				daysBeforeCompletedProvision = settings.DaysBeforeCompletedProvision;
				daysBeforeCompletedTransfer = settings.DaysBeforeCompletedTransfer;
			}
			if (CURRENT_USER_DATA?.SettingsVesselsAvailability?.VesselsUrl) {
				vUrl = CURRENT_USER_DATA.SettingsVesselsAvailability.VesselsUrl;
				setVesselUrl(CURRENT_USER_DATA.SettingsVesselsAvailability.VesselsUrl);
			}

			setSettingsVirtualGuide(CURRENT_USER_DATA?.SettingsVirtualGuide);
		} else {
			setVesselUrl(vUrl);
		}

		var last = tr.Type === "business" ? null : "departure: -";
		if (tr.StartDate !== null) {
			last = `departure : ${formatDate_(tr.StartDate)}`;
		}
		const det = [`${tr.DurationDays} days`];
		if (tr.DistanceNM) {
			det.push(`${Math.round(tr.DistanceNM)} nm`);

			if (_tripVessel?.FuelConsumptionLiters && _tripVessel?.SpeedKnots) {
				det.push(
					`${Math.round(
						(parseInt(_tripVessel?.FuelConsumptionLiters) /
							parseInt(_tripVessel?.SpeedKnots)) *
							parseInt(tr.DistanceNM)
					)} lt`
				);
			}
		}
		if (tr.Stops) {
			det.push(`${tr.Stops} stops`);
		}
		const tags_ = tr.Attributes;
		if (tr.VesselType) {
			tags_.push(`overview_trip_attr_${tr.VesselType}trip`);
		}
		if (tr.Type === "business") {
			const val = tr.Vessel.Type.replaceAll("community").replaceAll(
				"business",
				""
			);
			tags_.push(`overview_trip_attr_${val}trip`);
		}

		setTrip(tr);
		setTags(tags_);
		setTripItinerary({
			...TRIP_ITINERARY,
			data: tr,
			canEdit:
				TRIP_USER?.Role === TRIP_USER_ROLES.CREATOR ||
				TRIP_USER?.Role === TRIP_USER_ROLES.SKIPPER ||
				TRIP_USER?.Role === TRIP_USER_ROLES.SUPERPASSENGER ||
				TRIP_USER?.Role === TRIP_USER_ROLES.PASSENGERSKIPPER ||
				TRIP_USER?.Role === TRIP_USER_ROLES.COCREATOR,
			visibility: false,
		});
		retrieveTripDaysList(tr?.Id);
		setRole(TRIP_USER?.Role ?? TRIP_USER_ROLES.NO_MEMBER);

		const bdet = [];
		if (tr.State) {
			det.push(tr.State);
		}

		if (tr.State) {
			if (tr.StartDate) {
				bdet.push(last);
			}
		} else {
			if (tr.StartDate) {
				det.push(last);
			}
		}

		if (tr.NumberOfAdults) {
			bdet.push("Adults: " + tr.NumberOfAdults);
		}

		if (tr.NumberOfKids) {
			bdet.push("Kids: " + tr.NumberOfKids);
		}

		if (tr.ArrivalTime) {
			var val = tr.ArrivalTime.substring(2);
			if (val.length === 6) {
				val = val.substring(0, val.length - 1);
			}
			bdet.push("ETA: " + val);
		}

		if (tr.StartDateTimeUTC) {
			var startDate = new Date(tr.StartDateTimeUTC);
			const now = new Date();
			const milliseconds = 86400000;
			const lastDayDealineCrew = new Date(
				startDate.getTime() - daysBeforeCompletedCrew * milliseconds
			);
			const lastDayDealineProvision = new Date(
				startDate.getTime() - daysBeforeCompletedProvision * milliseconds
			);
			const lastDayDealineTransfer = new Date(
				startDate.getTime() - daysBeforeCompletedTransfer * milliseconds
			);

			setIsCrewDeadlineReached(now >= lastDayDealineCrew);
			setIsProvisionDeadlineReached(now >= lastDayDealineProvision);
			setIsTransferDeadlineReached(now >= lastDayDealineTransfer);
		}

		setDetails(det);
		setBusinessDetails(bdet);

		let currentRole = TRIP_USER?.Role;
		let allInvites = tr.Invitations ?? [];
		var invites = [];
		if (
			currentRole === TRIP_USER_ROLES.CREATOR ||
			currentRole === TRIP_USER_ROLES.COCREATOR
		) {
			invites = allInvites;
		}
		if (currentRole === TRIP_USER_ROLES.SUPERPASSENGER) {
			invites = allInvites.filter(
				(u) =>
					u.Role === TRIP_USER_ROLES.PASSENGER ||
					u.Role === TRIP_USER_ROLES.PASSENGERSKIPPER
			);
		}
		if (
			currentRole === TRIP_USER_ROLES.PASSENGER ||
			currentRole === TRIP_USER_ROLES.PASSENGERSKIPPER
		) {
			invites = allInvites.filter((u) => u.Role === TRIP_USER_ROLES.PASSENGER);
		}
		setAllowedInvites(invites);
		setLoading(false);

		if (TRIP_CREATOR.UserName && tr.ShareGUID && tr.Name) {
			const HOST_NAME = window.location.host;
			const FORMATTED_CREATOR_NAME =
				typeof TRIP_CREATOR.UserName === "string"
					? TRIP_CREATOR.UserName.replace(
							new RegExp(/[ ]/, "g"),
							"-"
					  ).toLowerCase()
					: "";
			const FORMATTED_TRIP_NAME =
				typeof tr.Name === "string"
					? tr.Name.replace(new RegExp(/[ ]/, "g"), "-").toLowerCase()
					: "";
			const URL_TRIP_TYPE = tr?.Type === "business" ? "b" : "c";
			const TRIP_SHARE_GUID = tr.ShareGUID;

			const NEW_URL = `https://${HOST_NAME}/explore/user/${FORMATTED_CREATOR_NAME}/trips/${URL_TRIP_TYPE}/${FORMATTED_TRIP_NAME}/${TRIP_SHARE_GUID}`;

			window.history.pushState(null, null, NEW_URL);
		}
	};

	const onChangeVirtualGuidState = (newData) => {
		setTrip((_prevState) => ({
			..._prevState,
			VirtualGuide: newData,
		}));
	};

	// DATA
	const CAN_SEE_VG_NOTIFICATION =
		showVirtualGuideNotification &&
		trip?.VirtualGuide?.State === TRIP_VIRTAUL_GUIDE_STATES.DISABLED &&
		trip?.State === "onboarding" &&
		role === TRIP_USER_ROLES.SUPERPASSENGER;

	const CAN_SEE_VG =
		settingsVirtualGuide &&
		trip?.VirtualGuide &&
		trip.SubType === "booked" &&
		!(
			role === TRIP_USER_ROLES.SKIPPER ||
			role === TRIP_USER_ROLES.DECKMAN ||
			role === TRIP_USER_ROLES.BASEMANAGER ||
			role === TRIP_USER_ROLES.HOSTESS ||
			role === TRIP_USER_ROLES.CHEF
		);

	// COMPONENTS
	const OptionItem = ({
		label = "",
		onClick = () => {},
		className = "",
		style = {},
	}) => {
		return (
			<Button
				onClick={onClick}
				className={
					"d-flex text-left align-items-center justify-content-start w-100" +
					className
				}
				style={{
					height: 50,
					marginTop: 25,
					marginBottom: 0,
					textTransform: "capitalize",
					...style,
				}}
			>
				<h3
					className="m-0 p-0"
					style={{ color: formMainTxt, fontSize: 20, flex: 1 }}
				>
					{label}
				</h3>

				<img
					src={ArrowIcon}
					alt="chevron-right"
					style={{
						height: 14,
						width: 14,
						transform: "rotate(180deg)",
					}}
				/>
			</Button>
		);
	};

	const DetailItem = ({ label = "" }) => (
		<span
			className="mr-2 mb-2 p-2 dromon-l"
			style={{
				backgroundColor: formMainTxt,
				color: whiteColor,
				fontSize: 12,
				borderRadius: 5,
			}}
		>
			{label}
		</span>
	);

	// EFFECTS
	useEffect(() => {
		if (show) {
			logEvent_("react_app_trip_overview_screen", { tripId: trip?.Id });

			const handleViewUpdate = (trip) => {
				onOpen(trip);
				updateView(trip);
			};

			setLoading(true);
			if (id) {
				ApiClient.getTrip(id)
					.then((tr) => handleViewUpdate(tr))
					.finally(() => setLoading(false));
			} else if (guid) {
				ApiClient.getTripFromShare(guid, type)
					.then((tr) => handleViewUpdate(tr))
					.finally(() => setLoading(false));
			}
		}
	}, [id, show]);

	return (
		<Sidebar
			visible={show}
			customCloseBtn={
				<IconButton
					onClick={() => {
						onClose(tripCreator.Type === "partner");
					}}
					component="span"
					className="d-flex justify-content-center align-items-center"
					style={{ backgroundColor: "transparent", color: "#222c4b" }}
				>
					<img
						src={CloseIcon}
						alt="closeIcon"
						style={{ height: 32, width: 32 }}
					/>
				</IconButton>
			}
			isLoading={loading}
			sidebarProps={{ className: "pb-5" }}
			sidebarContentProps={{
				style: {
					overflow: CAN_SEE_VG_NOTIFICATION ? "visible" : "hidden auto",
					marginTop: CAN_SEE_VG_NOTIFICATION ? -300 : 0,
				},
			}}
		>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackbarClipboard}
				autoHideDuration={2000}
				onClose={() => setSnackbarClipboard(false)}
				message="Copied to clipboard"
			/>

			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}

			<Dialog open={shareDialog} onClose={() => setShareDialog(false)}>
				<DialogTitle>
					{languages[CURRENT_LANGUAGE]["place_details_share_button"]}
				</DialogTitle>

				<DialogContent>
					<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
						<a
							href={generatedShareLink}
							target="_blank"
							className="mr-2"
							rel="noreferrer"
						>
							<h3
								className="m-0 p-0"
								style={{
									color: { formMainTxt },
									fontSize: 14,
								}}
							>
								<i className="fas fa-fw fa-link mr-2" />
								{generatedShareLink}
							</h3>
						</a>

						<Tooltip title="Copy to clipboard" placement="top">
							<IconButton onClick={() => copyToClipboard(generatedShareLink)}>
								<img
									src={require(`../../assets/copy-ic.svg`)}
									style={{ width: 14 }}
								/>
							</IconButton>
						</Tooltip>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShareDialog(false)}>Dismiss</Button>
					<Button
						onClick={() => {
							setShareDialog(false);
							copyToClipboard(generatedShareLink);
						}}
						autoFocus
					>
						Copy and dismiss
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={inviteDialog}
				onClose={() => {
					setInviteDialog(false);
					setGeneratedInviteLink(null);
				}}
			>
				<DialogTitle>Invite</DialogTitle>

				<DialogContent style={{ minWidth: 380, maxWidth: 480 }}>
					{isEmpty(generatedInviteLink) ? (
						<DialogContentText>
							{!isEmpty(allowedInvites) &&
								allowedInvites.length &&
								allowedInvites.map((invitation, index) => (
									<Button
										key={index}
										className="w-100 text-left justify-content-start rounded-0 mb-0"
										style={{ textTransform: "capitalize", fontSize: 14 }}
										onClick={() =>
											onSelectInvitation(invitation?.Role, invitation?.GUID)
										}
									>
										<span style={{ flex: 1 }}>{invitation?.Role}</span>
										<img
											src={PlusIcon}
											alt="invite PlusIcon"
											style={{ height: 12, width: 12 }}
										/>
									</Button>
								))}
						</DialogContentText>
					) : (
						<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
							<a
								href={generatedInviteLink}
								target="_blank"
								className="mr-2"
								rel="noreferrer"
							>
								<h3
									className="m-0 p-0"
									style={{
										color: { formMainTxt },
										fontSize: 14,
									}}
								>
									<i className="fas fa-fw fa-link mr-2" />
									{generatedInviteLink}
								</h3>
							</a>
							<Tooltip title="Copy to clipboard" placement="top">
								<IconButton
									onClick={() => copyToClipboard(generatedInviteLink)}
								>
									<img src={CopyIcon} alt="CopyIcon" style={{ width: 14 }} />
								</IconButton>
							</Tooltip>
						</DialogContentText>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => {
							setGeneratedInviteLink(null);
							setInviteDialog(false);
						}}
					>
						Dismiss
					</Button>

					{!isEmpty(generatedInviteLink) && (
						<Button
							onClick={() => {
								setGeneratedInviteLink(null);
								setInviteDialog(false);
								copyToClipboard(generatedInviteLink);
							}}
							autoFocus
						>
							Copy and dismiss
						</Button>
					)}
				</DialogActions>
			</Dialog>

			<div
				className="px-4"
				style={{
					height: 233,
					backgroundColor: "#f6f6f6",
					paddingTop: 70,
					backgroundImage: trip?.Cover
						? `url(${trip?.Cover})`
						: `url(${require("../../assets/svg/hero-ve-bg.svg")})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>

			<div className="px-4">
				{/* TRIP NAME */}
				<div className="d-flex flex-row justify-content-between align-items-center pt-3 pb-0">
					<h2 className="mb-0" style={{ color: formMainTxt, fontSize: 22 }}>
						{trip?.Name}
					</h2>
					{TRIP_ITINERARY?.data?.IsPublic && role !== tripUserRoles.NO_MEMBER && (
						<Button
							className="text-capitalize"
							variant="outlined"
							component="span"
							style={{
								color: filterHighlightColor,
								fontSize: 14,
								fontWeight: "normal",
								borderRadius: 20,
								backdropFilter: " blur(4px)",
								border: "solid 1px " + filterHighlightColor,
							}}
							onClick={shareTrip}
						>
							<img
								src={ShareIcon}
								alt="ShareIcon"
								style={{ width: 12, marginRight: 10 }}
							/>
							<span className="deci" style={{ fontSize: 12, marginTop: 2 }}>
								{" "}
								{languages[CURRENT_LANGUAGE]["place_details_share_button"]}
							</span>
						</Button>
					)}
				</div>

				{/* TRIP DETAILS */}
				<div className="" style={{ marginTop: 35 }}>
					<div className="d-flex flex-row align-items-center justify-content-between mb-1">
						<h3
							className="mb-0 pb-0"
							style={{ color: formMainTxt, fontSize: 20 }}
						>
							{languages[CURRENT_LANGUAGE]["trip_itinerary_detail"]}
						</h3>

						{(trip.State === "onboarding" ||
							trip.State === "live" ||
							trip.Type === "community" ||
							trip.SubType === "template") &&
							(role === TRIP_USER_ROLES.CREATOR ||
								role === TRIP_USER_ROLES.COCREATOR ||
								role === TRIP_USER_ROLES.SUPERPASSENGER) && (
								<IconButton
									onClick={() => {
										logEvent_("react_trip_edittripbutton_pressed", {
											tripId: trip?.Id,
										});
										handleEdit(trip);
									}}
									style={{
										height: 40,
										width: 40,
										color: filterHighlightColor,
										textTransform: "initial",
									}}
								>
									<img src={EditIcon} style={{ height: 15 }} />
								</IconButton>
							)}
					</div>

					<div className="d-flex flex-wrap">
						{details?.map((d, _id) => (
							<DetailItem key={_id} label={d} />
						))}

						{businessDetails?.map((d, _id) => (
							<DetailItem key={_id} label={d} />
						))}
					</div>
				</div>

				{/* OPTIONS ITEMS */}
				<OptionItem
					label={languages[CURRENT_LANGUAGE]["trip_itinerary_itinerary"]}
					onClick={() => {
						logEvent_("react_trip_itinerarylist_pressed", { tripId: trip?.Id });
						onClose(tripCreator.Type === "partner");
						setTripItinerary({
							...TRIP_ITINERARY,
							data: trip,
							visibility: true,
							canEdit:
								role === TRIP_USER_ROLES.CREATOR ||
								role === TRIP_USER_ROLES.SKIPPER ||
								role === TRIP_USER_ROLES.COCREATOR ||
								role === TRIP_USER_ROLES.SUPERPASSENGER ||
								role === TRIP_USER_ROLES.PASSENGERSKIPPER,
						});
					}}
				/>

				{/* VIRTUAL GUIDE */}
				{CAN_SEE_VG && (
					<VirtualGuide
						tripData={trip}
						data={{ ...settingsVirtualGuide, ...trip.VirtualGuide }}
						currentUserRole={role}
						currentVirtualGuide={currentVirtualGuide}
						showNotification={CAN_SEE_VG_NOTIFICATION}
						onClickChat={handleChat}
						onChangeDialogState={setCustomDialogProps}
						onChangeVirtualGuidState={onChangeVirtualGuidState}
						onClosesNotification={() => setShowVirtualGuideNotification(false)}
					/>
				)}

				{/* TRIP MEMBERS */}
				<div className="mb-3" style={{ marginTop: 30 }}>
					<h3 style={{ color: formMainTxt, fontSize: 20 }}>
						{!isEmpty(trip.NumberOfAdults)
							? languages[CURRENT_LANGUAGE]["trip_overview_trip_members"]
							: languages[CURRENT_LANGUAGE]["trip_itinerary_crew"]}
					</h3>

					<Button
						className="d-flex align-items-center w-100"
						onClick={(e) => {
							e.stopPropagation();
							logEvent_("react_trip_crewmemberlist_pressed", {
								tripId: trip?.Id,
							});
							handleCrew(trip);
						}}
						style={{ height: 50 }}
					>
						{(role === TRIP_USER_ROLES.CREATOR ||
							role === TRIP_USER_ROLES.SKIPPER ||
							role === TRIP_USER_ROLES.COCREATOR ||
							role === TRIP_USER_ROLES.PASSENGER ||
							role === TRIP_USER_ROLES.SUPERPASSENGER ||
							role === TRIP_USER_ROLES.PASSENGERSKIPPER) &&
							trip?.Type === "community" && (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										handleInvite();
									}}
									style={{ height: 40, width: 40 }}
								>
									<img
										src={PlusIcon}
										alt="Invite PlusIcon"
										style={{ height: 14, width: 14 }}
									/>
								</IconButton>
							)}

						{(role === TRIP_USER_ROLES.CREATOR ||
							role === TRIP_USER_ROLES.SKIPPER ||
							role === TRIP_USER_ROLES.COCREATOR ||
							(role === TRIP_USER_ROLES.PASSENGER &&
								!isEmpty(trip.NumberOfAdults)) ||
							role === TRIP_USER_ROLES.SUPERPASSENGER ||
							role === TRIP_USER_ROLES.PASSENGERSKIPPER) &&
							trip.Type === "business" &&
							(trip.SubType === "template" ||
								trip.State === "onboarding" ||
								trip.State === "live") && (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										handleInvite();
									}}
									style={{ height: 40, width: 40 }}
								>
									<img
										src={PlusIcon}
										alt="Invite PlusIcon"
										style={{ height: 14, width: 14 }}
									/>
								</IconButton>
							)}

						<AvatarGroup max={7} style={{ flex: 1 }}>
							{trip?.Members?.map((member, index) => (
								<IconButton
									key={index}
									onClick={(e) => {
										e.stopPropagation();
										onShowUserProfile(member.UserId);
									}}
									title={` ${member.FirstName || ""} ${member.LastName || ""} `}
									style={{ height: 40, width: 40 }}
								>
									<Avatar
										style={{
											height: 40,
											width: 40,
											background: filterHighlightColor,
										}}
										key={member.UserId}
										alt={`${member.FirstName || ""} ${member.LastName || ""} `}
										src={member.Photo}
									>
										{`${member.FirstName || ""} ${member.LastName || ""} `[0]}
									</Avatar>
								</IconButton>
							))}
						</AvatarGroup>

						<img
							src={ArrowIcon}
							alt="chevron-right"
							style={{
								height: 14,
								width: 14,
								transform: "rotate(180deg)",
							}}
						/>
					</Button>
				</div>

				{/* TAGS */}
				<div
					style={{
						marginTop: 15,
						marginBottom: 15,
					}}
				>
					<h3 style={{ color: formMainTxt, fontSize: 20 }}>
						{languages[CURRENT_LANGUAGE]["trip_itinerary_tags"]}
					</h3>
					<div className="row px-3">
						{Tags.map((tag, index) => (
							<span
								className="dromon-l mr-2 mb-2 p-1"
								style={{
									color: formMainTxt,
									backgroundColor: "#f6f6f6",
									fontSize: 12,
									borderRadius: 5,
								}}
								key={index}
							>
								{languages[CURRENT_LANGUAGE][
									tag
										.replaceAll(":", "_")
										.replaceAll("uri", "overview")
										.replaceAll("tag", "attr")
								]
									? languages[CURRENT_LANGUAGE][
											tag
												.replaceAll(":", "_")
												.replaceAll("uri", "overview")
												.replaceAll("tag", "attr")
									  ]
									: tag}
							</span>
						))}
					</div>
				</div>

				{!!trip?.HasCharterListsEnabled && (
					<div>
						{(role === TRIP_USER_ROLES.CREATOR ||
							role === TRIP_USER_ROLES.COCREATOR ||
							role === TRIP_USER_ROLES.BASEMANAGER ||
							role === TRIP_USER_ROLES.SUPERPASSENGER) && (
							<OptionItem
								label={languages[CURRENT_LANGUAGE]["trip_crew_list"]}
								onClick={() => {
									handleCrewList(trip, isCrewDeadlineReached);
									logEvent_("react_trip_crewlist_pressed", {
										tripId: trip?.Id,
									});
								}}
							/>
						)}

						{(role === TRIP_USER_ROLES.CREATOR ||
							role === TRIP_USER_ROLES.HOSTESS ||
							role === TRIP_USER_ROLES.CHEF ||
							role === TRIP_USER_ROLES.SUPERPASSENGER ||
							role === TRIP_USER_ROLES.PASSENGER ||
							role === TRIP_USER_ROLES.BASEMANAGER ||
							role === TRIP_USER_ROLES.PASSENGER_SKIPPER ||
							role === TRIP_USER_ROLES.PASSENGERSKIPPER) && (
							<OptionItem
								label={languages[CURRENT_LANGUAGE]["trip_provision_list"]}
								onClick={() => {
									handleProvisionList(trip, isProvisionDeadlineReached);
									logEvent_("react_trip_provisionlist_pressed", {
										tripId: trip?.Id,
									});
								}}
							/>
						)}

						{(role === TRIP_USER_ROLES.CREATOR ||
							role === TRIP_USER_ROLES.COCREATOR ||
							role === TRIP_USER_ROLES.BASEMANAGER ||
							role === TRIP_USER_ROLES.SUPERPASSENGER) && (
							<OptionItem
								label={languages[CURRENT_LANGUAGE]["trip_transfer_list"]}
								onClick={() => {
									handleTransferList(isTransferDeadlineReached);
									logEvent_("react_trip_transferlist_pressed", {
										tripId: trip?.Id,
									});
								}}
							/>
						)}
					</div>
				)}

				{!isEmpty(trip?.Description) && (
					<div className="" style={{ marginTop: 35, marginBottom: 50 }}>
						<h3 style={{ color: formMainTxt, fontSize: 20 }}>
							{languages[CURRENT_LANGUAGE]["trip_itinerary_description"]}
						</h3>
						<div className="">
							<p
								style={{ fontSize: 12, color: formMainTxt }}
								className="mb-0 dromon-l"
							>
								{trip?.Description}
							</p>
						</div>
					</div>
				)}

				{!isEmpty(trip?.Preferences) && (
					<div className="pb-2" style={{ marginTop: 35, marginBottom: 50 }}>
						<h3 style={{ color: formMainTxt, fontSize: 20 }}>Preferences</h3>
						<div className="">
							<p
								style={{ fontSize: 12, color: formMainTxt }}
								className="mb-0 dromon-l"
							>
								{trip?.Preferences}
							</p>
						</div>
					</div>
				)}
			</div>

			<div
				style={{
					width: 415,
					position: "fixed",
					bottom: 0,
					left: 0,
					zIndex: 2,
					backgroundColor: whiteColor,
				}}
			>
				<div className="px-3 pt-2 pb-4">
					{role === TRIP_USER_ROLES.CREATOR && (
						<div className="d-flex justify-content-between">
							{trip.Type === "community" && (
								<Button
									onClick={() => {
										logEvent_("react_trip_chatwithcrewbutton_pressed", {
											tripId: trip?.Id,
										});
									}}
									className="d-flex justify-content-center align-items-center"
									style={{
										flex: 1,
										height: 36,
										borderRadius: 5,
										border: "solid 1px #0078ff",
										color: filterHighlightColor,
										textTransform: "initial",
									}}
								>
									{languages[CURRENT_LANGUAGE]["trip_itinerary_chat_button"]}
								</Button>
							)}

							{trip.SubType === "template" && (
								<div
									className="d-flex flex-row align-items-center"
									style={{ flex: 1 }}
								>
									<Button
										onClick={() => {
											if (trip?.SubType === "template") {
												cloneToBusiness(trip);
											}
										}}
										className="d-flex justify-content-center align-items-center"
										style={{
											flex: 1,
											height: 36,
											borderRadius: 5,
											backgroundColor: "#0078ff",
											color: "#FFFFFF",
											textTransform: "initial",
										}}
									>
										{languages[CURRENT_LANGUAGE]["trip_createfromtemplate_btn"]}
									</Button>
								</div>
							)}
						</div>
					)}

					{role === TRIP_USER_ROLES.NO_MEMBER &&
						AuthService.isUserLogedIn() &&
						CURRENT_USER_DATA?.Type === "business" &&
						vesselUrl && (
							<div className="d-flex justify-content-between flex-row">
								<Button
									onClick={() => {
										logEvent_("react_trip_available_boats_pressed", {
											tripId: trip?.Id,
										});
										window.open(vesselUrl, "_blank");
									}}
									className="d-flex justify-content-center align-items-center"
									style={{
										flex: 1,
										height: 36,
										borderRadius: 5,
										backgroundColor: "#0078FF",
										color: "white",
										textTransform: "initial",
										fontFamily: "Decimamono",
									}}
								>
									<img
										src={VesselAvailIcon}
										alt="VesselAvailIcon"
										style={{ width: 20, height: 22, marginLeft: "-60px" }}
									/>

									<span
										style={{
											fontFamily: "Decimamono",
											fontSize: 14,
											marginLeft: "55px",
										}}
									>
										{languages[CURRENT_LANGUAGE]["trip_check_boat"]}
									</span>
								</Button>
							</div>
						)}

					{(role === TRIP_USER_ROLES.SKIPPER ||
						role === TRIP_USER_ROLES.PASSENGER ||
						role === TRIP_USER_ROLES.NO_MEMBER) &&
						AuthService.isUserLogedIn() &&
						CURRENT_USER_DATA?.Type === "community" &&
						trip.Type === "community" && (
							<div
								className="d-flex justify-content-between flex-row"
								style={{ paddingTop: "3px" }}
							>
								<Button
									onClick={() => {
										if (
											role !== TRIP_USER_ROLES.CREATOR &&
											role !== TRIP_USER_ROLES.PASSENGER &&
											role !== TRIP_USER_ROLES.SKIPPER &&
											AuthService.isUserLogedIn()
										) {
											logEvent_("react_trip_duplicatebutton_pressed", {
												tripId: trip?.Id,
											});
											handleDuplicate();
										} else if (
											role === TRIP_USER_ROLES.PASSENGER ||
											role === TRIP_USER_ROLES.SKIPPER
										) {
											openChat();
										} else {
											showAuth();
										}
									}}
									className="d-flex justify-content-center align-items-center"
									style={{
										flex: 1,
										height: 36,
										borderRadius: 5,
										textTransform: "initial",
										backgroundColor: filterHighlightColor,
										color: whiteColor,
									}}
								>
									{role === TRIP_USER_ROLES.SKIPPER ||
									role === TRIP_USER_ROLES.PASSENGER
										? languages[CURRENT_LANGUAGE]["trip_itinerary_chat_button"]
										: `Duplicate Trip`}
								</Button>
							</div>
						)}
				</div>
			</div>
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTripItineraryVisibility: (bool = false) =>
			dispatch(setTripItineraryVisibility(bool)),
		setUserProfileId: (userId) => dispatch(setUserProfileId(userId)),
		showUserProfile: () => dispatch(showUserProfile()),
		setTripItinerary: (tripData = {}, combine = true) =>
			dispatch(setTripItinerary(tripData, combine)),
		retrieveTripDaysList: (tripId) => dispatch(retrieveTripDaysList(tripId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TripOverview);

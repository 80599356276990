import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Slider from "@material-ui/core/Slider";
import { Button, Snackbar, Switch, withStyles } from "@material-ui/core";

// HELPERS
import { AuthService } from "../../AuthService";
import { ApiClient } from "../../ApiClient";
import { handleFilters } from "../map/helpers/applyFilter";
import { convertDepthToMyUnit } from "../../helpers/UnitConverter";
import { logEvent_ } from "../../helpers/analytics";

// SELECTORS
import { languages } from "../../store/features/translation/Languages";
import { selectedItineraryDay as selectedItineraryDay_ } from "../../store/features/map/selectors";
import { currentLang } from "../../store/features/translation/selectors";
import { userData } from "../../store/features/user/selectors";

// ACTIONS
import { setMapFiltersSelected } from "../../store/features/map/actions";

// CONSTANTS
import {
	experience,
	filterHighlightColor,
	fooddrink,
	formMainTxt,
	inspired,
	lanchpoint,
	shelter,
	shopsservices,
	whiteColor,
	trips,
} from "../../constants/colors";

// COMPONENTS
import CardVessels from "./CardVessels";
import CardMultiSelect from "./CardMultiSelect";
import CardCategory from "./CardCategory";

// ICONS
import closeIcon from "../../assets/close.svg";

// STYLES
import { CloseModalBtn, BoxFilter, Overlay } from "./styled";

export const colorDictionary = {
	experience,
	fooddrink,
	inspired,
	lanchpoint,
	shelter,
	shopsservices,
	trips,
};

export const Check = withStyles({
	switchBase: {
		color: "#e5f1ff",
		"&$checked": {
			color: "#0078ff",
		},
		"&$checked + $track": {
			backgroundColor: "#e5f1ff",
		},
	},
	checked: {},
	track: {},
})(Switch);

const FilterModal = ({
	visible,
	setMapFiltersSelected,
	initials,
	onClose = () => {},
}) => {
	// SELECTORS
	const myFilters = useSelector((state) => state.map.filters);
	const currentUser = useSelector(userData);
	const currentLanguage = useSelector(currentLang);
	const selectedItineraryDay = useSelector(selectedItineraryDay_);
	const myFilterSelected = useSelector((state) => state.map.filtersSelected);

	// DATA
	const initialFiltersState = myFilterSelected;
	const labelVesselsList = [];
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};

	// STATES
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [filtersSelected, setFiltersSelected] = useState(initialFiltersState);
	const [filters_, setFilters_] = useState({});
	const [tripTags, setTriptags] = useState({});
	const [selectedVessel, setSelectedVessel] = useState(null);
	const [, setEnableOnboard] = useState(false);
	const [, setFilterOptionsOfCurrentVessel] = useState({});
	const [checked, setChecked] = useState(false);
	const [value, setValue] = useState(10);

	for (const item in myFilters) {
		if (Object.hasOwnProperty.call(myFilters, item)) {
			labelVesselsList.push(item);
		}
	}

	const needDictionary = {
		shelter: "filter_need_shelter",
		experience: "filter_need_experiences",
		fooddrink: "filter_need_food",
		shopsservices: "filter_need_shop",
		lanchpoint: "filter_needs_launch",
		trips: "filter_need_trips",
	};

	const placeTypeDictionary = {
		beach: "filter_placetype_beach",
		anchorage: "filter_placetype_anchorage",
		cave: "filter_placetype_cave",
		wreck: "filter_placetype_wreck",
		marina: "filter_placetype_marina",
		port: "filter_placetype_port",
		shipyard: "filter_placetype_shipyard",
		boatyard: "filter_placetype_boatyard",
		mooring: "filter_placetype_mooring",
		boatramp: "filter_placetype_boatramp",
		community: "filter_placetype_community",
		business: "filter_placetype_business",
	};

	const vesselDictionary = {
		motorboat: "user_vessels_type_motorboat",
		kayak: "user_vessels_type_kayak",
		sailboat: "user_vessels_type_sailing",
	};

	const onSelectVessel = (vesselType) => {
		switch (vesselType) {
			case "kayak":
				logEvent_("react_explore_filters_kayak_selected");
				break;
			case "motorboat":
				logEvent_("react_explore_filters_motor_selected");
				break;
			case "sailboat":
				logEvent_("react_explore_filters_sail_selected");
				break;
			default:
				break;
		}
		if (!checked) {
			setFiltersSelected({
				...filtersSelected,
				vesselLength: filtersSelected.vesselLength,
				vesselType: vesselType,
				needCategoryTag:
					myFilters[vesselType][filtersSelected.needs].categoryTag,
			});
		}
	};

	const onSliderValueChange = (e, newValue) => {
		setValue(newValue);
		setChecked(false);
		let newFiltersSelectedState = {
			...filtersSelected,
			vesselLength: newValue,
		};
		if (
			filtersSelected.vesselType === "smallboat" ||
			filtersSelected.vesselType === "motorboat"
		) {
			const vesselType = newValue > 10 ? "motorboat" : "smallboat";
			newFiltersSelectedState = {
				...newFiltersSelectedState,
				vesselType: vesselType,
				needCategoryTag:
					myFilters[vesselType][filtersSelected.needs].categoryTag,
				places: [],
				tags: [],
			};
		}
		setFiltersSelected(newFiltersSelectedState);
	};

	const onSelectNeed = (key) => {
		switch (key) {
			case "experience":
				logEvent_("react_explore_filters_experien_selected");
				break;
			case "fooddrink":
				logEvent_("react_explore_filters_food_selected");
				break;
			case "shelter":
				logEvent_("react_explore_filters_shelter_selected");
				break;
			case "shopsservices":
				logEvent_("react_explore_filters_needshops_selected");
				break;
			case "lanchpoint":
				logEvent_("react_explore_filters_launch_selected");
				break;
			case "trips":
				logEvent_("react_explore_filters_needtrips_selected");
				break;
			default:
				break;
		}
		setFiltersSelected({
			...filtersSelected,
			needs: key,
			needCategoryTag: myFilters[filtersSelected.vesselType][key].categoryTag,
			places: [],
			tags: [],
		});
	};

	const onSelectPlace = (placeName) => {
		// ADD EVENT
		logEvent_("react_explore_filters_placetype_selected");
		var placesList = Array.from(filtersSelected.places);
		if (placeName === "business" || placeName === "community") {
			placesList = [];
		}
		if (placesList.includes(placeName)) {
			placesList = placesList.filter((item) => item !== placeName);
		} else {
			placesList.push(placeName);
		}
		setFiltersSelected({
			...filtersSelected,
			places: placesList,
			tags: placesList.length ? filtersSelected.tags : [],
		});
	};

	const onSelectTag = (tagName) => {
		// ADD EVENT
		logEvent_("react_explore_filters_tag_selected");

		if (filtersSelected.needs === "trips") {
			var allTagsToUse = filtersSelected.tags;

			for (let t in tripTags) {
				if (tripTags[t].Values?.includes(tagName)) {
					if (tripTags[t].IsSingle) {
						const found = allTagsToUse.find((e) => e === tagName);
						tripTags[t].Values.forEach((e) => {
							allTagsToUse = allTagsToUse.filter((item) => item !== e);
						});
						if (!found) {
							allTagsToUse.push(tagName);
						}
					} else {
						const found = allTagsToUse.find((e) => e === tagName);
						if (found) {
							allTagsToUse = allTagsToUse.filter((item) => item !== found);
						} else {
							allTagsToUse.push(tagName);
						}
					}
				}
			}
			setFiltersSelected({
				...filtersSelected,
				tags: allTagsToUse,
			});
		} else {
			let tagList = filtersSelected.tags;
			if (tagList.includes(tagName)) {
				tagList = tagList.filter((item) => item !== tagName);
			} else {
				tagList.push(tagName);
			}
			setFiltersSelected({
				...filtersSelected,
				tags: tagList,
			});
		}
	};

	const onApply = () => {
		// ADD EVENT
		logEvent_("react_explore_filters_applybtn_pressed");

		setMapFiltersSelected(handleFilters(filtersSelected, myFilters));
		onClose();
		const VESSELS_TYPES_ = filtersSelected.vesselType;
		const NEEDS_ = filtersSelected.needs;
		const PLACE_TYPES_ = filtersSelected.places?.length
			? filtersSelected.places.join("-")
			: "";
		const SELECTED_TAGS = filtersSelected.tags.filter((tag) => tag);
		const TAGS_ =
			filtersSelected.places?.length && SELECTED_TAGS?.length
				? "/" +
				  SELECTED_TAGS.join("-")
						.replaceAll("uri:pl:tag:", "place_")
						.replaceAll("uri:trip:tag:", "trip_")
				: "";
		const NEW_URL = `https://${
			window.location.host
		}/explore/filter/${VESSELS_TYPES_}/${NEEDS_}/${PLACE_TYPES_ + TAGS_}`;

		window.history.pushState(null, null, NEW_URL);
	};

	const handleSwitch = () => {
		let selectedVess = currentUser?.SelectedVessel;
		if (selectedVess === null) {
			return;
		}
		if (checked) {
			setChecked(false);
		} else {
			if (selectedVess) {
				var length = selectedVess.LengthMeters.toFixed(0);

				if (selectedVess.FilterType === "motorboat") {
					setFiltersSelected({
						...initialFiltersState,
						vesselType: length > 10 ? "motorboat" : "smallboat",
						vesselLength: selectedVess.LengthMeters.toFixed(0),
					});
					setValue(length);
				} else {
					setFiltersSelected({
						...initialFiltersState,
						vesselLength: length,
						vesselType: selectedVess.FilterType,
					});
					setValue(length);
				}
				setChecked(true);
			} else {
				setNotice({
					type: "WARNING",
					content: languages[currentLanguage]["user_vessels_emptystate_header"],
				});
			}
		}
	};

	const resetFilter = () => {
		if (visible && Object.keys(myFilterSelected).length !== 0) {
			setFiltersSelected({
				...myFilterSelected,
				allPredicates: myFilterSelected?.allPredicates
					? myFilterSelected.allPredicates
					: filtersSelected.allPredicates,
				needCategoryTag: myFilterSelected?.needCategoryTag
					? myFilterSelected.needCategoryTag
					: filtersSelected.needCategoryTag,
				needs: myFilterSelected?.needs
					? myFilterSelected.needs
					: filtersSelected.needs,
				places: myFilterSelected?.places
					? myFilterSelected.places
					: filtersSelected.places,
				tags: myFilterSelected?.tags
					? myFilterSelected.tags
					: filtersSelected.tags,
				vesselLength: myFilterSelected?.vesselLength
					? myFilterSelected.vesselLength
					: filtersSelected.vesselLength,
				vesselType: myFilterSelected?.vesselType
					? myFilterSelected.vesselType
					: filtersSelected.vesselType,
			});
		}
	};

	useEffect(() => {
		if (!window.localStorage.getItem("isFilterVisited") && visible) {
			setTimeout(() => {
				setEnableOnboard(true);
			}, 2000);
		}
	}, [visible]);

	useEffect(() => {
		setSelectedVessel("smallboat");
		setFilters_(myFilters);
		onSelectVessel("smallboat");
		return () => {};
	}, []);

	useEffect(() => {
		// GET TRIP TAGS
		const getTripTags = async () => {
			const tags = await ApiClient.getTripAttributesFilter();
			if (!tags || tags.ErrorCode || tags.ErrorMessage) return [];
			return tags;
		};

		getTripTags().then((t) => {
			if (t) {
				let types = Object.keys(t);
				let tags = [];
				for (let i = 0; i < types.length; i++) {
					tags = tags.concat(t[types[i]]?.Values);
				}
				setTriptags(t);
			}
		});

		setFilterOptionsOfCurrentVessel(myFilters[selectedVessel]);
		if (Object.keys(initials).length !== 0) {
			setFiltersSelected({
				...filtersSelected,
				needCategoryTag: initials?.needCategoryTag
					? initials?.needCategoryTag
					: filtersSelected.needCategoryTag,
				needs: initials?.need ? initials.need : filtersSelected.needs,
				places: initials?.placeTypes
					? initials.placeTypes
					: filtersSelected.places,
				vesselType:
					initials?.vessel && initials.vessel === "motorboat"
						? "smallboat"
						: initials?.vessel
						? initials?.vessel
						: filtersSelected.vesselType,
				tags: initials?.tags ? initials?.tags : filtersSelected.tags,
			});
		}
		return () => {};
	}, [selectedVessel, initials, filters_, initialFiltersState]);

	return (
		<div>
			<Overlay
				show={visible}
				onClick={() => {
					onClose();
					resetFilter();
				}}
			/>
			<BoxFilter
				className="container"
				show={visible}
				style={{
					padding: "24px 16px 86px 16px",
				}}
			>
				<h2 style={{ fontSize: 21, color: formMainTxt }}>
					{languages[currentLanguage]["filter_header"]}
				</h2>
				<CloseModalBtn
					onClick={() => {
						onClose();
						resetFilter();
					}}
				>
					<img src={closeIcon} alt="Close" style={{ height: 15, width: 15 }} />
				</CloseModalBtn>
				<div style={{ marginBottom: 16 }} className="">
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							marginTop: 42,
						}}
					>
						<h3 style={titleStyle} className="vessel-type-a">
							{languages[currentLanguage]["filter_vesseltype_header"]}
						</h3>
						{currentUser.Type === "community" && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<span
									style={{ color: "#8F96A7", fontSize: 12 }}
									className="dromon-l"
								>
									{
										languages[currentLanguage][
											"filter_vesseltype_save_checkbox"
										]
									}
								</span>
								<Check
									checked={checked}
									value={value}
									size="small"
									onChange={handleSwitch}
									disabled={!AuthService.isUserLogedIn()}
									name="weather"
									inputProps={{ "aria-label": "primary checkbox" }}
								/>
							</div>
						)}
					</div>
					<div className="container-fluid mt-1">
						<div className="row" style={{ justifyContent: "space-between" }}>
							{labelVesselsList.map(
								(vessel, index) =>
									((filtersSelected.vesselLength > 10 &&
										vessel !== "smallboat") ||
										(filtersSelected.vesselLength < 11 &&
											vessel !== "motorboat")) && (
										<CardVessels
											key={index}
											active={vessel === filtersSelected.vesselType}
											type={vessel}
											disabled={
												checked && vessel !== filtersSelected.vesselType
											}
											label={
												vessel === "smallboat"
													? languages[currentLanguage][
															vesselDictionary["motorboat"]
													  ]
													: languages[currentLanguage][vesselDictionary[vessel]]
											}
											onClick={() => onSelectVessel(vessel)}
										/>
									)
							)}
						</div>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						marginBottom: 36,
					}}
				>
					<div
						className="d-flex flex-row justify-content-between"
						style={{ alignItems: "center", width: "100%" }}
					>
						<h3
							style={{
								fontWeight: "500",
								fontSize: 12,
								color: "#222c4b",
								marginBottom: 0,
							}}
							className="boat-length"
						>
							{languages[currentLanguage]["filter_vesseltype_length_label"]}(
							{currentUser?.Settings?.DepthUnits
								? currentUser?.Settings?.DepthUnits
								: "m"}
							)
						</h3>
						<span
							className="d-flex justify-content-center align-items-center px-2"
							style={{
								color: filterHighlightColor,
								fontSize: 12,
								padding: "3px 7px",
								height: 24,
								borderRadius: 5,
								backgroundColor: "#e5f1ff",
							}}
						>
							{AuthService.isUserLogedIn()
								? Math.round(
										convertDepthToMyUnit(
											filtersSelected?.vesselLength,
											currentUser?.Settings?.DepthUnits
										)
								  )
								: filtersSelected.vesselLength}
						</span>
						<Slider
							min={2}
							max={50}
							value={value}
							style={{ width: 218, color: "#dbdee6" }}
							onChange={onSliderValueChange}
						/>
					</div>
				</div>

				<div style={{}}>
					<div className="need-filter">
						<h3 style={titleStyle}>
							{languages[currentLanguage]["filter_needs_header"]}
						</h3>
					</div>
					<div
						className="container-fluid"
						style={{ display: "flex", flexDirection: "row", marginBottom: 36 }}
					>
						<div className="row">
							{Object.keys(
								Object.keys(filters_).length ? filters_[selectedVessel] : {}
							).map(function (key, index) {
								return (
									<CardCategory
										key={index}
										onClick={() => {
											onSelectNeed(key, filters_[selectedVessel][key]);
										}}
										label={languages[currentLanguage][needDictionary[key]]}
										active={filtersSelected.needs === key}
										bgColor={colorDictionary[key]}
									/>
								);
							})}
						</div>
					</div>
					<div className="container-fluid" style={{ marginBottom: 36 }}>
						<h3 style={titleStyle} className="tags-one">
							{languages[currentLanguage]["filter_placetypes_header"]}
						</h3>
						<div className="row">
							<div className="col-12">
								<div className="row px-3">
									{filtersSelected &&
										!!Object?.keys(
											Object.keys(filters_)?.length
												? filters_[filtersSelected?.vesselType]
												: {}
										).length &&
										!!filtersSelected.needs.length && (
											<>
												{filters_[filtersSelected.vesselType][
													filtersSelected.needs
												].types.map((key, index) => {
													return (
														<CardMultiSelect
															key={index}
															onClick={() => onSelectPlace(key)}
															active={filtersSelected.places.includes(key)}
															label={
																languages[currentLanguage][
																	placeTypeDictionary[key]
																]
															}
														/>
													);
												})}
											</>
										)}
								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<h3 style={titleStyle} className="tags-two">
							{languages[currentLanguage]["uri_pl_tags_header"]}
						</h3>
						<div className="row">
							<div className="col-12" style={{}}>
								<div className="row px-3">
									{!!Object.keys(
										Object.keys(filters_).length
											? filters_[filtersSelected.vesselType]
											: {}
									).length &&
										!!filtersSelected.needs.length &&
										filters_[filtersSelected.vesselType][
											filtersSelected.needs
										].tags.map((tag, idTags) => {
											return (
												<CardMultiSelect
													key={idTags}
													onClick={() => onSelectTag(tag)}
													disable={(() => {
														if (filtersSelected.needs === "trips") return false;
														let available = false;
														const appliedTags =
															filters_[filtersSelected.vesselType][
																filtersSelected.needs
															].appliedTags;
														Object.keys(appliedTags).map((key, index) => {
															if (filtersSelected.places.includes(key)) {
																if (appliedTags[key].includes(tag)) {
																	available = true;
																}
															}
														});
														return !available;
													})()}
													active={filtersSelected.tags.includes(tag)}
													label={
														languages[currentLanguage][tag.replaceAll(":", "_")]
															? languages[currentLanguage][
																	tag.replaceAll(":", "_")
															  ]
															: languages[currentLanguage][
																	tag
																		.replaceAll(":", "_")
																		.replaceAll("uri", "overview")
																		.replaceAll("tag", "attr")
															  ]
															? languages[currentLanguage][
																	tag
																		.replaceAll(":", "_")
																		.replaceAll("uri", "overview")
																		.replaceAll("tag", "attr")
															  ]
															: tag
													}
												/>
											);
										})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={notice.type !== ""}
					autoHideDuration={2000}
					onClose={() => setNotice({ type: "", content: "" })}
					message={notice.content}
				/>
			</BoxFilter>

			<BoxFilter
				className="container d-flex align-items-center"
				show={visible}
				style={{
					top: "calc(100% - 70px)",
					padding: "0 12px",
					width: 407,
					left: visible ? 0 : -407,
					height: 70,
					overflow: "hidden",
				}}
			>
				<Button
					className="p-2 col-12 text-capitalize apply-filter-f"
					onClick={onApply}
					style={{
						color: whiteColor,
						backgroundColor: filterHighlightColor,
						border: "none",
						fontSize: 14,
						borderRadius: 5,
						margin: "16px 0",
						boxShadow: "-5px 0 7px 0 rgba(160, 160, 169, 0.15)",
					}}
				>
					<span className="deci text-capitalize">
						{languages[currentLanguage]["filter_apply_button"]}
					</span>
				</Button>
			</BoxFilter>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMapFiltersSelected: (filtersSelected) =>
			dispatch(setMapFiltersSelected(filtersSelected)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);

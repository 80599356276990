import styled, { css } from "styled-components";

export const sidebarWidth = 415;
export const animationDuration = 0.4;

export const Box = styled.div`
	position: absolute;
	top: 75px;
	right: 30px;
	width: 300px;
	background-color: white;
	box-shadow: 0 0 4px whitesmoke;
`;

export const Sidebar = styled.div`
	position: absolute;
	z-index: 19;
	top: 55px;
	left: -${sidebarWidth}px;
	width: ${sidebarWidth}px;
	height: calc(100vh - 55px);
	margin: 0;
	padding: 0;
	box-shadow: none;
	visibility: hidden;
	background-color: #ffffff;
	opacity: 0.5;
	transition: all ${animationDuration}s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.show &&
		css`
			left: 0;
			opacity: 1;
			box-shadow: 7px 0 7px 0 rgba(160, 160, 169, 0.18);
			visibility: visible;
		`}
`;

export const SidebarContent = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #fff;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background: #8f96a7;
		border-radius: 3px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #222c4b;
	}
`;

import {
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION,
	SET_CUSTOM_DIALOG_PROPS,
} from "./types";

export const showNotification = (payload) => {
	return {
		type: SHOW_NOTIFICATION,
		payload,
	};
};

export const hideNotification = () => {
	return {
		type: HIDE_NOTIFICATION,
	};
};

export const setCustomDialogProps = () => {
	return {
		type: SET_CUSTOM_DIALOG_PROPS,
	};
};

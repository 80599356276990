import { toast } from "react-toastify";

// TYPES
import {
	SET_MAP,
	CLEAN_MAP,
	SET_PLACE_ATTRIBUTES,
	SET_TRIP_ATTRIBUTES,
	SET_MAP_FILTERS,
	SET_MAP_FILTERS_SELECTED,
	DEFAULT_NEED,
	DEFAULT_NEED_KEY,
	DEFAULT_PLACES_PREDICATE,
	SET_WEATHER,
	SET_GLOBAL_WEATHER_DATE,
	SET_GLOBAL_WEATHER_BRAND_VISIBILITY,
	SET_CURRENT_TRIP_ITINERARY,
	SET_TRIP_ITINERARY_DAYS_LIST,
	ADD_NODE_TO_TRIP_ITINERARY,
	REPLACE_SHELTER_NODE,
	TOGGLE_SHELTER_NODE,
	REMOVE_NODE_TO_TRIP_ITINERARY,
	UPDATE_TRIP_ITINERARY_NODE_DURATION,
	UPDATE_INDEX_TRIP_DAY,
	SET_TRIP_ITINERARY_VISIBILITY,
	UPDATE_MIDDLE_NODES,
} from "./types";
import { USER_CLEAN } from "../user/types";

// CONSTANTS
import tostifyDefaultProps from "../../../constants/tostifyDefaultProps";

// HELPERS
import { startAt } from "../../../components/Itinerary/components/NoEditDayState";
import { isEmpty } from "../../../helpers/functions";

const currentLanguage = window.localStorage.getItem("language")
	? JSON.parse(window.localStorage.getItem("language")).language
	: "EN";

const textTranslated = {
	noEditableDay: {
		EN: "Start by creating a Trip and then you can add places to it",
		FR: "Commencez par créer un voyage, puis vous pouvez y ajouter des lieux",
		GR: "Ξεκίνα δημιουργώντας ένα Ταξίδι και μετά πρόσθεσε τοποθεσίες σε αυτό",
	},
	onRemoveNode: {
		EN: "Place removed successfully",
		FR: "Lieu supprimé avec succès",
		GR: "Η τοποθεσία αφαιρέθηκε επιτυχώς από το Ταξίδι σου",
	},
};

export const initialState = {
	placeAttributes: {},
	tripAttributes: {},
	filters: {},
	filtersSelected: {
		savedVessel: false,
		vesselType: "smallboat",
		vesselLength: [10],
		needs: DEFAULT_NEED_KEY,
		needCategoryTag: DEFAULT_NEED,
		places: [],
		tags: [],
		allPredicates: DEFAULT_PLACES_PREDICATE,
		tripsPredicates: ["!=", ["get", "id"], 0],
	},
	vessels: [],
	places: [],
	weather: {},
	globalWeatherBrandVisibility: false,
	globalWeatherDate: null,
	currentTripItinerary: {
		visibility: false,
		canEdit: false,
		data: null,
		selectedDay: {
			index: null,
			startTime: "08:30",
			inEditing: false,
			launchPoint: null,
			tripNodes: [],
			shelterPoint: null,
			selectedNode: {
				visibility: false,
				index: null,
				note: null,
			},
			forceLastNode: false,
		},
		daysList: [],
	},
};

export default function userReducer(state = initialState, action) {
	let new_state = state;

	const initializeTripItineraryIndexDay = (
		newIdxTripDay,
		state = new_state
	) => {
		let new_state = state;

		if (typeof newIdxTripDay === "number") {
			let currentTripItinerary = JSON.parse(
				JSON.stringify(state?.currentTripItinerary)
			);
			let tripDay = currentTripItinerary?.daysList[newIdxTripDay];
			let isCircularTrip;
			(currentTripItinerary?.data?.Attributes || []).map((item) => {
				const reg = new RegExp(/circular/, "ig");
				if (reg.test(item)) isCircularTrip = true;

				return item;
			});

			if (!isEmpty(tripDay)) {
				if (!!tripDay?.EditableNodes?.length || newIdxTripDay === 0) {
					currentTripItinerary = {
						...currentTripItinerary,
						selectedDay: {
							...currentTripItinerary.selectedDay,
							index: newIdxTripDay,
							startTime: "08:30",
							inEditing: currentTripItinerary?.data?.State !== "completed",
							launchPoint: null,
							tripNodes: [],
							shelterPoint: null,
						},
					};

					if (!isEmpty(tripDay?.EditableNodes)) {
						if (
							tripDay?.EditableNodes[0]?.Need === "lanchpoint" ||
							tripDay?.EditableNodes[0]?.Need === "launchpoint" ||
							tripDay?.EditableNodes[0]?.Need === "shelter"
						)
							currentTripItinerary = {
								...currentTripItinerary,
								selectedDay: {
									...currentTripItinerary.selectedDay,
									launchPoint: tripDay?.EditableNodes[0],
								},
							};
						if (tripDay?.EditableNodes?.length > 1) {
							const tripNodes = tripDay?.EditableNodes.slice(
								1,
								tripDay.EditableNodes.length
							);
							if (
								tripNodes[tripNodes.length - 1]?.Need === "shelter" ||
								tripNodes[tripNodes.length - 1]?.Need === "lanchpoint" ||
								tripNodes[tripNodes.length - 1]?.Need === "launchpoint"
							) {
								currentTripItinerary = {
									...currentTripItinerary,
									selectedDay: {
										...currentTripItinerary.selectedDay,
										shelterPoint: tripNodes[tripNodes.length - 1],
										tripNodes: tripNodes.filter(
											(item, idx) => idx !== tripNodes.length - 1
										),
										inEditing: false,
									},
								};
							} else
								currentTripItinerary = {
									...currentTripItinerary,
									selectedDay: {
										...currentTripItinerary.selectedDay,
										tripNodes,
										inEditing:
											isCircularTrip &&
											currentTripItinerary?.data?.State !== "completed"
												? false
												: true,
									},
								};
						}

						new_state = {
							...new_state,
							currentTripItinerary: {
								...currentTripItinerary,
								selectedDay: {
									...currentTripItinerary.selectedDay,
									startTime: currentTripItinerary?.daysList[newIdxTripDay]
										?.StartTime
										? `${
												startAt(
													currentTripItinerary?.daysList[newIdxTripDay]
														?.StartTime
												).getHours() >= 10
													? startAt(
															currentTripItinerary?.daysList[newIdxTripDay]
																?.StartTime
													  ).getHours()
													: `0${startAt(
															currentTripItinerary?.daysList[newIdxTripDay]
																?.StartTime
													  ).getHours()}`
										  }:${
												startAt(
													currentTripItinerary?.daysList[newIdxTripDay]
														?.StartTime
												).getMinutes() >= 10
													? startAt(
															currentTripItinerary?.daysList[newIdxTripDay]
																?.StartTime
													  ).getMinutes()
													: `0${startAt(
															currentTripItinerary?.daysList[newIdxTripDay]
																?.StartTime
													  ).getMinutes()}`
										  }`
										: "08:00",
								},
								daysList: currentTripItinerary?.daysList?.map((dayObject) => {
									return {
										...dayObject,
										Nodes:
											dayObject?.Nodes?.map((nodeDay) => {
												return {
													...nodeDay,
													Name: { Latin: nodeDay.Place?.Name },
													CoverImage: nodeDay.Place?.Cover,

													// REQUIRED
													PlaceId: nodeDay.Place?.Id,
												};
											}) || dayObject.Nodes,
										EditableNodes:
											dayObject?.Nodes?.filter(
												(item) => item.Type !== "sail"
											).map((nodeDay) => {
												return {
													...nodeDay,
													Type: nodeDay.Place?.Type,
													CoverImage: nodeDay.Place?.Cover,

													// REQUIRED
													Point: nodeDay.Point || nodeDay.Line,
													Duration: nodeDay.Duration
														? startAt(nodeDay.Duration).getHours() +
														  Math.round(
																(startAt(nodeDay.Duration).getMinutes() / 60) *
																	100
														  ) /
																100
														: 2,
													Note: nodeDay.Note,
													PlaceId: nodeDay.Place?.Id,
												};
											}) || dayObject.Nodes,
									};
								}),
							},
						};
					}
				} else {
					//alert("Can't collapse day. Day empty")
				}
			} else {
				toast.error("Day not found", {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				// alert("Day not found")
			}
		}

		localStorage.setItem(
			"currentTripItinerary",
			JSON.stringify(new_state.currentTripItinerary)
		);
		return new_state;
	};

	switch (action.type) {
		case SET_MAP:
			new_state = {
				...state,
				...action.payload,
			};

			return new_state;

		case SET_PLACE_ATTRIBUTES:
			if (
				typeof action.payload === "object" &&
				Object.keys(action.payload).length
			) {
				new_state = { ...state, placeAttributes: action.payload };
			}
			return new_state;

		case SET_TRIP_ATTRIBUTES:
			if (
				typeof action.payload === "object" &&
				Object.keys(action.payload).length
			) {
				new_state = { ...state, tripAttributes: action.payload };
			}
			return new_state;

		case SET_CURRENT_TRIP_ITINERARY:
			if (typeof action.payload === "object" && !isEmpty(action.payload)) {
				new_state = {
					...state,
					currentTripItinerary: action?.combine
						? { ...state.currentTripItinerary, ...action.payload }
						: action.payload,
				};
			}
			return new_state;

		case SET_TRIP_ITINERARY_DAYS_LIST:
			let daysList = action.payload;
			let currentTripItinerary = JSON.parse(
				JSON.stringify(state.currentTripItinerary)
			);

			let isCircularTrip;
			(currentTripItinerary?.data?.Attributes || []).map((item) => {
				const reg = new RegExp(/circular/, "ig");
				if (reg.test(item)) isCircularTrip = true;

				return item;
			});

			if (typeof daysList === "object" && !isEmpty(daysList)) {
				daysList = daysList.map((dayObject, idxDay) => {
					return {
						...dayObject,
						Nodes:
							dayObject?.Nodes?.map((nodeDay) => {
								return {
									...nodeDay,
									Name: { Latin: nodeDay.Place?.Name },
									CoverImage: nodeDay.Place?.Cover,

									// REQUIRED
									PlaceId: nodeDay.Place?.Id,
								};
							}) || dayObject.Nodes,
						EditableNodes:
							dayObject?.Nodes?.filter(
								(item, idx) => item.Type !== "sail"
							)?.map((nodeDay) => {
								return {
									...nodeDay,
									//Name: { Latin: nodeDay.Place?.Name },
									Type: nodeDay.Place?.Type,
									CoverImage: nodeDay.Place?.Cover,
									//Order: nodeDay.Order,

									// REQUIRED
									Point: nodeDay.Point || nodeDay.Line,
									Duration: nodeDay.Duration
										? startAt(nodeDay.Duration).getHours() +
										  Math.round(
												(startAt(nodeDay.Duration).getMinutes() / 60) * 100
										  ) /
												100
										: 2,
									Note: nodeDay.Note,
									//Need: nodeDay.Need,
									PlaceId: nodeDay.Place?.Id,
								};
							}) || dayObject.Nodes,
					};
				});
				new_state = {
					...state,
					currentTripItinerary: {
						...state.currentTripItinerary,
						selectedDay: {
							...state.currentTripItinerary.selectedDay,
							index: 0,
							startTime: daysList[0].StartTime,
						},
						daysList: daysList,
					},
				};
			}
			return initializeTripItineraryIndexDay(0, new_state);

		case ADD_NODE_TO_TRIP_ITINERARY:
			if (typeof action.payload === "object" && !isEmpty(action.payload)) {
				const placeData = action?.payload;
				const _REG_DIRECT = new RegExp(/direct/, "ig");
				let currentTripItinerary = state?.currentTripItinerary;
				let isDirectTrip = false;

				(currentTripItinerary?.data?.Attributes || []).map((item) => {
					if (_REG_DIRECT.test(item)) isDirectTrip = true;

					return item;
				});

				let selectedDay = currentTripItinerary?.selectedDay;
				let isLastDayDirect =
					isDirectTrip &&
					selectedDay?.index === currentTripItinerary?.daysList?.length - 1;

				if (typeof selectedDay.index === "number") {
					const selectedFilter = state?.filtersSelected;

					if (!isEmpty(selectedFilter.needs)) {
						const newNodeData = {
							...placeData,
							needTag: selectedFilter?.needCategoryTag,
							Point: placeData?.Location,
							Duration: 2,
							Note: "",
							Need: state?.filtersSelected?.needs,
							PlaceId: placeData?.ID,
						};

						if (!selectedDay.launchPoint) {
							if (
								isEmpty(selectedDay.tripNodes) ||
								(isLastDayDirect &&
									(selectedDay?.forceLastNode ||
										selectedDay?.launchPoint !== null ||
										action?.forceLastNode) &&
									newNodeData.PlaceId !=
										selectedDay?.tripNodes[selectedDay?.tripNodes?.length - 1]
											?.PlaceId) ||
								newNodeData.PlaceId !== selectedDay?.tripNodes[0]?.PlaceId
							) {
								const storedLaunchPoint = selectedDay.launchPoint;
								const storedShelterPoint = selectedDay.shelterPoint;

								if (
									storedLaunchPoint?.PlaceId !== placeData?.ID ||
									(isLastDayDirect &&
										(selectedDay?.forceLastNode ||
											selectedDay?.launchPoint !== null ||
											action?.forceLastNode) &&
										storedShelterPoint?.PlaceId !== placeData?.ID)
								) {
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...selectedDay,
											...(isLastDayDirect &&
											(selectedDay?.forceLastNode ||
												selectedDay?.launchPoint !== null ||
												action?.forceLastNode)
												? { shelterPoint: newNodeData, Need: "shelter" }
												: {
														launchPoint: { ...newNodeData, Need: "lanchpoint" },
												  }),
										},
									};
									toast.success(
										isLastDayDirect &&
											(selectedDay?.forceLastNode ||
												selectedDay?.launchPoint !== null ||
												action?.forceLastNode)
											? `Place added to Itinerary as a Last Point in Day ${
													currentTripItinerary.selectedDay.index + 1
											  }`
											: `Place added to Itinerary as a Starting Point in Day ${
													currentTripItinerary.selectedDay.index + 1
											  }`,
										tostifyDefaultProps
									);
								}
							} else {
								toast.error(
									"Can't have two successive place in a day",
									tostifyDefaultProps
								);
							}
						} else {
							if (selectedDay.inEditing) {
								const tripNodes = selectedDay?.tripNodes;
								let isSuccessivePlace = false;

								if (
									!!tripNodes.length &&
									tripNodes[tripNodes.length - 1].PlaceId ===
										newNodeData.PlaceId
								) {
									isSuccessivePlace = true;
								}

								if (!isSuccessivePlace) {
									let globalDuration = 0;

									tripNodes.map((item) => {
										globalDuration = globalDuration + item?.Duration;
										return item;
									});

									if (globalDuration + 2 <= 24) {
										if (
											!(
												(tripNodes.length === 0 &&
													!isEmpty(selectedDay.launchPoint) &&
													newNodeData.PlaceId ===
														selectedDay?.launchPoint?.PlaceId) ||
												(!isEmpty(selectedDay.shelterPoint) &&
													newNodeData.PlaceId ===
														selectedDay?.shelterPoint?.PlaceId)
											)
										) {
											currentTripItinerary = {
												...currentTripItinerary,
												selectedDay: {
													...selectedDay,
													tripNodes: selectedDay?.tripNodes?.concat([
														{ ...newNodeData, Need: "experience" },
													]),
												},
											};
											toast.success(
												`Place added to Itinerary in Day ${
													currentTripItinerary.selectedDay.index + 1
												}`,
												tostifyDefaultProps
											);
										} else {
											toast.error(
												"Can't have two successive place in a day",
												tostifyDefaultProps
											);
										}
									} else {
										toast.error(
											"Can't add node to trip. Hours full",
											tostifyDefaultProps
										);
									}
								} else {
									toast.error(
										"Can't have two successive place in a day",
										tostifyDefaultProps
									);
								}
							} else {
								toast.info(
									textTranslated.noEditableDay[currentLanguage],
									tostifyDefaultProps
								);
								setTimeout(() => {
									action.callbackIfNoTrips();
								}, 500);
							}
						}
					} else {
						toast.info("Invalid need", tostifyDefaultProps);
					}
				} else {
					toast.info(
						textTranslated.noEditableDay[currentLanguage],
						tostifyDefaultProps
					);
					setTimeout(() => {
						action.callbackIfNoTrips();
					}, 500);
				}

				new_state = {
					...state,
					currentTripItinerary,
				};
			}

			localStorage.setItem(
				"currentTripItinerary",
				JSON.stringify(new_state.currentTripItinerary)
			);
			return new_state;

		case UPDATE_MIDDLE_NODES:
			let currentTripItinerary_ = state?.currentTripItinerary;
			let selectedDay = currentTripItinerary_?.selectedDay;
			if (
				JSON.stringify(action?.payload) ===
				JSON.stringify(currentTripItinerary_?.selectedDay?.tripNodes)
			)
				return state;
			if (selectedDay.inEditing) {
				currentTripItinerary_ = {
					...currentTripItinerary_,
					selectedDay: {
						...selectedDay,
						tripNodes: action?.payload,
					},
				};
			} else {
				toast.info(textTranslated.noEditableDay[currentLanguage], {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
			new_state = {
				...state,
				currentTripItinerary: currentTripItinerary_,
			};

			localStorage.setItem(
				"currentTripItinerary",
				JSON.stringify(new_state.currentTripItinerary)
			);
			return new_state;

		case REPLACE_SHELTER_NODE:
			if (typeof action.payload === "object" && !isEmpty(action.payload)) {
				const placeData = action?.payload;

				let currentTripItinerary = state?.currentTripItinerary;
				let selectedDay = currentTripItinerary?.selectedDay;

				if (
					selectedDay.index !== null &&
					typeof selectedDay.index === "number"
				) {
					const selectedFilter = state?.filtersSelected;

					if (!isEmpty(selectedFilter.needs)) {
						const newNodeData = {
							...placeData,
							needTag: selectedFilter?.needCategoryTag,
							Point: placeData?.Location
								? placeData?.Location
								: placeData?.Point,
							Duration: 2,
							Note: "",
							Need: "shelter",
							PlaceId: placeData?.ID ? placeData?.ID : placeData?.PlaceId,
						};
						const newTripNodes =
							currentTripItinerary.selectedDay.tripNodes.filter(
								(item) => item.PlaceId !== newNodeData.PlaceId
							);
						currentTripItinerary = {
							...currentTripItinerary,
							selectedDay: {
								...selectedDay,
								shelterPoint: null,
								tripNodes: newTripNodes,
							},
						};
						if (
							isEmpty(selectedDay.tripNodes) ||
							newNodeData.PlaceId !==
								selectedDay?.tripNodes[selectedDay?.tripNodes?.length - 1]
									?.PlaceId
						) {
							const storedShelterPoint = selectedDay.shelterPoint;

							if (storedShelterPoint?.PlaceId !== placeData?.ID) {
								currentTripItinerary = {
									...currentTripItinerary,
									selectedDay: {
										...selectedDay,
										shelterPoint: newNodeData,
									},
								};
								toast.success(
									`Place added to Itinerary as a Last Point in Day ${
										currentTripItinerary.selectedDay.index + 1
									}`,
									{
										position: "bottom-left",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
									}
								);
							}
						} else {
							toast.error("Can't have two successive place in a day", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
						}
					} else {
						toast.error("Invalid need", {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
				} else
					toast.error(textTranslated.noEditableDay[currentLanguage], {
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

				new_state = {
					...state,
					currentTripItinerary,
				};
			}

			localStorage.setItem(
				"currentTripItinerary",
				JSON.stringify(new_state.currentTripItinerary)
			);
			return new_state;

		case TOGGLE_SHELTER_NODE:
			if (state?.currentTripItinerary) {
				let currentTripItinerary = state?.currentTripItinerary;
				let selectedDay = currentTripItinerary?.selectedDay;
				const TRIP_NODES = currentTripItinerary?.selectedDay.tripNodes;
				const LAUNCH_POINT = currentTripItinerary?.selectedDay.launchPoint;
				const SHELTER_POINT = currentTripItinerary?.selectedDay.shelterPoint;
				const SELECTED_FILTER = state?.filtersSelected;

				if (SHELTER_POINT) {
					currentTripItinerary = {
						...currentTripItinerary,
						selectedDay: {
							...selectedDay,
							shelterPoint: null,
							tripNodes: [
								...TRIP_NODES,
								...(!currentTripItinerary.selectedDay.tripNodes.length &&
								LAUNCH_POINT?.PlaceId === SHELTER_POINT?.PlaceId
									? []
									: [
											{
												...SHELTER_POINT,
												needTag: SELECTED_FILTER?.needCategoryTag,
												Need: SELECTED_FILTER.needs,
											},
									  ]),
							],
						},
					};
				} else if (TRIP_NODES?.length) {
					currentTripItinerary = {
						...currentTripItinerary,
						selectedDay: {
							...selectedDay,
							shelterPoint: {
								...TRIP_NODES[TRIP_NODES.length - 1],
								Need: "shelter",
							},
							tripNodes: TRIP_NODES.slice(0, TRIP_NODES.length - 1),
						},
					};
				} else if (!SHELTER_POINT && LAUNCH_POINT) {
					currentTripItinerary = {
						...currentTripItinerary,
						selectedDay: {
							...selectedDay,
							shelterPoint: {
								...LAUNCH_POINT,
								Need: "shelter",
							},
						},
					};
				}

				new_state = {
					...state,
					currentTripItinerary,
				};
			}

			localStorage.setItem(
				"currentTripItinerary",
				JSON.stringify(new_state.currentTripItinerary)
			);
			return new_state;

		case REMOVE_NODE_TO_TRIP_ITINERARY:
			const nodeData = action?.payload;

			if (typeof nodeData === "object" && !isEmpty(nodeData)) {
				let currentTripItinerary = state?.currentTripItinerary;
				let selectedDay = currentTripItinerary?.selectedDay;

				if (
					selectedDay.index !== null &&
					typeof selectedDay.index === "number"
				) {
					if (!isEmpty(nodeData.Need)) {
						switch (nodeData.Need) {
							case "lanchpoint":
								const storedLaunchPoint = selectedDay.launchPoint;
								const storedShelterPoint_ = selectedDay.shelterPoint;
								let isDirectTrip = false;
								(currentTripItinerary?.data?.Attributes || []).map((item) => {
									const reg = new RegExp(/direct/, "ig");
									if (reg.test(item)) isDirectTrip = true;

									return item;
								});
								let isLastDayDirect =
									isDirectTrip &&
									selectedDay?.index ===
										currentTripItinerary?.daysList?.length - 1;
								if (
									storedLaunchPoint !== null &&
									storedLaunchPoint?.PlaceId === nodeData?.PlaceId
								) {
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...selectedDay,
											launchPoint: currentTripItinerary?.selectedDay?.tripNodes
												?.length
												? {
														...currentTripItinerary?.selectedDay?.tripNodes[0],
														Need: "lanchpoint",
												  }
												: null,
											tripNodes:
												currentTripItinerary?.selectedDay?.tripNodes.filter(
													(_item, _index) => _index !== 0
												),
										},
									};
									toast.info(
										textTranslated.onRemoveNode[currentLanguage],
										tostifyDefaultProps
									);
								} else if (
									isLastDayDirect &&
									storedShelterPoint_ !== null &&
									storedShelterPoint_?.PlaceId === nodeData?.PlaceId
								) {
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...selectedDay,
											shelterPoint: null,
										},
									};
									toast.info(
										textTranslated.onRemoveNode[currentLanguage],
										tostifyDefaultProps
									);
								}
								break;

							case "shelter":
								const storedShelterPoint = selectedDay.shelterPoint;

								if (
									storedShelterPoint !== null &&
									storedShelterPoint?.PlaceId === nodeData?.PlaceId
								) {
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...selectedDay,
											tripNodes: currentTripItinerary?.selectedDay?.tripNodes
												?.length
												? currentTripItinerary.selectedDay.tripNodes.slice(
														0,
														currentTripItinerary.selectedDay.tripNodes.length -
															1
												  )
												: [],
											shelterPoint: currentTripItinerary?.selectedDay?.tripNodes
												?.length
												? {
														...currentTripItinerary.selectedDay.tripNodes[
															currentTripItinerary.selectedDay.tripNodes
																.length - 1
														],
														Need: "shelter",
												  }
												: null,
										},
									};
									toast.info(textTranslated.onRemoveNode[currentLanguage], {
										position: "bottom-left",
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
									});
								}
								break;

							default:
								const tripNodes = selectedDay?.tripNodes;
								const idxNode = nodeData?.idxNode;

								let indexExist = false;

								tripNodes.map((item, idx) => {
									if (idx === idxNode) indexExist = true;
									return item;
								});

								if (indexExist) {
									currentTripItinerary = {
										...currentTripItinerary,
										selectedDay: {
											...selectedDay,
											tripNodes: tripNodes.filter(
												(_item, idx) => idx !== idxNode
											),
										},
									};
									toast.info(
										textTranslated.onRemoveNode[currentLanguage],
										tostifyDefaultProps
									);
								} else {
									toast.warn("Index node not found", tostifyDefaultProps);
								}
								break;
						}
					} else {
						toast.info("Invalid need", tostifyDefaultProps);
					}
				} else {
					toast.info("Please select a trip day", tostifyDefaultProps);
				}

				new_state = { ...state, currentTripItinerary };
			}

			localStorage.setItem(
				"currentTripItinerary",
				JSON.stringify(new_state.currentTripItinerary)
			);
			return new_state;

		case UPDATE_TRIP_ITINERARY_NODE_DURATION:
			if (
				typeof action.numberToUpdate === "number" &&
				typeof action.nodeIndex === "number"
			) {
				const tripNodes = state?.currentTripItinerary?.selectedDay?.tripNodes;
				const selectedNodeIdx = tripNodes?.findIndex(
					(item, idx) => idx === action.nodeIndex
				);

				if (selectedNodeIdx !== -1) {
					const selectedNode = tripNodes[selectedNodeIdx];

					let currentDuration = selectedNode.Duration;
					let globalDuration = 0;

					tripNodes.map((item) => {
						globalDuration = globalDuration + item.Duration;
						return item;
					});

					const newNodeDuration = currentDuration + action.numberToUpdate;
					const newTotalHours = globalDuration + action.numberToUpdate;

					if (newNodeDuration >= 1 && newNodeDuration <= 12) {
						if (newTotalHours >= 1 && newTotalHours <= 24) {
							selectedNode.Duration = currentDuration + action.numberToUpdate;

							new_state = {
								...state,
								currentTripItinerary: {
									...state.currentTripItinerary,
								},
							};
						} else {
							toast.warn("Global hours can't be updated", {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
						}
					} else {
						toast.warn("Hours can't be updated", {
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
				} else {
					toast.warn("Index don't exist", {
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			}
			return new_state;

		case UPDATE_INDEX_TRIP_DAY:
			const newIdxTripDay = action.payload;

			return initializeTripItineraryIndexDay(newIdxTripDay);

		case SET_TRIP_ITINERARY_VISIBILITY:
			const visibility = action.payload;
			if (typeof visibility === "boolean") {
				let currentTripItinerary = JSON.parse(
					JSON.stringify(state?.currentTripItinerary)
				);

				new_state = {
					...new_state,
					currentTripItinerary: {
						...currentTripItinerary,
						visibility,
					},
				};
			}
			return new_state;

		case SET_MAP_FILTERS:
			if (
				typeof action.payload === "object" &&
				Object.keys(action.payload).length
			)
				new_state = { ...state, filters: action.payload };

			return new_state;

		case SET_MAP_FILTERS_SELECTED:
			if (
				typeof action.payload === "object" &&
				Object.keys(action.payload).length
			)
				new_state = { ...state, filtersSelected: action.payload };

			return new_state;

		case SET_GLOBAL_WEATHER_DATE:
			if (typeof action.payload === "string" && action.payload?.length)
				new_state = {
					...state,
					globalWeatherDate: action.payload,
				};

			return new_state;

		case SET_GLOBAL_WEATHER_BRAND_VISIBILITY:
			if (typeof action.payload === "boolean")
				new_state = {
					...state,
					globalWeatherBrandVisibility: action.payload,
				};

			return new_state;

		case SET_WEATHER:
			if (
				typeof action.payload === "object" &&
				Object.keys(action.payload).length
			)
				new_state = { ...state, weather: action.payload };

			return new_state;

		case CLEAN_MAP:
			new_state = initialState;
			localStorage.removeItem("currentTripItinerary");

			return new_state;

		case USER_CLEAN:
			new_state = {
				...state,
				currentTripItinerary: initialState.currentTripItinerary,
			};

			return new_state;

		default:
			return state;
	}
}

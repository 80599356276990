import { ApiClient } from "../../../ApiClient";
import { gtag_report_conversion, types } from "../../../helpers/googleTags";
export const REGISTRED = "REGISTRED";
export const UNREGISTRED = "UNREGISTRED";
export const COASTVIEW = "COASTVIEW";
export const PLACE = "PLACE";

export const addBookmarks = (
  placeId,
  name,
  img,
  placeType,
  location,
  mode,
  type = PLACE
) => {
  gtag_report_conversion("https://" + window.location.host + "/explore/", types.ADDED_TO_TRIP);
  let currentList = window.localStorage.getItem("myBookmarks")
    ? JSON.parse(window.localStorage.getItem("myBookmarks"))
    : [];
  currentList.push({
    Timestamp: new Date(),
    Place: {
      Name: {
        Latin: name,
        Local: name,
      },
      Location: {
        Longitude: location[0],
        Latitude: location[1]
      },
      ID: placeId,
      Type: placeType,
      CoverImage: img,
    },
  });
  // Set the new localStorage
  window.localStorage.setItem("myBookmarks", JSON.stringify(currentList));
};

export const addListBookmarks = (e) => {
  var currentList = [];
    e.forEach((place) => {
        currentList.push({
            Timestamp: new Date(),
            Place: {
              Name: {
                Latin: place.Place.Name?.Latin,
                Local: place.Place.Name?.Local,
              },
              Location: {
                  Longitude: place.Place.Location[0],
                  Latitude: place.Place.Location[1]
              },
              ID: place.Place.ID,
              Type: place.Place.Type,
              CoverImage: place.Place.CoverImage,
            },
        });
    });
  window.localStorage.setItem("myBookmarks", JSON.stringify(currentList));
};

export const getBookmarks = () => {
  let currentList = window.localStorage.getItem("myBookmarks")
    ? JSON.parse(window.localStorage.getItem("myBookmarks"))
    : [];

  return currentList;
};

export const deleteBoomark = (placeId, mode) => {
  let currentList = window.localStorage.getItem("myBookmarks")
    ? JSON.parse(window.localStorage.getItem("myBookmarks"))
    : [];
  let updatedList = currentList.filter((place) => place.Place.ID !== placeId);
  // Set the new localStorage
  window.localStorage.setItem("myBookmarks", JSON.stringify(updatedList));
};

export const isBookmarked = (placeId, mode) => {
  const place = window.localStorage.getItem("myBookmarks")
    ? JSON.parse(window.localStorage.getItem("myBookmarks")).filter(
        (p) => p.Place.ID === placeId
      )
    : null;
  return place?.length > 0 ? true : false;
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	filterHighlightColor,
	formMainTxt,
	secondaryTxt,
	whiteColor,
} from "../../../constants/colors";
import { getDuration_ } from "../../PlacesDetailsSidebar/helpers/functions";
import { CardPlace } from "./FiledStart";
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages";

export const filterByRegex = (str, type) => {
	const regexes = {
		hours: /\d{1,}H/,
		minutes: /\d{1,}M/,
		seconds: /\d{1,}\.?\d{1,}S/,
	};
	return str?.match && str?.match(regexes[type])
		? Math.floor(parseInt(str.match(regexes[type])[0].slice(0, -1)))
		: 0;
};

// RETURNED IN UTC ms
export const startAt = (time) => {
	return new Date(
		new Date(
			filterByRegex(time, "hours") * 3600 * 1000 +
				filterByRegex(time, "minutes") * 60000 +
				filterByRegex(time, "seconds") * 1000
		)
			.toUTCString()
			.slice(0, -4)
	);
};

const CardNode = ({
	place,
	start,
	end,
	placeType,
	endNode = false,
	isCircular = false,
	canEdit,
	onOpenNoteEdit,
	openPlace = () => {},
}) => {
	return (
		<div
			className="ml-1 py-4"
			style={{
				borderLeft: "1px dashed " + secondaryTxt,
				paddingLeft: 64,
				position: "relative",
			}}
		>
			<div
				style={{
					position: "absolute",
					left: -5,
					top: 20,
					bottom: 20,
				}}
			>
				<div className="h-100 d-flex flex-column justify-content-between">
					<div className="d-flex flex-row align-items-center">
						{start !== end && (
							<>
								<div
									style={{
										height: 10,
										width: 10,
										borderRadius: "50%",
										backgroundColor: "#8f96a7",
									}}
								></div>
								<span
									className="ml-2"
									style={{ color: formMainTxt, fontSize: 12 }}
								>
									{new Date(start).getHours() >= 10
										? new Date(start).getHours()
										: `0${new Date(start).getHours()}`}
									:
									{new Date(start).getMinutes() >= 10
										? new Date(start).getMinutes()
										: `0${new Date(start).getMinutes()}`}
								</span>
							</>
						)}
					</div>
					<div className="d-flex flex-row align-items-center">
						{start !== end && (
							<>
								<div
									style={{
										height: 10,
										width: 10,
										borderRadius: "50%",
										backgroundColor: "#8f96a7",
									}}
								></div>
								<span
									className="ml-2"
									style={{ color: formMainTxt, fontSize: 12 }}
								>
									{new Date(end).getHours() >= 10
										? new Date(end).getHours()
										: `0${new Date(end).getHours()}`}
									:
									{new Date(end).getMinutes() >= 10
										? new Date(end).getMinutes()
										: `0${new Date(end).getMinutes()}`}
								</span>
							</>
						)}
					</div>
				</div>
			</div>
			<CardPlace
				end={endNode}
				isCircular={isCircular}
				place={place}
				placeType={placeType}
				edited
				onClickNote={onOpenNoteEdit}
				canEdit={canEdit}
				noEditMode
				openPlace={openPlace}
			/>
		</div>
	);
};

const Underway = (props) => {
	const { data, speed = 0, fuel } = props;
	const [duration_, setDuration_] = useState("");
	const currentLanguage = useSelector(currentLang);
	useEffect(() => {
		let date_ =
			filterByRegex(data.Duration, "hours") * 3600 * 1000 +
			filterByRegex(data.Duration, "minutes") * 60000 +
			filterByRegex(data.Duration, "seconds") * 1000;
		let durationString = getDuration_(date_);
		setDuration_(durationString);
	}, [data]);
	return (
		<div className="pl-0 py-0 my-0 d-flex justify-content-between flex-row align-items-center">
			<div className="pl-0 py-0 my-0 d-flex flex-row align-items-center">
				<span
					className="mb-0"
					style={{
						border: "solid 1px #8f96a7",
						height: 10,
						width: 10,
						borderRadius: 5,
					}}
				></span>
				<span
					className="dromon-l mb-0 ml-3"
					style={{ color: secondaryTxt, fontSize: 12 }}
				>
					{/* Underway */}
					{languages[currentLanguage]["trip_itinerary_calc_underway_label"]}
				</span>
				<span
					className="mb-0 ml-3"
					style={{ color: formMainTxt, fontSize: 12 }}
				>
					{`${duration_} (${Math.round(data?.Distance)} nm @ ${
						!!speed && speed !== undefined ? speed : 0
					} kts)`}
					{fuel && speed
						? ` - ${
								languages[currentLanguage]["profile_settings_units_fuel"]
						  } ${Math.round((fuel / speed) * data?.Distance)} lt`
						: ""}
				</span>
			</div>
		</div>
	);
};

const NoEditDayState = ({
	nodes,
	speed,
	fuel,
	StartTime,
	data,
	canEdit = false,
	durationDays,
	attributes = [],
	indexDay,
	openPlace,
	onOpenNoteEdit = () => {},
}) => {
	const [startEnd, setStartEnd] = useState([]);
	const [isCircular, setIsCircular] = useState(false);

	useEffect(() => {
		// //console.log("SEE PROPS ==.", durationDays, attributes);
		setIsCircular(attributes.includes("uri:trip:attr:direction:circular"));
	}, [durationDays, attributes]);
	useEffect(() => {
		// LOGIC TO SET CALCULATIONS
		const durations = [
			{
				start: startAt(StartTime).getTime(),
				end: startAt(StartTime).getTime(),
			},
		];
		let sum_ = startAt(StartTime).getTime();
		for (let i = 1; i < nodes.length - 1; i++) {
			durations.push({
				start: sum_,
				end:
					Math.round(
						filterByRegex(nodes[i].Duration, "hours") * 3600 * 1000 +
							filterByRegex(nodes[i].Duration, "minutes") * 60000 +
							filterByRegex(nodes[i].Duration, "seconds") * 1000
					) + sum_,
			});
			sum_ =
				Math.round(
					filterByRegex(nodes[i].Duration, "hours") * 3600 * 1000 +
						filterByRegex(nodes[i].Duration, "minutes") * 60000 +
						filterByRegex(nodes[i].Duration, "seconds") * 1000
				) + sum_;
		}
		// SET THE LAST NODE'S DURATION
		durations.push({
			start: sum_,
			end: sum_,
		});
		// //console.log("OUR DURATIONS ==>", durations);
		setStartEnd(durations);
	}, [nodes, data, StartTime]);
	return (
		<div>
			<div
				className="pb-4 ml-1"
				style={{ borderLeft: "1px dashed " + secondaryTxt }}
			>
				<div
					className="d-flex flex-row align-items-center"
					style={{ marginLeft: -11 }}
				>
					<i
						style={{
							color: filterHighlightColor,
							fontSize: 23,
							backgroundColor: whiteColor,
						}}
						className="fas fa-stopwatch mr-2"
					></i>
					<span
						style={{ color: filterHighlightColor, fontSize: 16 }}
						className="mr-3"
					>
						{startAt(StartTime).getHours() >= 10
							? startAt(StartTime).getHours()
							: `0${startAt(StartTime).getHours()}`}
						:
						{startAt(StartTime).getMinutes() >= 10
							? startAt(StartTime).getMinutes()
							: `0${startAt(StartTime).getMinutes()}`}
					</span>
					<span style={{ color: formMainTxt, fontSize: 14 }} className="mr-3">
						Start : {nodes[0]?.Place?.Name}
					</span>
				</div>
			</div>
			{nodes?.map((node, idx) => (
				<span key={idx}>
					{node.Type === "sail" ? (
						<Underway data={node} speed={speed} fuel={fuel} />
					) : (
						<CardNode
							key={idx}
							place={node.Place}
							placeType={node.Need}
							start={startEnd[idx]?.start}
							end={startEnd[idx]?.end}
							onOpenNoteEdit={() => onOpenNoteEdit(idx, node.Note, node.Order)}
							canEdit={canEdit}
							endNode={durationDays === indexDay + 1}
							isCircular={isCircular}
							openPlace={() => openPlace(node.Place.Id)}
						/>
					)}
				</span>
			))}
			<div className="pl-0 pb-2 pt-0 my-0 d-flex flex-row justify-content-between align-items-center">
				<div className="d-flex flex-row align-items-center">
					<span
						className="mb-0"
						style={{
							// border: "solid 1px #8f96a7",
							height: 10,
							backgroundColor: secondaryTxt,
							width: 10,
							borderRadius: 5,
						}}
					></span>
					<span
						className="ml-2 mb-0"
						style={{ color: formMainTxt, fontSize: 12 }}
					>
						{new Date(startEnd[startEnd?.length - 1]?.end).getHours() >= 10
							? new Date(startEnd[startEnd?.length - 1]?.end).getHours()
							: `0${new Date(startEnd[startEnd?.length - 1]?.end).getHours()}`}
						:
						{new Date(startEnd[startEnd?.length - 1]?.end).getMinutes() >= 10
							? new Date(startEnd[startEnd?.length - 1]?.end).getMinutes()
							: `0${new Date(
									startEnd[startEnd?.length - 1]?.end
							  ).getMinutes()}`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default NoEditDayState;

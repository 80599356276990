export const types = {
	FILTER_INITIATE: "FILTER_INITIATE",
	GO_TO_MAP: "GO_TO_MAP",
	PLACE_VIEWED: "PLACE_VIEWED",
	ADDED_TO_TRIP: "ADDED_TO_TRIP",
	SHARE_INITIATED: "SHARE_INITIATED",
	SEARCH_PERFORMED: "SEARCH_PERFORMED",
	TRIP_LIST_VIEWED: "TRIP_LIST_VIEWED",
	LOCATION_VIEWED: "LOCATION_VIEWED",
	ACCOUNT_CREATION_INITIATED: "ACCOUNT_CREATION_INITIATED",
	ACCOUNT_CREATED: "ACCOUNT_CREATED",
	LOGGED_ON: "LOGGED_ON",
	INSPIRED_CLICKED: "INSPIRED_CLICKED",
	COAST_VIEW_IMAGE_SHOWN: "COAST_VIEW_IMAGE_SHOWN",
};

export const gtag_report_conversion = (url, action) => {
	var sendTo = "";
	var gtm_event_name = "";
	switch (action) {
		case types.FILTER_INITIATE:
			sendTo = "tbWECKeN9ssCEIOvpPsC";
			gtm_event_name = "filter_initiate";
			break;
		case types.GO_TO_MAP:
			sendTo = "U4X1CNCam8wCEIOvpPsC";
			gtm_event_name = "";
			break;
		case types.PLACE_VIEWED:
			sendTo = "B646CJWhncwCEIOvpPsC";
			gtm_event_name = "place_viewed";
			break;
		case types.ADDED_TO_TRIP:
			sendTo = "1QTKCMOo-MsCEIOvpPsC";
			gtm_event_name = "added_to_trip";
			break;
		case types.SHARE_INITIATED:
			sendTo = "JoymCOnRncwCEIOvpPsC";
			gtm_event_name = "share_initiated";
			break;
		case types.SEARCH_PERFORMED:
			sendTo = "JM4xCNmP-csCEIOvpPsC";
			gtm_event_name = "search_performed";
			break;
		case types.TRIP_LIST_VIEWED:
			sendTo = "XNvPCMuonswCEIOvpPsC";
			gtm_event_name = "trip_list_viewed";
			break;
		case types.LOCATION_VIEWED:
			sendTo = "C5UyCO7RnswCEIOvpPsC";
			gtm_event_name = "location_viewed";
			break;
		case types.ACCOUNT_CREATION_INITIATED:
			sendTo = "z7PNCOzYnswCEIOvpPsC";
			gtm_event_name = "account_creation_initiated";
			break;
		case types.ACCOUNT_CREATED:
			sendTo = "ervVCJ7An8wCEIOvpPsC";
			gtm_event_name = "account_created";
			break;
		case types.LOGGED_ON:
			sendTo = "632RCNre-csCEIOvpPsC";
			gtm_event_name = "logged_on";
			break;
		case types.INSPIRED_CLICKED:
			sendTo = "afwYCOvan8wCEIOvpPsC";
			gtm_event_name = "get_inspired_clicked";
			break;
		case types.COAST_VIEW_IMAGE_SHOWN:
			gtm_event_name = "coast_view_image_shown";
			break;
		default:
			sendTo = "tbWECKeN9ssCEIOvpPsC";
			gtm_event_name = "";
			break;
	}
	const callback = () => {
		if (typeof url !== "undefined") {
			// window.location = url;
		}
	};
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ event: gtm_event_name });
	window.gtag("event", "conversion", {
		send_to: "AW-795416451/" + sendTo,
		event_callback: callback,
	});
	return false;
};

import React from "react";

const MultiselectCheck = (props) => {
  return (
    <>
      <div
        onClick={props.handleSelect}
        className="d-flex flex-row justify-content-between align-items-center px-3 my-3"
        style={{
          border: "solid 2px #dbdee6",
          borderRadius:10,
          height: 57,
          width: 384,
          backgroundColor: "rgba(221, 222, 222, 0)",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: 300,
            color: "#222c4b",
          }}
          className="dromon-l"
        >
          {props.text}
        </span>
        {props.selected ? (
          <img
            src={require("../../../assets/svg/filters_icons/tick_ic.svg")}
            style={{ width: 26, height: 26 }}
          />
        ) : (
          <div
            style={{
              height: 26,
              width: 26,
              borderRadius: 14,
              border: "solid 2px #dbdee6",
            }}
          ></div>
        )}
      </div>
    </>
  );
};

export default MultiselectCheck;

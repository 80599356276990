import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import {
	bgActiveItem,
	bgInactiveItem,
	colorInactiveItem,
	filterHighlightColor,
	whiteColor,
} from "../../constants/colors";

const CardMultiSelect = (props) => {
	return (
		<Button
			onClick={() => {
				if (!props.disable) {
					props.onClick()
				}
			}}
			className="dromon-l"
			style={{
				backgroundColor: props.active
					? "#e5f1ff"
					: props.disable
						? whiteColor
						: "#f6f6f6",
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				fontSize: 12,
				fontWeight: "300",
				paddingTop: 3,
				paddingBottom: 3,
				paddingLeft: 10,
				paddingRight: 8,
				marginBottom: 8,
				borderRadius: 5,
				marginRight: 8,
				border: 'none',
			}}
		>
			{props.active ? <img
				src={require("../../assets/svg/filters_icons/tick_ic.svg")}
				style={{ width: 14, height: 14 }}
			/>
				: props.disable ? <></>

				: <img
					src={require("../../assets/svg/filters_icons/add_ic.svg")}
					style={{ width: 10, height: 10, margin:"0 2px"}}
				/>}
			<span
				style={{
					textTransform: "lowercase",
					color: props.active ? "#0078ff" : props.disable ? "#8f96A7" : "#222c4b",
					marginLeft: 10,
					fontSize: 12,
					fontWeight: "300",
					fontFamily: "Dromon-light"
				}}
			>
				{props.label}
			</span>
		</Button>
	);
};

CardMultiSelect.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	active: PropTypes.bool,
	disable: PropTypes.bool,
};

export default CardMultiSelect;

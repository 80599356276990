import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import IconButton from "@material-ui/core/IconButton";

// CONSTANTS
import { filterHighlightColor, formMainTxt } from "../../../constants/colors";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";

// COMPONENTS
import Button from "../Components/Button";

// SVG ICONS
import checkIcon from "../../../assets/Check.svg";
import minus from "../../../assets/svg/itinerary/minus-icon.svg";
import plus from "../../../assets/svg/itinerary/plus-icon.svg";

// STYLES
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export const getNumberOfDays = (start, end) => {
	const date1 = new Date(start);
	const date2 = new Date(end);
	const oneDay = 1000 * 60 * 60 * 24;
	const diffInTime = date2.getTime() - date1.getTime();
	const diffInDays = Math.round(diffInTime / oneDay);

	return diffInDays;
};

const Second = (props) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	const [canEdit, setCanEdit] = useState(true);
	// STATES
	const [checked, setChecked] = useState(false);
	const [data, setData] = useState({
		numberOfDays: props.state?.DurationDays ? props.state?.DurationDays : 0,
	});
	const [state, setState] = useState([
		{
			startDate: props.state?.StartDate
				? new Date(props.state?.StartDate)
				: new Date(),
			endDate: props.state?.DurationDays
				? new Date(
						new Date(props.state?.StartDate).getTime() +
							(props.state?.DurationDays - 1) * 24 * 3600 * 1000
				  )
				: new Date(),
			key: "selection",
		},
	]);
	const [duration, setDuration] = useState(1);

	// DATA
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};

	// FUNCTIONS
	const handleBack = () => {
		props.handleBack({
			step: 2,
		});
	};

	const handleNext = () => {
		logEvent_("react_tripwiz_stepdays_nextbtn_pressed");

		const nbDays = getNumberOfDays(state[0].startDate, state[0].endDate) + 1;

		props.handleNext({
			step: 2,
			startDate: !checked ? state[0].startDate : null,
			numberOfDays: duration,
		});
	};

	const increaseDuration = () => {
		const days = duration + 1;
		setDuration(days);
	};

	const decreaseDuration = () => {
		const days = duration - 1;
		setDuration(days > 0 ? days : 0);
	};

	const setCustomDuration = (e) => {
		const val = parseInt(e);
		setDuration(val >= 0 ? val : 0);
	};

	// EFFECTS
	useEffect(() => {
		if (props.state?.DurationDays) {
			setDuration(props.state?.DurationDays ? props.state?.DurationDays : 0);
		}
		setState([
			{
				startDate: props.state?.StartDate
					? new Date(props.state?.StartDate)
					: new Date(),
				endDate:
					props.state?.DurationDays && props.state?.StartDate
						? new Date(
								new Date(props.state?.StartDate).getTime() +
									(props.state?.DurationDays - 1) * 24 * 3600 * 1000
						  )
						: props.state?.DurationDays
						? new Date(
								new Date().getTime() +
									(props.state?.DurationDays - 1) * 24 * 3600 * 1000
						  )
						: new Date(),
				key: "selection",
			},
		]);
		setChecked(props.state?.StartDate === null ? true : false);
	}, [props.state, props.finished]);

	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<div className="mb-4">
						<h3
							className="mb-2 dromon-l"
							style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
						>
							{props.state?.Type === "business" &&
							props.state?.State !== "template"
								? languages[currentLanguage]["trip_create_new_business_trip"]
								: languages[currentLanguage]["trip_create_new_template_trip"]}
						</h3>
						<h2 style={{ fontSize: 22, color: "#222c4b" }}>
							{props.state?.Type === "business" &&
							props.state?.State !== "template"
								? languages[currentLanguage]["trip_date_header"]
								: languages[currentLanguage]["trip_wizard_template_duration"]}
						</h2>
					</div>

					<div
						className="d-flex align-items-center justify-content-around"
						style={{ flex: 1 }}
					>
						{canEdit && (
							<IconButton
								onClick={() => {
									decreaseDuration();
								}}
								className="d-flex justify-content-center align-items-center p-0 m-0 rounded-circle"
								style={{
									height: 25,
									width: 25,
									border: "2px solid #dbdee6",
									cursor: "pointer",
									transform: "scale(1.5)",
								}}
							>
								<img src={minus} alt="" style={{ height: 2, width: 20 }} />
							</IconButton>
						)}

						<div className="d-flex align-items-center">
							<input
								className="d-inline form-control text-center border-0 p-0 m-0"
								type="number"
								value={duration}
								onChange={(e) => setCustomDuration(e.target.value)}
								style={{
									borderBottom: "1px solid",
									fontSize: 50,
									color: "#222c4b",
									maxWidth: 80,
									background: "transparent",
								}}
								max={99}
								maxLength={2}
								min={1}
								minLength={1}
								disabled
							/>

							<span
								className="dromon-l small text-muted"
								style={{
									width: 15,
									fontSize: 17,
									color: formMainTxt,
									marginTop: 12,
								}}
							>
								{languages[currentLanguage]["trip_wizard_days"]}
							</span>
						</div>
						{/*  */}

						{canEdit && (
							<IconButton
								onClick={() => {
									increaseDuration();
								}}
								className="d-flex justify-content-center align-items-center p-0 m-0 rounded-circle"
								style={{
									height: 25,
									width: 25,
									border: "2px solid #dbdee6",
									cursor: "pointer",
									transform: "scale(1.5)",
								}}
							>
								<img src={plus} alt="" style={{ height: 10, width: 10 }} />
							</IconButton>
						)}
					</div>

					{props.state?.Type === "business" &&
						props.state?.State !== "template" && (
							<div className="d-flex flex-column align-items-center mb-4">
								<DateRangePicker
									onChange={(item) => {
										const selectedDate =
											item.selection?.startDate || item.selection?.endDate;
										setState([
											{
												...item.selection,
												endDate: new Date(
													new Date(selectedDate).getTime() +
														(duration - 1) * 24 * 3600 * 1000
												),
											},
										]);
									}}
									showSelectionPreview={true}
									moveRangeOnFirstSelection={false}
									ranges={state}
									direction="horizontal"
									minDate={new Date()}
									className="mb-3"
								/>

								{/*
							<div className="d-flex align-items-center">
								<div
									onClick={() =>
										getNumberOfDays(state[0].startDate, state[0].endDate) !== 0
											? setChecked(!checked)
											: {}
									}
									className="d-flex justify-content-center align-items-center p-1 mr-2"
									style={{
										height: 25,
										width: 25,
										borderRadius: 4,
										backgroundColor: checked ? "transparent" : "#dbdee6",
										border: "1px solid #dbdee6",
										cursor: "pointer",
									}}
								>
									{checked && <img src={checkIcon} alt="Accept" />}
								</div>
								<span
									className="dromon-l"
									style={{ fontSize: 12, color: formMainTxt }}
								>
									{languages[currentLanguage]["trip_date_exclude_checkbox"]}
								</span>
							</div>
                            */}
							</div>
						)}
				</div>

				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Second);

import { deleteToken } from "firebase/messaging";
import { isEmpty } from "../../../helpers/functions";
import { CometChat } from "@cometchat-pro/chat";

// TYPES
import {
	USER_SET,
	USER_SET_PROFILE_ID,
	USER_SET_DATA,
	USER_SET_SETTINGS,
	USER_SET_VESSELS,
	USER_AUTH_VIEW,
	USER_SIDEBAR_PROFILE,
	USER_SIDEBAR_SETTINGS,
	USER_SIDEBAR_VESSELS,
	USER_SIDEBAR_PARTNER_PROVISION,
	USER_CLOSE_SIDEBARS,
	USER_CLEAN,
	USER_SIDEBAR_EDIT,
} from "./types";

// STATE
export const initialSidebarsState = {
	authView: false,
	sidebarProfile: false,
	sidebarEdit: false,
	sidebarSettings: false,
	sidebarVessels: false,
	sidebarPartnerProvision: false,
};

export const initialState = {
	profileId: null,
	data: {},
	...initialSidebarsState,
};

// REDUCER
export default function userReducer(state = initialState, action) {
	let new_state = state;

	switch (action.type) {
		case USER_SET:
			if (!isEmpty(action.payload)) new_state = action.payload;

			break;

		case USER_SET_PROFILE_ID:
			if (!isEmpty(action.payload) || action.payload === null)
				new_state = {
					...state,
					profileId: action.payload,
				};

			break;

		case USER_SET_DATA:
			if (!isEmpty(action.payload))
				new_state = {
					...state,
					data: {
						...state.data,
						...action.payload,
					},
				};

			break;

		case USER_SET_SETTINGS:
			if (!isEmpty(action.payload))
				new_state = {
					...state,
					data: {
						...state.data,
						Settings: {
							...state.data.Settings,
							...action.payload,
						},
					},
				};

			break;

		case USER_SET_VESSELS:
			if (!isEmpty(action.payload))
				new_state = {
					...state,
					data: {
						...state.data,
						Vessels: {
							...state.data.Vessels,
							...action.payload,
						},
					},
				};

			break;

		case USER_AUTH_VIEW:
			new_state = {
				...state,
				...initialSidebarsState,
				authView: true,
			};
			break;

		case USER_SIDEBAR_PROFILE:
			new_state = {
				...state,
				...initialSidebarsState,
				sidebarProfile: true,
			};
			break;

		case USER_SIDEBAR_EDIT:
			new_state = {
				...state,
				...initialSidebarsState,
				sidebarEdit: true,
			};
			break;

		case USER_SIDEBAR_SETTINGS:
			new_state = {
				...state,
				...initialSidebarsState,
				sidebarSettings: true,
			};
			break;

		case USER_SIDEBAR_VESSELS:
			new_state = {
				...state,
				...initialSidebarsState,
				sidebarVessels: true,
			};
			break;

		case USER_SIDEBAR_PARTNER_PROVISION:
			new_state = {
				...state,
				...initialSidebarsState,
				sidebarPartnerProvision: true,
			};
			break;

		case USER_CLOSE_SIDEBARS:
			new_state = {
				...state,
				...initialSidebarsState,
			};
			break;

		case USER_CLEAN:
			// LOGOUT COMETCHAT
			try {
				new_state = initialState;
				CometChat.logout().then(() => "LOGGED OUT");
				//if (deleteToken) deleteToken();
			} catch (error) {
				console.warn(error);
			}
			break;

		default:
			break;
	}

	//setLocalUser(new_state);
	return new_state;
}

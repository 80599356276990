import React from "react";
import { connect } from "react-redux";
import { IconButton, Avatar, TextField, MenuItem } from "@material-ui/core";

// CONSTANTS
import { secondaryTxt, formMainTxt } from "../../../constants/colors";

// SELECTORS
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// ICONS
import CrewItemIcon from "../../../assets/svg/crewList/crewitem_ic.svg";

function CollapseCardHeader({
	currentLang = "EN",
	member = {},
	inAdding = false,
	inEditing = null,
	state = {},
	role = "no-member",
	setState = () => {},
	onClickUserProfile = () => {},
}) {
	// DATA
	let inEdition = inAdding || inEditing;
	let title = `${member.Name || ""} ${member.Surname || ""}`;
	let editionTitle = `${state.Name || ""} ${state.Surname || ""}`;
	let formattedTitle = !inEdition ? title : editionTitle;

	return (
		<>
			<IconButton
				onClick={(e) => {
					e.stopPropagation();
					onClickUserProfile();
				}}
				className="px-0 py-0 mr-3"
				title={title}
				style={{
					backgroundColor: "#DBDEE6",
				}}
			>
				<Avatar
					{...{
						...(!(isEmpty(formattedTitle[0]) || inAdding)
							? { children: formattedTitle[0] }
							: {}),
					}}
					alt={formattedTitle}
					src={
						!(isEmpty(member.Photo) || isEmpty(formattedTitle[0]) || inAdding)
							? member?.Photo
							: CrewItemIcon
					}
					className="m-2"
					style={{
						color: "#DBDEE6",
						width: 27,
						height: 27,
						userSelect: "none",
						pointerEvents: "none",
					}}
				/>
			</IconButton>
			<div
				style={{ flex: 1 }}
				className="d-flex flex-column justify-content-center h-100"
			>
				{!inEdition ? (
					<>
						<h2
							className="text-capitalize dromon-l mb-0 pb-0"
							style={{
								color: formMainTxt,
								fontSize: 16,
							}}
						>
							{title}{" "}
							{member.MemberOf === "charter" ? "(" + member.MemberOf + ")" : ""}
						</h2>

						<span
							className="text-capitalize dromon-l mb-0 pb-0"
							style={{ fontSize: 14, color: secondaryTxt }}
						>
							{member.Type}
						</span>
					</>
				) : (
					<>
						<div className="row">
							<div className="col-6 mb-2">
								<TextField
									label="Name"
									variant="outlined"
									value={state.Name}
									onChange={(event) =>
										setState({
											...state,
											Name: event.target.value,
										})
									}
								/>
							</div>
							{/* /.col-6 */}

							<div className="col-6 mb-2">
								<TextField
									label="Surname"
									variant="outlined"
									value={state.Surname}
									onChange={(event) =>
										setState({
											...state,
											Surname: event.target.value,
										})
									}
								/>
							</div>
							{/* /.col-6 */}

							{role === "creator" && inAdding && (
								<div className="col-6 mb-2">
									<TextField
										select
										label="Type"
										value={state.MemberOf}
										className="w-100"
										onChange={(event) => {
											console.log(event.target.value);

											setState({
												...state,
												MemberOf: event.target.value,
											});
										}}
										variant="outlined"
										disabled={!inAdding}
										required={true}
									>
										{state.CanAddCharter && (
											<MenuItem value="charter">
												{
													languages[currentLang][
														"trip_crew_list_item_type_crew"
													]
												}
											</MenuItem>
										)}

										{state.CanAddClient && (
											<MenuItem value="client">
												{
													languages[currentLang][
														"trip_crew_list_item_type_guest"
													]
												}
											</MenuItem>
										)}
									</TextField>
								</div>
							)}

							<div className="col-6 mb-2">
								<TextField
									select
									label="Role"
									value={state.Type}
									className="w-100"
									onChange={(event) =>
										setState({
											...state,
											Type: event.target.value,
										})
									}
									variant="outlined"
									disabled={!inAdding}
									required={true}
								>
									<MenuItem value="passenger">Passenger</MenuItem>
									{(role === "creator" ||
										role === "cocreator" ||
										role === "superpassenger") && (
										<MenuItem value="skipper">Skipper</MenuItem>
									)}
								</TextField>
							</div>
							{/* /.col-6 */}
						</div>
					</>
				)}
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		currentLang: currentLang(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseCardHeader);

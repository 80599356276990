import React from "react";
import { IconButton, TextField } from "@material-ui/core";

// CONSTANTS
import { borderColor } from "../../../constants/colors";

// SVG ICONS
import SvgMinus from "../../../assets/svg/itinerary/minus-icon.svg";
import SvgPlus from "../../../assets/svg/itinerary/plus-icon.svg";

function IncreaseDecrease({
	props = {},
	increaseDecreaseContainerProps = {},
	textInputProps = {},
	increaseProps = {},
	decreaseProps = {},
	customBodyComponent,
	onIncrease = () => {},
	onDecrease = () => {},
    isCompleted = false
}) {
	return (
		<div
			{...{
				...props,
				className: `d-flex align-items-stretch ${
					props?.className ? props?.className : ""
				}`,
			}}
		>
            { !isCompleted && (
			<div
				{...{
					...increaseDecreaseContainerProps,
					className: `d-flex flex-column justify-content-between align-items-center mr-2 ${
						props?.increaseDecreaseContainerProps
							? props?.increaseDecreaseContainerProps
							: ""
					}`,
				}}
			>
                
				<IconButton
					{...{
						onClick: onIncrease,
						...increaseProps,
					}}
					style={{
						height: 22,
						width: 22,
						border: `2px solid ${borderColor}`,
					}}
					className="d-flex justify-content-center align-items-center p-0 m-0 rounded-circle"
				>
					<img src={SvgPlus} alt="plus" style={{ height: 8, width: 8 }} />
				</IconButton>

				<IconButton
					{...{
						onClick: onDecrease,
						...decreaseProps,
					}}
					className="d-flex justify-content-center align-items-center p-0 m-0 rounded-circle"
					style={{
						height: 22,
						width: 22,
						border: `2px solid ${borderColor}`,
					}}
				>
					<img src={SvgMinus} alt="minus" style={{ height: 2, width: 8 }} />
				</IconButton>
                    
			</div>
)}
			{customBodyComponent ? (
				customBodyComponent
			) : (
				<TextField
					inputProps={{ maxlength: 3 }}
					{...{
						type: "number",
						variant: "outlined",
						...textInputProps,
						style: {
							fontFamily: "Dromon-light !important",
							width: "100%",
							color: borderColor,
							flex: 1,
							...(textInputProps?.style ? textInputProps.style : {}),
						},
					}}
                    disabled={isCompleted}
				/>
			)}
		</div>
	);
}

export default IncreaseDecrease;

import React from "react";
import { connect } from "react-redux";
import {
	Checkbox,
	Button,
	TextField,
	MenuItem,
	IconButton,
	InputLabel,
	withStyles,
	FormControlLabel,
} from "@material-ui/core";
import dateFormat from "date-fns/format";
import { KeyboardDatePicker } from "@material-ui/pickers";

// SELECTORS
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages";

// CONSTANTS
import {
	colorInactiveItem,
	filterHighlightColor,
	deleteColor,
} from "../../../constants/colors";

// HELPERS
import { isEmpty } from "../../../helpers/functions";

// SVG ICONS
import CloseIcon from "../../../assets/close-btn.svg";

const CollapseCardBody = ({
	currentLang = "EN",
	member = {},
	inAdding = false,
	inEditing = null,
	isLoadingSave = false,
	canEdit = false,
	state = {},
	setState = () => {},
	onEdit = () => {},
	onCancel = () => {},
	onDelete = () => {},
	onSave = () => {},
}) => {
	// LOCAL COMPONENTS
	const CustomCheckbox = withStyles({
		root: {
			color: "#0078ff",
			"&$checked": {
				color: "#0078ff",
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	// DATA
	const iso3311a2 = require("iso-3166-1-alpha-2");
	const AllCountries = require("country-telephone-data").allCountries.sort(
		(a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		}
	);

	return (
		<div style={{ flex: 1 }} className="px-2 mx-0">
			{!(inAdding || inEditing) && (
				<>
					<div className="row">
						{!isEmpty(member.DateOfBirth) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Date of birth:
								</InputLabel>
								{dateFormat(new Date(member.DateOfBirth), "yyyy-MM-dd")}
							</div>
						)}

						{!isEmpty(member.Nationality) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Nationality:
								</InputLabel>
								{member.Nationality}
							</div>
						)}

						{!isEmpty(member.EmbarkDate) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									{languages[currentLang]["trip_crew_embark_title"]}:
								</InputLabel>
								{dateFormat(new Date(member.EmbarkDate), "yyyy-MM-dd")}
							</div>
						)}
						{!isEmpty(member.DisembarkDate) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									{languages[currentLang]["trip_crew_disembark_title"]}:
								</InputLabel>
								{dateFormat(new Date(member.DisembarkDate), "yyyy-MM-dd")}
							</div>
						)}

						{!isEmpty(member.PassportId || member.IdentityId) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									{member.PassportId ? "Passport:" : "Identity ID:"}
								</InputLabel>
								{member.PassportId || member.IdentityId}
							</div>
						)}

						{!isEmpty(member.ContactNumber) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Contact Number:
								</InputLabel>
								{member.ContactNumber}
							</div>
						)}

						{member.Type === "skipper" && (
							<>
								{!isEmpty(member.SkipperLicenseId) && (
									<div className="col-6 d-flex flex-column justify-content-center mb-4">
										<InputLabel className="mb-0" style={{ fontSize: 12 }}>
											Skipper License ID:
										</InputLabel>
										{member.SkipperLicenseId}
									</div>
								)}
								{!isEmpty(member.SkipperLicenseUrl) && (
									<div className="col-6 d-flex flex-column justify-content-center mb-4">
										<InputLabel className="mb-0" style={{ fontSize: 12 }}>
											Skipper License URL:
										</InputLabel>
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={member.SkipperLicenseUrl}
										>
											{"Download License"}
										</a>
									</div>
								)}
							</>
						)}
					</div>

					{canEdit && (
						<div className="d-flex justify-content-end">
							<Button
								onClick={onEdit}
								className=""
								style={{
									fontFamily: "inherit",
									textTransform: "initial",
									fontSize: 16,
									color: filterHighlightColor,
								}}
							>
								{languages[currentLang]["profile_vessel_edit"]}
							</Button>
						</div>
					)}
				</>
			)}

			{(inAdding || inEditing) && (
				<>
					<form className="row" autoComplete="off">
						<div className="col-6 mb-4">
							<label style={{ fontSize: 13 }}>Date Of Birth:</label>
							<KeyboardDatePicker
								clearable="true"
								value={state.DateOfBirth}
								placeholder="10/10/2018"
								onChange={(selectedDate) => {
									try {
										setState({
											...state,
											DateOfBirth: dateFormat(
												new Date(selectedDate),
												"yyyy-MM-dd"
											),
										});
									} catch (e) {
										setState({
											...state,
											DateOfBirth: null,
										});
									}
								}}
								// minDate={new Date()}
								format="MM/dd/yyyy"
								className="my-0"
								autoOk={true}
								style={{
									fontFamily: "Dromon-light",
									width: "100%",
								}}
								inputVariant="outlined"
								variant="inline"
							/>
						</div>

						<div className="col-6 mb-4">
							<label style={{ fontSize: 13 }}>Nationality:</label>
							<TextField
								select
								value={state.Nationality}
								className="w-100"
								onChange={(event) =>
									setState({
										...state,
										Nationality: event.target.value,
									})
								}
								variant="outlined"
							>
								<MenuItem value={state.Nationality}>
									{iso3311a2.getCountry(state.Nationality)}
								</MenuItem>
								{iso3311a2.getCodes().map((flg, _index) => (
									<MenuItem key={_index} value={flg}>
										{iso3311a2.getCountry(flg)}
									</MenuItem>
								))}
							</TextField>
						</div>

						<div className="col-6 mb-4">
							<label style={{ fontSize: 13 }}>
								{languages[currentLang]["trip_crew_embark_title"]}:
							</label>
							<KeyboardDatePicker
								clearable="true"
								value={state.EmbarkDate}
								placeholder=""
								onChange={(selectedDate) => {
									try {
										setState({
											...state,
											EmbarkDate: dateFormat(
												new Date(selectedDate),
												"yyyy-MM-dd"
											),
										});
									} catch (e) {
										setState({
											...state,
											EmbarkDate: null,
										});
									}
								}}
								minDate={state.TripStartDate}
								maxDate={state.TripEndDate}
								format="MM/dd/yyyy"
								className="my-0"
								autoOk={true}
								style={{
									fontFamily: "Dromon-light",
									width: "100%",
								}}
								inputVariant="outlined"
								variant="inline"
							/>
						</div>

						<div className="col-6 mb-4">
							<label style={{ fontSize: 13 }}>
								{languages[currentLang]["trip_crew_disembark_title"]}:
							</label>
							<KeyboardDatePicker
								clearable="true"
								value={state.DisembarkDate}
								placeholder=""
								onChange={(selectedDate) => {
									try {
										setState({
											...state,
											DisembarkDate: dateFormat(
												new Date(selectedDate),
												"yyyy-MM-dd"
											),
										});
									} catch (e) {
										setState({
											...state,
											DisembarkDate: null,
										});
									}
								}}
								minDate={state.EmbarkDate}
								maxDate={state.TripEndDate}
								format="MM/dd/yyyy"
								autoOk={true}
								className="my-0"
								style={{
									fontFamily: "Dromon-light",
									width: "100%",
								}}
								inputVariant="outlined"
								variant="inline"
							/>
						</div>

						<div className="col-6 mb-4">
							<TextField
								select
								label={state.CurrentID === "IdentityId" ? "ID" : "Passport"}
								value={state.CurrentID}
								className="w-100"
								onChange={(event) =>
									setState({ ...state, CurrentID: event.target.value })
								}
								variant="outlined"
							>
								<MenuItem value="IdentityId">ID</MenuItem>
								<MenuItem value="PassportId">Passport</MenuItem>
							</TextField>
						</div>
						{/* /.col-6 */}

						<div className="col-6 mb-4">
							{!isEmpty(state.CurrentID) && (
								<TextField
									label={state.CurrentID === "IdentityId" ? "ID" : "Passport"}
									variant="outlined"
									value={
										state.CurrentID === "IdentityId"
											? state.IdentityId
											: state.PassportId
									}
									onChange={(event) => {
										if (state.CurrentID === "IdentityId") {
											setState({
												...state,
												IdentityId: event.target.value,
												PassportId: "",
											});
										} else {
											setState({
												...state,
												IdentityId: "",
												PassportId: event.target.value,
											});
										}
									}}
								/>
							)}
						</div>
						{/* /.col-6 */}

						<div className="col-12 mb-4 d-flex">
							<div style={{ width: 110 }}>
								<TextField
									select
									label="Code"
									value={state.CodeNum}
									className="w-100"
									onChange={(event) =>
										setState({
											...state,
											CodeNum: event.target.value,
										})
									}
									variant="outlined"
								>
									<MenuItem value={0}></MenuItem>
									{AllCountries.map((country, _index) => (
										<MenuItem key={_index} value={"+" + country.dialCode}>
											{"+" + country.dialCode} | {country.name}
										</MenuItem>
									))}
								</TextField>
							</div>

							<div style={{ flex: 1 }}>
								<TextField
									type="number"
									label="Phone"
									variant="outlined"
									value={state.ContactNumber}
									className="w-100"
									onChange={(event) => {
										setState({
											...state,
											ContactNumber: event.target.value,
										});
									}}
								/>
							</div>
						</div>
						{/* /.col-6 */}

						{state.Type === "skipper" && (
							<>
								<div className="col-6 mb-4">
									<TextField
										label="Skipper License ID"
										variant="outlined"
										value={state.SkipperLicenseId}
										onChange={(event) => {
											setState({
												...state,
												SkipperLicenseId: event.target.value,
											});
										}}
									/>
								</div>
								{/* /.col-6 */}

								<div className="col-6 mb-4">
									<TextField
										type="file"
										accept=".jpg,.jpeg,.pdf"
										helperText="Skipper License. (Must be jpg, jpeg or pdf)"
										variant="outlined"
										onChange={(event) => {
											const file = event.target.files[0];
											if (
												file.type !== "application/pdf" &&
												file.type !== "image/jpg" &&
												file.type !== "image/jpeg"
											) {
												alert("Please select a valid image or pdf");
											} else {
												setState({
													...state,
													SkipperLicenseUrl: file,
												});
											}
										}}
										style={{ flex: 1 }}
									/>
								</div>
								{/* /.col-6 */}

								{state.SkipperLicenseUrl && (
									<div className="col-6 d-flex align-items-center mb-4">
										<span
											className="mr-2 overflow-hidden text-right"
											style={{ flex: 1 }}
										>
											{state.SkipperLicenseUrl?.name || "Saved License file"}
										</span>
										<IconButton
											type="button"
											aria-label="Remove doc"
											style={{ width: 30, height: 30 }}
											onClick={() =>
												setState({ ...state, SkipperLicenseUrl: null })
											}
										>
											<img src={CloseIcon} style={{ height: 25 }} />
										</IconButton>
									</div>
								)}
							</>
						)}
					</form>
					<div className="d-flex justify-content-end align-items-center">
						<Button
							onClick={onCancel}
							className="mr-2"
							style={{
								fontFamily: "inherit",
								textTransform: "initial",
								fontSize: 16,
								color: colorInactiveItem,
							}}
							disabled={isLoadingSave}
						>
							{languages[currentLang]["common_cancel"]}
						</Button>

						{inEditing && canEdit && (
							<Button
								onClick={onDelete}
								className="mr-2"
								style={{
									fontFamily: "inherit",
									textTransform: "initial",
									fontSize: 16,
									color: deleteColor,
								}}
							>
								{languages[currentLang]["common_delete"]}
							</Button>
						)}

						<Button
							onClick={() => onSave()}
							className=""
							style={{
								fontFamily: "inherit",
								textTransform: "initial",
								fontSize: 16,
								color: filterHighlightColor,
							}}
							disabled={isLoadingSave}
						>
							{isLoadingSave && (
								<i className="fas fa-spin fa-circle-notch mr-2" />
							)}
							{languages[currentLang]["common_save"]}
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentLang: currentLang(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseCardBody);

// CONSTANTS
import { filterHighlightColor } from "../../../constants/colors";

// PLACES ICONS
// ANCHORAGE
import anchorage_exp from "../../../assets/img/mapIcons/new_markers/anchorage_marker/anchorage_marker_dark_blue.svg";
import anchorage_food from "../../../assets/img/mapIcons/anchorage/foodndrinks_anchorage_marker.svg";
import anchorage_launch from "../../../assets/img/mapIcons/anchorage/launch_anchorage_marker.svg";
import anchorage_shelter from "../../../assets/img/mapIcons/anchorage/shelter_anchorage_marker.svg";
import anchorage_shopping from "../../../assets/img/mapIcons/anchorage/shopping_anchorage_marker.svg";
import anchorage_selected from "../../../assets/img/mapIcons/anchorage/selected_anchorage_marker.svg";
import anchorage_itinerary from "../../../assets/img/mapIcons/new_markers/anchorage_marker/anchorage_marker_light_blue.svg";

import anchorage_exp_1 from "../../../assets/img/mapIcons/anchorage/weather/exp_anchorage_1.svg";
import anchorage_exp_2 from "../../../assets/img/mapIcons/anchorage/weather/exp_anchorage_2.svg";
import anchorage_exp_3 from "../../../assets/img/mapIcons/anchorage/weather/exp_anchorage_3.svg";

import anchorage_food_1 from "../../../assets/img/mapIcons/anchorage/weather/food_anchorage_1.svg";
import anchorage_food_2 from "../../../assets/img/mapIcons/anchorage/weather/food_anchorage_2.svg";
import anchorage_food_3 from "../../../assets/img/mapIcons/anchorage/weather/food_anchorage_3.svg";

import anchorage_launch_1 from "../../../assets/img/mapIcons/anchorage/weather/launch_anchorage_1.svg";
import anchorage_launch_2 from "../../../assets/img/mapIcons/anchorage/weather/launch_anchorage_2.svg";
import anchorage_launch_3 from "../../../assets/img/mapIcons/anchorage/weather/launch_anchorage_3.svg";

import anchorage_shelter_1 from "../../../assets/img/mapIcons/anchorage/weather/shelter_anchorage_1.svg";
import anchorage_shelter_2 from "../../../assets/img/mapIcons/anchorage/weather/shelter_anchorage_2.svg";
import anchorage_shelter_3 from "../../../assets/img/mapIcons/anchorage/weather/shelter_anchorage_3.svg";

import anchorage_shopping_1 from "../../../assets/img/mapIcons/anchorage/weather/shop_anchorage_1.svg";
import anchorage_shopping_2 from "../../../assets/img/mapIcons/anchorage/weather/shop_anchorage_2.svg";
import anchorage_shopping_3 from "../../../assets/img/mapIcons/anchorage/weather/shop_anchorage_3.svg";

// BEACH
import beach_exp from "../../../assets/img/mapIcons/new_markers/beach_marker/beach_marker_dark_blue.svg";
import beach_food from "../../../assets/img/mapIcons/beach/foodndrinks_beach_marker.svg";
import beach_launch from "../../../assets/img/mapIcons/beach/launch_beach_marker.svg";
import beach_shelter from "../../../assets/img/mapIcons/beach/shelter_beach_marker.svg";
import beach_shopping from "../../../assets/img/mapIcons/beach/shopping_beach_marker.svg";
import beach_selected from "../../../assets/img/mapIcons/beach/selected_beach_marker.svg";
import beach_itinerary from "../../../assets/img/mapIcons/new_markers/beach_marker/beach_marker_light_blue.svg";

import beach_exp_1 from "../../../assets/img/mapIcons/beach/weather/exp_beach_1.svg";
import beach_exp_2 from "../../../assets/img/mapIcons/beach/weather/exp_beach_2.svg";
import beach_exp_3 from "../../../assets/img/mapIcons/beach/weather/exp_beach_3.svg";

import beach_food_1 from "../../../assets/img/mapIcons/beach/weather/food_beach_1.svg";
import beach_food_2 from "../../../assets/img/mapIcons/beach/weather/food_beach_2.svg";
import beach_food_3 from "../../../assets/img/mapIcons/beach/weather/food_beach_3.svg";

import beach_launch_1 from "../../../assets/img/mapIcons/beach/weather/launch_beach_1.svg";
import beach_launch_2 from "../../../assets/img/mapIcons/beach/weather/launch_beach_2.svg";
import beach_launch_3 from "../../../assets/img/mapIcons/beach/weather/launch_beach_3.svg";

import beach_shelter_1 from "../../../assets/img/mapIcons/beach/weather/shelter_beach_1.svg";
import beach_shelter_2 from "../../../assets/img/mapIcons/beach/weather/shelter_beach_2.svg";
import beach_shelter_3 from "../../../assets/img/mapIcons/beach/weather/shelter_beach_3.svg";

import beach_shopping_1 from "../../../assets/img/mapIcons/beach/weather/shop_beach_1.svg";
import beach_shopping_2 from "../../../assets/img/mapIcons/beach/weather/shop_beach_2.svg";
import beach_shopping_3 from "../../../assets/img/mapIcons/beach/weather/shop_beach_3.svg";

// BOATRAMP
import boatramp_launch from "../../../assets/img/mapIcons/new_markers/boatramp_marker/boatramp_marker_dark_blue.svg";
import boatramp_shopping from "../../../assets/img/mapIcons/boatramp/shopping_boatramp_marker.svg";
import boatramp_selected from "../../../assets/img/mapIcons/boatramp/selected_boatramp_marker.svg";
import boatramp_itinerary from "../../../assets/img/mapIcons/new_markers/boatramp_marker/boatramp_marker_light_blue.svg";

// BOATYARD
import boatyard_food from "../../../assets/img/mapIcons/new_markers/boatyard_marker/boatyard_marker_dark_blue.svg";
import boatyard_launch from "../../../assets/img/mapIcons/boatyard/launch_boatyard_marker.svg";
import boatyard_shelter from "../../../assets/img/mapIcons/boatyard/shelter_boatyard_marker.svg";
import boatyard_shopping from "../../../assets/img/mapIcons/boatyard/shopping_boatyard_marker.svg";
import boatyard_selected from "../../../assets/img/mapIcons/boatyard/selected_boatyard_marker.svg";
import boatyard_itinerary from "../../../assets/img/mapIcons/new_markers/boatyard_marker/boatyard_marker_light_blue.svg";

// CAVE
import cave_exp from "../../../assets/img/mapIcons/new_markers/cave_marker/cave_marker_dark_blue.svg";
import cave_shelter from "../../../assets/img/mapIcons/cave/shelter_cave_marker.svg";
import cave_selected from "../../../assets/img/mapIcons/cave/selected_cave_marker.svg";
import cave_itinerary from "../../../assets/img/mapIcons/new_markers/cave_marker/cave_marker_dark_blue.svg";

// FISHING AREA
import fishingarea_exp from "../../../assets/img/mapIcons/new_markers/fishing_marker/fishing_marker_dark_blue.svg";
import fishingarea_food from "../../../assets/img/mapIcons/fishingarea/foodndrinks_fishing_marker.svg";
import fishingarea_launch from "../../../assets/img/mapIcons/fishingarea/launch_fishing_marker.svg";
import fishingarea_shelter from "../../../assets/img/mapIcons/fishingarea/shelter_fishing_marker.svg";
import fishingarea_shopping from "../../../assets/img/mapIcons/fishingarea/shopping_fishing_marker.svg";
import fishingarea_selected from "../../../assets/img/mapIcons/fishingarea/selected_fishing_marker.svg";
import fishingarea_itinerary from "../../../assets/img/mapIcons/new_markers/fishing_marker/fishing_marker_light_blue.svg";

// MARINA
import marina_exp from "../../../assets/img/mapIcons/new_markers/marina_marker/marina_marker_light_blue.svg";
import marina_food from "../../../assets/img/mapIcons/marina/foodndrinks_marina_marker.svg";
import marina_launch from "../../../assets/img/mapIcons/marina/launch_marina_marker.svg";
import marina_shelter from "../../../assets/img/mapIcons/marina/shelter_marina_marker.svg";
import marina_shopping from "../../../assets/img/mapIcons/marina/shopping_marina_marker.svg";
import marina_selected from "../../../assets/img/mapIcons/marina/selected_marina_marker.svg";
import marina_itinerary from "../../../assets/img/mapIcons/new_markers/marina_marker/marina_marker_light_blue.svg";

// MOORING-SPOT
import mooring_exp from "../../../assets/img/mapIcons/new_markers/mooring_marker/mooring_marker_dark_blue.svg";
import mooring_food from "../../../assets/img/mapIcons/mooringspot/foodndrinks_mooring_marker.svg";
import mooring_launch from "../../../assets/img/mapIcons/mooringspot/launch_mooring_marker.svg";
import mooring_shelter from "../../../assets/img/mapIcons/mooringspot/shelter_mooring_marker.svg";
import mooring_shopping from "../../../assets/img/mapIcons/mooringspot/shopping_mooring_marker.svg";
import mooring_selected from "../../../assets/img/mapIcons/mooringspot/selected_mooring_marker.svg";
import mooring_itinerary from "../../../assets/img/mapIcons/new_markers/mooring_marker/mooring_marker_light_blue.svg";

// PORT
import port_exp from "../../../assets/img/mapIcons/new_markers/port_marker/port_marker_dark_blue.svg";
import port_food from "../../../assets/img/mapIcons/port/foodndrinks_port_marker.svg";
import port_launch from "../../../assets/img/mapIcons/port/launch_port_marker.svg";
import port_shelter from "../../../assets/img/mapIcons/port/shelter_port_marker.svg";
import port_shopping from "../../../assets/img/mapIcons/port/shopping_port_marker.svg";
import port_selected from "../../../assets/img/mapIcons/port/selected_port_marker.svg";
import port_itinerary from "../../../assets/img/mapIcons/new_markers/port_marker/port_marker_light_blue.svg";

// SEA PLANE PORT
import seaplaneport_launch from "../../../assets/img/mapIcons/new_markers/seaplaneport_marker/seaplaneport_marker_dark_blue.svg";
import seaplaneport_shelter from "../../../assets/img/mapIcons/seaplaneport/shelter_seaplaneport_marker.svg";
import seaplaneport_shopping from "../../../assets/img/mapIcons/seaplaneport/shopping_seaplaneport_marker.svg";
import seaplaneport_selected from "../../../assets/img/mapIcons/seaplaneport/selected_seaplaneport_marker.svg";
import seaplaneport_itinerary from "../../../assets/img/mapIcons/new_markers/seaplaneport_marker/seaplaneport_marker_light_blue.svg";

// SHIPYARD
import shipyard_exp from "../../../assets/img/mapIcons/new_markers/shipyard_marker/shipyard_marker_dark_blue.svg";
import shipyard_launch from "../../../assets/img/mapIcons/shipyward/launch_shipyard_marker.svg";
import shipyard_shopping from "../../../assets/img/mapIcons/shipyward/shopping_shipyard_marker.svg";
import shipyard_selected from "../../../assets/img/mapIcons/shipyward/selected_shipyard_marker.svg";
import shipyard_itinerary from "../../../assets/img/mapIcons/new_markers/shipyard_marker/shipyard_marker_light_blue.svg";

// TRANSITIONAL
import transitional_exp from "../../../assets/img/mapIcons/transitional/exp_transitional_marker.svg";
import transitional_food from "../../../assets/img/mapIcons/transitional/foodndrinks_transitional_marker.svg";
import transitional_launch from "../../../assets/img/mapIcons/transitional/launch_transitional_marker.svg";
import transitional_shelter from "../../../assets/img/mapIcons/transitional/shelter_transitional_marker.svg";
import transitional_shopping from "../../../assets/img/mapIcons/transitional/shopping_transitional_marker.svg";
import transitional_selected from "../../../assets/img/mapIcons/transitional/selected_transitional_marker.svg";
import transitional_itinerary from "../../../assets/img/mapIcons/new_markers/transitional_marker/transitional_marker_light_blue.svg";

import vessel from "../../../assets/img/mapIcons/vessel_onmove.svg";
import vesselstop from "../../../assets/img/mapIcons/vessel_stopped.svg";
import coastViewIcon from "../../../assets/coastview_marker_ic.svg";

import anchorage_saved from "../../../assets/svg/savedMarkers/saved_anchorage_marker.svg";
import beach_saved from "../../../assets/svg/savedMarkers/saved_beach_marker.svg";
import boatramp_saved from "../../../assets/svg/savedMarkers/saved_boatramp_marker.svg";
import boatyard_saved from "../../../assets/svg/savedMarkers/saved_boatyard_marker.svg";
import cave_saved from "../../../assets/svg/savedMarkers/saved_cave_marker.svg";
import fishing_saved from "../../../assets/svg/savedMarkers/saved_fishing_marker.svg";
import marina_saved from "../../../assets/svg/savedMarkers/saved_marina_marker.svg";
import mooring_saved from "../../../assets/svg/savedMarkers/saved_mooring_marker.svg";
import port_saved from "../../../assets/svg/savedMarkers/saved_port_marker.svg";
import seaplaneport_saved from "../../../assets/svg/savedMarkers/saved_seaplaneport_marker.svg";
import shipyard_saved from "../../../assets/svg/savedMarkers/saved_shipyard_marker.svg";
import transitional_saved from "../../../assets/svg/savedMarkers/saved_transitional_marker.svg";
import wreck_saved from "../../../assets/svg/savedMarkers/saved_wreck_marker.svg";

// CONFIGS
import { API_CONFIG } from "../../../appConfig";

const VESSEL_ICONS = {
	vessel: vessel,
	vesselstop: vesselstop,
};

const PLACE_TYPES_PHOTOS = {
	beach_experience: beach_exp,
	beach_fooddrink: beach_food,
	beach_shelter: beach_shelter,
	beach_shopsservices: beach_shopping,
	beach_lanchpoint: beach_launch,
	beach_selected: beach_selected,
	beach_itinerary: beach_itinerary,

	beach_experience_1: beach_exp_1,
	beach_experience_2: beach_exp_2,
	beach_experience_3: beach_exp_3,

	beach_fooddrink_1: beach_food_1,
	beach_fooddrink_2: beach_food_2,
	beach_fooddrink_3: beach_food_3,

	beach_shelter_1: beach_shelter_1,
	beach_shelter_2: beach_shelter_2,
	beach_shelter_3: beach_shelter_3,

	beach_shopsservices_1: beach_shopping_1,
	beach_shopsservices_2: beach_shopping_2,
	beach_shopsservices_3: beach_shopping_3,

	beach_lanchpoint_1: beach_launch_1,
	beach_lanchpoint_2: beach_launch_2,
	beach_lanchpoint_3: beach_launch_3,

	anchorage_experience: anchorage_exp,
	anchorage_fooddrink: anchorage_food,
	anchorage_shelter: anchorage_shelter,
	anchorage_shopsservices: anchorage_shopping,
	anchorage_lanchpoint: anchorage_launch,
	anchorage_selected: anchorage_selected,
	anchorage_itinerary: anchorage_itinerary,

	anchorage_experience_1: anchorage_exp_1,
	anchorage_experience_2: anchorage_exp_2,
	anchorage_experience_3: anchorage_exp_3,

	anchorage_fooddrink_1: anchorage_food_1,
	anchorage_fooddrink_2: anchorage_food_2,
	anchorage_fooddrink_3: anchorage_food_3,

	anchorage_shelter_1: anchorage_shelter_1,
	anchorage_shelter_2: anchorage_shelter_2,
	anchorage_shelter_3: anchorage_shelter_3,

	anchorage_shopsservices_1: anchorage_shopping_1,
	anchorage_shopsservices_2: anchorage_shopping_2,
	anchorage_shopsservices_3: anchorage_shopping_3,

	anchorage_lanchpoint_1: anchorage_launch_1,
	anchorage_lanchpoint_2: anchorage_launch_2,
	anchorage_lanchpoint_3: anchorage_launch_3,

	boatramp_shopsservices: boatramp_shopping,
	boatramp_lanchpoint: boatramp_launch,
	boatramp_selected: boatramp_selected,
	boatramp_itinerary: boatramp_itinerary,

	boatyard_fooddrink: boatyard_food,
	boatyard_shelter: boatyard_shelter,
	boatyard_shopsservices: boatyard_shopping,
	boatyard_lanchpoint: boatyard_launch,
	boatyard_selected: boatyard_selected,
	boatyard_itinerary: boatyard_itinerary,

	cave_experience: cave_exp,
	cave_shelter: cave_shelter,
	cave_selected: cave_selected,
	cave_itinerary: cave_itinerary,

	fishing_experience: fishingarea_exp,
	fishing_fooddrink: fishingarea_food,
	fishing_shelter: fishingarea_shelter,
	fishing_shopsservices: fishingarea_shopping,
	fishing_lanchpoint: fishingarea_launch,
	fishing_selected: fishingarea_selected,
	fishing_itinerary: fishingarea_itinerary,

	marina_experience: marina_exp,
	marina_fooddrink: marina_food,
	marina_shelter: marina_shelter,
	marina_shopsservices: marina_shopping,
	marina_lanchpoint: marina_launch,
	marina_selected: marina_selected,
	marina_itinerary: marina_itinerary,

	mooring_experience: mooring_exp,
	mooring_fooddrink: mooring_food,
	mooring_shelter: mooring_shelter,
	mooring_shopsservices: mooring_shopping,
	mooring_lanchpoint: mooring_launch,
	mooring_selected: mooring_selected,
	mooring_itinerary: mooring_itinerary,

	port_experience: port_exp,
	port_fooddrink: port_food,
	port_shelter: port_shelter,
	port_shopsservices: port_shopping,
	port_lanchpoint: port_launch,
	port_selected: port_selected,
	port_itinerary: port_itinerary,

	seaplaneport_shelter: seaplaneport_shelter,
	seaplaneport_shopsservices: seaplaneport_shopping,
	seaplaneport_lanchpoint: seaplaneport_launch,
	seaplaneport_selected: seaplaneport_selected,
	seaplaneport_itinerary: seaplaneport_itinerary,

	shipyard_experience: shipyard_exp,
	shipyard_shopsservices: shipyard_shopping,
	shipyard_lanchpoint: shipyard_launch,
	shipyard_selected: shipyard_selected,
	shipyard_itinerary: shipyard_itinerary,

	transitional_experience: transitional_exp,
	transitional_fooddrink: transitional_food,
	transitional_shelter: transitional_shelter,
	transitional_shopsservices: transitional_shopping,
	transitional_lanchpoint: transitional_launch,
	transitional_selected: transitional_selected,
	transitional_itinerary: transitional_itinerary,

	coastViewIcon: coastViewIcon,
	anchorage_saved: anchorage_saved,
	beach_saved: beach_saved,
	boatramp_saved: boatramp_saved,
	boatyard_saved: boatyard_saved,
	cave_saved: cave_saved,
	fishing_saved: fishing_saved,
	marina_saved: marina_saved,
	mooring_saved: mooring_saved,
	port_saved: port_saved,
	seaplaneport_saved: seaplaneport_saved,
	shipyard_saved: shipyard_saved,
	transitional_saved: transitional_saved,
	wreck_saved: wreck_saved,
};

/**
 *
 * @param {object} map
 * @param {object} shipsRef
 * @param {object} placesRef
 * @param {object} bRef
 * @param {object} itineraryPlaces
 * @param {object} itineraryRoutes
 * @param {object} anchoragesRef
 * @param {object} tripsRef
 * @param {object} filtersSelected
 */
export const onSetMapData = (
	map,
	ref,
	placesRef,
	bRef,
	itineraryPlaces,
	itineraryRoutes,
	anchoragesRef,
	tripsRef,
	tripsExpRef,
	filtersSelected
) => {
	// ADD IMAGES
	Object.keys(VESSEL_ICONS).forEach((key) => {
		let icon = new Image(28, 28);
		if (key === "vessel") {
			icon = new Image(105, 50);
		} else if (key === "vesselstop") {
			icon = new Image(38, 38);
		}
		icon.onload = () => map.addImage(`${key}`, icon);
		icon.src = VESSEL_ICONS[key];
	});

	Object.keys(PLACE_TYPES_PHOTOS).forEach((key) => {
		let icon = new Image(80, 90);
		icon.onload = () => map.addImage(`${key}`, icon);
		icon.src = PLACE_TYPES_PHOTOS[key];
	});

	var layers = map.getStyle().layers;
	var firstSymbolId = 0;
	for (var i = 0; i < layers.length; i++) {
		if (layers[i].type === "symbol") {
			firstSymbolId = layers[i].id;
			break;
		}
	}

	tripsRef.features = [];

	map.MyCustomLoaded = true;

	// ADD SOURCES
	map.addSource("postgis-tiles-gtphotos", {
		type: "vector",
		tiles: [
			process.env.REACT_APP_ENVIRONMENT === "production"
				? API_CONFIG.tileProdUrl
				: API_CONFIG.tileDevUrl,
		],
	});

	map.addSource("ships_source", {
		type: "geojson",
		data: ref,
	});

	map.addSource("anchorages_source", {
		type: "geojson",
		data: anchoragesRef,
	});

	map.addSource("places_source", {
		type: "geojson",
		data: placesRef,
	});

	map.addSource("tile_trips_source", {
		type: "geojson",
		data: tripsExpRef,
		cluster: true,
		clusterMaxZoom: 14, // Max zoom to cluster points on
		clusterRadius: 50,
	});

	map.addSource("bookmark_places_source", {
		type: "geojson",
		data: bRef,
	});

	map.addSource("itinerary_places_source", {
		type: "geojson",
		data: itineraryPlaces,
	});

	map.addSource("itinerary_routes_source", {
		type: "geojson",
		data: itineraryRoutes,
	});

	// ADD LAYERS
	map.addLayer({
		id: "anchorages_layer",
		type: "fill",
		source: "anchorages_source",
		layout: {},
		paint: {
			"fill-color": "#C6E1EB",
			"fill-opacity": 0.9,
			"fill-outline-color": "#C6E1EB",
		},
		firstSymbolId,
	});

	map.addLayer({
		id: "coastview",
		type: "circle",
		source: "postgis-tiles-gtphotos",
		"source-layer": "default",
		minzoom: 10,
		maxzoom: 22,
		paint: {
			"circle-radius": 5,
			"circle-color": "#569DD1",
		},
	});

	map.addLayer({
		id: "ships",
		type: "symbol",
		source: "ships_source",
		minzoom: 11,
		layout: {
			"icon-image": ["get", "icon"],
			"icon-size": ["get", "iconSize"],
			"icon-rotate": ["get", "angle"],
			"icon-allow-overlap": true,
		},
	});

	map.addLayer({
		id: "places",
		type: "symbol",
		source: "places_source",
		minzoom: 8,
		maxzoom: 22,
		layout: {
			"icon-size": 0.35,
			"icon-image": ["concat", ["get", "icon"], `_${filtersSelected.needs}`],
			"icon-allow-overlap": true,
		},
		filter: filtersSelected.allPredicates,
	});

	map.addLayer({
		id: "clusters",
		type: "circle",
		source: "tile_trips_source",
		paint: {
			"circle-color": "#cd6e9e",
			"circle-radius": 14,
			"circle-stroke-width": 1,
			"circle-stroke-color": "#fff",
		},
		filter: filtersSelected.tripsPredicates,
	});

	map.addLayer({
		id: "clusters-count",
		type: "symbol",
		source: "tile_trips_source",
		paint: {
			"text-color": "#ffffff",
		},
		layout: {
			"text-field": "{point_count_abbreviated}",
			"text-size": 12,
		},
		filter: filtersSelected.tripsPredicates,
	});

	map.addLayer({
		id: "bookmark_places",
		type: "symbol",
		source: "bookmark_places_source",
		layout: {
			"icon-size": 0.35,
			"icon-image": ["concat", ["get", "icon"], "_saved"],
			"icon-allow-overlap": true,
		},
	});

	map.addLayer({
		id: "itinerary_layer_routes",
		type: "line",
		source: "itinerary_routes_source",
		paint: {
			"line-color": "#0078ff",
			"line-width": 3,
			"line-dasharray": [2, 2],
		},
	});

	map.addLayer({
		id: "itinerary_layer_places",
		type: "symbol",
		source: "itinerary_places_source",
		layout: {
			"icon-size": 0.35,
			"icon-image": ["concat", ["get", "icon"], "_", ["get", "need"]],
			"icon-allow-overlap": true,
		},
	});

	map.addLayer({
		id: "itinerary-clusters",
		type: "circle",
		source: "itinerary_places_source",
		paint: {
			"circle-color": filterHighlightColor,
			"circle-radius": 14,
			"circle-stroke-width": 1,
			"circle-stroke-color": "#fff",
			"circle-translate": [0, 31],
		},
	});

	map.addLayer({
		id: "itinerary-clusters-count",
		type: "symbol",
		source: "itinerary_places_source",
		paint: {
			"text-color": "#ffffff",
			"text-translate": [0, 31],
		},
		layout: {
			"text-field": ["get", "index"],
			"text-size": 12,
		},
	});
};

export const onResetMapData = (map) => {
	// REMOVE IMAGES
	Object.keys(VESSEL_ICONS).forEach((key) => {
		map.removeImage(`${key}`);
	});

	Object.keys(PLACE_TYPES_PHOTOS).forEach((key) => {
		map.removeImage(`${key}`);
	});

	// REMOVE LAYERS
	if (map.getLayer("anchorages_layer")) {
		map.removeLayer("anchorages_layer");
	}
	if (map.getLayer("coastview")) {
		map.removeLayer("coastview");
	}
	if (map.getLayer("ships")) {
		map.removeLayer("ships");
	}
	if (map.getLayer("places")) {
		map.removeLayer("places");
	}
	if (map.getLayer("clusters")) {
		map.removeLayer("clusters");
	}
	if (map.getLayer("clusters-count")) {
		map.removeLayer("clusters-count");
	}
	if (map.getLayer("itinerary-clusters")) {
		map.removeLayer("itinerary-clusters");
	}
	if (map.getLayer("itinerary-clusters-count")) {
		map.removeLayer("itinerary-clusters-count");
	}
	if (map.getLayer("bookmark_places")) {
		map.removeLayer("bookmark_places");
	}
	if (map.getLayer("itinerary_layer_routes")) {
		map.removeLayer("itinerary_layer_routes");
	}
	if (map.getLayer("itinerary_layer_places")) {
		map.removeLayer("itinerary_layer_places");
	}

	// REMOVE SOURCES
	if (map.getSource("postgis-tiles-gtphotos")) {
		map.removeSource("postgis-tiles-gtphotos");
	}
	if (map.getSource("ships_source")) {
		map.removeSource("ships_source");
	}
	if (map.getSource("anchorages_source")) {
		map.removeSource("anchorages_source");
	}
	if (map.getSource("places_source")) {
		map.removeSource("places_source");
	}
	if (map.getSource("tile_trips_source")) {
		map.removeSource("tile_trips_source");
	}
	if (map.getSource("bookmark_places_source")) {
		map.removeSource("bookmark_places_source");
	}
	if (map.getSource("itinerary_routes_source")) {
		map.removeSource("itinerary_routes_source");
	}
	if (map.getSource("itinerary_places_source")) {
		map.removeSource("itinerary_places_source");
	}
};

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

// CONSTANTS
import {
	filterHighlightColor,
	formMainTxt,
	whiteColor,
} from "../../../constants/colors";
import { Slider, Snackbar } from "@material-ui/core";

// HELPERS
import { convertSpeedToMyUnit } from "../../../helpers/UnitConverter";
import { logEvent_ } from "../../../helpers/analytics";

// SELECTORS
import { languages } from "../../../store/features/translation/Languages";
import { currentLang } from "../../../store/features/translation/selectors";
import { userData } from "../../../store/features/user/selectors";

// COMPONENTS
import Button from "../Components/Button";
import { Check } from "../../FIlterModal";
import CardVessels from "../../FIlterModal/CardVessels";

// SVGs ICONS
import kayak from "../../../assets/svg/tripWizard/wiz_kayak_active_ic.svg";
import motorboat from "../../../assets/svg/tripWizard/wiz_motorboat_active_ic.svg";
import sailboat from "../../../assets/svg/tripWizard/wiz_sailboat_active_ic.svg";
import kayak_ from "../../../assets/svg/tripWizard/wiz_kayak_selected_ic.svg";
import motorboat_ from "../../../assets/svg/tripWizard/wiz_motorboat_selected_ic.svg";
import sailboat_ from "../../../assets/svg/tripWizard/wiz_sailboat_selected_ic.svg";
import beach_ from "../../../assets/svg/placeWhiteIcons/beach.svg";
import anchorage_ from "../../../assets/svg/placeWhiteIcons/anchorage.svg";
import cave_ from "../../../assets/svg/placeWhiteIcons/cave.svg";
import mooring_ from "../../../assets/svg/placeWhiteIcons/mooring.svg";
import marina_ from "../../../assets/svg/placeWhiteIcons/marina.svg";
import port_ from "../../../assets/svg/placeWhiteIcons/port.svg";
import wreck_ from "../../../assets/svg/placeWhiteIcons/wreck.svg";
import fishingarea_ from "../../../assets/svg/placeWhiteIcons/fishingarea.svg";
import shipyard_ from "../../../assets/svg/placeWhiteIcons/shipyard.svg";
import transitional_anchorage_ from "../../../assets/svg/placeWhiteIcons/transitional_anchorage.svg";
import seaplaneport_ from "../../../assets/svg/placeWhiteIcons/seaplaneport.svg";
import boatramp_ from "../../../assets/svg/placeWhiteIcons/boatramp.svg";
import boatyard_ from "../../../assets/svg/placeWhiteIcons/boatyard.svg";

export const iconDictionary = {
	kayak,
	kayak_,
	motorboat,
	motorboat_,
	sailboat,
	sailboat_,
	// ADDITIONAL
	beach_,
	anchorage_,
	cave_,
	mooring_,
	marina_,
	port_,
	wreck_,
	fishingarea_,
	shipyard_,
	transitional_anchorage_,
	seaplaneport_,
	boatramp_,
	boatyard_,
};

const First = (props) => {
	const currentLanguage = useSelector(currentLang);
	const currentUser = useSelector(userData);
	const [notice, setNotice] = useState({ type: "", content: "" });
	const [checked, setChecked] = useState(false);
	const [value, setValue] = useState(10);
	const [valueSpeed, setValueSpeed] = useState(10);
	const [state, setState] = useState({
		allVessels: [
			{ key: "kayak", text: "sea kayak" },
			{ key: "motorboat", text: "motorboat" },
			{ key: "sailboat", text: "sailing boat" },
		],
		selectedVessel:
			props.state?.VesselType !== "" && props.state?.VesselType
				? languages["EN"][props.state?.VesselType]
				: "kayak",
		speedValue: props.state?.VesselLengthMeters ? props.state?.VesseyType : 10,
		lengthValue: props.state?.VesselSpeedKnots
			? props.state?.VesselSpeedKnots
			: 10,
	});
	const onSliderSpeedValueChange = (e, newValue) => {
		setValueSpeed(newValue);
		setChecked(false);
		setState({
			...state,
			speedValue: newValue,
		});
	};
	const onSliderValueChange = (e, newValue) => {
		setValue(newValue);
		setChecked(false);
		setState({
			...state,
			lengthValue: newValue,
		});
	};
	const titleStyle = {
		fontWeight: "500",
		fontSize: 14,
		color: formMainTxt,
		marginBottom: 16,
	};
	const handleSwitch = () => {
		let selectedVess = currentUser?.SelectedVessel;
		if (!selectedVess) {
			return;
		}

		if (checked) {
			setChecked(false);
			return;
		}
		if (selectedVess) {
			var length = selectedVess?.LengthMeters?.toFixed(0);
			var speed = selectedVess?.SpeedKnots
				? Number.parseFloat(
						convertSpeedToMyUnit(selectedVess.SpeedKnots, "kts")
				  ).toFixed(0)
				: 10;

			if (selectedVess.FilterType === "motorboat") {
				setState({
					...state,
					selectedVessel: length > 10 ? "motorboat" : "smallboat",
					lengthValue: selectedVess.LengthMeters.toFixed(0),
					speedValue: speed,
				});
			} else {
				setState({
					...state,
					selectedVessel: selectedVess.FilterType,
					lengthValue: length,
					speedValue: speed,
				});
			}
			setValue(length);
			setValueSpeed(speed);
			setChecked(true);
			return;
		}

		setNotice({
			type: "WARNING",
			content: languages[currentLanguage]["user_vessels_emptystate_header"],
		});
	};

	const onSelectVessel = (vessel) => {
		// UPDATE THE STATE
		setState({
			...state,
			selectedVessel: vessel,
		});
		setChecked(false);
	};
	const handleBack = () => {
		props.handleBack({
			step: 1,
		});
		// props.onCancel()
	};
	const handleNext = () => {
		logEvent_("react_tripwiz_stepvessel_nextbtn_pressed");

		props.handleNext({
			step: 1,
			type: state.selectedVessel,
			speed: state.speedValue,
			length: state.lengthValue,
		});
	};

	useEffect(() => {
		setState({
			allVessels: [
				{ key: "kayak", text: "sea kayak" },
				{ key: "motorboat", text: "motorboat" },
				{ key: "sailboat", text: "sailing boat" },
			],
			selectedVessel:
				props.state?.VesselType !== "" && props.state?.VesselType
					? props.state?.VesselType
					: "kayak",
			speedValue: props.state?.VesselSpeedKnots
				? props.state?.VesselSpeedKnots
				: 10,
			lengthValue: props.state?.VesselLengthMeters
				? props.state?.VesselLengthMeters
				: 10,
		});
		setValueSpeed(
			props.state?.VesselSpeedKnots ? props.state?.VesselSpeedKnots : 10
		);
		setValue(
			props.state?.VesselLengthMeters ? props.state?.VesselLengthMeters : 10
		);
		setChecked(false);
	}, [props.state, props.finished]);

	return (
		<>
			<div className={props.visible ? "pb-4" : "d-none"}>
				<div
					className={
						props.visible
							? "px-0 d-flex flex-column justify-content-space-between step-body"
							: "d-none"
					}
					style={{
						width: 384,
					}}
				>
					<h3
						className="my-2 dromon-l"
						style={{ fontSize: 15, color: "rgb(143, 150, 167)" }}
					>
						Creating a New Trip
					</h3>
					<h2 className="mb-0" style={{ fontSize: 22, color: "#222c4b" }}>
						{languages[currentLanguage]["trip_attr_vesseltype_header"]}
					</h2>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							marginTop: 42,
						}}
					>
						<h3 className="mb-0" style={titleStyle}>
							{languages[currentLanguage]["trip_attr_vesseltype_subheader"]}
						</h3>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<span
								style={{ color: "#8F96A7", fontSize: 12 }}
								className="dromon-l"
							>
								{languages[currentLanguage]["filter_vesseltype_save_checkbox"]}
							</span>
							<Check
								checked={checked}
								value={value}
								size="small"
								onChange={handleSwitch}
								name="weather"
								inputProps={{ "aria-label": "primary checkbox" }}
							/>
						</div>
					</div>
					<div style={{ marginTop: 15 }}>
						<div className="col-12 px-0">
							<div className="row justify-content-between">
								{state.allVessels?.map((place_) => (
									<CardVessels
										key={place_.key}
										icon={iconDictionary[place_.key]}
										active={state.selectedVessel === place_.key}
										type={place_.key}
										activeIcon={
											iconDictionary[`${place_.key}_`]
												? iconDictionary[`${place_.key}_`]
												: iconDictionary[place_.key]
										}
										disabled={false}
										trip
										label={place_.text}
										onClick={() => onSelectVessel(place_.key)}
									/>
								))}
							</div>
						</div>
					</div>
					<div
						className="d-flex flex-row justify-content-between"
						style={{ alignItems: "center", width: "100%", marginTop: 36 }}
					>
						<h3
							style={{
								fontWeight: "500",
								fontSize: 12,
								color: "#222c4b",
								marginBottom: 0,
							}}
						>
							{/* {languages[currentLanguage]["filter_vesseltype_length_label"]}(
            {currentUser?.Settings?.DepthUnits
              ? currentUser?.Settings?.DepthUnits
              : "m"} */}
							{languages[currentLanguage]["trip_attr_vesseltype_length_label"]}
						</h3>
						<span
							className="d-flex justify-content-center align-items-center px-2"
							style={{
								color: filterHighlightColor,
								fontSize: 12,
								padding: "3px 7px",
								height: 24,
								borderRadius: 5,
							}}
						>
							{/* {AuthService.isUserLogedIn()
              ? Math.round(
                  convertDepthToMyUnit(
                    filtersSelected?.vesselLength,
                    currentUser?.Settings?.DepthUnits
                  )
                )
              : filtersSelected.vesselLength} */}
							{value}m
						</span>
						<Slider
							min={2}
							max={50}
							value={value}
							style={{ width: 218, color: filterHighlightColor }}
							onChange={onSliderValueChange}
						/>
					</div>
					<div
						className="d-flex flex-row justify-content-between"
						style={{
							alignItems: "center",
							width: "100%",
							marginTop: 36,
						}}
					>
						<h3
							style={{
								fontWeight: "500",
								fontSize: 12,
								color: "#222c4b",
								marginBottom: 0,
							}}
						>
							{/* {languages[currentLanguage]["filter_vesseltype_length_label"]}(
            {currentUser?.Settings?.DepthUnits
              ? currentUser?.Settings?.DepthUnits
              : "m"} */}
							{languages[currentLanguage]["trip_attr_vesseltype_speed_label"]}
						</h3>
						<span
							className="d-flex justify-content-center align-items-center px-2"
							style={{
								color: filterHighlightColor,
								fontSize: 12,
								padding: "3px 7px",
								height: 24,
								borderRadius: 5,
								// backgroundColor: "#e5f1ff",
							}}
						>
							{/* {AuthService.isUserLogedIn()
              ? Math.round(
                  convertDepthToMyUnit(
                    filtersSelected?.vesselLength,
                    currentUser?.Settings?.DepthUnits
                  )
                )
              : filtersSelected.vesselLength} */}
							{valueSpeed}kts
						</span>
						<Slider
							min={2}
							max={50}
							value={valueSpeed}
							style={{ width: 218, color: filterHighlightColor }}
							onChange={onSliderSpeedValueChange}
						/>
					</div>
				</div>
				<div
					style={{ width: 384 }}
					className="d-flex flex-row justify-content-between px-0"
				>
					<Button text="Back" handleBack={handleBack} />
					<Button
						text={languages[currentLanguage]["common_next"]}
						next
						handleNext={handleNext}
					/>
					<Snackbar
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						open={notice.type !== ""}
						autoHideDuration={2000}
						onClose={() => setNotice({ type: "", content: "" })}
						message={notice.content}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(First);

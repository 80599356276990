import {
	SHOW_NOTIFICATION,
	HIDE_NOTIFICATION,
	SET_CUSTOM_DIALOG_PROPS,
} from "./types";

export const initialState = {
	visible: false,
	message: null,
	severity: "success", // "error" | "warning" | "info" | "success"
	positionX: "left",
	positionY: "bottom",
	duration: 5000,
	customDialogProps: {
		visible: false,
	},
};

/**
 *
 * @param {*} state
 * @param {*} action
 */

export default function notificationReducer(state = initialState, action) {
	let newState = state;
	switch (action.type) {
		case SHOW_NOTIFICATION:
			const { visible, message, severity, positionX, positionY, duration } =
				action.payload;

			return (newState = {
				visible: visible || true,
				message: message || "",
				severity: severity || "success",
				positionX: positionX || "left",
				positionY: positionY || "bottom",
				duration: duration || 5000,
			});

		case HIDE_NOTIFICATION:
			return (newState = {
				...state,
				visible: false,
				message: null,
			});

		case SET_CUSTOM_DIALOG_PROPS:
			if (action.payload) {
			}
			return {
				...state,
				customDialogProps: { ...action.payload },
			};

		default:
			return newState;
	}
}

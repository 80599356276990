import { ApiClient } from "./ApiClient";
import { FacebookClient } from "./FacebookClient";

const AuthService = (function () {
	let OnHaveSession = null;
	const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
	function reviver(key, value) {
		if (typeof value === "string" && dateFormat.test(value)) {
			return new Date(value);
		}
		return value;
	}
	let expiryTimeout;
	var CheckSessionExpiry;
	(CheckSessionExpiry = function () {
		const session = JSON.parse(localStorage.getItem("session"), reviver);
		if (session && session.ExpiresOn > new Date().getTime()) {
			var diff = session.ExpiresOn - new Date().getTime();
			expiryTimeout = setTimeout(() => {
				if (OnHaveSession) OnHaveSession({ status: false });
			}, diff);
		}
	})();

	return {
		setHaveSession: function (callback) {
			OnHaveSession = callback;
		},
		logIn: async function (provider, access_token, auth_code) {
			try {
				let data = await ApiClient.localLoginme(
					provider,
					access_token.access_token,
					auth_code
				);
				if (data.Error || data.ErrorCode || !data.Token) {
					return {
						success: false,
						data,
					};
				} else {
					data = {
						...data,
						provider,
						refreshToken: access_token.refreshToken
							? access_token.refreshToken
							: null,
					};
					localStorage.setItem("session", JSON.stringify(data));
					if (OnHaveSession) OnHaveSession({ status: true });
					CheckSessionExpiry();
					return {
						success: true,
						data,
					};
				}
			} catch (ex) {
				localStorage.removeItem("session");
				return {
					success: false,
					data: ex,
				};
			}
		},

		logOut: async function () {
			if (expiryTimeout) clearTimeout(expiryTimeout);

			localStorage.removeItem("session");
			if (OnHaveSession) OnHaveSession({ status: false });

			const rsp = await FacebookClient.init();
			const response = await FacebookClient.checkLoginState();
			if (response.status === "connected") {
				const out = await FacebookClient.logout();
			}
		},

		isUserLogedIn: function () {
			const session = JSON.parse(localStorage.getItem("session"), reviver);
			if (!session) return false;
			if (session.ExpiresOn > new Date().getTime()) return true;
			return false;
		},

		userType: function () {
			return JSON.parse(window.localStorage.getItem("currentUserType"))?.type;
		},

		isUserPartner: function () {
			return (
				JSON.parse(window.localStorage.getItem("currentUserType"))?.type ==
					"partner" && this.isUserLogedIn()
			);
		},
	};
})();

export { AuthService };

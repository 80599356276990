import styled, { css } from "styled-components";

export const Overlay = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  width: 0;
  height: 100%;
  background-color: transparent;
  opacity: 0.5;
  transition: all 0s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  z-index: 4;
  ${(props) =>
    props.show &&
    css`
      width: 100%;
      opacity: 1;
    `}
`;

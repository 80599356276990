import React from "react";
import { connect } from "react-redux";
import {
	Button,
	TextField,
	MenuItem,
	IconButton,
	InputLabel,
} from "@material-ui/core";
import dateFormat from "date-fns/format";
import {
	KeyboardDatePicker,
	KeyboardDateTimePicker,
} from "@material-ui/pickers";
// SELECTORS
import { currentLang } from "../../../../store/features/translation/selectors";
import { languages } from "../../../../store/features/translation/Languages";

// CONSTANTS
import {
	colorInactiveItem,
	filterHighlightColor,
	deleteColor,
} from "../../../../constants/colors";

// HELPERS
import { isEmpty } from "../../../../helpers/functions";

// SVG ICONS
import CloseIcon from "../../../../assets/close-btn.svg";

const CollapseCardBody = ({
	currentLang = "EN",
	member = {},
	inAdding = false,
	inEditing = null,
	isLoadingSave = false,
	canEdit = false,
	canComplete = false,
	state = {},
	setState = () => {},
	onEdit = () => {},
	onComplete = () => {},
	onCancel = () => {},
	onDelete = () => {},
	onSave = () => {},
}) => {
	// DATA
	const AllCountries = require("country-telephone-data").allCountries.sort(
		(a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		}
	);

	function disableDates(date) {
		const each = new Date(date.getFullYear(), date.getMonth(), date.getDate());

		const min = new Date(state.CalendarMin);
		const max = new Date(state.CalendarMax);

		const minCalendarDate = new Date(
			min.getFullYear(),
			min.getMonth(),
			min.getDate()
		);
		const maxCalendarDate = new Date(
			max.getFullYear(),
			max.getMonth(),
			max.getDate()
		);

		const isGreaterThanMin = each.getTime() > minCalendarDate.getTime();
		const isLessThanMax = each.getTime() < maxCalendarDate.getTime();
		return isGreaterThanMin && isLessThanMax;
	}

	return (
		<div style={{ flex: 1 }} className="px-2 mx-0">
			{!(inAdding || inEditing) && (
				<>
					<div className="row">
						{!isEmpty(member.PickUpPlace) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									From:
								</InputLabel>
								{member.PickUpPlace}
							</div>
						)}

						{!isEmpty(member.DestinationPlace) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									To:
								</InputLabel>
								{member.DestinationPlace}
							</div>
						)}

						{!isEmpty(member.Name) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Name:
								</InputLabel>
								{member.Name}
							</div>
						)}

						{!isEmpty(member.NumberOfAdults) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Number Of Passenger:
								</InputLabel>
								{member.NumberOfAdults}
							</div>
						)}

						{!isEmpty(member.Contact) && (
							<div className="col-6 d-flex flex-column justify-content-center mb-4">
								<InputLabel className="mb-0" style={{ fontSize: 12 }}>
									Contact Number:
								</InputLabel>
								{member.Contact}
							</div>
						)}
					</div>

					<div className="d-flex justify-content-end">
						{member.CanEdit && (
							<Button
								onClick={onEdit}
								className=""
								style={{
									fontFamily: "inherit",
									textTransform: "initial",
									fontSize: 16,
									color: filterHighlightColor,
								}}
							>
								{languages[currentLang]["profile_vessel_edit"]}
							</Button>
						)}
						{member.CanComplete && (
							<Button
								onClick={onComplete}
								className=""
								style={{
									fontFamily: "inherit",
									textTransform: "initial",
									fontSize: 16,
									color: state.IsCompleted ? "red" : filterHighlightColor,
								}}
							>
								{state.IsCompleted
									? languages[currentLang]["trip_transfer_cancel_title"]
									: languages[currentLang]["trip_transfer_confirm_title"]}
							</Button>
						)}
					</div>
				</>
			)}

			{(inAdding || inEditing) && (
				<>
					<form className="row" autoComplete="off">
						{/* /.col-6 */}
						<div className="col-6 mb-4">
							<TextField
								label="From"
								variant="outlined"
								disabled={!state.IsArrival && !state.AllowFreeText}
								value={state.PickUpPlace}
								style={{
									opacity:
										!state.IsArrival && !state.AllowFreeText ? "0.5" : "1",
								}}
								onChange={(event) => {
									setState({
										...state,
										PickUpPlace: event.target.value,
									});
								}}
							/>
						</div>

						{/* /.col-6 */}
						<div className="col-6 mb-4">
							<TextField
								label="To"
								variant="outlined"
								style={{
									opacity:
										state.IsArrival && !state.AllowFreeText ? "0.5" : "1",
								}}
								value={state.DestinationPlace}
								onChange={(event) => {
									setState({
										...state,
										DestinationPlace: event.target.value,
									});
								}}
								disabled={state.IsArrival && !state.AllowFreeText}
							/>
						</div>

						<div className="col-6 mb-4">
							<KeyboardDateTimePicker
								clearable="true"
								value={state.PickUpDateTime}
								placeholder="10/10/2018"
								onChange={(selectedDate) => {
									try {
										const thisDate = new Date(
											selectedDate.getFullYear(),
											selectedDate.getMonth(),
											selectedDate.getDate()
										);
										const min = new Date(state.CalendarMin);
										const minCalendarDate = new Date(
											min.getFullYear(),
											min.getMonth(),
											min.getDate()
										);
										const isGreaterThanMin =
											thisDate.getTime() > minCalendarDate.getTime();
										const isArrival = !isGreaterThanMin;
										var DestinationPlace = state.DestinationPlace;
										var PickUpPlace = state.PickUpPlace;
										if (isArrival) {
											DestinationPlace = state.BaseStationName;
											PickUpPlace = "";
										} else {
											DestinationPlace = "";
											PickUpPlace = state.BaseStationName;
										}
										if (state.AllowFreeText) {
											DestinationPlace = state.DestinationPlace;
											PickUpPlace = state.PickUpPlace;
										}
										const date = dateFormat(
											new Date(selectedDate),
											"yyyy-MM-dd'T'HH:mm"
										);
										setState({
											...state,
											PickUpDateTime: date,
											IsArrival: isArrival,
											DestinationPlace: DestinationPlace,
											PickUpPlace: PickUpPlace,
										});
									} catch (e) {
										setState({
											...state,
											PickUpDateTime: null,
										});
									}
								}}
								format="yyyy-MM-dd'T'HH:mm"
								className="my-0"
								style={{
									fontFamily: "Dromon-light",
									width: "100%",
								}}
								minDateMessage=""
								inputVariant="outlined"
								variant="inline"
								autoOk={true}
								minDate={state.CalendarMin}
								maxDate={state.CalendarDayAfterMax}
								shouldDisableDate={disableDates}
							/>
						</div>

						{/* /.col-6 */}
						<div className="col-6 mb-4"></div>

						<div className="col-6 mb-4">
							<TextField
								label="Name"
								variant="outlined"
								value={state.Name}
								onChange={(event) => {
									setState({
										...state,
										Name: event.target.value,
									});
								}}
							/>
						</div>

						{/* /.col-6 */}
						<div className="col-6 mb-4">
							<TextField
								type="number"
								label="Passengers"
								variant="outlined"
								value={state.NumberOfAdults}
								onChange={(event) => {
									setState({
										...state,
										NumberOfAdults: event.target.value,
									});
								}}
							/>
						</div>
						{/* /.col-6 */}

						<div className="col-12 mb-4 d-flex">
							<div style={{ width: 110 }}>
								<TextField
									select
									label="Code"
									value={state.CodeNum}
									className="w-100"
									onChange={(event) =>
										setState({
											...state,
											CodeNum: event.target.value,
										})
									}
									variant="outlined"
								>
									<MenuItem value={0}></MenuItem>
									{AllCountries.map((country, index) => (
										<MenuItem key={index} value={"+" + country.dialCode}>
											{"+" + country.dialCode} | {country.name}
										</MenuItem>
									))}
								</TextField>
							</div>

							<div style={{ flex: 1 }}>
								<TextField
									type="number"
									label="Phone"
									variant="outlined"
									value={state.Contact}
									className="w-100"
									onChange={(event) => {
										setState({
											...state,
											Contact: event.target.value,
										});
									}}
								/>
							</div>
						</div>
						{/* /.col-6 */}
					</form>
					<div className="d-flex justify-content-end align-items-center">
						<Button
							onClick={onCancel}
							className="mr-2"
							style={{
								fontFamily: "inherit",
								textTransform: "initial",
								fontSize: 16,
								color: colorInactiveItem,
							}}
							disabled={isLoadingSave}
						>
							{languages[currentLang]["common_cancel"]}
						</Button>

						{inEditing && canEdit && (
							<Button
								onClick={onDelete}
								className="mr-2"
								style={{
									fontFamily: "inherit",
									textTransform: "initial",
									fontSize: 16,
									color: deleteColor,
								}}
							>
								{languages[currentLang]["common_delete"]}
							</Button>
						)}

						<Button
							onClick={() => onSave()}
							className=""
							style={{
								fontFamily: "inherit",
								textTransform: "initial",
								fontSize: 16,
								color: filterHighlightColor,
							}}
							disabled={isLoadingSave}
						>
							{isLoadingSave && (
								<i className="fas fa-spin fa-circle-notch mr-2" />
							)}
							{languages[currentLang]["common_save"]}
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentLang: currentLang(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseCardBody);

import { TileHelper } from "../../../TileHelper";
import { ApiClient } from "../../../ApiClient";
import { isEmpty } from "../../../helpers/functions";

var iMove = 0;

export const getPlacesToSave = () => {
	return JSON.parse(window.localStorage.getItem("placesToSave"));
};

export const onMove = ({ map, setLng, setLat, setZoom, propDispatch }) => {
	if (map) {
		map.on("move", () => {
			setLng(map.getCenter().lng.toFixed(4));
			setLat(map.getCenter().lat.toFixed(4));
			setZoom(map.getZoom().toFixed(2));

			const center = {
				lng: map.getCenter().lng.toFixed(4),
				lat: map.getCenter().lat.toFixed(4),
				zoom: map.getZoom().toFixed(2),
			};

			if (propDispatch)
				propDispatch({ type: "updatemapcenter", center: center });
		});
	}
};

export const onMoveEnds = async ({
	map,
	ref,
	placesRef,
	anchoragesRef,
	tripsRef,
	tripsExpRef,
	dispatch = () => {},
	onIncrementMove = () => {},
}) => {
	
	if (map) {
		map.on("moveend", async function () {
			var TEMP_PLACES_FEATURE = {
				type: "FeatureCollection",
				features: [],
			};

			const currentTripType = JSON.parse(
				window.localStorage.getItem("tripMapType")
			);
			const isBusiness = currentTripType === "business";
			const bounds = map.getBounds();

			const nw = bounds.getNorthWest();
			const se = bounds.getSouthEast();
			const zoom = Math.floor(map.getZoom());

			const pixelNW = TileHelper.latlongToPixelXY(nw.lat, nw.lng, zoom);
			const tileNW = TileHelper.PixelXYToTileXY(pixelNW.x, pixelNW.y);
			const pixelSE = TileHelper.latlongToPixelXY(se.lat, se.lng, zoom);
			const tileSE = TileHelper.PixelXYToTileXY(pixelSE.x, pixelSE.y);

			const promises = [];
			const places = [];
			const trips = [];
			const center = map.getCenter();
			window.localStorage.setItem(
				"latestPosition",
				JSON.stringify([center.lat, center.lng, zoom])
			);
			var addedPlaceXYs = getPlacesToSave();
			for (let y = tileNW.y; y <= tileSE.y; y++) {
				for (let x = tileNW.x; x <= tileSE.x; x++) {
					if (zoom > 7) {
						if (addedPlaceXYs != null && addedPlaceXYs?.length > 0) {
							var foundIndex = addedPlaceXYs.findIndex(
								(e) => e[0] === x && e[1] === y
							);
							if (foundIndex != -1) {
								addedPlaceXYs.splice(foundIndex, 1);
								window.localStorage.setItem(
									"placesToSave",
									JSON.stringify(addedPlaceXYs)
								);
								places.push(ApiClient.getPlaces(x, y, zoom, true));
							} else {
								places.push(ApiClient.getPlaces(x, y, zoom, false));
							}
						} else {
							places.push(ApiClient.getPlaces(x, y, zoom, false));
						}
						promises.push(ApiClient.getMTVessels(x, y, zoom));
					}
					trips.push(ApiClient.getTripTile(x, y, zoom, isBusiness));
				}
			}

			await Promise.all(promises).then((values) => {
				values.forEach((v) => {
					if (Array.isArray(v) && v.length > 0) {
						v.forEach((s) => {
							const ship = ref.features.find((f) => f.properties.id === s.Id);
							if (ship) {
								ship.geometry.coordinates = [
									s.Location.Longitude,
									s.Location.Latitude,
								];
							} else {
								const icon = s.Speed > 0.4 ? "vessel" : "vesselstop";
								const iconSize = s.Speed > 0.4 ? 0.25 : 0.3;
								ref.features.push({
									type: "Feature",
									properties: {
										id: s.Id,
										angle: s.Course - 90,
										icon: icon,
										iconSize: iconSize,
										name: s.Name,
										Speed: s.Speed,
									},
									geometry: {
										type: "Point",
										coordinates: [s.Location.Longitude, s.Location.Latitude],
									},
								});
							}
						});
					}
				});
			});

			await Promise.all(places).then((values) => {
				values.forEach((v) => {
					if (Array.isArray(v) && v.length > 0) {
						v.forEach((p) => {
							const place = TEMP_PLACES_FEATURE.features.find(
								(f) => f.properties.id === p.ID
							);
							const anchorage = anchoragesRef.features.find(
								(f) => f.properties.id === p.ID
							);
							if (!place) {
								TEMP_PLACES_FEATURE.features.push({
									id: p.ID,
									type: "Feature",
									properties: {
										id: p.ID,
										icon: p.Type,
										tags: p.Tags,
										suitability: p.Suitability,
									},
									geometry: {
										type: "Point",
										coordinates: [p.Location[0], p.Location[1]],
									},
								});
							}

							if (!anchorage && p.Type === "anchorage") {
								const invertedLatLong = p.Area.map((array) => [
									array[0],
									array[1],
								]);
								anchoragesRef.features.push({
									id: p.ID,
									type: "Feature",
									properties: { id: p.ID, icon: p.Type, tags: p.Tags },
									geometry: {
										type: "Polygon",
										coordinates: [invertedLatLong],
									},
								});
							}
						});
					}
				});
			});

			await Promise.all(trips).then((values) => {
				const trip = tripsRef.features.find(
					(f) => f.properties.type === currentTripType
				);
				if (!trip) {
					tripsRef.features = [];
				}
				values.forEach((v) => {
					if (Array.isArray(v) && v.length > 0) {
						v.forEach((p) => {
							const trip = tripsRef.features.find(
								(f) => f.properties.id === p.Id
							);
							if (!trip) {
								tripsRef.features.push({
									id: p.Id,
									type: "Feature",
									properties: { id: p.Id, data: p, tags: p.Tags, type: p.Type },
									geometry: {
										type: "Point",
										coordinates: [p.Point[0], p.Point[1]],
									},
								});
							}
						});
					}
				});
			});

			placesRef.features = TEMP_PLACES_FEATURE.features;
			updateDataSources();
			handleClosetCoastView();

			iMove++;
			onIncrementMove(iMove);
			updateUrl();
		});

		function updateUrl() {
			const ZOOM_ = Math.floor(map.getZoom());
			const CENTER_ = map.getCenter();
			const LOCATION_PATH =
				"@" + CENTER_.lat + "," + CENTER_.lng + "," + ZOOM_ + "z";

			const URL_PARTS = window.location.pathname.split("/");
			if (URL_PARTS.length > 2 && URL_PARTS[2] === "filter") {
				const VESSELS_TYPES_ = !isEmpty(URL_PARTS[3]) ? URL_PARTS[3] : "";
				const NEEDS_ =
					!isEmpty(VESSELS_TYPES_) && !isEmpty(URL_PARTS[4])
						? "/" + URL_PARTS[4]
						: "";
				const PLACE_TYPES_ =
					!isEmpty(NEEDS_) &&
					!isEmpty(URL_PARTS[5]) &&
					!!URL_PARTS[5].split("-")?.length &&
					!URL_PARTS[5].includes("@")
						? "/" + URL_PARTS[5]
						: "";
				const TAGS_ =
					!isEmpty(PLACE_TYPES_) &&
					!isEmpty(URL_PARTS[6]) &&
					!!URL_PARTS[6].split("-")?.length &&
					!URL_PARTS[6].includes("@")
						? "/" + URL_PARTS[6]
						: "";

				const NEW_URL = `https://${window.location.host}/explore/filter/${
					VESSELS_TYPES_ + NEEDS_ + PLACE_TYPES_ + TAGS_
				}`;

				window.history.pushState(null, null, NEW_URL + "/" + LOCATION_PATH);
				return;
			}

			if (
				(URL_PARTS.length > 2 && URL_PARTS[2] === "coastview") ||
				(URL_PARTS.length > 2 && URL_PARTS[2] === "places") ||
				(URL_PARTS.length > 2 && URL_PARTS[2] === "user") ||
				(URL_PARTS.length > 4 &&
					URL_PARTS[3] === "trips" &&
					(URL_PARTS[4] === "b" || URL_PARTS[4] === "c"))
			) {
				return;
			}

			const NEW_URL_ =
				"https://" + window.location.host + "/explore/" + LOCATION_PATH;
			window.history.pushState(null, null, NEW_URL_);
		}

		function updateDataSources() {
			if (!map || typeof map === "undefined") return;
			if (
				map &&
				map?.getSource &&
				//map?.getSource("places_source") &&
				map.getSource("anchorages_source") &&
				map.getSource("ships_source")
			) {
				//map.getSource("places_source").setData(placesRef);
				map.getSource("anchorages_source").setData(anchoragesRef);
				map.getSource("ships_source").setData(ref);
				map.getSource("tile_trips_source").setData(tripsRef);
			}
		}

		function handleClosetCoastView() {
			if (!map || typeof map === "undefined") return;
			var width = 300;
			var height = 300;
			const center = { lng: map.getCenter().lng, lat: map.getCenter().lat };
			const point = map.project(center);
			var bbox = [
				[point.x - width / 2, point.y - height / 2],
				[point.x + width / 2, point.y + height / 2],
			];
			const coastViewFeatures = map.queryRenderedFeatures(bbox, {
				layers: ["coastview"],
			});
			if (coastViewFeatures.length > 0) {
				const getClosest = coastViewFeatures[0].properties;
				const id = getClosest.id;
				const lineid = getClosest.lineid;

				ApiClient.getGTline(lineid).then((res) => {
					dispatch({
						type: "showClosestCVState",
						closestCV: { ...res, id },
					});
				});
			}
		}
	}
};

import React from "react";
import { ListItem, Collapse } from "@material-ui/core";

// CONSTANTS
import {
	shelter,
	filterDisabledItemColor,
	secondaryTxt,
} from "../../../constants/colors";

function CollapseCard({
	isCollapsed = false,
	collapsable = true,
	headerContent = React.Component,
	BodyContent = React.Component,
	style = {},
	className = "",
	headerProps = {},
	bodyProps = {},
	onCollapse = () => {},
}) {
	return (
		<div className={className} style={{ ...style }}>
			<ListItem
				{...{
					button: true,
					className: "py-2",
					style: {
						color: shelter,
						height: collapsable ? 85 : "auto",
						fontFamily: "inherit",
						borderTop: "solid 1px #edeef2",
						...(!collapsable ? { backgroundColor: "transparent" } : {}),
					},
					onClick: collapsable ? onCollapse : () => {},
					disableRipple: !collapsable,
					...headerProps,
				}}
			>
				{headerContent}

				{collapsable && (
					<span
						style={{
							color: "#9096a6",
							opacity: 0.5,
							fontSize: 16,
						}}
					>
						{isCollapsed ? (
							<i className="fas fa-fw fa-chevron-up" />
						) : (
							<i className="fas fa-fw fa-chevron-down" />
						)}
					</span>
				)}
			</ListItem>
			<Collapse
				{...{
					in: !isCollapsed,
					timeout: "auto",
					unmountOnExit: true,
					className: "p-2",
					style: {
						borderBottom: `solid 1px ${filterDisabledItemColor}`,
						color: secondaryTxt,
					},
					...bodyProps,
				}}
			>
				{BodyContent}
			</Collapse>
		</div>
	);
}

export default CollapseCard;

import React from "react";
import startIcon from "../../../assets/review/star_review_selected_ic.svg";
import startIconUnselected from "../../../assets/review/star_review_unselected_ic.svg";

const Ratings = (props) => {
	const { numberOfStars, uncheckedStars } = props;
	return (
		<>
			<div>
				{numberOfStars.map((start) => (
					<img
						key={start}
						className="mr-2"
						src={startIcon}
						alt="Start"
						style={{ height: 20, width: 20 }}
					/>
				))}
				{uncheckedStars.map((start) => (
					<img
						key={start}
						className="mr-2"
						src={startIconUnselected}
						alt="Start"
						style={{ height: 20, width: 20 }}
					/>
				))}
			</div>
		</>
	);
};

export default Ratings;

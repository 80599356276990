import styled, { css } from "styled-components";

export const ClosestCoastViewStyled = styled.div`
	width: 120px;
	height: 100px;
	position: absolute;
	top: -100px;
	right: 32px;
	z-index: 0;
	margin: 0;
	padding: 0;
	border-radius: 10px;
	box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.2);
	opacity: 0.5;
	transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	${(props) =>
		props.show &&
		css`
			top: 87px;
			opacity: 1;
			@media only screen and (max-width: 425px) {
				top: 150px;
				right: 10px;
			}
		`}
	&:hover {
		box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.35);
		cursor: pointer;
	}
`;

export const PhotoItem = styled.div`
	width: 120px;
	height: 100px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	transition: all 0.5s;
	border-radius: 10px;
	background-color: white;
`;

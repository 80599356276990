import { isEmpty } from "../../../helpers/functions";

export const userCurrent = ({ user }) => user;
export const userProfileId = ({ user }) => user.profileId;
export const userData = ({ user }) => user.data;
export const userSettings = ({ user }) =>
	!isEmpty(user.data?.Settings) ? user.data?.Settings : {};
export const userVessels = ({ user }) =>
	!isEmpty(user.data?.Vessels) ? user.data?.Vessels : [];
export const userTags = ({ user }) => user.data?.Tags;
export const authView = ({ user }) => user?.authView;
export const sidebarProfile = ({ user }) => user?.sidebarProfile;
export const sidebarEdit = ({ user }) => user?.sidebarEdit;
export const sidebarSettings = ({ user }) => user?.sidebarSettings;
export const sidebarVessels = ({ user }) => user?.sidebarVessels;
export const sidebarPartnerProvision = ({ user }) =>
	user?.sidebarPartnerProvision;
export const userIsLoggedIn = ({ user }) => isEmpty(user.data);

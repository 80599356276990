import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Switch, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// ACTIONS
import {
	removeTripNodeEditing,
	setMapFiltersSelected,
	updateNodeTripDuration,
	replaceShelterNode,
	updateMiddleNodes,
} from "../../../store/features/map/actions";

// SELECTORS
import { currentLang } from "../../../store/features/translation/selectors";
import { languages } from "../../../store/features/translation/Languages";
import { toggleShelterNode } from "../../../store/features/map/actions";
import {
	currentTripItinerary,
	selectedItineraryDay,
} from "../../../store/features/map/selectors";

// HELPERS
import { logEvent_ } from "../../../helpers/analytics";

// CONSTANTS
import {
	filterHighlightColor,
	formMainTxt,
	gray,
	secondaryTxt,
	shelter,
} from "../../../constants/colors";

// FILED COMPONENTS
import { FiledStart, FiledMiddle, FiledOvernight } from "./FiledStart";

// SVG ICONS
import EditIcon from "../../../assets/svg/itinerary/edit-active-ic.svg";
import AddFromMapIcon from "../../../assets/svg/itinerary/addfrommap_itinerary_emptystate.svg";

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		fontFamily: "dromon-l",
		color: formMainTxt,
		borderBottom: "none",
	},
}));

const PrimarySwitch = withStyles({
	switchBase: {
		color: filterHighlightColor,
		"&$checked": {
			color: filterHighlightColor,
		},
		"&$checked + $track": {
			backgroundColor: filterHighlightColor,
		},
	},
	checked: {},
	track: {},
})(Switch);

const EmptyStarting = (props) => {
	const classes = useStyles();
	const [toEdit, setToEdit] = useState(false);
	const currentLanguage = useSelector(currentLang);
	const currentTripId = useSelector(currentTripItinerary)?.data?.Id;

	return (
		<>
			<div
				className="d-flex flex-row mb-1 align-items-center icon-hours-a"
				style={{}}
				id="icon-hours-a"
			>
				<img
					src={EditIcon}
					alt="EditIcon"
					className="mr-3"
					style={{ height: 15, width: 14, cursor: "pointer" }}
					onClick={() => {
						if (props.canEdit) setToEdit(!toEdit);
					}}
				/>
				{!toEdit ? (
					<span
						onClick={() => {
							if (props.canEdit) setToEdit(!toEdit);
						}}
						style={{
							color: filterHighlightColor,
							fontSize: 16,
							cursor: "pointer",
						}}
						className="mr-3"
					>
						{props.startTime || "08:30"}
					</span>
				) : (
					<TextField
						id="time"
						label=""
						type="time"
						onBlur={() =>
							logEvent_("react_trip_itiner_starttimebtn_pressed", {
								tripId: currentTripId,
							})
						}
						value={props.startTime || "08:30"}
						onChange={(e) => props.handleChangeStartTime(e.target.value)}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300,
						}}
						size="small"
					/>
				)}
				<span style={{ color: formMainTxt, fontSize: 14 }} className="mr-3">
					{languages[currentLanguage]["trip_itinerary_start"]} :
				</span>
			</div>
			<div
				className="ml-1 pl-3 pb-3 pt-0"
				style={{ borderLeft: "1px dashed " + secondaryTxt }}
			></div>
		</>
	);
};

const EmptyMiddle = () => {
	const currentLanguage = useSelector(currentLang);
	return (
		<>
			<div>
				<div className="pl-0 py-0 my-0 d-flex flex-row align-items-center">
					<span
						className="mb-0"
						style={{
							border: "solid 1px #8f96a7",
							height: 10,
							width: 10,
							borderRadius: 5,
						}}
					></span>
					<span
						className="dromon-l mb-0 ml-3"
						style={{ color: secondaryTxt, fontSize: 12 }}
					>
						{/* Route (requires calculation) */}
						{languages[currentLanguage]["trip_itinerary_route_tip"]}
					</span>
				</div>
			</div>
			<div
				className="ml-1 pl-3 pb-3 pt-3"
				style={{ borderLeft: "1px dashed " + secondaryTxt }}
			></div>
		</>
	);
};

const EmptyOvernight = ({ durationDays, attributes = [] }) => {
	const [, setIsCircular] = useState(false);
	const currentLanguage = useSelector(currentLang);

	useEffect(() => {
		setIsCircular(attributes.includes("uri:trip:attr:direction:circular"));
	}, [durationDays, attributes]);

	return (
		<div className="pb-4">
			<div
				className="ml-1 pl-3 pb-4 pt-3"
				style={{ borderLeft: "1px dashed " + secondaryTxt }}
			></div>
			<div className="pl-0 py-0 my-0 d-flex flex-row justify-content-between align-items-center">
				<span
					className="mb-0"
					style={{
						height: 10,
						backgroundColor: secondaryTxt,
						width: 10,
						borderRadius: 5,
					}}
				/>
			</div>
		</div>
	);
};

const DayState = ({
	launchPoint,
	shelterPoint,
	tripNodes,
	startTime,
	vessel,
	attributes,
	durationDays,
	indexDay,
	showFilter = () => {},
	updateNodeTripDuration = () => {},
	removeTripNodeEditing = () => {},
	updateMiddleNodes = () => {},
	handleChangeStartTime = () => {},
	onOpenNoteEdit = () => {},
	replaceShelterNode = () => {},
	openPlace = () => {},
}) => {
	// DISPATCHER
	const dispatcher = useDispatch();

	// SELECTORS
	const CURRENT_SHELTER_POINT = useSelector(selectedItineraryDay).shelterPoint;
	const currentTripItinerary_ = useSelector(currentTripItinerary);
	const updateDuration = (index, value) => {
		updateNodeTripDuration(index, value);
	};
	const handleOnDragEnd = (result) => {
		if (!result.destination) return;

		const items = Array.from(tripNodes);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		updateMiddleNodes(items);
	};

	return (
		<div>
			{launchPoint === null ? (
				<EmptyStarting
					onClickAdd={() => {
						showFilter({
							vessel,
							need: "lanchpoint",
						});
					}}
					startTime={startTime}
					handleChangeStartTime={handleChangeStartTime}
					canEdit={currentTripItinerary_.canEdit}
				/>
			) : (
				<FiledStart
					openPlace={() => {
						openPlace(launchPoint.PlaceId);
					}}
					indexDay={indexDay}
					startTime={startTime}
					handleChangeStartTime={handleChangeStartTime}
					place={launchPoint}
					onOpenNoteEdit={() =>
						onOpenNoteEdit(0, launchPoint.Note, launchPoint.Order)
					}
					removeTripNode={() => removeTripNodeEditing(launchPoint)}
					canEdit={currentTripItinerary_.canEdit}
				/>
			)}

			{tripNodes?.length ||
			shelterPoint ||
			(attributes.includes("uri:trip:attr:direction:circular") &&
				indexDay + 1 === durationDays) ? (
				<>
					{tripNodes?.length <= 0 ? (
						<EmptyMiddle
							onClickAdd={() => {
								logEvent_("react_trip_itiner_addexperiebtn_pressed", {
									tripId: currentTripItinerary_?.data?.Id,
								});
								showFilter({
									vessel,
									need: "experience",
								});
							}}
							canEdit={currentTripItinerary_.canEdit}
						/>
					) : (
						<DragDropContext onDragEnd={handleOnDragEnd}>
							<Droppable droppableId="characters">
								{(provided) => (
									<ul
										className="characters px-0"
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{tripNodes?.map((place, index) => {
											return (
												<Draggable
													key={place?.PlaceId?.toString()}
													draggableId={place?.PlaceId?.toString()}
													index={index}
												>
													{(provided) => (
														<li
															className="m-0 p-0"
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<FiledMiddle
																place={place}
																openPlace={() => openPlace(place.PlaceId)}
																canEdit={currentTripItinerary_.canEdit}
																idxNode={index}
																increaseDuration={() =>
																	updateDuration(index, 0.5)
																}
																decreaseDuration={() =>
																	updateDuration(index, -0.5)
																}
																removeTripNode={() =>
																	removeTripNodeEditing(place, index)
																}
																onOpenNoteEdit={() => {
																	onOpenNoteEdit(
																		index + 1,
																		place.Note,
																		place.Order
																	);
																}}
																isCircular={attributes.includes(
																	"uri:trip:attr:direction:circular"
																)}
																end={
																	indexDay + 1 === durationDays &&
																	attributes.includes(
																		"uri:trip:attr:direction:circular"
																	)
																}
																replaceShelterNode={() => {
																	logEvent_(
																		"react_trip_itiner_promonightbtn_pressed",
																		{ tripId: currentTripItinerary_?.data?.Id }
																	);
																	replaceShelterNode(place);
																}}
															/>
														</li>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</ul>
								)}
							</Droppable>
						</DragDropContext>
					)}
					{(shelterPoint !== null && indexDay + 1 !== durationDays) ||
					(launchPoint !== null &&
						attributes.includes("uri:trip:attr:direction:circular") &&
						indexDay + 1 === durationDays) ||
					(shelterPoint !== null &&
						!attributes.includes("uri:trip:attr:direction:circular")) ? (
						<FiledOvernight
							place={
								attributes.includes("uri:trip:attr:direction:circular") &&
								durationDays === 1
									? launchPoint
									: attributes.includes("uri:trip:attr:direction:circular") &&
									  indexDay + 1 === durationDays
									? currentTripItinerary_?.daysList[0].EditableNodes[0]
									: shelterPoint
							}
							openPlace={() =>
								openPlace(
									attributes.includes("uri:trip:attr:direction:circular") &&
										durationDays === 1
										? launchPoint.PlaceId
										: attributes.includes("uri:trip:attr:direction:circular") &&
										  indexDay + 1 === durationDays
										? currentTripItinerary_?.daysList[0].EditableNodes[0]
												.PlaceId
										: shelterPoint.PlaceId
								)
							}
							removeTripNode={() => removeTripNodeEditing(shelterPoint)}
							onOpenNoteEdit={() =>
								onOpenNoteEdit(
									currentTripItinerary_?.daysList?.length - 1,
									shelterPoint.Note,
									shelterPoint.Order
								)
							}
							end={
								indexDay + 1 === durationDays &&
								attributes.includes("uri:trip:attr:direction:circular")
							}
							isCircular={attributes.includes(
								"uri:trip:attr:direction:circular"
							)}
							canEdit={currentTripItinerary_.canEdit}
							canCalculate={currentTripItinerary_.canEdit}
						/>
					) : (
						<EmptyOvernight
							onClickAdd={() => {
								logEvent_("react_trip_itiner_addnightbtn_pressed", {
									tripId: currentTripItinerary_?.data?.Id,
								});
								showFilter({
									vessel,
									need:
										indexDay + 1 === durationDays ? "lanchpoint" : "shelter",
								});
							}}
							canEdit={currentTripItinerary_.canEdit}
							attributes={attributes}
							durationDays={durationDays}
							indexDay={indexDay}
						/>
					)}
				</>
			) : (
				<div className="d-flex flex-column justify-content-center align-items-center py-5">
					<img src={AddFromMapIcon} className="mb-4" />

					<h5 style={{ color: shelter }}>Add from map</h5>
					<p style={{ color: gray, fontSize: 13 }}>
						Once finished, click ✓ to save the day
					</p>
				</div>
			)}

			{!(
				attributes.includes("uri:trip:attr:direction:circular") &&
				indexDay + 1 === durationDays
			) && (
				<div
					style={{
						opacity: !(
							currentTripItinerary_?.selectedDay?.tripNodes?.length ||
							currentTripItinerary_?.selectedDay?.launchPoint ||
							currentTripItinerary_?.selectedDay?.shelterPoint
						)
							? 0.7
							: 1,
						fontSize: 14,
					}}
				>
					<PrimarySwitch
						color="primary"
						id="night-shelter-switch"
						disabled={
							!(
								currentTripItinerary_?.selectedDay?.tripNodes?.length ||
								currentTripItinerary_?.selectedDay?.launchPoint ||
								currentTripItinerary_?.selectedDay?.shelterPoint
							)
						}
						checked={!!CURRENT_SHELTER_POINT}
						onChange={() => dispatcher(toggleShelterNode())}
					/>
					<label htmlFor="night-shelter-switch">
						The last point is an overnight stay
					</label>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMapFiltersSelected: (filterData = {}) =>
			dispatch(setMapFiltersSelected(filterData)),
		updateNodeTripDuration: (nodeIndex, numberToUpdate) =>
			dispatch(updateNodeTripDuration(nodeIndex, numberToUpdate)),
		removeTripNodeEditing: (nodeData = {}, idxNode = null) =>
			dispatch(removeTripNodeEditing(nodeData, idxNode)),
		replaceShelterNode: (placeData = {}) =>
			dispatch(replaceShelterNode(placeData)),
		updateMiddleNodes: (middleNodes = []) =>
			dispatch(updateMiddleNodes(middleNodes)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DayState);

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { Steps } from "intro.js-react";

// HELPERS
import { ApiClient } from "../../ApiClient";
import { AuthService } from "../../AuthService";
import { logEvent_ } from "../../helpers/analytics";
import { isEmpty } from "../../helpers/functions";

// ACTIONS
import {
	setTripItineraryVisibility,
	retrieveTripDaysList,
	calculateDayTrip,
	setTripItinerary,
	updateIndexTripDay,
} from "../../store/features/map/actions";

// SELECTORS
import {
	tripItineraryVisibility,
	currentTripItinerary,
	selectedItineraryDay,
} from "../../store/features/map/selectors";
import { userData as currentUserData_ } from "../../store/features/user/selectors";
import { currentLang } from "../../store/features/translation/selectors";
import { languages } from "../../store/features/translation/Languages";

// COMPONENTS
import SideBar from "../utils/SideBar";
import DayState from "./components/DayState";
import NoEditDayState from "./components/NoEditDayState";

// CONSTANTS
import {
	filterHighlightColor,
	colorInactiveItem,
	shelter,
	gray,
} from "../../constants/colors";
import { sidebarWidth } from "../utils/SideBar/styled";
import TRIP_USER_ROLES from "../../constants/tripUserRoles";

// ICONS
import EditItineraryIcon from "../../assets/svg/itinerary/edit_Itinerary.svg";
import CheckSaveIcon from "../../assets/svg/itinerary/check_save.svg";
import {
	convertDistanceToMyUnit,
	convertFuelToMyUnit,
	convertSpeedToMyUnit,
} from "../../helpers/UnitConverter";

const Itinerary = ({
	setTripItineraryVisibility = () => {},
	retrieveTripDaysList = (_tripId = Number) => {},
	calculateDayTrip = async (_trip = null) => {},
	setTripItinerary = (_data, _combine) => {},
	updateIndexTripDay = (_index) => {},
	displayOverview = () => {},
	showFilter = () => {},
	openWizard = () => {},
	dispatch = () => {},
	setOnSetNightShelter = () => {},
}) => {
	// SELECTORS
	const currentLanguage = useSelector(currentLang);
	const visible = useSelector(tripItineraryVisibility);
	const selectedTripItinerary = useSelector(currentTripItinerary);
	const selectedTripItineraryDay = useSelector(selectedItineraryDay);
	const currentUserData = useSelector(currentUserData_);

	// STATES
	const [currentTripID, setCurrentTripID] = useState(null);
	const [loading, setLoading] = useState(true);
	const [enableOnboard, setEnableOnboard] = useState(false);
	const [tripVessel, setTripVessel] = useState(null);

	// DATA
	const steps = [
		{
			element: "#icon-hours-a",
			intro: languages[currentLanguage]["journey_itinerary_a"],
			position: "auto",
		},
		{
			element: ".icon-starting",
			intro: languages[currentLanguage]["journey_itinerary_b"],
			position: "auto",
		},
		{
			element: ".add-place-top",
			intro: languages[currentLanguage]["journey_itinerary_c"],
			position: "auto",
		},
		{
			element: ".icon-middle",
			intro: languages[currentLanguage]["journey_itinerary_d"],
			position: "auto",
		},
		{
			element: ".icon-end",
			intro: languages[currentLanguage]["journey_itinerary_e"],
			position: "auto",
		},
		{
			element: ".calculate-btn",
			intro: languages[currentLanguage]["journey_itinerary_f"],
			position: "auto",
		},
	];

	// FUNCTION
	const handleCalculate = (newTripItinerary = selectedTripItinerary) => {
		setLoading(true);
		(async () => {
			await calculateDayTrip(newTripItinerary);
			setLoading(false);
		})();
	};

	const handleEdit = () => {
		setTripItinerary({
			selectedDay: {
				...selectedTripItineraryDay,
				inEditing: true,
			},
		});
	};

	const onOpenNoteEdit = (idxNode, note, order) => {
		setTripItineraryVisibility(false);
		setTripItinerary({
			selectedDay: {
				...selectedTripItineraryDay,
				selectedNode: {
					visibility: true,
					index: idxNode,
					note: note,
					order: order,
				},
			},
		});
	};

	const handleChangeStartTime = (startTime) => {
		setTripItinerary(
			{
				selectedDay: {
					...selectedTripItineraryDay,
					startTime,
				},
			},
			true
		);
	};

	const onClose = () => {
		displayOverview(selectedTripItinerary.data.Id);
		setTripItineraryVisibility(false);
	};

	const updateItineraryUrl = (currectDay = 1) => {
		const TRIP_DATA = selectedTripItinerary?.data;
		const TEMP_TRIP_CREATOR = TRIP_DATA?.Members?.filter(
			(u) => u.Role === TRIP_USER_ROLES.CREATOR
		)[0];
		const TRIP_CREATOR =
			TEMP_TRIP_CREATOR?.UserId === currentUserData?.ID
				? currentUserData
				: TEMP_TRIP_CREATOR;

		if (TRIP_CREATOR?.UserName && TRIP_DATA.ShareGUID && TRIP_DATA.Name) {
			const HOST_NAME = window.location.host;
			const FORMATTED_CREATOR_NAME =
				typeof TRIP_CREATOR.UserName === "string"
					? TRIP_CREATOR.UserName.replace(
							new RegExp(/[ ]/, "g"),
							"-"
					  ).toLowerCase()
					: "";
			const FORMATTED_TRIP_NAME =
				typeof TRIP_DATA.Name === "string"
					? TRIP_DATA.Name.replace(new RegExp(/[ ]/, "g"), "-").toLowerCase()
					: "";
			const URL_TRIP_TYPE = TRIP_DATA?.Type === "business" ? "b" : "c";
			const TRIP_SHARE_GUID = TRIP_DATA.ShareGUID;

			const NEW_URL = `https://${HOST_NAME}/explore/user/${FORMATTED_CREATOR_NAME}/trips/${URL_TRIP_TYPE}/${FORMATTED_TRIP_NAME}/${TRIP_SHARE_GUID}/itinerary/day-${currectDay}`;

			window.history.pushState(null, null, NEW_URL);
		}
	};

	const loadData = async (forceLoad = false) => {
		setLoading(true);

		if (visible) {
			const _TRIP_DATA = selectedTripItinerary?.data;

			if (isEmpty(_TRIP_DATA)) {
				const trips = await ApiClient.getMyCreatedTrips();

				if (
					!trips ||
					trips.ErrorCode ||
					trips.ErrorMessage ||
					trips.length === 0
				) {
					// OPEN TRIP WIZARD
					setTripItineraryVisibility(false);
					openWizard();
					return null;
				}

				const trip = trips[0];
				const currentMember = trip?.Members?.find(
					(m) => m?.UserId?.toString() === currentUserData?.ID?.toString()
				);
				const canEdit_ =
					(currentMember?.Role === "creator" ||
						currentMember?.Role === "skipper" ||
						currentMember?.Role === "superpassenger" ||
						currentMember?.Role === "passengerskipper" ||
						currentMember?.Role === "cocreator") &&
					AuthService.isUserLogedIn();

				setTripItinerary({
					data: trips[0],
					canEdit: canEdit_,
				});
				setCurrentTripID(trips[0].Id);
				retrieveTripDaysList(trips[0].Id);
			}

			if (
				isEmpty(currentTripID) ||
				currentTripID !== _TRIP_DATA.Id ||
				forceLoad
			) {
				setTripVessel(selectedTripItinerary?.data?.Vessel);
				setCurrentTripID(_TRIP_DATA.Id);
				retrieveTripDaysList(_TRIP_DATA.Id);
			}

			updateItineraryUrl((selectedTripItinerary?.selectedDay?.index ?? 0) + 1);
			setLoading(false);
		}
	};

	const getTotalDayDistance = (dayIndex = 0, withUnit = true) => {
		let distanceCounter = 0;
		let distanceUnit = "nm";

		selectedTripItinerary.daysList[dayIndex].Nodes.map((node) => {
			if (node?.Distance) {
				distanceCounter += node.Distance;
			}
		});

		// if (AuthService.isUserLogedIn) {
		// 	distanceUnit = currentUserData?.Settings?.DistanceUnits ?? distanceUnit;
		// 	distanceCounter = convertDistanceToMyUnit(distanceCounter, distanceUnit);
		// }

		return `${Math.round(distanceCounter)} ${withUnit ? distanceUnit : ""}`;
	};

	const getTotalDayFuel = (dayIndex = 0, withUnit = true) => {
		if (!tripVessel?.FuelConsumptionLiters) {
			return "";
		}

		const TOTAL_DISTANCE = parseInt(getTotalDayDistance(dayIndex, false));
		let fuelUnit = "lt";
		let speedUnit = "kts";
		let fuelValue = tripVessel?.FuelConsumptionLiters ?? 0;
		let speedValue = tripVessel?.SpeedKnots ?? 0;
		let totalFuel = 0;

		if (AuthService.isUserLogedIn) {
			fuelUnit = currentUserData?.Settings?.FuelUnit ?? fuelUnit;
			speedUnit = currentUserData?.Settings?.SpeedUnit ?? speedUnit;
			fuelValue = convertFuelToMyUnit(fuelValue, fuelUnit);
			speedValue = convertSpeedToMyUnit(speedValue, speedUnit);
		}

		totalFuel = (fuelValue / speedValue) * TOTAL_DISTANCE;

		return `${Math.round(totalFuel)} ${withUnit ? fuelUnit : ""}`;
	};

	// EFFECTS
	useEffect(() => {
		loadData();
		return () => {};
	}, [visible, selectedTripItinerary]);

	useEffect(() => {
		setTimeout(() => {
			if (
				!window.localStorage.getItem("isTripVisited") &&
				visible &&
				!loading &&
				selectedTripItineraryDay.inEditing &&
				selectedTripItineraryDay.tripNodes?.length === 0 &&
				!selectedTripItineraryDay.shelterPoint &&
				!selectedTripItineraryDay.launchPoint &&
				selectedTripItinerary?.canEdit
			) {
				setEnableOnboard(true);
			}
		}, 2000);
	}, [
		visible,
		selectedTripItineraryDay.inEditing,
		selectedTripItinerary.canEdit,
	]);

	return (
		<SideBar
			visible={visible}
			isLoading={loading}
			useBackBtn
			back_sticky
			back_title={languages[currentLanguage]["trip_itinerary_itinerary"]}
			back_subTitle={
				<>
					{"Day " + (selectedTripItineraryDay?.index + 1)}{" "}
					{selectedTripItineraryDay.inEditing && (
						<span
							className="text-white font-weight-bold p-1 rounded"
							style={{
								backgroundColor: colorInactiveItem,
								color: colorInactiveItem,
							}}
						>
							Edit mode
						</span>
					)}
				</>
			}
			backSubTitleStyle={{
				color: filterHighlightColor,
				fontSize: 14,
				marginTop: 6,
			}}
			onClickBack={onClose}
		>
			<Steps
				enabled={enableOnboard}
				steps={steps}
				initialStep={0}
				onExit={() => {
					setEnableOnboard(false);
					// Update the localStorage
					window.localStorage.setItem(
						"isTripVisited",
						JSON.stringify({ isTripVisited: true })
					);
				}}
			/>

			<div
				className="d-flex align-items-center justify-content-between"
				style={{
					position: "fixed",
					top: 83,
					left: sidebarWidth - 130,
					padding: 0,
					zIndex: 9999,
				}}
			>
				<IconButton
					className="ml-2"
					style={{
						fontSize: 14,
					}}
					onClick={() => {
						logEvent_("react_trip_itiner_headrefreshbtn_pressed", {
							trip: selectedTripItinerary?.data?.Id,
						});
						loadData(true);
					}}
				>
					<i className="fa fa-fw fa-refresh" />
				</IconButton>

				{selectedTripItinerary.canEdit && (
					<>
						{selectedTripItineraryDay.inEditing ? (
							<IconButton
								title={
									languages[currentLanguage]["trip_itinerary_calculate_button"]
								}
								className="ml-2"
								style={{
									backgroundColor: filterHighlightColor,
								}}
								onClick={
									!selectedTripItineraryDay?.shelterPoint &&
									!(
										selectedTripItinerary?.data?.Attributes.includes(
											"uri:trip:attr:direction:circular"
										) &&
										selectedTripItineraryDay?.index ===
											selectedTripItinerary?.daysList?.length - 1
									)
										? () => setOnSetNightShelter(handleCalculate)
										: () => handleCalculate(selectedTripItinerary)
								}
							>
								<img src={CheckSaveIcon} style={{ width: 14, height: 14 }} />
							</IconButton>
						) : (
							<IconButton
								title={languages[currentLanguage]["trip_itinerary_day_edit"]}
								className="ml-2"
								style={{
									backgroundColor: filterHighlightColor,
								}}
								onClick={handleEdit}
							>
								<img
									src={EditItineraryIcon}
									style={{ width: 14, height: 14 }}
								/>
							</IconButton>
						)}
					</>
				)}
			</div>

			<div>
				{selectedTripItinerary?.daysList?.map((item, idx) => (
					<div key={idx}>
						{selectedTripItineraryDay.index === idx &&
						selectedTripItineraryDay.inEditing ? (
							<div className="container-fluid">
								<div
									style={{
										backgroundColor: "#fbfbfb",
										height: 50,
										cursor: "pointer",
									}}
									className="d-flex flex-row align-items-center justify-content-between px-4 mb-4"
								>
									<span style={{ color: shelter, fontSize: 16 }}>
										Day {idx + 1}
										<span
											className="ml-3"
											style={{ color: gray, fontSize: 12 }}
										>
											{getTotalDayDistance(idx)}
											{!isEmpty(getTotalDayDistance(idx, false)) &&
											!isEmpty(getTotalDayFuel(idx, false))
												? " - "
												: " "}
											{getTotalDayFuel(idx)}
										</span>
									</span>
									<i
										style={{ color: "#9096a6", width: 16 }}
										className={
											selectedTripItineraryDay.index === idx
												? "fas fa-chevron-down"
												: "fas fa-chevron-up"
										}
									></i>
								</div>
								{selectedTripItineraryDay.index === idx && (
									<div className="px-3">
										<DayState
											openPlace={(placeId) => {
												dispatch({
													type: "updateSidebarPlacesState",
													place: {
														id: placeId,
													},
												});
											}}
											launchPoint={selectedTripItineraryDay.launchPoint}
											shelterPoint={selectedTripItineraryDay.shelterPoint}
											tripNodes={selectedTripItineraryDay.tripNodes}
											startTime={selectedTripItineraryDay.startTime}
											handleChangeStartTime={handleChangeStartTime}
											onOpenNoteEdit={onOpenNoteEdit}
											showFilter={showFilter}
											vessel={selectedTripItinerary?.data.VesselType}
											attributes={selectedTripItinerary?.data.Attributes}
											durationDays={selectedTripItinerary?.data.DurationDays}
											indexDay={selectedTripItineraryDay.index}
										/>
									</div>
								)}
							</div>
						) : (
							<div>
								<div className="container-fluid">
									<div
										onClick={() => {
											logEvent_("react_trip_itiner_dayexpanderbtn_pressed", {
												trip: selectedTripItinerary?.data?.Id,
											});
											updateIndexTripDay(idx);
											if (
												!!selectedTripItinerary?.daysList[idx].EditableNodes
													?.length
											) {
												updateItineraryUrl(idx + 1);
											}
										}}
										style={{
											backgroundColor: "#fbfbfb",
											height: 50,
											cursor: "pointer",
										}}
										className="d-flex flex-row align-items-center justify-content-between px-4 mb-4"
									>
										<span style={{ color: shelter, fontSize: 16 }}>
											Day {idx + 1}
											<span
												className="ml-3"
												style={{ color: gray, fontSize: 12 }}
											>
												{getTotalDayDistance(idx)}
												{!isEmpty(getTotalDayDistance(idx, false)) &&
												!isEmpty(getTotalDayFuel(idx, false))
													? " - "
													: " "}
												{getTotalDayFuel(idx)}
											</span>
										</span>
										<i
											style={{ color: gray, width: 16 }}
											className={
												selectedTripItineraryDay.index === idx
													? "fas fa-chevron-down"
													: "fas fa-chevron-up"
											}
										></i>
									</div>

									{selectedTripItineraryDay.index === idx && (
										<div className="px-3">
											<NoEditDayState
												openPlace={(placeId) => {
													dispatch({
														type: "updateSidebarPlacesState",
														place: {
															id: placeId,
														},
													});
												}}
												nodes={item.Nodes}
												data={{ item: item, day: selectedTripItinerary }}
												StartTime={item.StartTime}
												speed={
													selectedTripItinerary?.data?.Vessel?.SpeedKnots
														? Math.round(
																selectedTripItinerary?.data?.Vessel?.SpeedKnots
														  )
														: undefined
												}
												fuel={tripVessel?.FuelConsumptionLiters ?? undefined}
												onOpenNoteEdit={onOpenNoteEdit}
												canEdit={selectedTripItinerary.canEdit}
												attributes={selectedTripItinerary?.data.Attributes}
												durationDays={selectedTripItinerary?.data.DurationDays}
												indexDay={selectedTripItineraryDay.index}
											/>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		</SideBar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setTripItineraryVisibility: (bool = false) =>
			dispatch(setTripItineraryVisibility(bool)),
		retrieveTripDaysList: (tripId) => dispatch(retrieveTripDaysList(tripId)),
		calculateDayTrip: async (selectedTrip) =>
			dispatch(await calculateDayTrip(selectedTrip)),
		setTripItinerary: (data, combine = true) =>
			dispatch(setTripItinerary(data, combine)),
		updateIndexTripDay: (index = Number) => dispatch(updateIndexTripDay(index)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Itinerary);

import React from "react";
import { connect, useSelector } from "react-redux";
import {
	Avatar,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Snackbar,
	Tooltip,
} from "@material-ui/core";
import branch from "branch-sdk";
import Alert from "@material-ui/lab/Alert";

// API
import { ApiClient } from "../../ApiClient";

// HELPERS
import { isEmpty } from "../../helpers/functions";
import { generateTripInviteLink } from "../../shareConfig";
import { logEvent_ } from "../../helpers/analytics";
import { useStyles } from "../map/Popup";

// SELECTORS
import { currentLang } from "../../store/features/translation/selectors";
import { userData, userProfileId } from "../../store/features/user/selectors";

// ACTIONS
import {
	closeUserSidebars,
	setUserProfileId,
	showUserProfile,
} from "../../store/features/user/actions";

import { Sidebar } from "../PlacesDetailsSidebar/styled";
import { SidebarContent } from "../PlacesDetailsSidebar/styled";
import { languages } from "../../store/features/translation/Languages";
import {
	filterHighlightColor,
	formMainTxt,
	whiteColor,
} from "../../constants/colors";

// SVG ICONS
import SvgPlus from "../../assets/svg/plus.svg";
import creator from "../../assets/svg/crew/creator_badge_ic.svg";
import skipper from "../../assets/svg/crew/editor_badge_ic.svg";
import deleteIcon from "../../assets/svg/delete-ic-n.svg";

const Crew = ({
	visible,
	tripId,
	closeSidebar,
	showUserProfile,
	setUserProfileId,
	handleTripOverviewSidebars = () => {},
}) => {
	// STYLES
	const classes = useStyles();

	// SELECTORS
	const currentUserData = useSelector(userData),
		selectedUserProfileId = useSelector(userProfileId),
		currentLanguage = useSelector(currentLang);

	// STATES
	const [displayedTrips, setdisplayedTrips] = React.useState([]),
		[isOwner, setIsOwner] = React.useState(false),
		[inviteDialog, setInviteDialog] = React.useState(false),
		[loading, setLoading] = React.useState(false),
		[snackbarClipboard, setSnackbarClipboard] = React.useState(false),
		[generatedInviteLink, setGeneratedInviteLink] = React.useState(null),
		[trip, setTrip] = React.useState({}),
		[role, setRole] = React.useState("");

	const [possibleRoles, setPossibleRoles] = React.useState([]);
	const [allowedInvites, setAllowedInvites] = React.useState([]);

	const [notice, setNotice] = React.useState({ type: "", content: "" });

	const iconDictionary = {
		creator,
		skipper,
	};

	// FUNCTIONS
	const copyToClipboard = (str = "") => {
		const el = window.document.createElement("textarea");
		el.value = str;
		window.document.body.appendChild(el);
		el.select();
		window.document.execCommand("copy");
		window.document.body.removeChild(el);
		navigator.clipboard.writeText(str);
		setSnackbarClipboard(true);
	};

	const deleteMember = (member) => {
		setLoading(true);
		ApiClient.deleteMember(trip?.Id, member.UserId, member.Role)
			.then((res) => {
				if (!res?.ErrorMessage && !res?.ErrorCode) {
					// updateData();
					ApiClient.getTrip(tripId)
						.then((tr) => {
							const user = tr.Members?.filter(
								(u) => u.UserId === currentUserData?.ID
							);
							setRole(user?.length >= 1 ? user[0].Role : "no-member");
							if (visible) {
							} else {
							}
							setTrip(tr);
							setLoading(false);
						})
						.catch((error) => {
							//console.log("ERROR ==>", error);
							setLoading(false);
						});
				}
			})
			.catch((error) => {
				//console.log("ERRORR ==>", error);
				setLoading(false);
			});
	};

	const updateRole = (role, member) => {
		setLoading(true);
		ApiClient.editMemberRole(trip?.Id, member.UserId, role)
			.then((res) => {
				if (!res?.ErrorMessage && !res?.ErrorCode) {
					// updateData();
					ApiClient.getTrip(tripId)
						.then((tr) => {
							const user = tr.Members?.filter(
								(u) => u.UserId === currentUserData?.ID
							);
							setRole(user?.length >= 1 ? user[0].Role : "no-member");
							if (visible) {
							} else {
							}
							setTrip(tr);
							setLoading(false);
						})
						.catch((error) => {
							//console.log("ERROR ==>", error);
							setLoading(false);
						});
				} else {
					setLoading(false);
					if (res?.ErrorCode === "uri:KeeanoWS:MaxNumberRoleReached") {
						const message = "There can only be one " + res?.ErrorMessage;
						setNotice({ content: message, type: "ERROR" });
						return;
					}
					if (res?.ErrorMessage) {
						setNotice({ content: res.ErrorMessage, type: "ERROR" });
					}
				}
			})
			.catch((error) => {});
	};

	const handleInvite = () => {
		logEvent_("react_trip_addcrewmemberbutton_pressed", { tripId });

		setInviteDialog(true);
	};

	const onSelectInvitation = (Role, GUID) => {
		if (GUID === null) return;
		const linkData = generateTripInviteLink(
			trip.Id,
			GUID,
			trip.Cover ? trip.Cover : "",
			trip?.Name,
			trip?.Description,
			Role,
			!!trip?.HasCharterListsEnabled
		);

		branch.link(linkData, function (err, link) {
			setGeneratedInviteLink(link);
		});
	};

	const onShowUserProfile = (userId = null) => {
		handleTripOverviewSidebars();
		setUserProfileId(userId);
		showUserProfile();
	};

	// EFFECTS
	React.useEffect(() => {
		setLoading(true);
		// Load trip
		if (visible) {
			ApiClient.getTrip(tripId)
				.then((tr) => {
					if (tr.Invitations) {
						const allRoles = tr.Invitations.map((e) => e.Role);
						setPossibleRoles(allRoles);
					}
					const user = tr.Members?.filter(
						(u) => u.UserId === currentUserData?.ID
					);
					setRole(user?.length >= 1 ? user[0].Role : "no-member");
					setTrip(tr);
					let currentRole = user[0]?.Role;
					let allInvites = tr.Invitations ?? [];
					var invites = [];
					if (currentRole === "creator" || currentRole === "cocreator") {
						invites = allInvites;
					}
					if (currentRole === "superpassenger") {
						invites = allInvites.filter(
							(u) => u.Role === "passenger" || u.Role === "passengerskipper"
						);
					}
					if (
						currentRole === "passenger" ||
						currentRole === "passengerskipper"
					) {
						invites = allInvites.filter((u) => u.Role === "passenger");
					}
					setAllowedInvites(invites);

					setLoading(false);
				})
				.catch((error) => {
					console.log("ERROR ==>", error);
					setLoading(false);
				});
		}
	}, [visible, selectedUserProfileId]);

	return (
		<Sidebar show={visible}>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackbarClipboard}
				autoHideDuration={2000}
				onClose={() => setSnackbarClipboard(false)}
				message="Copied to clipboard"
			/>

			<Dialog
				open={inviteDialog}
				onClose={() => {
					setInviteDialog(false);
					setGeneratedInviteLink(null);
				}}
			>
				<DialogTitle>Invite</DialogTitle>

				<DialogContent style={{ minWidth: 380, maxWidth: 480 }}>
					{isEmpty(generatedInviteLink) ? (
						<DialogContentText>
							{!isEmpty(allowedInvites) &&
								allowedInvites.length &&
								allowedInvites.map((invitation, index) => (
									<Button
										key={index}
										className="w-100 text-left justify-content-start rounded-0 mb-0"
										style={{ textTransform: "capitalize", fontSize: 14 }}
										onClick={() =>
											onSelectInvitation(invitation?.Role, invitation?.GUID)
										}
									>
										<span style={{ flex: 1 }}>{invitation?.Role}</span>
										<img
											src={SvgPlus}
											alt="invite plus"
											style={{ height: 12, width: 12 }}
										/>
									</Button>
								))}
						</DialogContentText>
					) : (
						<DialogContentText className="d-flex flex-row justify-content-between align-items-center">
							<a
								href={generatedInviteLink}
								target="_blank"
								className="mr-2"
								rel="noreferrer"
							>
								<h3
									className="m-0 p-0"
									style={{
										color: { formMainTxt },
										fontSize: 14,
									}}
								>
									<i className="fas fa-fw fa-link mr-2" />
									{generatedInviteLink}
								</h3>
							</a>
							<Tooltip title="Copy to clipboard" placement="top">
								<IconButton
									onClick={() => copyToClipboard(generatedInviteLink)}
								>
									<img
										src={require(`../../assets/copy-ic.svg`)}
										style={{ width: 14 }}
									/>
								</IconButton>
							</Tooltip>
						</DialogContentText>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => {
							setGeneratedInviteLink(null);
							setInviteDialog(false);
						}}
					>
						Dismiss
					</Button>

					{!isEmpty(generatedInviteLink) && (
						<Button
							onClick={() => {
								setGeneratedInviteLink(null);
								setInviteDialog(false);
								copyToClipboard(generatedInviteLink);
							}}
							autoFocus
						>
							Copy and dismiss
						</Button>
					)}
				</DialogActions>
			</Dialog>

			<SidebarContent>
				<Button
					onClick={() => {
						closeSidebar();
					}}
					className="justify-content-start py-3 w-100"
					style={{
						textTransform: "initial",
						fontFamily: "inherit",
						height: 80,
					}}
				>
					<i
						className="fas fa-fw fa-chevron-left mr-3"
						style={{ fontSize: 20 }}
					/>
					<div className="d-flex flex-column align-items-start">
						<h2 className="m-0 p-0" style={{ fontSize: 21 }}>
							{trip.Name}
						</h2>
						<div
							className="dromon-l"
							style={{
								color: "#8f96a7",
								fontWeight: "300",
								fontSize: 12,
							}}
						>
							{languages[currentLanguage]["trip_crew_manage_header"]}
						</div>
					</div>
				</Button>

				<div
					className="d-flex flex-column justify-content-between"
					style={{
						marginTop: 0,
						minHeight: "calc(100% - 110px)",
						width: "100%",
					}}
				>
					{loading ? (
						<div
							className="d-flex w-100 justify-content-center align-items-center"
							style={{ flex: 1 }}
						>
							<CircularProgress className={classes.progress} />
						</div>
					) : (
						<>
							<div className="py-3">
								{trip.Members?.map((member, index) => (
									<div
										key={index}
										className="container mb-0 d-flex align-items-center"
										style={{ position: "relative" }}
									>
										<Button
											disableFocusRipple={true}
											onClick={() => onShowUserProfile(member.UserId)}
											className="d-flex text-left align-items-center justify-content-start rounded-0 mb-0"
											style={{
												flex: 1,
												textTransform: "capitalize",
												fontSize: 14,
											}}
										>
											<div
												style={{
													position: "relative",
													height: 40,
													width: 40,
												}}
											>
												<Avatar alt={member.FirstName} src={member.Photo} />
												{(member.Role === "creator" ||
													member.Role === "skipper") && (
													<img
														src={iconDictionary[member.Role]}
														style={{
															position: "absolute",
															top: -1,
															right: -1,
														}}
													/>
												)}
											</div>

											<h2
												className="dromon-l mb-0 ml-3"
												style={{
													color: formMainTxt,
													fontSize: 16,
													flex: 1,
												}}
											>
												{member.FirstName} {member.LastName}
											</h2>

											{!(member.Role !== "creator" && role === "creator") &&
												!(
													(member.Role === "passenger" ||
														member.Role === "passengerskipper") &&
													role === "superpassenger"
												) && (
													<span
														className="dromon-l d-flex justify-content-center align-items-center "
														style={{
															backgroundColor: "#f9f9f9",
															color: "#8f96a7",
															fontSize: 14,
															height: 34,
															minWidth: 115,
														}}
													>
														{member.Role}
													</span>
												)}
										</Button>

										{member.Role !== "creator" && role === "creator" && (
											<select
												onClick={(e) => e.stopPropagation()}
												onChange={(e) => {
													e.stopPropagation();
													updateRole(e.target.value, member);
												}}
												value={member.Role}
												className="ml-2"
												style={{
													color: "#8f96a7",
													fontSize: 14,
													borderColor: "rgb(143, 150, 167)",
													height: 34,
													minWidth: 115,
													borderRadius: 5,
													paddingLeft: 12,
													paddingRight: 12,
												}}
											>
												{possibleRoles.map((role, index) => (
													<option
														key={index}
														style={{ color: "#8f96a7", fontSize: 14 }}
													>
														{role}
													</option>
												))}
											</select>
										)}

										{(member.Role === "passenger" ||
											member.Role === "passengerskipper") &&
											role === "superpassenger" && (
												<select
													onClick={(e) => e.stopPropagation()}
													onChange={(e) => {
														e.stopPropagation();
														updateRole(e.target.value, member);
													}}
													value={member.Role}
													className="ml-2"
													style={{
														color: "#8f96a7",
														fontSize: 14,
														borderColor: "rgb(143, 150, 167)",
														height: 34,
														minWidth: 115,
														borderRadius: 5,
														paddingLeft: 12,
														paddingRight: 12,
													}}
												>
													{possibleRoles.map((role, index) => (
														<option
															key={index}
															style={{ color: "#8f96a7", fontSize: 14 }}
														>
															{role}
														</option>
													))}
												</select>
											)}

										{member.Role !== "creator" && role === "creator" && (
											<IconButton
												onClick={() =>
													role === "creator" ? deleteMember(member) : {}
												}
												className="ml-2"
												style={{
													height: 25,
													width: 25,
												}}
											>
												<img
													alt="Delete icon"
													src={deleteIcon}
													style={{
														height: 18,
														width: 18,
													}}
												/>
											</IconButton>
										)}
									</div>
								))}
							</div>
						</>
					)}
					{(role === "creator" ||
						role === "skipper" ||
						role === "cocreator" ||
						(role === "passenger" && !isEmpty(trip.NumberOfAdults)) ||
						role === "superpassenger" ||
						role === "passengerskipper") && (
						<div className="container">
							<Button
								className="p-2 col-12 text-capitalize"
								onClick={() => handleInvite()}
								style={{
									color: whiteColor,
									backgroundColor: filterHighlightColor,
									border: "none",
									fontSize: 14,
									borderRadius: 5,
									margin: "16px 0",
									boxShadow: "-5px 0 7px 0 rgba(160, 160, 169, 0.15)",
								}}
							>
								<span className="deci text-capitalize">
									{languages[currentLanguage]["trip_crew_invite_button"]}
								</span>
							</Button>
						</div>
					)}
				</div>
			</SidebarContent>
			{notice.type !== "" && (
				<>
					<Alert
						onClose={() => {
							setNotice({ type: "", content: "" });
						}}
						variant="filled"
						severity={notice.type.toLowerCase()}
						style={{ position: "absolute", bottom: 10, left: 10, zIndex: 22 }}
					>
						{notice.content}
					</Alert>
				</>
			)}
		</Sidebar>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUserProfileId: (userId) => dispatch(setUserProfileId(userId)),
		showUserProfile: () => dispatch(showUserProfile()),
		closeUserSidebars: () => dispatch(closeUserSidebars()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Crew);

export const BoatType = {
	sailing: "sailing",
	pleasure: "pleasure",
	fishing: "fishing",
};
export const VehicleType = {
	road: "road",
	offroad: "offroad",
};
export const DistanceUnits = {
	km: "km",
	nm: "nm",
	mi: "mi",
};
export const DepthUnits = {
	m: "m",
	ft: "ft",
	fth: "fth",
};
export const FuelUnit = {
	lt: "lt",
	gl: "gl",
};
export const SpeedUnit = {
	kmh: "kmh",
	kts: "kts",
	mph: "mph",
};
export const TempUnits = {
	celsius: "c",
	fahrenheit: "f",
};
export const WindSpeedUnits = {
	kmh: "kmh",
	kts: "kts",
	mph: "mph",
	bf: "bf",
};

const kmToNauticalMileConstant = 0.5399;
const kmToMileConstant = 0.621371102;
const meterToFeet = 3.2808399;
const meterToFanthom = 0.546806649;
const literToGallons = 0.264172052;
const kmhToKts = 0.539957;
const kmhToMph = 0.621371;
const ktsToKmh = 1.852;
const ktsToMph = 1.151;

// WindSpeedUnitsToDisplay
export function toDisplay(value) {
	if (value === WindSpeedUnits.kts) {
		return "KT";
	}
	return value.toUpperCase();
}

//DISTANCE
export function convertDistanceToMyUnit(value, userDistanceUnits) {
	const currentUnit = userDistanceUnits;
	switch (currentUnit) {
		case DistanceUnits.km:
			// SERVER DEFAULT
			return value.toFixed(2);
		case DistanceUnits.mi:
			return (value * kmToMileConstant).toFixed(2);
		case DistanceUnits.nm:
			return (value * kmToNauticalMileConstant).toFixed(2);
	}
}
//DEPTH
export function convertDepthToMyUnit(value, userDepthUnits) {
	const currentUnit = userDepthUnits;
	if (!value) {
		return 0;
	}
	if (value === null) {
		return 0;
	}
	switch (currentUnit) {
		case DepthUnits.m:
			// SERVER DEFAULT
			return value;
		case DepthUnits.ft:
			return (value * meterToFeet).toFixed(2);
		case DepthUnits.fth:
			return (value * meterToFanthom).toFixed(2);
	}
}
//FUEL
export function convertFuelToMyUnit(value, userFuelUnit) {
	const currentUnit = userFuelUnit;
	switch (currentUnit) {
		case FuelUnit.lt:
			// SERVER DEFAULT
			return value.toFixed(2);
		case FuelUnit.gl:
			return (value * literToGallons).toFixed(2);
	}
}
//SPEED
export function convertSpeedToMyUnit(value, userSpeedUnit) {
	const currentUnit = userSpeedUnit;
	switch (currentUnit) {
		case SpeedUnit.kmh:
			return (value * ktsToKmh).toFixed(2);
		case SpeedUnit.kts:
			// SERVER DEFAULT
			return value.toFixed(2);
		case SpeedUnit.mph:
			return (value * kmhToMph).toFixed(2);
	}
}

//TEMPERATURE
export function convertTemperatureToMyUnit(value, userTempUnits) {
	const currentUnit = userTempUnits;
	switch (currentUnit) {
		case TempUnits.celsius:
			// SERVER DEFAULT
			return value.toFixed(2);
		case TempUnits.fahrenheit:
			let convertedValue = value * 1.8 + 32;
			return convertedValue.toFixed(2);
	}
}
//WIND SPPED
export function convertWindSpeedToMyUnit(value, userWindSpeedUnits) {
	const currentUnit = userWindSpeedUnits;
	switch (currentUnit) {
		case WindSpeedUnits.kmh:
			return (value * ktsToKmh).toFixed(2);
		case WindSpeedUnits.kts:
			// SERVER DEFAULT
			return value.toFixed(2);
		case WindSpeedUnits.mph:
			return (value * ktsToMph).toFixed(2);
		case WindSpeedUnits.bf:
			return getBeaufortValue(value);
	}
}
//GET BEAUFORT SCALE
export function getBeaufortValue(value) {
	if (value < 1) {
		return 0.0;
	} else if (value > 0 && value < 4) {
		// 1-3
		return 1.0;
	} else if (value > 3 && value < 7) {
		// 4-6
		return 2.0;
	} else if (value > 6 && value < 11) {
		// 7-10
		return 3.0;
	} else if (value > 10 && value < 17) {
		// 11-16
		return 4.0;
	} else if (value > 16 && value < 22) {
		// 17-21
		return 5.0;
	} else if (value > 21 && value < 28) {
		// 22-27
		return 6.0;
	} else if (value > 27 && value < 34) {
		// 28-33
		return 7.0;
	} else if (value > 33 && value < 41) {
		// 34-40
		return 8.0;
	} else if (value > 40 && value < 48) {
		// 41-47
		return 9.0;
	} else if (value > 47 && value < 56) {
		// 48-55
		return 10.0;
	} else if (value > 55 && value < 64) {
		// 56-63
		return 11.0;
	} else if (value > 63) {
		// 64 - ...
		return 12.0;
	}
	return 0.0;
}

// CONVERT BACK TO SERVER UNIT
//DEPTH -> WHEN UPDATING VESSEL'S BEAM, DRAFT & LENGTH
export function convertDepthToServerUnit(value, userDepthUnits) {
	const currentUnit = userDepthUnits;
	switch (currentUnit) {
		case DepthUnits.m:
			// SERVER DEFAULT
			return value;
		case DepthUnits.ft:
			return value / meterToFeet;
		case DepthUnits.fth:
			return value / meterToFanthom;
	}
}
//FUEL -> WHEN UPDATING VESSEL'S FUEL CONSUMPTION
export function convertFuelToServerUnit(value, userFuelUnit) {
	const currentUnit = userFuelUnit;
	switch (currentUnit) {
		case FuelUnit.lt:
			// SERVER DEFAULT
			return value;
		case FuelUnit.gl:
			return value / literToGallons;
	}
}
//SPEED -> WHEN UPDATING VESSEL'S CRUISE SPEED
export function convertSpeedToServerUnit(value, userSpeedUnit) {
	const currentUnit = userSpeedUnit;
	switch (currentUnit) {
		case SpeedUnit.kmh:
			return value / ktsToKmh;
		case SpeedUnit.kts:
			// SERVER DEFAULT
			return value;
		case SpeedUnit.mph:
			return value / kmhToMph;
	}
}

export function convertDistanceToKM(value, userDistanceUnits) {
	const currentUnit = userDistanceUnits;
	switch (currentUnit) {
		case DistanceUnits.km:
			// SERVER DEFAULT
			return value;
		case DistanceUnits.mi:
			return value / kmToMileConstant;
		case DistanceUnits.nm:
			return value / kmToNauticalMileConstant;
	}
}

export function convertSpeedToKMPH(value) {
	convertSpeedToServerUnit(value);
}

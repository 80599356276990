import styled from "styled-components";

export const ButtonFilter = styled.button`
  background-color: "white";
  position: "absolute";
  bottom: 50px;
  right: 50px;
  z-index: 100px;
  border-radius: 50%;
  box-shadow: 0 0 10px gray;
  padding: 10px;
`;

export const MainContainer = styled.div`
  height: 115px;
  width: 100%;
`;
export const ImgContainer = styled.div`
  height: 100%;
  width: 115px;
  margin-left: 0px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`;
export const DescriptionContainer = styled.div`
  padding: 10px 0 10px 15px;
  border-top: solid 2px #e3e5e9;
  border-right: solid 2px #e3e5e9;
  border-bottom: solid 2px #e3e5e9;
  flex: 1;
`;
export const Title = styled.h1`
  margin: 0 0 5px;
  padding: 0;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.2px;
  color: #222c4b;
`;
export const SubTitle = styled.h2`
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: #8f96a7;
`;
export const DescTxt = styled.span`
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #222c4b;
`;
